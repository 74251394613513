import { createInstance } from '@/services/axios.api'
import { isLoggedIn } from '@/utils/user.utils'
export const api = createInstance(isLoggedIn() ? process.env.VUE_APP_DOCUMENT_SERVICE_CTF : process.env.VUE_APP_DOCUMENT_SERVICE_PUBLIC)

const fetchPublicPublished = async product => {
  const url = 'document/public/published'

  return await api.get(url, {
    params: { product }
  })
}

const fetchPublicArchived = async product => {
  const url = 'document/public/archived'

  return await api.get(url, {
    params: { product }
  })
}

const fetchLoggedInPublished = async product => {
  const url = 'document/logged-in/published'

  return await api.get(url, {
    params: { product }
  })
}

const fetchLoggedInArchived = async product => {
  const url = 'document/logged-in/archived'

  return await api.get(url, {
    params: { product }
  })
}

export default {
  fetchPublicPublished,
  fetchPublicArchived,
  fetchLoggedInPublished,
  fetchLoggedInArchived
}
