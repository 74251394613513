import AreaSearchService from '@/services/area-search.service'

export const AreaSearchModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    exception: false,
    fiberMarketAreas: [],
    accessPoints: [],
    deliveryDate: '',
    requestException: false,
    requestLoading: false,
    requestLoaded: false
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    },
    setFiberMarketAreas (state, fiberMarketAreas) {
      state.fiberMarketAreas = fiberMarketAreas
    },
    setAccessPoints (state, accessPoints) {
      state.accessPoints = accessPoints
    },
    setDeliveryDate (state, deliveryDate) {
      state.deliveryDate = deliveryDate
    },
    setRequestLoading (state, requestLoading) {
      state.requestLoading = requestLoading
    },
    setRequestException (state, requestException) {
      state.requestException = requestException
    },
    setRequestLoaded (state, requestLoaded) {
      state.requestLoaded = requestLoaded
    }
  },
  actions: {
    loadAreaSearchChoices ({ commit }) {
      commit('setLoading', true)

      return AreaSearchService.getAreaSearchChoices()
        .then(result => {
          commit('setFiberMarketAreas', result.fiberMarketAreas)
          commit('setAccessPoints', result.accessPoint)
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    submitRequest ({ commit }, areaSearchRequest) {
      commit('setRequestException', false)
      commit('setRequestLoading', true)

      return AreaSearchService.postAreaSerchOrder(areaSearchRequest)
        .then(result => {
          commit('setDeliveryDate', result.deliveryDate)
          commit('setRequestLoaded', true)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Area search request: ' + error)
          commit('setRequestException', true)
          commit('setRequestLoaded', false)
        })
        .finally(() => {
          commit('setRequestLoading', false)
        })
    }
  }
}
