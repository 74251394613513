<template>
  <purpur-heading
    :variant="variant"
    :tag="tag">
    <slot />
  </purpur-heading>
</template>
<script>
import { Heading } from '@purpurds/purpur'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  components: {
    'purpur-heading': applyReactInVueWithOptions(Heading)
  },
  props: {
    variant: {
      type: String,
      default: 'title-100'
    },
    tag: {
      type: String,
      default: 'h1'
    }
  }
}
</script>
