<template>
  <tbody>
    <tr
      class="expandable-row"
      :class="{'expandable-row--even': rowIndex % 2 === 0 }">
      <slot name="columns" />
      <td
        v-if="isExpandableRowAvailable"
        class="icon"
        :class="{ 'icon--expanded' : showSubRow }">
        <TeliaCoreIcon
          name="arrow_down"
          size="25px"
          @click.native="toggleSubRow()" />
      </td>
      <td v-else />
    </tr>
    <template
      v-if="showSubRow">
      <slot name="subRow" />
    </template>
  </tbody>
</template>

<script>

export default {
  props: {
    rowIndex: {
      type: Number,
      required: true
    },
    isExpandableRowAvailable: {
      type: Boolean
    }
  },
  data () {
    return {
      showSubRow: false
    }
  },
  methods: {
    toggleSubRow () {
      this.showSubRow = !this.showSubRow
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.expandable-row {
  &--even {
    background-color: $light-grey;
  }

  &:hover {
    background-color: $medium-grey;
  }
}

.icon {
  padding: 2rem;
  text-align: right;

  &--expanded svg {
    transform: rotateX(180deg);
  }

  svg {
    cursor: pointer;
  }
}
</style>
