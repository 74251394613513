<template>
  <CommonPageView
    class="tws-news-view"
    :is-grey-background="true"
    :loading="false"
    :breadcrumb-items="breadcrumbItems"
    :banner="banner">
    <template #breadcrumb>
      <InfraTabs :items="tabs" />
    </template>

    <template #content>
      <div class="container">
        <tws-map-generic-page-alerts
          :loading="isLoading"
          :exception="exception" />

        <div
          v-if="!isLoading && !exception"
          class="row">
          <div class="col-lg-6 col-md-12">
            <h1 class="heading-l--black">
              {{ $t('map.estimated-delivery') }}
            </h1>
            <tws-map-delivery-time-counties-table
              :content="content"
              :items="deliveryTime"
              @select="loadCountyInfo" />
          </div>

          <div class="col-lg-6 col-md-12">
            <div
              v-if="!selectedCounty"
              class="row">
              <div class="offset-lg-2 col-md-10 col-sm-12">
                <tws-map
                  :items="mapItems"
                  color-palette="cyan"
                  @select="loadCountyInfo" />

                <h4>
                  {{ $t('map.estimated-delivery') }}
                </h4>

                <tws-map-legend
                  color-palette="cyan"
                  :items="mapLegend" />
              </div>
            </div>

            <div
              v-else
              class="row">
              <div class="col">
                <tws-map-delivery-time-municipalities-table
                  :county="selectedCounty"
                  @close="selectedCounty = null" />
              </div>
            </div>
          </div>

          <div
            v-if="content.mapTextInformation"
            class="col-lg-6 col-md-12">
            <InfraContentfulRichTextContainer :content="content.mapTextInformation" />
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'
import {
  InfraTabs, InfraContentfulRichTextContainer
} from 'tws-vue-components'
import CommonPageView from '@/views/CommonPageView'
import Map from '@/components/map/map'
import MapGenericPageAlerts from '@/components/map/map-generic-page-alerts'
import MapNetworkInformationCountiesTable from '@/components/map/map-delivery-time/map-delivery-time-counties-table'
import MapNetworkInformationMunicipalitiesTable from '@/components/map/map-delivery-time/map-delivery-time-municipalities-table'
import MapLegend from '@/components/map/map-legend'
import { COUNTY_CODE_BY_NAME } from '@/utils/map-utils'
import i18n from '@/i18n'
import { networkInfoTabs } from '@/utils/network-info.utils'
import { BREADCRUMBS_DELIVERY_TIME } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    'tws-map': Map,
    'tws-map-delivery-time-counties-table': MapNetworkInformationCountiesTable,
    'tws-map-delivery-time-municipalities-table': MapNetworkInformationMunicipalitiesTable,
    'tws-map-generic-page-alerts': MapGenericPageAlerts,
    'tws-map-legend': MapLegend,
    InfraTabs,
    InfraContentfulRichTextContainer,
    CommonPageView
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_DELIVERY_TIME,
      banner: {
        heading: i18n.t('network-information.tabs.delivery-time'),
        description: i18n.t('network-information.delivery-time.description')
      },
      tabs: networkInfoTabs,
      selectedCounty: null,
      mapLegend: [
        {
          status: 0,
          text: '< 114'
        },
        {
          status: 1,
          text: '115 - 129'
        },
        {
          status: 2,
          text: '> 130'
        }
      ]
    }
  },
  computed: {
    ...mapState('map/deliveryTime', [
      'loading',
      'loadingContent',
      'exception',
      'deliveryTime',
      'mapItems',
      'content'
    ]),
    isLoading () {
      return this.loading || this.loadingContent
    }
  },
  mounted () {
    this.fetchDeliveryTime()
    this.fetchContent()
  },
  methods: {
    loadCountyInfo (selectedCountyCode) {
      this.selectedCounty = this.deliveryTime.find(c => {
        return COUNTY_CODE_BY_NAME[c.county] === selectedCountyCode
      })
    },
    ...mapActions({
      fetchDeliveryTime: 'map/deliveryTime/fetchDeliveryTime',
      fetchContent: 'map/deliveryTime/fetchContent'
    })
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 60px;
}
</style>
