<template>
  <div>
    <template v-if="isWholesaleFiberOutside">
      <InfraPageAlert
        v-if="!productData.fromAddressNod"
        type="info"
        class="mt-5 mb-5">
        {{ $t('products-display.wholesale-fiber-outside.no-offers-text') }}
      </InfraPageAlert>
      <template v-else>
        <wholesale-fiber-product-side
          side="A"
          :title="pointHeaderA" />
        <InfraPageAlert
          type="info"
          class="mt-5 mb-5">
          {{ $t('products-display.wholesale-fiber-outside.product-view-text') }}
          <span class="purple-link">
            <a :href="getFragaBestallaLink()">
              {{ $t('products-display.wholesale-fiber-outside.quoteOrder') }}
            </a>
          </span>
        </InfraPageAlert>
      </template>
    </template>
    <template v-else>
      <div
        v-if="parsedProductData.offers.length === 0"
        class="row mt-3">
        <div class="col">
          <InfraPageAlert
            type="info">
            {{ $t('products-display.advanced-wholesale-fiber.no-offers') }}
          </InfraPageAlert>
        </div>
      </div>
      <div
        v-else
        class="row mt-5">
        <div class="col-3">
          <span class="access-point-details__title">
            {{ $t('products-display.advanced-wholesale-fiber.title') }}
          </span>
        </div>
        <div
          class="col-lg-6 col-9 wholesale-fiber-product-offers">
          <div
            v-for="offer in parsedProductData.offers"
            :key="offer.product"
            class="wholesale-fiber-product-offer">
            <strong>{{ offer.product }}</strong> {{ formatDeliveryTime(offer.deliveryTime) }}
          </div>
        </div>
      </div>
      <wholesale-fiber-product-side
        class="mb-5"
        side="A"
        :title="pointHeaderA"
        :point-data="parsedProductData.pointA"
        :site-data="parsedProductData.siteA"
        :hide-delivery="true" />
      <wholesale-fiber-product-side
        side="B"
        :title="pointHeaderB"
        :point-data="parsedProductData.pointB"
        :site-data="parsedProductData.siteB"
        :hide-delivery="true" />
    </template>
  </div>
</template>

<script>
import {
  printAddress,
  printNode
} from '@/apps/address-search/utils/string-utils'
import { InfraPageAlert } from 'tws-vue-components'
import WholesaleFiberProductSide from '@/apps/address-search/components/ProductsDisplay/wholesale-fiber-product-side'
import parseProductData from '@/apps/address-search/utils/wholesale-fiber'
import URIUtils from '@/apps/address-search/utils/uri-utils'

export default {
  components: {
    InfraPageAlert,
    WholesaleFiberProductSide
  },
  props: {
    searchedAddressA: {
      type: Object,
      default: null
    },
    searchedAddressB: {
      type: Object,
      default: null
    },
    searchedNodeA: {
      type: Object,
      default: null
    },
    searchedNodeB: {
      type: Object,
      default: null
    },
    productData: {
      type: Object,
      default: () => {}
    },
    isWholesaleFiberOutside: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    pointHeaderA () {
      if (this.searchedNodeA) {
        return printNode(this.searchedNodeA)
      }

      return printAddress(this.searchedAddressA)
    },
    pointHeaderB () {
      if (this.searchedNodeB) {
        return printNode(this.searchedNodeB)
      }

      return printAddress(this.searchedAddressB)
    },
    parsedProductData () {
      return parseProductData(this.productData)
    }
  },
  methods: {
    formatDeliveryTime (deliveryTime) {
      if (deliveryTime === -1) {
        return `(${this.$t('products-display.advanced-wholesale-fiber.delivery-time.manual-quote')})`
      }

      return `(${this.$t('products-display.advanced-wholesale-fiber.delivery-time.preliminary-delivery')} ${deliveryTime} ${this.$t('products-display.advanced-wholesale-fiber.delivery-time.working-days')})`
    },
    getFragaBestallaLink () {
      return URIUtils.constructFragaBestallaLink()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.access-point-details__title {
  font-weight: bold;
}

.purple-link {
  text-decoration: underline;
  color: $core-purple;
}
</style>
