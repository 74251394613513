const UserInfoMixin = {
  methods: {
    getFullName (user) {
      if (!user || !user.firstName || !user.lastName) {
        return ''
      }

      return `${user.firstName} ${user.lastName}`
    }
  }
}

export default UserInfoMixin
