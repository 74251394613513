<template>
  <div>
    <div class="col-3 d-flex align-items-start">
      <div class="address-icon mr-4">
        <TwsFieldLabel
          :id="`address-icon-spacer-${label}`"
          label="label"
          class="address-icon__spacer" />
        <div
          class="address-icon__box"
          :class="{ 'address-icon__box--orange': label === 'B' }">
          {{ label }}
        </div>
      </div>

      <TextField
        :id="`node-${label}`"
        :name="`node-${label}`"
        class="flex-fill"
        :label="$t('address-search.field-labels.node')"
        :value="node"
        :validator="validators.node"
        :validation-messages="validationMessages.node"
        @change="changeField('node', $event)" />
    </div>

    <div class="col-2">
      <TextField
        :id="`kc-${label}`"
        :name="`kc-${label}`"
        :label="$t('address-search.field-labels.kc')"
        :value="kc"
        :validator="validators.kc"
        :validation-messages="validationMessages.kc"
        @change="changeField('kc', $event)" />
    </div>
  </div>
</template>

<script>
import { TwsFieldLabel } from 'tws-vue-components'
import TextField from '@/apps/address-search/components/TextField'

export default {
  components: {
    TextField,
    TwsFieldLabel
  },
  props: {
    label: {
      type: String,
      default: 'A'
    },
    node: {
      type: String,
      required: true
    },
    kc: {
      type: String,
      required: true
    },
    validators: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        node: {
          required: this.$t('address-search.validation-messages.node.required')
        },
        kc: {}
      }
    }
  },
  methods: {
    changeField (key, nextValue) {
      this.$emit('change', {
        key,
        value: nextValue.toUpperCase()
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "tws-core-atoms/variables.scss";

.address-icon {
  &__box {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $core-purple;
    color: $white;
    border-radius: 3px;

    height: 5rem;
    padding: 0 14px;
    font-size: 22px;
    font-weight: 700;

    &--orange {
      background-color: #DD8804;
    }
  }

  &__spacer {
    visibility: hidden;
  }
}

</style>
