import { createInstance } from './axios.api'
import UserUtils from '@/utils/user.utils'

export const api = createInstance(process.env.VUE_APP_PRODUCT_MANAGEMENT_API)

const ProductManagementService = {
  getTicketHandlingMenuItems: async () => {
    return await api.get('/company/products/my-tickets')
  },
  getProductVisibility: async () => {
    return await api.get('/company/products/operator-change')
  },
  getOrders: async () => {
    return await api.get('/company/products/order')
  },
  getMyOrders: async () => {
    return await api.get('/company/products/my-orders')
  },
  getIframePath: (page, url) => {
    return page + '?iframe-size=large&iframeUrl=' + encodeURIComponent(location.origin + url)
  },
  getProductsWithAgreementLinks: async (productItems) => {
    const companyCode = UserUtils.companyCode()

    return await api.get('/company/products/with-agreement', {
      params: {
        companyCode: companyCode
      }
    }).then(productIdsAvailable => {

      return productItems.reduce((acc, item) => {
        const filteredCategories = item.categories.filter(
          category => productIdsAvailable.includes(category.productId)
        )

        if (filteredCategories.length > 0) {
          acc.push({
            order: item.order,
            title: item.title,
            titleEn: item.titleEn || item.title,
            subCategories: filteredCategories.map((category, index) => ({
              order: index,
              link: `${category.link}/dokument`,
              title: category.title,
              titleEn: category.title,
              productId: category.productId
            }))
          })
        }
        return acc
      }, [])
    })
  }
}

export default ProductManagementService
