import { postComment } from '@/apps/fault-reporting/services/teliaNOW.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    exception: false
  },
  mutations: {
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception
  },
  actions: {
    async postComment ({ commit, dispatch }, payload) {
      try {
        commit('setLoading', true)
        commit('setException', false)

        const updatedCaseDetails = await postComment(payload.id, payload.request)

        dispatch('FaultReporting/FaultReportDetails/updateCaseResponse', updatedCaseDetails, { root: true })

      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    resetState ({ commit }) {
      commit('setLoading', false)
      commit('setException', false)
    }
  }
}
