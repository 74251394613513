import { createInstance } from './axios.api'

export const apiPiam = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE_PIAM)

const CompanyService = {
  getCompanies: async () => {
    return await apiPiam.get('/external/companies')
  },
  getCompanyById: async companyId => {
    return await apiPiam.get(`/external/companies/${companyId}`)
  },
  create: async form => {
    return await apiPiam.post('/external/companies', form)
  },
  update: async form => {
    return await apiPiam.put('/external/companies', form)
  }
}

export default CompanyService
