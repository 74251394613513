<template>
  <div class="news">
    <h1
      v-if="title"
      class="heading-m">
      {{ title }}
    </h1>

    <template v-for="data in news">
      <article
        :key="data.sys.id"
        class="news-item">
        <section>
          <tws-nav-link
            :href="getNewsViewLink(data.sys.id)"
            class="news-item__header">
            {{ data.title }}
          </tws-nav-link>

          <div class="news-item__text">
            {{ data.leadParagraph | limitText }}

            <tws-nav-link
              :href="getNewsViewLink(data.sys.id)"
              class="news-item__read-more">
              {{ $t('common.read-more') }}
            </tws-nav-link>
          </div>
        </section>

        <figure
          v-if="data.thumbnail"
          class="news-item__image">
          <img
            :src="data.thumbnail.url"
            :alt="data.thumbnail.url">
        </figure>

        <time class="news-item__date">
          {{ data.publishDate | formatDate }}
        </time>
      </article>
    </template>
  </div>
</template>

<script>
import DateTimeUtils from '@/common/datetime-utils'
import { TwsNavLink } from 'tws-vue-components'

export default {
  components: {
    TwsNavLink
  },
  filters: {
    formatDate (date) {
      return DateTimeUtils.formatCustom(date, 'yyyy-MM-dd')
    },
    limitText (text) {
      return text && text.length > 256 ? text.substring(0, 256) + '...' : text
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    news: {
      type: Array,
      required: true
    }
  },
  methods: {
    getNewsViewLink (newsId) {
      return `/news-archive/news-page?news-id=${newsId}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.news {
  margin-top: 55px;

  @media(max-width: $breakpoint-sm) {
    margin-top: 35px;
  }
}

.heading-m {
  margin-bottom: 30px;
}

.news-item {
  display: flex;
  justify-content: space-between;

  border-top: 1px solid #BFBFBF;
  padding-top: 15px;
  padding-bottom: 15px;

  @media(max-width: $breakpoint-sm) {
    flex-direction: column-reverse;
  }

  &:last-child {
    padding-bottom: 15px;
    border-bottom: 1px solid #BFBFBF;
  }

  &__header {
    font-size: 22px;
  }

  &__text {
    margin-top: 10px;
    color: #757575;

    a {
      white-space: nowrap;
    }
  }

  &__image {
    margin-left: 50px;

    @media(max-width: $breakpoint-sm) {
      display: none;
    }

    img {
      max-width: 200px;
    }
  }

  &__date {
    white-space: nowrap;
    color: $dark-grey;
    margin-bottom: 10px;

    @media(min-width: $breakpoint-sm) {
      margin-left: 50px;
    }
  }

  &__read-more {
    color: $core-purple;
  }
}
</style>
