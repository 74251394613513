<template>
  <div class="form-container">
    <component
      :is="form"
      v-if="html" />

    <FormPlaceholder v-if="loading" />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  mapState, mapActions
} from 'vuex'

import { FormPlaceholder } from '../components/'
import {
  Alert,
  CheckboxGroup,
  Checkbox,
  Column,
  Dropdown,
  Form,
  Heading,
  Image,
  InputField,
  Paragraph,
  RadioButtonGroup,
  RichText,
  Row,
  TextArea,
  TextBox
} from '../../dynamic-form/components/'

Vue.component('TwsForm', Form)
Vue.component('TwsFormRow', Row)
Vue.component('TwsFormColumn', Column)
Vue.component('TwsFormInputField', InputField)
Vue.component('TwsFormRadioGroup', RadioButtonGroup)
Vue.component('TwsFormTextArea', TextArea)
Vue.component('TwsFormHeading', Heading)
Vue.component('TwsFormTextBox', TextBox)
Vue.component('TwsFormDropdown', Dropdown)
Vue.component('TwsFormCheckbox', Checkbox)
Vue.component('TwsFormCheckboxGroup', CheckboxGroup)
Vue.component('TwsFormParagraph', Paragraph)
Vue.component('TwsFormImage', Image)
Vue.component('TwsRichText', RichText)
Vue.component('TwsFormAlert', Alert)

export default {
  components: {
    FormPlaceholder
  },
  props: {
    subCategory: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('ContactUs/Form', [
      'loading',
      'html'
    ]),
    form () {
      return {
        template: this.html
      }
    }
  },
  async mounted () {
    await this.load(this.subCategory.fields.internalLink)

    setTimeout(() => {
      const element = document.getElementsByClassName('items-view')[0]

      element.scrollIntoView({
        behavior: 'smooth'
      })
    }, 200)
  },
  methods: {
    ...mapActions('ContactUs/Form', ['load'])
  }
}
</script>
