<template>
  <GrootHeroBanner
    :title="$t('common.greeting') + ' ' + getFirstName"
    :breadcrumbs="items" />
</template>

<script>
import { mapGetters } from 'vuex'
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import { GrootHeroBanner } from 'groot-components'

export default {
  components: {
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('eamCookies', ['getFirstName'])
  }
}
</script>
