import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_PRODUCT_SEARCH_URL)

const PhoneSearchService = {
  getAssets: async (cityCode, number) => {
    return await api.post('/phone/search', {
      cityCode: cityCode,
      number: number
    })
  }
}

export default PhoneSearchService
