import { render, staticRenderFns } from "./dashboard-document-links.vue?vue&type=template&id=219459dc&scoped=true"
import script from "./dashboard-document-links.vue?vue&type=script&lang=js"
export * from "./dashboard-document-links.vue?vue&type=script&lang=js"
import style0 from "./dashboard-document-links.vue?vue&type=style&index=0&id=219459dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219459dc",
  null
  
)

export default component.exports