<template>
  <div
    class="rich-text-container"
    v-html="html" />
</template>

<script>
export default {
  props: {
    html: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "tws-core-atoms/variables.scss";

  .rich-text-container {
    a {
      text-decoration: none;
      color: $core-purple;

      &:hover,
      &:focus {
        background-color: inherit;
      }

      &:hover {
        color: $core-purple;
      }

      &:active,
      &:visited {
        color: $dark-core-purple;
      }

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    /* headings */
    h2 + p {
      margin-top: 2rem;
    }

    h2 + p.preamble {
      margin-top: 3rem;
    }

    p.preamble + h3 {
      margin-top: 1rem;
    }

    h3.heading-s + ul,
    h3.heading-s + ol {
      margin-top: 2rem;
    }

    p.preamble + ul,
    p.preamble + ol {
      margin-top: 2rem;
    }

    h3.heading-s {
      padding-top: 2rem;
    }

    /* lists */
    li p {
      margin: 0;
    }

    ul,
    ol {
      padding-left: 3rem;
    }

    ol li {
      padding-left: 1rem;
    }

    ol ul li {
      padding-left: 0;
    }

    /* tables */
    table,
    td,
    th {
      border: 0 solid transparent;
      line-height: 1.38;
    }

    table {
      width: 100% !important;
      border-collapse: collapse;
      margin: 2rem 0 3rem 0;
    }

    tr:nth-child(odd) {
      background-color: $white;
    }

    tr:nth-child(even) {
      background-color: $light-grey;
    }

    td,
    th {
      text-align: left;
      padding: 1.6rem 1rem;
      vertical-align: top;
    }

    td p,
    th p {
      margin: 0;
    }
  }
</style>
