import Cookies from 'js-cookie'

const epinfo = Cookies.get('epinfo')

export const hasAnyWebtabRole = () => {
  const roles = epinfo ? epinfo.split('-') : []

  return roles.includes('tabcc') ||
      roles.includes('tabkt') ||
      roles.includes('tabktf')
}
