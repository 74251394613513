<template>
  <div
    v-if="isSuperUser || isAdmin || isBillingAdmin"
    class="row my-documents-company-filter">
    <div class="col-md-6 col-sm-12">
      <InfraSearchableDropdown
        id="searchable-company-filter"
        v-model="selectedCompany"
        :label-getter="dropdownLabelGetter"
        :label="$t('common.business')"
        :clearable="true"
        :options="getCompaniesWithTscid" />
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState
} from 'vuex'

import { InfraSearchableDropdown } from 'tws-vue-components'

export default {
  components: {
    InfraSearchableDropdown
  },
  computed: {
    ...mapGetters('eamCookies', [
      'isSuperUser',
      'isAdmin',
      'isBillingAdmin'
    ]),
    ...mapState('MyDocuments', ['company']),
    ...mapGetters('lookups/companies', [
      'getCompanies',
      'getCompaniesWithTscid'
    ]),
    selectedCompany: {
      get () {
        return this.company
      },
      set (value) {
        this.updateCompany(value || '')
      }
    }
  },
  async created () {
    if (this.isSuperUser || this.isAdmin || this.isBillingAdmin) {
      this.fetchCompanies()
    }

    this.loadCompany()
  },
  methods: {
    ...mapActions('lookups/companies', ['fetchCompanies']),
    ...mapActions('MyDocuments', [
      'loadCompany',
      'updateCompany'
    ]),
    dropdownLabelGetter (option) {
      return option?.nameWithTscid
    }
  }
}
</script>

<style lang="scss" scoped>
.my-documents-company-filter::v-deep .form-control {
  margin-bottom: 0;
}
</style>
