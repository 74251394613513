import { render, staticRenderFns } from "./personal-information-display.vue?vue&type=template&id=1de68e6a&scoped=true"
import script from "./personal-information-display.vue?vue&type=script&lang=js"
export * from "./personal-information-display.vue?vue&type=script&lang=js"
import style0 from "./personal-information-display.vue?vue&type=style&index=0&id=1de68e6a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1de68e6a",
  null
  
)

export default component.exports