<template>
  <div class="container">
    <div class="row">
      <div class="col heading-col">
        <h1 class="heading-m">
          {{ $t('account-history.heading') }}
        </h1>
      </div>
    </div>

    <div
      v-if="informationLoaded && (information.lastLoginDate || information.lastFailedLoginDate)"
      class="row login-information-row">
      <div
        v-if="information.lastLoginDate"
        class="col-3">
        <label class="label">
          {{ $t('account-history.last-login') }}
        </label>

        <span>{{ information.lastLoginDate }}</span>
      </div>

      <div
        v-if="information.lastFailedLoginDate"
        class="col-3">
        <label class="label">
          {{ $t('account-history.last-unsuccessful-login') }}
        </label>

        <span>{{ information.lastFailedLoginDate }}</span>
      </div>
    </div>

    <InfraPageAlert
      v-if="loading"
      type="info">
      {{ $t('account-history.loading') }}
    </InfraPageAlert>

    <InfraPageAlert
      v-if="exception"
      type="danger">
      {{ $t('account-history.loading-failed') }}
    </InfraPageAlert>

    <template v-if="loaded">
      <InfraPageAlert
        v-if="history.length === 0"
        type="success">
        {{ $t('account-history.no-history') }}
      </InfraPageAlert>

      <template v-if="history.length > 0">
        <div class="row">
          <div class="col">
            <div class="actions">
              <tws-borderless-custom-content-button class="action color-core-purple">
                <template v-slot:toggler>
                  <span>{{ $t('account-history.export') }}</span>&nbsp;
                  <tws-download-icon />
                </template>
                <span>{{ $t('account-history.file-format') }}</span>

                <div class="export-buttons">
                  <button
                    class="export-button"
                    @click="exportResults('xls')">
                    <tws-xls-icon /><br>
                    <span>{{ $t('account-history.xls') }}</span>
                  </button>

                  <button
                    class="export-button"
                    @click="exportResults('csv')">
                    <tws-csv-icon />
                    <br>
                    <span>{{ $t('account-history.csv') }}</span>
                  </button>
                </div>
              </tws-borderless-custom-content-button>

              <tws-borderless-button
                class="action color-core-purple"
                @click="toggleFilters">
                {{ showFilters ? $t('common.close-filter') : $t('common.filter') }}

                <tws-arrow-up-icon
                  v-if="showFilters"
                  class="icon" />
                <tws-arrow-down-icon
                  v-if="!showFilters"
                  class="icon" />

                <span :class="{ 'active-filter-list': showFilters }" />
              </tws-borderless-button>
            </div>
          </div>
        </div>

        <div
          v-show="showFilters"
          class="advanced-filters-wrapper">
          <div class="row">
            <div class="col filters-heading-col">
              {{ $t('account-history.filters.type-of-event') }}
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <input
                id="created-user-checkbox"
                v-model="filters"
                class="input-checkbox"
                name="filters"
                type="checkbox"
                :value="$t('account-history.filters.created-user')">
              <label
                class="label"
                for="created-user-checkbox">
                {{ $t('account-history.filters.created-user') }}
              </label>
            </div>

            <div class="col-3">
              <input
                id="password-generated-checkbox"
                v-model="filters"
                class="input-checkbox"
                name="filters"
                type="checkbox"
                :value="$t('account-history.filters.password-generated')">
              <label
                class="label"
                for="password-generated-checkbox">
                {{ $t('account-history.filters.password-generated') }}
              </label>
            </div>

            <div class="col-3">
              <input
                id="unlock-checkbox"
                v-model="filters"
                class="input-checkbox"
                name="filters"
                type="checkbox"
                :value="$t('account-history.filters.unlock')">
              <label
                class="label"
                for="unlock-checkbox">
                {{ $t('account-history.filters.unlock') }}
              </label>
            </div>

            <div class="col-3">
              <input
                id="email-checkbox"
                v-model="filters"
                class="input-checkbox"
                name="filters"
                type="checkbox"
                :value="$t('account-history.filters.email')">
              <label
                class="label"
                for="email-checkbox">
                {{ $t('account-history.filters.email') }}
              </label>
            </div>

            <div class="col-3">
              <input
                id="modified-checkbox"
                v-model="filters"
                class="input-checkbox"
                name="filters"
                type="checkbox"
                :value="$t('account-history.filters.modified')">
              <label
                class="label"
                for="modified-checkbox">
                {{ $t('account-history.filters.modified') }}
              </label>
            </div>

            <div class="col-3">
              <input
                id="deleted-checkbox"
                v-model="filters"
                class="input-checkbox"
                name="filters"
                type="checkbox"
                :value="$t('account-history.filters.deleted')">
              <label
                class="label"
                for="deleted-checkbox">
                {{ $t('account-history.filters.deleted') }}
              </label>
            </div>

            <div class="col-3">
              <input
                id="error-checkbox"
                v-model="filters"
                class="input-checkbox"
                name="filters"
                type="checkbox"
                :value="$t('account-history.filters.error')">
              <label
                class="label"
                for="error-checkbox">
                {{ $t('account-history.filters.error') }}
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col reset-button-col">
              <tws-borderless-button @click="resetFilters">
                <TeliaCoreIcon
                  class="icon"
                  name="end_user_thin"
                  size="2rem" />
                <tws-reset-filter-icon class="reset-button-icon" />
                {{ $t('common.reset-filter') }}
              </tws-borderless-button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <tws-paginated-table
              :items="filteredHistory"
              :current-page="currentPage"
              :extra-page-count="3"
              :item-count-on-page="10"
              :hoverable="false"
              aria-label="account-history"
              @onPageChange="onPageChange">
              <template #thead>
                <tr>
                  <th>
                    {{ $t('account-history.table-headers.date') }}
                  </th>
                  <th>
                    {{ $t('account-history.table-headers.action') }}
                  </th>
                  <th>
                    {{ $t('account-history.table-headers.action-by') }}
                  </th>
                  <th>
                    {{ $t('account-history.table-headers.message') }}
                  </th>
                </tr>
              </template>

              <template #tr="{ item }">
                <td>{{ item.date }}</td>
                <td>{{ item.action }}</td>
                <td>{{ item.actionBy }}</td>
                <td>{{ item.message }}</td>
              </template>
            </tws-paginated-table>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import XLSX from 'xlsx'

import {
  TwsBorderlessButton,
  TwsBorderlessCustomContentButton,
  InfraPageAlert,
  TwsPaginatedTable
} from 'tws-vue-components'

export default {
  components: {
    TwsBorderlessButton,
    TwsBorderlessCustomContentButton,
    InfraPageAlert,
    TwsPaginatedTable
  },
  data () {
    return {
      showFilters: false,
      filters: []
    }
  },
  computed: {
    ...mapState('accountHistory', [
      'loading',
      'loaded',
      'exception',
      'history',
      'filteredHistory',
      'currentPage'
    ]),
    ...mapState('userDetails', [
      'informationLoading',
      'informationLoaded',
      'informationException',
      'information'
    ])
  },
  watch: {
    filters () {
      this.changeFilters(this.filters)
    }
  },
  methods: {
    ...mapActions('accountHistory', [
      'changeCurrentPage',
      'changeFilters'
    ]),
    toggleFilters () {
      this.showFilters = !this.showFilters
    },
    resetFilters () {
      this.filters = []
    },
    onPageChange (page) {
      this.changeCurrentPage(page)
    },
    generateExportData () {
      const data = [[
        this.$t('account-history.export-headers.date'),
        this.$t('account-history.export-headers.action'),
        this.$t('account-history.export-headers.username'),
        this.$t('account-history.export-headers.business'),
        this.$t('account-history.export-headers.updated-by'),
        this.$t('account-history.export-headers.information')
      ]]

      this.filteredHistory.forEach(item => {
        data.push([
          item.date,
          item.action,
          this.information.username,
          this.information.company,
          item.actionBy,
          item.message
        ])
      })

      return data
    },
    exportResults (extension) {
      const worksheet = XLSX.utils.aoa_to_sheet(this.generateExportData())
      const workbook = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(workbook, worksheet, 'caa')
      XLSX.writeFile(workbook, 'caa.' + extension)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 44px;
  margin-bottom: 108px;
}

.heading-col {
  padding-bottom: 22px;
}

.login-information-row .label {
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1;
}

.login-information-row {
  margin-bottom: 6.2rem;
}

.actions {
  float: right;
  display: flex;

  span, select {
    font-size: 16px;
  }
}

.action {
  margin-right: 25px;
  padding: 0;

  &:last-child {
    margin-right: 0;
  }
}

.export-buttons {
  display: flex;
}

.icon {
  margin-left: 0.7rem;
}

.active-filter-list {
  position: relative;
  display: block;
  border-bottom: 3px solid;
  padding-bottom: 3px;
  margin-bottom: -6px;
}

.export-button {
  background-color: #fff;
  padding: 24px;
  border: none;
  text-align: center;
  font-size: 16px;
  line-height: 1;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

.advanced-filters-wrapper {
  padding-top: 30px;
  padding-bottom: 49px;
}

.filters-heading-col {
  font-weight: bold;
  padding-bottom: 21px;
}

.reset-button-col {
  padding-top: 52px;
}

.reset-button-icon {
  margin-right: 17px;
}
</style>
