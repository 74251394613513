<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="heading-m">
          {{ $t('operational-news.planned.heading') }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="operational-news">
          <tws-loading-information
            :loading="loading"
            :exception="exception"
            :exception-text="$t('operational-news.planned.loading-failed')" />

          <template v-if="loaded">
            <InfraPageAlert
              v-if="news.length === 0"
              type="success">
              {{ $t('operational-news.planned.no-data') }}
            </InfraPageAlert>

            <template v-else>
              <div
                v-for="(data, index) in news"
                :key="index"
                class="operational-new">
                <TeliaCoreIcon
                  name="megaphone_thin"
                  size="3rem" />
                <div class="operational-new-content">
                  <div
                    class="operational-new-title"
                    @click="openNewsItem = data">
                    {{ data.title }}
                  </div>
                  <div class="operational-new-date">
                    {{ computeDate(data) }}
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <tws-operational-news-dialog
      v-if="openNewsItem"
      :news="openNewsItem"
      @close="openNewsItem = null" />
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'
import TwsOperationalNewsDialog from '@/components/operational-news/operational-news-dialog/operational-news-dialog'

import DateTimeUtils from '@/common/datetime-utils'

import {
  InfraPageAlert,
  TwsLoadingInformation
} from 'tws-vue-components'

export default {
  components: {
    InfraPageAlert,
    TwsLoadingInformation,
    TwsOperationalNewsDialog
  },
  data () {
    return {
      openNewsItem: null
    }
  },
  computed: {
    ...mapState('plannedOperationalNews', [
      'loading',
      'loaded',
      'exception',
      'news'
    ])
  },
  mounted () {
    this.fetchNews()
  },
  methods: {
    ...mapActions('plannedOperationalNews', ['fetchNews']),
    computeDate (data) {
      return DateTimeUtils.computeDate(data.startDate, data.endDate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.operational-new {
  display: flex;
}

.operational-new-content {
  margin-left: 10px;
}

.operational-news {
  margin-top: 24px;
  margin-bottom: 13px;
}

.operational-new {
  margin-bottom: 20px;
}

.operational-new-title {
  color: $black;
  cursor: pointer;

  &:hover {
    color: $purple;
  }
}

.operational-news-actions {
  display: flex;
}

.action-button {
  background-color: $light-grey;

  &:not(:last-of-type) {
    margin-right: 12px;
  }
}
</style>
