import i18n from '@/i18n'
import IframeFullScreenView from '@/views/iframes/IframeFullScreenView'

const meta = {
  title: i18n.t('contact-us.chat-with-tws'),
  requiresAuth: true,
  hideGlobalComponents: true
}

const inquiryOrderDeliveryPath = 'tws/contact-us-chat/inquiry-order-delivery/chat-popup-'
const errorReportPath = 'tws/contact-us-chat/error-report/chat-popup-'

export default [
  {
    path: inquiryOrderDeliveryPath + '1',
    meta: meta,
    component: IframeFullScreenView,
    props: {
      src: i18n.t('contact-us.chat-url.inquiry-order-delivery.chat1')
    }
  },
  {
    path: inquiryOrderDeliveryPath + '2',
    meta: meta,
    component: IframeFullScreenView,
    props: {
      src: i18n.t('contact-us.chat-url.inquiry-order-delivery.chat2')
    }
  },
  {
    path: inquiryOrderDeliveryPath + '3',
    meta: meta,
    component: IframeFullScreenView,
    props: {
      src: i18n.t('contact-us.chat-url.inquiry-order-delivery.chat3')
    }
  },
  {
    path: errorReportPath + '1',
    meta: meta,
    component: IframeFullScreenView,
    props: {
      src: i18n.t('contact-us.chat-url.error-report.chat1')
    }
  },
  {
    path: errorReportPath + '2',
    meta: meta,
    component: IframeFullScreenView,
    props: {
      src: i18n.t('contact-us.chat-url.error-report.chat2')
    }
  },
  {
    path: errorReportPath + '3',
    meta: meta,
    component: IframeFullScreenView,
    props: {
      src: i18n.t('contact-us.chat-url.error-report.chat3')
    }
  }
]
