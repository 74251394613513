<template>
  <tws-header-action
    class="header-action--login"
    @click="doLogin">
    <template #icon>
      <TeliaCoreIcon
        class="icon"
        name="end_user_thin"
        size="2.4rem" />
    </template>

    <template #text>
      {{ $t('header.login-button') }}
    </template>
  </tws-header-action>
</template>

<script>
import HeaderAction from '@/components/header/header-action/header-action'
import AuthService from '@/services/auth.service'

export default {
  components: {
    'tws-header-action': HeaderAction
  },
  methods: {
    async doLogin () {
      await AuthService.signinRedirect()
    }
  }
}
</script>
