\<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <generic-banner
        :header="$t('company-creation.banner-header')" />
    </InfraBreadcrumbContainer>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="heading-m">
            {{ $t('company-creation.heading') }}
          </h1>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <tws-company-information-edit
            :information="information"
            :validator="$v.information" />
        </div>
      </div>

      <div class="row creation-buttons-row">
        <div class="col-12">
          <tws-button-primary
            :disabled="creating"
            @click="onCompanyCreate">
            {{ $t('company-creation.submit') }}
          </tws-button-primary>

          <tws-borderless-button
            :disabled="creating"
            @click="onCancelCompanyCreation">
            {{ $t('common.cancel') }}
          </tws-borderless-button>
        </div>
      </div>

      <div
        v-show="exception"
        class="row">
        <div class="col-12">
          <InfraPageAlert
            type="danger">
            {{ $t('company-creation.messages.exception') }}
          </InfraPageAlert>
        </div>
      </div>

      <confirmation-dialog
        v-show="showDialog"
        :heading="$t('company-creation.dialog.heading')"
        :confirmation-button-text="$t('company-creation.dialog.confirm')"
        :cancel-button-text="$t('company-creation.dialog.cancel')"
        @confirm="goToCompanyAdministration"
        @cancel="toggleDialog">
        <template #body>
          {{ $t('company-creation.dialog.content') }}
        </template>
      </confirmation-dialog>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import {
  companyCode,
  orgNumber
} from '@/common/validation-rules'

import CompanyInformationEdit from '@/components/company-administration/company-information-edit.vue'
import GenericBanner from '@/components/common/generic-banner.vue'
import ConfirmationDialog from '@/components/common/confirmation-dialog.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_COMPANY_CREATION } from '@/constants/breadcrumbs-constants'

import {
  TwsBorderlessButton,
  TwsButtonPrimary,
  InfraPageAlert
} from 'tws-vue-components'

export default {
  components: {
    'tws-company-information-edit': CompanyInformationEdit,
    TwsBorderlessButton,
    TwsButtonPrimary,
    InfraPageAlert,
    InfraBreadcrumbContainer,
    GenericBanner,
    ConfirmationDialog
  },
  mixins: [validationMixin],
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_COMPANY_CREATION,
      showDialog: false
    }
  },
  computed: {
    ...mapState('companyCreation', [
      'creating',
      'exception',
      'information'
    ])
  },
  methods: {
    ...mapActions('companyCreation', ['createCompany']),
    toggleDialog () {
      this.showDialog = !this.showDialog
    },
    onCompanyCreate () {
      this.$v.information.$touch()

      if (this.$v.information.$invalid) {
        return
      }

      this.createCompany()
    },
    onCancelCompanyCreation () {
      if (this.$v.information.$anyDirty) {
        this.toggleDialog()
      } else {
        this.goToCompanyAdministration()
      }
    },
    goToCompanyAdministration () {
      this.$router.push({
        name: 'company-administration',
        params: this.$route.params
      })
    }
  },
  validations: {
    information: {
      code: {
        required,
        companyCode
      },
      name: {
        required
      },
      orgNr: {
        required,
        orgNumber
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 45px;
  margin-bottom: 45px;
}

.heading-m {
  margin-bottom: 30px;
}

.creation-buttons-row {
  margin-top: 25px;

  .tws-borderless-button {
    margin-left: 25px;
  }
}

.infra-page-alert--danger {
  margin-top: 10px;
}
</style>
