<template>
  <span
    :class="resolveFileExtensionColor(extension)"
    class="file-extension">
    {{ extension }}
  </span>
</template>

<script>
export default {
  props: {
    extension: {
      type: String,
      required: true
    }
  },
  methods: {
    resolveFileExtensionColor (extension) {
      switch (extension) {
        case 'xls':
        case 'xlsx':
          return 'file-extension--green'
        case 'doc':
        case 'docx':
          return 'file-extension--blue'
        case 'pdf':
          return 'file-extension--red'
        default:
          return 'file-extension--grey'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "tws-core-atoms/variables.scss";

  .file-extension {
    color: $white;
    text-transform: uppercase;
    font-family: $teliaSans;
    font-weight: bold;
    font-size: 15px;
    border-radius: 3px;
    padding: 0px 7px;
    display: inline-block;
    min-width: 4.4rem;
    text-align: center;
  }

  .file-extension--green {
    background-color: #00CC66;
  }

  .file-extension--blue {
    background-color: #0099FF;
  }

  .file-extension--grey {
    background-color: $dark-grey;
  }

  .file-extension--red {
    background-color: $red;
  }
</style>
