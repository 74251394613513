<template>
  <div
    :disabled="disabled"
    class="selection-button__container"
    :class="{ 'selection-button__container--selected': selected }">
    <input
      :id="id"
      :checked="selected"
      :value="value"
      :name="name"
      :type="type"
      :disabled="disabled"
      class="selection-button__input"
      @change="handleChange">
    <slot />
  </div>
</template>

<script>
export default {
  model: {
    event: 'change',
    prop: 'checked'
  },
  props: {
    type: {
      type: String,
      default: () => 'radio'
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    checked: {
      type: String,
      required: true
    },
    id: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected () {
      return this.checked === this.value
    }
  },
  methods: {
    handleChange (event) {
      this.$emit('change', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.selection-button {
  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 22px 32px;
    border: 1px solid #CCCCCC;
    border-radius: 3px;

    position: relative;

    &:hover {
      border-color: $core-purple;
    }

    &--selected {
      border-width: 2px;
      border-color: $core-purple;
      padding: 21px 31px;
    }

    &[disabled=disabled] {
      color: #CCCCCC;
      border-color: #CCCCCC;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
  }
}
</style>
