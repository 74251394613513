const extractHero = (hero) => {
  if (!hero || !hero.fields) {
    return null
  }

  const fields = hero.fields

  return {
    backgroundImage: fields.backgroundImage.fields.file.url,
    heading: fields.heading,
    paragraph: fields.paragraph,
    alignment: fields.alignment,
    verticalAlignment: fields.verticalAlignment
  }
}

const extractNavigationLinks = (navigationLinks) => {
  if (!navigationLinks || !navigationLinks.length) {
    return null
  }

  return navigationLinks.map((navLink) => {
    const fields = navLink.fields

    return {
      title: fields.heading,
      icon: fields.icon.fields.title,
      link: fields.internalLink
    }
  })
}

const extractPageIconsHeading = (pageIcons) => {
  if (!pageIcons || !pageIcons.fields) {
    return null
  }

  return pageIcons.fields.heading
}

const extractPageIcons = (pageIcons) => {
  if (
    !pageIcons ||
    !pageIcons.fields ||
    !pageIcons.fields.icons ||
    !pageIcons.fields.icons.length
  ) {
    return null
  }

  const icons = pageIcons.fields.icons

  return icons.map((icon) => {
    const fields = icon.fields

    return {
      icon: fields.icon.fields.title,
      title: fields.iconText
    }
  })
}

const extractBanners = (banners) => {
  if (!banners || !banners.length) {
    return null
  }

  return banners.map((banner) => {
    const fields = banner.fields

    return {
      imageSide: fields.imageAlignment,
      bannerWidth: fields.width,
      image: fields.image.fields.file.url,
      paragraph: fields.paragraph,
      buttonText: fields.buttonText,
      buttonLink: fields.buttonLink,
      buttonType: fields.buttonType
    }
  })
}

const extractProductTiles = (productTiles) => {
  if (!productTiles || !productTiles.fields) {
    return null
  }

  const fields = productTiles.fields

  const products = fields.solutionProducts.map((product) => {
    const productFields = product.fields

    return {
      title: productFields.heading,
      description: productFields.text,
      path: productFields.url
    }
  })

  return {
    heading: fields.heading,
    items: products
  }
}

const extractFaq = (faq) => {
  if (!faq || !faq.fields) {
    return null
  }

  const fields = faq.fields

  const faqItems = fields.faqItems.map((faqItem) => {
    const faqItemFields = faqItem.fields

    return {
      question: faqItemFields.question,
      answer: faqItemFields.answer
    }
  })

  return {
    heading: fields.heading,
    items: faqItems
  }
}

const extractTextSectionContent = (textSection) => {
  if (!textSection || !textSection.fields) {
    return null
  }

  const fields = textSection.fields

  return {
    background: fields.background,
    mainText: fields.mainText,
    sidebarHeading: fields.sidebarHeading,
    sidebarText: fields.sidebarText,
    sidebarImage: fields.sidebarImage.fields.file.url,
    sidebarBackground: fields.sidebarBackground
  }
}

export default {
  extractContent (response) {
    return {
      hero: extractHero(response.hero),
      navigationLinks: extractNavigationLinks(response.navigationLinks),
      pageIconsHeading: extractPageIconsHeading(response.pageIcons),
      pageIcons: extractPageIcons(response.pageIcons),
      banners: extractBanners(response.pageContentBanners),
      bottomText: response.bottomText,
      textSectionContent: extractTextSectionContent(response.textSection),
      faq: extractFaq(response.faq),
      products: extractProductTiles(response.solutionProductContainer),
      isEnglishAvailable: response.englishIsAvailableMessage
    }
  }
}
