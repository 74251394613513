import ContentfulWrapper from '@/services/contentful/contentful.wrapper'
import { InfraLocaleUtils } from 'tws-vue-components'

export const fetchContent = async (path) => {
  const genericContentPage = await ContentfulWrapper.fetchEntries({
    'content_type': 'genericContentPage',
    locale: InfraLocaleUtils.getSelectedLocale(),
    'fields.slug': path,
    include: 3
  })

  if (genericContentPage.total) {
    return genericContentPage.items && genericContentPage.items[0].fields
  }

  const contentPageAssembly = await ContentfulWrapper.fetchEntries({
    'content_type': 'contentPageAssembly',
    locale: InfraLocaleUtils.getSelectedLocale(),
    'fields.slug': path,
    include: 3
  })

  return contentPageAssembly.items && contentPageAssembly.items[0].fields
}

export default { fetchContent }
