import AddressSearch from './address-search.store'
import PropertySearch from './property-search.store'
import Products from './products.store'
import Content from './content.store'

export const AddressSearchModule = {
  namespaced: true,
  modules: {
    AddressSearch,
    PropertySearch,
    Content,
    Products
  },
  getters: {
    anythingLoading: (_, getters) => getters['AddressSearch/loading'] || getters['Products/isLoading'] || getters['Products/advancedProducts/loading'],
    searchMode: (_, getters) => getters['AddressSearch/searchMode']
  },
  actions: {
    clearProducts ({ dispatch }) {
      dispatch('Products/resetState')
      dispatch('Products/advancedProducts/resetState')
    }
  }
}
