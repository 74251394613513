<template>
  <TwsValidatedTextInputGroup
    :id="id"
    class="address-search-input-field"
    :name="name"
    :label="label"
    :value="value"
    :validator="validator"
    :validation-messages="validationMessages"
    @input="change" />
</template>

<script>
import { TwsValidatedTextInputGroup } from 'tws-vue-components'

export default {
  components: {
    TwsValidatedTextInputGroup
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    validator: {
      type: Object,
      required: true
    },
    validationMessages: {
      type: Object,
      required: true
    }
  },
  methods: {
    change (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss">
@import "tws-core-atoms/variables.scss";

.address-search-input-field input {
  border-radius: 3px;
  border: 1px solid $dark-grey;
}
</style>
