<template>
  <div class="search-page-content">
    <tws-route-query-manager
      :fields="QUERY_FIELDS"
      :values-to-filter="[undefined, null, false]"
      :model="searchModel"
      @queryChange="changeSearchModel" />
    <tws-pebble-background-container
      :grey-background="true"
      class="search-banner">
      <template #breadcrumb>
        <tws-breadcrumb-container
          :grey-background="false"
          :items="computedBreadcrumbItems" />
      </template>

      <template>
        <div class="container">
          <div class="row">
            <div class="col-2">
              <h1 class="heading-l--black title-500">
                {{ $t('site-search.heading') }}
              </h1>
            </div>
            <div class="col-10">
              <div class="form-control search-field">
                <input
                  type="text"
                  class="input-text search-field"
                  :placeholder="$t('site-search.search-placeholder')"
                  :value="searchModel.query"
                  @keyup.enter="handleQuerySubmit">
                <a
                  v-if="searchModel.query"
                  role="button"
                  @click="changeSearchInput(null)">
                  <tws-delete-row-icon
                    class="clear-search-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </tws-pebble-background-container>

    <div class="container">
      <div class="row">
        <div class="col-2 categories">
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="category"
            :class="{ 'active-category': category === searchModel.category }"
            @click="changeCategory(category)">
            {{ $t(CATEGORY_LABEL[category]) }}
          </div>
        </div>
        <div class="col-10">
          <tws-loading-spinner
            v-show="resultsLoading"
            class="results-loading-spinner" />
          <div
            v-show="resultsLoaded"
            class="results-container">
            <div class="result-header">
              <div class="result-title">
                <strong>{{ $tc('site-search.result-count', totalResults) }}</strong> {{ $t('site-search.result-title') }} {{ $t(CATEGORY_LABEL[searchModel.category]) }}
              </div>
              <div class="filter-control-container">
                <!--                FILTERS NOT SUPPORTED YET -->
                <!--                <tws-borderless-button-->
                <!--                  id="filter-toggle"-->
                <!--                  class="result-action color-core-purple"-->
                <!--                  @click="toggleFilters">-->
                <!--                  Filter-->

                <!--                  <tws-arrow-up-icon-->
                <!--                    v-if="showAdditionalFilters"-->
                <!--                    class="icon" />-->

                <!--                  <tws-arrow-down-icon-->
                <!--                    v-if="!showAdditionalFilters"-->
                <!--                    class="icon" />-->
                <!--                </tws-borderless-button>-->
                <tws-borderless-dropdown
                  :items="sortOptions"
                  :default-key="searchModel.sorting"
                  :displayed-value-wrapper="`${$t('common.sort')} ({value})`"
                  class="color-core-purple"
                  @onChange="changeSorting" />
              </div>
            </div>
            <div
              v-if="showAdditionalFilters"
              class="filter-container">
              <div
                class="filter-list">
                <tws-checkbox
                  v-for="filter in FILTERS"
                  :id="filter"
                  :key="filter"
                  class="filter-checkbox"
                  :value="!!searchModel[filter]"
                  :label="$t(FILTER_LABEL[filter])"
                  @input="toggleBoolField(filter)" />
              </div>
              <a
                role="button"
                class="reset-button"
                @click="clearFields(FILTERS)">
                <tws-reset-filter-icon />
                <span>{{ $t('common.reset-filter') }}</span>
              </a>
            </div>
            <div class="result-list">
              <search-result
                v-for="(data, index) in results"
                :key="index"
                :title="data.title"
                :subtitle="data.subtitle"
                :lead-paragraph="data.leadParagraph"
                :thumbnail="data.thumbnail"
                :date="data.publishDate"
                :link="data.link"
                :product="data.product"
                :document-type="data.documentType" />
            </div>
            <tws-pagination
              v-show="showPagination"
              class="search-pagination"
              :current-page="currentPage"
              :extra-page-count="2"
              :item-count-on-page="searchModel.limit"
              :data-count="totalResults"
              @onPageClick="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

import AnalyticsService from '@/services/analytics.service'

import {
  TwsBorderlessDropdown,
  TwsCheckbox,
  TwsPagination,
  TwsLoadingSpinner,
  TwsRouteQueryManager
} from 'tws-vue-components'

import SearchResult from './search-result'

import {
  CATEGORY,
  CATEGORIES,
  CATEGORY_LABEL
} from './constants'

export const FILTERS = [
  'fiber',
  'mobileTelephony',
  'capacity',
  'fixedTelephony',
  'copper',
  'establishment',
  'serviceBidder'
]

export const FILTER_LABEL = {
  fiber: 'site-search.filters.fiber',
  mobileTelephony: 'site-search.filters.mobile-phone',
  capacity: 'site-search.filters.capacity',
  fixedTelephony: 'site-search.filters.fixed-phone',
  copper: 'site-search.filters.copper',
  establishment: 'site-search.filters.establishment',
  serviceBidder: 'site-search.filters.service-bidder'
}

export const SORT_OPTIONS = [
  {
    key: 'RELEVANCE',
    value: 'site-search.sort-labels.relevance'
  },
  {
    key: 'NEWEST',
    value: 'site-search.sort-labels.newest'
  },
  {
    key: 'OLDEST',
    value: 'site-search.sort-labels.oldest'
  },
  {
    key: 'ABC',
    value: 'site-search.sort-labels.abc'
  },
  {
    key: 'ZYX',
    value: 'site-search.sort-labels.zyx'
  }
]

const QUERY_FIELDS = [
  'query',
  'offset',
  'category',
  'limit',
  'sorting',
  ...FILTERS
]

const getCurrentPage = (offset, limit) => {
  if (offset === 0) { return 1 }

  let currentPage = Math.floor(offset / limit) + 1

  if (offset % limit !== 0) {
    currentPage += 1
  }

  return currentPage
}

export default {
  components: {
    'search-result': SearchResult,
    TwsBorderlessDropdown,
    TwsCheckbox,
    TwsPagination,
    TwsLoadingSpinner,
    TwsRouteQueryManager
  },
  props: {
    breadcrumbItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      CATEGORY_LABEL,
      FILTERS,
      FILTER_LABEL,
      QUERY_FIELDS,

      showAdditionalFilters: false,
      trackingEnabled: false
    }
  },
  computed: {
    ...mapState('publicHeader', ['isContentfulLoading']),
    ...mapState('siteSearch', [
      'results',
      'searchModel'
    ]),
    ...mapState('siteSearch', {
      totalResults: 'totalCount',
      resultsLoaded: 'loaded',
      resultsLoading: 'loading'
    }),
    sortOptions () {
      return SORT_OPTIONS.map(option => ({
        ...option,
        value: this.$t(option.value)
      }))
    },
    computedBreadcrumbItems () {
      const computedItems = JSON.parse(JSON.stringify(this.breadcrumbItems))
      computedItems.pop()

      return computedItems
    },
    currentPage () {
      return getCurrentPage(
        this.searchModel.offset,
        this.searchModel.limit
      )
    },
    showPagination () {
      return this.totalResults !== this.results.length
    },
    categories () {
      const categories = CATEGORIES

      categories.push(CATEGORY.DOCUMENTS)

      return categories
    }
  },
  watch: {
    isContentfulLoading (loading) {
      if (!loading) {
        if (this.searchModel && this.searchModel.query) {
          this.loadSearchResults()
        }
      }
    },
    resultsLoaded (loaded) {
      if (this.trackingEnabled) { return }
      this.trackingEnabled = loaded
    },

    searchModel (newModel, prevModel) {
      if (!this.trackingEnabled) {
        return
      }

      if (newModel.query !== prevModel.query || newModel.category !== prevModel.category) {
        AnalyticsService.trackSearch(newModel)
      }
    }
  },
  methods: {
    ...mapActions('siteSearch', [
      'loadSearchResults',
      'clearSearchResults',
      'changeSearchInput',
      'changeSearchModel',
      'changeCategory',
      'changeSorting',
      'toggleBoolField',
      'changeSearchOffset',
      'clearFields'
    ]),
    toggleFilters () {
      this.showAdditionalFilters = !this.showAdditionalFilters
    },
    handlePageChange (nextPage) {
      this.changeSearchOffset((nextPage - 1) * this.searchModel.limit)
    },
    handleQuerySubmit (event) {
      this.changeSearchInput(event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.search-banner {
  padding: 15px 0 30px;
}

.search-field {
  height: 3.5rem;
  width: 90%;
  border-radius: 1.75rem;
  font-size: 22px;
}

.clear-search-icon {
  margin-left: -36px;
  height: 16px;

  path {
    fill: $dark-grey;
  }
}

.result-header {
    justify-content: space-between;
    margin: 45px 0 0;
    display: flex;

  .result-title {
    font-size: 22px;
  }
}

.active-category {
  font-weight: 900;
  color: #000;
}

.categories {
  color: $core-purple;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.category {
  cursor: pointer;
  margin-top: 15px;
}

.filter-control-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .dropdown {
    margin-left: 25px;
  }
}

.filter-container {
  margin: 40px 0 30px;

  .filter-list {
    align-content: flex-start;
    display: flex;
    flex-grow: 0;
    margin-top: 30px;
    max-height: 230px;
    flex-flow: column wrap;
  }

  .filter-checkbox {
    margin-bottom: 16px;
    width: 300px;
  }

  .reset-button {
    margin: 25px 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    :first-child {
      margin-right: 18px;
    }
  }
}

.result-list {
  margin-top: 15px;
}

.search-field {
  input {
    background-color: $white;
    border-radius: 50px;
    height: 50px;
    border-color: $grey;
  }

  i {
    position: absolute;
    top: 17px;
    left: 18px;
  }
}

.search-pagination {
  margin-top: 30px;
}

.results-loading-spinner {
  margin-top: 45px;
  // search-fields width of 90% gets applied twice
  width: 81%;
}
</style>
