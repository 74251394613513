import TicketService from '@/apps/customer-tickets/service/ticket.service'

import constants from '@/apps/customer-tickets/utils/constants'

export default {
  namespaced: true,
  state: {
    response: null,
    loading: false,
    exception: false,
    attachmentsLoading: false,
    attachmentsException: false,
    removeAttachmentLoading: false
  },
  mutations: {
    setResponse: (state, response) => state.response = response,
    addAttachment: (state, attachment) => state.response.attachments.unshift(attachment),
    addAttachments: (state, attachments) => attachments.forEach(attachment => state.response.attachments.unshift(attachment)),
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setAttachmentsLoading: (state, loading) => state.attachmentsLoading = loading,
    setAttachmentsException: (state, exception) => state.attachmentsException = exception,
    setRemoveAttachmentLoading: (state, loading) => state.removeAttachmentLoading = loading,
    removeAttachment: (state, fileKey) => state.response.attachments = state.response.attachments.filter(file => file.fileKey !== fileKey),
    addNote: (state, note) => {
      state.response.notes = [
        note,
        ...state.response.notes
      ]
    },
    setStatus: (state, status) => state.response.status = status
  },
  getters: {
    attachments: (state) => state.response.attachments,
    supportId: (state) => state.response.supportId
  },
  actions: {
    async fetchTicket ({ commit }, supportId) {
      try {
        commit('setResponse', null)
        commit('setLoading', true)
        commit('setException', false)
        commit('setAttachmentsException', false)

        const response = await TicketService.getTicket(supportId)

        if (response['ERROR']) {
          commit('setException', true)
        }

        commit('setResponse', response)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    async putAttachments ({ commit, getters }, files) {
      try {
        commit('setAttachmentsLoading', true)
        commit('setAttachmentsException', false)

        await files.reduce(async (promise, file) => {
          await promise
          const response = await TicketService.putAttachment(getters.supportId, file)

          if (!response) {
            throw new Error('Failed to upload attachment')
          }

          commit('addAttachments', [response])
        }, Promise.resolve())
      } catch {
        commit('setAttachmentsException', true)
      } finally {
        commit('setAttachmentsLoading', false)
      }
    },
    async removeAttachment ({ commit, getters }, fileKey) {
      try {
        commit('setRemoveAttachmentLoading', true)
        commit('setAttachmentsException', false)

        await TicketService.removeAttachment(getters.supportId, fileKey)

        commit('removeAttachment', fileKey)
      } catch {
        commit('setAttachmentsException', true)
      } finally {
        commit('setRemoveAttachmentLoading', false)
      }
    },
    addNote ({ commit, state }, note) {
      commit('addNote', {
        content: note,
        created: new Date().toLocaleString('sv-SE', { timeZone: 'Europe/Stockholm' }),
        createdBy: 'Kund',
        direction: 'In',
        subject: 'Uppdatering från portalen'
      })

      if (state.response.status === constants.STATUSES.CLOSED) {
        commit('setStatus', constants.STATUSES.UPDATED)
      }
    }
  }
}
