<template>
  <div
    :class="labels.backgroundColorClass"
    class="form-text-box-style">
    <tws-rich-text :html="labels.text" />
  </div>
</template>

<script>
export default {
  name: 'TwsFormTextBox',
  props: {
    labels: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-text-box-style {
    padding: 8px 0;
    margin-bottom: 20px;
  }

  .bg-transparent {
    background-color: transparent;
  }

  .bg-grey {
    background-color: #EDEDED;
  }
</style>
