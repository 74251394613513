import {
  FORM_DESCRIPTION_PROPERTIES,
  FORM_TYPE
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import { sendForm } from '@/apps/fault-reporting/services/teliaNOW.service'

export default {
  namespaced: true,
  state: {
    [FORM_DESCRIPTION_PROPERTIES.CASE_ID]: '',
    [FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO]: '',
    [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: [],
    [FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED]: null,
    [FORM_DESCRIPTION_PROPERTIES.COMPLAINT]: '',
    [FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE]: '',
    [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME]: '',
    [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE]: '',
    [FORM_DESCRIPTION_PROPERTIES.PREMISES_NETWORK_CONSENT]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_VALID]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_LOADING]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_ERROR]: false
  },
  mutations: {
    setCaseId: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.CASE_ID] = val,
    setAdditionalInformation: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO] = val,
    setAttachments: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] = val,
    setEntryCode: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE] = val,
    setComplaint: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.COMPLAINT] = val,
    setIsCircuitBeenUsed: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED] = val,
    setLastCircuitOperationalDate: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE] = val,
    setLocalContactName: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME] = val,
    setLocalContactPhone: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE] = val,
    setPremisesNetworkContent: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.PREMISES_NETWORK_CONSENT] = val,
    setIsValid: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_VALID] = val,
    setIsLoading: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_LOADING] = val,
    setIsError: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_ERROR] = val

  },
  actions: {
    updateAdditionalInformation ({ commit }, val) {
      commit('setAdditionalInformation', val)
    },
    updateAttachments ({ commit }, val) {
      commit('setAttachments', val)
    },
    updateEntryCode ({ commit }, val) {
      commit('setEntryCode', val)
    },
    updateComplaint ({ commit }, val) {
      commit('setComplaint', val)
    },
    updateIsCircuitBeenUsed ({ commit }, val) {
      commit('setIsCircuitBeenUsed', val)
      commit('setLastCircuitOperationalDate', '')
    },
    updateLastCircuitOperationalDate ({ commit }, val) {
      commit('setLastCircuitOperationalDate', val)
    },
    updateLocalContactName ({ commit }, val) {
      commit('setLocalContactName', val)
    },
    updateLocalContactPhone ({ commit }, val) {
      commit('setLocalContactPhone', val)
    },
    updatePremisesNetworkContent ({ commit }, val) {
      commit('setPremisesNetworkContent', val)
    },
    updateIsValid ({ commit }, val) {
      commit('setIsValid', val)
    },
    resetState ({ commit }) {
      commit('setCaseId', '')
      commit('setAdditionalInformation', '')
      commit('setAttachments', [])
      commit('setEntryCode', '')
      commit('setComplaint', '')
      commit('setIsCircuitBeenUsed', null)
      commit('setLastCircuitOperationalDate', '')
      commit('setLocalContactName', '')
      commit('setLocalContactPhone', '')
      commit('setPremisesNetworkContent', false)
      commit('setIsValid', false)
      commit('setIsError', false)
    },
    async sendForm ({ state, commit }, basicInfo) {
      const request = {
        ...basicInfo,
        [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.GENERAL,
        [FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO]: state[FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO],
        [FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE]: state[FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE],
        [FORM_DESCRIPTION_PROPERTIES.COMPLAINT]: state[FORM_DESCRIPTION_PROPERTIES.COMPLAINT],
        [FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED]: state[FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED],
        ...state[FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED] &&
        { [FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE]: state[FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE] },
        [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME]: state[FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME],
        [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE]: state[FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE],
        [FORM_DESCRIPTION_PROPERTIES.PREMISES_NETWORK_CONSENT]: state[FORM_DESCRIPTION_PROPERTIES.PREMISES_NETWORK_CONSENT],
        [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]
      }
      try {
        commit('setIsLoading', true)
        commit('setIsError', false)
        const response = await sendForm(request)

        commit('setCaseId', response.id)
      } catch (err) {
        commit('setIsError', true)
      } finally {
        commit('setIsLoading', false)
      }
    }
  }
}
