<template>
  <div class="navigation-container">
    <div class="container">
      <div class="row navigation-row">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="navigation-link-column col-lg-3 col-sm-6 col-xs-12">
          <InfraActionLink
            :link="item.link"
            :icon="item.icon"
            :title="item.title" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfraActionLink } from 'tws-vue-components'

export default {
  components: { InfraActionLink },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.navigation-container {
  background-color: $light-grey;
  padding-top: 30px;
  padding-bottom: 30px;
}

.navigation-row {
  margin: -0.5px;
}

.navigation-link-column {
  padding: 0.5px;
}
</style>
