import {
  mapGetters, mapState
} from 'vuex'

const SecureMenuMixin = {
  data () {
    return {
      pages: {
        dashboard: process.env.VUE_APP_DASHBOARD_PATH,
        addressSearch: process.env.VUE_APP_ADDRESS_SEARCH_PATH,
        mapSearch: process.env.VUE_APP_MAP_SEARCH_PATH,
        areaSearch: process.env.VUE_APP_AREA_SEARCH_PATH,
        connectionNumberSearch: process.env.VUE_APP_CONNECTION_NUMBER_SEARCH_PATH,
        phoneSearch: process.env.VUE_APP_PHONE_SEARCH_PATH,
        userAdministration: process.env.VUE_APP_USER_ADMINISTRATION_PATH,
        userDeletion: process.env.VUE_APP_USER_DELETION_PATH,
        userTransfer: process.env.VUE_APP_USER_TRANSFER_PATH,
        companyAdministration: process.env.VUE_APP_COMPANY_ADMINISTRATION_PATH,
        reportsAdministration: process.env.VUE_APP_REPORTS_ADMINISTRATION_PATH,
        reportsManualUpload: process.env.VUE_APP_REPORTS_MANUAL_UPLOAD_PATH,
        myDocuments: process.env.VUE_APP_MY_DOCUMENTS_PATH,
        myReports: process.env.VUE_APP_MY_REPORTS_PATH,
        myInvoices: process.env.VUE_APP_MY_INVOICES_PATH,
        customerTickets: process.env.VUE_APP_CUSTOMER_TICKETS_PATH,
        felanmalan: process.env.VUE_APP_FELANMALAN_PATH
      }
    }
  },
  computed: {
    ...mapGetters('eamCookies', [
      'isSuperUser',
      'isDelegatedAdmin',
      'canTransferUsers',
      'canCreateCompanies',
      'canAdministrateUsers',
      'canAdministrateReports',
      'canAccessReports',
      'canUseSearchTools',
      'canAccessInvoiceSpecifications',
      'canAccessInvoices',
      'hasAgreementsResponsibleRole',
      'hasUserTicketsRole'
    ]),
    ...mapState('secureHeader', [
      'orders',
      'ordersLoading',
      'ordersLoaded',
      'ordersException',
      'myOrders',
      'myOrdersLoading',
      'myOrdersLoaded',
      'myOrdersException',
      'tickets',
      'ticketsLoading',
      'ticketsLoaded',
      'ticketsException'
    ]),
    canAccessAdministrationSubMenu () {
      return this.isSuperUser || this.canCreateCompanies || this.canAdministrateUsers && !this.isDelegatedAdmin
    },
    canAccessOnlyCompanyAdministrationSubMenu () {
      return !this.isSuperUser && this.canCreateCompanies && !this.canAdministrateUsers
    },
    canAccessReportsInMenu () {
      return this.canAccessReports
    },
    canAccessInvoicesOrSpecifications () {
      return this.canAccessInvoiceSpecifications || this.canAccessInvoices
    }
  },
  methods: {
    constructSearchToolCategories () {
      return [
        {
          title: this.$t('search-categories.address'),
          link: this.pages.addressSearch,
          external: false
        },
        {
          title: this.$t('search-categories.map'),
          link: this.pages.mapSearch,
          external: false
        },
        {
          title: this.$t('search-categories.area'),
          link: this.pages.areaSearch,
          external: false
        },
        {
          title: this.$t('search-categories.connection-number'),
          link: this.pages.connectionNumberSearch,
          external: false
        },
        {
          title: this.$t('search-categories.phone'),
          link: this.pages.phoneSearch,
          external: false
        }
      ]
    }
  }
}

export default SecureMenuMixin
