export const PRODUCT_DOCUMENT_CATEGORY = {
  PRODUCT_INFORMATION: 'product-documents.types.product-info',
  AGREEMENT: 'product-documents.types.agreements',
  MANUAL: 'product-documents.types.manuals',
  ORDER: 'product-documents.types.orders',
  ORDER_AND_MANAGE: 'product-documents.types.order-and-manage',
  OTHER: 'product-documents.types.other',
  REFERENCE: 'product-documents.types.reference',
  SPECIFICATION: 'product-documents.types.specification',
  AGREEMENT_LETTER: 'product-documents.types.agreement-letter'
}
