export const STATES = {
  ALL: 'All',
  NEW: 'New',
  OPEN: 'Open',
  RESOLVED: 'Resolved',
  CLOSED: 'Closed'
}
const STATE_FILTERS_FOR_ACTIVE_CASES = {
  [STATES.ALL]: 'case-list-filter.state.all',
  [STATES.NEW]: 'case-list-filter.state.new',
  [STATES.OPEN]: 'case-list-filter.state.open',
  [STATES.RESOLVED]: 'case-list-filter.state.resolved'
}

export const STATE_FILTERS = {
  ...STATE_FILTERS_FOR_ACTIVE_CASES,
  [STATES.CLOSED]: 'case-list-filter.state.closed'
}

export const getStatefulStateFilters = (isAllCasesRequested) => isAllCasesRequested ? STATE_FILTERS : STATE_FILTERS_FOR_ACTIVE_CASES
