<template>
  <div class="news-view">
    <div
      v-if="item.thumbnail"
      class="news-view-image">
      <img
        :src="item.thumbnail.url"
        :alt="item.thumbnail.url">
    </div>

    <div class="news-view-lead-paragraph">
      {{ item.leadParagraph }}
    </div>

    <InfraContentfulRichTextContainer
      v-if="item.bodyText"
      :content="item.bodyText.json"
      :links="item.bodyText.links"
      class="news-view-text-body" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { InfraContentfulRichTextContainer } from 'tws-vue-components'
export default {
  components: {
    InfraContentfulRichTextContainer
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'eamCookies/isLoggedIn'
    })
  }
}
</script>

<style lang="scss" scoped>
.news-view-lead-paragraph {
  font-size: 22px;
  padding-bottom: 30px;
}

.news-view-text-body {
  padding-bottom: 60px;
}

.news-view-image {
  padding-bottom: 30px;
}

.news-view-image img {
  max-width: 690px;
}

</style>
