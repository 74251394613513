<template>
  <div>
    <GrootHeroBanner
      :title="$t('header.menu-items.my-invoices')"
      :breadcrumbs="breadcrumbItems" />

    <div class="container mt-5">
      <my-documents-company-filter />
      <breadcrumb-tabs-container
        :tabs="tabs"
        :current-uri="currentPath" />
    </div>

    <div id="telia-wholesale-invoices">
      <router-view />
    </div>
  </div>
</template>

<script>
import MyDocumentsCompanyFilter from '@/components/my-documents-company-filter/my-documents-company-filter.vue'
import BreadcrumbTabsContainer from '@/components/common/breadcrumb-tabs-container/breadcrumb-tabs-container.vue'
import { BREADCRUMB_DASHBOARD } from '@/constants/breadcrumbs-constants'
import { GrootHeroBanner } from 'groot-components'
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import { mapGetters } from 'vuex'
import i18n from '@/i18n'

export default {
  components: {
    MyDocumentsCompanyFilter,
    BreadcrumbTabsContainer,
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  computed: {
    ...mapGetters('eamCookies', [
      'canAccessInvoiceSpecifications',
      'canAccessInvoices'
    ]),
    currentPath () {
      return this.$route.path
    },
    breadcrumbItems () {
      return [
        ...BREADCRUMB_DASHBOARD,
        {
          title: i18n.t('header.menu-items.my-invoices'),
          path: this.tabs[0].link
        }
      ]
    },
    tabs () {
      return [
        {
          name: 'invoices',
          title: this.$t('invoices.title'),
          link: `${process.env.VUE_APP_MY_INVOICES_PATH}/invoices`,
          public: false,
          isVisible: this.canAccessInvoices
        },
        {
          name: 'invoice-specifications',
          title: this.$t('invoice-specifications.title'),
          link: `${process.env.VUE_APP_MY_INVOICES_PATH}/invoice-specifications`,
          public: false,
          isVisible: this.canAccessInvoiceSpecifications
        }
      ].filter(tab => tab.isVisible)
    }
  },
  mounted () {
    this.$router.push({ name: this.tabs[0].name })
  }
}
</script>
