import { printAddress } from '@/apps/address-search/utils/string-utils'
import { getCoordinates } from '@/apps/address-search/services/address-search.service'

export default {
  data () {
    return {
      addressCoordinates: {},
      coordinatesLoading: false
    }
  },
  methods: {
    buildMapSearchQuery (coordinates) {
      const { xcoord,
        ycoord,
        pointId } = coordinates

      return `?x=${xcoord}&y=${ycoord}&pointId=${pointId}`
    },
    async openMap (address) {
      const addressKey = printAddress(address)

      if (!this.addressCoordinates[addressKey]) {
        this.coordinatesLoading = true
        this.addressCoordinates[addressKey] = await getCoordinates(address)
        this.coordinatesLoading = false
      }

      window.open(this.mapSearchUrl + this.buildMapSearchQuery(this.addressCoordinates[addressKey]))
    }
  }
}
