import {
  FORM_DESCRIPTION_PROPERTIES,
  FORM_TYPE
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import { sendForm } from '@/apps/fault-reporting/services/teliaNOW.service'

export default {
  namespaced: true,
  state: {
    [FORM_DESCRIPTION_PROPERTIES.CASE_ID]: '',
    [FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO]: '',
    [FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE]: '',
    [FORM_DESCRIPTION_PROPERTIES.COMPLAINT]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED]: null,
    [FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE]: '',
    [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME]: '',
    [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_EQUIPMENT_RESTARTED]: null,
    [FORM_DESCRIPTION_PROPERTIES.IS_LINK_TO_NETADMIN]: null,
    [FORM_DESCRIPTION_PROPERTIES.IS_LINK_IN_CUSTOMER_EQUIPMENT]: null,
    [FORM_DESCRIPTION_PROPERTIES.ACTIVE_LAMPS]: '',
    [FORM_DESCRIPTION_PROPERTIES.IP_ADDRESS]: '',
    [FORM_DESCRIPTION_PROPERTIES.MAC_ADDRESS]: '',
    [FORM_DESCRIPTION_PROPERTIES.BROKEN_POWER_ADAPTER_TYPE]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_CORRECT_PORT_IN_BF]: null,
    [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: [],
    [FORM_DESCRIPTION_PROPERTIES.IS_VALID]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_LOADING]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_ERROR]: false
  },
  mutations: {
    setCaseId: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.CASE_ID] = val,
    setAdditionalInformation: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO] = val,
    setComplaint: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.COMPLAINT] = val,
    setAttachments: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] = val,
    setEntryCode: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE] = val,
    setIsCircuitBeenUsed: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED] = val,
    setLastCircuitOperationalDate: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE] = val,

    setLocalContactName: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME] = val,
    setLocalContactPhone: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE] = val,

    setIsEquipmentRestarted: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_EQUIPMENT_RESTARTED] = val,
    setIsLinkToNetadmin: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_TO_NETADMIN] = val,
    setIsLinkInCustomerEquipment: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_IN_CUSTOMER_EQUIPMENT] = val,
    setActiveLamps: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ACTIVE_LAMPS] = val,
    setIpAddress: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IP_ADDRESS] = val,
    setMACAddress: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.MAC_ADDRESS] = val,

    setBrokenPowerAdapterType: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.BROKEN_POWER_ADAPTER_TYPE] = val,
    setIsCorrectPortInBF: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_CORRECT_PORT_IN_BF] = val,

    setIsValid: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_VALID] = val,
    setIsLoading: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_LOADING] = val,
    setIsError: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_ERROR] = val

  },
  actions: {
    updateAdditionalInformation ({ commit }, val) {
      commit('setAdditionalInformation', val)
    },
    updateComplaint ({ commit }, val) {
      commit('setComplaint', val)
    },
    updateAttachments ({ commit }, val) {
      commit('setAttachments', val)
    },
    updateEntryCode ({ commit }, val) {
      commit('setEntryCode', val)
    },
    updateIsCircuitBeenUsed ({ commit }, val) {
      commit('setIsCircuitBeenUsed', val)
      commit('setLastCircuitOperationalDate', '')
    },
    updateLastCircuitOperationalDate ({ commit }, val) {
      commit('setLastCircuitOperationalDate', val)
    },
    updateLocalContactName ({ commit }, val) {
      commit('setLocalContactName', val)
    },
    updateLocalContactPhone ({ commit }, val) {
      commit('setLocalContactPhone', val)
    },
    updateIsEquipmentRestarted ({ commit }, val) {
      commit('setIsEquipmentRestarted', val)
    },
    updateIsLinkToNetadmin ({ commit }, val) {
      commit('setIsLinkToNetadmin', val)

      commit('setBrokenPowerAdapterType', '')
      commit('setActiveLamps', '')
      commit('setIpAddress', '')
      commit('setIsCorrectPortInBF', null)
    },
    updateIsLinkInCustomerEquipment ({ commit }, val) {
      commit('setIsLinkInCustomerEquipment', val)

      commit('setActiveLamps', '')
      commit('setIpAddress', '')
      commit('setBrokenPowerAdapterType', '')
      commit('setIsCorrectPortInBF', null)
    },
    updateActiveLamps ({ commit }, val) {
      commit('setActiveLamps', val)
    },
    updateIpAddress ({ commit }, val) {
      commit('setIpAddress', val)
    },
    updateMACAddress ({ commit }, val) {
      commit('setMACAddress', val)
    },
    updateBrokenPowerAdapterType ({ commit }, val) {
      commit('setBrokenPowerAdapterType', val)
    },
    updateIsCorrectPortInBF ({ commit }, val) {
      commit('setIsCorrectPortInBF', val)
    },
    updateIsValid ({ commit }, val) {
      commit('setIsValid', val)
    },
    resetState ({ commit }) {
      commit('setCaseId', '')
      commit('setAdditionalInformation', '')
      commit('setAttachments', [])
      commit('setEntryCode', '')
      commit('setIsCircuitBeenUsed', null)
      commit('setLastCircuitOperationalDate', '')
      commit('setLocalContactName', '')
      commit('setLocalContactPhone', '')
      commit('setIsEquipmentRestarted', null)
      commit('setIsLinkToNetadmin', null)
      commit('setIsLinkInCustomerEquipment', null)
      commit('setActiveLamps', '')
      commit('setIpAddress', '')
      commit('setMACAddress', '')
      commit('setBrokenPowerAdapterType', '')
      commit('setIsCorrectPortInBF', null)
      commit('setIsValid', false)
      commit('setIsError', false)
      commit('setComplaint', '')
    },
    async sendForm ({ state, commit }, baseInfo) {
      const request = {
        ...baseInfo,
        [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.OPEN_FIBER,
        [FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO]: state[FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO],
        [FORM_DESCRIPTION_PROPERTIES.COMPLAINT]: state[FORM_DESCRIPTION_PROPERTIES.COMPLAINT],
        [FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE]: state[FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE],
        [FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED]: state[FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED],
        ...state[FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED] &&
        { [FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE]: state[FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE] },
        [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME]: state[FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME],
        [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE]: state[FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE],
        [FORM_DESCRIPTION_PROPERTIES.IS_EQUIPMENT_RESTARTED]: state[FORM_DESCRIPTION_PROPERTIES.IS_EQUIPMENT_RESTARTED],
        [FORM_DESCRIPTION_PROPERTIES.IS_LINK_TO_NETADMIN]: state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_TO_NETADMIN],
        [FORM_DESCRIPTION_PROPERTIES.IS_LINK_IN_CUSTOMER_EQUIPMENT]: state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_IN_CUSTOMER_EQUIPMENT],
        ...!state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_TO_NETADMIN] && !state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_IN_CUSTOMER_EQUIPMENT] && {
          [FORM_DESCRIPTION_PROPERTIES.BROKEN_POWER_ADAPTER_TYPE]: state[FORM_DESCRIPTION_PROPERTIES.BROKEN_POWER_ADAPTER_TYPE]
        },
        ...!state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_TO_NETADMIN] && state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_IN_CUSTOMER_EQUIPMENT] && {
          [FORM_DESCRIPTION_PROPERTIES.IS_CORRECT_PORT_IN_BF]: state[FORM_DESCRIPTION_PROPERTIES.IS_CORRECT_PORT_IN_BF]
        },
        ...state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_TO_NETADMIN] && state[FORM_DESCRIPTION_PROPERTIES.IS_LINK_IN_CUSTOMER_EQUIPMENT] && {
          [FORM_DESCRIPTION_PROPERTIES.ACTIVE_LAMPS]: state[FORM_DESCRIPTION_PROPERTIES.ACTIVE_LAMPS],
          [FORM_DESCRIPTION_PROPERTIES.IP_ADDRESS]: state[FORM_DESCRIPTION_PROPERTIES.IP_ADDRESS]
        },
        [FORM_DESCRIPTION_PROPERTIES.MAC_ADDRESS]: state[FORM_DESCRIPTION_PROPERTIES.MAC_ADDRESS],
        [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]
      }
      try {
        commit('setIsLoading', true)
        commit('setIsError', false)
        const response = await sendForm(request)

        commit('setCaseId', response.id)
      } catch (err) {
        commit('setIsError', true)
      } finally {
        commit('setIsLoading', false)
      }
    }
  }
}
