import TicketService from '@/apps/customer-tickets/service/ticket.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    exception: false
  },
  mutations: {
    setLoading: (state, loading) => state.loading = loading,
    setLoaded: (state, loaded) => state.loaded = loaded,
    setException: (state, exception) => state.exception = exception
  },
  actions: {
    async postNote ({ commit, dispatch }, payload) {
      try {
        commit('setLoading', true)
        commit('setLoaded', false)
        commit('setException', false)

        await TicketService.postNote(payload.supportId, payload.request)

        dispatch('CustomerTickets/TicketDetails/addNote', payload.request.content, { root: true })

        commit('setLoaded', true)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    resetState ({ commit }) {
      commit('setLoading', false)
      commit('setLoaded', false)
      commit('setException', false)
    }
  }
}
