<template>
  <div class="row fault-report-general-case">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <p class="title-200">
            {{ $t('fault-reporting.form.description.description') }}
          </p>
        </div>
      </div>
      <div class="row fieldset">
        <div class="col-xl-5 col-sm-6 col-xs-12">
          <HorizontalRadioButtons
            :id="'complaint'"
            v-model="complaintProxy"
            :validation-messages="validationMessages.complaint"
            :validator="$v.complaint"
            :tooltip-text="$t('fault-reporting.form.description.complaint.tooltip')"
            :options="complaintOptions"
            :label="$t('fault-reporting.form.description.complaint.label')" />
        </div>
      </div>
      <hr class="divider">

      <div class="row">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="additionalInfo"
            v-model="additionalInformationProxy"
            :validator="$v.additionalInformation"
            :validation-messages="validationMessages.additionalInformation"
            :label="$t('fault-reporting.form.description.additional-information.label')" />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <InfraFilePicker
            v-model="attachmentsProxy"
            class="mb-5"
            button-type="primary"
            :acceptable-file-extensions="acceptableFileExtensions"
            :limit="attachmentsLimit"
            :file-size-limit="defaultFileSizeLimit"
            :show-add-icon="true"
            :add-file-text="$t('fault-reporting.form.attachments-button.button-text')"
            :limit-is-reached-text="$t('fault-reporting.form.attachments-button.limit-is-reached-text').replace('limit', attachmentsLimit)"
            :size-limit-text="$t('fault-reporting.form.attachments-button.size-is-reached-text')" />
        </div>
      </div>
      <hr class="divider">
      <div class="row">
        <div class=" col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-text-input-group
            id="entryCode"
            v-model="entryCodeProxy"
            :tooltip-width="tooltipWidth"
            :tooltip-text="$t('fault-reporting.form.description.entrycode.tooltip')"
            :validator="$v.entryCode"
            :validation-messages="validationMessages.entryCode"
            :label="$t('fault-reporting.form.description.entrycode.label')" />
        </div>
      </div>
      <hr class="divider">

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            :id="'isCircuitBeenUsed'"
            v-model="isCircuitBeenUsedProxy"
            :validation-messages="validationMessages.isCircuitBeenUsed"
            :validator="$v.isCircuitBeenUsed"
            :label="$t('fault-reporting.form.description.circuit-been-used.label')" />
        </div>
        <div
          v-if="isCircuitBeenUsedProxy"
          class="col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-date-picker
            id="faultTime"
            v-model="lastCircuitOperationalDateProxy"
            :show-icon="true"
            :read-only="true"
            :validator="$v.lastCircuitOperationalDate"
            :validation-messages="validationMessages.lastCircuitOperationalDate"
            :options="datePickerOptions"
            :label="$t('fault-reporting.form.description.circuit-operational-date.label')"
            required />
        </div>
      </div>
      <hr class="divider">

      <div class="row fieldset">
        <div class=" col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-text-input-group
            id="localContactName"
            v-model="localContactNameProxy"
            :validator="$v.localContactName"
            :validation-messages="validationMessages.localContactName"
            :label="$t('fault-reporting.form.description.local-contact-name.label')" />
        </div>
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-text-input-group
            id="localContactPhone"
            v-model="localContactPhoneProxy"
            :validator="$v.localContactPhone"
            :descriptive-text="$t('fault-reporting.form.description.local-contact-phone.descriptive-text')"
            :validation-messages="validationMessages.localContactPhone"
            :label="$t('fault-reporting.form.description.local-contact-phone.label')" />
        </div>
      </div>
      <hr class="divider">

      <tws-checkbox
        id="premisesNetworkContent"
        v-model="premisesNetworkContentProxy"
        :label="$t('fault-reporting.form.description.premises-network-content.label')" />
    </div>
  </div>
</template>

<script>
import {
  TwsValidatedTextAreaGroup,
  TwsValidatedTextInputGroup,
  TwsValidatedDatePicker,
  TwsCheckbox,
  InfraFilePicker
} from 'tws-vue-components'
import HorizontalRadioButtons from '@/apps/fault-reporting/components/form/HorizontalRadioButtons'
import {
  mapState, mapActions
} from 'vuex'
import {
  required, requiredIf, maxLength
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  ATTACHMENTS_LIMIT,
  DATE_PICKER_OPTIONS,
  COMPLAINT_OPTIONS,
  ATTACHMENTS_FILE_SIZE_LIMIT,
  ATTACHMENTS_ALLOWED_EXTENSIONS,
  TOOLTIP_WIDTH
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import { phoneNumberValidator } from '@/apps/fault-reporting/utils/validation.utils'

export default {
  components: {
    HorizontalRadioButtons,
    TwsCheckbox,
    TwsValidatedDatePicker,
    TwsValidatedTextAreaGroup,
    TwsValidatedTextInputGroup,
    InfraFilePicker
  },
  mixins: [validationMixin],
  data () {
    return {
      tooltipWidth: TOOLTIP_WIDTH,
      attachmentsLimit: ATTACHMENTS_LIMIT,
      datePickerOptions: DATE_PICKER_OPTIONS,
      complaintOptions: COMPLAINT_OPTIONS,
      acceptableFileExtensions: ATTACHMENTS_ALLOWED_EXTENSIONS,
      defaultFileSizeLimit: ATTACHMENTS_FILE_SIZE_LIMIT,
      validationMessages: {
        additionalInformation: {
          required: this.$t('fault-reporting.form.description.additional-information.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.additional-information.validation-message-max-length')
        },
        entryCode: {
          required: this.$t('fault-reporting.form.description.entrycode.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.entrycode.validation-message-max-length')
        },
        complaint: {
          required: this.$t('fault-reporting.form.description.complaint.validation-message')
        },
        isCircuitBeenUsed: {
          required: this.$t('fault-reporting.form.description.circuit-been-used.validation-message')
        },
        lastCircuitOperationalDate: {
          required: this.$t('fault-reporting.form.description.circuit-operational-date.validation-message')
        },
        localContactName: {
          required: this.$t('fault-reporting.form.description.local-contact-name.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.local-contact-name.validation-message-max-length')
        },
        localContactPhone: {
          required: this.$t('fault-reporting.form.description.local-contact-phone.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.local-contact-phone.validation-message-max-length'),
          phone: this.$t('fault-reporting.form.description.local-contact-phone.validation-message-phone')
        }
      }
    }
  },
  computed: {
    ...mapState('FaultReporting/FaultReportForm/GeneralCase', [
      'additionalInformation',
      'attachments',
      'entryCode',
      'complaint',
      'isCircuitBeenUsed',
      'lastCircuitOperationalDate',
      'localContactName',
      'localContactPhone',
      'premisesNetworkContent',
      'isValid'
    ]),
    additionalInformationProxy: {
      get () {
        return this.additionalInformation
      },
      set (value) {
        this.updateAdditionalInformation(value)
      }
    },
    attachmentsProxy: {
      get () {
        return this.attachments
      },
      set (value) {
        this.updateAttachments(value)
      }
    },
    entryCodeProxy: {
      get () {
        return this.entryCode
      },
      set (value) {
        this.updateEntryCode(value)
      }
    },
    complaintProxy: {
      get () {
        return this.complaint
      },
      set (value) {
        this.updateComplaint(value)
      }
    },
    isCircuitBeenUsedProxy: {
      get () {
        return this.isCircuitBeenUsed
      },
      set (value) {
        this.updateIsCircuitBeenUsed(value)
      }
    },
    lastCircuitOperationalDateProxy: {
      get () {
        return this.lastCircuitOperationalDate
      },
      set (value) {
        this.updateLastCircuitOperationalDate(value)
      }
    },
    localContactNameProxy: {
      get () {
        return this.localContactName
      },
      set (value) {
        this.updateLocalContactName(value)
      }
    },
    localContactPhoneProxy: {
      get () {
        return this.localContactPhone
      },
      set (value) {
        this.updateLocalContactPhone(value)
      }
    },
    premisesNetworkContentProxy: {
      get () {
        return this.premisesNetworkContent
      },
      set (value) {
        this.updatePremisesNetworkContent(value)
      }
    },
    isValidProxy () {
      return !this.$v.$invalid
    }
  },
  validations: {
    additionalInformation: {
      required,
      maxLength: maxLength(1000)
    },
    entryCode: {
      required,
      maxLength: maxLength(50)
    },
    complaint: {
      required
    },
    isCircuitBeenUsed: {
      required
    },
    lastCircuitOperationalDate: {
      required: requiredIf((form) => form.isCircuitBeenUsedProxy)
    },
    localContactName: {
      required,
      maxLength: maxLength(50)
    },
    localContactPhone: {
      required,
      maxLength: maxLength(50),
      phone: phoneNumberValidator
    }
  },
  watch: {
    isValidProxy (val) {
      this.updateIsValid(val)
    }
  },
  methods: {
    ...mapActions('FaultReporting/FaultReportForm/GeneralCase', [
      'updateAdditionalInformation',
      'updateAttachments',
      'updateEntryCode',
      'updateIsCircuitBeenUsed',
      'updateLastCircuitOperationalDate',
      'updateLocalContactName',
      'updateLocalContactPhone',
      'updatePremisesNetworkContent',
      'updateIsValid',
      'updateComplaint'
    ])
  }
}
</script>
