<template>
  <div>
    <Stepper
      :route-index="index"
      :routes="routes"
      @close="handleClose"
      @complete="pickAddress"
      @navigate="navigate" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Stepper from '@/apps/address-search/components/Stepper'
import PropertySearchForm from './PropertySearchForm'
import PropertyPicker from './PropertyPicker.vue'
import PropertyAddressPicker from './PropertyAddressPicker.vue'

const routes = [
  { component: PropertySearchForm },
  { component: PropertyPicker },
  { component: PropertyAddressPicker }
]

const PROPERTY_SEARCH_FORM_INDEX = 0
const PROPERTY_PICKER_INDEX = 1
const PROPERTY_ADDRESS_PICKER_INDEX = 2

export default {
  components: {
    Stepper
  },
  data () {
    return {
      routes,
      index: PROPERTY_SEARCH_FORM_INDEX
    }
  },
  computed: {
    ...mapGetters('AddressSearch/PropertySearch', ['results'])
  },
  watch: {
    results ({ propertyBlocks, addresses }) {
      switch (this.index) {
        case PROPERTY_SEARCH_FORM_INDEX: {
          if (propertyBlocks && propertyBlocks.length > 0) {
            this.index = PROPERTY_PICKER_INDEX
          }
          if (addresses && addresses.length > 0) {
            this.index = PROPERTY_ADDRESS_PICKER_INDEX
          }
          break
        }
        case PROPERTY_PICKER_INDEX: {
          if (addresses && addresses.length > 0) {
            this.index = PROPERTY_ADDRESS_PICKER_INDEX
          }
          break
        }
      }
    }
  },
  methods: {
    navigate (next) {
      const returningFromAddressPicker = this.index === PROPERTY_ADDRESS_PICKER_INDEX && next === PROPERTY_PICKER_INDEX

      if (returningFromAddressPicker && this.results.propertyBlocks.length === 0) {
        this.index = PROPERTY_SEARCH_FORM_INDEX
        return
      }

      this.index = next
    },
    handleClose () {
      this.$emit('close')
    },
    pickAddress (address) {
      this.$emit('address-pick', address)
      this.$store.dispatch('AddressSearch/PropertySearch/resetState')
    }
  }
}
</script>
