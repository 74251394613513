<script>
import PurpurCtaLink from '../purpur/PurpurCtaLink.vue'
import PurpurHeading from '../purpur/PurpurHeading.vue'
import NewsPreviewItem from './NewsPreviewItem.vue'

export default {
  components: {
    PurpurCtaLink,
    PurpurHeading,
    NewsPreviewItem
  },
  props: {
    articles: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    cta: {
      type: Object,
      default: null
    }
  }
}
</script>

<template>
  <article class="news-preview-block container">
    <PurpurHeading
      tag="h2"
      variant="title-400"
      class="news-preview-block__title">
      {{ title }}
    </PurpurHeading>

    <section class="news-preview-block__news">
      <NewsPreviewItem
        v-for="(article, index) in articles"
        :key="article.id"
        :index="index"
        :publish-date="article.publishDate"
        :title="article.title"
        :lead-paragraph="article.leadParagraph"
        :link-url="article.linkUrl" />
    </section>

    <PurpurCtaLink
      v-if="cta"
      class="news-preview-block__cta"
      :href="cta.url"
      size="md"
      variant="primary">
      {{ $t("public-landing.common.more_news") }}
    </PurpurCtaLink>
  </article>
</template>

<style scoped lang="scss">
@use "@purpurds/tokens/breakpoint/variables" as breakpoints;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;
@use '@purpurds/tokens/color/variables' as colors;

.news-preview-block {
  display: flex;
  flex-wrap: wrap;
  gap: spacing.$purpur-spacing-400;
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (min-width: breakpoints.$purpur-breakpoint-md) {
    padding-top: spacing.$purpur-spacing-800;
    padding-bottom: 80px;
  }

  &__news {
    display: grid;
    gap: spacing.$purpur-spacing-400;
    width: 100%;

    @media screen and (min-width: breakpoints.$purpur-breakpoint-md) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: breakpoints.$purpur-breakpoint-lg) {
      column-gap: spacing.$purpur-spacing-800;
    }
  }
}

::v-deep .news-preview-block__cta a {
  &:hover {
    color: colors.$purpur-color-functional-white
  }
}

::v-deep .news-preview-block__title span {
  width: 100%;
}

::v-deep .news-preview-block__icon span {
  width: 1.4rem;
}
</style>
