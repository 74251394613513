<template>
  <div class="secured-header-feedback">
    <LocalisedLink
      :to="link"
      class="secured-header-feedback__button">
      <TeliaCoreIcon
        class="secured-header-feedback__icon"
        name="player_settings_thin"
        size="2.7rem" />
      <span class="secured-header-feedback__text">
        {{ $t('header.feedback-text') }}
      </span>
    </LocalisedLink>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

$spacer: .8em;

.secured-header-feedback {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__button {
    position: absolute;
    bottom: 0;
    padding: 1rem 2.5rem;
    background: #00D066;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
  }

  &__text {
    color: $white;
    font-weight: bold;
    letter-spacing: 0.03em;
  }

  &__icon {
    box-sizing: content-box;
    color: $white;
    padding-right: $spacer;
    margin-right: $spacer;
    border-right: 1px solid $white;
  }
}
</style>
