<template>
  <div class="page-icons-container">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="page-icons-container__heading heading-m">
            {{ heading }}
          </h2>
        </div>
      </div>
      <InfraPageIcons :items="items" />
    </div>
  </div>
</template>

<script>
import { InfraPageIcons } from 'tws-vue-components'

export default {
  components: { InfraPageIcons },
  props: {
    heading: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-icons-container {
  margin-top: 55px;

  &__heading {
    margin-bottom: 35px;
    text-align: center;
    font-size: 24px;
  }
}
</style>
