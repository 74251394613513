<template>
  <CommonPageView
    :banner="banner"
    :exception="false"
    :breadcrumb-items="[]"
    :is-grey-banner-background="false"
    :loading="false">
    <template #content>
      <div class="fault-report-form__navigation mt-5">
        <button
          type="button"
          class="button button--primary"
          @click="openFaultReport">
          {{ $t('fault-reporting.thank-you-page.button-text.open-errand') }}
        </button>
        <button
          type="button"
          class="button button--secondary ml-3"
          @click="goToFaultReporting">
          {{ $t('fault-reporting.thank-you-page.button-text.start-page') }}
        </button>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import { RouteName } from '@/apps/fault-reporting/router/routing-items'
import CommonPageView from '@/views/CommonPageView'
import i18n from '@/i18n'
import { mapState } from 'vuex'
export default {
  components: {
    CommonPageView
  },
  props: {
    caseId: {
      type: String,
      required: true
    }
  },
  computed: {
    computedDescription () {
      return this.$t('fault-reporting.thank-you-page.banner.description').replace('caseId', this.caseId)
    },
    ...mapState('FaultReporting/NewFaultReportContent', ['content']),
    banner () {
      return {
        heading: i18n.t('fault-reporting.thank-you-page.banner.heading'),
        description: this.computedDescription
      }
    }
  },
  methods: {
    openFaultReport () {
      this.$router.push({
        name: RouteName.FaultReport,
        params: {
          id: this.caseId,
          locale: this.$route.params.locale
        }
      })
    },
    goToFaultReporting () {
      this.$router.push({
        name: RouteName.FaultReporting,
        params: this.$route.params
      })
    }
  }
}
</script>
