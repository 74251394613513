<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="heading-m">
          {{ $t('company-history.heading') }}
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <label class="label">
          {{ $t('company-history.created') }}
        </label>

        <p>
          {{ formattedDate(information.created, information.createdBy) }}
        </p>
      </div>

      <div class="col-3">
        <label class="label">
          {{ $t('company-history.changed') }}
        </label>

        <p>
          {{ formattedDate(information.modified, information.modifiedBy) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimeUtils from '@/common/datetime-utils'

export default {
  props: {
    information: {
      type: Object,
      required: true
    }
  },
  methods: {
    formattedDate (datetime, user) {
      const date = DateTimeUtils.formatCustom(datetime, 'yyyy-MM-dd')
      const time = DateTimeUtils.formatCustom(datetime, 'HH:mm')
      return `${date} ${this.$t('common.at')} ${time} ${this.$t('common.of')} ${user}`
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 45px;
  margin-bottom: 45px;
}

.heading-m {
  padding-bottom: 30px;
}

.label {
  font-weight: bold;
}

p {
  margin-bottom: 0;
}
</style>
