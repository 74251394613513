<template>
  <div>
    <div class="row mt-4">
      <div class="col-12 d-flex flex-row justify-content-between">
        <span
          class="side-box"
          :class="{
            'side-box--purple': side === 'A',
            'side-box--orange': side === 'B',
          }">
          {{ $t('general.side') }} {{ side }}
        </span>
        <tws-tooltip
          v-if="tooltipContent"
          :rich-text="tooltipContent"
          width="40rem"
          class="mr-4"
          arrow-position="right" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <h3 class="heading-s address">
          {{ title }}
        </h3>
      </div>
    </div>
    <div
      v-if="pointData"
      class="row mt-4 pb-5 access-point-details">
      <div class="col-12">
        <div class="row mt-1">
          <div class="col-3">
            <span class="access-point-details__title">
              {{ $t('products-display.advanced-wholesale-fiber.connection-point') }}
            </span>
          </div>
          <div class="col-lg-6 col-9">
            {{ pointData.accessPoint ? pointData.accessPoint.name : $t('products-display.advanced-wholesale-fiber.no-connection') }}
          </div>
        </div>
        <div
          v-if="pointData.accessPoint"
          class="row mt-1">
          <div class="col-3">
            <span class="access-point-details__title">
              {{ $t('products-display.advanced-wholesale-fiber.directions') }}
            </span>
          </div>
          <div class="col-lg-6 col-9">
            {{ getAccessPointAddress() }}
            <br v-if="pointData.accessPoint.address">
            {{ pointData.accessPoint.walkway }}
          </div>
        </div>
        <div
          v-if="!hideDelivery"
          class="row mt-1">
          <div class="col-3">
            <span class="access-point-details__title">
              {{ $t('products-display.advanced-wholesale-fiber.delivery-time.shortest') }}
            </span>
          </div>
          <div class="col-lg-6 col-9 access-point-details__delivery-time">
            <template v-if="manualQuote">
              {{ $t('products-display.advanced-wholesale-fiber.manual-quote') }}
            </template>
            <template v-else>
              {{ deliveryTime }} {{ $t('products-display.advanced-wholesale-fiber.delivery-time.days') }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="siteData"
      class="row mt-4 pb-5">
      <div class="col-12">
        <div class="row mt-1">
          <div class="col-3">
            <span class="access-point-details__title">
              {{ $t('general.city') }}
            </span>
          </div>
          <div class="col-lg-6 col-9">
            {{ siteData.city }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TwsTooltip } from 'tws-vue-components'
import { printAddress } from '@/apps/address-search/utils/string-utils'

export default {
  components: { TwsTooltip },
  props: {
    side: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    tooltipContent: {
      type: Object,
      default: () => {}
    },
    pointData: {
      type: Object,
      default: null
    },
    siteData: {
      type: Object,
      default: null
    },
    hideDelivery: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    deliveryTime () {
      const deliveryTimes = this.pointData.offers.map(offer => offer.deliveryTime).sort()
      if (deliveryTimes[0] === -1) { return -1 }

      if (deliveryTimes[0] === deliveryTimes[deliveryTimes.length - 1]) {
        return deliveryTimes[0]
      }

      return `${deliveryTimes[0]} ${this.$t('products-display.advanced-wholesale-fiber.delivery-time.to')} ${deliveryTimes[deliveryTimes.length - 1]}`
    },
    manualQuote () {
      return this.deliveryTime === -1
    }
  },
  methods: {
    getAccessPointAddress () {
      if (this.pointData.accessPoint && this.pointData.accessPoint.address) {
        const { city, streetname, streetnumber, entrance, zipcode } = this.pointData.accessPoint.address

        return `${this.$t('products-display.advanced-wholesale-fiber.access-point-address')}: ${printAddress({
          city,
          streetName: streetname,
          streetNumber: streetnumber,
          entrance,
          postNumber: zipcode
        })}`
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.access-point-details__title {
  font-weight: bold;
}

.side-box {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 3px;
  color: $white;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.56px;
  text-transform: uppercase;

  &--purple {
    background-color: $core-purple;
  }

  &--orange {
    background-color: #DD8804;
  }
}

.address {
  font-weight: bold;
  text-transform: capitalize;
}
</style>
