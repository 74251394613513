<template>
  <div
    class="sub-menu-item"
    :class="{
      'sub-menu-item--expanded': item.expanded,
      'sub-menu-item--expandable': item.expandable
    }">
    <div
      class="sub-menu-item__heading"
      role="button"
      @click="item.expanded = !item.expanded">
      <div class="sub-menu-item__title">
        <template v-if="item.expandable">
          {{ getItemTitle(item.title) }}
        </template>

        <LocalisedLink
          v-else-if="item.link"
          :to="item.link"
          class="sub-menu-item__link">
          {{ getItemTitle(item.title) }}
          <tws-new-window-icon
            v-if="item.external"
            class="sub-menu-item__link__external-link-icon" />
        </LocalisedLink>
      </div>

      <div
        v-if="item.expandable"
        class="sub-menu-item__icon">
        <tws-cross-icon v-show="item.expanded" />

        <tws-arrow-down-icon
          v-show="!item.expanded"
          height="18"
          width="18"
          :view-box.camel="'0 0 16 16'" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import { getTranslationValue } from '@/utils/translation.utils'

import TwsNewWindowIcon from '@/img/svg/new-window.svg'
import TwsArrowDownIcon from '@/img/svg/arrow-down.svg'
import TwsCrossIcon from '@/img/svg/cross.svg'

export default {
  components: {
    TwsNewWindowIcon,
    TwsArrowDownIcon,
    TwsCrossIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    getItemTitle (title) {
      return getTranslationValue(title)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.sub-menu-item {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 18px;
  padding-bottom: 18px;

  &--expanded {
    background-color: #EAEAEA;

    .sub-menu-item__heading {
      font-weight: bold;
      margin-bottom: 18px;
    }
  }

  &--expandable {
    .sub-menu-item__heading {
      display: flex;
    }

    .sub-menu-item__title {
      flex-grow: 1;
    }
  }

  &__link {
    display: block;

    &__external-link-icon {
      margin-left: 5px;
    }
  }

  &__icon svg path {
    fill: $black;
  }
}
</style>
