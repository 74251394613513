<template>
  <InfraPageAlert
    class="form-alert"
    type="info">
    <tws-rich-text
      class="form-alert--text"
      :html="removeParagraphTags(labels.text)"
      @click.native="onAlertClick()" />
  </InfraPageAlert>
</template>

<script>
import {
  InfraPageAlert, InfraLocaleUtils
} from 'tws-vue-components'

export default {
  name: 'TwsFormAlert',
  components: { InfraPageAlert },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  methods: {
    onAlertClick () {
      if (this.labels.isLanguageToggle) {
        InfraLocaleUtils.changeLocale()
      }
    },
    removeParagraphTags (text) {
      return text.replace(/<[p/]+>/g, '')
    }
  }
}
</script>
<style lang="scss" scoped>

.form-alert {
  margin-bottom: 5rem;

  &--text {
    width: fit-content;
  }
}
</style>
