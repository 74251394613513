import {
  setTokenData, requiresToRefreshToken, signOut
} from '@/common/auth-utils'
import {
  EVENT_KEY_SESSION_TIMEOUT,
  LOCAL_KEY_REFRESH_TOKEN
} from '@/common/auth-constants'
import { createInstance } from '@/services/axios.api'

export const api = createInstance(
  process.env.VUE_APP_USER_ADMIN_SERVICE_PIAM)

async function callRefreshToken (refreshToken) {
  const config = {
    params: {
      refresh_token: refreshToken
    }
  }

  try {
    const response = await api.get('/external/token/refresh', config)

    await setTokenData(response)
  } catch (error) {
    signOut()
    window.dispatchEvent(new Event(EVENT_KEY_SESSION_TIMEOUT))
  }
}

export async function checkAndRefreshToken () {
  const refreshToken = localStorage.getItem(LOCAL_KEY_REFRESH_TOKEN)

  if (refreshToken && requiresToRefreshToken()) {
    await callRefreshToken(refreshToken)
  }
}
