<template>
  <div class="email-column col-xl-4 col-md-6 col-sm-12">
    <div class="email">
      <div class="email__title preamble">
        {{ title }}
      </div>

      <div
        v-if="subTitle"
        class="email__sub-title">
        {{ subTitle }}
      </div>

      <a
        class="email__link"
        :href="'mailto:' + email">
        <TeliaCoreIcon
          name="email"
          class="email__link-icon"
          size="16px" />
        <span>{{ email }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: null
    },
    email: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.email {
  &-column {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__link {
    margin-top: 20px;
    display: flex;
    align-items: center;

    &:hover {
      color: $core-purple;
    }

    &:active,
    &:visited {
      color: $dark-core-purple;
    }

    &-icon {
      margin-right: 10px;
      color: $core-purple;
    }
  }
}
</style>
