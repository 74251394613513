<template>
  <CommonPageView
    :banner="banner"
    :exception="false"
    :breadcrumb-items="[]"
    :is-grey-banner-background="false"
    :loading="false">
    <template #content>
      <div class="fault-report-form grey-background">
        <FaultReportBasicInfo
          ref="basicInfoRef"
          :selected-product="selectedProduct" />

        <template v-if="isBaseInfoValid">
          <Component
            :is="faultDescriptionComponent" />
        </template>
      </div>

      <div class="fault-report-form__navigation mt-5">
        <button
          :disabled="!isFormValid(faultReportFormProperties.VUEX_NAMESPACE)"
          type="button"
          class="button button--primary mr-3"
          @click="goToSummary">
          {{ $t('common.next') }}
        </button>

        <button
          type="button"
          class="button button--secondary"
          @click="onCancelClick">
          {{ $t('common.cancel') }}
        </button>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import CommonPageView from '@/views/CommonPageView'
import FaultReportBasicInfo from '@/apps/fault-reporting/components/report-form/FaultReportBasicInfo'
import FaultReportGeneralCaseDescription from '@/apps/fault-reporting/components/report-form/general-case/GeneralCaseDescription'
import FaultReportInterconnectCaseDescription from '@/apps/fault-reporting/components/report-form/interconnect/InterconnectCaseDescription'
import FaultReportOpenFiberCaseDescription from '@/apps/fault-reporting/components/report-form/open-fiber/OpenFiberCaseDescription'
import FaultReportGeneralOrIntermittentProblemOrSlowSpeedCaseDescription from '@/apps/fault-reporting/components/report-form/mobilesp/GeneralOrIntermittentProblemOrSlowSpeedCaseDescription'
import FaultReportProblemAbroadCaseDescription from '@/apps/fault-reporting/components/report-form/mobilesp/ProblemAbroadCaseDescription'
import FaultReportSmsMmsCaseDescription from '@/apps/fault-reporting/components/report-form/mobilesp/SmsMmsCaseDescription'
import FaultReportVoicemailOrProblemWithProvisioningOrMobileNumberPortabilityCaseDescription from '@/apps/fault-reporting/components/report-form/mobilesp/VoicemailOrProblemWithProvisioningOrMobileNumberPortabilityCaseDescription'
import FaultReportIncidentVoiceAndMobileNetworkAllCaseDescriptions from '@/apps/fault-reporting/components/report-form/mobilesp/IncidentVoiceAndMobileNetworkAllCaseDescriptions'
import FaultReportAddAndRemoveBarringIMEICaseDescription from '@/apps/fault-reporting/components/report-form/mobilesp/AddAndRemoveBarringIMEICaseDescription'
import {
  FORM_TYPE, PRODUCT_CATEGORY_ITEMS
  , FAULT_CATEGORY_DROPDOWN_OPTION, PROBLEM_TYPE_DROPDOWN_OPTION
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import {
  mapGetters, mapState, mapActions
} from 'vuex'
import { RouteName } from '@/apps/fault-reporting/router/routing-items'
import { preventNav } from '@/apps/fault-reporting/utils/navigation.utils'
import i18n from '@/i18n'

export default {
  components: {
    CommonPageView,
    FaultReportBasicInfo,
    FaultReportGeneralCaseDescription,
    FaultReportGeneralOrIntermittentProblemOrSlowSpeedCaseDescription,
    FaultReportAddAndRemoveBarringIMEICaseDescription
  },

  data () {
    return {
      faultDescriptionComponent: null
    }
  },

  computed: {
    ...mapGetters({
      isFormValid: 'FaultReporting/FaultReportForm/FormValidation/IsFormValid',
      isBaseInfoValid: 'FaultReporting/FaultReportForm/FormValidation/IsBasicInfoValid'
    }),
    ...mapState('FaultReporting/NewFaultReportContent', ['content']),
    ...mapState('FaultReporting/C2bCache', {
      selectedProduct: 'selectedProduct'
    }),
    ...mapState('FaultReporting/FaultReportForm/BasicInfo', [
      'problemType',
      'faultCategory'
    ]),
    faultReportFormProperties () {
      return PRODUCT_CATEGORY_ITEMS[this.selectedProduct.categoryId]
    },
    isMobileSP () {
      return this.faultReportFormProperties.FORM_TYPE === FORM_TYPE.MOBILESP
    },
    getFaultCategoriesOptions () {
      return PRODUCT_CATEGORY_ITEMS[this.selectedProduct.categoryId].FAULT_CATEGORY_OPTIONS
    },
    banner () {
      return {
        heading: i18n.t('fault-reporting.new-fault'),
        description: this.isMobileSP ? i18n.t('fault-reporting.sub-title') : this.selectedProduct.categoryName
      }
    }
  },

  watch: {
    problemType (option) {
      if (this.isMobileSP) {
        this.resetState(option)
        this.switchMobileSPForm(option)
      }
    }
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.preventNav)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.preventNav)
  },

  created () {
    switch (this.faultReportFormProperties.FORM_TYPE) {
      case FORM_TYPE.GENERAL:
        this.faultDescriptionComponent = FaultReportGeneralCaseDescription
        break
      case FORM_TYPE.OPEN_FIBER:
        this.faultDescriptionComponent = FaultReportOpenFiberCaseDescription
        break
      case FORM_TYPE.INTERCONNECT:
        this.faultDescriptionComponent = FaultReportInterconnectCaseDescription
        break
      case FORM_TYPE.MOBILESP:
        this.switchMobileSPForm(this.problemType)
        break
    }
  },

  methods: {
    ...mapActions('FaultReporting/FaultReportForm/MobileSP', ['resetState']),
    goToSummary () {
      this.$router.push({
        name: RouteName.NewFaultReportSummary,
        params: this.$route.params
      })
    },
    onCancelClick () {
      this.$router.push({
        name: RouteName.NewFaultReport,
        params: this.$route.params
      })
    },
    preventNav,
    switchMobileSPForm (problemType) {
      switch (this.faultCategory?.value) {
        case FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_MOBILE_DATA.value: {
          const isProblemAbroadSelected = problemType?.value === PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_ABROAD.value
          const isGeneralProblemWithMobileDataSelected = problemType?.value === PROBLEM_TYPE_DROPDOWN_OPTION.GENERAL_PROBLEM_MOBILE_DATA.value
          const isIntermittentProblemSelected = problemType?.value === PROBLEM_TYPE_DROPDOWN_OPTION.INTERMITTENT_PROBLEM.value
          const isSlowSpeedSelected = problemType?.value === PROBLEM_TYPE_DROPDOWN_OPTION.SLOW_SPEED.value

          if (isProblemAbroadSelected) {
            this.faultDescriptionComponent = FaultReportProblemAbroadCaseDescription
          }

          if (isGeneralProblemWithMobileDataSelected || isIntermittentProblemSelected || isSlowSpeedSelected) {
            this.faultDescriptionComponent = FaultReportGeneralOrIntermittentProblemOrSlowSpeedCaseDescription
          }

          break
        }

        case FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_PRODUCTS_MOBILE_NUMBER_PORTING.value: {
          const isSmsMmsSelected = problemType?.value === PROBLEM_TYPE_DROPDOWN_OPTION.SMS_MMS.value
          const isVoicemailSelected = problemType?.value === PROBLEM_TYPE_DROPDOWN_OPTION.VOICEMAIL.value
          const isProblemWithProvisioningSelected = problemType?.value === PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_PROVISIONING.value
          const isMobileNumberPortabilitySelected = problemType?.value === PROBLEM_TYPE_DROPDOWN_OPTION.MOBILE_NUMBER_PORTABILITY.value

          if (isSmsMmsSelected) {
            this.faultDescriptionComponent = FaultReportSmsMmsCaseDescription
          }

          if (isVoicemailSelected || isProblemWithProvisioningSelected || isMobileNumberPortabilitySelected) {
            this.faultDescriptionComponent = FaultReportVoicemailOrProblemWithProvisioningOrMobileNumberPortabilityCaseDescription
          }

          break
        }

        case FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_VOICE_MOBILE_NETWORK.value: {
          this.faultDescriptionComponent = FaultReportIncidentVoiceAndMobileNetworkAllCaseDescriptions

          break
        }

        case FAULT_CATEGORY_DROPDOWN_OPTION.IMEI_BARRING.value: {
          this.faultDescriptionComponent = FaultReportAddAndRemoveBarringIMEICaseDescription

          break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.fault-report-form {
  padding-bottom: 40px;
}
</style>
