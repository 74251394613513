<template>
  <div>
    <tws-table
      class="invoice-table"
      :items="items">
      <template #thead>
        <tr class="header">
          <th colspan="3">
            {{ $t('invoice-specifications.table-headers.specification') }}
          </th>
          <th>{{ $t('invoice-specifications.table-headers.date') }}</th>
          <th>{{ $t('invoice-specifications.table-headers.type') }}</th>
          <th>{{ $t('invoice-specifications.table-headers.period-start') }}</th>
          <th>{{ $t('invoice-specifications.table-headers.period-end') }}</th>
          <th>{{ $t('invoice-specifications.table-headers.invoice') }}</th>
        </tr>
      </template>

      <template #tr="{ item }">
        <td colspan="3">
          <a
            :href="getDownloadLink(item)"
            class="invoice-specification-link">
            <div>
              <tws-file-extension-icon :extension="item.contentType" />
            </div>
            <div class="document-number">{{ item.id }}</div>
          </a>
        </td>
        <td class="fit-width">
          {{ item.invoiceDate }}
        </td>
        <td>{{ $t(getTypeLabel(item.type)) }}</td>
        <td class="fit-width">
          {{ item.invoicePeriodStart }}
        </td>
        <td class="fit-width">
          {{ item.invoicePeriodEnd }}
        </td>
        <td>{{ item.invoiceNumber === '0' ? '' : item.invoiceNumber }}</td>
      </template>
    </tws-table>
    <tws-pagination
      v-if="totalCount > limit"
      :aria-label="ariaLabel"
      :current-page="currentPage"
      :extra-page-count="5"
      :item-count-on-page="limit"
      :data-count="totalCount"
      @onPageClick="changePage" />
  </div>
</template>

<script>
import {
  TwsTable, TwsPagination
} from 'tws-vue-components'

import TwsFileExtensionIcon from '@/components/common/file-extension-icon.vue'

import {
  INVOICE_SPECIFICATION_TYPE_LABEL,
  INVOICE_SPECIFICATION_TYPE_GROUP_LABEL
} from '@/constants/invoice-specification-types.js'

export default {
  components: {
    TwsTable,
    TwsPagination,
    TwsFileExtensionIcon
  },
  props: {
    ariaLabel: {
      type: String,
      default: null
    },
    totalCount: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    offset: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    currentPage () {
      return Math.floor(this.offset / this.limit) + 1
    }
  },
  methods: {
    changePage (page) {
      this.$emit('onOffsetChange', (page - 1) * this.limit)
    },
    getDownloadLink (item) {
      return `${process.env.VUE_APP_BILLING_SYSTEM_INTEGRATION_API}/specifications/contents/${item.id}`
    },
    getTypeLabel (type) {
      if (!type) {
        return ''
      }

      return INVOICE_SPECIFICATION_TYPE_LABEL[type] || INVOICE_SPECIFICATION_TYPE_GROUP_LABEL.OTHER
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.invoice-table {
  table-layout: auto !important;

  .document-number {
    margin-left: 15px;
    font-weight: bold;
  }

  .invoice-specification-link {
    &:hover {
      color: $core-purple;
    }

    display: flex;
  }

  .fit-width {
    width:1%;
    white-space:nowrap;
  }

  .header {
    white-space: nowrap;
  }
}
</style>
