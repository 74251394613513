<script>
import PurpurCtaLink from '../purpur/PurpurCtaLink.vue'
import PurpurHeading from '../purpur/PurpurHeading.vue'
import PurpurParagraph from '../purpur/PurpurParagraph.vue'
import { createMultipleSizeImages } from './utils'
import { CardVariant } from '@/apps/public-landing/components/card/variant'

export default {
  components: {
    PurpurCtaLink,
    PurpurHeading,
    PurpurParagraph
  },
  props: {
    title: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      default: null
    },
    paragraph: {
      type: String,
      default: null
    },
    altText: {
      type: String,
      default: null
    },
    buttonText: {
      type: String,
      default: null
    },
    buttonLink: {
      type: String,
      default: null
    },
    cardVariant: {
      type: String,
      default: CardVariant.REGULAR
    }
  },
  computed: {
    image () {
      return createMultipleSizeImages(
        this.imgSrc,
        this.altText,
        this.getImageDimensions()
      )
    }
  },
  methods: {
    getImageDimensions () {
      switch (this.cardVariant) {
        case CardVariant.REGULAR: {
          return {
            small: [
              '3:4',
              600
            ],
            medium: [
              '5:4',
              500
            ],
            large: [
              '27:10',
              400
            ]
          }
        }
        default: {
          return {
            small: [
              '3:4',
              750
            ],
            medium: [
              '5:4',
              400
            ],
            large: [
              '5:4',
              500
            ]
          }
        }
      }
    }
  }
}
</script>

<template>
  <div :class="`card card--${cardVariant}`">
    <picture v-if="imgSrc">
      <source
        media="(max-width:599px)"
        :srcset="image.small">
      <source
        media="(max-width:1023px)"
        :srcset="image.medium">
      <img
        class="card__image"
        :src="image.large"
        :alt="altText">
    </picture>
    <div :class="`card__content-wrapper card__content-wrapper--${cardVariant}`">
      <PurpurHeading
        tag="h1"
        variant="title-300"
        class="card__title">
        {{ title }}
      </PurpurHeading>

      <PurpurParagraph
        v-if="paragraph"
        variant="preamble-100"
        class="card__paragraph">
        {{ paragraph }}
      </PurpurParagraph>

      <div
        v-if="buttonLink"
        class="card__cta">
        <PurpurCtaLink
          :href="buttonLink"
          size="md"
          variant="secondary-negative">
          {{ buttonText }}
        </PurpurCtaLink>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@purpurds/tokens/typography/variables' as typography;
@use '@purpurds/tokens/breakpoint/variables' as breakpoints;
@use '@purpurds/tokens/color/variables' as colors;
@use '../../assets/scss/mixins' as mixins;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;

$card-shading: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0) 35.21%,
  rgba(0, 0, 0, 0.7) 67.19%,
  #000000 100%
);
$landscape-aspect-ratio: calc(27 / 10);
$classic-tv-aspect-ratio: calc(5 / 4);
$small-portrait-aspect-ratio: 0.65;

.card {
  position: relative;
  border-radius: 8px;
  color: white;
  padding: 32px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  &__cta {
    z-index: 2;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      background: $card-shading;
      display: block;
      height: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      border-radius: 8px;
      z-index: 1;
    }

    &--regular {
      width: 100%;

      @media screen and (min-width: breakpoints.$purpur-breakpoint-md) {
        width: 95%;
      }

      @media screen and (min-width: 770px) {
        width: 75%;
      }

      @media screen and (min-width: breakpoints.$purpur-breakpoint-lg) {
        width: 55%;
      }
    }
  }

  &--regular {
    aspect-ratio: $small-portrait-aspect-ratio;
    width: auto;

    @media screen and (min-width: breakpoints.$purpur-breakpoint-md) {
      aspect-ratio: $classic-tv-aspect-ratio;
    }

    @media screen and (min-width: breakpoints.$purpur-breakpoint-lg) {
      aspect-ratio: $landscape-aspect-ratio;
    }
  }
}

::v-deep.card__title span {
  position: relative;
  z-index: 2;
  margin-bottom: spacing.$purpur-spacing-100;
  color: colors.$purpur-color-functional-white;
}

::v-deep.card__cta a {
  &:hover {
    background-color: colors.$purpur-color-purple-100;
    color: colors.$purpur-color-purple-900;
  }
}

::v-deep.card__paragraph span {
  position: relative;
  z-index: 2;
  margin-bottom: spacing.$purpur-spacing-400;
  color: colors.$purpur-color-functional-white;

  @include mixins.line-clamp(5);

  @media screen and (min-width: breakpoints.$purpur-breakpoint-md) {
    @include mixins.line-clamp(3);
  }
}
</style>
