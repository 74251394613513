<template>
  <CommonPageView
    :banner="banner"
    :exception="false"
    :breadcrumb-items="[]"
    :is-grey-banner-background="false"
    :loading="false">
    <template #content>
      <div class="fault-report-summary">
        <div class="row">
          <div class="col-12">
            <h1>{{ $t('fault-reporting.summary.heading') }}</h1>
          </div>
        </div>
        <ReportSummary
          class="form-background"
          :summary-state="currentFormState"
          :customer-ref-number="customerRefNumber"
          :problem-type="problemType"
          :fault-category="faultCategory" />
      </div>

      <div class="notifications grey-background">
        <div class="row">
          <div class="col-12">
            <h2>
              {{ $t('fault-reporting.summary.notifications.heading') }}
            </h2>
          </div>
        </div>
        <div
          v-for="(email, index) in emailInputList"
          :key="index"
          class="row notification-input">
          <div class="col-xl-5 col-sm-5 col-xs-12">
            <tws-validated-text-input-group
              :id="`email-${index}`"
              :ref="`emailRef${index}`"
              v-model="email.value"
              :validator="$v.emailInputListProxy.$each.$iter[index].value"
              :validation-messages="validationMessages.emailInputListProxy"
              :placeholder="$t('fault-reporting.summary.notifications.placeholder')"
              :label="getEmailInputFieldLabel(index)" />
          </div>
          <div class="col-1 notification-input__trash-icon">
            <TeliaCoreIcon
              v-if="index !== 0"
              class="notification-input__trash-icon__icon"
              name="trash_empty_thin"
              size="20px"
              @click.native="removeAdditionalEmailField(index)" />
          </div>
        </div>
        <div class="row">
          <div class=" col-xl-5 col-sm-5 col-xs-12">
            <button
              type="button"
              :disabled="isAddAdditionalEmailButtonDisabled"
              class="button button--primary add-email-button"
              @click="addAdditionalEmailField">
              <TeliaCoreIcon
                class="add-email-button__icon"
                name="add_thin"
                size="20px" />
              <span>{{ $t('fault-reporting.summary.notifications.button-text') }}</span>
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="isError"
        class="row mt-5">
        <div class="col-lg-5 col-md-6 col-sm-6">
          <InfraPageAlert
            type="danger">
            {{ $t('common.state.error') }}
          </InfraPageAlert>
        </div>
      </div>

      <div class="fault-report-form__navigation mt-5">
        <TwsButtonStateful
          :disabled="!isCurrentFormValid"
          :in-progress="isLoading"
          type="button"
          class="button button--primary"
          :text="$t('common.send')"
          @click="sendForm" />
        <button
          type="button"
          class="button button--secondary ml-3"
          @click="backToForm">
          {{ $t('common.back') }}
        </button>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import CommonPageView from '@/views/CommonPageView'
import ReportSummary from '@/apps/fault-reporting/components/report-summary/ReportSummary'
import {
  TwsValidatedTextInputGroup, TwsButtonStateful, InfraPageAlert
} from 'tws-vue-components'
import TeliaCoreIcon from '@telia/core-icons'
import i18n from '@/i18n'

import {
  mapState, mapActions, mapGetters
} from 'vuex'
import { email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { RouteName } from '@/apps/fault-reporting/router/routing-items'
import { preventNav } from '@/apps/fault-reporting/utils/navigation.utils'
import { PRODUCT_CATEGORY_ITEMS } from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  components: {
    CommonPageView,
    TwsValidatedTextInputGroup,
    TeliaCoreIcon,
    TwsButtonStateful,
    InfraPageAlert,
    ReportSummary
  },
  mixins: [validationMixin],
  data () {
    return {
      emailInputListLimit: 5,
      validationMessages: {
        emailInputListProxy: {
          email: this.$t('fault-reporting.summary.notifications.validation-message')
        }
      }
    }
  },
  validations: {
    emailInputListProxy: {
      $each: {
        value: {
          email
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isFormValid: 'FaultReporting/FaultReportForm/FormValidation/IsFormValid'
    }),
    ...mapState('FaultReporting/NewFaultReportContent', ['content']),
    ...mapGetters('FaultReporting/FormGlobal', ['stateByNamespace']),
    ...mapState('FaultReporting/C2bCache', {
      selectedProduct: 'selectedProduct'
    }),
    ...mapState('FaultReporting/FaultReportForm/Notifications', {
      emailInputList: 'emailInputList'
    }),
    ...mapState('FaultReporting/FaultReportForm/BasicInfo', {
      customerRefNumber: 'customerRefNumber',
      faultCategory: 'faultCategory',
      problemType: 'problemType'
    }),
    emailInputListProxy: {
      get () {
        return this.emailInputList
      },
      set (value) {
        this.updateEmailInputList(value)
      }
    },
    currentFormState () {
      return this.stateByNamespace(PRODUCT_CATEGORY_ITEMS[this.selectedProduct.categoryId].VUEX_NAMESPACE)
    },
    isLoading () {
      return this.currentFormState.isLoading
    },
    isError () {
      return this.currentFormState.isError
    },
    isAddAdditionalEmailButtonDisabled () {
      return this.emailInputListProxy.length >= this.emailInputListLimit
    },
    currentNamespace () {
      return PRODUCT_CATEGORY_ITEMS[this.selectedProduct.categoryId].VUEX_NAMESPACE
    },
    isCurrentFormValid () {
      return this.isFormValid(this.currentNamespace) && !this.$v.emailInputListProxy.$invalid
    },
    banner () {
      return {
        heading: i18n.t('fault-reporting.new-fault'),
        description: this.selectedProduct.categoryName
      }
    }
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.preventNav)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.preventNav)
  },
  methods: {
    ...mapActions('FaultReporting/FormGlobal', [
      'resetGlobalFormsState',
      'sendFormByNamespace'
    ]),
    async sendForm () {
      if (this.isCurrentFormValid) {
        await this.sendFormByNamespace(this.currentNamespace)

        if (!this.isError) {
          this.$router.push({
            name: RouteName.ThankYouPage,
            params: {
              caseId: this.currentFormState.caseId,
              locale: this.$route.params.locale
            }
          })
          this.resetGlobalFormsState()
        }
      }
    },
    backToForm () {
      this.$router.push({
        name: RouteName.NewFaultReportForm,
        params: {
          locale: this.$route.params.locale
        }
      })
    },
    getEmailInputFieldLabel (index) {
      return index === 0 ? this.$t('fault-reporting.summary.notifications.input-label') : ''
    },
    ...mapActions('FaultReporting/FaultReportForm/Notifications', {
      updateEmailInputList: 'updateEmailInputList'
    }),
    addAdditionalEmailField () {
      this.emailInputListProxy.push({
        value: ''
      })
    },
    removeAdditionalEmailField (index) {
      this.emailInputListProxy.splice(index, 1)
    },
    preventNav
  }
}
</script>
<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
.notifications {
  padding-top: 30px;
  padding-bottom: 60px;
}
.add-email-button {
  display: flex;
  place-items: center;

  &__icon {
    margin-right: 10px;

  }
}
.notification-input {
  align-items: center;

  &__trash-icon {
    padding-bottom: 15px;

    &__icon {
      margin-right: 20px;
      cursor: pointer;
      color: $core-purple;
      line-height: 0;
    }
  }
}

.fault-report-summary {
  margin-bottom: 40px;
}
</style>
