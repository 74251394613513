import CategoriesView from '@/apps/contact-us/views/CategoriesView.vue'
import ItemsView from '@/apps/contact-us/views/ItemsView.vue'
import SubCategoriesView from '@/apps/contact-us/views/SubCategoriesView.vue'

export default {
  path: 'kontakt',
  name: 'contact',
  component: CategoriesView,
  children: [{
    path: ':category',
    component: SubCategoriesView,
    children: [{
      path: ':subCategory',
      component: ItemsView
    }]
  }]
}
