<template>
  <div class="py-5">
    <AddressSearchForm
      @property-search-request-a="openPropertySearchA"
      @property-search-request-b="openPropertySearchB"
      @search="handleSearch" />

    <InfraModal
      :display="showAddressPicker"
      @close="closeAddressPicker">
      <AddressPickerContainer
        :advanced-search="advancedSearch"
        :addresses-a="sortedAddressesA"
        :addresses-b="sortedAddressesB"
        @address-pick-a="handleAddressPickA"
        @address-pick-b="handleAddressPickB"
        @close="closeAddressPicker" />
    </InfraModal>

    <InfraModal
      :display="showPropertySearch"
      @close="closePropertySearch">
      <PropertySearch
        @address-pick="handleAddressPick"
        @close="closePropertySearch" />
    </InfraModal>

    <InfraPageAlert
      v-if="displayAlert"
      :class="`address-search-alert--${alertType}`"
      :type="alertType">
      {{ alertText }}
    </InfraPageAlert>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import {
  InfraModal,
  InfraPageAlert
} from 'tws-vue-components'
import AddressSearchForm from './AddressSearchForm'
import AddressPickerContainer from '@/apps/address-search/components/AddressPicker/AddressPickerContainer'
import { SEARCH_MODE } from '@/apps/address-search/utils/constants.utils'
import PropertySearch from '@/apps/address-search/components/PropertySearch'

export default {
  components: {
    AddressSearchForm,
    AddressPickerContainer,
    InfraModal,
    PropertySearch,
    InfraPageAlert
  },
  data () {
    return {
      isAddressBeingRefined: false,
      showPropertySearch: false,
      propertySearchPoint: null
    }
  },
  computed: {
    ...mapGetters('AddressSearch/AddressSearch', [
      'sortedAddresses',
      'loaded',
      'error',
      'exceptionA',
      'exceptionB',
      'searchMode',
      'nodeQueryA',
      'nodeQueryB',
      'searchPoints'
    ]),
    ...mapGetters('AddressSearch/Content', ['errorMessages']),
    advancedSearch () { return this.searchMode !== SEARCH_MODE.ADDRESS },
    sortedAddressesA () { return this.sortedAddresses.sortedAddressesA },
    sortedAddressesB () { return this.sortedAddresses.sortedAddressesB },
    showAddressPicker () {
      return this.loaded && this.isAddressBeingRefined && (this.sortedAddressesA.length > 1 || this.sortedAddressesB.length > 1)
    },
    displayAlert () {
      return this.error || this.exceptionA || this.exceptionB
    },
    alertType () {
      return this.error ? 'danger' : 'warning'
    },
    alertText () {
      if (this.error) { return this.errorMessages['contact.customer.support'] }

      if (this.exceptionA === 'node.not.found' || this.exceptionA === 'nodes.not.found') {
        return this.errorMessages[this.exceptionA]
      }

      if (this.searchMode === SEARCH_MODE.ADDRESS) {
        return `${this.errorMessages[this.exceptionA]} ${this.errorMessages['contact.customer.support']}`
      }

      let str = ''
      if (this.exceptionA === this.exceptionB && this.exceptionA !== null) {
        str += this.errorMessages[this.exceptionA + '.both'] + ' '
      } else {
        str += this.exceptionA ? this.errorMessages[this.exceptionA + '.a'] + ' ' : ''
        str += this.exceptionB ? this.errorMessages[this.exceptionB + '.b'] + ' ' : ''
      }
      str += this.errorMessages['contact.customer.support']

      return str
    }
  },
  watch: {
    sortedAddresses (addresses) {
      if (this.showPropertySearch) { return }

      const { sortedAddressesA, sortedAddressesB } = addresses

      if (sortedAddressesA.length !== 1) { return }

      if (this.searchMode === SEARCH_MODE.ADDRESS) {
        this.finishAddressSearch()
        return
      }

      if (this.searchMode === SEARCH_MODE.ADDRESS_ADDRESS && sortedAddressesB.length === 1) {
        this.finishAddressAddressSearch()
        return
      }

      if (this.searchMode === SEARCH_MODE.ADDRESS_NODE) {
        this.finishAddressNodeSearch()
      }
    }
  },
  methods: {
    ...mapActions('AddressSearch/AddressSearch', [
      'fetchAddresses',
      'setSelectedAddress'
    ]),
    requestOpenAddressPicker () {
      this.isAddressBeingRefined = true
    },
    closeAddressPicker () {
      this.isAddressBeingRefined = false
    },
    openPropertySearchA () {
      this.showPropertySearch = true
      this.propertySearchPoint = 'A'
    },
    openPropertySearchB () {
      this.showPropertySearch = true
      this.propertySearchPoint = 'B'
    },
    closePropertySearch () {
      this.showPropertySearch = false
      this.propertySearchPoint = null
    },
    handleSearch () {
      if (this.searchMode === SEARCH_MODE.NODE_NODE) {
        this.$emit('select-node-node', [
          this.nodeQueryA,
          this.nodeQueryB
        ])
        return
      }

      this.requestOpenAddressPicker()
      this.fetchAddresses()
    },
    finishAddressSearch () {
      this.$emit('select-address', this.sortedAddressesA[0])
      this.closeAddressPicker()
    },
    finishAddressAddressSearch () {
      this.$emit('select-address-address', [
        this.sortedAddressesA[0],
        this.sortedAddressesB[0]
      ])
      this.closeAddressPicker()
    },
    finishAddressNodeSearch () {
      this.$emit('select-address-node', [
        this.sortedAddressesA[0],
        this.nodeQueryB
      ])
      this.closeAddressPicker()
    },
    async handleAddressPick (payload) {
      if (this.propertySearchPoint === 'A') {
        this.handleAddressPickA(payload)
      } else if (this.propertySearchPoint === 'B') {
        this.handleAddressPickB(payload)
      }
      await this.$nextTick()
      this.closePropertySearch()
    },
    handleAddressPickA (payload) {
      this.setSelectedAddress({
        address: payload,
        point: 'A'
      })
    },
    handleAddressPickB (payload) {
      this.setSelectedAddress({
        address: payload,
        point: 'B'
      })
    }
  }
}
</script>
