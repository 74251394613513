<template>
  <div>
    <GrootHeroBanner
      :title="$t('header.menu-items.my-reports')"
      :breadcrumbs="breadcrumbItems" />

    <div class="container mt-5">
      <my-documents-company-filter />
    </div>

    <div class="container">
      <div
        v-if="exception"
        class="row mt-40 justify-content-center">
        <div class="col-8">
          <InfraPageAlert
            class="page-alert"
            type="danger">
            {{ $t('reports.errors.default') }}
          </InfraPageAlert>
        </div>
      </div>

      <template v-else>
        <template v-if="reports.length > 0">
          <div class="row">
            <div class="col">
              <div class="result-actions mt-40">
                <TwsBorderlessButton
                  class="result-action color-core-purple"
                  us
                  @click="showFilters = !showFilters">
                  {{ showFilters ? $t('common.close-filter') : $t('common.filter') }}

                  <TeliaCoreIcon
                    v-show="showFilters"
                    name="arrow_up"
                    size="16px" />

                  <TeliaCoreIcon
                    v-show="!showFilters"
                    name="arrow_down"
                    size="16px" />

                  <span :class="{ 'active-filter-list': showFilters }" />
                </TwsBorderlessButton>

                <TwsBorderlessDropdown
                  :items="sortingItems"
                  :default-key="sortBy"
                  :displayed-value-wrapper="`${$t('common.sort')} ({value})`"
                  class="result-action color-core-purple"
                  @onChange="onSortChange" />
              </div>
            </div>
          </div>

          <div
            v-show="showFilters"
            class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h4>Typ</h4>
                </div>
              </div>

              <div class="row">
                <div class="col-md-2 grouped-form-controls">
                  <TwsCheckbox
                    id="kopparlista"
                    v-model="typeFiltersProxy"
                    checked-value="COPPER_LIST"
                    :label="$t('reports.filters.copper')" />
                </div>

                <div class="col grouped-form-controls">
                  <TwsCheckbox
                    id="fmo-villa-lista"
                    v-model="typeFiltersProxy"
                    checked-value="FIBER_MARKET_AREA_VILLAS"
                    :label="$t('reports.filters.villas')" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-2 grouped-form-controls">
                  <TwsCheckbox
                    id="lista-lediga-fiber"
                    v-model="typeFiltersProxy"
                    checked-value="AVAILABLE_FIBER_LIST"
                    :label="$t('reports.filters.available-fiber')" />
                </div>

                <div class="col grouped-form-controls">
                  <TwsCheckbox
                    id="manuellt-upplagt-dokument"
                    v-model="typeFiltersProxy"
                    checked-value="MANUAL_UPLOAD"
                    :label="$t('reports.filters.manual-upload')" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-2 grouped-form-controls">
                  <TwsCheckbox
                    id="fmo-omraden"
                    v-model="typeFiltersProxy"
                    checked-value="FIBER_MARKET_AREAS"
                    :label="$t('reports.filters.fiber-market')" />
                </div>

                <div class="col grouped-form-controls">
                  <TwsCheckbox
                    id="omradessok"
                    v-model="typeFiltersProxy"
                    checked-value="AREA_SEARCH"
                    :label="$t('reports.filters.area-search')" />
                </div>
              </div>

              <div class="row reset-button-container">
                <div class="col">
                  <a
                    role="button"
                    class="reset-button"
                    @click="onFilterResetClick">
                    <tws-reset-filter-icon />&nbsp;
                    <span>{{ $t('common.reset-filter') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            v-show="filteredReports.length > 0"
            class="row mt-40">
            <div class="col">
              <TwsPaginatedTable
                :items="filteredReports"
                :current-page="currentPage"
                :extra-page-count="5"
                :item-count-on-page="20"
                @onPageChange="onPageChange"
                @onRowClick="onRowClick">
                <template #thead>
                  <tr>
                    <th colspan="2">
                      {{ $t('reports.table-headers.file-name') }}
                    </th>
                    <th>{{ $t('reports.table-headers.publishing-date') }}</th>
                    <th>{{ $t('reports.table-headers.expiration-date') }}</th>
                    <th>{{ $t('reports.table-headers.type') }}</th>
                  </tr>
                </template>

                <template #tr="{ item }">
                  <td>
                    <tws-file-extension-icon :extension="item.fileExtension" />
                  </td>
                  <td class="filename">
                    {{ item.filename }}
                  </td>
                  <td>{{ item.publishingDate }}</td>
                  <td>{{ item.expiryDate }}</td>
                  <td>{{ resolveDocumentType(item.documentType) }}</td>
                </template>
              </TwsPaginatedTable>
            </div>
          </div>
        </template>

        <div
          v-if="!loading"
          v-show="filteredReports.length === 0"
          class="row mt-40 justify-content-center">
          <div class="col-8 no-results-message-column">
            <p class="preamble">
              {{ $t('reports.no-data') }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'

import TwsFileExtensionIcon from '@/components/common/file-extension-icon.vue'
import MyDocumentsCompanyFilter from '@/components/my-documents-company-filter/my-documents-company-filter.vue'
import * as logger from '@/logger.js'
import { SortOptions } from '@/store/modules/my-reports.store.js'
import { BREADCRUMBS_REPORTS } from '@/constants/breadcrumbs-constants'
import { GrootHeroBanner } from 'groot-components'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

import {
  InfraPageAlert,
  TwsBorderlessButton,
  TwsBorderlessDropdown,
  TwsCheckbox,
  TwsPaginatedTable
} from 'tws-vue-components'

export default {
  components: {
    TwsBorderlessButton,
    TwsBorderlessDropdown,
    TwsCheckbox,
    InfraPageAlert,
    TwsPaginatedTable,
    MyDocumentsCompanyFilter,
    TwsFileExtensionIcon,
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_REPORTS,
      showFilters: false,
      sortingItems: [
        {
          key: SortOptions.PUBLISHING_DATE_DESC,
          value: this.$t('reports.sort-options.publishing-date-descending')
        },
        {
          key: SortOptions.PUBLISHING_DATE_ASC,
          value: this.$t('reports.sort-options.publishing-date-ascending')
        },
        {
          key: SortOptions.EXPIRY_DATE_DESC,
          value: this.$t('reports.sort-options.expiration-date-descending')
        },
        {
          key: SortOptions.EXPIRY_DATE_ASC,
          value: this.$t('reports.sort-options.expiration-date-ascending')
        }
      ]
    }
  },
  computed: {
    ...mapState('myReports', [
      'loading',
      'loaded',
      'exception',
      'currentPage',
      'sortBy',
      'typeFilters',
      'reports'
    ]),
    ...mapGetters('myReports', ['filteredReports']),
    typeFiltersProxy: {
      get () {
        return this.typeFilters
      },
      set (value) {
        this.changeTypeFilters(value)
      }
    }
  },
  created () {
    this.loadReports()
  },
  methods: {
    ...mapActions('myReports', [
      'loadReports',
      'changeTypeFilters',
      'changeCurrentPage',
      'changeSort',
      'resetTypeFilters'
    ]),
    onPageChange (page) {
      this.changeCurrentPage(page)
    },
    onRowClick (reportRow) {
      window.location.href = this.fileDownloadUrl(reportRow.reportId)
    },
    onSortChange (value) {
      this.changeSort(value)
    },
    onFilterResetClick () {
      this.resetTypeFilters()
    },
    resolveDocumentType (type) {
      switch (type) {
        case 'COPPER_LIST':
          return this.$t('reports.document-types.copper')
        case 'AVAILABLE_FIBER_LIST':
          return this.$t('reports.document-types.available-fiber')
        case 'FIBER_MARKET_AREAS':
          return this.$t('reports.document-types.fiber-market')
        case 'AREA_SEARCH':
          return this.$t('reports.document-types.area-search')
        case 'FIBER_MARKET_AREA_VILLAS':
          return this.$t('reports.document-types.villas')
        case 'MANUAL_UPLOAD':
          return this.$t('reports.document-types.manual-upload')
        default:
          logger.warn(`Unknown document type [${type}].`)
          return this.$t('reports.document-types.default')
      }
    },
    fileDownloadUrl (reportId) {
      return `${process.env.VUE_APP_REPORT_MANAGEMENT_API}/reports/fetch?id=${reportId}`
    }
  }
}
</script>

  <style lang="scss" scoped>
  @import "tws-core-atoms/variables.scss";

  .mt-40 {
    margin-top: 4rem;
  }

  .icon {
    margin-left: 0.7rem;
  }

  .reset-button-container {
    padding-top: 5rem;
    .reset-button {
      cursor: pointer;
      margin-right: 24px;
    }
  }

  .active-filter-list {
    position: relative;
    display: block;
    border-bottom: 3px solid;
    padding-bottom: 3px;
    margin-bottom: -6px;
  }

  .result-action {
    margin: 10px;
    padding: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .result-actions {
    float: right;
    display: flex;

    span, select {
      font-size: 16px;
    }
  }

  .filename {
    font-weight: bold;

    &:hover, &:focus {
      background-color: transparent;
    }
  }

  .infra-page-alert {
    margin-bottom: 2rem;
  }

  .grouped-form-controls {
    margin-bottom: 0;
  }
  .no-results-message-column {
    display: flex;
    justify-content: center;

    .preamble {
      border: 1px solid $medium-grey;
      padding: 20px 30px;
      max-width: 790px;
    }
  }
  </style>
