<template>
  <ul>
    <li
      v-for="(item, index) in items"
      :key="index">
      <tws-map-status-icon
        :color-palette="colorPalette"
        :status="item.status" />
      {{ item.text }}
    </li>
  </ul>
</template>

<script>
import MapStatusIcon from '@/components/map/map-status-icon'
import MapUtils from '@/utils/map-utils'

export default {
  components: {
    'tws-map-status-icon': MapStatusIcon
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    colorPalette: {
      type: String,
      required: true,
      validator: MapUtils.colorPaletteValidator
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 5px;
  }
}
</style>
