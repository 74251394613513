import { VUEX_NAMESPACES } from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  namespaced: true,
  getters: {
    IsBasicInfoValid (state, getters, rootState) {
      return rootState.FaultReporting.FaultReportForm[VUEX_NAMESPACES.BASIC_INFO].isValid
    },
    IsFormValid: (state, getters, rootState) => (formNamespace) => {
      return rootState.FaultReporting.FaultReportForm[VUEX_NAMESPACES.BASIC_INFO].isValid
          && rootState.FaultReporting.FaultReportForm[formNamespace].isValid
    }
  }
}
