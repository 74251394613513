import LookupsService from '@/services/lookups.service'
import { isLocaleEnglish } from '@/utils/locale.utils'

const prepareCompanies = (companies) => {
  return companies.map(company => ({
    ...company,
    nameWithTscid: company.tscid ? `${company.name}, TSCID: ${company.tscid}` : ''
  }))
}

export const LookupsModule = {
  namespaced: true,
  modules: {
    countries: {
      namespaced: true,
      state: {
        loading: false,
        loaded: false,
        exception: false,
        countries: []
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setLoaded (state, loaded) {
          state.loaded = loaded
        },
        setException (state, exception) {
          state.exception = exception
        },
        setCountries (state, countries) {
          state.countries = countries
        }
      },
      getters: {
        isLoading (state) {
          return state.loading
        },
        isLoaded (state) {
          return state.loaded
        },
        isException (state) {
          return state.exception
        },
        getCountries (state) {
          return state.countries
        }
      },
      actions: {
        async fetchCountries ({ commit }) {
          commit('setLoading', true)
          try {
            let countries

            if (isLocaleEnglish()) {
              countries = await LookupsService.getAllCountriesEn()
            } else {
              countries = await LookupsService.getAllCountries()
            }

            commit('setCountries', countries)
            commit('setLoaded', true)
          } catch (err) {
            commit('setException', true)
          } finally {
            commit('setLoading', false)
          }
        }
      }
    },
    companies: {
      namespaced: true,
      state: {
        loading: false,
        loaded: false,
        exception: false,
        companies: []
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setLoaded (state, loaded) {
          state.loaded = loaded
        },
        setException (state, exception) {
          state.exception = exception
        },
        setCompanies (state, companies) {
          state.companies = companies
        }
      },
      getters: {
        isLoading (state) {
          return state.loading
        },
        isLoaded (state) {
          return state.loaded
        },
        isException (state) {
          return state.exception
        },
        getCompanies (state) {
          return state.companies
        },
        getCompaniesWithTscid (state) {
          return state.companies.filter(company => company.tscid)
        },
        getCompany: state => code => {
          return state.companies.find(company => company.code === code)
        }
      },
      actions: {
        fetchCompanies ({ commit }) {
          commit('setLoading', true)

          return LookupsService.getAllCompanies()
            .then(prepareCompanies)
            .then(companies => {
              commit('setCompanies', companies)
              commit('setLoaded', true)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        }
      }
    }
  }
}
