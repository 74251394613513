import Cookies from 'js-cookie'
import { USER_FIELDS } from '@/common/auth-constants'

const firstName = () => localStorage.getItem(USER_FIELDS.FIRST_NAME)
const lastName = () => localStorage.getItem(USER_FIELDS.LAST_NAME)
const email = () => localStorage.getItem(USER_FIELDS.EMAIL)
const username = () => localStorage.getItem(USER_FIELDS.USERNAME)
const companyCode = () => localStorage.getItem(USER_FIELDS.COMPANY_CODE)

export const isLoggedIn = () => {
  const firstName = localStorage.getItem(USER_FIELDS.FIRST_NAME)
  const lastName = localStorage.getItem(USER_FIELDS.LAST_NAME)
  const company = localStorage.getItem(USER_FIELDS.COMPANY_NAME)
  const username = localStorage.getItem(USER_FIELDS.USERNAME)

  return !!firstName && !!lastName && !!company && !!username
}

export const getUsername = () => Cookies.get('username')

export default {
  companyCode,
  firstName,
  lastName,
  email,
  username
}
