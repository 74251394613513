import { InfraLocaleUtils } from 'tws-vue-components'
import ContentfulWrapper from '@/services/contentful/contentful.wrapper'

const getContent = async (id) => {
  return ContentfulWrapper.fetchEntry(id, { locale: InfraLocaleUtils.getSelectedLocale() })
}
export const fetchLandingPageContent = async () => {
  return await getContent('4AbGhgqDCHNKsCUAuaHSrf')
}

export const fetchNewFaultReportContent = async () => {
  return await getContent('5tdVpF3HaqDORN1fH76xkX')
}
