<template>
  <div class="company-information-display">
    <div class="row">
      <div class="col-3">
        <label class="label">
          {{ $t('common.company.code') }}
        </label>

        <p>
          {{ information.code }}
        </p>
      </div>

      <div class="col-3">
        <label class="label">
          {{ $t('common.company.name') }}
        </label>

        <p class="word-break">
          {{ information.name }}
        </p>
      </div>

      <div class="col-3">
        <label class="label">
          {{ $t('common.company.number') }}
        </label>

        <p>
          {{ information.orgNr }}
        </p>
      </div>

      <div class="col-3">
        <label class="label">
          {{ $t('common.company.id') }}
        </label>

        <p>
          {{ information.spId }}
        </p>
      </div>
    </div>

    <div class="row self-admin-row">
      <div class="col-4">
        <label class="label">
          {{ $t('company-information.self-administration') }}
        </label>

        <p>
          {{ information.selfAdministration ? $t('common.yes') : $t('common.no') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    information: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  font-weight: bold;
}

.word-break {
  word-break: break-word;
}

.self-admin-row {
  margin-top: 40px;
}

p {
  margin: 0;
}
</style>
