<template>
  <div class="phone">
    <div class="row">
      <div class="col-12">
        <h2 class="phone__title heading-m">
          {{ title }}
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div class="contact-information">
          <TeliaCoreIcon
            name="phone"
            class="contact-information__icon"
            size="30px" />

          <h3 class="contact-information__title heading-s">
            {{ phone }}, <span class="contact-information__rate">{{ rate }}</span>
          </h3>
        </div>

        <p class="open-hours paragraph paragraph--grey">
          <span class="open-hours__title">
            {{ $t('sub-categories.phone.open-hours') }}
          </span>
          <br>
          {{ openHours }}
        </p>
      </div>

      <div
        v-if="shortcuts.length > 0"
        class="shortcuts-column col-sm-6 col-xs-12">
        <div class="shortcuts__title preamble">
          {{ $t('sub-categories.phone.shortcuts') }}
        </div>

        <PhoneShortcut
          v-for="(shortcut, index) in shortcuts"
          :key="index"
          :title="shortcut.fields.label"
          :value="shortcut.fields.value" />
      </div>
    </div>
  </div>
</template>

<script>
import PhoneShortcut from './PhoneShortcut'

export default {
  components: { PhoneShortcut },
  props: {
    title: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    rate: {
      type: String,
      required: true
    },
    openHours: {
      type: String,
      required: true
    },
    shortcuts: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.phone {
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }

  &__title {
    margin-bottom: 25px;
  }
}

.contact-information {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  &__icon {
    color: $core-purple;
  }

  &__title {
    margin-left: 20px;
    font-weight: bold;
  }

  &__rate {
    font-weight: normal;
  }
}

.open-hours {
  @media (max-width: $breakpoint-sm) {
    margin-bottom: 25px;
  }

  &__title {
    font-weight: bold;
  }
}

.shortcuts-column {
  padding: 25px;
  background-color: $light-grey;
}
</style>
