import DocumentService from '@/apps/products/services/document.service'

import { isLoggedIn } from '@/utils/user.utils'
import ObjectUtils from '@/apps/products/utils/object.utils'

export const SortOptions = {
  STANDARD: 'STANDARD',
  PUBLISH_DATE_ASC: 'PUBLISH_DATE_ASC',
  PUBLISH_DATE_DESC: 'PUBLISH_DATE_DESC',
  EN_SV: 'EN_SV',
  SV_EN: 'SV_EN'
}

const SortOrder = {
  ASC: 'ASC',
  DESC: 'DESC'
}

const SortConfig = {
  STANDARD: {
    field: 'sortingOrder',
    order: SortOrder.ASC
  },
  PUBLISH_DATE_ASC: {
    field: 'publishDate',
    order: SortOrder.ASC
  },
  PUBLISH_DATE_DESC: {
    field: 'publishDate',
    order: SortOrder.DESC
  },
  EN_SV: {
    field: 'language',
    order: SortOrder.ASC
  },
  SV_EN: {
    field: 'language',
    order: SortOrder.DESC
  }
}

const compareByPublishDateDesc = (a, b) => ObjectUtils.compareObjectFields(b, a, SortConfig.PUBLISH_DATE_DESC.field)

const sortDocuments = (documents, sortBy) => {
  documents.sort((a, b) => {
    if (SortConfig[sortBy].order === SortOrder.ASC) {
      return ObjectUtils.compareObjectFields(a, b, SortConfig[sortBy].field) || compareByPublishDateDesc(a, b)
    }
    return ObjectUtils.compareObjectFields(b, a, SortConfig[sortBy].field) || compareByPublishDateDesc(a, b)
  })
}

const sortItems = (state, sortBy) => {
  const sortByStatus = (status) => {
    Object.values(state.documents[status]).forEach(document => {
      sortDocuments(document, sortBy)
    })
  }

  sortByStatus('published')
  sortByStatus('archived')
}

const filterItems = (section, searchTerm) => {
  const result = {}

  Object.keys(section).forEach(category => {
    result[category] = section[category].filter(document => !searchTerm ||
      document.displayName.toLowerCase().includes(searchTerm))
  })

  return result
}

export default {
  namespaced: true,
  state: {
    loading: null,
    exception: null,
    documents: null,
    sortBy: SortOptions.STANDARD,
    searchTerm: ''
  },
  mutations: {
    loading: (state, loading) => state.loading = loading,
    exception: (state, exception) => state.exception = exception,
    documents: (state, documents) => state.documents = documents,
    clear: state => Object.keys(state).forEach(key => state[key] = null),
    setSortBy (state, value) {
      state.sortBy = value
    },
    sort (state, sortBy) {
      sortItems(state, sortBy)
    },
    setSearchTerm (state, searchTerm) {
      state.searchTerm = searchTerm
    }
  },
  getters: {
    filteredItems: (state) => {
      const normalizedTerm = (state.searchTerm || '').toLowerCase()

      const published = state.documents.published
      const archived = state.documents.archived

      return {
        published: filterItems(published, normalizedTerm),
        archived: filterItems(archived, normalizedTerm)
      }
    }
  },
  actions: {
    async loadDocuments ({ commit, state }, productId) {
      if (state.documents) {
        return
      }

      commit('loading', true)
      commit('exception', false)

      try {
        let responses

        if (isLoggedIn()) {
          responses = await Promise.all([
            DocumentService.fetchLoggedInPublished(productId),
            DocumentService.fetchLoggedInArchived(productId)
          ])
        } else {
          responses = await Promise.all([
            DocumentService.fetchPublicPublished(productId),
            DocumentService.fetchPublicArchived(productId)
          ])
        }

        commit('documents', {
          published: responses[0],
          archived: responses[1]
        })
        commit('sort', SortOptions.STANDARD)
      } catch (error) {
        commit('exception', true)
        throw error
      } finally {
        commit('loading', false)
      }
    },
    clearDocuments ({ commit }) {
      commit('clear')
    },
    changeSort ({ commit }, sortBy) {
      commit('setSortBy', sortBy)
      commit('sort', sortBy)
    },
    changeSearchTerm ({ commit }, newSearchTerm) {
      commit('setSearchTerm', newSearchTerm)
    }
  }
}
