export const USER_ROLES = {
  AGREEMENT_RESPONSIBLE: 'user-roles.agreement-responsible',
  REPORTS: 'user-roles.reports',
  SEARCH: 'user-roles.search',
  TROUBLE_TICKET: 'user-roles.trouble-ticket.default',
  TROUBLE_TICKET_PLUS: 'user-roles.trouble-ticket.plus',
  TROUBLE_TICKET_NO_ACCESS: 'user-roles.trouble-ticket.no-access',
  NETBUSINESS_READ: 'user-roles.net-business.read',
  NETBUSINESS_QOUTE_CREATION: 'user-roles.net-business.quote-creation',
  NETBUSINESS_ORDER_CREATION: 'user-roles.net-business.order-creation',
  NETBUSINESS_MANAGE_LEVEL_NO_ACCESS: 'user-roles.net-business.manage-level-no-access',
  PHONE_SUBCRIPTION_ORDER_CREATION: 'user-roles.phone-subscription.order-creation',
  PHONE_SUBCRIPTION_ORDER_MANAGMENT: 'user-roles.phone-subscription.order-management',
  PHONE_SUBCRIPTION_FAULT_HANDLING: 'user-roles.phone-subscription.fault-handling',
  WEBTAB_ADMIN_TAB_ADMIN: 'user-roles.web-tab.tab-admin',
  WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE: 'user-roles.web-tab.operator-customer-service',
  WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR: 'user-roles.web-tab.network-defect-administrator',
  WEBTAB_ADMIN_SUPERUSER: 'user-roles.web-tab.superuser',
  WEBTAB_ADMIN_MAINTENANCE: 'user-roles.web-tab.maintenance',
  WEBTAB_ADMIN_TAB_PORTING_INTERNAL: 'user-roles.web-tab.tab-porting-internal',
  WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL: 'user-roles.web-tab.tab-readonly-internal',
  ADMINISTRATOR: 'user-roles.administrator',
  DELEGATED_ADMINISTRATOR: 'user-roles.delegated-administrator',
  SYSTEM_USER: 'user-roles.system-user',
  INVOICES: 'user-roles.invoices',
  SPECIFICATIONS: 'user-roles.specifications',
  BILLING_ADMINISTRATOR: 'user-roles.billing-administrator'
}
