<template>
  <div class="operational-news-container">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <template v-for="item in getItems">
            <div
              v-if="item.visible"
              :key="item.news.id"
              class="operational-news">
              <div
                :class="getIconClass(item.news)"
                class="operational-news-icon">
                <tws-user-error-icon v-if="item.news.isUrgentEvent" />
                <TeliaCoreIcon
                  v-else
                  name="info_thin"
                  size="3rem" />
              </div>

              <div class="operational-news-text">
                <tws-borderless-button @click="selectedItem = item">
                  {{ item.news.bannerText }}
                </tws-borderless-button>
              </div>

              <div class="operational-news-close-icon">
                <tws-close2-icon @click="hide(item)" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <tws-operational-news-dialog
      v-if="selectedItem"
      :news="selectedItem.news"
      @close="selectedItem = null" />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'

import TwsOperationalNewsDialog from '@/components/operational-news/operational-news-dialog/operational-news-dialog'

import { TwsBorderlessButton } from 'tws-vue-components'

export default {
  components: {
    TwsBorderlessButton,
    TwsOperationalNewsDialog
  },
  data () {
    return {
      selectedItem: null
    }
  },
  computed: {
    ...mapGetters('bannerOperationalNews', ['getItems'])
  },
  mounted () {
    this.fetch()
  },
  methods: {
    ...mapActions('bannerOperationalNews', [
      'fetch',
      'hide'
    ]),
    getIconClass (news) {
      return news.isUrgentEvent ? 'operational-news-icon--warning' : 'operational-news-icon--info'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.operational-news-container {
  border-bottom: solid 1px $medium-grey;
}

.operational-news {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.operational-news-icon {
  padding: 10px;

  &.operational-news-icon--warning {
    background-color: $orange;
  }

  &.operational-news-icon--info {
    background-color: $blue;
  }

  svg path {
    fill: $white;
  }
}

.operational-news-text {
  padding: 0 20px;
  flex-grow: 1;

  a:hover {
    color: $core-purple;
  }
}

.operational-news-icon,
.operational-news-close-icon {
  line-height: 0;
}

.operational-news-close-icon,
.operational-news-text a {
  cursor: pointer;
}
</style>
