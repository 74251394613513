<template>
  <purpur-icon
    class="icon-vue"
    :size="size"
    :svg="svg" />
</template>

<script>

import { Icon } from '@purpurds/purpur'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  components: {
    'purpur-icon': applyReactInVueWithOptions(Icon)
  },
  props: {
    svg: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'xs'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.icon-vue {
  display: flex;
  vertical-align: middle;
}
</style>
