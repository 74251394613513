<template>
  <div class="mega-menu">
    <div class="container">
      <div class="row">
        <div
          v-for="(category, index) in categories"
          :key="index"
          class="mega-menu__category-container col-lg-3 col-md-4 category-col">
          <mega-menu-category
            v-if="category.title"
            :category="category"
            type="category" />

          <template v-for="(subCategory, index2) in category.categories">
            <mega-menu-category
              v-if="subCategory.title"
              :key="index2"
              :category="subCategory"
              type="sub-category"
              class="mega-menu__sub-category" />
          </template>
        </div>
      </div>

      <div
        v-if="loading || exception"
        class="mega-menu__info row">
        <div
          v-if="loading"
          class="mega-menu__loading col">
          <p>{{ $t('common.loading') }}</p>
        </div>

        <div
          v-if="exception"
          class="mega-menu__exception col">
          <p>{{ $t('common.loading-failed') }}</p>
        </div>
      </div>

      <div class="row">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import MegaMenuCategory from './mega-menu-category'

export default {
  components: {
    'mega-menu-category': MegaMenuCategory
  },
  props: {
    categories: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    exception: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.mega-menu {
  position: relative;
  background-color: $light-grey;
  padding: 36px 0 12px 0;

  &__category-container {
    padding-bottom: 30px;
  }
}
</style>
