import NewsView from '@/views/news/news-view/NewsView'
import NewsArchiveView from '@/views/news/news-archive/NewsArchiveView'

export default {
  path: 'news-archive',
  component: {
    template: '<router-view />'
  },
  children: [
    {
      path: '',
      name: 'news-archive',
      component: NewsArchiveView
    },
    {
      path: 'news-page',
      name: 'news-view',
      component: NewsView
    }
  ]
}
