<template>
  <InfraReportingActionPanelAttachments
    :attachments="attachments"
    :attachments-loading="attachmentsLoading"
    :attachments-exception="attachmentsException"
    :remove-attachment-loading="removeAttachmentLoading"
    :label-delete="$t('action-panel.attachments.delete')"
    :label-confirmation="$t('action-panel.attachments.delete-confirmation')"
    :label-cancel="$t('buttons.cancel')"
    :label-remove="$t('buttons.remove')"
    :label-add-file-button="$t('file-picker.add')"
    @removeAttachment="removeAttachment"
    @putAttachments="putAttachments" />
</template>

<script>
import {
  mapActions, mapGetters, mapState
} from 'vuex'
import FilenameMixin from '@/apps/customer-tickets/mixins/filename.mixin'
import { InfraReportingActionPanelAttachments } from 'tws-vue-components'

export default {
  components: {
    InfraReportingActionPanelAttachments
  },
  mixins: [FilenameMixin],
  computed: {
    ...mapState('CustomerTickets/TicketDetails', [
      'attachmentsLoading',
      'removeAttachmentLoading',
      'attachmentsException'
    ]),
    ...mapGetters('CustomerTickets/TicketDetails', ['attachments'])
  },
  methods: {
    ...mapActions('CustomerTickets/TicketDetails', [
      'putAttachments',
      'removeAttachment'
    ])
  }
}
</script>

<style lang="scss" scoped>

.confirmation {
  &__file-name {
    font-weight: bold;
  }

  &__submit {
    margin-right: 1.2rem;
  }
}

.confirmation__button-wrapper {
  margin-top: 12rem;
}

.modal::v-deep .modal-container {
  min-height: 0;
  padding: 2rem;
}
</style>
