import ReportsService from '@/services/reports.service.js'
import ObjectUtils from '@/utils/object-utils.js'

export const SortOptions = {
  PUBLISHING_DATE_ASC: 'PUBLISHING_DATE_ASC',
  PUBLISHING_DATE_DESC: 'PUBLISHING_DATE_DESC',
  EXPIRY_DATE_ASC: 'EXPIRY_DATE_ASC',
  EXPIRY_DATE_DESC: 'EXPIRY_DATE_DESC'
}

export const MyReportsModule = {
  namespaced: true,
  state: {
    loading: true,
    exception: false,
    reports: [],
    typeFilters: [],
    currentPage: 1,
    sortBy: SortOptions.PUBLISHING_DATE_DESC
  },
  getters: {
    filteredReports (state, _getters, rootState) {
      const company = rootState.MyDocuments.company

      const filterSelected = state.typeFilters.length > 0

      let reports = [...state.reports]

      if (company) {
        reports = reports.filter(item => item.companyCode === company.code)
      }

      if (filterSelected) {
        reports = reports.filter(item => state.typeFilters.includes(item.documentType))
      }

      return reports
    }
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setException (state, exception) {
      state.exception = exception
    },
    setReports (state, reports) {
      state.reports = reports
    },
    setTypeFilters (state, typeFilters) {
      state.typeFilters = typeFilters
    },
    setCurrentPage (state, page) {
      state.currentPage = page
    },
    setSortBy (state, sortBy) {
      state.sortBy = sortBy
    },
    sortReports (state) {
      if (state.sortBy === SortOptions.PUBLISHING_DATE_DESC) {
        state.reports.sort((a, b) => {
          return ObjectUtils.compareObjectFields(b, a, 'publishingDate')
        })
      }
      if (state.sortBy === SortOptions.PUBLISHING_DATE_ASC) {
        state.reports.sort((a, b) => {
          return ObjectUtils.compareObjectFields(a, b, 'publishingDate')
        })
      }
      if (state.sortBy === SortOptions.EXPIRY_DATE_DESC) {
        state.reports.sort((a, b) => {
          return ObjectUtils.compareObjectFields(b, a, 'expiryDate')
        })
      }
      if (state.sortBy === SortOptions.EXPIRY_DATE_ASC) {
        state.reports.sort((a, b) => {
          return ObjectUtils.compareObjectFields(a, b, 'expiryDate')
        })
      }
    }
  },
  actions: {
    async loadReports ({ commit, rootGetters }) {
      try {
        const superuser = rootGetters['eamCookies/isSuperUser']
        const admin = rootGetters['eamCookies/isAdmin']

        let reports
        if (superuser || admin) {
          reports = await ReportsService.loadAllReports()
        } else {
          reports = await ReportsService.loadMyReports()
        }

        commit('setReports', reports)
        commit('sortReports')
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    resetTypeFilters ({ commit }) {
      commit('setTypeFilters', [])
      commit('setCurrentPage', 1)
    },
    changeCurrentPage ({ commit }, page) {
      commit('setCurrentPage', page)
    },
    changeTypeFilters ({ commit }, filters) {
      commit('setTypeFilters', filters)
      commit('setCurrentPage', 1)
    },
    changeSort ({ commit }, sortBy) {
      commit('setSortBy', sortBy)
      commit('sortReports')
    }
  }
}
