import NewsService from '@/services/news.service'

export const NewsModule = {
  namespaced: true,
  state: {
    news: [],
    totalCount: 0,
    loading: false,
    loaded: false,
    exception: false
  },
  mutations: {
    setNews (state, news) {
      state.news = news
    },
    setTotalCount (state, count) {
      state.totalCount = count
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    }
  },
  actions: {
    loadPublicNews ({ commit }, form) {
      commit('setLoading', true)
      commit('setLoaded', false)
      commit('setNews', [])
      commit('setTotalCount', 0)
      commit('setException', false)

      return NewsService.getPublicNews(form)
        .then(response => {

          commit('setNews', response.items)
          commit('setTotalCount', response.total)
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    loadAllNews ({ commit }, form) {
      commit('setLoading', true)
      commit('setLoaded', false)
      commit('setNews', [])
      commit('setTotalCount', 0)
      commit('setException', false)

      return NewsService.getAllNews(form)
        .then(response => {
          commit('setNews', response.items)
          commit('setTotalCount', response.total)
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  }
}
