import DateTimeUtils from '@/utils/date.utils'
import { cleanupFilename } from '@/utils/file.utils'

export const commaSeparated = function (values) {
  return values.join(', ')
}

export const convertDateTime = function (dateTimeUtc) {
  return DateTimeUtils.dateTime.formatDateTime(dateTimeUtc)
}

export const fileName = function (value) {
  return cleanupFilename(value)
}
