import NewsService from '@/services/news.service'
import { transformNewsArticles } from './transformers/landing-page.transformer.js'
import { isLoggedIn } from '@/utils/user.utils'

export const PublicLandingNewsModule = {
  namespaced: true,
  state: () => ({
    loading: true,
    exception: false,
    articles: []
  }),
  mutations: {
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setArticles: (state, articles) => state.articles = articles
  },
  actions: {
    async loadNews ({ commit }) {
      try {
        const newsPayload = {
          size: 3,
          page: 1
        }

        const articles = isLoggedIn() ?
          await NewsService.getAllNews(newsPayload) :
          await NewsService.getPublicNews(newsPayload)

        const transformedArticles = transformNewsArticles(articles.items)

        commit('setArticles', transformedArticles)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    }
  }
}
