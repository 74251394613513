<template>
  <div>
    <GrootHeroBanner
      :title="$t('categories.heading')"
      :breadcrumbs="getBreadcrumbItems()" />

    <div class="container">
      <NewsContainer />
    </div>
    <div class="view-wrapper">
      <div class="categories-view">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="categories-view__title heading-s">
                {{ $t('categories.title') }}
              </h2>
            </div>
          </div>

          <div
            class="categories-row row">
            <Category
              v-for="(category, index) in categories.items"
              :key="index"
              :title="category.fields.title"
              :icon="category.fields.iconV2"
              :path="`/kontakt/${category.fields.path}`"
              :link="category.fields.link" />

            <template v-if="loading">
              <CategoryPlaceholder
                v-for="index in 4"
                :key="'placeholder' + index" />
            </template>
          </div>
        </div>
      </div>

      <router-view
        v-if="activeCategory"
        :key="$route.params.category" />
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'
import NewsContainer from '../containers/NewsContainer.vue'
import ViewMixin from '../mixins/view.mixin.js'

import {
  Category, CategoryPlaceholder
} from '../components/'
import { BREADCRUMBS_CONTACT_US } from '@/constants/breadcrumbs-constants'
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import { GrootHeroBanner } from 'groot-components'

export default {
  components: {
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner),
    NewsContainer,
    Category,
    CategoryPlaceholder
  },
  mixins: [ViewMixin],
  computed: {
    ...mapState('ContactUs', [
      'loading',
      'categories'
    ])
  },
  watch: {
    activeCategory (value) {
      if (value && value.fields.subcategories.length === 1) {
        const path = `${this.pathLocale}/kontakt/${value.fields.path}/${value.fields.subcategories[0].fields.path}`

        if (this.$route.path !== path) {
          this.$router.push(path)
        }
      }
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    ...mapActions('ContactUs', ['load']),
    getBreadcrumbItems () {
      return BREADCRUMBS_CONTACT_US
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.categories {
  &-view {
    padding-top: 45px;

    &__title {
      margin-bottom: 50px;
    }
  }

  &-row {
    margin-top: -10px;
    margin-bottom: -10px;
  }
}

.container {
  padding-bottom: 45px;
}
</style>
