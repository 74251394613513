<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <generic-banner
        :header="$t('mass-user-transfer.banner-header')" />
    </InfraBreadcrumbContainer>
    <mass-user-transfer-form />
  </div>
</template>
<script>
import MassUserTransferForm from '@/views/mass-user-transfer/mass-user-transfer-form/mass-user-transfer-form.vue'
import GenericBanner from '@/components/common/generic-banner.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_USER_TRANSFER } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    MassUserTransferForm,
    InfraBreadcrumbContainer,
    GenericBanner
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_USER_TRANSFER
    }
  }
}
</script>
