<template>
  <div class="container">
    <div class="row table-header">
      <div
        v-for="({ key, label }, keyIndex) in keys"
        :key="key"
        :class="`${getColumnClass(keyIndex)} header-cell data-cell`">
        {{ label }}
      </div>
    </div>
    <div
      v-for="(item, dataRowIndex) in data"
      :key="`data-${dataRowIndex}`"
      class="row data-row"
      @click="handleRowClick(dataRowIndex)">
      <div
        v-for="({ key }, keyIndex) in keys"
        :key="`data-${key}-${dataRowIndex}`"
        :class="`${getColumnClass(keyIndex)} data-cell`">
        {{ item[key] }}
      </div>
    </div>
  </div>
</template>

<script>
const sumWidths = keys => keys.reduce((sum, { width }) => (width || 1) + sum, 0)

export default {
  name: 'FlexTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    keys: {
      type: Array,
      default: () => [],
      validator: k => {
        return k.length === 0
          || 12 % sumWidths(k) === 0
      }
    }
  },
  computed: {
    colSize () {
      return `col-${Math.floor(12 / this.keys.length).toFixed(0)}`
    },
    totalWidth () {
      return sumWidths(this.keys)
    }
  },
  methods: {
    handleRowClick (index) {
      this.$emit('click', this.data[index])
    },
    getColumnClass (index) {
      const width = this.keys[index].width || 1
      return `col-${Math.floor(12 * (width / this.totalWidth))}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'tws-core-atoms/variables.scss';

.table-header {
  height: 48px;
}

.data-row {
  height: 48px;
  cursor: pointer;

  &:nth-child(even) {
    background-color: $light-grey;
  }

  &:hover {
    background-color: #D2D2D2;
  }
}

.data-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 18px;
}

.header-cell {
  font-weight: 700;
}

</style>
