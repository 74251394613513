import { createInstance } from '@/services/axios.api'
export const api = createInstance(process.env.VUE_APP_PRODUCT_SEARCH_URL)

const filterObject = predicate => target => {
  return Object.keys(target).reduce((object, key) => {
    if (predicate('' + target[key])) {
      object[key] = target[key]
    }
    return object
  }, {})
}

export const getAddresses = async (addresses) => {
  return await api.post('/address/search',
    addresses.map(filterObject(s => s.length > 0))
  )
}

export const getCoordinates = async (address) => {
  const { data } = await api.get('address/coordinates', {
    params: {
      ...address
    }
  })

  return data
}
