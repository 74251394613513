import {
  signOut, storeReturnUri
} from '@/common/auth-utils'

const LoginMenuMixin = {
  methods: {
    async onUserDashboardClick () {
      await this.$router.push({
        name: 'settings',
        params: {
          locale: this.$route.params.locale
        }
      })
    },
    async onLogoutClick () {
      signOut()
      storeReturnUri()

      await this.$router.push({
        name: 'login',
        query: {
          action: 'logout'
        },
        params: {
          locale: this.$route.params.locale
        }
      })

      window.location.reload()
    }
  }
}

export default LoginMenuMixin
