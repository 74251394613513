import FaultReporting from '@/apps/fault-reporting/views/FaultReporting'
import FaultReport from '@/apps/fault-reporting/views/FaultReportDetails'
import NewFaultReport from '@/apps/fault-reporting/views/new-fault-report/NewFaultReport'
import FaultReportingIframeView from '@/apps/fault-reporting/views/skanova/FaultReportingIframeView'
import NewFaultReportForm from '@/apps/fault-reporting/views/new-fault-report/NewFaultReportForm'
import NewFaultReportSummary from '@/apps/fault-reporting/views/new-fault-report/NewFaultReportSummary'
import ThankYouPage from '@/apps/fault-reporting/views/new-fault-report/ThankYouPage'

import {
  RouteName,
  RoutePath
} from '@/apps/fault-reporting/router/routing-items'

export default [
  {
    path: RoutePath.FaultReporting,
    name: RouteName.FaultReporting,
    component: FaultReporting
  },
  {
    path: RoutePath.FaultReport,
    name: RouteName.FaultReport,
    component: FaultReport
  },
  {
    path: RoutePath.NewFaultReport,
    name: RouteName.NewFaultReport,
    component: NewFaultReport
  },
  {
    path: RoutePath.SkanovaTicketsList,
    name: RouteName.SkanovaTicketsList,
    props: { url: process.env.VUE_APP_IFRAME_TICKETS_LIST_SKANOVA_TICKETS },
    component: FaultReportingIframeView
  },
  {
    path: RoutePath.SkanovaTicketCreate,
    name: RouteName.SkanovaTicketCreate,
    props: { url: process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET },
    component: FaultReportingIframeView
  },
  {
    path: RoutePath.NewFaultReportForm,
    name: RouteName.NewFaultReportForm,
    component: NewFaultReportForm,
    beforeEnter: (_to, from, next) => {
      if (from.name === RouteName.NewFaultReport || from.name === RouteName.NewFaultReportSummary) {
        next()
      } else {
        next({
          name: RouteName.NewFaultReport,
          params: from.params
        })
      }
    }
  },
  {
    path: RoutePath.NewFaultReportSummary,
    name: RouteName.NewFaultReportSummary,
    component: NewFaultReportSummary,
    beforeEnter: (_to, from, next) => {
      if (from.name === RouteName.NewFaultReportForm) {
        next()
      } else {
        next({
          name: RouteName.NewFaultReport,
          params: from.params
        })
      }
    }
  },
  {
    path: RoutePath.ThankYouPage,
    name: RouteName.ThankYouPage,
    component: ThankYouPage,
    beforeEnter: (_to, from, next) => {
      if (from.name === RouteName.NewFaultReportSummary) {
        next()
      } else {
        next({
          name: RouteName.NewFaultReport,
          params: from.params
        })
      }
    },
    props: true
  }
]
