<template>
  <div class="view-wrapper">
    <div
      ref="itemsView"
      class="items-view">
      <component
        :is="activeSubCategory.fields.component + 'Container'"
        v-if="isContainerValid(activeSubCategory.fields.component)"
        :sub-category="activeSubCategory" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ViewMixin from '../mixins/view.mixin.js'

import {
  PortalContainer,
  TicketContainer,
  ChatContainer,
  EmailContainer,
  PhoneContainer,
  FormContainer
} from '../containers/'

const VALID_CONTAINERS = [
  'Portal',
  'Ticket',
  'Chat',
  'Email',
  'Phone',
  'Form'
]

export default {
  components: {
    PortalContainer,
    TicketContainer,
    ChatContainer,
    EmailContainer,
    PhoneContainer,
    FormContainer
  },
  mixins: [ViewMixin],
  computed: {
    ...mapState('ContactUs', ['categories']),
    activeSubCategory () {
      return this.activeCategory.fields.subcategories.find(subCategory => subCategory.fields.path === this.$route.params.subCategory)
    }
  },
  mounted () {
    document.title = this.activeSubCategory.fields.title

    this.scrollIntoView()
  },
  methods: {
    isContainerValid (container) {
      return VALID_CONTAINERS.includes(container)
    },
    scrollIntoView () {
      const element = this.$refs.itemsView
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.items-view {
  padding-top: 45px;
}
</style>
