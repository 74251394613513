const ObjectUtils = {
  compareObjectFields: (obj1, obj2, field) => {
    const x = obj1[field]
    const y = obj2[field]

    if (x > y) {
      return 1
    }
    if (x < y) {
      return -1
    }
    return 0
  },
  shallowCompareObjects: (obj1, obj2) => {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length < keys2.length) { return 1 }
    if (keys1.length > keys2.length) { return -1 }

    return keys1.reduce((result, key) => {
      if (obj1[key] !== obj2[key]) {
        return result + 1
      }

      return result
    }, 0)
  },
  getKeyRemover: value => object => {
    return Object.keys(object).reduce((agg, key) => {
      if (![].concat(value).includes(object[key])) {
        agg[key] = object[key]
      }

      return agg
    }, {})
  },
  getObjectKeyFilter: allowedFields => object => allowedFields.reduce((agg, key) => {
    if (object[key]) {
      agg[key] = object[key]
    }

    return agg
  }, {}),
  getObjectPrefiller: fields => object => fields.reduce((agg, field) => {
    if (object[field.key] === undefined) {
      agg[field.key] = field.defaultValue
    }

    return agg
  }, {
    ...object
  })
}

export default ObjectUtils
