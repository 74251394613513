import {
  email, required, requiredIf, maxLength
} from 'vuelidate/lib/validators'

const ORDER_ID_NUMBER_VALIDATOR = /2-[0-9a-zA-Z]{1,}$/
const ASSET_ID_VALIDATOR = /(0000|FB-)[0-9a-zA-Z]{1,}$/
const TT_ID_NUMBER_VALIDATOR = /2-[0-9a-zA-Z]{1,}$/

export const orderId = value => {
  return !value || ORDER_ID_NUMBER_VALIDATOR.test(value)
}

export const assetId = value => {
  return !value || ASSET_ID_VALIDATOR.test(value)
}

export const ttId = value => {
  return !value || TT_ID_NUMBER_VALIDATOR.test(value)
}

const trim = value => value.trim()

export const responseEmail = (value = '') => {
  return value.split(';').map(trim).every(email)
}

export const ticketCreateFormValidations = {
  form: {
    caseType: {
      required
    },
    customerReference: {
      maxLength: maxLength(50)
    },
    product: {
      required
    },
    subject: {
      required
    },
    content: {
      required
    },
    orderId: {
      orderId
    },
    assetId: {
      assetId
    },
    ttId: {
      ttId
    },
    receiveEmailNotification: {},
    responseEmail: {
      required: requiredIf((form) => {
        return form.receiveEmailNotification
      }),
      email: responseEmail
    }
  }
}
