import {
  HEADER_ITEMS,
  MEGA_MENU
} from '@/constants/header-contants'
import ContentfulWrapper from '@/services/contentful/contentful.wrapper'
import { InfraLocaleUtils } from 'tws-vue-components'
import {
  mapProductEntries,
  mapSimplePageEntries,
  pathIncludesSolutions
} from '@/store/transformers/header-transformers'

const fetchContent = async (type) => {
  return await ContentfulWrapper.fetchEntries({
    'content_type': type,
    locale: InfraLocaleUtils.getSelectedLocale()
  })
}

export const PublicHeaderModule = {
  namespaced: true,
  state: {
    headerItems: [],
    megaMenus: null,
    isContentfulLoading: true
  },
  mutations: {
    setHeaderItems (state, headerItems) {
      state.headerItems = headerItems
    },
    setMegaMenus (state, megaMenus) {
      state.megaMenus = megaMenus
    },
    setIsContentfulLoading (state, isLoading) {
      state.isContentfulLoading = isLoading
    }
  },
  getters: {
    getMegaMenus (state) {
      return state.megaMenus
    }
  },
  actions: {
    updateMegaMenus ({ commit }, newMegaMenus) {
      commit('setMegaMenus', newMegaMenus)
    },
    async fetchPublicHeaderItems ({ commit }) {
      try {
        commit('setIsContentfulLoading', true)

        const productsContent = await fetchContent('twsProductsCategory')
        const simplePagesContent = await fetchContent('twsPublicLandingWrapper')

        const solutionsCategoryItems = simplePagesContent.items.filter(pathIncludesSolutions)
        const aboutUsCategoryItems = simplePagesContent.items.filter(i => !pathIncludesSolutions(i))

        const megaMenus = {
          [MEGA_MENU.PRODUCTS]: mapProductEntries(productsContent.items),
          [MEGA_MENU.SOLUTIONS]: mapSimplePageEntries(solutionsCategoryItems),
          [MEGA_MENU.ABOUT_US]: mapSimplePageEntries(aboutUsCategoryItems)
        }

        commit('setHeaderItems', HEADER_ITEMS)
        commit('setMegaMenus', megaMenus)
      } catch (e) {
        //nothing
      } finally {

        commit('setIsContentfulLoading', false)
      }
    }
  }
}
