import ReportsService from '@/services/reports.service.js'
import ObjectUtils from '@/utils/object-utils.js'

export const SortOptions = {
  PUBLISHING_DATE_ASC: 'PUBLISHING_DATE_ASC',
  PUBLISHING_DATE_DESC: 'PUBLISHING_DATE_DESC',
  EXPIRY_DATE_ASC: 'EXPIRY_DATE_ASC',
  EXPIRY_DATE_DESC: 'EXPIRY_DATE_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC'
}

const filterReports = (reports, companyCode) => {
  if (!companyCode) {
    return reports
  }

  return reports.filter(item => {
    return item.companyCode === companyCode
  })
}

export const ReportsAdministrationModule = {
  namespaced: true,
  modules: {
    loading: {
      namespaced: true,
      state: {
        loading: false,
        exception: false,
        reports: [],
        filteredReports: [],
        selectedCompanyCode: null,
        currentPage: 1,
        sortBy: SortOptions.PUBLISHING_DATE_DESC
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setException (state, exception) {
          state.exception = exception
        },
        setReports (state, reports) {
          state.reports = reports
        },
        setSelectedCompanyCode (state, selectedCompanyCode) {
          state.selectedCompanyCode = selectedCompanyCode
        },
        setCurrentPage (state, page) {
          state.currentPage = page
        },
        setSortBy (state, sortBy) {
          state.sortBy = sortBy
        },
        updateFilteredReports (state) {
          const filteredReports = filterReports(state.reports, state.selectedCompanyCode)
          state.filteredReports = filteredReports.slice(0)
        },
        sortReports (state) {
          if (state.sortBy === SortOptions.PUBLISHING_DATE_DESC) {
            state.reports.sort((a, b) => {
              return ObjectUtils.compareObjectFields(b, a, 'publishingDate')
            })
          }
          if (state.sortBy === SortOptions.PUBLISHING_DATE_ASC) {
            state.reports.sort((a, b) => {
              return ObjectUtils.compareObjectFields(a, b, 'publishingDate')
            })
          }
          if (state.sortBy === SortOptions.EXPIRY_DATE_DESC) {
            state.reports.sort((a, b) => {
              return ObjectUtils.compareObjectFields(b, a, 'expiryDate')
            })
          }
          if (state.sortBy === SortOptions.EXPIRY_DATE_ASC) {
            state.reports.sort((a, b) => {
              return ObjectUtils.compareObjectFields(a, b, 'expiryDate')
            })
          }
          if (state.sortBy === SortOptions.NAME_DESC) {
            state.reports.sort((a, b) => {
              return ObjectUtils.compareObjectFields(b, a, 'filename')
            })
          }
          if (state.sortBy === SortOptions.NAME_ASC) {
            state.reports.sort((a, b) => {
              return ObjectUtils.compareObjectFields(a, b, 'filename')
            })
          }
        },
        removeReport (state, reportId) {
          state.reports = state.reports.filter(r => r.reportId !== reportId)
        }
      },
      actions: {
        loadReports ({ commit }) {
          commit('setLoading', true)

          ReportsService.loadAllReports()
            .then(reports => {
              commit('setReports', reports)
              commit('sortReports')
              commit('updateFilteredReports')
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        },
        changeCurrentPage ({ commit }, page) {
          commit('setCurrentPage', page)
        },
        changeSelectedCompanyCode ({ commit }, companyCode) {
          commit('setSelectedCompanyCode', companyCode)
          commit('updateFilteredReports')
          commit('setCurrentPage', 1)
        },
        changeSort ({ commit }, sortBy) {
          commit('setSortBy', sortBy)
          commit('sortReports')
          commit('updateFilteredReports')
        }
      }
    },
    deletion: {
      namespaced: true,
      state: {
        exception: false
      },
      mutations: {
        setException (state, exception) {
          state.exception = exception
        }
      },
      actions: {
        deleteReport ({ commit }, reportId) {
          commit('setException', false)

          ReportsService.deleteReport(reportId)
            .then(() => {
              commit('reportsAdministration/loading/removeReport', reportId, {
                root: true
              })
              commit('reportsAdministration/loading/updateFilteredReports', null, {
                root: true
              })
            })
            .catch(() => {
              commit('setException', true)
            })
        }
      }
    }
  }
}
