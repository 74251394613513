<template>
  <div class="iframe-wrapper">
    <slot />
    <tws-loading-spinner
      v-show="showSpinner"
      class="spinner" />
    <iframe
      ref="iframe"
      class="iframe"
      scrolling="no"
      :src="contentUrl"
      type="application"
      frameBorder="0"
      title="Content"
      @load="onLoad" />
  </div>
</template>

<script>
import { TwsLoadingSpinner } from 'tws-vue-components'
import { RouteName } from '@/apps/fault-reporting/router/routing-items'

const DEFAULT_IFRAME_HEIGHT = 150

let interval = 0

export default {
  components: {
    TwsLoadingSpinner
  },
  props: {
    contentUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      iframeHeight: 0,
      iframeLoaded: false
    }
  },
  computed: {
    showSpinner () {
      return !this.iframeLoaded || this.iframeHeight === 0 || this.iframeHeight === DEFAULT_IFRAME_HEIGHT
    },
    showFaultPageAlert () {
      return this.$route.name === RouteName.SkanovaTicketCreate
    }
  },
  destroyed () {
    this.onLoad(false)
  },
  methods: {
    onLoad (run = true) {
      if (run) {
        interval = window.setInterval(() => {
          const iframe = this.$refs.iframe
          this.iframeHeight = iframe.contentWindow.document.body.scrollHeight
          iframe.height = this.iframeHeight + 'px'
          this.iframeLoaded = true
        }, 250)
      } else {
        window.clearInterval(interval)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  margin-top: 30px;
}

.iframe {
  width: 100%;
  margin-top: 2.4rem;
}
</style>
