<template>
  <div class="container">
    <div
      v-if="loading || exception"
      class="row justify-content-center">
      <div
        v-show="loading"
        class="col-8">
        <infra-page-alert
          class="page-alert"
          type="info">
          {{ $t('common.loading') }}
        </infra-page-alert>
      </div>

      <div
        v-show="exception"
        class="col-8">
        <infra-page-alert
          class="page-alert"
          type="danger">
          {{ $t('common.loading-failed') }}
        </infra-page-alert>
      </div>
    </div>

    <div v-else>
      <div v-if="invoices.length > 0">
        <div class="row actions-row d-flex align-items-center">
          <div class="col-lg-4 col-md-12 ">
            <div class="form-control search-field">
              <tws-search-icon class="search-icon" />
              <input
                v-model="searchTermProxy"
                type="text"
                class="input-text"
                :placeholder="$t('invoices.search-placeholder')">
            </div>
          </div>

          <div class="col-lg-8 col-md-12">
            <div class="result-actions">
              <tws-borderless-dropdown
                :items="sortingItems"
                :default-key="sortingKey"
                :displayed-value-wrapper="`${$t('common.sort')} ({value})`"
                class="result-action color-core-purple"
                @onChange="onSortChange" />
            </div>
          </div>
        </div>

        <div class="row">
          <invoices-table
            v-show="filteredInvoices.length > 0"
            class="col-12"
            :items="pagedItems"
            :total-count="filteredInvoices.length"
            :limit="limit"
            :offset="offset"
            :has-invoice-specifications-role="hasInvoiceSpecificationsRole"
            @onOffsetChange="changeOffset" />
        </div>
      </div>

      <div
        v-else
        class="col-12 no-results-message-column">
        <p class="preamble">
          {{ $t('invoices.no-data') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState
} from 'vuex'
import InvoicesTable from './invoices-table'

import {
  InfraPageAlert, TwsBorderlessDropdown
} from 'tws-vue-components'

import { SORTING_KEY } from '@/store/modules/invoices.store.js'

export default {
  components: {
    InvoicesTable,
    InfraPageAlert,
    TwsBorderlessDropdown
  },
  data () {
    return {
      searchFilterModel: 'terms'
    }
  },
  computed: {
    sortingItems () {
      return [
        {
          key: SORTING_KEY.DATE_DESC,
          value: this.$t('invoices.sort-labels.date-descending')
        },
        {
          key: SORTING_KEY.DATE_ASC,
          value: this.$t('invoices.sort-labels.date-ascending')
        },
        {
          key: SORTING_KEY.DATE_DUE_DESC,
          value: this.$t('invoices.sort-labels.date-due-descending')
        },
        {
          key: SORTING_KEY.DATE_DUE_ASC,
          value: this.$t('invoices.sort-labels.date-due-ascending')
        }
      ]
    },
    ...mapState('invoices', [
      'loading',
      'loaded',
      'exception',
      'limit',
      'offset',
      'searchTerm',
      'sortingKey',
      'invoices'
    ]),
    ...mapGetters('invoices', ['filteredInvoices']),
    ...mapState('MyDocuments', ['company']),
    searchTermProxy: {
      get () {
        return this.searchTerm
      },
      set (term) {
        this.changeSearchTerm(term)
      }
    },
    pagedItems () {
      return this.invoices.slice(this.offset, this.offset + this.limit)
    },
    ...mapGetters('eamCookies', ['hasInvoiceSpecificationsRole'])
  },
  watch: {
    company () {
      this.loadInvoices()
    }
  },
  mounted () {
    this.loadInvoices()
  },
  methods: {
    ...mapActions('invoices', [
      'loadInvoices',
      'changeOffset',
      'changeSearchTerm',
      'changeSorting'
    ]),
    onSortChange (sortingKey) {
      this.changeSorting(sortingKey)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.container {
  margin: 60px auto 0;
}

.no-results-message-column {
  margin-top: 4rem;
  display: flex;
  justify-content: center;

  .preamble {
    border: 1px solid $medium-grey;
    padding: 20px 30px;
  }
}

.search-icon {
  position: absolute;
  margin-left: 1rem;
  margin-top: 1rem;
}

.search-field {
  margin-bottom: 0;

  input {
    background-color: $light-grey;
    border-radius: 50px;
    border-color: $light-grey;
    padding-left: 46px;
  }

  i {
    position: absolute;
    top: 17px;
    left: 18px;
  }
}

.result-actions {
  display: flex;
  justify-content: flex-end;
}

.result-action {
  margin: 10px;
  padding: 0;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
