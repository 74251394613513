<template>
  <TicketCreate
    :show-breadcrumbs="false"
    :show-back-button="false"
    @goToDetails="onGoToDetails" />
</template>

<script>
import TicketCreate from '@/apps/customer-tickets/views/TicketCreate.vue'

export default {
  components: {
    TicketCreate
  },
  methods: {
    onGoToDetails (payload) {
      this.$router.push(`${this.pathLocale}/tickets/details/${payload.params.supportId}?created=true`)
    }
  }
}
</script>
