import i18n from '@/i18n'

export const getLogicalValue = (val) => val ? i18n.t('common.yes') : i18n.t('common.no')
export const convertFileSize = (size) => {
  const i = Math.floor( Math.log(size) / Math.log(1024) )
  return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + [
    'B',
    'KB',
    'MB'
  ][i]
}
export const getTextValue = (val) => val ? val.trim() : '-'
