<template>
  <div
    v-if="items.length > 0"
    class="row">
    <div class="col-lg-8 col-md-12">
      <InfraPageAlert type="info">
        {{ $t('news-container.alert') }}

        <div
          v-for="(item, index) in items"
          :key="index"
          class="news">
          <span class="news__date">{{ item.startDate | date }} - </span>
          <TwsBorderlessButton
            class="news__title"
            @click="openItem = item">
            {{ getComputedTitle(item) }}
          </TwsBorderlessButton>
        </div>
      </InfraPageAlert>
    </div>

    <OperationalNewsDialog
      v-if="openItem"
      :news="openItem"
      @close="openItem = null" />
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'
import OperationalNewsDialog
  from '@/components/operational-news/operational-news-dialog/operational-news-dialog'

import {
  TwsBorderlessButton,
  InfraPageAlert,
  InfraLocaleUtils
} from 'tws-vue-components'

import DateUtils from '@/utils/date.utils'
import { isLoggedIn } from '@/utils/user.utils'

export default {
  components: {
    OperationalNewsDialog,
    TwsBorderlessButton,
    InfraPageAlert
  },
  filters: {
    date: date => DateUtils.dayAndMonth(date)
  },
  data () {
    return {
      openItem: null,
      isEnglish: InfraLocaleUtils.getSelectedLocale() === InfraLocaleUtils.LOCALES.EN
    }
  },
  computed: {
    ...mapState('ContactUsNews', ['items'])
  },
  mounted () {
    if (isLoggedIn()) {
      this.load()
    }
  },
  methods: {
    ...mapActions('ContactUsNews', ['load']),
    getComputedTitle (item) {
      return this.isEnglish && item.titleEn ? item.titleEn : item.title
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.news {
  display: flex;
  align-items: baseline;

  &__date {
    color: $dark-grey;
    white-space: pre;
}

  &__title {
    text-align: left;
  }
}
</style>
