<template>
  <div
    class="expandable-container"
    :class="{'expandable-container--grey-background' : isGreyBackground}">
    <div
      class="expandable-container__text">
      <template v-if="!shortText || expanded">
        <RichTextRenderer :document="fullText" />
      </template>
      <template v-else>
        <RichTextRenderer :document="shortText" />
      </template>

      <button
        v-if="fullText"
        class="button button--secondary"
        :class="{'expandable-container__expand-button--expanded' : expanded}"
        @click="expanded = !expanded">
        <div
          v-show="!expanded"
          class="expandable-container__expand-button-text">
          {{ $t('common.read-more') }}
        </div>
        <div
          v-show="expanded"
          class="expandable-container__expand-button-text">
          {{ $t('common.read-less') }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import RichTextRenderer from 'contentful-rich-text-vue-renderer'

export default {
  components: {
    RichTextRenderer
  },
  props: {
    shortText: {
      type: Object,
      required: true
    },
    fullText: {
      type: Object,
      default: null
    },
    isGreyBackground: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

h2 {
  margin: 0;
}

ul {
  margin-bottom: 15px;
  margin-top: 15px;
}

.expandable-container {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 25px;

  &--grey-background {
    background-color: $light-grey;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      p {
        display: inline-block;
        margin-left: 20px;
        margin-top: 11px;
        margin-bottom: 11px;
      }
    }

    li:before {
      align-self: center;
      content:  url("~@/img/svg/checked.svg");
    }
  }

}

</style>
