<template>
  <CommonPageView
    :banner="banner"
    :exception="exception"
    :breadcrumb-items="breadcrumbItems"
    :show-breadcrumb-items="true"
    :is-grey-banner-background="true"
    :loading="loading">
    <template #content>
      <div
        v-if="response"
        class="details">
        <div class="details-info">
          <div class="details-info-field case-id">
            <div class="details-info-field__label">
              {{ $t('details.info.number') }}
            </div>
            <div class="details-info-field__value">
              {{ response.caseId }}
            </div>
          </div>
          <div class="details-info-field status">
            <div class="details-info-field__label">
              {{ $t('details.info.state') }}
            </div>
            <div class="details-info-field__value">
              {{ stateLabels[[response.status]] }}
            </div>
          </div>
          <div class="details-info-field product">
            <div class="details-info-field__label">
              {{ $t('details.info.product') }}
            </div>
            <div class="details-info-field__value">
              {{ response.offeringName }}
            </div>
          </div>
          <div class="details-info-field account">
            <div class="details-info-field__label">
              {{ $t('details.info.account') }}
            </div>
            <div class="details-info-field__value">
              {{ response.account.name }}
            </div>
          </div>
          <div class="details-info-field contact">
            <div class="details-info-field__label">
              {{ $t('details.info.contact') }}
            </div>
            <div class="details-info-field__value">
              {{ response.contactPerson }}
            </div>
          </div>
          <div class="details-info-field-empty" />

          <template v-if="expanded">
            <div class="details-info-field watchlist">
              <div class="details-info-field__label">
                {{ $t('details.info.watch-list') }}
              </div>
              <div class="details-info-field__value">
                {{ response.watchlist | commaSeparated }}
              </div>
            </div>
            <div class="details-info-field customer-ref">
              <div class="details-info-field__label">
                {{ $t('details.info.customer-reference') }}
              </div>
              <div class="details-info-field__value">
                {{ response.customerReference }}
              </div>
            </div>
            <div class="details-info-field breach-time">
              <div class="details-info-field__label">
                {{ $t('details.info.breach-time') }}
              </div>
              <div class="details-info-field__value">
                {{ response.breachTime | convertDateTime }}
              </div>
            </div>
            <div
              v-if="response.thirdPartyReference && response.thirdPartyReference.length > 0"
              class="details-info-field third-party">
              <div class="details-info-field__label">
                {{ $t('details.info.third-party') }}
              </div>
              <div class="details-info-field__value">
                {{ response.thirdPartyReference | commaSeparated }}
              </div>
            </div>
          </template>
        </div>

        <template v-if="expanded">
          <div class="details__description-label">
            {{ $t('details.info.description') }}
          </div>
          <div class="details__description">
            {{ response.description }}
          </div>
        </template>

        <div class="details__toggler">
          <Toggler :expanded.sync="expanded" />
        </div>

        <div
          class="details__conversation-container">
          <div class="details__separator" />
          <FaultReportingDetailsActionPanelAndConversation
            :comments="convertedActivityLog"
            :case-status="response.status" />
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import CommonPageView from '@/views/CommonPageView'

import {
  mapActions, mapState
} from 'vuex'
import Toggler from '@/apps/fault-reporting/components/common/Toggler'
import FaultReportingDetailsActionPanelAndConversation
  from '@/apps/fault-reporting/views/action-panel/FaultReportDetailsActionPanel'
import { parseTranslationsFromEnum } from '@/utils/translation.utils'
import { STATE_FILTERS } from '@/apps/fault-reporting/constants/fault-report-case-list.contants'
import i18n from '@/i18n'

export default {
  name: 'FaultReportDetails',
  components: {
    FaultReportingDetailsActionPanelAndConversation,
    CommonPageView,
    Toggler
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    ...mapState('FaultReporting/FaultReportDetails', [
      'response',
      'loading',
      'exception'
    ]),
    ...mapState('FaultReporting/NewFaultReportContent', ['content']),
    stateLabels () {
      return parseTranslationsFromEnum(STATE_FILTERS)
    },
    convertedActivityLog () {
      const comments = this.response.activityLog

      return comments.map(item => {
        return Object.assign(item, { date: this.$options.filters.convertDateTime(item.date) })
      })
    },
    banner () {
      return {
        heading: this.response ? this.response.productName : i18n.t('common.state.loading'),
        description: this.response ? this.response.offeringName : i18n.t('common.state.loading')
      }
    },
    breadcrumbItems () {
      return this.content?.breadcrumbItems
    }
  },
  async mounted () {
    const id = this.$route.params.id
    await this.fetchDetails(id)
  },
  methods: {
    ...mapActions('FaultReporting/FaultReportDetails', ['fetchDetails'])
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.details {
  margin-bottom: 50px;

  &-info {
    display: grid;
    gap: 20px;

    @media(min-width: $breakpoint-md) {
      gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-field {
      &__label {
        font-weight: bold;
      }
    }

    &-field-empty {
      display: none;

      @media(min-width: $breakpoint-md) {
        display: block;
      }
    }
  }

  &__toggler {
    width: fit-content;
  }

  &__separator {
    margin: 30px 0;
    border-top: 1px solid #E6E6E6;
  }

  &__description-label {
    margin-top: 30px;
    font-weight: bold;
  }

  &__description {
    max-width: 40%;
  }
}
</style>
