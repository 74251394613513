<script>
import PurpurParagraph from '../purpur/PurpurParagraph.vue'

export default {
  components: {
    PurpurParagraph
  },
  props: {
    iconUrl: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}

</script>

<template>
  <div class="promotion-card">
    <img
      class="icon"
      :src="iconUrl"
      alt="promotion card icon">

    <PurpurParagraph class="promotion-card__paragraph">
      {{ text }}
    </PurpurParagraph>
  </div>
</template>

<style scoped lang="scss">
@use '@purpurds/tokens/color/variables' as colors;
@use '@purpurds/tokens/breakpoint/variables' as breakpoints;
@use '@purpurds/tokens/border/variables' as borders;
@use "../../assets/scss/mixins" as mixins;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;

$icon-diameter: 40px;

.promotion-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: spacing.$purpur-spacing-300 spacing.$purpur-spacing-200;
  gap: spacing.$purpur-spacing-200;
  border-radius: borders.$purpur-border-radius-md;
  border: borders.$purpur-border-width-xs solid colors.$purpur-color-gray-700;
  background: colors.$purpur-color-gray-50;

  @media(min-width: breakpoints.$purpur-breakpoint-md){
    padding: spacing.$purpur-spacing-400 spacing.$purpur-spacing-300;
    width: 100%;
  }

  @media(min-width: breakpoints.$purpur-breakpoint-lg){
    padding: spacing.$purpur-spacing-400;
  }
}

.icon {
  height: $icon-diameter;
  width: $icon-diameter;
}

::v-deep.promotion-card__paragraph span {
  text-align: left;
  font-size: 22px;
  @include mixins.line-clamp(3);
}

</style>
