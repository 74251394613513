<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h2 class="heading-m search-result-header">
          {{ header }}
        </h2>
      </div>
    </div>

    <div class="row">
      <!-- 1 column -->
      <div class="col-6">
        <div class="row asset-row">
          <div class="col bold">
            {{ $t('asset-search.asset-type') }}
          </div>

          <div class="col">
            {{ assets.assetType }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('asset-search.coverage-area') }}
          </div>

          <div class="col">
            {{ assets.coverageArea }}
          </div>
        </div>

        <template
          v-if="assets.assetType === Koppar">
          <div
            class="row asset-row">
            <div class="col bold">
              {{ $t('asset-search.line-length') }}
            </div>

            <div class="col">
              {{ assets.lineLength }}
            </div>
          </div>

          <div
            class="row asset-row">
            <div class="col bold">
              {{ $t('asset-search.cabinet') }}
            </div>

            <div class="col">
              {{ assets.cabinet }}
            </div>
          </div>

          <div
            class="row asset-row">
            <div class="col bold">
              {{ $t('asset-search.vsdl') }}
            </div>

            <div class="col">
              {{ assets.vsdl === VsdlExist ? $t('common.yes') : (assets.vsdl === NoVsdl ? $t('asset-search.no-vsdl'): '') }}
            </div>
          </div>
        </template>
      </div>

      <!-- 2 column -->
      <div class="col-6">
        <div class="row asset-row">
          <div class="col bold">
            {{ $t('asset-search.street-address') }}
          </div>

          <div class="col">
            <tws-nav-link :href="addressSearch">
              {{ assets.streetName | capitalizeFirstLetter }} {{ assets.streetNumber }}{{ assets.entrance }}
            </tws-nav-link>
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('asset-search.postal-address') }}
          </div>

          <div class="col">
            <tws-nav-link :href="addressSearch">
              {{ assets.city | capitalizeFirstLetter }}
            </tws-nav-link>
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('asset-search.apartment-number') }}
          </div>

          <div class="col">
            {{ assets.layerUnitNumber }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('asset-search.tvth') }}
          </div>

          <div class="col">
            {{ assets.tvth }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('asset-search.stairs') }}
          </div>

          <div class="col">
            {{ assets.numberOfStairs }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('asset-search.comment') }}
          </div>

          <div class="col">
            {{ assets.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TwsNavLink } from 'tws-vue-components'
import { capitalizeFirstLetter } from '@/utils/string.utils'

export default {
  components: {
    TwsNavLink
  },
  filters: {
    capitalizeFirstLetter
  },
  props: {
    assets: {
      type: Object,
      required: true
    },
    header: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      Koppar: 'Koppar',
      VsdlExist: 'N',
      NoVsdl: 'NA'
    }
  },
  computed: {
    addressSearch () {
      return process.env.VUE_APP_ADDRESS_SEARCH_PATH + '?streetName=' + this.assets.streetName + '&streetNumber=' + this.assets.streetNumber + '&entrance=' + this.assets.entrance + '&city=' + this.assets.city
    }
  }
}
</script>

<style lang="scss" scoped>

  .asset-row {
    padding-top: 5px;
  }

  .search-result-header {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .bold {
    font-weight: bold;
  }

</style>
