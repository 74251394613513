<template>
  <div class="row py-5 products-view">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h2 class="heading-m search-result-header">
            {{ header }}
          </h2>
        </div>
      </div>

      <div class="row">
        <div
          v-show="showMapSearch"
          class="col-6">
          <tws-borderless-button @click="openMapSearch">
            <TeliaCoreIcon
              name="map_thin"
              size="24px"
              class="open-all-button__icon" />
            {{ $t('products-display.open-map') }}
          </tws-borderless-button>
        </div>

        <div
          class="col text-right actions-container">
          <tws-borderless-button
            v-show="!isAllOpen"
            class="open-all-button"
            @click="openAllProducts">
            {{ $t('products-display.open-all') }}
            <TeliaCoreIcon
              name="add_thin"
              size="24px"
              class="open-all-button__icon" />
          </tws-borderless-button>

          <tws-borderless-button
            v-show="isAllOpen"
            class="open-all-button"
            @click="closeAllProducts">
            {{ $t('products-display.close-all') }}
            <TeliaCoreIcon
              name="minus_thin"
              size="24px"
              class="open-all-button__icon" />
          </tws-borderless-button>
        </div>
      </div>

      <div
        v-if="showFiberSection"
        class="row product-category">
        <div class="col-12">
          <h2 class="product-category-heading">
            {{ $t('products-display.fiber-title') }}
          </h2>
          <div class="accordion">
            <tws-accordion>
              <tws-accordion-item
                v-if="stateWholesaleFiberAccess || stateWholesaleFiberAccessOutside"
                :title="descriptions.wholesale_fiber.title"
                :expanded="isWholesaleFiberExpanded"
                @toggle="toggleAccordion(wholesaleFiberAccordionKey)">
                <wholesale-fiber-product
                  :address="header"
                  :tooltip-content="descriptions.wholesale_fiber.tooltip"
                  :product-data="stateWholesaleFiberAccess"
                  :is-wholesale-fiber-outside="stateWholesaleFiberAccessOutside && stateWholesaleFiberAccessOutside.exists" />
              </tws-accordion-item>

              <tws-accordion-item
                v-if="stateFiberSingleAccess && false"
                :title="descriptions.fiber_single.title"
                :expanded="stateFiberSingleAccess['isExpanded']"
                @toggle="toggleAccordion('fiberSingleAccess')">
                <tws-result-table
                  key="fiberSingle"
                  :data="fiberSingleData"
                  :tooltip-content="descriptions.fiber_single.tooltip"
                  class="accordion-content">
                  <tws-table
                    v-if="stateFiberSingleAccess.nearestAccessNodes !== null && stateFiberSingleAccess.nearestAccessNodes.length"
                    :columns="fiberSingleDataFields"
                    :items="stateFiberSingleAccess.nearestAccessNodes"
                    :striped="false"
                    header-color="#f2f2f2" />
                </tws-result-table>
              </tws-accordion-item>

              <tws-accordion-item
                v-if="stateFiberAreaAccess"
                :title="descriptions.fiber_area.title"
                :expanded="stateFiberAreaAccess['isExpanded']"
                @toggle="toggleAccordion('fiberArea')">
                <tws-result-table
                  key="fiberArea"
                  :data="fiberAreaData"
                  :columns="2"
                  :tooltip-content="descriptions.fiber_area.tooltip"
                  class="accordion-content">
                  <tws-table
                    v-if="stateFiberAreaAccess.addressesInformation !== null && stateFiberAreaAccess.addressesInformation.length"
                    :columns="fiberAreaTableFields"
                    :items="stateFiberAreaAccess.addressesInformation"
                    :striped="false"
                    header-color="#f2f2f2" />
                </tws-result-table>
              </tws-accordion-item>
            </tws-accordion>
          </div>
        </div>
      </div>

      <div
        v-if="showKapacitetSection"
        class="row product-category">
        <div class="col-12">
          <h2 class="product-category-heading">
            {{ $t('products-display.capacity-title') }}
          </h2>
          <div class="accordion">
            <tws-accordion>
              <tws-accordion-item
                v-if="stateWholesaleCapacityAccess"
                id="wholesale-capacity"
                :title="descriptions.wholesale_capacity.title"
                :expanded="stateWholesaleCapacityAccess['isExpanded']"
                @toggle="toggleAccordion('wholesaleCapacityAccess')">
                <tws-result-table
                  key="capacityBasicAccess"
                  :data="wholesaleCapacityAccessData"
                  slot-position="text-aligned"
                  :tooltip-content="descriptions.wholesale_capacity.tooltip"
                  class="accordion-content" />
              </tws-accordion-item>

              <tws-accordion-item
                v-if="stateEthernetSwedenAccess"
                :title="descriptions.ethernet_sweden.title"
                :expanded="stateEthernetSwedenAccess['isExpanded']"
                @toggle="toggleAccordion('ethernetSwedenAccess')">
                <tws-result-table
                  key="ethernetSwedenAccess"
                  :data="ethernetSwedenAccessData"
                  :columns="2"
                  :tooltip-content="descriptions.ethernet_sweden.tooltip"
                  class="accordion-content" />
              </tws-accordion-item>

              <tws-accordion-item
                v-if="stateEthernetNordicAccess"
                :title="descriptions.ethernet_nordic.title"
                :expanded="stateEthernetNordicAccess['isExpanded']"
                @toggle="toggleAccordion('ethernetNordicAccess')">
                <tws-result-table
                  key="ethernetNordicAccess"
                  :data="ethernetNordicAccessData"
                  :columns="2"
                  :tooltip-content="descriptions.ethernet_nordic.tooltip"
                  class="accordion-content" />
              </tws-accordion-item>
            </tws-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultTable from './result-table'
import WholesaleFiberProduct from './wholesale-fiber-product'

import {
  TwsBorderlessButton,
  TwsAccordion,
  TwsAccordionItem,
  TwsTable
} from 'tws-vue-components'
import { capitalizeFirstLetter } from '@/utils/string.utils'

const arrayToString = (array, char) => array ? array.join(char) : ''

export default {
  components: {
    'tws-result-table': ResultTable,
    TwsBorderlessButton,
    TwsAccordion,
    TwsAccordionItem,
    TwsTable,
    WholesaleFiberProduct
  },
  filters: {
    capitalizeFirstLetter
  },
  props: {
    productList: {
      type: Object,
      required: true
    },
    descriptions: {
      type: Object,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    mapSearchUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      copperAccessFields: [
        {
          key: 'stairCase',
          value: this.$t('products-display.common-table-fields.stair-case')
        },
        {
          key: 'numberOfStairs',
          value: this.$t('products-display.common-table-fields.number-of-stairs')
        },
        {
          key: 'tvth',
          value: this.$t('products-display.common-table-fields.tvth')
        },
        {
          key: 'lgh',
          value: this.$t('products-display.common-table-fields.lgh')
        },
        {
          key: 'orderable',
          value: this.$t('products-display.common-table-fields.orderable')
        },
        {
          link: {
            key: 'assetNumberLink'
          },
          linkText: {
            key: 'assetNumber'
          },
          value: this.$t('products-display.common-table-fields.asset-number')
        },
        {
          key: 'connectionStatus',
          value: this.$t('products-display.common-table-fields.status')
        },
        {
          key: 'statusChange',
          value: this.$t('products-display.common-table-fields.status-change')
        },
        {
          key: 'vsdlInformation',
          value: this.$t('products-display.copper.table-fields.vsdl-information')
        }
      ],
      commonResultTableFields: [
        {
          key: 'stairCase',
          value: this.$t('products-display.common-table-fields.stair-case')
        },
        {
          key: 'numberOfStairs',
          value: this.$t('products-display.common-table-fields.number-of-stairs')
        },
        {
          key: 'tvth',
          value: this.$t('products-display.common-table-fields.tvth')
        },
        {
          key: 'lgh',
          value: this.$t('products-display.common-table-fields.lgh')
        },
        {
          key: 'orderable',
          value: this.$t('products-display.common-table-fields.orderable')
        },
        {
          link: {
            key: 'assetNumberLink'
          },
          linkText: {
            key: 'assetNumber'
          },
          value: this.$t('products-display.common-table-fields.asset-number')
        },
        {
          key: 'connectionStatus',
          value: this.$t('products-display.common-table-fields.status-change')
        }
      ],
      fiberAreaTableFields: [
        {
          key: 'stairCase',
          value: this.$t('products-display.common-table-fields.stair-case')
        },
        {
          key: 'numberOfStairs',
          value: this.$t('products-display.common-table-fields.number-of-stairs')
        },
        {
          key: 'tvth',
          value: this.$t('products-display.common-table-fields.tvth')
        },
        {
          key: 'lgh',
          value: this.$t('products-display.common-table-fields.lgh')
        },
        {
          link: {
            key: 'assetNumberLink'
          },
          linkText: {
            key: 'assetNumber'
          },
          value: this.$t('products-display.fiber.table-fields.asset-number')
        },
        {
          key: 'connectionStatus',
          value: this.$t('products-display.fiber.table-fields.connection-status')
        }
      ],
      fiberSingleDataFields: [
        {
          key: 'nodeName',
          value: this.$t('general.node')
        },
        {
          key: 'price',
          value: this.$t('products-display.fiber.table-fields.price')
        },
        {
          key: 'priceWithTransport',
          value: this.$t('products-display.fiber.table-fields.price-with-transport')
        }
      ]
    }
  },
  computed: {
    fiber2009Data () {
      return [{
        entries: [
          {
            label: this.$t('products-display.fiber.entries.connection-point'),
            value: this.productFieldData(this.stateFiber2009Access, 'connectionPoint')
          },
          {
            label: this.$t('products-display.fiber.entries.directions'),
            value: this.productFieldData(this.stateFiber2009Access, 'directions')
          },
          {
            label: this.$t('products-display.fiber.entries.nearest-access-nodes'),
            value: arrayToString(this.productFieldData(this.stateFiber2009Access, 'nearestAccessNodes'), '<br>')
          }
        ]
      }]
    },
    fiberSingleData () {
      return [{
        entries: [
          {
            label: this.$t('products-display.fiber.entries.connection-point'),
            value: this.productFieldData(this.stateFiberSingleAccess, 'connectionPoint')
          },
          {
            label: this.$t('products-display.fiber.entries.directions'),
            value: this.productFieldData(this.stateFiberSingleAccess, 'directions')
          },
          {
            label: this.$t('products-display.fiber.entries.delivery-time'),
            value: this.productFieldData(this.stateFiberSingleAccess, 'deliveryTime')
          }
        ]
      }]
    },
    copperAccessData () {
      return [{
        entries: [
          {
            label: this.$t('products-display.common-entries.coverage-area'),
            value: this.productFieldData(this.stateCopperAccess, 'coverageArea')
          },
          {
            label: this.$t('products-display.common-entries.line-length'),
            value: this.productFieldData(this.stateCopperAccess, 'lineLength')
          },
          {
            label: this.$t('products-display.common-entries.cabinet'),
            value: this.productFieldData(this.stateCopperAccess, 'cabinet')
          },
          ...this.mapCopperPeriod(
            this.$t('products-display.common-entries.copper-period-label'),
            this.$t('products-display.common-entries.copper-period-text'),
            'copperPeriod')
        ]
      }]
    },
    ipStreamEnhancedData () {
      return [{
        entries: [
          {
            label: this.$t('products-display.common-entries.coverage-area'),
            value: this.productFieldData(this.stateIpStreamEnhancedAccess, 'coverageArea')
          },
          {
            label: this.$t('products-display.common-entries.line-length'),
            value: this.productFieldData(this.stateIpStreamEnhancedAccess, 'lineLength')
          },
          {
            label: this.$t('products-display.common-entries.max-bandwidth'),
            value: this.productFieldData(this.stateIpStreamEnhancedAccess, 'maxBandwidth', ' Mbps')
          },
          {
            label: this.$t('products-display.common-entries.cabinet'),
            value: this.productFieldData(this.stateIpStreamEnhancedAccess, 'cabinet')
          },
          ...this.mapCopperPeriod(
            this.$t('products-display.common-entries.copper-period-label'),
            this.$t('products-display.common-entries.copper-period-text'),
            'copperPeriod')
        ]
      }]
    },
    phoneSubscriptionData () {
      return [{
        entries: [
          {
            label: this.$t('products-display.common-entries.coverage-area'),
            value: this.productFieldData(this.statePhoneSubscription, 'coverageArea')
          },
          {
            label: this.$t('products-display.common-entries.line-length'),
            value: this.productFieldData(this.statePhoneSubscription, 'lineLength')
          },
          {
            label: this.$t('products-display.common-entries.cabinet'),
            value: this.productFieldData(this.statePhoneSubscription, 'cabinet')
          },
          ...this.mapCopperPeriod(
            this.$t('products-display.common-entries.copper-period-label'),
            this.$t('products-display.common-entries.copper-period-text'),
            'copperPeriod')
        ]
      }]
    },
    fiberAreaData () {
      const phaseChangeDate = this.productFieldData(this.stateFiberAreaAccess, 'phaseChangeDate')

      return [
        {
          entries: [
            {
              label: this.$t('products-display.fiber-area.entries.area'),
              value: this.productFieldData(this.stateFiberAreaAccess, 'area')
            },
            {
              label: this.$t('products-display.fiber-area.entries.area-phase'),
              value: this.productFieldData(this.stateFiberAreaAccess, 'areaPhase')
            },
            // This hides phaseChangeDate field if it's empty
            ...phaseChangeDate ? [{
              label: this.$t('products-display.fiber-area.entries.phase-change-date'),
              value: phaseChangeDate
            }] : [],
            {
              label: this.$t('products-display.fiber-area.entries.next-delivery-period'),
              value: this.productFieldData(this.stateFiberAreaAccess, 'nextDeliveryPeriod')
            }
          ]
        },
        {
          entries: [
            {
              label: this.$t('products-display.fiber-area.entries.access-point'),
              value: this.productFieldData(this.stateFiberAreaAccess, 'accessPoint')
            },
            {
              label: this.$t('products-display.fiber-area.entries.fiber-products'),
              value: arrayToString(this.productFieldData(this.stateFiberAreaAccess, 'fiberProducts'), '<br>')
            },
            ...this.mapConnectionStatus(
              this.$t('products-display.fiber-area.entries.connections-status-label'),
              'orderable',
              'fiberStatus')
          ]
        }
      ]
    },
    ethernetSwedenAccessData () {
      return [
        {
          heading: this.$t('products-display.fiber.title'),
          entries: [
            ...this.mapSwedenEthernet(this.$t('products-display.common-entries.max-bandwidth'), 'fiber/maxBandwidth', ' Mbps'),
            ...this.mapSwedenEthernet(this.$t('products-display.common-entries.max-sla'), 'fiber/maxSla', ''),
            ...this.mapSwedenEthernet(this.$t('products-display.common-entries.delivery-time'), 'fiber/deliveryTime', '')
          ]
        },
        {
          heading: this.$t('products-display.copper.title'),
          entries: [
            ...this.mapSwedenEthernet(this.$t('products-display.common-entries.max-bandwidth'), 'copper/maxBandwidth', ' Mbps'),
            ...this.mapSwedenEthernet(this.$t('products-display.common-entries.max-sla'), 'copper/maxSla', ''),
            ...this.stateEthernetSwedenAccess['copper']['maxBandwidth'] ? [{
              label: this.$t('products-display.common-entries.delivery-time'),
              value: '15'
            }] : []
          ]
        },
        {
          heading: this.$t('products-display.common-entries.mobile-heading'),
          entries: [
            {
              label: this.$t('products-display.common-entries.max-bandwidth'),
              value: '50 Mbps'
            },
            {
              label: this.$t('products-display.common-entries.max-sla'),
              value: 'Bas'
            },
            {
              label: this.$t('products-display.common-entries.delivery-time'),
              value: '8'
            }
          ],
          note: this.$t('products-display.common-entries.note')
        }
      ]
    },
    ethernetNordicAccessData () {
      return [
        {
          heading: this.$t('products-display.fiber.title'),
          entries: [
            ...this.mapNordicEthernet(this.$t('products-display.common-entries.max-bandwidth'), 'fiber/maxBandwidth', ' Mbps'),
            ...this.mapNordicEthernet(this.$t('products-display.common-entries.max-sla'), 'fiber/maxSla', ''),
            ...this.mapNordicEthernet(this.$t('products-display.common-entries.delivery-time'), 'fiber/deliveryTime', '')
          ]
        },
        {
          heading: this.$t('products-display.copper.title'),
          entries: [
            ...this.mapNordicEthernet(this.$t('products-display.common-entries.max-bandwidth'), 'copper/maxBandwidth', ' Mbps'),
            ...this.mapNordicEthernet(this.$t('products-display.common-entries.max-sla'), 'copper/maxSla', ''),
            ...this.stateEthernetNordicAccess['copper']['maxBandwidth'] ? [{
              label: this.$t('products-display.common-entries.delivery-time'),
              value: '15'
            }] : []
          ]
        },
        {
          heading: this.$t('products-display.common-entries.mobile-heading'),
          entries: [
            {
              label: this.$t('products-display.common-entries.max-bandwidth'),
              value: '50 Mbps'
            },
            {
              label: this.$t('products-display.common-entries.max-sla'),
              value: 'Bas'
            },
            {
              label: this.$t('products-display.common-entries.delivery-time'),
              value: '8'
            }
          ],
          note: this.$t('products-display.common-entries.note')
        }
      ]
    },
    capacityBasicAccessData () {
      return [{
        entries: [
          {
            label: this.$t('products-display.capacity-basic.entries.speeds'),
            value: '100 Mbit/s, 1 Gbit/s'
          },
          {
            label: this.$t('products-display.common-entries.max-sla'),
            value: this.productFieldData(this.stateCapacityBasicAccess, 'maxSla').split('/')[1].replace(/ /g, '') === 'BASIC' ? 'Bas' : 'Standard'
          },
          {
            label: this.$t('products-display.capacity-basic.entries.municipality'),
            value: this.$options.filters.capitalizeFirstLetter(this.productFieldData(this.stateCapacityBasicAccess, 'municipality'))
          },
          {
            label: this.$t('products-display.capacity-basic.entries.county'),
            value: this.$options.filters.capitalizeFirstLetter(this.productFieldData(this.stateCapacityBasicAccess, 'county'))
          },
          {
            label: this.$t('products-display.common-entries.delivery-time'),
            value: '8'
          }
        ],
        note: this.$t('products-display.capacity-basic.note')
      }]
    },
    wholesaleCapacityAccessData () {
      return [{
        entries: [
          {
            label: this.$t('products-display.wholesale-capacity.entries.speeds'),
            value: this.getBandwidthValuesString(this.productFieldData(this.stateWholesaleCapacityAccess, 'maxBandwidth'))
          },
          {
            label: this.$t('products-display.wholesale-capacity.entries.preliminary-delivery'),
            value: this.productFieldData(this.stateWholesaleCapacityAccess, 'responseCode') === '087' ?
              this.$t('products-display.wholesale-capacity.entries.preliminary-delivery-manual-summer') :
              this.productFieldData(this.stateWholesaleCapacityAccess, 'deliveryTime') + ' ' + this.$t('products-display.wholesale-capacity.entries.working-days')
          }
        ]
      }]
    },
    isAllOpen () {
      return this.$store.getters['AddressSearch/Products/isAllAccordionsOpen']
    },
    stateWholesaleFiberAccess () {
      return this.productList['wholesaleFiberAccess']
    },
    stateWholesaleFiberAccessOutside () {
      return this.productList['wholesaleFiberAccessOutside']
    },
    stateFiber2009Access () {
      return this.productList['fiber2009Access']
    },
    stateFiberSingleAccess () {
      return this.productList['fiberSingleAccess']
    },
    stateCopperAccess () {
      const product = this.productList['copperAccess']
      this.addNumber(product)
      return product
    },
    stateIpStreamEnhancedAccess () {
      const product = this.productList['ipStreamEnhancedAccess']
      this.addNumber(product)
      return product
    },
    statePhoneSubscription () {
      const product = this.productList['phoneSubscription']
      this.addNumber(product)
      return product
    },
    stateFiberAreaAccess () {
      const product = this.productList['fiberArea']
      this.addNumber(product)
      return product
    },
    stateEthernetSwedenAccess () {
      return this.productList['ethernetSwedenAccess']
    },
    stateEthernetNordicAccess () {
      return this.productList['ethernetNordicAccess']
    },
    stateCapacityBasicAccess () {
      return this.productList['capacityBasicAccess']
    },
    stateWholesaleCapacityAccess () {
      return this.productList['wholesaleCapacityAccess']
    },
    mapSearchParams () {
      if (this.productList.xcoord !== '' && this.productList.ycoord !== '' && this.productList.pointId !== '') {
        return `?x=${this.productList.xcoord}&y=${this.productList.ycoord}&pointId=${this.productList.pointId}`
      }
      return ''

    },
    showMapSearch () {
      return this.productList.xcoord && this.productList.ycoord
    },
    showKapacitetSection () {
      return this.stateEthernetSwedenAccess || this.stateEthernetNordicAccess || this.stateCapacityBasicAccess
    },
    showFiberSection () {
      return this.stateWholesaleFiberAccess || this.stateFiberSingleAccess || this.stateFiberAreaAccess || this.stateWholesaleFiberAccessOutside
    },
    isWholesaleFiberExpanded () {
      return this.stateWholesaleFiberAccess ? this.stateWholesaleFiberAccess['isExpanded'] : this.stateWholesaleFiberAccessOutside['isExpanded']
    },
    wholesaleFiberAccordionKey () {
      return this.stateWholesaleFiberAccess ? 'wholesaleFiberAccess' : 'wholesaleFiberAccessOutside'
    }
  },
  methods: {
    getBandwidthValuesString (maxBandwidth) {
      const maxBandwidthInt = parseInt(maxBandwidth)

      if (isNaN(maxBandwidthInt)) {
        return this.$t('products-display.wholesale-capacity.entries.preliminary-delivery-manual-summer')
      }

      if (maxBandwidthInt >= 1000) {
        return '100 Mbps, 500 Mbps, 1 Gbps'
      } else if (maxBandwidthInt < 1000 && maxBandwidthInt >= 500) {
        return '100 Mbps, 500 Mbps'
      } else if (maxBandwidthInt >= 100 && maxBandwidthInt < 500) {
        return '100 Mbps'
      }
      return maxBandwidthInt + ' Mbps'
    },
    openAllProducts () {
      this.$store.dispatch('AddressSearch/Products/openAllAccordions')
    },
    closeAllProducts () {
      this.$store.dispatch('AddressSearch/Products/closeAllAccordions')
    },
    toggleAccordion (accordionKey) {
      this.$store.dispatch('AddressSearch/Products/toggleAccordion', accordionKey)
    },
    productFieldData (productObject, key, optionalVal) {
      if (productObject === null) {
        return ''
      }

      let product = {
        ...productObject
      }

      for (const keyPart of key.split('/')) {
        product = product[keyPart]
        if (product === null) {
          return ''
        }
      }

      if (optionalVal !== undefined && product !== '') {
        product += optionalVal
      }

      return product
    },
    openMapSearch () {
      window.open(this.mapSearchUrl + this.mapSearchParams)
    },
    addNumber (product) {
      if (product !== undefined && product.addressesInformation !== undefined) {
        product.addressesInformation.forEach(info => {
          if (info.assetNumber) {
            if (info.assetNumber.match('^FB-[0-9]*$')) {
              info['assetNumberLink'] = process.env.VUE_APP_CONNECTION_NUMBER_SEARCH_PATH + '?number=' + info.assetNumber.match('[0-9]*$')[0]
            }
          } else if (info.phonePrefix && info.phoneNumber) {
            info.assetNumber = info.phonePrefix + '-' + info.phoneNumber
            info['assetNumberLink'] = process.env.VUE_APP_PHONE_SEARCH_PATH + '?prefix=' + info.phonePrefix + '&number=' + info.phoneNumber
          }
        })
      }
    },
    mapCopperPeriod (label, additionalText, copperPeriod) {
      return this.stateCopperAccess[copperPeriod] ? [{
        label: label,
        value: additionalText + this.stateCopperAccess[copperPeriod]
      }] : []
    },
    mapConnectionStatus (label, orderable, fiberStatus) {
      return this.stateFiberAreaAccess[orderable] && this.stateFiberAreaAccess[fiberStatus] ? [{
        label: label,
        value: this.stateFiberAreaAccess[fiberStatus]
      }] : []
    },
    mapSwedenEthernet (label, field, optionalValue) {
      return this.stateEthernetSwedenAccess[field.split('/')[0]][field.split('/')[1]] ? [{
        label: label,
        value: this.productFieldData(this.stateEthernetSwedenAccess, field, optionalValue)
      }] : []
    },
    mapNordicEthernet (label, field, optionalValue) {
      return this.stateEthernetNordicAccess[field.split('/')[0]][field.split('/')[1]] ? [{
        label: label,
        value: this.productFieldData(this.stateEthernetNordicAccess, field, optionalValue)
      }] : []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
$horizontalPadding: 6.8rem;

.actions-container {
  padding-bottom: 20px;
}

.search-result-header {
  margin-bottom: 2rem;
}

.text-right {
  text-align: right;
}

.text-right {
  text-align: right;
  a {
    text-decoration: none;
  }
}

.accordion-content {
  padding: 2.7rem 0 2.7rem $horizontalPadding;
}

.open-all-button__icon {
  color: $core-purple;
}

.product-category {
  margin-bottom: 5rem;
}

.product-category-heading {
  font-size: 30px;
  font-weight: bold;
  color: $core-purple;
}

.products-view::v-deep .table  {
  table-layout: auto;

  tbody tr {
    font-size: 14px;
    border-bottom: 1px solid $medium-grey;

    td {
      padding: 9px 20px;
    }
  }
}
</style>
