import EmailNotificationPreferencesService from '@/services/email-notification-preferences.service'
import UserService from '@/services/user.service'

const UserDashboardModule = {
  namespaced: true,
  modules: {
    information: {
      namespaced: true,
      state: {
        loading: true,
        exception: false,
        information: null
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setException (state, exception) {
          state.exception = exception
        },
        setInformation (state, information) {
          state.information = information
        }
      },
      actions: {
        fetch ({ commit }) {
          return UserService
            .getCurrentUserDetails()
            .then(information => {
              commit('setInformation', information)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        }
      }
    },
    administrators: {
      namespaced: true,
      state: {
        loading: true,
        exception: false,
        administrators: null
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setException (state, exception) {
          state.exception = exception
        },
        setAdministrators (state, administrators) {
          state.administrators = administrators
        }
      },
      actions: {
        fetch ({ commit }) {
          return UserService
            .getCompanyAdmins()
            .then(administrators => {
              commit('setAdministrators', administrators)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        }
      }
    },
    preferences: {
      namespaced: true,
      state: {
        loading: true,
        exception: false,
        preferences: null,
        saving: false,
        saved: false
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setException (state, exception) {
          state.exception = exception
        },
        setPreferences (state, preferences) {
          state.preferences = preferences
        },
        setSaving (state, saving) {
          state.saving = saving
        },
        setSaved (state, saved) {
          state.saved = saved
        }
      },
      actions: {
        fetch ({ commit }) {
          return EmailNotificationPreferencesService
            .get()
            .then(preferences => {
              commit('setPreferences', preferences)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        },
        save ({ commit, state }) {
          commit('setSaving', true)
          commit('setSaved', false)
          commit('setException', false)

          return EmailNotificationPreferencesService
            .save(state.preferences)
            .then(() => {
              commit('setSaved', true)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setSaving', false)
            })
        }
      }
    },
    permissions: {
      namespaced: true,
      state: {
        loading: true,
        exception: false,
        teliaSuperAdministrator: false
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setException (state, exception) {
          state.exception = exception
        },
        setTeliaSuperAdministrator (state, value) {
          state.teliaSuperAdministrator = value
        }
      },
      getters: {
        isTeliaSuperAdministrator (state) {
          return state.teliaSuperAdministrator
        }
      },
      actions: {
        async fetchPermissions ({ commit }) {
          commit('setLoading', true)
          try {
            const value = await UserService.isTeliaSuperAdministrator()
            commit('setTeliaSuperAdministrator', value)
          } catch (error) {
            commit('setException', true)
          } finally {
            commit('setLoading', false)
          }
        }
      }
    }
  }
}

export default UserDashboardModule
