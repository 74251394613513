<template>
  <div class="personal-information-edit">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-validated-text-input-group
          id="first-name"
          v-model="editableInformation.firstName"
          :validator="validator.firstName"
          :validation-messages="validationMessages.firstName"
          :label="$t('personal-information.first-name')" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-validated-text-input-group
          id="last-name"
          v-model="editableInformation.lastName"
          :validator="validator.lastName"
          :validation-messages="validationMessages.lastName"
          :label="$t('personal-information.last-name')" />
      </div>

      <div
        v-if="isSuperUser"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-validated-dropdown-group
          id="company"
          v-model="companyProxy"
          :validator="validator.company"
          :validation-messages="validationMessages.company"
          :disabled="usernameContainsSpId"
          :label="$t('personal-information.company')">
          <option value="">
            {{ $t('common.select') }}
          </option>
          <option
            v-for="company in companies"
            :key="company.id"
            :value="company.id">
            {{ company.name }}
          </option>
        </tws-validated-dropdown-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-validated-text-input-group
          id="position"
          v-model="editableInformation.position"
          :validator="validator.position"
          :validation-messages="validationMessages.position"
          :label="$t('personal-information.position')" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-validated-text-input-group
          id="phone"
          v-model="editableInformation.phone"
          :validator="validator.phone"
          :validation-messages="validationMessages.phone"
          :label="$t('personal-information.phone')" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-validated-text-input-group
          id="mobile-phone"
          v-model="editableInformation.mobilePhone"
          :validator="validator.mobilePhone"
          :validation-messages="validationMessages.mobilePhone"
          :label="$t('personal-information.mobile-phone')" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-validated-text-input-group
          id="email"
          v-model="editableInformation.email"
          :validator="validator.email"
          :validation-messages="validationMessages.email"
          :label="$t('personal-information.email')" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-dropdown-group
          id="country"
          v-model="editableInformation.country"
          :label="$t('personal-information.country')">
          <option value="">
            {{ $t('common.select') }}
          </option>
          <option
            v-for="country in countries"
            :key="country.code"
            :value="country.code">
            {{ country.name }}
          </option>
        </tws-dropdown-group>
      </div>

      <div class="col-12">
        <tws-validated-text-area-group
          id="comment"
          v-model="editableInformation.comments"
          :validator="validator.comments"
          :validation-messages="validationMessages.comments"
          :label="$t('personal-information.comment')" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  TwsDropdownGroup,
  TwsValidatedDropdownGroup,
  TwsValidatedTextAreaGroup,
  TwsValidatedTextInputGroup
} from 'tws-vue-components'

export default {
  components: {
    TwsDropdownGroup,
    TwsValidatedDropdownGroup,
    TwsValidatedTextAreaGroup,
    TwsValidatedTextInputGroup
  },
  props: {
    countries: {
      type: Array,
      required: true
    },
    companies: {
      type: Array,
      required: true
    },
    information: {
      type: Object,
      required: true
    },
    validator: {
      type: Object,
      required: true
    },
    isSuperUser: {
      type: Boolean,
      required: true
    },
    usernameContainsSpId: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editableInformation: Object.assign({}, this.information),
      validationMessages: {
        firstName: {
          required: this.$t('personal-information.validations.first-name.required'),
          maxLength: this.$t('personal-information.validations.first-name.max-length')
        },
        lastName: {
          required: this.$t('personal-information.validations.last-name.required'),
          maxLength: this.$t('personal-information.validations.last-name.max-length')
        },
        company: {
          required: this.$t('personal-information.validations.company.required')
        },
        email: {
          required: this.$t('personal-information.validations.email.required'),
          email: this.$t('personal-information.validations.email.email')
        },
        phone: {
          required: this.$t('personal-information.validations.phone.required'),
          phone: this.$t('personal-information.validations.phone.phone')
        },
        mobilePhone: {
          required: this.$t('personal-information.validations.mobile-phone.required'),
          mobilePhone: this.$t('personal-information.validations.mobile-phone.mobile-phone')
        },
        position: {
          maxLength: this.$t('personal-information.validations.position.max-length')
        },
        comments: {
          maxLength: this.$t('personal-information.validations.comments.max-length')
        }
      }
    }
  },
  computed: {
    companyProxy: {
      get () {
        return this.editableInformation.company ? this.editableInformation.company.id : null
      },
      set (value) {
        if (value) {
          this.editableInformation.company = this.companies.find(company => company.id === value)
        } else {
          this.editableInformation.company = null
        }
      }
    }
  },
  watch: {
    editableInformation: {
      handler (newValue) {
        this.$emit('informationChanged', newValue)
      },
      deep: true
    }
  }
}
</script>
