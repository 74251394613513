import { render, staticRenderFns } from "./rich-text.vue?vue&type=template&id=46407a25"
import script from "./rich-text.vue?vue&type=script&lang=js"
export * from "./rich-text.vue?vue&type=script&lang=js"
import style0 from "./rich-text.vue?vue&type=style&index=0&id=46407a25&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports