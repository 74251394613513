<template>
  <div id="tws-customer-tickets">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.load()
  },
  methods: {
    ...mapActions('CustomerTickets', ['load'])
  }
}
</script>
