import AuthService from '@/services/auth.service'
import {
  getReturnUri, saveUserDetails, setTokenData
} from '@/common/auth-utils'
import {
  SESSION_KEY_CODE_VERIFIER, SESSION_KEY_STATE
} from '@/common/auth-constants'
import UserService from '@/services/user.service'

function getExceptionMessage (error) {
  if (error.message === 'Network Error') {
    return error.message
  } else if (error.response) {
    const data = error.response.data
    return `${data.message} (${data.uuid})`
  }
  return `Failure from IdP. ${error}`
}

export const AuthenticationCallbackModule = {
  namespaced: true,
  state: {
    loading: false,
    exception: false,
    exceptionMessage: ''
  },
  mutations: {
    setLoading (state, value) {
      state.loading = value
    },
    setException (state, value) {
      state.exception = value
    },
    setExceptionMessage (state, value) {
      state.exceptionMessage = value
    }
  },
  actions: {
    async exchangeToken ({ commit }, { code, state }) {
      commit('setLoading', true)
      commit('setException', false)
      commit('setExceptionMessage', '')

      const initialState = sessionStorage.getItem(SESSION_KEY_STATE)
      const isStateCorrect = initialState && initialState === state

      try {
        if (code && isStateCorrect) {
          const codeVerifier = sessionStorage.getItem(SESSION_KEY_CODE_VERIFIER)

          const response = await AuthService.exchangeToken(code, codeVerifier)

          await setTokenData(response)
          const currentUser = await UserService.getCurrentUserDetails()
          await saveUserDetails(currentUser)

          window.location.href = getReturnUri()
        } else {
          commit('setExceptionMessage', 'Incorrect state or code.')
          commit('setException', true)
        }
      } catch (error) {
        commit('setExceptionMessage', getExceptionMessage(error))
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    }
  }
}
