import { ACCOUNT_STATUS } from '@/constants/account-status.js'
import router from '@/router/router'

import UserService from '@/services/user.service'

function getDefaultInformation () {
  return {
    firstName: null,
    lastName: null,
    company: null,
    position: null,
    phone: null,
    mobilePhone: null,
    email: null,
    country: 'SE',
    comments: null,
    username: null,
    loginMethodPassword: true,
    accountStatus: ACCOUNT_STATUS.PASSWORD_EXPIRED,
    accountExpirationDate: null,
    roles: {
      AGREEMENT_RESPONSIBLE: false,
      REPORTS: false,
      SEARCH: false,
      WEBTAB_ADMIN_TAB_ADMIN: false,
      WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE: false,
      WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR: false,
      WEBTAB_ADMIN_SUPERUSER: false,
      WEBTAB_ADMIN_MAINTENANCE: false,
      WEBTAB_ADMIN_TAB_PORTING_INTERNAL: false,
      WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL: false,
      ADMINISTRATOR: false,
      DELEGATED_ADMINISTRATOR: false,
      MASTER_ADMINISTRATOR: false,
      COMPANY_ADMIN: false,
      COMPANY_TRANSFER: false,
      TROUBLE_TICKET: false,
      TROUBLE_TICKET_PLUS: false,
      TROUBLE_TICKET_NO_ACCESS: false,
      NETBUSINESS_READ: false,
      NETBUSINESS_QOUTE_CREATION: false,
      NETBUSINESS_ORDER_CREATION: false,
      NETBUSINESS_MANAGE_LEVEL_NO_ACCESS: false,
      PHONE_SUBCRIPTION_ORDER_CREATION: false,
      PHONE_SUBCRIPTION_ORDER_MANAGMENT: false,
      PHONE_SUBCRIPTION_FAULT_HANDLING: false,
      SYSTEM_USER: false,
      INVOICES: false,
      SPECIFICATIONS: false,
      BILLING_ADMINISTRATOR: false
    },
    errorReporting: null,
    manageNBM: null
  }
}

export const UserCreationModule = {
  namespaced: true,
  state: {
    creating: false,
    exception: false,
    information: getDefaultInformation()
  },
  mutations: {
    resetInformation (state) {
      Object.assign(state.information, getDefaultInformation())
    },
    setCreating (state, creating) {
      state.creating = creating
    },
    setException (state, exception) {
      state.exception = exception
    },
    setPersonalInformation (state, information) {
      state.information.firstName = information.firstName
      state.information.lastName = information.lastName
      state.information.company = Object.assign({}, information.company)
      state.information.position = information.position
      state.information.phone = information.phone
      state.information.mobilePhone = information.mobilePhone
      state.information.email = information.email
      state.information.country = information.country
      state.information.comments = information.comments
    },
    setAccountInformation (state, information) {
      state.information.username = information.username
      state.information.loginMethodPassword = information.loginMethodPassword
      state.information.accountStatus = information.accountStatus
      state.information.accountExpirationDate = information.accountExpirationDate
      state.information.roles = Object.assign({}, information.roles)
      state.information.errorReporting = information.errorReporting
      state.information.manageNBM = information.manageNBM
    }
  },
  actions: {
    createUser ({ commit,
      state,
      rootGetters }) {
      commit('setCreating', true)
      commit('setException', false)

      const information = Object.assign({}, state.information)
      const systemUser = information.roles.SYSTEM_USER

      if (!systemUser) {
        if (information.errorReporting) {
          information.roles[information.errorReporting] = true
        }

        if (information.manageNBM) {
          information.roles[information.manageNBM] = true
        }
      }

      delete information.errorReporting
      delete information.manageNBM

      const delegatedAdmin = !rootGetters['eamCookies/isSuperUser']
      const companies = rootGetters['lookups/companies/getCompanies']
      const singleCompany = companies && companies.length === 1

      if (delegatedAdmin && singleCompany) {
        // auto-select the same company if delegated admin
        information.company = JSON.parse(JSON.stringify(companies[0]))
      }

      information['companyCode'] = information.company.code

      return UserService
        .createUser(information, systemUser)
        .then(response => {
          const pathLocale = router.currentRoute.params?.locale
            ? `/${router.currentRoute.params.locale}` : ''

          router.push(
            `${pathLocale}${process.env.VUE_APP_USER_ADMINISTRATION_PATH}` +
          `?redirect-action=created&username=${response.username}&id=${response.userId}`
          )
          commit('resetInformation')
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setCreating', false)
        })
    },
    personalInformationChanged ({ commit }, information) {
      commit('setPersonalInformation', information)
    },
    accountInformationChanged ({ commit }, information) {
      commit('setAccountInformation', information)
    },
    resetInformation ({ commit }) {
      commit('resetInformation')
    }
  }
}
