const ObjectUtils = {
  compareObjectFields: (obj1, obj2, field) => {
    const x = obj1[field]
    const y = obj2[field]

    if (x > y) {
      return 1
    }
    if (x < y) {
      return -1
    }
    return 0
  }
}

export default ObjectUtils
