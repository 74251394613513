<template>
  <div class="chat-column col-md-4 col-sm-6">
    <div class="chat">
      <div class="chat__title preamble">
        {{ title }}
      </div>

      <button
        class="button button--primary chat__button"
        @click="openChat">
        {{ $t('sub-categories.chat.buttons.start') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  methods: {
    openChat () {
      window.open(
        this.pathLocale + this.link,
        '_blank',
        'resizable=no,status=no,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=no,dependent=no,width=700,height=850'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.chat {
  &-column {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__title {
    margin-bottom: 25px;
  }

  &__button {
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
}
</style>
