<template>
  <tws-information-dialog
    class="operational-news-dialog"
    :header="news.title"
    heading-alignment="left"
    @close="$emit('close')">
    <template #body>
      <div
        v-if="news.startDate"
        class="dialog-dates">
        <TeliaCoreIcon
          v-if="news.isUrgentEvent"
          name="info_thin"
          size="3rem" />
        <TeliaCoreIcon
          v-else-if="isFutureDate()"
          name="megaphone_thin"
          size="3rem" />
        <TeliaCoreIcon
          v-else
          name="repair_thin"
          size="3rem" />

        <p class="preamble">
          {{ computeDate(news) }}
        </p>
      </div>
      <div
        class="dialog-content">
        <InfraContentfulRichTextContainer
          :content="news.content.json" />
      </div>

      <div class="dialog-footer">
        <tws-button-primary
          @click="$emit('close')">
          {{ $t('common.close') }}
        </tws-button-primary>

        <label
          v-if="news.startDate"
          class="dialog-publish-date">
          {{ $t('operational-news.published-date') }}: {{ news.publishedAt || news.sys.publishedAt | formatDateIso }}
        </label>
      </div>
    </template>
  </tws-information-dialog>
</template>

<script>
import DateTimeUtils from '@/common/datetime-utils'
import { TwsButtonPrimary } from 'tws-vue-components'
import { InfraContentfulRichTextContainer } from 'tws-vue-components'
import TwsInformationDialog from '@/components/common/information-dialog'

export default {
  components: {
    TwsButtonPrimary,
    TwsInformationDialog,
    InfraContentfulRichTextContainer
  },
  filters: {
    formatDateIso (date) {
      return DateTimeUtils.formatDateIso(date)
    }
  },
  props: {
    news: {
      type: Object,
      required: true
    }
  },
  methods: {
    computeDate () {
      return DateTimeUtils.computeDate(this.news.startDate, this.news.endDate)
    },
    isFutureDate () {
      return DateTimeUtils.isFutureDate(this.news.startDate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.dialog-dates {
  display: flex;
  margin-top: 25px;

  svg {
    margin-right: 20px;
  }
}

.dialog-content {
  margin: 60px 0;
}

.dialog-english-content {
  margin-top: 60px;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-publish-date {
  color: $dark-grey;
}

.operational-news-dialog::v-deep .information-dialog-body {
  margin-top: 0;
}
</style>
