<template>
  <div
    v-show="loading || exception"
    class="row justify-content-center">
    <div class="col-lg-6 col-md-10">
      <InfraPageAlert
        v-show="loading"
        type="info">
        {{ $t('map.loading') }}
      </InfraPageAlert>

      <InfraPageAlert
        v-show="exception"
        type="danger">
        {{ $t('map.loading-failed') }}
      </InfraPageAlert>
    </div>
  </div>
</template>

<script>
import { InfraPageAlert } from 'tws-vue-components'

export default {
  components: {
    InfraPageAlert
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    exception: {
      type: Boolean,
      default: false
    }
  }
}
</script>
