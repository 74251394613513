<template>
  <CommonPageView
    class="fault-reporting"
    :banner="banner"
    :breadcrumb-items="breadcrumbItems"
    :exception="isContentException"
    :loading="isContentLoading">
    <template #banner>
      <div class="fault-reporting__cta mt-5">
        <PurpurCtaLink
          class="fault-reporting__cta--link"
          href=""
          size="md"
          variant="primary-negative"
          @click.native="goToCreateNewFaultReport">
          {{ $t('fault-reporting.button.new-fault-report') }}
        </PurpurCtaLink>
      </div>
    </template>
    <template #content>
      <div class="row fault-reporting__content">
        <div class="col-xl-8 col-sm-8 col-xs-12">
          <MultifunctionalContent :content="content" />
        </div>
      </div>

      <tws-loading-spinner
        v-if="isProductsLoading"
        class="spinner mt-5" />

      <template v-else>
        <NavLinkWrapper
          v-if="hasAnyWebtabRole()"
          class="mt-5"
          :href="`${hostOrigin}${navigationLinkWebtab}`"
          :text="$t('fault-reporting.navigation-link-webtab')" />

        <div class="row fault-reporting__action-panel">
          <div class="col-xl-10 col-sm-10 col-xs-12">
            <div class="row">
              <div class="fault-reporting__action-panel__reference-search col-xl-5 col-sm-5 col-xs-12">
                <AutocompleteField
                  id="AssetSearch"
                  v-model="selectedAssetViewValueProxy"
                  :rounded="true"
                  :placeholder="$t('fault-reporting.reference-search-input.placeholder')"
                  :background="'grey'"
                  :tooltip-text="$t('fault-reporting.reference-search-input.tooltip')"
                  :options="assets"
                  :label-getter="assetsLabelGetter"
                  :label="$t('fault-reporting.reference-search-input.label')"
                  @select="handleAssetSelect"
                  @input="handleSearchStringChange">
                  <template #icon>
                    <TeliaCoreIcon
                      class="search-icon"
                      name="search_thin"
                      size="2.7rem" />
                  </template>
                  <template #spinner>
                    <TwsLoadingSpinner
                      v-show="assetsQueryLoading"
                      :small="true"
                      class="loading-spinner" />
                  </template>
                </AutocompleteField>
              </div>
              <div class="fault-reporting__action-panel__text col-xl-1 col-sm-1 col-xs-12">
                {{ $t('fault-reporting.middle-text') }}
              </div>
              <div class="fault-reporting__action-panel__products-search col-xl-5 col-sm-5 col-xs-12">
                <InfraSearchableDropdown
                  id="product"
                  ref="productsDropdown"
                  v-model="selectedProductProxy"
                  :options="products"
                  :label-getter="productsOptionsLabelGetter"
                  :label="labels.product" />
              </div>
            </div>
            <div class="row mt-5 centered">
              <div class="col-xl-2 col-sm-3 col-xs-12 mb-5">
                <tws-button-primary
                  :disabled="!selectedProductEntity"
                  @click="handleProductEntitySelection">
                  {{ $t('fault-reporting.show-button') }}
                </tws-button-primary>
              </div>
              <div class="col-xl-6 col-sm-6 col-xs-12">
                <TwsCheckbox
                  id="isAllCasesRequested"
                  v-model="isAllCasesRequestedProxy"
                  :disabled="!selectedProductEntity"
                  :tooltip="$t('fault-reporting.is-all-cases-requested.tooltip')"
                  :tooltip-width="tooltipWidth"
                  :label="$t('fault-reporting.is-all-cases-requested.label')" />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="isProductsException || isCasesException"
          class="row">
          <div class="col-lg-5 col-md-6 col-sm-6">
            <InfraPageAlert
              type="danger">
              {{ $t('common.state.error') }}
            </InfraPageAlert>
          </div>
        </div>

        <tws-loading-spinner
          v-if="isCasesLoading"
          class="spinner" />

        <template v-if="!isCasesLoading && !isCasesException && isCasesShown">
          <NavLinkWrapper
            v-if="navLinkRouteName || navLinkHref"
            :href="navLinkHref"
            :route-name="navLinkRouteName"
            :text="$t('fault-reporting.navigation-link')" />

          <div v-else>
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <InfraTileButtons
                  v-model="stateFilterProxy"
                  :options="stateFilters" />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-lg-4 col-md-12">
                <div class="search-field">
                  <TeliaCoreIcon
                    class="search-field__icon"
                    name="search_thin"
                    size="2.7rem" />
                  <input
                    v-model="searchTermProxy"
                    type="text"
                    class="input-text"
                    :placeholder="$t('case-list-filter.search-input-placeholder')">
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12">
                <tws-paginated-table
                  v-if="filteredCases.length > 0"
                  class="fault-reporting-table"
                  :items="filteredCases"
                  :current-page="currentPage"
                  :extra-page-count="3"
                  :item-count-on-page="25"
                  @onPageChange="changeCurrentPage"
                  @onRowClick="handleRowClick">
                  <template #thead>
                    <tr>
                      <th scope="col">
                        {{ $t('case-list-table.number') }}
                      </th>
                      <th scope="col">
                        {{ $t('case-list-table.asset') }}
                      </th>
                      <th scope="col">
                        {{ $t('case-list-table.reference') }}
                      </th>
                      <th scope="col">
                        {{ $t('case-list-table.description') }}
                      </th>
                      <th scope="col">
                        {{ $t('case-list-table.product') }}
                      </th>
                      <th scope="col">
                        {{ $t('case-list-table.created') }}
                      </th>
                      <th scope="col">
                        {{ $t('case-list-table.state') }}
                      </th>
                    </tr>
                  </template>

                  <template #tr="{ item }">
                    <td class="fit-width">
                      {{ item.caseId }}
                    </td>
                    <td class="fault-reporting-table-asset">
                      {{ item.assetName }}
                    </td>
                    <td class="fit-width">
                      {{ item.customerReference }}
                    </td>
                    <td class="fit-width">
                      {{ item.shortDescription }}
                    </td>
                    <td class="fit-width">
                      {{ item.offeringName }}
                    </td>
                    <td class="fit-width">
                      {{ item.createdDate | convertDateTime }}
                    </td>
                    <td class="fit-width">
                      {{ stateLabels[item.status] }}
                    </td>
                  </template>
                </tws-paginated-table>
                <div
                  v-else
                  class="no-results-message-column">
                  <p class="preamble">
                    {{ $t('case-list-table.no-results') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
  </CommonPageView>
</template>

<script>
import {
  mapState, mapGetters, mapActions
} from 'vuex'
import TeliaCoreIcon from '@telia/core-icons'
import CommonPageView from '@/views/CommonPageView'
import {
  TwsButtonPrimary,
  TwsCheckbox,
  InfraSearchableDropdown,
  TwsLoadingSpinner,
  TwsPaginatedTable,
  InfraTileButtons,
  InfraPageAlert
} from 'tws-vue-components'
import PurpurCtaLink from '@/apps/public-landing/components/purpur/PurpurCtaLink.vue'
import { RouteName } from '@/apps/fault-reporting/router/routing-items'
import MultifunctionalContent from '@/apps/fault-reporting/components/content/MultifunctionalContent'
import AutocompleteField from '@/apps/fault-reporting/components/input/AutocompleteField'
import { hasAnyWebtabRole } from '@/apps/fault-reporting/utils/user.utils'
import debounce from 'lodash/debounce'
import {
  STATE_FILTERS,
  getStatefulStateFilters
} from '@/apps/fault-reporting/constants/fault-report-case-list.contants'
import { parseTranslationsFromEnum } from '@/utils/translation.utils'
import {
  CASEY_PRODUCT_CATEGORY_IDS,
  PRODUCT_CATEGORY_ITEMS,
  TOOLTIP_WIDTH,
  WOW_PRODUCT_CATEGORY_IDS
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import NavLinkWrapper from '@/apps/fault-reporting/components/common/NavLinkWrapper'

const FRIENDLY_NAME = 'friendlyname'

export default {
  components: {
    NavLinkWrapper,
    AutocompleteField,
    MultifunctionalContent,
    CommonPageView,
    TwsButtonPrimary,
    TwsCheckbox,
    TeliaCoreIcon,
    TwsPaginatedTable,
    TwsLoadingSpinner,
    InfraSearchableDropdown,
    InfraTileButtons,
    InfraPageAlert,
    PurpurCtaLink
  },
  data () {
    return {
      navLinkHref: null,
      navLinkRouteName: null,
      navigationLinkWebtab: process.env.VUE_APP_WEBTAB_PATH,
      hostOrigin: window.location.origin,
      labels: {
        product: this.$t('fault-reporting.product-dropdown.label')
      },
      skanovaTicketCreateRoute: RouteName.SkanovaTicketCreate,
      tooltipWidth: TOOLTIP_WIDTH
    }
  },
  computed: {
    ...mapState('FaultReporting/C2bCache', {
      isProductsLoading: 'loading',
      isProductsException: 'exception',
      assets: 'assets',
      selectedProduct: 'selectedProduct',
      selectedAsset: 'selectedAsset',
      selectedAssetViewValue: 'selectedAssetViewValue',
      assetsQueryLoading: 'assetsQueryLoading'
    }),
    ...mapState('FaultReporting/CaseList', {
      isCasesLoading: 'loading',
      isCasesException: 'exception',
      currentPage: 'currentPage',
      stateFilter: 'stateFilter',
      searchTerm: 'searchTerm',
      isCasesShown: 'isCasesShown',
      isAllCasesRequested: 'isAllCasesRequested'
    }),
    stateLabels () {
      return parseTranslationsFromEnum(STATE_FILTERS)
    },
    stateFilters () {
      return Object.entries(getStatefulStateFilters(this.isAllCasesRequested)).map(([
        value,
        label
      ]) => ({
        value,
        label: this.$t(label)
      }))
    },
    stateFilterProxy: {
      get: function () {
        return this.stateFilter
      },
      set: function (value) {
        this.changeStateFilter(value)
        this.changeCurrentPage(1)
      }
    },
    searchTermProxy: {
      get: function () {
        return this.searchTerm
      },
      set: function (value) {
        this.changeSearchTerm(value)
      }
    },
    isAllCasesRequestedProxy: {
      get: function () {
        return this.isAllCasesRequested
      },
      set: function (value) {
        this.changeIsAllCasesRequested(value)
        this.changeIsCasesShown(false)
      }
    },
    selectedProductProxy: {
      get () {
        return this.selectedProduct
      },
      set (value) {
        if (value !== this.selectedProduct) {
          this.changeIsCasesShown(false)
        }

        this.updateSelectedProduct(value)

        if (value) {
          this.handleSearchStringChange(null)
          this.updateSelectedAssetViewValue(null)
        }
      }
    },
    selectedAssetViewValueProxy: {
      get () {
        return this.selectedAssetViewValue
      },
      set (value) {
        this.updateSelectedAssetViewValue(value)
      }
    },
    ...mapGetters('FaultReporting/C2bCache', [
      'products',
      'selectedProductEntity',
      'tscId'
    ]),
    ...mapGetters('FaultReporting/CaseList', ['filteredCases']),
    ...mapState('FaultReporting/LandingPageContent', {
      isContentLoading: 'loading',
      isContentException: 'exception',
      content: 'content'
    }),
    loading () {
      return this.isContentLoading || this.isProductsLoading
    },
    exception () {
      return this.isContentException || this.isProductsException
    },
    queryAssetsDebounced () {
      return debounce(function (input) {
        this.queryAssets(input)
      }, 300)
    },
    banner () {
      return {
        heading: this.content?.fields.pageBanner.fields.heading,
        description: this.content?.fields.pageBanner.fields.description
      }
    },
    breadcrumbItems () {
      return this.content?.breadcrumbItems
    }
  },
  created () {
    this.loadContent()
    this.fetchProductsCategories()
  },
  methods: {
    ...mapActions('FaultReporting/LandingPageContent', ['loadContent']),
    ...mapActions('FaultReporting/CaseList', [
      'fetchCaseList',
      'changeCurrentPage',
      'changeStateFilter',
      'changeSearchTerm',
      'changeIsCasesShown',
      'changeIsAllCasesRequested',
      'resetCaseList'
    ]),
    ...mapActions('FaultReporting/C2bCache', [
      'clearAssets',
      'fetchProductsCategories',
      'cancelQueryAssets',
      'queryAssets',
      'updateSelectedProduct',
      'updateSelectedAsset',
      'updateSelectedAssetViewValue'
    ]),
    goToCreateNewFaultReport () {
      this.$router.push({
        name: RouteName.NewFaultReport,
        params: this.$route.params
      })
    },
    productsOptionsLabelGetter (val) {
      return val && val.categoryname ? val.categoryname : '-'
    },
    assetsLabelGetter (val) {
      return val && val[FRIENDLY_NAME] ? val[FRIENDLY_NAME] : '-'
    },
    handleAssetSelect (val) {
      this.changeIsCasesShown(false)

      this.updateSelectedAsset(val)

      if (val) {
        this.selectedAssetViewValueProxy = val[FRIENDLY_NAME]
        this.selectedProductProxy = null
        this.$refs.productsDropdown.handleClickClear()
      }
    },
    handleSearchStringChange (value) {
      this.clearAssets()
      this.updateSelectedAsset(null)

      if (value && value.length > 2) {
        this.queryAssetsDebounced(value)
      } else {
        this.cancelQueryAssets()
        this.queryAssetsDebounced.cancel()
      }
    },
    async handleProductEntitySelection () {
      this.resetCaseList()

      const selectedCategoryId = this.selectedProductEntity.categoryid
      const subscriptionId = this.selectedProductEntity.subscriptionid

      this.navLinkHref = this.navLinkRouteName = null

      if (WOW_PRODUCT_CATEGORY_IDS.includes(selectedCategoryId)) {
        this.navLinkRouteName = RouteName.SkanovaTicketsList

      } else if (CASEY_PRODUCT_CATEGORY_IDS.includes(selectedCategoryId)) {
        this.navLinkHref = process.env.VUE_APP_IFRAME_TICKETS_MOBILE_WHOLESALE_PRODUCTS

      } else if (PRODUCT_CATEGORY_ITEMS[selectedCategoryId]) {
        await this.fetchCaseList({
          tscId: this.tscId,
          isAllCasesRequested: this.isAllCasesRequested,
          query: subscriptionId,
          ...!subscriptionId && { category: selectedCategoryId }
        })
      }

      this.changeIsCasesShown(true)
    },
    handleRowClick (val) {
      this.$router.push({
        name: RouteName.FaultReport,
        params: {
          id: val.caseId,
          locale: this.$route.params.locale
        }
      })
    },
    hasAnyWebtabRole
  }
}
</script>
<style lang="scss" scoped>
@use "@purpurds/tokens/color/variables" as colors;
@import "@/main.scss";
@import "tws-core-atoms/variables.scss";

.search-icon {
  position: absolute;
  margin-left: 1rem;
  margin-top: 1rem;
}

.loading-spinner {
  position: absolute;
  left: 90%;
  top: 67%;
}

.fault-reporting-table {
  ::v-deep.table {
    width:auto;
  }

  &-asset {
    font-weight: bold;
  }
}

.search-field {
  input {
    background-color: $light-grey;
    border-radius: 50px;
    border-color: $light-grey;
    padding-left: 46px;
  }

  &__icon {
    position: absolute;
    margin-left: 1rem;
    margin-top: 1rem;
  }
}

.no-results-message-column {
  display: flex;
  justify-content: center;

  .preamble {
    border: 1px solid $medium-grey;
    padding: 20px 30px;
    max-width: 790px;
  }
}

.centered {
  align-items: center;
}

::v-deep.fault-reporting__cta--link a {
  &:hover {
    color: colors.$purpur-color-purple-800;
  }
}

</style>
