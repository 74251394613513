<template>
  <div class="row generic-banner">
    <div class="col-md-9 col-12">
      <h1
        v-once
        class="generic-banner__header">
        {{ header }}
      </h1>
      <p
        v-if="description"
        v-once
        class="generic-banner__description">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    header: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import "tws-core-atoms/atoms/typography.scss";

.generic-banner {
  &__header {
    @extend .title-500;
    color: $black;
    margin-bottom: 20px;
  }

  &__description {
    @extend .subsection-100;
    color: $black;
  }
}

</style>
