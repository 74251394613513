<template>
  <AddressElementPicker
    :title="title"
    :sub-title="$t('address-picker.post-number.sub-title')"
    :options="postNumbers"
    :can-go-back="canGoBack"
    @select="$emit('post-number-pick', $event.value)"
    @go-back="$emit('post-number-pick', null)"
    @close="$emit('close')" />
</template>

<script>
import AddressElementPicker from './AddressElementPicker'
import { printAddress } from '@/apps/address-search/utils/string-utils'

const reduceToPostNumber = (agg, address) => {
  agg.add(address.postNumber)
  return agg
}

export default {
  components: { AddressElementPicker },
  props: {
    addresses: {
      type: Array,
      required: true
    },
    pickedCity: {
      type: String,
      required: true
    },
    pickedStreet: {
      type: String,
      required: true
    },
    pickedStreetNumber: {
      type: String,
      required: true
    },
    canGoBack: {
      type: Boolean,
      default: false
    },
    titlePrefix: {
      type: String,
      default: ''
    }
  },
  computed: {
    title () {
      return this.titlePrefix + printAddress({
        city: this.pickedCity,
        streetName: this.pickedStreet,
        streetNumber: this.pickedStreetNumber
      })
    },
    postNumbers () {
      return Array
        .from(this.addresses.reduce(reduceToPostNumber, new Set()))
        .map(postNumber => ({
          label: postNumber,
          value: postNumber
        }))
    }
  }
}
</script>
