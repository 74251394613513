<template>
  <ul class="sub-categories">
    <template v-for="(category, index) in categories">
      <li
        v-if="category.public || isLoggedIn"
        :key="index">
        <tws-nav-link :href="category.link">
          {{ category.title }}
        </tws-nav-link>
      </li>
    </template>
  </ul>
</template>

<script>
import { TwsNavLink } from 'tws-vue-components'
import { mapGetters } from 'vuex'

export default {
  components: {
    TwsNavLink
  },
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('eamCookies', ['isLoggedIn'])
  }
}
</script>

<style lang="scss" scoped>
.sub-categories {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 991px) {
  .sub-categories li {
    margin-top: 11px;
  }
}
</style>
