<template>
  <div :class="computeColumnWidth">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TwsFormColumn',
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  computed: {
    computeColumnWidth () {
      return 'col-' + this.labels.column_width
    }
  }
}
</script>

<style lang="scss" scoped>
  .column-wrapper {
    display: flex;
  }
</style>
