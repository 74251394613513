<script>
import { arrowRight } from '@purpurds/icon'
import PurpurLink from '../purpur/PurpurLink.vue'
import PurpurIcon from '../purpur/PurpurIcon.vue'
import PurpurHeading from '../purpur/PurpurHeading.vue'
import PurpurParagraph from '../purpur/PurpurParagraph.vue'

export default {
  components: {
    PurpurLink,
    PurpurIcon,
    PurpurHeading,
    PurpurParagraph
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    publishDate: {
      type: String,
      required: true
    },
    linkUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    leadParagraph: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      arrowRight
    }
  }
}

</script>

<template>
  <article class="news-preview">
    <PurpurParagraph
      variant="additional-100"
      class="news-preview__publish-date">
      {{ publishDate }}
    </PurpurParagraph>

    <PurpurHeading
      tag="h3"
      :variant="index ? 'title-100' : 'title-200'"
      class="news-preview__title">
      {{ title }}
    </PurpurHeading>

    <div class="news-preview__content">
      <PurpurParagraph
        class="news-preview__paragraph"
        :variant="index ? 'paragraph-100' : 'preamble-100'">
        {{ leadParagraph }}
      </PurpurParagraph>

      <slot />
    </div>

    <PurpurLink
      variant="standalone"
      :href="linkUrl">
      {{ $t("public-landing.common.read_more") }}

      <PurpurIcon
        class="news-preview__icon"
        :svg="arrowRight" />
    </PurpurLink>
  </article>
</template>

<style lang="scss" scoped>
@use "@purpurds/tokens/color/variables" as colors;
@use "@purpurds/tokens/breakpoint/variables" as breakpoints;
@use "../../assets/scss/mixins" as mixins;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;
@use 'src/apps/public-landing/assets/scss/purpur-scale-variables' as scales;

$border-line: 1px solid colors.$purpur-color-transparent-black-150;

.news-preview {
  border-bottom: $border-line;
  padding-bottom: spacing.$purpur-spacing-400;

  &:not(.news-preview:first-child) {
    .news-preview__content {
      @include mixins.line-clamp(4);
    }
  }

  @media screen and (min-width: breakpoints.$purpur-breakpoint-md) {
    padding-right: spacing.$purpur-spacing-400;

    &:nth-child(2) {
      border-bottom: none;
      border-right: $border-line;
      padding-bottom: 0px;
    }
  }

  @media screen and (min-width: breakpoints.$purpur-breakpoint-lg) {
    border-right: $border-line;

    &:first-child,
    &:last-child {
      border-bottom: none;
      padding-bottom: 0px
    }

    &:nth-child(2) {
      border-right: none;
      border-bottom: $border-line;
      padding-bottom: spacing.$purpur-spacing-400;
    }

    &:not(.news-preview:first-child) {
      .news-preview__content {
        @include mixins.line-clamp(2);
      }
    }
  }

  &:first-child {
    @media screen and (max-width: breakpoints.$purpur-breakpoint-md) {
      ::v-deep .news-preview__title > h3 > div > span {
        font-size: clamp(#{scales.$purpur-typography-scale-100}, 3vw, #{scales.$purpur-typography-scale-200});
        line-height: scales.$purpur-typography-line-height-default;
      }
    }

    @media screen and (max-width: breakpoints.$purpur-breakpoint-md) {
      ::v-deep .news-preview__paragraph > p > div > span {
        font-size: scales.$purpur-typography-scale-100;
        line-height: scales.$purpur-typography-line-height-loose;
      }
    }

    .news-preview__content {
      @include mixins.line-clamp(9);
    }

    @media screen and (min-width: breakpoints.$purpur-breakpoint-md) {
      grid-column: span 2;
    }

    @media screen and (min-width: breakpoints.$purpur-breakpoint-lg) {
      grid-row: span 2;
      grid-column: 1;
      padding-right: spacing.$purpur-spacing-800;
    }
  }

  &:last-child {
    border: none;
  }

  &__content {
    display: grid;
    gap: spacing.$purpur-spacing-200;
    margin-bottom: spacing.$purpur-spacing-200;
  }
}

::v-deep.news-preview__publish-date span {
  color: colors.$purpur-color-transparent-black-600;
  margin-bottom: spacing.$purpur-spacing-50;
}

::v-deep.news-preview__title div span {
  margin-bottom: spacing.$purpur-spacing-200;
}

::v-deep.news-preview__icon span {
  width: spacing.$purpur-spacing-200;
  height: spacing.$purpur-spacing-200;
}

.news-preview__title div span {
  background: #990AE3;
  font-size: clamp(#{scales.$purpur-typography-scale-100}, 3vw, #{scales.$purpur-typography-scale-200});
  line-height: scales.$purpur-typography-line-height-default;
}
</style>
