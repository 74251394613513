<template>
  <CommonPageView
    class="tws-area-search"
    :breadcrumb-items="breadcrumbItems"
    :loading="false"
    :banner="banner">
    <template #content>
      <div class="container">
        <div class="row mt-40">
          <div class="col-sm-2">
            <tws-checkbox
              id="building-phase"
              v-model="areaSearchRequest.buildPhase"
              :label="$t('area-search.build-phase')" />
          </div>
          <div class="col-sm-2">
            <tws-checkbox
              id="after-market-phase"
              v-model="areaSearchRequest.afterMarketPhase"
              :label="$t('area-search.market-phase')" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <tws-dropdown-group
              id="fmo-villa"
              v-model="areaSearchRequest.fiberMarketArea"
              :disabled="loading || exception || areaSearchRequest.postalCode.length > 0 || areaSearchRequest.accessPoint.length > 0"
              :label="$t('area-search.fiber-market-area')">
              <option value="">
                {{ $t('common.select') }}
              </option>
              <option
                v-for="area in fiberMarketAreas"
                :key="area"
                :value="area">
                {{ area }}
              </option>
            </tws-dropdown-group>
          </div>
          <div class="col-md-3">
            <tws-text-input-group
              id="postal-code"
              v-model="areaSearchRequest.postalCode"
              :disabled="areaSearchRequest.fiberMarketArea.length > 0 || areaSearchRequest.accessPoint.length > 0"
              :label="$t('area-search.post-code')"
              @enterKeyPressed="submitRequest(areaSearchRequest)" />
          </div>
          <div class="col-md-3">
            <tws-dropdown-group
              id="access-point"
              v-model="areaSearchRequest.accessPoint"
              :disabled="loading || exception || areaSearchRequest.fiberMarketArea.length > 0 || areaSearchRequest.postalCode.length > 0"
              :label="$t('area-search.access-point')">
              <option value="">
                {{ $t('common.select') }}
              </option>
              <option
                v-for="accessPoint in accessPoints"
                :key="accessPoint"
                :value="accessPoint">
                {{ accessPoint }}
              </option>
            </tws-dropdown-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <tws-text-input-group
              id="report-name"
              v-model="areaSearchRequest.reportName"
              :label="$t('area-search.report-name')"
              @enterKeyPressed="submit(areaSearchRequest)" />
          </div>
        </div>
        <div class="row my-10">
          <div class="col">
            <button
              :disabled="!canSubmit || requestLoading"
              type="button"
              class="button button--primary"
              @click="submit(areaSearchRequest)">
              {{ $t('area-search.submit') }}
            </button>
          </div>
        </div>
        <div
          v-show="requestException"
          class="row mt-50">
          <div class="col">
            <InfraPageAlert
              type="danger">
              {{ $t('area-search.exception') }}
            </InfraPageAlert>
          </div>
        </div>
        <div
          v-show="!requestException && requestLoaded"
          class="row">
          <div class="col">
            <div class="row mt-50">
              <div class="col">
                <h2 class="heading-m heading-m--black">
                  {{ $t('area-search.order-sent-title') }}
                </h2>
                <p>{{ $t('area-search.order-sent') }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 report-title">
                {{ $t('area-search.report-name') }}
              </div>
              <div class="col">
                {{ areaSearchRequestEdited.reportName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 report-title">
                {{ $t('area-search.format') }}
              </div>
              <div class="col">
                .csv
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 report-title">
                {{ $t('area-search.delivery-date') }}
              </div>
              <div class="col">
                {{ deliveryDate }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 report-title">
                {{ $t('area-search.phase') }}
              </div>
              <div class="col">
                {{ areaSearchRequestEdited.buildPhase ? $t('area-search.build-phase') : '' }}
                {{ areaSearchRequestEdited.buildPhase && areaSearchRequestEdited.afterMarketPhase ? '&' : '' }}
                {{ areaSearchRequestEdited.afterMarketPhase ? $t('area-search.market-phase') : '' }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 report-title">
                {{ $t('area-search.fiber-market-area') }}
              </div>
              <div class="col">
                {{ areaSearchRequestEdited.fiberMarketArea }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 report-title">
                {{ $t('area-search.post-code') }}
              </div>
              <div class="col">
                {{ areaSearchRequestEdited.postalCode }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 report-title">
                {{ $t('area-search.access-point') }}
              </div>
              <div class="col">
                {{ areaSearchRequestEdited.accessPoint }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import CommonPageView from '@/views/CommonPageView'
import {
  mapActions,
  mapState
} from 'vuex'

import {
  TwsCheckbox,
  TwsDropdownGroup,
  InfraPageAlert,
  TwsTextInputGroup
} from 'tws-vue-components'
import { BREADCRUMBS_AREA_SEARCH } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    CommonPageView,
    TwsCheckbox,
    TwsDropdownGroup,
    InfraPageAlert,
    TwsTextInputGroup
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_AREA_SEARCH,
      banner: {
        heading: this.$t('area-search.title')
      },
      areaSearchRequest: {
        buildPhase: false,
        afterMarketPhase: false,
        fiberMarketArea: '',
        postalCode: '',
        accessPoint: '',
        reportName: ''
      },
      areaSearchRequestEdited: {
        buildPhase: false,
        afterMarketPhase: false,
        fiberMarketArea: '',
        postalCode: '',
        accessPoint: '',
        reportName: ''
      }
    }
  },
  computed: {
    ...mapState('areaSearch', [
      'loading',
      'loaded',
      'exception',
      'fiberMarketAreas',
      'accessPoints',
      'deliveryDate',
      'requestLoading',
      'requestLoaded',
      'requestException'
    ]),
    canSubmit () {
      if (!this.areaSearchRequest.buildPhase && !this.areaSearchRequest.afterMarketPhase) {
        return false
      }

      if (!this.areaSearchRequest.fiberMarketArea && !this.areaSearchRequest.postalCode && !this.areaSearchRequest.accessPoint) {
        return false
      }

      return true
    }
  },
  mounted () {
    this.loadAreaSearchChoices()
  },
  methods: {
    ...mapActions({
      loadAreaSearchChoices: 'areaSearch/loadAreaSearchChoices',
      submitRequest: 'areaSearch/submitRequest'
    }),
    submit (request) {
      this.submitRequest(request).then(() => {
        this.areaSearchRequestEdited.reportName = request.reportName
        this.areaSearchRequestEdited.buildPhase = request.buildPhase
        this.areaSearchRequestEdited.afterMarketPhase = request.afterMarketPhase
        this.areaSearchRequestEdited.fiberMarketArea = request.fiberMarketArea
        this.areaSearchRequestEdited.postalCode = request.postalCode
        this.areaSearchRequestEdited.accessPoint = request.accessPoint
        this.areaSearchRequestEdited.reportName = request.reportName
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.my-10 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mt-40 {
  margin-top: 4rem;
}
.mt-50 {
  margin-top: 5rem;
}

.report-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

</style>
