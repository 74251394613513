import OperationalNewsService from '@/services/operational-news.service'

export const ActualOperationalNewsModule = {
  namespaced: true,
  state: {
    news: [],
    loading: false,
    loaded: false,
    exception: false
  },
  mutations: {
    setNews (state, news) {
      state.news = news
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    }
  },
  actions: {
    fetchNews ({ commit }) {
      commit('setLoading', true)
      commit('setLoaded', false)

      return OperationalNewsService.getActualNews()
        .then((response) => {
          commit('setNews', response)
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  }
}
