import { FORM_DESCRIPTION_PROPERTIES } from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  namespaced: true,
  getters: {
    stateByNamespace: (state, getters, rootState) => (formNamespace) => rootState.FaultReporting.FaultReportForm[formNamespace],
    basicInfo: (state, getters, rootState) => rootState.FaultReporting.FaultReportForm.BasicInfo,
    watchlist: (state, getters, rootState, rootGetters) => rootGetters['FaultReporting/FaultReportForm/Notifications/watchlist'],
    c2bcacheuid: (state, getters, rootState) => rootState.FaultReporting.C2bCache.selectedAsset && rootState.FaultReporting.C2bCache.selectedAsset.c2bcacheuid,
    tscId: (state, getters, rootState, rootGetters) => rootGetters['FaultReporting/C2bCache/tscId']
  },
  actions: {
    resetGlobalFormsState ({ dispatch }) {
      dispatch('FaultReporting/FaultReportForm/BasicInfo/resetState', '', {
        root: true
      })
      dispatch('FaultReporting/FaultReportForm/GeneralCase/resetState', '', {
        root: true
      })
      dispatch('FaultReporting/FaultReportForm/InterconnectCase/resetState', '', {
        root: true
      })
      dispatch('FaultReporting/FaultReportForm/OpenFiberCase/resetState', '', {
        root: true
      })
      dispatch('FaultReporting/FaultReportForm/Notifications/resetState', '', {
        root: true
      })
      dispatch('FaultReporting/FaultReportForm/MobileSP/resetState', '', {
        root: true
      })
    },
    async sendFormByNamespace ({ dispatch, getters }, namespace) {
      const problemType = getters.basicInfo?.problemType ? {
        [FORM_DESCRIPTION_PROPERTIES.PROBLEM_TYPE]: getters.basicInfo.problemType.value
      } : {}

      const commonProperties = {
        [FORM_DESCRIPTION_PROPERTIES.CUSTOMER_REF_NUMBER]: getters.basicInfo.customerRefNumber,
        [FORM_DESCRIPTION_PROPERTIES.FAULT_CATEGORY]: getters.basicInfo.faultCategory.value,
        ...problemType,
        ...getters.watchlist && getters.watchlist.length && {
          [FORM_DESCRIPTION_PROPERTIES.WATCHLIST]: getters.watchlist
        },
        c2bcacheuid: getters.c2bcacheuid,
        tscId: getters.tscId
      }

      await dispatch(`FaultReporting/FaultReportForm/${namespace}/sendForm`, commonProperties, {
        root: true
      })
    }
  }
}
