export const ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PASSWORD_EXPIRED: 'PASSWORD EXPIRED',
  LOCKED: 'LOCKED',
  LOCKED_AND_EXPIRED: 'LOCKED AND EXPIRED',
  SYSTEM_INACTIVE: 'SYSTEM INACTIVE'
}

export const ACCOUNT_STATUS_TEXT = {
  [ACCOUNT_STATUS.ACTIVE]: 'user-administration.statuses.active',
  [ACCOUNT_STATUS.INACTIVE]: 'user-administration.statuses.inactive',
  [ACCOUNT_STATUS.PASSWORD_EXPIRED]: 'user-administration.statuses.password-expired',
  [ACCOUNT_STATUS.LOCKED_AND_EXPIRED]: 'user-administration.statuses.locked-and-expired'
}

export const ACCOUNT_STATUS_TEXT_LIST = [
  {
    key: ACCOUNT_STATUS.ACTIVE,
    value: ACCOUNT_STATUS_TEXT[ACCOUNT_STATUS.ACTIVE]
  },
  {
    key: ACCOUNT_STATUS.INACTIVE,
    value: ACCOUNT_STATUS_TEXT[ACCOUNT_STATUS.INACTIVE]
  }
]
