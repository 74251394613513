<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <company-details-banner />
    </InfraBreadcrumbContainer>

    <div class="company-details-container">
      <div
        v-if="loading || loadException"
        class="container">
        <div class="row">
          <div class="col-12">
            <InfraPageAlert
              v-if="loading"
              type="info">
              {{ $t('company-details.loading') }}
            </InfraPageAlert>

            <InfraPageAlert
              v-if="loadException"
              type="danger">
              {{ $t('company-details.loading-failed') }}
            </InfraPageAlert>
          </div>
        </div>
      </div>

      <template v-else>
        <tws-user-edit-container
          :editing="editing"
          :validator="$v.editableInformation"
          :updating="updating"
          :title="$t('company-details.heading')"
          @toggleEdit="onEditToggle"
          @confirmEdit="onEditConfirm">
          <div class="row">
            <div class="col-12">
              <InfraPageAlert
                v-show="updateException"
                type="danger"
                class="update-exception">
                {{ $t('company-details.messages.exception') }}
              </InfraPageAlert>
            </div>
          </div>

          <tws-company-information-edit
            v-if="editing"
            :information="editableInformation"
            :validator="$v.editableInformation"
            :creation="false" />

          <tws-company-information-display
            v-if="!editing"
            :information="information" />
        </tws-user-edit-container>

        <tws-company-history :information="information" />
      </template>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import {
  companyCode,
  orgNumber
} from '@/common/validation-rules'

import {
  mapActions,
  mapState
} from 'vuex'

import CompanyInformationEdit from '@/components/company-administration/company-information-edit.vue'
import CompanyInformationDisplay from './company-information-display.vue'
import UserEditContainer from '@/components/common/user-edit-container.vue'
import CompanyHistory from './company-history.vue'

import { InfraPageAlert } from 'tws-vue-components'
import CompanyDetailsBanner from './company-details-banner.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_COMPANY_DETAILS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    'tws-company-information-edit': CompanyInformationEdit,
    'tws-company-information-display': CompanyInformationDisplay,
    'tws-user-edit-container': UserEditContainer,
    'tws-company-history': CompanyHistory,
    InfraPageAlert,
    CompanyDetailsBanner,
    InfraBreadcrumbContainer
  },
  mixins: [validationMixin],
  data () {
    return {
      companyId: this.$route.query.companyId,
      editing: false,
      editableInformation: {},
      breadcrumbItems: BREADCRUMBS_COMPANY_DETAILS
    }
  },
  computed: {
    ...mapState('companyDetails', [
      'loading',
      'updating',
      'loadException',
      'updateException',
      'information'
    ])
  },
  mounted () {
    this.fetchCompany(this.companyId)
  },
  methods: {
    ...mapActions('companyDetails', [
      'fetchCompany',
      'updateCompany',
      'editingCanceled'
    ]),
    onEditToggle () {
      this.editing = !this.editing
      this.editableInformation = Object.assign({}, this.information)

      if (!this.editing) {
        this.editingCanceled()
      }
    },
    onEditConfirm () {
      this.updateCompany(this.editableInformation).then(() => {
        if (!this.updateException) {
          this.editing = false
        }
      })
    }
  },
  validations: {
    editableInformation: {
      code: {
        required,
        companyCode
      },
      name: {
        required
      },
      orgNr: {
        required,
        orgNumber
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 45px;
}

.update-exception {
  margin-bottom: 20px;
}
</style>
