<template>
  <div>
    <template v-if="loading">
      <InfraHeroPlaceholder />

      <InfraNewsListPlaceholder />
    </template>

    <div
      v-else-if="exception"
      class="container exception-alert">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6">
          <InfraPageAlert
            type="danger">
            {{ $t('content.exception') }}
          </InfraPageAlert>
        </div>
      </div>
    </div>

    <template v-else>
      <GrootHeroBanner
        :title="banner.heading"
        :preamble="banner.description"
        :breadcrumbs="breadcrumbItems">
        <slot name="banner" />
      </GrootHeroBanner>
      <div
        v-if="$slots.breadcrumb"
        class="container">
        <slot name="breadcrumb" />
      </div>

      <div :class="contentWrapperClass">
        <slot name="content" />
      </div>
    </template>
  </div>
</template>

<script>
import {
  InfraHeroPlaceholder,
  InfraNewsListPlaceholder,
  InfraPageAlert
} from 'tws-vue-components'
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import { GrootHeroBanner } from 'groot-components'

export default {
  components: {
    InfraNewsListPlaceholder,
    InfraHeroPlaceholder,
    InfraPageAlert,
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  props: {
    banner: {
      type: Object,
      default: () => {}
    },
    exception: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    isGreyBackground: {
      type: Boolean,
      default: true
    },
    breadcrumbItems: {
      type: Array,
      default: () => []
    },
    backgroundImage: {
      type: String,
      default: null
    },
    contentWrapperClass: {
      type: String,
      default: 'container content mt-5'
    },
    useInfraGenericBanner: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .infra-generic-banner__heading {
  color: black;
}
.exception-alert {
  margin-top: 5rem;
}
</style>
