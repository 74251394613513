<template>
  <div>
    <GrootHeroBanner
      :title="$t('individual-rights.heading')"
      :preamble="$t('individual-rights.description')"
      :breadcrumbs="breadcrumbItems" />
    <div class="form-container">
      <component
        :is="form"
        v-if="html" />
    </div>
  </div>
</template>

<script>
import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_INDIVIDUAL_RIGHTS } from '@/constants/breadcrumbs-constants'
import { isLocaleEnglish } from '@/utils/locale.utils'
import GenericBanner from '@/components/common/generic-banner.vue'
import { GrootHeroBanner } from 'groot-components'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  components: {
    InfraBreadcrumbContainer,
    GenericBanner,
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_INDIVIDUAL_RIGHTS,
      loading: true,
      html: ''
    }
  },
  computed: {
    form () {
      return {
        template: this.html
      }
    }
  },
  mounted () {
    this.loadContent()
  },
  methods: {
    loadContent () {
      const htmlContent = isLocaleEnglish() ?
        require('./html/individual-rights-en.html').default :
        require('./html/individual-rights-sv.html').default

      this.loading = false
      this.html = htmlContent
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 45px;
}
</style>
