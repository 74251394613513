import router from '@/router/router'

import ContentService from '@/apps/products/services/content.service'

import {
  extractHero,
  extractPageIcons
} from '@/apps/products/store/helpers/category.helper'

import {
  extractProduct, extractProducts
} from '@/apps/products/store/helpers/product.helper'

export default {
  namespaced: true,
  state: {
    loading: null,
    exception: null,
    category: null,
    product: null
  },
  mutations: {
    loading: (state, loading) => state.loading = loading,
    exception: (state, exception) => state.exception = exception,
    category: (state, category) => state.category = category,
    product: (state, product) => state.product = product
  },
  actions: {
    async loadCategory ({ commit, state }, isLoadingTerminated = true) {
      const category = router.currentRoute.params.category

      if (state.category && state.category.path === category) {
        return
      }

      commit('loading', true)
      commit('exception', false)

      try {
        const response = await ContentService.fetchContent(category, 'twsProductsCategory', 1)

        commit('category', {
          title: response.title,
          path: response.path,
          hero: extractHero(response.hero),
          products: extractProducts(response),
          pageIcons: extractPageIcons(response)
        })

      } catch (error) {
        commit('exception', true)
        throw error
      }
      if (isLoadingTerminated) {
        commit('loading', false)
      }

    },
    async loadProduct ({ commit, state }) {
      const product = router.currentRoute.params.product

      if (state.product && state.product.path === product) {
        return
      }

      commit('loading', true)
      commit('exception', false)

      try {
        const response = await ContentService.fetchContent(product, 'twsProduct', 5)

        commit('product', extractProduct(response))
      } catch (error) {
        commit('exception', true)
        throw error
      }

      commit('loading', false)
    }
  }
}
