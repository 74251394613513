import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { InfraLocaleUtils } from 'tws-vue-components'
import { getSelectedLocale } from '@/utils/locale.utils'

import sv from '@/locales/sv.json'
import en from '@/locales/en.json'

import svContactUs from '@/apps/contact-us/locales/sv.json'
import enContactUs from '@/apps/contact-us/locales/en.json'

import svCustomerTicketCreate from '@/apps/customer-tickets/locales/sv.json'
import enCustomerTicketCreate from '@/apps/customer-tickets/locales/en.json'

import svAddressSearch from '@/apps/address-search/locales/sv.json'
import enAddressSearch from '@/apps/address-search/locales/en.json'

import svFaultReporting from '@/apps/fault-reporting/locales/sv.json'
import enFaultReporting from '@/apps/fault-reporting/locales/en.json'

import svProducts from '@/apps/products/locales/sv.json'
import enProducts from '@/apps/products/locales/en.json'

import svLanding from '@/apps/public-landing/locales/sv.json'
import enLanding from '@/apps/public-landing/locales/en.json'

Vue.use(VueI18n)

const svMessages = Object.assign(
  sv,
  svContactUs,
  svCustomerTicketCreate,
  svAddressSearch,
  svFaultReporting,
  svProducts,
  svLanding
)
const enMessages = Object.assign(
  en,
  enContactUs,
  enCustomerTicketCreate,
  enAddressSearch,
  enFaultReporting,
  enProducts,
  enLanding
)

export default new VueI18n({
  locale: getSelectedLocale(),
  fallbackLocale: InfraLocaleUtils.LOCALES.SV,
  messages: {
    sv: svMessages,
    en: enMessages
  }
})
