<template>
  <div class="address-element-picker">
    <InfraModalHeader
      :title="title"
      :can-go-back="canGoBack"
      @go-back="$emit('go-back')"
      @close="$emit('close')" />

    <p class="address-element-picker__sub-title">
      {{ subTitle }}
    </p>

    <ul class="option-list">
      <li
        v-for="option in options"
        :key="option.value"
        class="option-list__item-container"
        :class="{ [`option-list__item-container--${option.width}-wide`]: !!option.width }">
        <button
          class="option-list__item"
          @click="$emit('select', option)">
          {{ option.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { InfraModalHeader } from 'tws-vue-components'

export default {
  components: {
    InfraModalHeader
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    canGoBack: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
@import "tws-core-atoms/variables.scss";

.address-element-picker {
  padding: 30px;

  &__sub-title {
    margin: 18px 0 28px;
  }
}

.option-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -6px;
  padding: 0;

  &__item {
    cursor: pointer;
    display: block;
    text-align: center;
    background-color: transparent;
    padding: 8px 0;
    width: 100%;
    border: 1px solid $medium-grey;
    border-radius: 3px;
    font-family: inherit;
    font-size: inherit;

    &:hover {
      color: inherit;
      border-color: $core-purple;
    }
  }

  &__item-container {
    display: flex;
    width: calc(100% / 7 - 12px);
    margin: 6px;

    &--half-wide {
      width: calc(50% - 12px);

      button {
        padding: 8px 16px;
        text-align: left;
      }
    }
  }
}

$widths: ( 2, 3, 4, 5, 6, 7 );

@each $width in $widths {
  .option-list__item-container--#{$width}-wide {
    width: calc((100% / 7) * #{$width} - 12px);
    @if $width > 2 {
      button {
        padding: 8px 16px;
        text-align: left;
      }
    }
  }
}

</style>
