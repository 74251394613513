<template>
  <div
    class="menu-item"
    :class="{
      'menu-item--expanded': item.expanded,
      'menu-item--expandable': item.expandable
    }">
    <div
      class="menu-item__heading"
      role="button"
      @click="toggle">
      <div class="menu-item__title">
        <template v-if="item.expandable">
          {{ item.title }}
        </template>

        <LocalisedLink
          v-else-if="item.link"
          :to="item.link"
          exact
          class="menu-item__link">
          {{ item.title }}
        </LocalisedLink>
      </div>

      <div
        v-if="item.expandable"
        class="menu-item__icon">
        <tws-arrow-up-icon
          v-show="item.expanded"
          height="18"
          width="18"
          :view-box.camel="'0 0 16 16'" />

        <tws-arrow-down-icon
          v-show="!item.expanded"
          height="18"
          width="18"
          :view-box.camel="'0 0 16 16'" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import TwsArrowUpIcon from '@/img/svg/arrow-up-pink.svg'
import TwsArrowDownIcon from '@/img/svg/arrow-down.svg'

export default {
  components: {
    TwsArrowUpIcon,
    TwsArrowDownIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggle () {
      if (!this.item.expandable) {
        return
      }
      this.item.expanded = !this.item.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.menu-item {
  padding-top: 15.75px;
  padding-bottom: 15.75px;
  border-bottom: solid 1px $medium-grey;

  &--expanded {
    padding-bottom: 0;

    .menu-item__heading {
      color: $core-purple;
      margin-bottom: 18px;
    }

    .menu-item__icon svg path {
      fill: $core-purple;
    }
  }

  &--expandable {
    .menu-item__heading {
      display: flex;
    }

    .menu-item__title {
      flex-grow: 1;
    }
  }

  &__heading {
    padding-left: 30px;
    padding-right: 30px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
  }

  &__link {
    display: block;
  }

  &__icon svg {
    vertical-align: middle;

    path {
      fill: $black;
    }
  }
}
</style>
