<template>
  <div class="row contact-information">
    <div class="col-12">
      <template v-if="loading || exception">
        <InfraPageAlert
          v-if="loading"
          type="info">
          {{ $t('user-dashboard.loading') }}
        </InfraPageAlert>

        <InfraPageAlert
          v-if="exception"
          type="danger">
          {{ $t('user-dashboard.loading-failed') }}
        </InfraPageAlert>
      </template>

      <div
        v-else
        class="administrators-wrapper">
        {{ $t('user-dashboard.contact-administrator') }}<component :is="administratorsHtml" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

import {
  InfraPageAlert,
  TwsTextLink
} from 'tws-vue-components'

export default {
  components: {
    InfraPageAlert,
    TwsTextLink
  },
  computed: {
    ...mapState('userDashboard/administrators', [
      'loading',
      'exception',
      'administrators'
    ]),
    administratorsHtml () {
      const anchors = this.administrators.map(admin => `<tws-text-link href="mailto:${admin.email}">${admin.firstName} ${admin.lastName}</tws-text-link>`).join(', ')

      return {
        template: `<div class="administrators">${anchors ? ' (' + anchors + ')' : '.'}</div>`
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    ...mapActions('userDashboard/administrators', ['fetch'])
  }
}
</script>

<style lang="scss" scoped>
.contact-information {
  margin-top: 25px;
  margin-bottom: 25px;
}

.administrators {
  display: inline;
}
</style>
