export const CATEGORY = {
  ALL: 'ALL',
  NEWS: 'NEWS',
  PAGES: 'PAGES',
  DOCUMENTS: 'DOCUMENTS'
}

export const CATEGORIES = [
  CATEGORY.ALL,
  CATEGORY.PAGES,
  CATEGORY.NEWS
]

export const CATEGORY_LABEL = {
  [CATEGORY.ALL]: 'site-search.categories.all',
  [CATEGORY.PAGES]: 'site-search.categories.pages',
  [CATEGORY.NEWS]: 'site-search.categories.news',
  [CATEGORY.DOCUMENTS]: 'site-search.categories.documents'
}
