<template>
  <div class="operational-news-container">
    <div class="row">
      <div class="col-12">
        <h2 class="heading-m operational-news-heading">
          {{ $t('dashboard-container.operational-news.heading') }}
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="operational-news">
          <div
            v-for="(data, index) in news"
            :key="index"
            class="operational-new">
            <div>
              <TeliaCoreIcon
                v-if="data.isUrgentEvent"
                name="info_thin"
                size="3rem" />
              <TeliaCoreIcon
                v-else-if="isFutureDate(data.startDate)"
                name="megaphone_thin"
                size="3rem" />
              <TeliaCoreIcon
                v-else
                name="repair_thin"
                size="3rem" />
            </div>

            <div class="operational-new__content">
              <div
                class="operational-new__title"
                role="button"
                @click="openNewsItem = data">
                {{ getComputedTitle(data) }}
              </div>

              <div class="operational-new__date">
                <template v-if="data.isUrgentEvent">
                  <span class="severe">{{ $t('operational-news.severe') }}</span> {{ data.startDate | formatSevereDate }}
                </template>

                <template v-else>
                  {{ computeDate(data) }}
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row actions-row">
      <div class="col-sm-6 col-xl-4 action-column">
        <tws-action-button @click="openOperationalNewsPage">
          <TeliaCoreIcon
            class="icon"
            name="calendar_thin"
            size="3rem" />
          <span>{{ $t('dashboard-container.operational-news.action-button.op-news') }}</span>
        </tws-action-button>
      </div>

      <div class="col-sm-6 col-xl-4 action-column">
        <tws-action-button @click="openNewsArchivePage">
          <TeliaCoreIcon
            class="icon"
            name="folder_thin"
            size="3rem" />
          <span>{{ $t('dashboard-container.operational-news.action-button.news-archive') }}</span>
        </tws-action-button>
      </div>

      <div class="col-sm-6 col-xl-4 action-column">
        <tws-action-button @click="openNetworkInformationMapPage">
          <TeliaCoreIcon
            class="icon"
            name="network_thin"
            size="3rem" />
          <span>{{ $t('dashboard-container.operational-news.action-button.online-info') }}</span>
        </tws-action-button>
      </div>
    </div>

    <tws-operational-news-dialog
      v-if="openNewsItem"
      :news="openNewsItem"
      @close="openNewsItem = null" />
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'
import TwsOperationalNewsDialog from '@/components/operational-news/operational-news-dialog/operational-news-dialog'

import DateTimeUtils from '@/common/datetime-utils'
import { TwsActionButton } from 'tws-vue-components'
import { getComputedTitle } from '@/utils/news.utils'

export default {
  components: {
    TwsActionButton,
    TwsOperationalNewsDialog
  },
  filters: {
    formatSevereDate (date) {
      return DateTimeUtils.formatSevereDate(date)
    }
  },
  props: {
    netInfoLinkName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      openNewsItem: null
    }
  },
  computed: {
    ...mapState('frontpageOperationalNews', ['news'])
  },
  mounted () {
    this.fetchNews()
  },
  methods: {
    ...mapActions('frontpageOperationalNews', ['fetchNews']),
    isFutureDate (date) {
      return DateTimeUtils.isFutureDate(date)
    },
    computeDate (data) {
      return DateTimeUtils.computeDate(data.startDate, data.endDate)
    },
    openOperationalNewsPage () {
      this.$router.push({
        name: 'operational-news-view',
        params: this.$route.params
      })
    },
    openNewsArchivePage () {
      this.$router.push({
        name: 'news-archive',
        params: this.$route.params
      })
    },
    openNetworkInformationMapPage () {
      this.$router.push({
        name: this.netInfoLinkName,
        params: this.$route.params
      })
    },
    getComputedTitle
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.operational-new {
  display: flex;
  margin-bottom: 20px;
  line-height: 1;

  &__content {
    margin-left: 10px;
  }

  &__title {
    margin-bottom: 8px;
    color: $black;
    cursor: pointer;

    &:hover {
      color: $purple;
    }
  }
}

.operational-news {
  margin-top: 24px;
  margin-bottom: 13px;
}

.action-button {
  background-color: $light-grey;
}

.severe {
  color: $red;
  font-weight: bold;
}

.actions-row {
  margin: -5px;
}

.action-column {
  padding: 5px;
}

.operational-news-heading {
  font-size: 30px;
}
</style>
