<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <user-dashboard-banner />
    </InfraBreadcrumbContainer>

    <div class="container">
      <user-dashboard-information />
      <user-dashboard-administrators />
      <hr>
    </div>
  </div>
</template>

<script>

import UserDashboardAdministrators from './dashboard/user-dashboard-administrators.vue'
import UserDashboardBanner from './dashboard/user-dashboard-banner.vue'
import UserDashboardInformation from './dashboard/user-dashboard-information.vue'
//import UserDashboardNotificationPreferences from './user-dashboard-notification-preferences/user-dashboard-notification-preferences'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_DASHBOARD_SETTINGS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    UserDashboardInformation,
    UserDashboardAdministrators,
    UserDashboardBanner,
    InfraBreadcrumbContainer
    //'tws-user-dashboard-notification-preferences': UserDashboardNotificationPreferences
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_DASHBOARD_SETTINGS
    }
  }
}
</script>

  <style lang="scss" scoped>
  .container {
    margin-top: 45px;
  }

  hr {
    border: 2px solid #990ae3;;
  }
  </style>
