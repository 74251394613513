import InvoicesService from '@/services/invoices.service'

export const SORTING_KEY = {
  DATE_ASC: 'DATE_ASC',
  DATE_DESC: 'DATE_DESC',
  DATE_DUE_ASC: 'DATE_DUE_ASC',
  DATE_DUE_DESC: 'DATE_DUE_DESC'
}

const sortByDateAsc = (a, b) => {
  if (a.invoiceDate > b.invoiceDate) { return 1 }
  if (a.invoiceDate < b.invoiceDate) { return -1 }
  return 0
}

const sortByDateDesc = (a, b) => {
  if (a.invoiceDate > b.invoiceDate) { return -1 }
  if (a.invoiceDate < b.invoiceDate) { return 1 }
  return 0
}

const sortByDateDueAsc = (a, b) => {
  if (a.invoicePeriodEnd > b.invoicePeriodEnd) { return 1 }
  if (a.invoicePeriodEnd < b.invoicePeriodEnd) { return -1 }
  return 0
}

const sortByDateDueDesc = (a, b) => {
  if (a.invoicePeriodEnd > b.invoicePeriodEnd) { return -1 }
  if (a.invoicePeriodEnd < b.invoicePeriodEnd) { return 1 }
  return 0
}

const sorterMap = {
  [SORTING_KEY.DATE_DESC]: sortByDateDesc,
  [SORTING_KEY.DATE_ASC]: sortByDateAsc,
  [SORTING_KEY.DATE_DUE_DESC]: sortByDateDueDesc,
  [SORTING_KEY.DATE_DUE_ASC]: sortByDateDueAsc
}

export const InvoicesModule = {
  namespaced: true,
  state: {
    invoices: [],
    loading: false,
    loaded: false,
    exception: false,

    sortingKey: SORTING_KEY.DATE_DESC,
    searchTerm: '',
    limit: 10,
    offset: 0
  },
  mutations: {
    setInvoices (state, invoices) {
      state.invoices = invoices
      state.invoices.sort(sorterMap[state.sortingKey])
    },
    setSorting (state, sortingKey) {
      state.sortingKey = sortingKey
      state.invoices.sort(sorterMap[state.sortingKey])
      state.offset = 0
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    },
    setOffset (state, offset) {
      state.offset = offset
    },
    setSearchTerm (state, searchTerm) {
      state.searchTerm = searchTerm
      state.offset = 0
    }
  },
  getters: {
    filteredInvoices: (state) => {
      const normalizedTerm = (state.searchTerm || '').toLowerCase()

      return state.invoices.filter(item => !normalizedTerm
        || item.invoiceNumber.toLowerCase().includes(normalizedTerm)
        || item.invoiceDate.toLowerCase().includes(normalizedTerm)
        || item.dueDate.toLowerCase().includes(normalizedTerm)
        || !!item.specifications && !!item.specifications.length && item.specifications.find(element => element.id.toLowerCase().includes(normalizedTerm))
      )
    }
  },
  actions: {
    async loadInvoices ({ commit, rootGetters, rootState }) {
      commit('setLoading', true)
      commit('setLoaded', false)
      commit('setException', false)

      try {
        const superuser = rootGetters['eamCookies/isSuperUser']
        const admin = rootGetters['eamCookies/isAdmin']
        const billingAdmin = rootGetters['eamCookies/isBillingAdmin']

        const company = rootState.MyDocuments.company

        let invoices
        if ((superuser || admin || billingAdmin) && company) {
          invoices = await InvoicesService.getInvoicesForCompany(company.code)
        } else {
          invoices = await InvoicesService.getInvoices()
        }

        commit('setInvoices', invoices || [])
        commit('setLoaded', true)
      } catch (err) {
        if (!err.response || err.response.status !== 400) {
          commit('setException', true)
        }
      } finally {
        commit('setLoading', false)
      }
    },
    changeOffset ({ commit }, newOffset) {
      commit('setOffset', newOffset)
    },
    changeSearchTerm ({ commit }, newSearchTerm) {
      commit('setSearchTerm', newSearchTerm)
    },
    changeSorting ({ commit }, newSortingKey) {
      commit('setSorting', newSortingKey)
    }
  }
}
