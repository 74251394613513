import router from '@/router/router'
import ResetPasswordService from '@/services/reset-password.service'
import UserService from '@/services/user.service'

const getErrorReportingRole = roles => {
  if (roles.TROUBLE_TICKET) {
    return 'TROUBLE_TICKET'
  } else if (roles.TROUBLE_TICKET_PLUS) {
    return 'TROUBLE_TICKET_PLUS'
  } else if (roles.TROUBLE_TICKET_NO_ACCESS) {
    return 'TROUBLE_TICKET_NO_ACCESS'
  }
  return ''

}

const getNetBusinessRole = roles => {
  if (roles.NETBUSINESS_READ) {
    return 'NETBUSINESS_READ'
  } else if (roles.NETBUSINESS_QOUTE_CREATION) {
    return 'NETBUSINESS_QOUTE_CREATION'
  } else if (roles.NETBUSINESS_ORDER_CREATION) {
    return 'NETBUSINESS_ORDER_CREATION'
  } else if (roles.NETBUSINESS_MANAGE_LEVEL_NO_ACCESS) {
    return 'NETBUSINESS_MANAGE_LEVEL_NO_ACCESS'
  }
  return ''

}

const resetNetBusinessRoles = (information) => {
  information.roles.NETBUSINESS_READ = false
  information.roles.NETBUSINESS_QOUTE_CREATION = false
  information.roles.NETBUSINESS_ORDER_CREATION = false
  information.roles.NETBUSINESS_MANAGE_LEVEL_NO_ACCESS = false
}

const resetErrorReportingRoles = (information) => {
  information.roles.TROUBLE_TICKET = false
  information.roles.TROUBLE_TICKET_PLUS = false
  information.roles.TROUBLE_TICKET_NO_ACCESS = false
}

const constructPersonalInformation = source => {
  return {
    username: source.username,
    firstName: source.firstName,
    lastName: source.lastName,
    company: Object.assign({}, source.company),
    position: source.position,
    phone: source.phone,
    mobilePhone: source.mobilePhone,
    email: source.email,
    country: source.country,
    comments: source.comments,
    loginMethodPassword: source.loginMethodPassword,
    userId: source.userId,
    organizationId: source.company.id
  }
}

const constructAccountInformation = source => {
  return {
    userId: source.userId,
    username: source.username,
    loginMethodPassword: source.loginMethodPassword,
    accountStatus: source.accountStatus,
    accountExpirationDate: source.accountExpirationDate,
    roles: Object.assign({}, source.roles),
    errorReporting: source.errorReporting,
    manageNBM: source.manageNBM
  }
}

export const UserDetailsModule = {
  namespaced: true,
  state: {
    informationLoading: false,
    informationLoaded: false,
    informationException: false,
    information: {}
  },
  mutations: {
    setLoading (state, loading) {
      state.informationLoading = loading
    },
    setLoaded (state, loaded) {
      state.informationLoaded = loaded
    },
    setException (state, exception) {
      state.informationException = exception
    },
    setInformation (state, information) {
      state.information = Object.assign({}, information)
    }
  },
  getters: {
    isLocked (state) {
      return state.information.accountStatus === 'LOCKED'
    },
    getFullName (state) {
      return state.information.firstName + ' ' + state.information.lastName
    },
    usernameContainsSpId (state, getters, rootState) {
      const { company, username } = rootState.userDetails.personalInformation.editableInformation

      return company && company.spId && username.endsWith(company.spId)
    }
  },
  actions: {
    fetchUser ({ commit, dispatch }, userDetails) {
      commit('setLoading', true)

      return UserService
        .getUserDetails(userDetails)
        .then(information => {
          information.errorReporting = getErrorReportingRole(information.roles)
          information.manageNBM = getNetBusinessRole(information.roles)

          commit('setInformation', information)

          dispatch('userDetails/personalInformation/informationLoaded', information, {
            root: true
          })
          dispatch('userDetails/accountInformation/informationLoaded', information, {
            root: true
          })

          commit('setLoaded', true)

          dispatch('accountHistory/fetch', information.userId, {
            root: true
          })
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    userUnlocked ({ commit, state, dispatch }) {
      const information = Object.assign({}, state.information)
      information.accountStatus = 'ACTIVE'

      commit('setInformation', information)

      dispatch('userDetails/personalInformation/informationLoaded', information, {
        root: true
      })
      dispatch('userDetails/accountInformation/informationLoaded', information, {
        root: true
      })
      dispatch('accountHistory/fetch', information.userId, {
        root: true
      })
    },
    personalInformationUpdated ({ commit, state }, personalInformation) {
      const information = Object.assign({}, state.information)
      information.username = personalInformation.username
      information.firstName = personalInformation.firstName
      information.lastName = personalInformation.lastName
      information.company = Object.assign({}, personalInformation.company)
      information.position = personalInformation.position
      information.phone = personalInformation.phone
      information.mobilePhone = personalInformation.mobilePhone
      information.email = personalInformation.email
      information.country = personalInformation.country
      information.comments = personalInformation.comments

      commit('setInformation', information)
    },
    accountInformationUpdated ({ commit, state }, accountInformation) {
      const information = Object.assign({}, state.information)
      information.username = accountInformation.username
      information.loginMethodPassword = accountInformation.loginMethodPassword
      information.accountStatus = accountInformation.accountStatus
      information.accountExpirationDate = accountInformation.accountExpirationDate
      information.roles = Object.assign({}, accountInformation.roles)
      information.errorReporting = accountInformation.errorReporting
      information.manageNBM = accountInformation.manageNBM

      commit('setInformation', information)
    }
  },
  modules: {
    personalInformation: {
      namespaced: true,
      state: {
        updating: false,
        updated: false,
        exception: false,
        displayedInformation: {},
        editableInformation: {}
      },
      mutations: {
        setUpdating (state, updating) {
          state.updating = updating
        },
        setUpdated (state, updated) {
          state.updated = updated
        },
        setException (state, exception) {
          state.exception = exception
        },
        setDisplayedInformation (state, information) {
          state.displayedInformation = Object.assign({}, information)
        },
        setEditableInformation (state, information) {
          state.editableInformation = Object.assign({}, information)
        }
      },
      actions: {
        editableInformationChanged ({ commit }, information) {
          commit('setEditableInformation', information)
        },
        resetEditableInformation ({ commit, rootState }) {
          commit('setEditableInformation', constructPersonalInformation(rootState.userDetails.information))
        },
        informationLoaded ({ commit }, information) {
          commit('setDisplayedInformation', constructPersonalInformation(information))
          commit('setEditableInformation', constructPersonalInformation(information))
        },
        updateInformation ({ commit, dispatch, state }) {
          commit('setUpdating', true)
          commit('setUpdated', false)
          commit('setException', false)

          const information = state.editableInformation
          information['companyCode'] = information.company.code

          return UserService
            .updatePersonalInformation(information)
            .then(() => {
              dispatch('userDetails/personalInformationUpdated', information, {
                root: true
              })

              commit('setDisplayedInformation', information)
              commit('setUpdated', true)

              dispatch('accountHistory/fetch', information.userId, {
                root: true
              })
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setUpdating', false)
            })
        }
      }
    },
    accountInformation: {
      namespaced: true,
      state: {
        updating: false,
        updated: false,
        exception: false,
        displayedInformation: {},
        editableInformation: {}
      },
      mutations: {
        setUpdating (state, updating) {
          state.updating = updating
        },
        setUpdated (state, updated) {
          state.updated = updated
        },
        setException (state, exception) {
          state.exception = exception
        },
        setDisplayedInformation (state, information) {
          state.displayedInformation = Object.assign({}, information)
        },
        setEditableInformation (state, information) {
          state.editableInformation = Object.assign({}, information)
        }
      },
      actions: {
        editableInformationChanged ({ commit }, information) {
          commit('setEditableInformation', information)
        },
        resetEditableInformation ({ commit, rootState }) {
          commit('setEditableInformation', constructAccountInformation(rootState.userDetails.information))
        },
        informationLoaded ({ commit }, information) {
          commit('setDisplayedInformation', constructAccountInformation(information))
          commit('setEditableInformation', constructAccountInformation(information))
        },
        resetExceptions ({ commit }) {
          commit('setException', false)
        },
        updateInformation ({ commit, dispatch, state, rootState }) {
          commit('setUpdating', true)
          commit('setUpdated', false)
          commit('setException', false)

          const information = Object.assign({}, state.editableInformation)
          const displayedInformation = Object.assign({},
            rootState.userDetails.information)

          resetNetBusinessRoles(information)
          resetErrorReportingRoles(information)

          if (information.errorReporting) {
            information.roles[information.errorReporting] = true
          }

          if (information.manageNBM) {
            information.roles[information.manageNBM] = true
          }

          return UserService
            .updateAccountInformation(information)
            .then(() => {
              dispatch('userDetails/accountInformationUpdated', information, {
                root: true
              })

              commit('setDisplayedInformation', information)
              commit('setUpdated', true)

              dispatch('accountHistory/fetch', displayedInformation.userId, {
                root: true
              })
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setUpdating', false)
            })
        }
      }
    },
    deletion: {
      namespaced: true,
      state: {
        deleting: false,
        deletionException: false
      },
      mutations: {
        setDeleting (state, deleting) {
          state.deleting = deleting
        },
        setException (state, exception) {
          state.deletionException = exception
        }
      },
      actions: {
        deleteUser ({ commit }, { username, userId }) {
          commit('setDeleting', true)
          commit('setException', false)

          return UserService
            .delete(userId)
            .then(() => {
              const pathLocale = router.currentRoute.params?.locale ? `/${router.currentRoute.params.locale}` : ''
              router.push(`${pathLocale}${process.env.VUE_APP_USER_ADMINISTRATION_PATH}?redirect-action=deleted&username=${username}&userId=${userId}`)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setDeleting', false)
            })
        }
      }
    },
    unlock: {
      namespaced: true,
      state: {
        unlocking: false,
        unlocked: false,
        unlockException: false
      },
      mutations: {
        setUnlocking (state, unlocking) {
          state.unlocking = unlocking
        },
        setUnlocked (state, unlocked) {
          state.unlocked = unlocked
        },
        setException (state, exception) {
          state.unlockException = exception
        }
      },
      actions: {
        unlockUser ({ commit, dispatch }, userId) {
          commit('setUnlocking', true)
          commit('setUnlocked', false)
          commit('setException', false)

          return UserService
            .unlockUser(userId)
            .then(() => {
              dispatch('userDetails/userUnlocked', null, {
                root: true
              })

              commit('setUnlocked', true)

              dispatch('accountHistory/fetch', userId, {
                root: true
              })
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setUnlocking', false)
            })
        }
      }
    },
    passwordReset: {
      namespaced: true,
      state: {
        reseting: false,
        reset: false,
        resetException: false
      },
      mutations: {
        setReseting (state, reseting) {
          state.reseting = reseting
        },
        setReset (state, reset) {
          state.reset = reset
        },
        setException (state, exception) {
          state.resetException = exception
        }
      },
      actions: {
        resetPassword ({ commit, dispatch }, { username, email }) {
          commit('setReseting', true)
          commit('setReset', false)
          commit('setException', false)

          return ResetPasswordService.loggedResetPassword(username, email)
            .then(() => {
              commit('setReset', true)

              dispatch('accountHistory/fetch', username, {
                root: true
              })
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setReseting', false)
            })
        }
      }
    }
  }
}
