import NetworkInformationService from '@/services/network-information.service'
import MapUtils, {
  COUNTY_CODE_BY_NAME, STATUS_CODE_BY_NAME
} from '@/utils/map-utils'
import ContentfulWrapper from '@/services/contentful/contentful.wrapper'
import { InfraLocaleUtils } from 'tws-vue-components'

export const MapModule = {
  namespaced: true,
  modules: {
    serviceState: {
      namespaced: true,
      state: {
        loading: false,
        exception: false,
        serviceState: [],
        mapItems: {}
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setException (state, exception) {
          state.exception = exception
        },
        setServiceState (state, serviceState) {
          state.serviceState = serviceState
        },
        setMapItems (state, mapItems) {
          state.mapItems = mapItems
        }
      },
      actions: {
        fetchServiceState ({ commit }) {
          commit('setLoading', true)

          return NetworkInformationService.getServiceState()
            .then(serviceState => {
              const mapItems = {}
              serviceState.counties.forEach(c => mapItems[COUNTY_CODE_BY_NAME[c.county]] = {
                status: STATUS_CODE_BY_NAME[c.slaText],
                title: `${c.county} - ${c.slaText}`
              })

              commit('setServiceState', serviceState.counties)
              commit('setMapItems', mapItems)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        }
      }
    },
    deliveryTime: {
      namespaced: true,
      state: {
        loading: false,
        loadingContent: false,
        exception: false,
        deliveryTime: [],
        mapItems: {},
        content: {}
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setLoadingContent (state, loadingContent) {
          state.loadingContent = loadingContent
        },
        setException (state, exception) {
          state.exception = exception
        },
        setDeliveryTime (state, deliveryTime) {
          state.deliveryTime = deliveryTime
        },
        setMapItems (state, mapItems) {
          state.mapItems = mapItems
        },
        setContent (state, content) {
          state.content = content
        }
      },
      actions: {
        fetchDeliveryTime ({ commit }) {
          commit('setLoading', true)

          return NetworkInformationService.getDeliveryTime()
            .then(deliveryTime => {
              const mapItems = {}
              deliveryTime.forEach(c => mapItems[COUNTY_CODE_BY_NAME[c.county]] = {
                status: MapUtils.statusCodeByDeliveryTime(c.deliveryTime),
                title: `${c.county} - ${c.deliveryTime}`
              })

              commit('setDeliveryTime', deliveryTime)
              commit('setMapItems', mapItems)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        },
        fetchContent ({ commit }) {
          commit('setLoadingContent', true)

          return ContentfulWrapper.fetchEntry('6oXYwyBLPOtfNaSacoPtxb', {
            locale: InfraLocaleUtils.getSelectedLocale()
          }).then(res => {
            commit('setContent', res.fields)
          })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoadingContent', false)
            })
        }
      }
    },
    cableFaults: {
      namespaced: true,
      state: {
        loading: false,
        exception: false,
        cableFaults: [],
        mapItems: {}
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setException (state, exception) {
          state.exception = exception
        },
        setCableFaults (state, cableFaults) {
          state.cableFaults = cableFaults
        },
        setMapItems (state, mapItems) {
          state.mapItems = mapItems
        }
      },
      actions: {
        fetchCableFaults ({ commit }) {
          commit('setLoading', true)

          return NetworkInformationService.getCableFaults()
            .then(response => {
              const mapItems = {}

              response.forEach(c => mapItems[c.countyCode] = {
                status: MapUtils.statusCodeByCableFaultsInflow(c.inflow)
              })

              commit('setCableFaults', response)
              commit('setMapItems', mapItems)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        }
      }
    }

  }
}
