import LoginView from '@/views/login/LoginView'
import { signOut } from '@/common/auth-utils'
import AuthService from '@/services/auth.service'

export default [
  {
    path: 'login',
    name: 'login',
    component: LoginView,
    alias: [
      'request-reset-password',
      'request-reset-password/reset-password',
      'change-password'
    ],
    beforeEnter: (to, _from, next) => {
      if (to.name === 'login' && to.query.reason === 'unauthorized') {
        signOut()
      }

      next()
    }
  },
  {
    path: 'login-redirect',
    name: 'landing-page',
    async beforeEnter () {
      await AuthService.signinRedirect()
    }
  }
]
