<template>
  <div class="email-container container">
    <div class="row">
      <div class="col-12">
        <h2 class="email-container__heading heading-m">
          {{ subCategory.fields.heading }}
        </h2>
      </div>
    </div>

    <div class="emails-row row">
      <Email
        v-for="(item, index) in subCategory.fields.items"
        :key="index"
        :title="item.fields.title"
        :sub-title="item.fields.subtitle"
        :email="item.fields.email" />
    </div>
  </div>
</template>

<script>
import { Email } from '../components/'

export default {
  components: { Email },
  props: {
    subCategory: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.email-container__heading {
  margin-bottom: 25px;
}

.emails-row {
  margin-top: -20px;
  margin-bottom: -20px;
}
</style>
