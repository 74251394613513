<template>
  <InfraReportingActionPanelConversation
    :message.sync="form.message"
    :invalid="$v.form.message.$error"
    :loading="loading"
    :exception="exception"
    :label-button-state="labelButtonState"
    :label-conversation-message="$t('details.action-panel.conversation.message-label')"
    :label-error="$t('common.state.error')"
    @submit="submit" />
</template>

<script>
import { InfraReportingActionPanelConversation } from 'tws-vue-components'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  mapActions, mapState
} from 'vuex'

export default {
  components: {
    InfraReportingActionPanelConversation
  },
  mixins: [validationMixin],
  data () {
    return {
      form: {
        message: null
      }
    }
  },
  validations: {
    form: {
      message: {
        required
      }
    }
  },
  computed: {
    ...mapState('FaultReporting/FaultReportDetails', ['response']),
    ...mapState('FaultReporting/FaultReportComments', [
      'loading',
      'exception'
    ]),
    labelButtonState () {
      if (this.loading) {
        return this.$t('common.state.sending')
      } else if (this.loaded) {
        return this.$t('common.state.sent')
      }
      return this.$t('common.send')
    }
  },
  created () {
    this.resetState()
  },
  methods: {
    ...mapActions('FaultReporting/FaultReportComments', [
      'postComment',
      'resetState'
    ]),
    async submit () {
      this.$v.form.$touch()

      if (this.$v.form.$invalid) {
        return
      }

      await this.postComment({
        id: this.response.caseId,
        request: {
          text: this.form.message
        }
      })

      this.form.message = null
      this.$v.form.$reset()
    }
  }
}
</script>
