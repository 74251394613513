import axios from 'axios'
import { LOCAL_KEY_ACCESS_TOKEN } from '@/common/auth-constants'

function getHeaders () {
  const accessToken = localStorage.getItem(LOCAL_KEY_ACCESS_TOKEN)
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  }

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }

  return headers
}

function getApi (baseUrl, withCredentials = true) {
  const instance = axios.create({
    baseURL: baseUrl,
    responseType: 'json',
    withCredentials: withCredentials
  })

  // Request interceptor
  instance.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
        ...getHeaders()
      }
      return config
    },
    (error) => Promise.reject(error)
  )

  return instance
}

export const createInstance = baseUrl => {
  const api = getApi(baseUrl)
  api.interceptors.response.use(response => response.data)
  return api
}

export const createInstanceWithoutCredentials = baseUrl => {
  const api = getApi(baseUrl, false)
  api.interceptors.response.use(response => response.data)
  return api
}
