<template>
  <form
    class="address-search-form"
    @submit.prevent="handleSearch">
    <div
      v-if="advancedSearch"
      class="row mb-4">
      <div class="col-6 col-xl-5 d-flex flex-row">
        <SelectionButton
          id="address-node"
          v-model="searchMode"
          :disabled="anythingLoading"
          class="mr-3 mode-radio"
          name="searchMode"
          :value="SEARCH_MODE.ADDRESS_NODE">
          <label for="address-node">{{ $t('address-search.search-modes.address-node') }}</label>
        </SelectionButton>
        <SelectionButton
          id="address-address"
          v-model="searchMode"
          :disabled="anythingLoading"
          class="mode-radio"
          name="searchMode"
          type="radio"
          :value="SEARCH_MODE.ADDRESS_ADDRESS">
          <label for="address-address">{{ $t('address-search.search-modes.address-address') }}</label>
        </SelectionButton>
        <SelectionButton
          id="node-node"
          v-model="searchMode"
          :disabled="anythingLoading"
          class="ml-3 mode-radio"
          name="searchMode"
          type="radio"
          :value="SEARCH_MODE.NODE_NODE">
          <label for="node-node">{{ $t('address-search.search-modes.node-node') }}</label>
        </SelectionButton>
      </div>
    </div>

    <AddressSearchFieldSet
      v-if="displayAddressQueryA"
      ref="addressFormRefA"
      key="addressA"
      class="row"
      :street-name="addressQueryA.streetName"
      :street-number="addressQueryA.streetNumber"
      :entrance="addressQueryA.entrance"
      :city="addressQueryA.city"
      :validators="$v.addressQueryA"
      @change="handleAddressChangeA" />

    <div class="faux-row">
      <div
        v-if="displayAddressQueryA"
        class="property-search-button"
        @click="handleSearchPropertyA">
        <TeliaCoreIcon
          name="home_thin"
          size="38px" />
        {{ $t('address-search.search-by-property') }}
      </div>
    </div>

    <NodeFieldSet
      v-if="displayNodeQueryA"
      ref="nodeFormRefA"
      key="nodeQueryA"
      label="A"
      class="row"
      :node="nodeQueryA.node"
      :kc="nodeQueryA.kc"
      :validators="$v.nodeQueryA"
      @change="handleNodeChangeA" />

    <AddressSearchFieldSet
      v-if="displayAddressQueryB"
      ref="addressFormRefB"
      key="addressB"
      label="B"
      class="row"
      :street-name="addressQueryB.streetName"
      :street-number="addressQueryB.streetNumber"
      :entrance="addressQueryB.entrance"
      :city="addressQueryB.city"
      :validators="$v.addressQueryB"
      @change="handleAddressChangeB" />

    <div class="faux-row">
      <div
        v-if="displayAddressQueryB"
        class="property-search-button"
        @click="handleSearchPropertyB">
        <TeliaCoreIcon
          name="home_thin"
          size="38px" />
        {{ $t('address-search.search-by-property') }}
      </div>
    </div>

    <NodeFieldSet
      v-if="displayNodeQueryB"
      ref="nodeFormRefB"
      key="nodeQueryB"
      label="B"
      class="row"
      :node="nodeQueryB.node"
      :kc="nodeQueryB.kc"
      :validators="$v.nodeQueryB"
      @change="handleNodeChangeB" />

    <TwsCheckbox
      id="advanced-search-toggle"
      v-model="advancedSearch"
      :disabled="anythingLoading"
      :label="$t('address-search.buttons.advanced-search')" />

    <div class="row my-5">
      <div class="col-12">
        <TwsButtonStateful
          type="submit"
          :text="$t('address-search.buttons.search')"
          :disabled="searchIsDisabled"
          :in-progress="anythingLoading" />
      </div>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import {
  streetName,
  streetNumber,
  city,
  addressWildcard,
  addressMultiWildcard,
  cityWildcard
} from '@/apps/address-search/utils/validation-rules'
import {
  mapActions,
  mapGetters
} from 'vuex'
import {
  TwsCheckbox,
  TwsButtonStateful
} from 'tws-vue-components'
import AddressSearchFieldSet from './AddressSearchFieldSet'
import NodeFieldSet from './NodeFieldSet'
import SelectionButton from '@/apps/address-search/components/SelectionButton'
import { SEARCH_MODE } from '@/apps/address-search/utils/constants.utils'
import URIUtils from '@/apps/address-search/utils/uri-utils'

export default {
  components: {
    AddressSearchFieldSet,
    TwsButtonStateful,
    TwsCheckbox,
    SelectionButton,
    NodeFieldSet
  },
  mixins: [validationMixin],
  data () {
    return { SEARCH_MODE }
  },
  computed: {
    ...mapGetters('AddressSearch/AddressSearch', [
      'addressQueryA',
      'addressQueryB',
      'nodeQueryA',
      'nodeQueryB'
    ]),
    ...mapGetters('AddressSearch', ['anythingLoading']),
    searchMode: {
      get () { return this.$store.getters['AddressSearch/AddressSearch/searchMode'] },
      set (value) { this.$store.dispatch('AddressSearch/AddressSearch/setSearchMode', value) }
    },
    advancedSearch: {
      get () { return this.searchMode !== SEARCH_MODE.ADDRESS },
      set (value) {
        if (value) {
          this.searchMode = SEARCH_MODE.ADDRESS_NODE
        } else {
          this.searchMode = SEARCH_MODE.ADDRESS
        }
      }
    },
    displayAddressQueryA () {
      return this.searchMode !== SEARCH_MODE.NODE_NODE
    },
    displayNodeQueryA () {
      return this.searchMode === SEARCH_MODE.NODE_NODE
    },
    displayAddressQueryB () {
      return this.searchMode === SEARCH_MODE.ADDRESS_ADDRESS
    },
    displayNodeQueryB () {
      return this.searchMode === SEARCH_MODE.ADDRESS_NODE || this.searchMode === SEARCH_MODE.NODE_NODE
    },
    anyFormError () {
      return this.$v.addressQueryA.$anyError && this.displayAddressQueryA
          || this.$v.addressQueryB.$anyError && this.displayAddressQueryB
          || this.$v.nodeQueryA.$anyError && this.displayNodeQueryA
          || this.$v.nodeQueryB.$anyError && this.displayNodeQueryB
    },
    searchIsDisabled () {
      return this.anythingLoading || this.anyFormError
    }
  },
  mounted () {
    const queryString = window.location.search

    const urlParams = new URLSearchParams(queryString)

    const parsedAddress = {
      streetName: URIUtils.decodeURIParam(urlParams.get('streetName') ? urlParams.get('streetName') : ''),
      streetNumber: urlParams.get('streetNumber') ? urlParams.get('streetNumber') : '',
      entrance: urlParams.get('streetNumber') ? urlParams.get('entrance') : '',
      city: URIUtils.decodeURIParam(urlParams.get('city') ? urlParams.get('city') : '')
    }

    if (parsedAddress.streetName.length && parsedAddress.city.length && parsedAddress.streetNumber.length) {
      Object.keys(parsedAddress).forEach(key => {
        this.handleAddressChangeA({
          key: key,
          value: parsedAddress[key]
        })
      })

      this.handleSearch()
    }
  },
  methods: {
    ...mapActions('AddressSearch/AddressSearch', [
      'setAddressQuery',
      'setNodeQuery'
    ]),
    touchAllFields () {
      if (this.displayAddressQueryA) { this.$v.addressQueryA.$touch() }
      if (this.displayAddressQueryB) { this.$v.addressQueryB.$touch() }
      if (this.displayNodeQueryA) { this.$v.nodeQueryA.$touch() }
      if (this.displayNodeQueryB) { this.$v.nodeQueryB.$touch() }
    },
    handleSearch () {
      this.touchAllFields()

      if (!this.anyFormError) {
        this.$emit('search')
      }
    },
    handleAddressChangeA (query) {
      this.setAddressQuery({
        ...query,
        point: 'A'
      })
    },
    handleAddressChangeB (query) {
      this.setAddressQuery({
        ...query,
        point: 'B'
      })
    },
    handleSearchPropertyA () {
      this.$emit('property-search-request-a')
    },
    handleSearchPropertyB () {
      this.$emit('property-search-request-b')
    },
    handleNodeChangeA (query) {
      this.setNodeQuery({
        ...query,
        point: 'A'
      })
    },
    handleNodeChangeB (query) {
      this.setNodeQuery({
        ...query,
        point: 'B'
      })
    }
  },
  validations: {
    addressQueryA: {
      streetName: {
        required,
        streetName,
        addressWildcard,
        addressMultiWildcard: addressMultiWildcard('city')
      },
      streetNumber: {
        streetNumber,
        requiredIfEntrance (streetNumber) {
          if (this.addressQueryA.entrance) {
            return !!streetNumber
          }
          return true
        }
      },
      entrance: {},
      city: {
        required,
        city,
        addressWildcard,
        addressMultiWildcard: addressMultiWildcard('streetName'),
        cityWildcard: cityWildcard({
          streetNumber: 'streetNumber',
          entrance: 'entrance'
        })
      }
    },
    addressQueryB: {
      streetName: {
        required,
        streetName,
        addressWildcard,
        addressMultiWildcard: addressMultiWildcard('city')
      },
      streetNumber: {
        streetNumber,
        requiredIfEntrance (streetNumber) {
          if (this.addressQueryB.entrance) {
            return !!streetNumber
          }
          return true
        }
      },
      entrance: {},
      city: {
        required,
        city,
        addressWildcard,
        addressMultiWildcard: addressMultiWildcard('streetName'),
        cityWildcard: cityWildcard({
          streetNumber: 'streetNumber',
          entrance: 'entrance'
        })
      }
    },
    nodeQueryA: {
      node: { required },
      kc: {}
    },
    nodeQueryB: {
      node: { required },
      kc: {}
    }
  }
}
</script>

<style scoped lang="scss">
@import "tws-core-atoms/variables.scss";

button {
  display: flex;
  align-items: center;
  border-radius: 2.5rem / 50%;
  padding: 1.6rem 3rem;
}

.address-search-form::v-deep .selection-button__container {
  padding: 10px;
  display: flex;

  &--selected {
    padding: 9px;
  }
}

.property-search-button {
  cursor: pointer;
  color: #990ae3;
  margin: 0 0 3rem 5.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
}

.faux-row {
  display: inline-block;
  position: relative;
  top: -2.4rem
}
</style>
