<template>
  <div
    v-if="description"
    class="description-view">
    <Icons
      v-if="pageIcons"
      :items="pageIcons"
      variant="secondary"
      class="description-view__page-icons" />

    <div class="container">
      <div class="row">
        <div class="col-md-7 col-sm-12">
          <InfraContentfulRichTextContainer
            v-if="content"
            :content="content" />
        </div>

        <div
          v-if="product.isProductCardAvailable"
          class="col-md-7 col-sm-12">
          <h2
            v-if="description.subTitle"
            class="description-view__sub-title heading-m">
            {{ description.subTitle }}
          </h2>
          <p
            v-if="description.subDescription"
            class="description-view__sub-description">
            {{ description.subDescription }}
          </p>

          <template v-for="(item, index) in description.productCards">
            <TwsProductCard
              :key="index"
              class="description-view__product-card"
              :title="item.title"
              :button-text="item.buttonText"
              :button-src="item.buttonLink"
              :image-src="item.image"
              :short-text="item.shortText"
              :full-text="item.fullText" />
          </template>
        </div>

        <div class="col-md-4 col-sm-12 offset-md-1">
          <template v-if="relatedProducts">
            <RelatedProducts
              v-for="(item, index) in relatedProducts"
              :key="index"
              class="description-view__related-product"
              :heading="item.heading"
              :items="item.links" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  InfraContentfulRichTextContainer, TwsProductCard
} from 'tws-vue-components'
import Icons from '@/apps/products/components/Icons'

import { RelatedProducts } from '@/apps/products/components'
import { mapState } from 'vuex'

export default {
  components: {
    Icons,
    InfraContentfulRichTextContainer,
    RelatedProducts,
    TwsProductCard
  },
  beforeRouteUpdate (to, from, next) {
    if (this.product?.tabs?.description) {
      next()
    } else if (this.product?.tabs?.specifications) {
      next({ name: 'specifications' })
    } else {
      next({ name: 'documents' })
    }
  },
  computed: {
    ...mapState('Products/ContentModule', ['product']),
    description () {
      return this.product.tabs.description
    },
    pageIcons () {
      return this.description.pageIcons
    },
    content () {
      return this.description.content
    },
    relatedProducts () {
      return this.description.relatedProducts
    }
  }
}
</script>

<style lang="scss" scoped>
.description-view {
  &__page-icons {
    margin-bottom: 40px;
  }

  &__sub-title {
    margin-bottom: 30px;
  }

  &__sub-description {
    margin-bottom: 60px;
  }

  &__product-card {
    margin-bottom: 60px;
  }
}
</style>
