<script>
import PurpurCtaLink from '../purpur/PurpurCtaLink.vue'
import PurpurHeading from '../purpur/PurpurHeading.vue'
import PurpurParagraph from '../purpur/PurpurParagraph.vue'

export default {
  components: {
    PurpurCtaLink,
    PurpurHeading,
    PurpurParagraph
  },
  props: {
    title: {
      type: String,
      required: true
    },
    preamble: {
      type: String,
      default: null
    },
    cta: {
      type: Object,
      default: null
    },
    img: {
      type: Object,
      required: true
    }
  }
}

</script>

<template>
  <div
    class="hero-banner-wrapper"
    :style="{ backgroundImage: `url(${img.url})`}">
    <div class="hero-banner container">
      <PurpurHeading
        tag="h1"
        variant="display-50"
        class="hero-banner__title">
        {{ title }}
      </PurpurHeading>

      <PurpurParagraph
        v-if="preamble"
        variant="preamble-200"
        class="hero-banner__paragraph">
        {{ preamble }}
      </PurpurParagraph>

      <PurpurCtaLink
        v-if="cta"
        class="hero-banner__cta"
        :href="cta.url"
        size="md"
        variant="primary-negative">
        {{ cta.text }}
      </PurpurCtaLink>
    </div>

    <div class="hero-banner-wrapper__image-background" />
  </div>
</template>

<style scoped lang="scss">
@use '@purpurds/tokens/typography/variables' as typography;
@use "@purpurds/tokens/breakpoint/variables" as breakpoints;
@use "@purpurds/tokens/color/variables" as colors;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;

.hero-banner-wrapper {

  position: relative;
  height: 64rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-position: 50%;
  background-size: cover;

  &__image-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(360deg, colors.$purpur-color-transparent-black-800 0%, rgba(0, 0, 0, 0) 100%);
  }

  @media (min-width: breakpoints.$purpur-breakpoint-lg) {
    padding: 80px 0;
  }

  @media (max-width: breakpoints.$purpur-breakpoint-lg) {
    padding: 80px 0;
  }

  @media (max-width: breakpoints.$purpur-breakpoint-md) {
    padding: 40px 0;
  }
}

.hero-banner {
  z-index: 1;
}

::v-deep .hero-banner__title span,
::v-deep .hero-banner__paragraph span {
  color: colors.$purpur-color-functional-white;
}

::v-deep.hero-banner__title span {
  font-family: typography.$purpur-typography-family-display;
  margin-bottom: spacing.$purpur-spacing-200;
}

::v-deep.hero-banner__paragraph span {
  margin-bottom: spacing.$purpur-spacing-400;
  max-width: 770px;
}

::v-deep.hero-banner__cta a {
  &:hover {
    color: colors.$purpur-color-purple-800;
  }
}

@media (max-width: breakpoints.$purpur-breakpoint-md) {
  ::v-deep .hero-banner__cta a {
    width: 100%;
  }
}
</style>
