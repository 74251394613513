<template>
  <ul class="desktop-menu">
    <li
      v-for="(item, index) in headerItems"
      :key="index"
      class="desktop-menu-item">
      <tws-nav-link
        v-if="item.link"
        :href="item.link">
        {{ item.title }}
      </tws-nav-link>

      <tws-borderless-button
        v-else
        :class="{ 'color-core-purple': megaMenus[item.menu].visible }"
        @click="$emit('toggleMegaMenu', item.menu)">
        {{ item.title }}
      </tws-borderless-button>
    </li>
  </ul>
</template>

<script>
import {
  TwsBorderlessButton,
  TwsNavLink
} from 'tws-vue-components'
import { mapState } from 'vuex'

export default {
  components: {
    TwsBorderlessButton,
    TwsNavLink
  },
  computed: {
    ...mapState('publicHeader', [
      'headerItems',
      'megaMenus'
    ])
  }
}
</script>

<style lang="scss" scoped>
.desktop-menu {
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-flow: row;
  list-style: none;
  margin-right: auto;

  &-item:not(:last-of-type) {
    margin-right: 40px;
  }
}

.tws-nav-link {
  display: inline-block;
}

.tws-nav-link,
.tws-borderless-button {
  padding-top: 35px;
  padding-bottom: 35px;
  line-height: 1;
}
</style>
