import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_PRODUCT_SEARCH_URL)

const AreaSearchService = {
  getAreaSearchChoices: async () => {
    return await api.get('/area/search/populate')
  },
  postAreaSerchOrder: async request => {
    return await api.post('/area/search/order', request)
  }
}

export default AreaSearchService
