<template>
  <div class="ticket-details-view">
    <GrootHeroBanner
      :title="$route.params.supportId"
      :breadcrumbs="breadcrumbItems" />

    <div
      class="container mt-5">
      <div
        v-show="loading || exception"
        class="row">
        <div class="col-9">
          <infra-page-alert
            v-show="loading"
            type="info">
            {{ $t('info-alert.loading') }}
          </infra-page-alert>

          <infra-page-alert
            v-show="exception"
            type="danger">
            {{ $t('ticket-details.info-alert.no-cases-found.part-one') }}
            <LocalisedLink
              class="help-link"
              to="/create">
              {{ $t('ticket-details.info-alert.no-cases-found.contact-link') }}
            </LocalisedLink>
            {{ $t('ticket-details.info-alert.no-cases-found.part-two') }}
          </infra-page-alert>
        </div>
      </div>

      <template v-if="response">
        <div
          v-if="showCreatedMessage"
          class="row">
          <div class="col-6">
            <infra-page-alert
              class="mb-5"
              type="success">
              {{ $t('ticket-details.info-alert.case-created') }}
            </infra-page-alert>
          </div>
        </div>

        <div class="row">
          <div class="col-6 ticket-details-left">
            <h3 class="ticket-details-left__pretitle">
              {{ response.caseType }}, {{ response.product }}
            </h3>
            <h2 class="tc-title tc-title--m">
              {{ response.subject }}
            </h2>

            <p class="ticket-details-left__content">
              {{ response.content }}
            </p>

            <div
              v-if="response.attachments && response.attachments.length > 0"
              class="file-wrapper">
              <div class="tc-title tc-title-xs">
                {{ $t('ticket-details.attachments') }}
              </div>
              <ul>
                <li
                  v-for="(file, index) in response.attachments"
                  :key="index"
                  class="file">
                  <TeliaCoreIcon
                    name="document_pdf"
                    size="21px"
                    color="black"
                    class="file__icon" />
                  <div class="file__name">
                    <a :href="`${servicePath}/tickets/${response.supportId}/attachments/${file.fileKey}`">{{ file.fileName | fileName }}</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-4 offset-2 ticket-details-right">
            <div class="row">
              <div class="col-6 tc-title tc-title--xxs ticket-details-right__status-title">
                {{ $t('ticket-details.status') }}
              </div>
              <div class="col-6">
                <div class="ticket-details-right__status">
                  <StatusBadge :status="statusLabels[response.status]" />
                </div>
              </div>
            </div>
            <div
              v-if="false && response.created"
              class="row">
              <div class="col-6 tc-title tc-title--xxs">
                {{ $t('ticket-details.created') }}
              </div>
              <div class="col-6">
                {{ response.created }}
              </div>
            </div>
            <div
              v-if="response.lastUpdated"
              class="row">
              <div class="col-6 tc-title tc-title--xxs">
                {{ $t('ticket-details.updated') }}
              </div>
              <div class="col-6">
                {{ response.lastUpdated }}
              </div>
            </div>
            <div
              v-if="getFullName(response.createdByUserInfo)"
              class="row">
              <div class="col-6 tc-title tc-title--xxs">
                {{ $t('ticket-details.created-by') }}
              </div>
              <div class="col-6">
                {{ getFullName(response.createdByUserInfo) }}
              </div>
            </div>

            <div
              v-if="response.orderId || response.ttId || response.assetId"
              class="row">
              <div class="col-12">
                <div class="ticket-details-right__reference">
                  {{ $t('ticket-details.reference') }}
                </div>
              </div>
            </div>

            <div
              v-if="response.orderId"
              class="row">
              <div class="col-6 tc-title tc-title--xxs">
                {{ $t('ticket-details.order-number') }}
              </div>
              <div class="col-6">
                {{ response.orderId }}
              </div>
            </div>
            <div
              v-if="response.assetId"
              class="row">
              <div class="col-6 tc-title tc-title--xxs">
                {{ $t('ticket-details.asset-number') }}
              </div>
              <div class="col-6">
                {{ response.assetId }}
              </div>
            </div>
            <div
              v-if="response.ttId"
              class="row">
              <div class="col-6 tc-title tc-title--xxs">
                {{ $t('ticket-details.complaint-number') }}
              </div>
              <div class="col-6">
                {{ response.ttId }}
              </div>
            </div>
            <div
              v-if="response.customerReference"
              class="row">
              <div class="col-6 tc-title tc-title--xxs">
                {{ $t('ticket-details.customer-reference') }}
              </div>
              <div class="col-6">
                {{ response.customerReference }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-show="isClosed"
          class="row">
          <div class="col-12 closed-alert">
            <infra-page-alert
              v-show="isClosed"
              type="info">
              {{ $t('ticket-details.info-alert.closed') }}
            </infra-page-alert>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <InfraReportingActionPanel
              :label-conversation-title="$t('action-panel.conversation.title')"
              :label-attachments-add="$t('action-panel.attachments.add')">
              <template #conversation>
                <ActionPanelConversation />
              </template>
              <template #attachments>
                <ActionPanelAttachments />
              </template>
            </InfraReportingActionPanel>
          </div>
        </div>

        <div class="row my-5">
          <div class="col-9">
            <TicketDetailsConversation />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TeliaCoreIcon from '@telia/core-icons'
import {
  InfraPageAlert,
  InfraReportingActionPanel
} from 'tws-vue-components'
import StatusBadge from '@/apps/customer-tickets/components/StatusBadge'
import Constants from '@/apps/customer-tickets/utils/constants'
import { GrootHeroBanner } from 'groot-components'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

import {
  mapActions, mapState
} from 'vuex'
import TicketDetailsConversation from '@/apps/customer-tickets/components/TicketDetailsConversation'
import UserInfoMixin from '@/apps/customer-tickets/mixins/user-info.mixin'
import FilenameMixin from '@/apps/customer-tickets/mixins/filename.mixin'
import { parseTranslationsFromEnum } from '@/utils/translation.utils'
import ActionPanelConversation from '@/apps/customer-tickets/components/action-panel/ActionPanelConversation'
import ActionPanelAttachments from '@/apps/customer-tickets/components/action-panel/ActionPanelAttachments'
import { BREADCRUMBS_CUSTOMER_TICKETS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    ActionPanelAttachments,
    ActionPanelConversation,
    InfraReportingActionPanel,
    TicketDetailsConversation,
    InfraPageAlert,
    StatusBadge,
    TeliaCoreIcon,
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  mixins: [
    UserInfoMixin,
    FilenameMixin
  ],
  data () {
    return {
      servicePath: process.env.VUE_APP_SUPPORT_TICKETS_SERVICE,
      breadcrumbItems: [
        ...BREADCRUMBS_CUSTOMER_TICKETS.DETAILS,
        { title: this.$route.params.supportId }
      ],
      showCreatedMessage: this.$route.query.created
    }
  },
  computed: {
    ...mapState('CustomerTickets/TicketDetails', [
      'response',
      'loading',
      'exception'
    ]),
    statuses () {
      return Constants.STATUSES
    },
    statusLabels () {
      return parseTranslationsFromEnum(Constants.STATUS_TO_LABEL)
    },
    isClosed () {
      return this.response.status === this.statuses.CLOSED
    }
  },
  mounted () {
    this.fetchTicket(this.$route.params.supportId)
  },
  methods: {
    ...mapActions('CustomerTickets/TicketDetails', ['fetchTicket'])
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.closed-alert {
  padding-top: 22px;
}

.help-link {
  color: $light-core-purple;
}

.ticket-details-left {
  &__pretitle {
    font-size: 1.4rem;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
  }

  &__content {
    white-space: pre-line;
    overflow-wrap: break-word;
  }

  .file-wrapper {
    margin-top: 4.5rem;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      .file {
        margin: 18px 0;
        display: flex;

        &__icon {
          margin-right: 1rem;
        }

        &__name a {
          color: $core-purple;
        }

        &__name a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.ticket-details-right {
  .row:not(:last-child) {
    margin-bottom: 16px;
  }

  .row:first-child {
    margin-bottom: 11px;
  }

  &__status-title {
    display: flex;
    align-items: center;
  }

  &__reference {
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #6E6E6E;

    border-top: 1px solid #CCCCCC;
    margin-top: 5px;
    padding-top: 10px;
  }
}
</style>
