<template>
  <div class="tws-language-toggle">
    <div
      v-for="(locale, index) in locales"
      :key="index"
      :class="{ 'tws-language-toggle-button--active': locale === getSelectedLocale() }"
      class="tws-language-toggle-button"
      @click="changeLocale(locale)">
      {{ locale.toUpperCase() }}
    </div>
  </div>
</template>

<script>
import { InfraLocaleUtils } from 'tws-vue-components'
import { getSelectedLocale } from '@/utils/locale.utils'

export default {
  computed: {
    locales () {
      return Object.keys(InfraLocaleUtils.LOCALES).map(key => key.toLowerCase())
    }
  },
  methods: {
    getSelectedLocale,
    changeLocale (locale) {
      if (getSelectedLocale() !== locale)
      {
        // this.$i18n.locale = locale
        InfraLocaleUtils.changeLocale()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.tws-language-toggle {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.tws-language-toggle-button {
  margin: 0 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;

  &--active {
    color: $core-purple;
  }
}
</style>
