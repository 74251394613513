import { render, staticRenderFns } from "./AddressSearchFieldSet.vue?vue&type=template&id=670d5b4a&scoped=true"
import script from "./AddressSearchFieldSet.vue?vue&type=script&lang=js"
export * from "./AddressSearchFieldSet.vue?vue&type=script&lang=js"
import style0 from "./AddressSearchFieldSet.vue?vue&type=style&index=0&id=670d5b4a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670d5b4a",
  null
  
)

export default component.exports