<template>
  <div class="menu-heading">
    <div class="menu-heading__logo">
      <LocalisedLink to="/">
        <tws-telia-wholesale-logo
          width="180"
          height="30" />
      </LocalisedLink>
    </div>

    <div class="menu-heading__icon">
      <tws-cross-icon @click="$emit('close')" />
    </div>
  </div>
</template>

<script>
import TwsTeliaWholesaleLogo from '@/img/svg/telia-wholesale-logo.svg'
import TwsCrossIcon from '@/img/svg/cross.svg'

export default {
  components: {
    TwsTeliaWholesaleLogo,
    TwsCrossIcon
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.menu-heading {
  padding: 25px 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-bottom: solid 1px $medium-grey;

  &__logo {
    flex-grow: 1;

    svg path {
      fill: $core-purple;
    }
  }
}
</style>
