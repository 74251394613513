<template>
  <tws-borderless-custom-content-button class="dropdown-toggler-button login-menu">
    <template #toggler>
      <tws-login-menu-user-info />
    </template>

    <div class="authenticated-user-dropdown-menu">
      <tws-borderless-button
        class="select-item"
        @click="onUserDashboardClick">
        {{ $t('header.settings-button') }}
      </tws-borderless-button>

      <tws-borderless-button
        class="select-item"
        @click="onLogoutClick">
        {{ $t('header.logout-button') }}
      </tws-borderless-button>
    </div>
  </tws-borderless-custom-content-button>
</template>

<script>
import LoginMenuUserInfo from './login-menu-user-info/login-menu-user-info'
import LoginMenuMixin from '@/mixins/login-menu.mixin'

import {
  TwsBorderlessButton,
  TwsBorderlessCustomContentButton
} from 'tws-vue-components'

export default {
  components: {
    'tws-login-menu-user-info': LoginMenuUserInfo,
    TwsBorderlessButton,
    TwsBorderlessCustomContentButton
  },
  mixins: [LoginMenuMixin]
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.authenticated-user-dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-menu::v-deep .container-authenticated {
  &:hover {
    .username-text, .company-text {
      color: $core-purple;
    }

    .icon {
      color :$core-purple;
    }
  }
}

.login-menu::v-deep .multi-action-button-menu {
  margin-top: 1.2rem;
}

.dropdown-toggler-button {
  color: $core-purple;
}

.select-item {
  margin-top: 1.5rem;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $core-purple;
  }
}

.select-item:last-of-type {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}
</style>
