<template>
  <purpur-button :variant="variant">
    <slot />
  </purpur-button>
</template>
<script>
import { Button } from '@purpurds/purpur'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  components: {
    'purpur-button': applyReactInVueWithOptions(Button)
  },
  props: {
    variant: {
      type: String,
      default: 'primary'
    }
  }
}

</script>
