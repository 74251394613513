import {
  FORM_DESCRIPTION_PROPERTIES,
  PROBLEM_TYPE_DROPDOWN_OPTION,
  FAULT_CATEGORY_DROPDOWN_OPTION,
  FORM_TYPE
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import { sendForm } from '@/apps/fault-reporting/services/teliaNOW.service'
import i18n from '@/i18n'

export default {
  namespaced: true,

  state: {
    [FORM_DESCRIPTION_PROPERTIES.CASE_ID]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED]: null,
    [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL]: null,
    [FORM_DESCRIPTION_PROPERTIES.NETWORK_PROVIDER]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_SELECTING_PROVIDER_MANUALLY_TRIED]: null,
    [FORM_DESCRIPTION_PROPERTIES.COUNTRY]: '',
    [FORM_DESCRIPTION_PROPERTIES.CITY]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_ROAMING_ALLOWED_FOR_TERMINAL]: null,
    [FORM_DESCRIPTION_PROPERTIES.NETWORK_USED]: '',
    [FORM_DESCRIPTION_PROPERTIES.APN_IN_USE]: '',
    [FORM_DESCRIPTION_PROPERTIES.SPEED_TEST_RESULTS]: '',
    [FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED]: '',
    [FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM]: null,
    [FORM_DESCRIPTION_PROPERTIES.AFFECTED_NUMBERS]: '',
    [FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS]: '',
    [FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE]: '',
    [FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION]: '',
    [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: [],
    [FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]: [],
    [FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED]: '',
    [FORM_DESCRIPTION_PROPERTIES.ADRESS_WHERE_PROBLEM_HAPPENED]: '',
    [FORM_DESCRIPTION_PROPERTIES.PROBLEM_WITH_SENDING_RECEIVING]: '',
    [FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION]: '',
    [FORM_DESCRIPTION_PROPERTIES.ERROR_MESSAGE]: '',
    [FORM_DESCRIPTION_PROPERTIES.PORT_IN_OUT]: '',
    [FORM_DESCRIPTION_PROPERTIES.WHICH_SERVICE_EXPERIENCING_PROBLEM]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_LTE_OR_WIFI_CALLING_ACTIVATED]: null,
    [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_PERSIST_IF_TRAFFIC_CONTROL_IS_INACTIVATED]: null,
    [FORM_DESCRIPTION_PROPERTIES.SELECTED_PROVIDER]: '',
    [FORM_DESCRIPTION_PROPERTIES.CONTACT]: '',
    [FORM_DESCRIPTION_PROPERTIES.IMEI]: '',
    [FORM_DESCRIPTION_PROPERTIES.REGISTRATION_NUMBER]: '',
    [FORM_DESCRIPTION_PROPERTIES.OWN_REFERENCE]: '',

    [FORM_DESCRIPTION_PROPERTIES.IS_VALID]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_LOADING]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_ERROR]: false
  },

  mutations: {
    setCaseId: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.CASE_ID] = val,
    setIsTerminalRestartedUpdated: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED] = val,
    setIsProblemInDifferentTerminal: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL] = val,
    setNetworkProvider: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.NETWORK_PROVIDER] = val,
    setIsSelectingProviderManuallyTried: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_SELECTING_PROVIDER_MANUALLY_TRIED] = val,
    setCountry: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.COUNTRY] = val,
    setCity: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.CITY] = val,
    setIsRoamingAllowedForTerminal: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_ROAMING_ALLOWED_FOR_TERMINAL] = val,
    setNetworkUsed: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.NETWORK_USED] = val,
    setAPNInUse: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.APN_IN_USE] = val,
    setSpeedTestResults: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.SPEED_TEST_RESULTS] = val,
    setNumberAffected: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED] = val,
    setAreCustomersExperiencingSameProblem: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM] = val,
    setAffectedNumbers: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.AFFECTED_NUMBERS] = val,
    setNumbersOfAffectedCustomers: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS] = val,
    setProblemOccurenceDate: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE] = val,
    setProblemCustomerExperiencingDescription: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION] = val,
    setAttachments: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] = val,
    setStateExamplesWhenCustomerExperiencedProblem: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM] = val,
    addEmptyStateExample: (state, emptyStateExample) => state[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM] = [
      ...state[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM],
      emptyStateExample
    ],
    removeStateExample: (state, index) => state[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM].splice(index, 1),
    setWhatTroubleshootingPerformed: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED] = val,
    setAdressWhereProblemHappened: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ADRESS_WHERE_PROBLEM_HAPPENED] = val,
    setProblemWithSendingReceiving: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_WITH_SENDING_RECEIVING] = val,
    setIncidentDescription: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION] = val,
    setErrorMessage: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ERROR_MESSAGE] = val,
    setPortInOut: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.PORT_IN_OUT] = val,
    setWhichServiceExperiencingProblem: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.WHICH_SERVICE_EXPERIENCING_PROBLEM] = val,
    setIsProblemPersistIfTrafficControlIsInactivated: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_PERSIST_IF_TRAFFIC_CONTROL_IS_INACTIVATED] = val,
    setIsLteOrWifiCallingActivated: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_LTE_OR_WIFI_CALLING_ACTIVATED] = val,
    setSelectedProvider: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.SELECTED_PROVIDER] = val,
    setContact: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.CONTACT] = val,
    setIMEI: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IMEI] = val,
    setRegistrationNumber: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.REGISTRATION_NUMBER] = val,
    setOwnReference: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.OWN_REFERENCE] = val,

    setIsValid: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_VALID] = val,
    setIsLoading: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_LOADING] = val,
    setIsError: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_ERROR] = val
  },

  actions: {
    updateIsTerminalRestartedUpdated ({ commit }, val) {
      commit('setIsTerminalRestartedUpdated', val)
    },
    updateIsProblemInDifferentTerminal ({ commit }, val) {
      commit('setIsProblemInDifferentTerminal', val)
    },
    updateNetworkProvider ({ commit }, val) {
      commit('setNetworkProvider', val)
    },
    updateIsSelectingProviderManuallyTried ({ commit }, val) {
      commit('setIsSelectingProviderManuallyTried', val)
    },
    updateCountry ({ commit }, val) {
      commit('setCountry', val)
    },
    updateCity ({ commit }, val) {
      commit('setCity', val)
    },
    updateIsRoamingAllowedForTerminal ({ commit }, val) {
      commit('setIsRoamingAllowedForTerminal', val)
    },
    updateNetworkUsed ({ commit }, val) {
      commit('setNetworkUsed', val)
    },
    updateAPNInUse ({ commit }, val) {
      commit('setAPNInUse', val)
    },
    updateSpeedTestResults ({ commit }, val) {
      commit('setSpeedTestResults', val)
    },
    updateNumberAffected ({ commit }, val) {
      commit('setNumberAffected', val)
    },
    updateAreCustomersExperiencingSameProblem ({ commit }, val) {
      commit('setAreCustomersExperiencingSameProblem', val)
    },
    updateAffectedNumbers ({ commit }, val) {
      commit('setAffectedNumbers', val)
    },
    updateNumbersOfAffectedCustomers ({ commit }, val) {
      commit('setNumbersOfAffectedCustomers', val)
    },
    updateProblemOccurenceDate ({ commit }, val) {
      commit('setProblemOccurenceDate', val)
    },
    updateProblemCustomerExperiencingDescription ({ commit }, val) {
      commit('setProblemCustomerExperiencingDescription', val)
    },
    updateAttachments ({ commit }, val) {
      commit('setAttachments', val)
    },
    updateStateExamplesWhenCustomerExperiencedProblem ({ commit }, val) {
      commit('setStateExamplesWhenCustomerExperiencedProblem', val)
    },
    addEmptyStateExample ({ commit }) {
      const emptyStateExample = {
        date: '',
        description: ''
      }

      commit('addEmptyStateExample', emptyStateExample)
    },
    removeStateExample ({ commit }, index) {
      commit('removeStateExample', index)
    },
    updateWhatTroubleshootingPerformed ({ commit }, val) {
      commit('setWhatTroubleshootingPerformed', val)
    },
    updateAdressWhereProblemHappened ({ commit }, val) {
      commit('setAdressWhereProblemHappened', val)
    },
    updateProblemWithSendingReceiving ({ commit }, val) {
      commit('setProblemWithSendingReceiving', val)
    },
    updateIncidentDescription ({ commit }, val) {
      commit('setIncidentDescription', val)
    },
    updateErrorMessage ({ commit }, val) {
      commit('setErrorMessage', val)
    },
    updatePortInOut ({ commit }, val) {
      commit('setPortInOut', val)
    },
    updateWhichServiceExperiencingProblem ({ commit }, val) {
      commit('setWhichServiceExperiencingProblem', val)
    },
    updateIsProblemPersistIfTrafficControlIsInactivated ({ commit }, val) {
      commit('setIsProblemPersistIfTrafficControlIsInactivated', val)
    },
    updateIsLteOrWifiCallingActivated ({ commit }, val) {
      commit('setIsLteOrWifiCallingActivated', val)
    },
    updateSelectedProvider ({ commit }, val) {
      commit('setSelectedProvider', val)
    },
    updateContact ({ commit }, val) {
      commit('setContact', val)
    },
    updateIMEI ({ commit }, val) {
      commit('setIMEI', val)
    },
    updateRegistrationNumber ({ commit }, val) {
      commit('setRegistrationNumber', val)
    },
    updateOwnReference ({ commit }, val) {
      commit('setOwnReference', val)
    },
    updateIsValid ({ commit }, val) {
      commit('setIsValid', val)
    },
    resetState ({ commit }, problemType) {
      commit('setCaseId', '')
      commit('setIsTerminalRestartedUpdated', null)
      commit('setIsProblemInDifferentTerminal', null)
      commit('setNetworkProvider', '')
      commit('setIsSelectingProviderManuallyTried', null)
      commit('setCountry', '')
      commit('setCity', '')
      commit('setIsRoamingAllowedForTerminal', null)
      commit('setNetworkUsed', '')
      commit('setAPNInUse', '')
      commit('setSpeedTestResults', '')
      commit('setNumberAffected', '')
      commit('setAreCustomersExperiencingSameProblem', null)
      commit('setAffectedNumbers', '')
      commit('setNumbersOfAffectedCustomers', '')
      commit('setProblemOccurenceDate', '')
      commit('setProblemCustomerExperiencingDescription', '')
      commit('setAttachments', [])
      if (problemType.label === PROBLEM_TYPE_DROPDOWN_OPTION.SMS_MMS.label) {
        commit('setStateExamplesWhenCustomerExperiencedProblem', [{
          date: '',
          description: ''
        }])
      } else {
        commit('setStateExamplesWhenCustomerExperiencedProblem', [
          {
            date: '',
            description: ''
          },
          {
            date: '',
            description: ''
          },
          {
            date: '',
            description: ''
          }
        ])
      }
      commit('setWhatTroubleshootingPerformed', '')
      commit('setAdressWhereProblemHappened', '')
      commit('setProblemWithSendingReceiving', '')
      commit('setIncidentDescription', '')
      commit('setErrorMessage', '')
      commit('setPortInOut', '')
      commit('setWhichServiceExperiencingProblem', '')
      commit('setIsProblemPersistIfTrafficControlIsInactivated', null)
      commit('setIsLteOrWifiCallingActivated', null)
      commit('setSelectedProvider', '')
      commit('setContact', '')
      commit('setIMEI', '')
      commit('setRegistrationNumber', '')
      commit('setOwnReference', '')
      commit('setIsValid', false)
      commit('setIsError', false)
    },
    async sendForm ({ state, commit }, baseInfo) {
      const { problemType, faultCategory } = baseInfo

      let request

      switch (faultCategory) {
        case FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_MOBILE_DATA.value: {
          switch (problemType) {
            case PROBLEM_TYPE_DROPDOWN_OPTION.GENERAL_PROBLEM_MOBILE_DATA.value:
            case PROBLEM_TYPE_DROPDOWN_OPTION.INTERMITTENT_PROBLEM.value:
            case PROBLEM_TYPE_DROPDOWN_OPTION.SLOW_SPEED.value: {
              const isWithAttachments = state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS].length

              request = {
                ...baseInfo,
                [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.MOBILESP,
                [FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED]: state[FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED],
                [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL]: state[FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL],
                [FORM_DESCRIPTION_PROPERTIES.NETWORK_USED]: state[FORM_DESCRIPTION_PROPERTIES.NETWORK_USED],
                [FORM_DESCRIPTION_PROPERTIES.APN_IN_USE]: state[FORM_DESCRIPTION_PROPERTIES.APN_IN_USE],
                ...problemType === PROBLEM_TYPE_DROPDOWN_OPTION.SLOW_SPEED.value && {
                  [FORM_DESCRIPTION_PROPERTIES.SPEED_TEST_RESULTS]: state[FORM_DESCRIPTION_PROPERTIES.SPEED_TEST_RESULTS]
                },
                [FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED]: state[FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED],
                [FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM]: state[FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM],
                ...state[FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM] && {
                  [FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS]: state[FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS]
                },
                [FORM_DESCRIPTION_PROPERTIES.ADRESS_WHERE_PROBLEM_HAPPENED]: state[FORM_DESCRIPTION_PROPERTIES.ADRESS_WHERE_PROBLEM_HAPPENED],
                [FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE],
                [FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION],
                ...isWithAttachments && { [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] },
                [FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]: JSON.stringify(state[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]),
                [FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED]: state[FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED]
              }
              break
            }

            case PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_ABROAD.value: {
              const isWithAttachments = state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS].length

              request = {
                ...baseInfo,
                [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.MOBILESP,
                [FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED]: state[FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED],
                [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL]: state[FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL],
                [FORM_DESCRIPTION_PROPERTIES.NETWORK_PROVIDER]: state[FORM_DESCRIPTION_PROPERTIES.NETWORK_PROVIDER],
                [FORM_DESCRIPTION_PROPERTIES.IS_SELECTING_PROVIDER_MANUALLY_TRIED]: state[FORM_DESCRIPTION_PROPERTIES.IS_SELECTING_PROVIDER_MANUALLY_TRIED],
                [FORM_DESCRIPTION_PROPERTIES.COUNTRY]: state[FORM_DESCRIPTION_PROPERTIES.COUNTRY],
                [FORM_DESCRIPTION_PROPERTIES.CITY]: state[FORM_DESCRIPTION_PROPERTIES.CITY],
                [FORM_DESCRIPTION_PROPERTIES.IS_ROAMING_ALLOWED_FOR_TERMINAL]: state[FORM_DESCRIPTION_PROPERTIES.IS_ROAMING_ALLOWED_FOR_TERMINAL],
                [FORM_DESCRIPTION_PROPERTIES.NETWORK_USED]: state[FORM_DESCRIPTION_PROPERTIES.NETWORK_USED],
                [FORM_DESCRIPTION_PROPERTIES.APN_IN_USE]: state[FORM_DESCRIPTION_PROPERTIES.APN_IN_USE],
                [FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED]: state[FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED],
                [FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM]: state[FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM],
                ...state[FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM] && {
                  [FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS]: state[FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS]
                },
                [FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE],
                [FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION],
                ...isWithAttachments && { [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] },
                [FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]: JSON.stringify(state[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]),
                [FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED]: state[FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED]
              }
              break
            }

          }

          break
        }

        case FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_PRODUCTS_MOBILE_NUMBER_PORTING.value: {
          switch (problemType) {
            case PROBLEM_TYPE_DROPDOWN_OPTION.SMS_MMS.value: {
              const isWithAttachments = state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS].length

              request = {
                ...baseInfo,
                [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.MOBILESP,
                [FORM_DESCRIPTION_PROPERTIES.PROBLEM_WITH_SENDING_RECEIVING]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_WITH_SENDING_RECEIVING],
                [FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED]: state[FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED],
                [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL]: state[FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL],
                [FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE],
                [FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]: JSON.stringify(state[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]),
                ...isWithAttachments && { [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] },
                [FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION]: state[FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION],
                [FORM_DESCRIPTION_PROPERTIES.ERROR_MESSAGE]: state[FORM_DESCRIPTION_PROPERTIES.ERROR_MESSAGE]
              }

              break
            }

            case PROBLEM_TYPE_DROPDOWN_OPTION.VOICEMAIL.value:
            case PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_PROVISIONING.value:
            case PROBLEM_TYPE_DROPDOWN_OPTION.MOBILE_NUMBER_PORTABILITY.value: {
              const isWithAttachments = state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS].length

              request = {
                ...baseInfo,
                [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.MOBILESP,
                ...problemType === PROBLEM_TYPE_DROPDOWN_OPTION.MOBILE_NUMBER_PORTABILITY.value ? {
                  [FORM_DESCRIPTION_PROPERTIES.PORT_IN_OUT]: state[FORM_DESCRIPTION_PROPERTIES.PORT_IN_OUT]
                } : { [FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE] },
                ...problemType === PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_PROVISIONING.value && { [FORM_DESCRIPTION_PROPERTIES.WHICH_SERVICE_EXPERIENCING_PROBLEM]: state[FORM_DESCRIPTION_PROPERTIES.WHICH_SERVICE_EXPERIENCING_PROBLEM] },
                [FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION]: problemType === PROBLEM_TYPE_DROPDOWN_OPTION.MOBILE_NUMBER_PORTABILITY.value ?
                  state[FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION] + ' ' + i18n.t('fault-reporting.form.description.affected-numbers.label') + ' ' + state[FORM_DESCRIPTION_PROPERTIES.AFFECTED_NUMBERS] :
                  state[FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION],
                ...isWithAttachments && { [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] },
                [FORM_DESCRIPTION_PROPERTIES.ERROR_MESSAGE]: state[FORM_DESCRIPTION_PROPERTIES.ERROR_MESSAGE]
              }

              break
            }
          }

          break
        }

        case FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_VOICE_MOBILE_NETWORK.value: {
          const isProblemAbroadSelected = problemType === PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_ABROAD.value
          const isWithAttachments = state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS].length

          request = {
            ...baseInfo,
            [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.MOBILESP,
            [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_PERSIST_IF_TRAFFIC_CONTROL_IS_INACTIVATED]: state[FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_PERSIST_IF_TRAFFIC_CONTROL_IS_INACTIVATED],
            [FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED]: state[FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED],
            [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL]: state[FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL],
            ...isProblemAbroadSelected && { [FORM_DESCRIPTION_PROPERTIES.NETWORK_PROVIDER]: state[FORM_DESCRIPTION_PROPERTIES.NETWORK_PROVIDER] },
            ...isProblemAbroadSelected && { [FORM_DESCRIPTION_PROPERTIES.IS_SELECTING_PROVIDER_MANUALLY_TRIED]: state[FORM_DESCRIPTION_PROPERTIES.IS_SELECTING_PROVIDER_MANUALLY_TRIED] },
            ...isProblemAbroadSelected && state[FORM_DESCRIPTION_PROPERTIES.IS_SELECTING_PROVIDER_MANUALLY_TRIED] && { [FORM_DESCRIPTION_PROPERTIES.SELECTED_PROVIDER]: state[FORM_DESCRIPTION_PROPERTIES.SELECTED_PROVIDER] },
            ...isProblemAbroadSelected && { [FORM_DESCRIPTION_PROPERTIES.COUNTRY]: state[FORM_DESCRIPTION_PROPERTIES.COUNTRY] },
            ...isProblemAbroadSelected && { [FORM_DESCRIPTION_PROPERTIES.CITY]: state[FORM_DESCRIPTION_PROPERTIES.CITY] },
            ...isProblemAbroadSelected && { [FORM_DESCRIPTION_PROPERTIES.IS_ROAMING_ALLOWED_FOR_TERMINAL]: state[FORM_DESCRIPTION_PROPERTIES.IS_ROAMING_ALLOWED_FOR_TERMINAL] },
            [FORM_DESCRIPTION_PROPERTIES.IS_LTE_OR_WIFI_CALLING_ACTIVATED]: state[FORM_DESCRIPTION_PROPERTIES.IS_LTE_OR_WIFI_CALLING_ACTIVATED],
            [FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED]: state[FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED],
            [FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM]: state[FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM],
            ...state[FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM] && {
              [FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS]: state[FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS]
            },
            [FORM_DESCRIPTION_PROPERTIES.ADRESS_WHERE_PROBLEM_HAPPENED]: state[FORM_DESCRIPTION_PROPERTIES.ADRESS_WHERE_PROBLEM_HAPPENED],
            [FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE],
            [FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION]: state[FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION],
            ...isWithAttachments && { [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] },
            [FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]: JSON.stringify(state[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]),
            [FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED]: state[FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED]
          }

          break
        }

        case FAULT_CATEGORY_DROPDOWN_OPTION.IMEI_BARRING.value: {
          request = {
            ...baseInfo,
            [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.MOBILESP,
            [FORM_DESCRIPTION_PROPERTIES.CONTACT]: state[FORM_DESCRIPTION_PROPERTIES.CONTACT],
            [FORM_DESCRIPTION_PROPERTIES.IMEI]: state[FORM_DESCRIPTION_PROPERTIES.IMEI],
            [FORM_DESCRIPTION_PROPERTIES.REGISTRATION_NUMBER]: state[FORM_DESCRIPTION_PROPERTIES.REGISTRATION_NUMBER],
            [FORM_DESCRIPTION_PROPERTIES.OWN_REFERENCE]: state[FORM_DESCRIPTION_PROPERTIES.OWN_REFERENCE]
          }

          break
        }
      }

      try {
        commit('setIsLoading', true)
        commit('setIsError', false)
        const response = await sendForm(request)

        commit('setCaseId', response.id)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('err', err)
        commit('setIsError', true)
      } finally {
        commit('setIsLoading', false)
      }
    }
  }
}
