export default {
  computed: {
    activeCategory () {
      if (!this.categories || !this.categories.items) {
        return null
      }
      return this.categories.items.find(category => category.fields.path === this.$route.params.category)
    }
  }
}
