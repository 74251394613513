const CASE_TYPES = {
  GENERAL: 'constants.case-types.general',
  ORDER: 'constants.case-types.order',
  DELIVERY: 'constants.case-types.delivery',
  ESCALATION: 'constants.case-types.escalation'
}

const CASE_TYPES_OPTIONS = [
  CASE_TYPES.GENERAL,
  CASE_TYPES.ORDER,
  CASE_TYPES.DELIVERY
]

const PRODUCT_CATEGORIES = {
  FIBER: 'constants.product-categories.fiber',
  COPPER: 'constants.product-categories.copper',
  CAPACITY: 'constants.product-categories.capacity',
  ESTABLISHMENT: 'constants.product-categories.establishment',
  OTHER: 'constants.product-categories.other'
}

const PRODUCTS = {
  TELIA_WHOLESALE_FIBER: 'constants.products.telia-wholesale-fiber',
  SKANOVA_FIBER_2009: 'constants.products.skanova-fiber-2009',
  SKANOVA_FIBER_SINGEL: 'constants.products.skanova-fiber-singel',
  FIBER_VILLA: 'constants.products.fiber-villa',
  FIBER_LAGENHET: 'constants.products.fiber-lagenhet',
  FIBER_SMAFORETAG: 'constants.products.fiber-smaforetag',
  COM_OP: 'constants.products.com-op',

  TELIA_WHOLESALE_CAPACITY: 'constants.products.telia-wholesale-capacity',
  ETHERNET_SWEDEN: 'constants.products.ethernet-sweden',
  WAVELENGTH: 'constants.products.wavelength',

  KOPPARACCESS: 'constants.products.kopparaccess',
  TELEFONIABONNEMANG: 'constants.products.telefoniabonnemang',
  IP_STREAM_ENHANCED: 'constants.products.ip-stream-enhanced',

  SAMLOKALISERING: 'constants.products.samlokalisering',
  INPLACERING: 'constants.products.inplacering',
  OPERATORSTRUNK: 'constants.products.operatorstrunk',
  INOM_NOD: 'constants.products.inom-nod',

  KUNDPORTALEN: 'constants.products.kundportalen'
}

const FAKE_PRODUCTS = {
  OTHER_FIBER: 'constants.products.other-fiber',
  OTHER_CAPACITY: 'constants.products.other-capacity',
  OTHER_COPPER: 'constants.products.other-copper',
  OTHER_ESTABLISHMENT: 'constants.products.other-establishment',
  SUPPORT_FOR_CUSTOMER_PORTAL: 'constants.products.support-for-customer-portal'
}

const CATEGORIZED_PRODUCTS = {
  [PRODUCT_CATEGORIES.FIBER]: [
    PRODUCTS.TELIA_WHOLESALE_FIBER,
    PRODUCTS.SKANOVA_FIBER_2009,
    PRODUCTS.SKANOVA_FIBER_SINGEL,
    PRODUCTS.FIBER_VILLA,
    PRODUCTS.FIBER_LAGENHET,
    PRODUCTS.FIBER_SMAFORETAG,
    PRODUCTS.COM_OP,
    FAKE_PRODUCTS.OTHER_FIBER
  ],
  [PRODUCT_CATEGORIES.CAPACITY]: [
    PRODUCTS.TELIA_WHOLESALE_CAPACITY,
    PRODUCTS.ETHERNET_SWEDEN,
    PRODUCTS.WAVELENGTH,
    FAKE_PRODUCTS.OTHER_CAPACITY
  ],
  [PRODUCT_CATEGORIES.COPPER]: [
    PRODUCTS.KOPPARACCESS,
    PRODUCTS.TELEFONIABONNEMANG,
    PRODUCTS.IP_STREAM_ENHANCED,
    FAKE_PRODUCTS.OTHER_COPPER
  ],
  [PRODUCT_CATEGORIES.ESTABLISHMENT]: [
    PRODUCTS.SAMLOKALISERING,
    PRODUCTS.INPLACERING,
    PRODUCTS.OPERATORSTRUNK,
    PRODUCTS.INOM_NOD,
    FAKE_PRODUCTS.OTHER_ESTABLISHMENT
  ],
  [PRODUCT_CATEGORIES.OTHER]: [FAKE_PRODUCTS.SUPPORT_FOR_CUSTOMER_PORTAL]
}

export const STATUSES = {
  OPEN: 'Öppen',
  ONGOING: 'Pågår',
  UPDATED: 'Uppdaterad',
  CANCELED: 'Makulerad',
  CLOSED: 'Avslutad',
  SHREDDING: 'Makulering begärd',
  INITIATED: 'Initiated',
  PENDING: 'Pending' // not used or mapping needs to be redefined
}

const STATUS_TO_COLOR = {
  [STATUSES.OPEN]: 'blue',
  [STATUSES.ONGOING]: 'blue',
  [STATUSES.UPDATED]: 'dark-grey',
  [STATUSES.CANCELED]: 'grey',
  [STATUSES.CLOSED]: 'grey',
  [STATUSES.SHREDDING]: 'orange',
  [STATUSES.INITIATED]: 'red',
  [STATUSES.PENDING]: 'green'
}

const STATUS_TO_LABEL = {
  [STATUSES.OPEN]: 'constants.status-labels.open',
  [STATUSES.ONGOING]: 'constants.status-labels.ongoing',
  [STATUSES.UPDATED]: 'constants.status-labels.updated',
  [STATUSES.CANCELED]: 'constants.status-labels.canceled',
  [STATUSES.CLOSED]: 'constants.status-labels.closed',
  [STATUSES.SHREDDING]: 'constants.status-labels.shredding',
  [STATUSES.INITIATED]: 'constants.status-labels.initiated',
  [STATUSES.PENDING]: 'constants.status-labels.pending'
}

export const FILTER_KEYS = {
  ALL: 'all',
  SELF: 'self'
}

export const USER_FILTERS = {
  [FILTER_KEYS.SELF]: 'constants.status-labels.self',
  [FILTER_KEYS.ALL]: 'constants.status-labels.all'
}

export const STATUS_FILTERS = {
  [FILTER_KEYS.ALL]: 'constants.status-labels.all',
  [STATUSES.OPEN]: 'constants.status-labels.open',
  [STATUSES.ONGOING]: 'constants.status-labels.ongoing',
  [STATUSES.CLOSED]: 'constants.status-labels.closed',
  [STATUSES.SHREDDING]: 'constants.status-labels.shredding'
}

export default {
  CASE_TYPES,
  CASE_TYPES_OPTIONS,
  PRODUCT_CATEGORIES,
  CATEGORIZED_PRODUCTS,
  PRODUCTS,
  FAKE_PRODUCTS,
  STATUSES,
  STATUS_TO_COLOR,
  STATUS_TO_LABEL
}
