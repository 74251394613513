<template>
  <div class="row fault-report-mobile-sp">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <p class="title-200">
            {{ $t('fault-reporting.form.description.description') }}
          </p>
        </div>
      </div>

      <div class="row mt-5 fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            v-if="isMobileNumberPortabilitySelected"
            id="portInOut"
            v-model="portInOutProxy"
            :validation-messages="validationMessages.portInOut"
            :validator="$v.portInOut"
            :options="portInOutOptions"
            :label="$t('fault-reporting.form.description.port-in-out.label')" />

          <TwsValidatedDatePicker
            v-else
            id="problemOccurenceDate"
            v-model="problemOccurenceDateProxy"
            :show-icon="true"
            :read-only="true"
            :validator="$v.problemOccurenceDate"
            :validation-messages="validationMessages.problemOccurenceDate"
            :options="datePickerOptions"
            :label="$t('fault-reporting.form.description.problem-occurence-date.label')"
            required />
        </div>

        <div
          v-if="isProblemWithProvisioningSelected"
          class="col-xl-5 col-sm-5 col-xs-12">
          <TwsValidatedTextInputGroup
            id="whichServiceExperiencingProblem"
            v-model="whichServiceExperiencingProblemProxy"
            :validator="$v.whichServiceExperiencingProblem"
            :validation-messages="validationMessages.whichServiceExperiencingProblem"
            :label="$t('fault-reporting.form.description.which-service-experiencing-problem.label')" />
        </div>
      </div>

      <template v-if="isMobileNumberPortabilitySelected">
        <hr class="divider">

        <div class="row mt-5 fieldset">
          <div class="col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedTextInputGroup
              id="affectedNumbers"
              v-model="affectedNumbersProxy"
              :validator="$v.affectedNumbers"
              :validation-messages="validationMessages.affectedNumbers"
              :label="$t('fault-reporting.form.description.affected-numbers.label')" />
          </div>
        </div>
      </template>

      <hr class="divider">

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="incidentDescription"
            v-model="incidentDescriptionProxy"
            :validator="$v.incidentDescription"
            :validation-messages="validationMessages.incidentDescription"
            :label="$t('fault-reporting.form.description.incident-description.label')" />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <InfraFilePicker
            v-model="attachmentsProxy"
            class="mb-5"
            button-type="primary"
            :acceptable-file-extensions="acceptableFileExtensions"
            :limit="attachmentsLimit"
            :file-size-limit="defaultFileSizeLimit"
            :show-add-icon="true"
            :add-file-text="$t('fault-reporting.form.attachments-button.button-text')"
            :limit-is-reached-text="$t('fault-reporting.form.attachments-button.limit-is-reached-text').replace('limit', attachmentsLimit)"
            :size-limit-text="$t('fault-reporting.form.attachments-button.size-is-reached-text-5mb')" />
        </div>
      </div>

      <hr class="divider">

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="error-message"
            v-model="errorMessageProxy"
            :validator="$v.errorMessage"
            :validation-messages="validationMessages.errorMessage"
            :label="$t('fault-reporting.form.description.error-message.label')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TwsValidatedTextAreaGroup,
  TwsValidatedTextInputGroup,
  TwsValidatedDatePicker,
  InfraFilePicker
} from 'tws-vue-components'
import HorizontalRadioButtons from '@/apps/fault-reporting/components/form/HorizontalRadioButtons'
import {
  mapState, mapActions
} from 'vuex'
import {
  required, requiredIf
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  ATTACHMENTS_ALLOWED_EXTENSIONS, ATTACHMENTS_FILE_SIZE_LIMIT_5MB,
  ATTACHMENTS_LIMIT, DATE_PICKER_OPTIONS, PROBLEM_TYPE_DROPDOWN_OPTION,
  PORT_IN_OUT_OPTIONS
} from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  components: {
    HorizontalRadioButtons,
    TwsValidatedTextAreaGroup,
    TwsValidatedTextInputGroup,
    TwsValidatedDatePicker,
    InfraFilePicker
  },

  mixins: [validationMixin],

  data () {
    return {
      attachmentsLimit: ATTACHMENTS_LIMIT,
      acceptableFileExtensions: ATTACHMENTS_ALLOWED_EXTENSIONS,
      defaultFileSizeLimit: ATTACHMENTS_FILE_SIZE_LIMIT_5MB,
      datePickerOptions: DATE_PICKER_OPTIONS,
      portInOutOptions: PORT_IN_OUT_OPTIONS,
      validationMessages: {
        portInOut: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        problemOccurenceDate: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        whichServiceExperiencingProblem: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        incidentDescription: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        affectedNumbers: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        errorMessage: {
          required: this.$t('fault-reporting.default-required-validation-message')
        }
      }
    }
  },

  computed: {
    ...mapState('FaultReporting/FaultReportForm/MobileSP', [
      'portInOut',
      'problemOccurenceDate',
      'whichServiceExperiencingProblem',
      'attachments',
      'affectedNumbers',
      'incidentDescription',
      'errorMessage'
    ]),
    ...mapState('FaultReporting/FaultReportForm/BasicInfo', ['problemType']),
    isVoicemailSelected () {
      return this.problemType.value === PROBLEM_TYPE_DROPDOWN_OPTION.VOICEMAIL.value
    },
    isProblemWithProvisioningSelected () {
      return this.problemType.value === PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_PROVISIONING.value
    },
    isMobileNumberPortabilitySelected () {
      return this.problemType.value === PROBLEM_TYPE_DROPDOWN_OPTION.MOBILE_NUMBER_PORTABILITY.value
    },
    portInOutProxy: {
      get () {
        return this.portInOut
      },
      set (value) {
        this.updatePortInOut(value)
      }
    },
    problemOccurenceDateProxy: {
      get () {
        return this.problemOccurenceDate
      },
      set (value) {
        this.updateProblemOccurenceDate(value)
      }
    },
    whichServiceExperiencingProblemProxy: {
      get () {
        return this.whichServiceExperiencingProblem
      },
      set (value) {
        this.updateWhichServiceExperiencingProblem(value)
      }
    },
    attachmentsProxy: {
      get () {
        return this.attachments
      },
      set (value) {
        this.updateAttachments(value)
      }
    },
    affectedNumbersProxy: {
      get () {
        return this.affectedNumbers
      },
      set (value) {
        this.updateAffectedNumbers(value)
      }
    },
    incidentDescriptionProxy: {
      get () {
        return this.incidentDescription
      },
      set (value) {
        this.updateIncidentDescription(value)
      }
    },
    errorMessageProxy: {
      get () {
        return this.errorMessage
      },
      set (value) {
        this.updateErrorMessage(value)
      }
    },
    isValidProxy () {
      return !this.$v.$invalid
    }
  },

  watch: {
    isValidProxy (val) {
      this.updateIsValid(val)
    }
  },

  validations: {
    portInOut: {
      required: requiredIf(function () {
        return this.isMobileNumberPortabilitySelected
      })
    },
    problemOccurenceDate: {
      required: requiredIf(function () {
        return !this.isMobileNumberPortabilitySelected
      })
    },
    whichServiceExperiencingProblem: {
      required: requiredIf(function () {
        return this.isProblemWithProvisioningSelected
      })
    },
    affectedNumbers: {
      required: requiredIf(function () {
        return this.isMobileNumberPortabilitySelected
      })
    },
    incidentDescription: {
      required
    },
    errorMessage: {
      required
    }
  },

  methods: {
    ...mapActions('FaultReporting/FaultReportForm/MobileSP', [
      'updatePortInOut',
      'updateProblemOccurenceDate',
      'updateWhichServiceExperiencingProblem',
      'updateAttachments',
      'updateAffectedNumbers',
      'updateIncidentDescription',
      'updateErrorMessage',
      'updateIsValid'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import "./default.scss";
</style>
