<template>
  <div
    class="text-section-container background-container"
    :class="background">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-sm-12">
          <InfraContentfulRichTextContainer :content="mainText" />
        </div>

        <div class="sidebar col-md-4 offset-md-1 col-sm-12">
          <img
            :src="sidebarImage"
            alt="img">

          <div
            :class="sidebarBackground"
            class="sidebar-container">
            <h2 class="sidebar-header heading-l">
              {{ sidebarHeading }}
            </h2>
            <div class="sidebar-content">
              <InfraContentfulRichTextContainer :content="sidebarText" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfraContentfulRichTextContainer } from 'tws-vue-components'

const white = 'white'

const backgroundValidator = (value) => [
  white,
  'gray',
  'grey'
].includes(value)

export default {
  components: {
    InfraContentfulRichTextContainer
  },
  props: {
    mainText: {
      type: Object,
      required: true
    },
    sidebarHeading: {
      type: String,
      required: true
    },
    sidebarText: {
      type: Object,
      required: true
    },
    sidebarImage: {
      type: String,
      required: true
    },
    sidebarBackground: {
      type: String,
      default: white,
      validator: backgroundValidator
    },
    background: {
      type: String,
      default: white,
      validator: backgroundValidator
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.background-container {
  margin-bottom: 48px;
}

.gray {
  background-color: $light-grey;
  padding-top: 48px;
  padding-bottom: 48px;
}

.white {
  background-color: $white;
}

.grey {
  background-color: $grey;
}

.sidebar-container {
  margin: 1.5rem auto;
  padding: 2.4rem;

  .sidebar {
    &-header {
      margin-bottom: 2rem;
      font-size: 2.4rem;
      color: $core-purple;
      overflow-wrap: break-word;
    }

    &-content {
      ul {
        padding-left: 3rem
      }
    }
  }
}
</style>
