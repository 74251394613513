const teliaAnalyticsExist = () => {
  if (!window.teliaAnalytics || process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('Telia Analytics not present!')
    return false
  }
  return true
}

const trackSearch = model => {
  if (teliaAnalyticsExist()) {
    const searchString = new URLSearchParams(model).toString()

    window.teliaAnalytics.pageview(`${window.location.pathname}?${searchString}`, -1)
  }
}

const event = (category, action, label) => {
  if (teliaAnalyticsExist()) {
    window.teliaAnalytics.event(category, action, label)
  }
}

const pageView = () => {
  if (teliaAnalyticsExist()) {
    window.teliaAnalytics.pageview(window.location.pathname + window.location.search, -1)
  }
}

export default {
  trackSearch,
  event,
  pageView
}
