import Service from '@/apps/address-search/services/property-search.service'

const state = {
  loading: false,
  loaded: false,
  exception: false,
  municipality: '',
  district: '',
  block: '',
  unit: '',
  propertyBlocks: [],
  addresses: []
}

export default {
  namespaced: true,
  state: { ...state },
  mutations: {
    loading: (state, loading) => state.loading = loading,
    loaded: (state, loaded) => state.loaded = loaded,
    exception: (state, exception) => state.exception = exception,
    municipality: (state, municipality) => state.municipality = municipality,
    district: (state, district) => state.district = district,
    block: (state, block) => state.block = block,
    unit: (state, unit) => state.unit = unit,
    propertyBlocks: (state, propertyBlocks) => state.propertyBlocks = propertyBlocks,
    addresses: (state, addresses) => state.addresses = addresses
  },
  getters: {
    loading: state => state.loading,
    loaded: state => state.loaded,
    exception: state => state.exception,
    municipality: state => state.municipality,
    district: state => state.district,
    block: state => state.block,
    unit: state => state.unit,
    propertyBlocks: state => state.propertyBlocks,
    addresses: state => state.addresses,
    results: (_, getters) => ({
      propertyBlocks: getters.propertyBlocks,
      addresses: getters.addresses
    })
  },
  actions: {
    fetchPropertyBlocks: async ({ commit, getters }) => {
      commit('loaded', false)
      commit('loading', true)
      commit('exception', false)

      try {
        const { matchingEstateBlocks, matchingAccesses } = await Service.getProperties({
          municipality: getters.municipality,
          district: getters.district,
          block: getters.block,
          unit: getters.unit
        })
        commit('propertyBlocks', matchingEstateBlocks)
        commit('addresses', matchingAccesses)
        commit('loaded', true)
      } catch {
        commit('exception', true)
      } finally {
        commit('loading', false)
      }
    },
    fetchAddresses: async ({ commit, getters }) => {
      commit('loaded', false)
      commit('loading', true)
      commit('exception', false)

      try {
        const { matchingAccesses } = await Service.getProperties({
          municipality: getters.municipality,
          district: getters.district,
          block: getters.block,
          unit: getters.unit
        })
        commit('addresses', matchingAccesses)
        commit('loaded', true)
      } catch {
        commit('exception', true)
      } finally {
        commit('loading', false)
      }
    },
    setMunicipality: ({ commit }, municipality) => {
      commit('municipality', municipality)
    },
    setDistrict: ({ commit }, district) => {
      commit('district', district)
    },
    setBlock: ({ commit }, block) => {
      commit('block', block)
    },
    setUnit: ({ commit }, unit) => {
      commit('unit', unit)
    },
    resetState: ({ commit }) => {
      Object.keys(state).forEach(key => {
        commit(key, state[key])
      })
    }
  }
}
