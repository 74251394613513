export const getAspectRatioWidth = (aspectRatio, height) => {
  const [
    widthAspect,
    heightAspect
  ] = aspectRatio.split(':')
  return Math.ceil(height * Number(widthAspect) / Number(heightAspect))
}

export const addImageUrlModifiers = (url) => {
  const FOCUS = 'center'
  const RESIZE_STRATEGY = 'fill'
  const FORMAT = 'webp'
  return `${url}?fm=${FORMAT}&f=${FOCUS}&fit=${RESIZE_STRATEGY}`
}

export const createMultipleSizeImages = (url, altText = '', dimensions) => {
  const sizesInPixels = {
    small: [
      getAspectRatioWidth(...dimensions.small),
      dimensions.small[1]
    ],
    medium: [
      getAspectRatioWidth(...dimensions.medium),
      dimensions.medium[1]
    ],
    large: [
      getAspectRatioWidth(...dimensions.large),
      dimensions.large[1]
    ]
  }

  const modifiedUrl = addImageUrlModifiers(url)

  return {
    default: modifiedUrl,
    small: `${modifiedUrl}&w=${sizesInPixels.small[0]}&h=${sizesInPixels.small[1]}`,
    medium: `${modifiedUrl}&w=${sizesInPixels.medium[0]}&h=${sizesInPixels.medium[1]}`,
    large: `${modifiedUrl}&w=${sizesInPixels.large[0]}&h=${sizesInPixels.large[1]}`,
    altText
  }
}
