<template>
  <div class="row fault-report-general-case">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <p class="title-200">
            {{ $t('fault-reporting.form.description.description') }}
          </p>
        </div>
      </div>

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-6 col-xs-12">
          <HorizontalRadioButtons
            :id="'complaint'"
            v-model="complaintProxy"
            :validation-messages="validationMessages.complaint"
            :validator="$v.complaint"
            :tooltip-text="$t('fault-reporting.form.description.complaint.tooltip')"
            :options="complaintOptions"
            :label="$t('fault-reporting.form.description.complaint.label')" />
        </div>
      </div>
      <hr class="divider">

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            :id="'isCircuitBeenUsed'"
            v-model="isCircuitBeenUsedProxy"
            :validation-messages="validationMessages.isCircuitBeenUsed"
            :validator="$v.isCircuitBeenUsed"
            :label="$t('fault-reporting.form.description.circuit-been-used.label')" />
        </div>
        <div
          v-if="isCircuitBeenUsedProxy"
          class="col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-date-picker
            id="faultTime"
            v-model="lastCircuitOperationalDateProxy"
            :show-icon="true"
            :read-only="true"
            :validator="$v.lastCircuitOperationalDate"
            :validation-messages="validationMessages.lastCircuitOperationalDate"
            :options="datePickerOptions"
            :label="$t('fault-reporting.form.description.circuit-operational-date.label')"
            required />
        </div>
      </div>

      <div class="row fieldset">
        <div class=" col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-text-input-group
            id="localContactName"
            v-model="localContactNameProxy"
            :validator="$v.localContactName"
            :validation-messages="validationMessages.localContactName"
            :label="$t('fault-reporting.form.description.local-contact-name.label')" />
        </div>
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-text-input-group
            id="localContactPhone"
            v-model="localContactPhoneProxy"
            :validator="$v.localContactPhone"
            :validation-messages="validationMessages.localContactPhone"
            :descriptive-text="$t('fault-reporting.form.description.local-contact-phone.descriptive-text')"
            :label="$t('fault-reporting.form.description.local-contact-phone.label')" />
        </div>
      </div>

      <div class="row">
        <div class=" col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-text-input-group
            id="entryCode"
            v-model="entryCodeProxy"
            :tooltip-width="tooltipWidth"
            :tooltip-text="$t('fault-reporting.form.description.entrycode.tooltip')"
            :validator="$v.entryCode"
            :validation-messages="validationMessages.entryCode"
            :label="$t('fault-reporting.form.description.entrycode.label')" />
        </div>
      </div>

      <hr class="divider">

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            :id="'isEquipmentRestarted'"
            v-model="isEquipmentRestartedProxy"
            :validation-messages="validationMessages.isEquipmentRestarted"
            :validator="$v.isEquipmentRestarted"
            :label="$t('fault-reporting.form.description.is-equipment-restarted.label')" />
        </div>
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            :id="'isLinkToNetadmin'"
            v-model="isLinkToNetadminProxy"
            :validation-messages="validationMessages.isLinkToNetadmin"
            :validator="$v.isLinkToNetadmin"
            :label="$t('fault-reporting.form.description.link-to-netadmin.label')" />
        </div>
      </div>

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            :id="'isLinkInCustomerEquipment'"
            v-model="isLinkInCustomerEquipmentProxy"
            :validation-messages="validationMessages.isLinkInCustomerEquipment"
            :validator="$v.isLinkInCustomerEquipment"
            :label="$t('fault-reporting.form.description.link-in-customer-equipment.label')" />
        </div>
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <template v-if="isLinkToNetadmin && !isLinkInCustomerEquipment || !isLinkToNetadmin && isLinkInCustomerEquipment">
            <HorizontalRadioButtons
              :id="'isCorrectPortInBF'"
              v-model="isCorrectPortInBFProxy"
              :validation-messages="validationMessages.isCorrectPortInBF"
              :validator="$v.isCorrectPortInBF"
              :label="$t('fault-reporting.form.description.is-correct-port-in-bf.label')" />
          </template>
          <template v-else-if="isLinkToNetadmin && isLinkInCustomerEquipment">
            <tws-validated-text-input-group
              id="activeLamps"
              v-model="activeLampsProxy"
              :validator="$v.activeLamps"
              :validation-messages="validationMessages.activeLamps"
              :label="$t('fault-reporting.form.description.active-lamps.label')" />
          </template>
          <template v-else>
            <tws-validated-text-input-group
              id="brokenPowerAdapterType"
              v-model="brokenPowerAdapterTypeProxy"
              :validator="$v.brokenPowerAdapterType"
              :validation-messages="validationMessages.brokenPowerAdapterType"
              :label="$t('fault-reporting.form.description.broken-power-adapter-type.label')" />
          </template>
        </div>
      </div>

      <div class="row fieldset">
        <div
          v-if="isLinkToNetadmin && isLinkInCustomerEquipment"
          class="col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-text-input-group
            id="ipAddress"
            v-model="ipAddressProxy"
            :validator="$v.ipAddress"
            :validation-messages="validationMessages.ipAddress"
            :label="$t('fault-reporting.form.description.ip-address.label')" />
        </div>
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <tws-validated-text-input-group
            id="MACAddress"
            v-model="MACAddressProxy"
            :validator="$v.MACAddress"
            :validation-messages="validationMessages.MACAddress"
            :label="$t('fault-reporting.form.description.mac-address.label')" />
        </div>
      </div>

      <div class="row">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="additionalInfo"
            v-model="additionalInformationProxy"
            :validator="$v.additionalInformation"
            :validation-messages="validationMessages.additionalInformation"
            :label="$t('fault-reporting.form.description.additional-information.label')" />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <InfraFilePicker
            v-model="attachmentsProxy"
            button-type="primary"
            :acceptable-file-extensions="acceptableFileExtensions"
            :limit="attachmentsLimit"
            :file-size-limit="defaultFileSizeLimit"
            :show-add-icon="true"
            :add-file-text="$t('fault-reporting.form.attachments-button.button-text')"
            :limit-is-reached-text="$t('fault-reporting.form.attachments-button.limit-is-reached-text').replace('limit', attachmentsLimit)"
            :size-limit-text="$t('fault-reporting.form.attachments-button.size-is-reached-text')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TwsValidatedTextAreaGroup,
  TwsValidatedTextInputGroup,
  TwsValidatedDatePicker,
  InfraFilePicker
} from 'tws-vue-components'
import HorizontalRadioButtons from '@/apps/fault-reporting/components/form/HorizontalRadioButtons'
import {
  mapState, mapActions
} from 'vuex'
import {
  required, requiredIf, maxLength
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  ATTACHMENTS_ALLOWED_EXTENSIONS, ATTACHMENTS_FILE_SIZE_LIMIT,
  ATTACHMENTS_LIMIT, COMPLAINT_OPTIONS, DATE_PICKER_OPTIONS, TOOLTIP_WIDTH
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import { phoneNumberValidator } from '@/apps/fault-reporting/utils/validation.utils'

export default {
  components: {
    HorizontalRadioButtons,
    TwsValidatedDatePicker,
    TwsValidatedTextAreaGroup,
    TwsValidatedTextInputGroup,
    InfraFilePicker
  },
  mixins: [validationMixin],
  data () {
    return {
      tooltipWidth: TOOLTIP_WIDTH,
      attachmentsLimit: ATTACHMENTS_LIMIT,
      datePickerOptions: DATE_PICKER_OPTIONS,
      complaintOptions: COMPLAINT_OPTIONS,
      acceptableFileExtensions: ATTACHMENTS_ALLOWED_EXTENSIONS,
      defaultFileSizeLimit: ATTACHMENTS_FILE_SIZE_LIMIT,
      validationMessages: {
        additionalInformation: {
          required: this.$t('fault-reporting.form.description.additional-information.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.additional-information.validation-message-max-length')
        },
        entryCode: {
          required: this.$t('fault-reporting.form.description.entrycode.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.entrycode.validation-message-max-length')
        },
        complaint: {
          required: this.$t('fault-reporting.form.description.complaint.validation-message')
        },
        isCircuitBeenUsed: {
          required: this.$t('fault-reporting.form.description.circuit-been-used.validation-message')
        },
        lastCircuitOperationalDate: {
          required: this.$t('fault-reporting.form.description.circuit-operational-date.validation-message')
        },
        localContactName: {
          required: this.$t('fault-reporting.form.description.local-contact-name.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.local-contact-name.validation-message-max-length')
        },
        localContactPhone: {
          required: this.$t('fault-reporting.form.description.local-contact-phone.validation-message'),
          phone: this.$t('fault-reporting.form.description.local-contact-phone.validation-message-phone'),
          maxLength: this.$t('fault-reporting.form.description.local-contact-phone.validation-message-max-length')
        },
        brokenPowerAdapterType: {
          required: this.$t('fault-reporting.form.description.broken-power-adapter-type.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.broken-power-adapter-type.validation-message-max-length')
        },
        isEquipmentRestarted: {
          required: this.$t('fault-reporting.form.description.is-equipment-restarted.validation-message')
        },
        isLinkToNetadmin: {
          required: this.$t('fault-reporting.form.description.link-to-netadmin.validation-message')
        },
        isLinkInCustomerEquipment: {
          required: this.$t('fault-reporting.form.description.link-in-customer-equipment.validation-message')
        },
        isCorrectPortInBF: {
          required: this.$t('fault-reporting.form.description.is-correct-port-in-bf.validation-message')
        },
        MACAddress: {
          required: this.$t('fault-reporting.form.description.mac-address.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.mac-address.validation-message-max-length')
        },
        activeLamps: {
          required: this.$t('fault-reporting.form.description.active-lamps.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.active-lamps.validation-message-max-length')
        },
        ipAddress: {
          required: this.$t('fault-reporting.form.description.ip-address.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.ip-address.validation-message-max-length')
        }
      }
    }
  },
  computed: {
    ...mapState('FaultReporting/FaultReportForm/OpenFiberCase', [
      'additionalInformation',
      'attachments',
      'entryCode',
      'complaint',
      'isCircuitBeenUsed',
      'lastCircuitOperationalDate',
      'localContactName',
      'localContactPhone',
      'isEquipmentRestarted',
      'isLinkToNetadmin',
      'isLinkInCustomerEquipment',
      'activeLamps',
      'ipAddress',
      'MACAddress',
      'brokenPowerAdapterType',
      'isCorrectPortInBF',
      'isValid'
    ]),
    additionalInformationProxy: {
      get () {
        return this.additionalInformation
      },
      set (value) {
        this.updateAdditionalInformation(value)
      }
    },
    complaintProxy: {
      get () {
        return this.complaint
      },
      set (value) {
        this.updateComplaint(value)
      }
    },
    attachmentsProxy: {
      get () {
        return this.attachments
      },
      set (value) {
        this.updateAttachments(value)
      }
    },
    entryCodeProxy: {
      get () {
        return this.entryCode
      },
      set (value) {
        this.updateEntryCode(value)
      }
    },
    isCircuitBeenUsedProxy: {
      get () {
        return this.isCircuitBeenUsed
      },
      set (value) {
        this.updateIsCircuitBeenUsed(value)
      }
    },
    lastCircuitOperationalDateProxy: {
      get () {
        return this.lastCircuitOperationalDate
      },
      set (value) {
        this.updateLastCircuitOperationalDate(value)
      }
    },
    localContactNameProxy: {
      get () {
        return this.localContactName
      },
      set (value) {
        this.updateLocalContactName(value)
      }
    },
    localContactPhoneProxy: {
      get () {
        return this.localContactPhone
      },
      set (value) {
        this.updateLocalContactPhone(value)
      }
    },
    isEquipmentRestartedProxy: {
      get () {
        return this.isEquipmentRestarted
      },
      set (value) {
        this.updateIsEquipmentRestarted(value)
      }
    },
    isLinkToNetadminProxy: {
      get () {
        return this.isLinkToNetadmin
      },
      set (value) {
        this.updateIsLinkToNetadmin(value)
      }
    },
    isLinkInCustomerEquipmentProxy: {
      get () {
        return this.isLinkInCustomerEquipment
      },
      set (value) {
        this.updateIsLinkInCustomerEquipment(value)
      }
    },
    isCorrectPortInBFProxy: {
      get () {
        return this.isCorrectPortInBF
      },
      set (value) {
        this.updateIsCorrectPortInBF(value)
      }
    },
    brokenPowerAdapterTypeProxy: {
      get () {
        return this.brokenPowerAdapterType
      },
      set (value) {
        this.updateBrokenPowerAdapterType(value)
      }
    },
    MACAddressProxy: {
      get () {
        return this.MACAddress
      },
      set (value) {
        this.updateMACAddress(value)
      }
    },
    ipAddressProxy: {
      get () {
        return this.ipAddress
      },
      set (value) {
        this.updateIpAddress(value)
      }
    },
    activeLampsProxy: {
      get () {
        return this.activeLamps
      },
      set (value) {
        this.updateActiveLamps(value)
      }
    },
    isValidProxy () {
      return !this.$v.$invalid
    }
  },
  validations: {
    additionalInformation: {
      required,
      maxLength: maxLength(1000)
    },
    entryCode: {
      required,
      maxLength: maxLength(50)
    },
    complaint: {
      required
    },
    isCircuitBeenUsed: {
      required
    },
    lastCircuitOperationalDate: {
      required: requiredIf((form) => form.isCircuitBeenUsedProxy)
    },
    localContactName: {
      required,
      maxLength: maxLength(50)
    },
    localContactPhone: {
      required,
      maxLength: maxLength(50),
      phone: phoneNumberValidator
    },
    isEquipmentRestarted: {
      required
    },
    isLinkToNetadmin: {
      required
    },
    isLinkInCustomerEquipment: {
      required
    },
    isCorrectPortInBF: {
      required: requiredIf((form) => form.isLinkToNetadmin && !form.isLinkInCustomerEquipment || !form.isLinkToNetadmin && form.isLinkInCustomerEquipment)
    },
    brokenPowerAdapterType: {
      required: requiredIf((form) => !form.isLinkToNetadmin && !form.isLinkInCustomerEquipment),
      maxLength: maxLength(50)
    },
    MACAddress: {
      required,
      maxLength: maxLength(50)
    },
    activeLamps: {
      required: requiredIf((form) => form.isLinkToNetadmin && form.isLinkInCustomerEquipment),
      maxLength: maxLength(50)
    },
    ipAddress: {
      required: requiredIf((form) => form.isLinkToNetadmin && form.isLinkInCustomerEquipment),
      maxLength: maxLength(50)
    }
  },
  watch: {
    isValidProxy (val) {
      this.updateIsValid(val)
    }
  },
  methods: {
    ...mapActions('FaultReporting/FaultReportForm/OpenFiberCase', [
      'updateAdditionalInformation',
      'updateAttachments',
      'updateEntryCode',
      'updateComplaint',
      'updateIsCircuitBeenUsed',
      'updateLastCircuitOperationalDate',
      'updateLocalContactName',
      'updateLocalContactPhone',
      'updateIsEquipmentRestarted',
      'updateIsLinkToNetadmin',
      'updateIsLinkInCustomerEquipment',
      'updateActiveLamps',
      'updateIpAddress',
      'updateMACAddress',
      'updateBrokenPowerAdapterType',
      'updateIsCorrectPortInBF',
      'updateIsValid'
    ])
  }
}
</script>
