<template>
  <div>
    <div class="row">
      <template v-for="(section, index) of data">
        <div
          v-if="section.entries && section.entries.length > 0"
          :key="index"
          class="col">
          <div
            v-if="section.heading"
            class="row">
            <div class="col-12">
              <h3 class="heading-s result-table__section-title">
                {{ section.heading }}
              </h3>
            </div>
          </div>

          <div
            v-for="(entry, index2) of section.entries"
            :key="index2"
            class="row margin-bottom">
            <div class="col-4 bold">
              {{ entry.label }}
            </div>
            <div
              class="col-8"
              v-html="entry.value" />
          </div>

          <div
            v-if="section.note"
            class="row note">
            <div class="col-12 paragraph">
              {{ section.note }}
            </div>
          </div>
        </div>
      </template>

      <tws-tooltip
        :rich-text="tooltipContent"
        class="address-search-result__info"
        width="40rem"
        arrow-position="right" />
    </div>

    <div class="row">
      <div
        :class="slotClasses"
        class="col">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { TwsTooltip } from 'tws-vue-components'

const SLOT_POSITION_LEFT = 'left'
const SLOT_POSITION_TEXT_ALIGNED = 'text-aligned'

export default {
  components: {
    TwsTooltip
  },
  props: {
    data: {
      required: true,
      type: Array
    },
    columns: {
      type: Number,
      default: 1,
      validator (value) { return [
        1,
        2
      ].includes(value) }
    },
    tooltipContent: {
      type: Object,
      required: true
    },
    slotPosition: {
      default: SLOT_POSITION_LEFT,
      type: String,
      validator (val) {
        return [
          SLOT_POSITION_LEFT,
          SLOT_POSITION_TEXT_ALIGNED
        ].includes(val)
      }
    }
  },
  computed: {
    slotClasses () {
      const classes = ['result-extras-slot']
      if (this.slotPosition === SLOT_POSITION_TEXT_ALIGNED) {
        classes.push('result-extras-slot--text-aligned')
      }
      return classes.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
$buttonSize: 2.2rem;
$buttonOffsetX: 2.7rem;
$headerWidth: 23%;
$textLeftPadding: 3rem;

.note {
  margin-top: 2rem;
}

.bold {
  font-weight: bold;
}

.margin-bottom {
  margin-bottom: .5rem;
}

.result-table__section-title {
  color: $dark-grey;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.address-search-result__info {
  right: $buttonOffsetX;
}

.result-extras-slot {
  margin-top: 2.4rem;
}
.result-extras-slot--text-aligned {
  padding-left: calc(#{$headerWidth} + #{$textLeftPadding - ($buttonSize + $buttonOffsetX)});
}
</style>
