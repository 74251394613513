<template>
  <div class="container callback">
    <div class="row justify-content-center">
      <div class="col-md-8 col-sm-12">
        <infra-page-alert
          v-if="exception"
          type="danger">
          {{ exceptionMessage }}
        </infra-page-alert>
        <infra-page-alert
          v-else-if="loading"
          type="info">
          <p>{{ $t('login.callback-loading') }}</p>
        </infra-page-alert>
        <infra-page-alert
          v-else
          type="success">
          <p>{{ $t('login.callback-loaded') }}</p>
        </infra-page-alert>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'
import { InfraPageAlert } from 'tws-vue-components'

export default {
  components: {
    InfraPageAlert
  },
  data () {
    return {
      code: '',
      state: ''
    }
  },
  computed: {
    ...mapState('authenticationCallback', [
      'loading',
      'exception',
      'exceptionMessage'
    ])
  },
  async created () {
    this.code = this.$route.query.code
    this.state = this.$route.query.state

    await this.exchangeToken({
      code: this.code,
      state: this.state
    })
  },
  methods: {
    ...mapActions('authenticationCallback', ['exchangeToken'])
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.callback {
  margin-top: $spacing;
}
</style>
