<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <generic-banner
        :header="$t('header.administration-categories.reports-upload')" />
    </InfraBreadcrumbContainer>

    <div class="container mt-6">
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <div class="col">
              <tws-validated-dropdown-group
                id="company"
                v-model="companyCode"
                :validator="$v.companyCode"
                :validation-messages="validationMessages.companyCode"
                :label="$t('reports.company-code')">
                <option
                  v-for="company in companiesProxy"
                  :key="company.id"
                  :value="company.code">
                  {{ company.name }}
                </option>
              </tws-validated-dropdown-group>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <tws-validated-date-picker
                id="datePicker"
                v-model="publishingDate"
                :validator="$v.publishingDate"
                :validation-messages="validationMessages.publishingDate"
                :label="$t('reports.publish-date')"
                required />
            </div>
            <div class="col">
              <tws-validated-text-input-group
                id="daysValid"
                v-model="daysValid"
                :validator="$v.daysValid"
                :validation-messages="validationMessages.daysValid"
                :label="$t('reports.days')"
                required />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <h2 class="tc-title tc-title--s">
                {{ $t('reports.files') }}
              </h2>
            </div>
          </div>
          <div class="row mt-4">
            <div
              class="col">
              <InfraFilePicker
                v-model="selectedFiles"
                :is-parent-busy="requestIsOngoing" />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <button
                :disabled="selectedFiles.length === 0 || requestIsOngoing"
                type="button"
                class="button button--primary submit-button"
                @click="submit">
                {{ $t('reports.upload-files') }}
              </button>
            </div>
          </div>

          <div
            v-show="!requestIsOngoing"
            class="row page-alert">
            <div class="col">
              <infra-page-alert
                v-show="showSystemError || companiesException"
                type="danger">
                {{ $t('reports.errors.default') }}
              </infra-page-alert>

              <infra-page-alert
                v-show="showFileTypeWarning"
                type="warning">
                {{ $t('reports.errors.file-type') }}
              </infra-page-alert>

              <infra-page-alert
                v-show="failedFilesCounter"
                type="warning">
                {{ $t('reports.errors.failed-upload', [failedFilesCounter]) }}
              </infra-page-alert>

              <infra-page-alert
                v-show="uploadedFilesCounter"
                type="success">
                {{ $t('reports.success', [uploadedFilesCounter, sentFilesCount]) }}
              </infra-page-alert>
            </div>
          </div>

          <div
            v-show="requestIsOngoing"
            class="row page-alert">
            <div class="col">
              <infra-page-alert
                type="info">
                {{ $t('reports.uploading', [uploadedFilesCounter, sentFilesCount]) }}
              </infra-page-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import { validationMixin } from 'vuelidate'

import ReportsService from '@/services/reports.service'
import DateTimeUtils from '@/common/datetime-utils.js'
import GenericBanner from '@/components/common/generic-banner.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_REPORTS_UPLOAD } from '@/constants/breadcrumbs-constants'

import {
  numeric,
  required
} from 'vuelidate/lib/validators'

import { todayOrAfter } from '@/common/validation-rules.js'
import {
  InfraFilePicker,
  InfraPageAlert,
  TwsValidatedDatePicker,
  TwsValidatedDropdownGroup,
  TwsValidatedTextInputGroup
} from 'tws-vue-components'

export default {
  components: {
    InfraFilePicker,
    InfraPageAlert,
    TwsValidatedDropdownGroup,
    TwsValidatedTextInputGroup,
    TwsValidatedDatePicker,
    InfraBreadcrumbContainer,
    GenericBanner
  },
  mixins: [validationMixin],
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_REPORTS_UPLOAD,
      companyCode: null,
      publishingDate: null,
      daysValid: null,
      selectedFiles: [],
      validationMessages: {
        companyCode: {
          required: this.$t('reports.validations.required')
        },
        publishingDate: {
          required: this.$t('reports.validations.required'),
          todayOrAfter: this.$t('reports.validations.publishing-date.today-or-after')
        },
        daysValid: {
          required: this.$t('reports.validations.required'),
          numeric: this.$t('reports.validations.days-valid.numeric')
        },
        selectedFiles: {
          required: this.$t('reports.validations.required')
        }
      },
      showSystemError: false,
      showFileTypeWarning: false,
      requestIsOngoing: false,
      uploadedFilesCounter: 0,
      failedFilesCounter: 0,
      sentFilesCount: 0
    }
  },
  validations: {
    companyCode: {
      required
    },
    publishingDate: {
      required,
      todayOrAfter
    },
    daysValid: {
      required,
      numeric
    }
  },
  computed: {
    ...mapState('lookups/companies', {
      companiesLoading: 'loading',
      companiesException: 'exception',
      companies: 'companies'
    }),
    companiesProxy () {
      return this.companies.filter(c => c.code !== null)
    },
    requests () {
      const requests = []

      if (this.selectedFiles.length <= 0) {
        return requests
      }

      const expirationDate = DateTimeUtils.formatDateIso(
        DateTimeUtils.addDays(this.publishingDate, this.daysValid)
      )

      this.selectedFiles.forEach(file => {
        requests.push({
          file: file,
          companyCode: this.companyCode,
          publishingDate: this.publishingDate,
          expirationDate: expirationDate
        })
      })

      return requests
    }
  },
  watch: {
    selectedFiles () {
      if (!this.requestIsOngoing) {
        this.resetCounters()
      }
    }
  },
  mounted () {
    this.fetchCompanies()
  },
  methods: {
    ...mapActions('lookups/companies', ['fetchCompanies']),
    submit () {
      this.resetCounters()
      this.sentFilesCount = this.requests.length

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.requestIsOngoing = true

      this.requests.forEach((req) => {
        ReportsService.uploadReport(req)
          .then(() => {
            const index = this.selectedFiles.findIndex(file => file.name === req.file.name)

            this.uploadedFilesCounter++
            this.selectedFiles.splice(index, 1)
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.showFileTypeWarning = true
            } else {
              this.showSystemError = true
            }
            this.failedFilesCounter++
          })
          .finally(() => {
            if (this.uploadedFilesCounter + this.failedFilesCounter === this.sentFilesCount) {
              this.requestIsOngoing = false
            }
          })
      })
    },
    resetCounters () {
      this.uploadedFilesCounter = this.failedFilesCounter = 0
      this.showSuccess = this.showSystemError = this.showFileTypeWarning = this.requestIsOngoing = false
    }
  }
}
</script>

<style lang="scss">

.mt-6 {
  margin-top: 6rem;
}

.submit-button {
  margin-top: 3rem;
}

.infra-page-alert {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
