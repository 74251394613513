<template>
  <li class="related-product">
    <InfraLink
      v-if="path"
      :title="title"
      :path="path"
      :router-link="false"
      variant="standalone" />
    <span v-else> {{ title }} </span>
  </li>
</template>

<script>
import { InfraLink } from 'tws-vue-components'

export default {
  components: { InfraLink },
  props: {
    path: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.related-product {
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  a {
    color: $core-purple;
    text-decoration: none;
  }
}
</style>
