<template>
  <PropertySearchView
    class="property-picker__container"
    :title="headerTitle"
    :can-go-back="true"
    :display-warning="addressMissing"
    :display-danger="exception"
    :warning-text="$t('property-search.property-picker.warning-text')"
    :danger-text="$t('property-search.property-picker.danger-text')"
    @go-back="goBack"
    @close="close">
    <p class="property-picker__sub-title">
      {{ $t('property-search.property-picker.sub-title') }}
    </p>

    <FlexTable
      :keys="propertyTableKeys"
      :data="propertyBlocks"
      @click="selectBlock" />
  </PropertySearchView>
</template>

<script>
import {
  mapActions, mapGetters
} from 'vuex'
import FlexTable from '@/apps/address-search/components/FlexTable'
import PropertySearchView from './PropertySearchView.vue'
export default {
  components: {
    FlexTable,
    PropertySearchView
  },
  data () {
    return {
      propertyTableKeys: [
        {
          key: 'block',
          label: this.$t('property-search.property-table.block')
        },
        {
          key: 'unit',
          label: this.$t('property-search.property-table.unit')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('AddressSearch/PropertySearch', [
      'propertyBlocks',
      'municipality',
      'district',
      'addresses',
      'loaded',
      'exception'
    ]),
    headerTitle () {
      const municipalityStr = this.municipality.substr(0, 1).toUpperCase() + this.municipality.substr(1).toLowerCase()
      return `${municipalityStr}, ${this.district.toUpperCase()}`
    },
    addressMissing () {
      return this.loaded && this.addresses === undefined && !this.exception
    }
  },
  methods: {
    ...mapActions('AddressSearch/PropertySearch', [
      'setMunicipality',
      'setDistrict',
      'setBlock',
      'setUnit',
      'fetchAddresses'
    ]),
    selectBlock (block) {
      this.setMunicipality(block.municipality)
      this.setDistrict(block.district)
      this.setBlock(block.block)
      this.setUnit(block.unit)
      this.fetchAddresses()
    },
    goBack () { this.$emit('go-back') },
    close () { this.$emit('close') }
  }
}
</script>

<style lang="scss" scoped>
.property-picker {
  &__sub-title {
    margin: 0 0 28px;
  }
}
</style>
