import ContentModule from './modules/content.module'
import ProductDocumentModule from './modules/document.module'

export const ProductsModule = {
  namespaced: true,
  modules: {
    ContentModule,
    ProductDocumentModule
  }
}
