<template>
  <div class="public-landing">
    <template v-if="loading">
      <InfraHeroPlaceholder />

      <NavigationContainerPlaceholder />
      <PageIconContainerPlaceholder />

      <InfraBannerPlaceholder />
    </template>

    <div
      v-else-if="exception"
      class="container exception-alert">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6">
          <InfraPageAlert type="danger">
            {{ $t("public-landing.exception") }}
          </InfraPageAlert>
        </div>
      </div>
    </div>

    <template v-else>
      <InfraHero
        :background-image="hero.backgroundImage"
        :heading="hero.heading"
        :paragraph="hero.paragraph"
        :alignment="hero.alignment"
        :vertical-alignment="hero.verticalAlignment" />

      <NavigationContainer
        v-if="navigationLinks"
        :items="navigationLinks" />

      <PageIconContainer
        v-if="pageIcons"
        :heading="pageIconsHeading"
        :items="pageIcons" />

      <InfraBanner
        v-for="(item, index) in banners"
        :key="index"
        :rich-text="item.paragraph"
        :banner-width="item.bannerWidth"
        :image-side="item.imageSide"
        :image="item.image"
        :button-text="item.buttonText"
        :button-link="item.buttonLink"
        :button-type="item.buttonType" />

      <InfraFaqContainer
        v-if="faq"
        :heading="faq.heading"
        :items="faq.items" />

      <InfraProductsTiles
        v-if="products"
        :heading="products.heading"
        :items="products.items" />

      <TextSectionContainer
        v-if="textSectionContent"
        :background="textSectionContent.background"
        :main-text="textSectionContent.mainText"
        :sidebar-heading="textSectionContent.sidebarHeading"
        :sidebar-text="textSectionContent.sidebarText"
        :sidebar-image="textSectionContent.sidebarImage"
        :sidebar-background="textSectionContent.sidebarBackground" />

      <div
        v-if="bottomText"
        class="container bottom-text">
        <div class="row">
          <div class="col-12">
            {{ bottomText }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  InfraBanner,
  InfraBannerPlaceholder,
  InfraFaqContainer,
  InfraHero,
  InfraHeroPlaceholder,
  InfraPageAlert,
  InfraProductsTiles
} from 'tws-vue-components'
import {
  mapActions,
  mapState
} from 'vuex'
import NavigationContainer from './containers/NavigationContainer.vue'
import PageIconContainer from './containers/PageIconContainer.vue'

import NavigationContainerPlaceholder from './containers/NavigationContainerPlaceholder.vue'
import PageIconContainerPlaceholder from './containers/PageIconContainerPlaceholder.vue'
import TextSectionContainer from './containers/TextSectionContainer.vue'
export default {
  components: {
    TextSectionContainer,
    InfraHero,
    InfraBanner,
    InfraBannerPlaceholder,
    InfraFaqContainer,
    InfraHeroPlaceholder,
    InfraProductsTiles,
    InfraPageAlert,
    NavigationContainer,
    PageIconContainer,
    NavigationContainerPlaceholder,
    PageIconContainerPlaceholder
  },
  computed: {
    ...mapState('basicInfo', [
      'loading',
      'exception',
      'content'
    ]),
    hero () {
      return this.content.hero
    },
    navigationLinks () {
      return this.content.navigationLinks
    },
    pageIconsHeading () {
      return this.content.pageIconsHeading
    },
    pageIcons () {
      return this.content.pageIcons
    },
    banners () {
      return this.content.banners
    },
    faq () {
      return this.content.faq
    },
    bottomText () {
      return this.content.bottomText
    },
    products () {
      return this.content.products
    },
    textSectionContent () {
      return this.content.textSectionContent
    },
    isEnglishAvailable () {
      return this.content.isEnglishAvailable === true
    }
  },
  watch: {
    '$route.path' (path) {
      this.loadRouteContent(path)
    }
  },
  created () {
    this.loadRouteContent(this.$route.path)
  },
  methods: {
    ...mapActions('basicInfo', ['loadContent']),
    loadRouteContent (path) {
      const fieldsPath = path !== '/' ? path.replace('/en/', '/') : path

      this.loadContent({
        content_type: 'twsPublicLandingWrapper',
        'fields.path': fieldsPath,
        locale: this.$route.params.locale,
        include: 3
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.language-toggle-message {
  margin-top: 50px;
  margin-bottom: 50px;

  &__toggle {
    color: $core-purple;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.bottom-text {
  margin-top: 10rem;
}

.exception-alert {
  margin-top: 5rem;
}
</style>
