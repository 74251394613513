<template>
  <div class="asset-information row">
    <div class="asset-information__information col-xl-10">
      <div class="row">
        <h4 class="asset-information__title heading-s col-12">
          {{ title }}
        </h4>
      </div>
      <div class="asset-information__details row">
        <section class="asset-information__address col-6">
          <span class="asset-information__details--name">Adressuppgifter</span>
          <span>{{ address }}</span>
        </section>
        <section class="asset-information__company-name col-6">
          <span class="asset-information__details--name">Företagsnamn</span>
          <span>{{ companyName }}</span>
        </section>
      </div>
    </div>
    <div class="asset-information__selections col-xl-2 col-sm-10 col-xs-12">
      <TwsButtonPrimary
        class="asset-information__button"
        @click.native="$emit('onNewFaultReportButtonClick')">
        {{ $t('fault-reporting.button.new-fault-report') }}
      </TwsButtonPrimary>
    </div>
  </div>
</template>

<script>
import { TwsButtonPrimary } from 'tws-vue-components'

export default {
  components: {
    TwsButtonPrimary
  },
  props: {
    title: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    companyName: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/main.scss";

.asset-information {
  padding-bottom: 2em;
  border-bottom: 1px solid $grey;
  margin-top: 18px;
  margin-bottom: 18px;

  &__title {
    margin-bottom: 18px;
    color: $core-purple;
    font-weight: bold;
    font-size: 22px;
  }

  &__details--name {
    font-weight: bold;
    margin-right: 2em;
  }

  &__selections {
    display: flex;
    align-items: center;
  }
}
</style>
