<template>
  <tws-nav-link
    class="news-item"
    :href="getNewsViewLink(news.sys.id)">
    <span class="news-item__title">
      {{ news.title }}
    </span>

    <span class="news-item__date">
      {{ news.publishDate | formatDate }}
    </span>
  </tws-nav-link>
</template>

<script>
import DateTimeUtils from '@/common/datetime-utils'
import { TwsNavLink } from 'tws-vue-components'
import { getComputedTitle } from '@/utils/news.utils'

export default {
  components: {
    TwsNavLink
  },
  filters: {
    formatDate (date) {
      return DateTimeUtils.formatCustom(date, 'yyyy-MM-dd')
    }
  },
  props: {
    news: {
      type: Object,
      required: true
    },
    newsViewLink: {
      type: String,
      required: true
    }
  },
  methods: {
    getNewsViewLink (newsId) {
      return `${this.newsViewLink}?news-id=${newsId}`
    },
    getComputedTitle
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.news-item {
  display: flex;
  justify-content: space-between;

  @media(max-width: $breakpoint-sm) {
    flex-direction: column-reverse;
  }

  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #bfbfbf;

  &:first-child {
    border-top: 1px solid #bfbfbf;
  }

  &__date {
    color: $dark-grey;
  }
}
</style>
