<template>
  <div>
    <div class="row news-title">
      <div class="col">
        <h1 class="heading-m">
          {{ $t('operational-news.archived.heading') }}
        </h1>
      </div>
    </div>
    <div class="row date-fields-container">
      <div class="col-md-3 col-sm-12">
        <tws-date-picker
          v-model="dateFrom"
          :label="$t('operational-news.archived.date-from')"
          @input="onDateFromInput" />
      </div>
      <div class="col-md-3 col-sm-12">
        <tws-date-picker
          v-model="dateTo"
          :label="$t('operational-news.archived.date-to')"
          @input="onDateToInput" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="loading-spinner-wrapper">
          <tws-loading-information
            :loading="loading"
            :exception="exception"
            :exception-text="$t('operational-news.archived.loading-failed')" />
        </div>

        <template v-if="loaded">
          <InfraPageAlert
            v-if="news.length === 0"
            type="success">
            {{ $t('operational-news.archived.no-data') }}
          </InfraPageAlert>

          <template v-else>
            <div class="operational-news">
              <div
                v-for="(data, index) in slottedList"
                :key="index"
                class="operational-new">
                <TeliaCoreIcon
                  v-if="data.isUrgentEvent"
                  name="info_thin"
                  size="3rem" />
                <TeliaCoreIcon
                  v-else
                  name="repair_thin"
                  size="3rem" />
                <div class="operational-new-content">
                  <div
                    class="operational-new-title"
                    @click="openNewsItem = data">
                    {{ data.title }}
                  </div>
                  <div class="operational-new-date">
                    <template v-if="data.isUrgentEvent">
                      {{ data.startDate | formatSevereDate }}
                    </template>
                    <template v-else>
                      {{ computeDate(data) }}
                    </template>
                  </div>
                </div>
              </div>
              <tws-pagination
                v-if="count > 10"
                :extra-page-count="3"
                :item-count-on-page="$options.slotSize"
                :data-count="count"
                :current-page="currentPageProxy"
                @onPageClick="onSlotChange" />
            </div>
          </template>
        </template>
      </div>
    </div>

    <tws-operational-news-dialog
      v-if="openNewsItem"
      :news="openNewsItem"
      @close="openNewsItem = null" />
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'
import TwsOperationalNewsDialog from '@/components/operational-news/operational-news-dialog/operational-news-dialog'

import DateTimeUtils from '@/common/datetime-utils'

import {
  TwsDatePicker,
  InfraPageAlert,
  TwsPagination,
  TwsLoadingInformation
} from 'tws-vue-components'

export default {
  components: {
    TwsDatePicker,
    InfraPageAlert,
    TwsPagination,
    TwsLoadingInformation,
    TwsOperationalNewsDialog
  },
  slotSize: 10,
  filters: {
    formatSevereDate (date) {
      return DateTimeUtils.formatSevereDate(date)
    }
  },
  data () {
    return {
      currentPage: 0,
      dateFrom: null,
      dateTo: null,
      openNewsItem: null
    }
  },
  computed: {
    ...mapState('archivedOperationalNews', [
      'loading',
      'loaded',
      'exception',
      'news'
    ]),
    slot () {
      const first = this.currentPage * this.$options.slotSize
      const last = Math.min(first + this.$options.slotSize, this.count)
      return {
        first,
        last
      }
    },
    currentPageProxy () {
      return this.currentPage + 1
    },
    count () {
      return this.news.length
    },
    slottedList () {
      return this.news.slice(this.slot.first, this.slot.last)
    }
  },
  mounted () {
    this.fetchNews({
      dateFrom: null,
      dateTo: null
    })
  },
  methods: {
    ...mapActions('archivedOperationalNews', ['fetchNews']),
    onSlotChange (pageIndex) {
      this.currentPage = pageIndex - 1
    },
    onItemSelect ({ index: indexInSlot }) {
      this.$emit('itemSelected', this.slot.first + indexInSlot)
    },
    computeDate (data) {
      return DateTimeUtils.computeDate(data.startDate, data.endDate)
    },
    onDateFromInput () {
      this.fetchNews({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      })
    },
    onDateToInput () {
      this.fetchNews({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.operational-new {
  display: flex;
}

.operational-new-content {
  margin-left: 10px;
}

.operational-news {
  margin-top: 24px;
  margin-bottom: 13px;
}

.operational-new {
  margin-bottom: 20px;
}

.operational-new-title {
  color: $black;
  cursor: pointer;

  &:hover {
    color: $purple;
  }
}

.operational-news-actions {
  display: flex;
}

.action-button {
  background-color: $light-grey;

  &:not(:last-of-type) {
    margin-right: 12px;
  }
}
.news-title {
  padding-bottom: 30px;
}
.loading-spinner-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
