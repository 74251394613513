import i18n from '@/i18n'
import ContentfulWrapper from '@/services/contentful/contentful.wrapper'

export const fetchContent = async (path, contentType, depth = 5) => {
  const content = await ContentfulWrapper.fetchEntries({
    'content_type': contentType,
    locale: i18n.locale,
    'fields.path': path,
    include: depth
  })

  return content.items.length > 0 && content.items[0].fields
}

export default { fetchContent }
