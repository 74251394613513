<template>
  <div
    class="filters-wrapper">
    <div class="row grouped-form-controls">
      <div class="col-lg-3 col-sm-6">
        <tws-checkbox
          v-for="groupKey in invoiceSpecificationTypeGroups.slice(0, 5)"
          :id="`${keyify(groupKey)}-filter`"
          :key="keyify(groupKey)"
          :label="$t(getTypeGroupLabel(groupKey))"
          :value="internalFilterGroupModel[groupKey]"
          @input="(value) => handleFilterCheckbox(groupKey, value)" />
      </div>
      <div class="col-lg-3 col-sm-6">
        <tws-checkbox
          v-for="groupKey in invoiceSpecificationTypeGroups.slice(5)"
          :id="`${keyify(groupKey)}-filter`"
          :key="keyify(groupKey)"
          :label="$t(getTypeGroupLabel(groupKey))"
          :value="internalFilterGroupModel[groupKey]"
          @input="(value) => handleFilterCheckbox(groupKey, value)" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <a
          role="button"
          class="reset-button"
          @click="resetFilter">
          <tws-reset-filter-icon />&nbsp;
          <span>{{ $t('common.reset-filter') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { TwsCheckbox } from 'tws-vue-components'
import {
  INVOICE_SPECIFICATION_TYPE_GROUPS,
  INVOICE_SPECIFICATION_TYPE_GROUP_MAP,
  INVOICE_SPECIFICATION_TYPE_GROUP_LABEL,
  INVOICE_SPECIFICATION_TYPES,
  INVOICE_SPECIFICATION_TYPE_GROUP
} from '@/constants/invoice-specification-types.js'

const defaultInternalFilterGroupModel = INVOICE_SPECIFICATION_TYPE_GROUPS.reduce((model, groupKey) => {
  model[groupKey] = false
  return model
}, {})

const MODEL_EVENT_KEY = 'change'

export default {
  name: 'InvoiceSpecificationTypesFilter',
  components: { TwsCheckbox },
  model: {
    event: MODEL_EVENT_KEY,
    prop: 'filters'
  },
  props: {
    availableInvoiceTypes: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      invoiceSpecificationTypeGroups: [...INVOICE_SPECIFICATION_TYPE_GROUPS],
      internalFilterGroupModel: { ...defaultInternalFilterGroupModel }
    }
  },
  computed: {
    otherInvoiceTypes () {
      return Object.keys(this.availableInvoiceTypes)
        .filter(availableType => !INVOICE_SPECIFICATION_TYPES.includes(availableType))
    }
  },
  methods: {
    clearModel () {
      this.$emit(MODEL_EVENT_KEY, null)
    },
    getTypeGroupLabel (type) {
      return INVOICE_SPECIFICATION_TYPE_GROUP_LABEL[type]
    },
    keyify: (input) => {
      return input.toLowerCase().replace(/[^a-z0-9]/g, '-')
    },
    getInitialFlatModel () {
      return { ...this.availableInvoiceTypes }
    },
    setFiltersToTheirGroupValues (flatModel) {
      Object.keys(this.internalFilterGroupModel).forEach(groupKey => {
        (INVOICE_SPECIFICATION_TYPE_GROUP_MAP[groupKey] || []).forEach(type => {
          flatModel[type] = this.internalFilterGroupModel[groupKey]
        })
      })
    },
    setFiltersForOtherGroupValue (flatModel) {
      this.otherInvoiceTypes.forEach(type => {
        flatModel[type] = this.internalFilterGroupModel[INVOICE_SPECIFICATION_TYPE_GROUP.OTHER]
      })
    },
    setFiltersForNotSpecifiedGroupValue (flatModel) {
      flatModel['null'] = this.internalFilterGroupModel[INVOICE_SPECIFICATION_TYPE_GROUP.NOT_SPECIFIED]
    },
    getFlattenedFilterGroupModel () {
      const noneChecked = this.invoiceSpecificationTypeGroups
        .every(groupKey => this.internalFilterGroupModel[groupKey] === false)

      if (noneChecked) {
        return null
      }

      const flatModel = this.getInitialFlatModel()
      this.setFiltersToTheirGroupValues(flatModel)
      this.setFiltersForOtherGroupValue(flatModel)
      this.setFiltersForNotSpecifiedGroupValue(flatModel)

      return flatModel
    },
    handleFilterCheckbox (key, value) {
      this.internalFilterGroupModel[key] = value

      this.$emit(MODEL_EVENT_KEY, this.getFlattenedFilterGroupModel())
    },
    resetFilter () {
      this.internalFilterGroupModel = { ...defaultInternalFilterGroupModel }
      this.clearModel()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.grouped-form-controls {
  .form-control:not(:last-of-type) {
    margin-bottom: 0;
  }

  label {
    font-weight: bold;
  }
}
</style>
