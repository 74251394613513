<template>
  <div v-if="response">
    <div
      v-if="response.notes.length > 0"
      class="tc-title tc-title--s mb-5">
      {{ $t('ticket-details-conversation.messages') }}
    </div>
    <InfraReportingConversation
      :items="response.notes"
      :label-customer="$t('ticket-details-conversation.customer')" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InfraReportingConversation } from 'tws-vue-components'

export default {
  components: {
    InfraReportingConversation
  },
  computed: {
    ...mapState('CustomerTickets/TicketDetails', [
      'response',
      'loading',
      'exception'
    ])
  }
}
</script>

<style lang="scss" scoped>
.message {
  $self: &;
  display: flex;
  margin-top: 18px;

  &__user {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-right: 30px;
  }

  &__name {
    margin-top: 5px;
  }

  &__body {
    padding: 18px 30px;
    width: 100%;
    max-width: 640px;

    border: 1px solid #990AE3;
    border-left: 3px solid #990AE3;
  }

  &__text {
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  &__date {
    margin-top: 18px;
    color: #6E6E6E;
    font-size: 13px;
  }

  &--user {
    flex-direction: row-reverse;

    #{$self}__user {
      margin-left: 30px;
    }

    #{$self}__body {
      max-width: fit-content;
      border: 1px solid #CCC;
      border-right: 3px solid #CCC;
    }
  }

  .arrow {
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    margin-top: 10px;

    &--right {
      border-left: 11px solid #CCC;
    }

    &--left {
      border-right: 11px solid #990AE3;
    }
  }
}
</style>
