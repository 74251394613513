import { createInstance } from './axios.api'

export const apiPiam = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE_PIAM)

const LookupsService = {
  getAllCountries: async () => {
    return await apiPiam.get('/external/lookups/countries')
  },
  getAllCountriesEn: async () => {
    return await apiPiam.get('/external/lookups/countries/en')
  },
  getAllCompanies: async () => {
    return await apiPiam.get('/external/companies')
  }
}

export default LookupsService
