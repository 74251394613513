<template>
  <TicketCreate
    @goBack="onGoBack"
    @goToDetails="onGoToDetails" />
</template>

<script>
import TicketCreate from '@/apps/customer-tickets/components/ticket-create/TicketCreate'
export default {
  components: {
    TicketCreate
  },
  methods: {
    onGoBack (payload) {
      this.$router.push({
        ...payload,
        params: {
          ...payload.params,
          locale: this.$route.params.locale
        }
      })
    },
    onGoToDetails (payload) {
      this.$router.push({
        ...payload,
        params: {
          ...payload.params,
          locale: this.$route.params.locale
        }
      })
    }
  }
}
</script>
