<template>
  <div class="multifunctional-content">
    <div
      v-for="(contentItem, index) in contentItems"
      :key="index">
      <InfraContentfulRichTextContainer
        v-if="isRichText(contentItem)"
        :content="contentItem.fields.content" />
      <ExpandableContainer
        v-if="isExpandableContainer(contentItem)"
        :is-grey-background="true"
        :short-text="contentItem.fields.shortText"
        :full-text="contentItem.fields.fullText" />
    </div>
  </div>
</template>

<script>
import ExpandableContainer from '@/apps/fault-reporting/components/model/ExpandableContainer'
import { contentType } from '@/apps/fault-reporting/utils/content.utils'
import { InfraContentfulRichTextContainer } from 'tws-vue-components'

export default {
  components: {
    InfraContentfulRichTextContainer,
    ExpandableContainer
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    contentItems () {
      return this.content.fields.contentItems
    }
  },
  methods: {
    isRichText (item) {
      return item.sys.contentType.sys.id === contentType.TWS_RICH_TEXT
    },
    isExpandableContainer (item) {
      return item.sys.contentType.sys.id === contentType.TWS_EXPANDABLE_CONTAINER
    }
  }
}
</script>
