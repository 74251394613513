<template>
  <div
    :class="{ 'grey-background': greyBackground }"
    class="background-container">
    <slot name="breadcrumb" />
    <slot />
  </div>
</template>

<script>

export default {
  props: {
    greyBackground: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.grey-background {
  background-color: $light-grey;
}

.background-container {
  background-repeat: no-repeat;
  background-position-x: center;
}
</style>
