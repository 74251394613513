<template>
  <div class="shortcut">
    <div class="shortcut__label">
      {{ title }}
    </div>

    <div class="shortcut__value">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.shortcut {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
