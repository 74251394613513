<template>
  <div class="personal-information-container">
    <tws-user-edit-container
      :editing="editing"
      :validator="$v.editableInformation"
      :updating="updating"
      :title="$t('personal-information.heading')"
      @toggleEdit="onEditToggle"
      @confirmEdit="onEditConfirm">
      <tws-personal-information-display
        v-if="!editing"
        :information="displayedInformation"
        :countries="countries" />

      <tws-personal-information-edit
        v-else
        :countries="countries"
        :companies="companies"
        :information="editableInformation"
        :validator="$v.editableInformation"
        :is-super-user="isSuperUser"
        :username-contains-sp-id="usernameContainsSpId"
        @informationChanged="onInformationChange" />
    </tws-user-edit-container>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions
} from 'vuex'

import { validationMixin } from 'vuelidate'

import {
  required, maxLength
} from 'vuelidate/lib/validators'

import {
  phone, email, mobilePhone
} from '@/common/validation-rules.js'

import UserEditContainer from '@/components/common/user-edit-container.vue'
import PersonalInformationEdit from './personal-information-edit.vue'

import PersonalInformationDisplay from './personal-information-display.vue'

export default {
  components: {
    'tws-user-edit-container': UserEditContainer,
    'tws-personal-information-edit': PersonalInformationEdit,
    'tws-personal-information-display': PersonalInformationDisplay
  },
  mixins: [validationMixin],
  props: {
    countries: {
      type: Array,
      required: true
    },
    companies: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      editing: false,
      updating: false
    }
  },
  computed: {
    ...mapGetters('eamCookies', ['isSuperUser']),
    ...mapState('userDetails/personalInformation', [
      'displayedInformation',
      'editableInformation'
    ]),
    ...mapGetters('userDetails', ['usernameContainsSpId'])
  },
  methods: {
    ...mapActions('userDetails/personalInformation', [
      'updateInformation',
      'resetEditableInformation',
      'editableInformationChanged'
    ]),
    onEditToggle () {
      this.editing = !this.editing

      if (!this.editing) {
        this.resetEditableInformation()
      }
    },
    onEditConfirm () {
      this.updating = true

      this.updateInformation().then(() => {
        this.editing = false
      }).finally(() => {
        this.updating = false
      })
    },
    onInformationChange (information) {
      this.editableInformationChanged(information)
    }
  },
  validations () {
    return {
      editableInformation: {
        firstName: {
          required,
          maxLength: maxLength(20)
        },
        lastName: {
          required,
          maxLength: maxLength(30)
        },
        ...this.isSuperUser ? {
          company: {
            required
          }
        } : {},
        email: {
          required,
          email
        },
        phone: {
          required,
          phone
        },
        mobilePhone: {
          mobilePhone,
          ...!this.editableInformation.loginMethodPassword ? {
            required
          } : {}
        },
        username: {
          required
        },
        position: {
          maxLength: maxLength(30)
        },
        comments: {
          maxLength: maxLength(400)
        }
      }
    }
  }
}
</script>
