<template>
  <div class="user-dashboard-information">
    <template v-if="loading || exception">
      <div class="row">
        <div class="col-12">
          <InfraPageAlert
            v-if="loading"
            type="info">
            {{ $t('user-dashboard.information.loading') }}
          </InfraPageAlert>

          <InfraPageAlert
            v-if="exception"
            type="danger">
            {{ $t('user-dashboard.information.loading-failed') }}
          </InfraPageAlert>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="row">
        <div class="col-12">
          <h1 class="heading-m">
            {{ $t('user-dashboard.information.heading') }}
          </h1>
        </div>
      </div>

      <personal-information-display
        :information="information"
        :countries="countries" />
    </template>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import PersonalInformationDisplay from '@/components/personal-information/personal-information-display'
import { InfraPageAlert } from 'tws-vue-components'

export default {
  components: {
    PersonalInformationDisplay,
    InfraPageAlert
  },
  computed: {
    ...mapState('lookups/countries', ['countries']),
    ...mapState('userDashboard/information', [
      'loading',
      'exception',
      'information'
    ])
  },
  mounted () {
    this.fetchCountries()
    this.fetch()
  },
  methods: {
    ...mapActions('lookups/countries', ['fetchCountries']),
    ...mapActions('userDashboard/information', ['fetch'])
  }
}
</script>

<style lang="scss" scoped>
.heading-m {
  margin-bottom: 30px;
}
</style>
