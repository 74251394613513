<template>
  <div class="category-view">
    <template v-if="loading">
      <InfraHeroPlaceholder :center="true" />

      <div class="category-content">
        <InfraPageIconsPlaceholder />
        <ProductsPlaceholder />
      </div>
    </template>

    <InfraPageAlert
      v-else-if="exception"
      type="danger">
      {{ $t('category.loading-failed') }}
    </InfraPageAlert>

    <template v-else>
      <GrootHeroBanner
        v-if="hero"
        :title="hero.heading"
        :preamble="hero.paragraph"
        :breadcrumbs="breadcrumbItems" />

      <div class="category-content">
        <Icons
          v-if="pageIcons"
          class="category-icons"
          :items="pageIcons" />
        <div class="products-wrapper">
          <Products
            v-if="products"
            :heading="products.heading"
            :items="products.items | filterAvailableProductItems" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'

import {
  InfraHeroPlaceholder,
  InfraPageIconsPlaceholder,
  InfraPageAlert
} from 'tws-vue-components'

import { isLoggedIn } from '@/utils/user.utils'

import { ProductsPlaceholder } from '@/apps/products/placeholders'

import { Products } from '@/apps/products/components'
import CategoryMixin from '@/apps/products/mixins/category.mixin'
import Icons from '@/apps/products/components/Icons'
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import { GrootHeroBanner } from 'groot-components'

export default {
  components: {
    Icons,
    InfraHeroPlaceholder,
    InfraPageIconsPlaceholder,
    InfraPageAlert,
    ProductsPlaceholder,
    Products,
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  filters: {
    filterAvailableProductItems (items) {
      return items.filter(item => item.isAvailableOnCategoryView)
    }
  },
  mixins: [CategoryMixin],
  computed: {
    ...mapState('Products/ContentModule', [
      'loading',
      'exception'
    ]),
    hero () {
      return this.categoryComputed.hero
    },
    pageIcons () {
      return this.categoryComputed.pageIcons
    },
    products () {
      return this.categoryComputed.products
    },
    breadcrumbItems () {
      return [
        {
          title: isLoggedIn() ? this.$t('breadcrumbs.dashboard-logged-in.title') : this.$t('breadcrumbs.dashboard.title'),
          path: isLoggedIn() ? '/dashboard' : '/',
          routerLink: true
        },
        {
          title: this.$t('breadcrumbs.products.title')
        },
        {
          title: this.categoryComputed.title
        }
      ]
    }
  },
  watch: {
    async '$route.path' () {
      await this.loadRouteCategory()
    }
  },
  async created () {
    await this.loadRouteCategory()
  },
  methods: {
    ...mapActions('Products/ContentModule', ['loadCategory']),
    async loadRouteCategory () {
      await this.loadCategory()

      if (this.exception) {
        document.title = this.$t('page.error.title')
      } else {
        document.title = this.categoryComputed.title
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.infra-page-icons,
.infra-page-icons-placeholder {
  padding-top: 20px;
  padding-bottom: 40px;

  @media (min-width: $breakpoint-md) {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

.products-wrapper {
  background-color: $white;
  margin-bottom: -4rem;
  padding-bottom: 60px;
  padding-top: 5px;

  @media (min-width: $breakpoint-md) {
    padding-top: 20px;
  }
}

.category-icons {
  @media (min-width: $breakpoint-md) {
    padding-top: 50px;
    padding-bottom: 60px;
  }
  padding-top: 20px;
  padding-bottom: 40px;
}
</style>
