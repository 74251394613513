<template>
  <div class="portal-container container">
    <div class="portals-row row">
      <Portal
        v-for="(item, index) in subCategory.fields.items"
        :key="index"
        :title="item.fields.title"
        :button-text="item.fields.buttonText"
        :button-link="item.fields.buttonLink" />
    </div>
  </div>
</template>

<script>
import { Portal } from '../components'

export default {
  components: { Portal },
  props: {
    subCategory: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.portals-row {
  margin-top: -20px;
  margin-bottom: -20px;
}
</style>
