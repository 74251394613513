<template>
  <div>
    <div class="col-4 d-flex align-items-start">
      <div class="address-icon mr-4">
        <TwsFieldLabel
          :id="`address-icon-spacer-${label}`"
          label="label"
          class="address-icon__spacer" />
        <div
          class="address-icon__box"
          :class="{ 'address-icon__box--orange': label != 'A' }">
          {{ label }}
        </div>
      </div>

      <TextField
        :id="`street-name-${label}`"
        class="flex-fill"
        :name="`street-name-${label}`"
        :label="$t('address-search.field-labels.street-name')"
        :value="streetName"
        :validator="validators.streetName"
        :validation-messages="validationMessages.streetName"
        @change="changeField('streetName', $event)" />
    </div>

    <div class="col-2">
      <TextField
        :id="`street-number-${label}`"
        :name="`street-number-${label}`"
        :label="$t('address-search.field-labels.street-number')"
        :value="streetNumber"
        :validator="validators.streetNumber"
        :validation-messages="validationMessages.streetNumber"
        @change="changeField('streetNumber', $event)" />
    </div>

    <div class="col-2">
      <TextField
        :id="`entrance-${label}`"
        :name="`entrance-${label}`"
        :label="$t('address-search.field-labels.entrance')"
        :value="entrance"
        :validator="validators.entrance"
        :validation-messages="validationMessages.entrance"
        @change="changeField('entrance', $event)" />
    </div>

    <div class="col-3">
      <TextField
        :id="`city-${label}`"
        :name="`city-${label}`"
        :label="$t('address-search.field-labels.city')"
        :value="city"
        :validator="validators.city"
        :validation-messages="validationMessages.city"
        @change="changeField('city', $event)" />
    </div>
  </div>
</template>

<script>
import { TwsFieldLabel } from 'tws-vue-components'
import TextField from '@/apps/address-search/components/TextField'

export default {
  components: {
    TextField,
    TwsFieldLabel
  },
  props: {
    label: {
      type: String,
      default: 'A'
    },
    streetName: {
      type: String,
      required: true
    },
    streetNumber: {
      type: String,
      required: true
    },
    entrance: {
      type: String,
      required: true
    },
    city: {
      type: String,
      required: true
    },
    validators: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        streetName: {
          required: this.$t('address-search.validation-messages.street-name.required'),
          streetName: this.$t('address-search.validation-messages.street-name.street-name'),
          addressWildcard: this.$t('address-search.validation-messages.street-name.address-wildcard'),
          addressMultiWildcard: this.$t('address-search.validation-messages.street-name.address-multi-wildcard')
        },
        streetNumber: {
          streetNumber: this.$t('address-search.validation-messages.street-number.street-number'),
          requiredIfEntrance: this.$t('address-search.validation-messages.street-number.required-if-entrance')
        },
        entrance: {},
        city: {
          required: this.$t('address-search.validation-messages.city.required'),
          city: this.$t('address-search.validation-messages.city.city'),
          addressWildcard: this.$t('address-search.validation-messages.city.address-wildcard'),
          addressMultiWildcard: this.$t('address-search.validation-messages.city.address-multi-wildcard'),
          cityWildcard: this.$t('address-search.validation-messages.city.city-wildcard')
        }
      }
    }
  },
  methods: {
    changeField (key, nextValue) {
      this.$emit('change', {
        key,
        value: nextValue
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "tws-core-atoms/variables.scss";

.address-icon {
  &__box {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $core-purple;
    color: $white;
    border-radius: 3px;

    height: 5rem;
    padding: 0 14px;
    font-size: 22px;
    font-weight: 700;

    &--orange {
      background-color: #DD8804;
    }
  }

  &__spacer {
    visibility: hidden;
  }
}

</style>
