<template>
  <div>
    <h2
      v-if="labels.heading_size === 'h2'"
      class="heading-m">
      {{ labels.heading_text }}
    </h2>
    <h3
      v-if="labels.heading_size === 'h3'"
      class="heading-s">
      {{ labels.heading_text }}
    </h3>
  </div>
</template>

<script>
export default {
  name: 'TwsFormHeading',
  props: {
    labels: {
      type: Object,
      required: true
    }
  }
}
</script>
