<template>
  <iframe
    :src="src"
  />
</template>

<script>

export default {
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100vw;
}
</style>
