export const COUNTY_CODE_BY_NAME = {
  'Blekinge län': 'K',
  'Dalarnas län': 'W',
  'Gotlands län': 'I',
  'Gävleborgs län': 'X',
  'Hallands län': 'N',
  'Jämtlands län': 'Z',
  'Jönköpings län': 'F',
  'Kalmar län': 'H',
  'Kronobergs län': 'G',
  'Norrbottens län': 'BD',
  'Skåne län': 'M',
  'Stockholms län': 'AB',
  'Södermanlands län': 'D',
  'Uppsala län': 'C',
  'Värmlands län': 'S',
  'Västerbottens län': 'AC',
  'Västernorrlands län': 'Y',
  'Västmanlands län': 'U',
  'Västra Götalands län': 'O',
  'Örebro län': 'T',
  'Östergötlands län': 'E'
}

export const STATUS_CODE_BY_NAME = {
  'Normalt läge': 0,
  'Förhöjt läge': 1,
  'Upptrappat läge': 2,
  'Extremläge': 3
}

const COLOR_PALETTES = [
  'orange',
  'orange-alt',
  'cyan'
]

const MapUtils = {
  statusCodeByDeliveryTime: (deliveryTime) => {
    if (deliveryTime <= 114) {
      return 0
    }

    if (deliveryTime >= 130) {
      return 2
    }

    return 1
  },

  statusCodeByCableFaultsInflow: (inflow) => {
    if (inflow < 100) {
      return 0
    }

    if (inflow < 200) {
      return 1
    }

    return 2
  },

  colorPaletteValidator: (value) => {
    return COLOR_PALETTES.indexOf(value) !== -1
  }
}

export default MapUtils
