import { isLocaleEnglish } from '@/utils/locale.utils.js'
import ContactUsService from '../services/contact-us.service.js'

export const ContactUsModule = {
  namespaced: true,
  state: {
    loading: true,
    exception: false,
    categories: []
  },
  mutations: {
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setCategories: (state, categories) => state.categories = categories
  },
  actions: {
    async load ({ commit }) {
      try {
        commit('setCategories', await ContactUsService.getCategories())
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  modules: {
    Form: {
      namespaced: true,
      state: {
        loading: null,
        exception: null,
        html: null
      },
      mutations: {
        setLoading: (state, loading) => state.loading = loading,
        setException: (state, exception) => state.exception = exception,
        setHTML: (state, html) => state.html = html
      },
      actions: {
        async load ({ commit }, url) {
          commit('setHTML', null)
          commit('setLoading', true)
          commit('setException', false)

          try {
            const dummy = document.createElement('div')
            let formHtml

            switch (url) {
              // Shared Forms
              case '/tws/contact-us-form/portal/form': {
                if (isLocaleEnglish()) {
                  formHtml = require('../components/forms/shared/customer-portal-en.html').default
                } else {
                  formHtml = require('../components/forms/shared/customer-portal-sv.html').default
                }

                break
              }

              // Public Forms
              case '/tws/contact-us-form/bli-kund/form': {
                if (isLocaleEnglish()) {
                  formHtml = require('../components/forms/public/become-customer-en.html').default
                } else {
                  formHtml = require('../components/forms/public/become-customer-sv.html').default
                }

                break
              }

              case '/tws/contact-us-form/public/form': {
                if (isLocaleEnglish()) {
                  formHtml = require('../components/forms/public/form-en.html').default
                } else {
                  formHtml = require('../components/forms/public/form-sv.html').default
                }

                break
              }

              // Private Forms
              case '/tws/contact-us-form/agreement/form': {
                if (isLocaleEnglish()) {
                  formHtml = require('../components/forms/private/agreement-support-en.html').default
                } else {
                  formHtml = require('../components/forms/private/agreement-support-sv.html').default
                }

                break
              }
            }

            dummy.innerHTML = formHtml

            commit('setHTML', dummy.getElementsByClassName('tws-root-fake')[0].innerHTML)
          } catch {
            commit('setException', true)
          } finally {
            commit('setLoading', false)
          }
        }
      }
    }
  }
}
