<template>
  <div
    v-if="isValidatorCreated && getValidatorFormField(labels.field_name)"
    :style="widthStyle"
    @click="showDependencyMessage">
    <tws-validated-dropdown-group
      :id="'form-dropdown' + labels.field_name"
      v-model="model"
      :label="labels.dropdown_label"
      :placeholder="labels.field_placeholder"
      :validator="getValidatorFormField(labels.field_name)"
      :validation-messages="validationMessages"
      :disabled="disabledField(labels.field_name)">
      <option
        v-for="option in labels.dropdown_options"
        :key="option.dropdown_title"
        :value="option.dropdown_value">
        {{ option.dropdown_title }}
      </option>
    </tws-validated-dropdown-group>
    <p
      v-show="showFieldMessage"
      class="dependency-text">
      {{ dependencyMessage }}
    </p>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import { TwsValidatedDropdownGroup } from 'tws-vue-components'

export default {
  name: 'TwsFormDropdown',
  components: {
    TwsValidatedDropdownGroup
  },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        required: this.labels.validation_message
      },
      showFieldMessage: false
    }
  },
  computed: {
    ...mapGetters('DynamicForm', [
      'getValidatorFormField',
      'isValidatorCreated',
      'disabledField',
      'getFieldDependencyMessage',
      'getFieldValue'
    ]),
    model: {
      get () {
        return this.getFieldValue(this.labels.field_name)
      },
      set (value) {
        this.onValueInput(value)
      }
    },
    dependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        return this.getFieldDependencyMessage(this.labels.field_name)
      }
      return ''

    },
    widthStyle () {
      const componentWidth = this.labels.component_width
      return componentWidth ? 'width: ' + componentWidth + '%;' : ''
    }
  },
  mounted () {
    this.addValidation(
      {
        fieldName: this.labels.field_name,
        validations: this.labels.validations
      }
    )
    this.fieldCreated(
      {
        fieldName: this.labels.field_name,
        label: this.labels.dropdown_label,
        templateText: this.labels.template_text,
        isFieldRequired: this.labels.is_field_required
      }
    )
  },
  methods: {
    ...mapActions('DynamicForm',
      [
        'fieldCreated',
        'fieldValueEntered',
        'addValidation'
      ]
    ),
    onValueInput (value) {
      this.fieldValueEntered(
        {
          key: this.labels.field_name,
          value: value,
          email: Object.values(this.labels.dropdown_options).filter(option => option.dropdown_value === value).map(option => option.email)[0]
        }
      )
    },
    showDependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        this.showFieldMessage = !this.showFieldMessage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .paragraph-align {
    text-align: justify;
  }
  .dependency-text {
    color: grey;
    font-size: medium;
  }
</style>
