<template>
  <div
    class="row external-navigation">
    <div class="col-xl-10 col-sm-10 col-xs-12">
      <LocalisedLink
        v-if="routeName"
        :to="{ name: routeName }">
        {{ text }}
      </LocalisedLink>
      <tws-nav-link
        v-else
        :href="href">
        {{ text }}
      </tws-nav-link>
    </div>
  </div>
</template>

<script>
import { TwsNavLink } from 'tws-vue-components'

export default {
  components: {
    TwsNavLink
  },
  props: {
    href: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/main.scss";

.external-navigation {
  @include link;
}

</style>
