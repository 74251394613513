const YES = 'common.yes'
const NO = 'common.no'
const NOVSDL = 'common.errors.no-vsdl'

const LocalizationService = {
  getYesNo (value) {
    if (/^([YN])$/.test(value)) {
      return /^Y$/.test(value) ? YES : NO
    }
    return value
  },
  getYesNoByBool (value) {
    return value ? YES : NO
  },
  getVsdl (value) {
    if (value === 'NA') {
      return NOVSDL
    }
    return YES
  },
  getFiberStatus (value) {
    switch (value) {
      case '1':
      case '2':
        return 'fiber-status.building-installed'
      case '3':
        return 'fiber-status.building-sewerage'
      case '4':
        return 'fiber-status.plot-sewerage'
      case '5':
        return 'fiber-status.street-sewerage'
      case '6':
        return 'fiber-status.quotation'
      case '7':
      case '8':
      case '9':
        return 'fiber-status.offer'
      default:
        return ''
    }
  }
}

export default LocalizationService
