<template>
  <IframeView
    :banner="banner"
    :exception="isContentException"
    :breadcrumb-items="breadcrumbItems"
    :loading="isContentLoading"
    :url="computedUrl">
    <template #iframeSlot>
      <faults-page-alert v-if="showFaultPageAlert" />
    </template>
  </IframeView>
</template>

<script>
import FaultsPageAlert from '@/apps/fault-reporting/components/skanova/FaultsPageAlert'
import IframeView from '@/views/iframes/IframeView'
import { RouteName } from '@/apps/fault-reporting/router/routing-items'
import Cookies from 'js-cookie'

import {
  mapState,
  mapActions
} from 'vuex'
import { getIframeLocalisedURL } from '@/utils/locale.utils'

export default {
  components: {
    FaultsPageAlert,
    IframeView
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    banner () {
      return {
        heading: this.content?.fields.pageBanner.fields.heading
      }
    },
    breadcrumbItems () {
      return this.content?.breadcrumbItems
    },
    showFaultPageAlert () {
      return this.$route.name === RouteName.SkanovaTicketCreate
    },
    ...mapState('FaultReporting/LandingPageContent', {
      isContentLoading: 'loading',
      isContentException: 'exception',
      content: 'content'
    }),
    computedUrl () {
      const username = Cookies.get('username')

      return getIframeLocalisedURL(this.url + username)
    }
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('FaultReporting/LandingPageContent', ['loadContent'])
  }
}
</script>
