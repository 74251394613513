import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_BILLING_SYSTEM_INTEGRATION_API)

const getInvoices = () => api.get(
  '/invoices', {
    params: {
      offset: 0,
      limit: 10000
    }
  }
)

const getInvoicesForCompany = (company) => api.get(
  `/invoices/${company}`, {
    params: {
      offset: 0,
      limit: 10000
    }
  }
)

const getSpecifications = () => api.get(
  '/specifications', {
    params: {
      offset: 0,
      limit: 10000 // TODO ask BDL to open an unlimited endpoint
    }
  }
)

const getSpecificationsForCompany = company => api.get(
  `/company-specifications/${company}`, {
    params: {
      offset: 0,
      limit: 10000 // TODO ask BDL to open an unlimited endpoint
    }
  }
)

export default {
  getInvoices,
  getInvoicesForCompany,
  getSpecifications,
  getSpecificationsForCompany
}
