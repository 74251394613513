import i18n from '@/i18n'

const URIUtils = {
  decodeURIParam (param) {
    try {
      return decodeURIComponent(param)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Malformed URI param: ' + param)
      return null
    }
  },
  constructFragaBestallaLink () {
    return location.origin +
        i18n.t('products-display.wholesale-fiber-outside.quoteOrderUrlPart1') +
        location.host +
        i18n.t('products-display.wholesale-fiber-outside.quoteOrderUrlPart2')
  }
}

export default URIUtils
