import { USER_FIELDS } from '@/common/auth-constants'

const hasWebTabAdministrativeRole = (rolesList) => {
  const roles = [
    'WEBTAB_ADMIN_SUPERUSER',
    'WEBTAB_ADMIN_MAINTENANCE',
    'WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE',
    'WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR',
    'WEBTAB_ADMIN_TAB_PORTING_INTERNAL',
    'WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL'
  ]

  return roles.some(role => rolesList.includes(role))
}

function getRolesList () {
  const rolesString = localStorage.getItem(USER_FIELDS.ROLES)
  if (rolesString) {
    return JSON.parse(rolesString)
  }
  return []
}

export const EAMCookiesModule = {
  namespaced: true,
  state: {
    idToken: null,
    firstName: null,
    lastName: null,
    company: null,
    username: null,
    email: null,
    rolesList: []
  },
  mutations: {
    setFirstName (state, firstName) {
      state.firstName = firstName
    },
    setLastName (state, lastName) {
      state.lastName = lastName
    },
    setCompany (state, company) {
      state.company = company
    },
    setRolesList (state, rolesList) {
      state.rolesList = rolesList
    },
    setUsername (state, username) {
      state.username = username
    }
  },
  getters: {
    getUsername (state) {
      return state.username
    },
    isSuperUser (state) {
      return state.rolesList.includes('ADMINISTRATOR')
    },
    isDelegatedAdmin (state) {
      return state.rolesList.includes('DELEGATED_ADMINISTRATOR')
    },
    isAdmin (state) {
      return state.rolesList.includes('ADMINISTRATOR')
    },
    canTransferUsers (state) {
      return state.rolesList.includes('ADMINISTRATOR')
    },
    canCreateCompanies (state) {
      return state.rolesList.includes('ADMINISTRATOR')
    },
    canAdministrateUsers (state) {
      const roles = [
        'ADMINISTRATOR',
        'DELEGATED_ADMINISTRATOR'
      ]

      return roles.some(role => state.rolesList.includes(role))

    },
    canAdministrateReports (state) {
      return state.rolesList.includes('ADMINISTRATOR')
    },
    canAccessReports (state) {
      return state.rolesList.includes('REPORTS')
    },
    canUseSearchTools (state) {
      return state.rolesList.includes('SEARCH')
    },
    canAccessInvoices (state) {
      return state.rolesList.includes('INVOICES')
    },
    canAccessInvoiceSpecifications (state) {
      return state.rolesList.includes('SPECIFICATIONS')
    },
    isBillingAdmin (state) {
      return state.rolesList.includes('BILLING_ADMINISTRATOR')
    },
    hasUserOrderRole (state) {
      const roles = [
        'NETBUSINESS_QOUTE_CREATION',
        'NETBUSINESS_ORDER_CREATION',
        'PHONE_SUBCRIPTION_ORDER_CREATION',
        'PHONE_SUBCRIPTION_FAULT_HANDLING',
        'PHONE_SUBCRIPTION_ORDER_MANAGMENT'
      ]

      return roles.some(role => state.rolesList.includes(role))
    },
    hasUserMyOrdersRole (state) {
      const roles = [
        'NETBUSINESS_READ',
        'NETBUSINESS_QOUTE_CREATION',
        'NETBUSINESS_ORDER_CREATION',
        'PHONE_SUBCRIPTION_ORDER_CREATION',
        'PHONE_SUBCRIPTION_FAULT_HANDLING',
        'PHONE_SUBCRIPTION_ORDER_MANAGMENT'
      ]

      return roles.some(role => state.rolesList.includes(role))
        || hasWebTabAdministrativeRole(state.rolesList)
    },
    hasUserTicketsRole (state) {
      const roles = [
        'PHONE_SUBCRIPTION_FAULT_HANDLING',
        'TROUBLE_TICKET',
        'TROUBLE_TICKET_PLUS',
        'TROUBLE_TICKET_NO_ACCESS'
      ]

      return roles.some(role => state.rolesList.includes(role))
    },
    hasUserOperatorsbyteSkanovaTobRoles (state) {
      const roles = [
        'NETBUSINESS_ORDER_CREATION',
        'NETBUSINESS_QOUTE_CREATION'
      ]

      return roles.some(role => state.rolesList.includes(role))
    },
    hasOrdersNetbusinessRole (state) {
      const roles = [
        'NETBUSINESS_ORDER_CREATION',
        'NETBUSINESS_QOUTE_CREATION'
      ]

      return roles.some(role => state.rolesList.includes(role))
    },
    hasMyOrdersNetbusinessRole (state) {
      const roles = [
        'NETBUSINESS_READ',
        'NETBUSINESS_ORDER_CREATION',
        'NETBUSINESS_QOUTE_CREATION'
      ]

      return roles.some(role => state.rolesList.includes(role))
    },
    hasUserOperatorsbyteTelefoniamagRole (state) {
      const roles = [
        'PHONE_SUBCRIPTION_ORDER_CREATION',
        'PHONE_SUBCRIPTION_FAULT_HANDLING',
        'PHONE_SUBCRIPTION_ORDER_MANAGMENT'
      ]

      return roles.some(role => state.rolesList.includes(role))
    },
    hasOrdersTelefoniabonnemangRole (state) {
      const roles = [
        'PHONE_SUBCRIPTION_ORDER_CREATION',
        'PHONE_SUBCRIPTION_FAULT_HANDLING',
        'PHONE_SUBCRIPTION_ORDER_MANAGMENT'
      ]

      return roles.some(role => state.rolesList.includes(role))
    },
    hasMyOrdersTelefoniabonnemangRole (state) {
      return state.rolesList.includes('PHONE_SUBCRIPTION_FAULT_HANDLING') ||
        state.rolesList.includes('PHONE_SUBCRIPTION_ORDER_MANAGMENT') ||
        hasWebTabAdministrativeRole(state.rolesList)

    },
    hasTicketsTelefoniabonnemangRole (state) {
      return state.rolesList.includes('PHONE_SUBCRIPTION_FAULT_HANDLING')
    },
    hasTicketsSkanovaProductsRole (state) {
      const roles = [
        'TROUBLE_TICKET_PLUS',
        'TROUBLE_TICKET'
      ]

      return roles.some(role => state.rolesList.includes(role))
    },
    hasAgreementsResponsibleRole (state) {
      return state.rolesList.includes('AGREEMENT_RESPONSIBLE')
    },
    hasInvoiceSpecificationsRole (state) {
      return state.rolesList.includes('SPECIFICATIONS')
    },
    getFirstName (state) {
      return !state.firstName ? null : state.firstName.replace(/\+/g, ' ')
    },
    getLastName (state) {
      return !state.lastName ? null : state.lastName.replace(/\+/g, ' ')
    },
    getFullName (state, getters) {
      return getters.getFirstName + ' ' + getters.getLastName
    },
    getCompany (state) {
      let formattedCompany = state.company

      if (formattedCompany) {
        formattedCompany = formattedCompany.replace(/NB__/, '')
        formattedCompany = formattedCompany.replace(/\+/g, ' ')
        formattedCompany = formattedCompany.replace(/(,)?(\s+)?\d+-\d+/, '')
      }

      return formattedCompany
    },
    isLoggedIn (state) {
      return !!state.firstName
        && !!state.lastName
        && !!state.company
    }
  },
  actions: {
    loadUser ({ commit }) {
      commit('setFirstName', localStorage.getItem(USER_FIELDS.FIRST_NAME))
      commit('setLastName', localStorage.getItem(USER_FIELDS.LAST_NAME))
      commit('setCompany', localStorage.getItem(USER_FIELDS.COMPANY_NAME))
      commit('setUsername', localStorage.getItem(USER_FIELDS.USERNAME))

      const rolesList = getRolesList()

      commit('setRolesList', rolesList)
    }
  }
}
