import {
  fetchCaseDetails, putAttachments, removeAttachment
} from '@/apps/fault-reporting/services/teliaNOW.service'

export default {
  namespaced: true,
  state: {
    response: null,
    loading: false,
    exception: false,
    attachmentsLoading: false,
    attachmentsException: false,
    removeAttachmentLoading: false
  },
  mutations: {
    setResponse: (state, response) => state.response = response,
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setAttachmentsLoading: (state, loading) => state.attachmentsLoading = loading,
    setAttachmentsException: (state, exception) => state.attachmentsException = exception,
    setRemoveAttachmentLoading: (state, loading) => state.removeAttachmentLoading = loading
  },
  getters: {
    attachments: (state) => state.response.attachments.map(attachment => {
      return { fileName: attachment.name }
    }),
    caseId: (state) => state.response.caseId
  },
  actions: {
    async fetchDetails ({ commit }, id) {
      try {
        commit('setResponse', null)
        commit('setLoading', true)
        commit('setException', false)

        const response = await fetchCaseDetails(id)

        commit('setResponse', response)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    async putAttachments ({ commit, getters }, files) {
      try {
        commit('setAttachmentsLoading', true)
        commit('setAttachmentsException', false)

        const response = await putAttachments(getters.caseId, files)

        commit('setResponse', response)
      } catch {
        commit('setAttachmentsException', true)
      } finally {
        commit('setAttachmentsLoading', false)
      }
    },
    async removeAttachment ({ commit, getters }, fileKey) {
      try {
        commit('setRemoveAttachmentLoading', true)
        commit('setAttachmentsException', false)

        const response = await removeAttachment(getters.caseId, fileKey)
        commit('setResponse', response)
      } catch {
        commit('setAttachmentsException', true)
      } finally {
        commit('setRemoveAttachmentLoading', false)
      }
    },
    updateCaseResponse ({ commit }, response) {
      commit('setResponse', response)
    }
  }
}
