<template>
  <div class="related-products">
    <h3 class="related-products__heading heading-m">
      {{ heading }}
    </h3>

    <ul
      v-if="items"
      class="related-products__list">
      <RelatedProduct
        v-for="(item, index) in items"
        :key="index"
        :path="item.path"
        :title="item.title" />
    </ul>
  </div>
</template>

<script>
import RelatedProduct from './RelatedProduct'

export default {
  components: { RelatedProduct },
  props: {
    heading: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.related-products {
  background-color: $light-grey;
  padding: 24px;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  @media (max-width: $breakpoint-md) {
    margin-top: 15px;
  }

  &__heading {
    margin-bottom: 20px;
    font-size: 24px;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}
</style>
