import isFuture from 'date-fns/isFuture'
import isToday from 'date-fns/isToday'
import isSameDay from 'date-fns/isSameDay'
import isPast from 'date-fns/isPast'
import format from 'date-fns/format'
import locale from 'date-fns/locale/sv'
import localeEn from 'date-fns/locale/en-GB'
import addDays from 'date-fns/addDays'
import addHours from 'date-fns/addHours'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import { isLocaleEnglish } from '@/utils/locale.utils'

const formatOpts = isLocaleEnglish() ? { localeEn } : { locale }

const parse = date => {
  return typeof date === 'string' ? parseISO(date) : date
}

const DateTimeUtils = {
  DATE_FORMAT_ISO: 'yyyy-MM-dd',
  DATE_FORMAT: 'dd MMMM',
  DATE_TIME_FORMAT: 'dd MMMM HH:mm',
  TIME_FORMAT: 'HH:mm',

  computeDate (startDate, endDate) {
    if (!isSameDay(parse(startDate), parse(endDate))) {
      const endDateRange = endDate ? ' - ' + DateTimeUtils.formatDateTime(endDate) : ''
      return DateTimeUtils.formatDateTime(startDate) + endDateRange
    }

    const endTimeRange = endDate ? ' - ' + DateTimeUtils.formatTime(endDate) : ''
    return DateTimeUtils.formatDateTime(startDate) + endTimeRange
  },

  safeFormat (date, formatting) {
    return date ? format(parse(date), formatting, formatOpts) : ''
  },
  formatDateTime (date) {
    return DateTimeUtils.safeFormat(date, DateTimeUtils.DATE_TIME_FORMAT)
  },
  formatTime (date) {
    return DateTimeUtils.safeFormat(date, DateTimeUtils.TIME_FORMAT)
  },
  formatSevereDate (date) {
    return DateTimeUtils.safeFormat(date, DateTimeUtils.DATE_FORMAT)
  },
  formatDateIso (date) {
    return DateTimeUtils.safeFormat(date, DateTimeUtils.DATE_FORMAT_ISO)
  },
  formatCustom (date, formatting) {
    return DateTimeUtils.safeFormat(date, formatting)
  },

  isFutureDate (date) {
    return isFuture(parse(date))
  },
  isPastDate (date) {
    return isPast(parse(date))
  },
  is1After2 (date1, date2) {
    return isAfter(parse(date1), parse(date2))
  },
  isTodayOrAfter: date => {
    return isToday(parse(date)) || isFuture(parse(date))
  },

  addDays (date, daysToAdd) {
    return addDays(parse(date), daysToAdd)
  },
  addHours (date, hours) {
    return addHours(parse(date), hours)
  }
}

export default DateTimeUtils
