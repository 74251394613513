import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_EMAIL_NOTIFICATION_PREFERENCES_API)

const EmailNotificationPreferencesService = {
  get: async () => {
    return await api.get('/')
  },
  save: async preferences => {
    return await api.post('/', preferences)
  }
}

export default EmailNotificationPreferencesService
