<template>
  <div class="view-wrapper">
    <div
      ref="subCategoriesView"
      class="sub-categories-view">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3 class="sub-categories-view__title heading-s">
              {{ $t("sub-categories.title") }}
            </h3>
          </div>
        </div>

        <div class="sub-categories-row row">
          <SubCategory
            v-for="(subCategory, index) in activeCategory.fields
              .subcategories"
            :key="index"
            :title="subCategory.fields.title"
            :icon="subCategory.fields.iconV2"
            :path="`/kontakt/${activeCategory.fields.path}/${subCategory.fields.path}`" />
        </div>
      </div>
    </div>

    <router-view
      :key="`${$route.params.category}/${$route.params.subCategory}`" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ViewMixin from '../mixins/view.mixin.js'
import { SubCategory } from '../components/'

export default {
  components: {
    SubCategory
  },
  mixins: [ViewMixin],
  computed: {
    ...mapState('ContactUs', ['categories'])
  },
  mounted () {
    if (!this.$route.params.subCategory) {
      document.title = this.activeCategory
        ? this.activeCategory.fields.title
        : ''

      this.scrollIntoView()
    }
  },
  methods: {
    scrollIntoView () {
      const element = this.$refs.subCategoriesView
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.sub-categories {
    &-view {
        background-color: $light-grey;
        padding-top: 45px;
        padding-bottom: 45px;

        &__title {
            margin-bottom: 20px;
        }
    }

    &-row {
        margin: -1px;
    }
}
</style>
