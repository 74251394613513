<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <company-administration-banner
        :help-dialog-header="$t('company-administration.help.header')"
        :help-dialog-text="$t('company-administration.help.text')" />
    </InfraBreadcrumbContainer>

    <company-administration-list />
  </div>
</template>

<script>
import CompanyAdministrationBanner from './company-administration/company-administration-banner.vue'
import CompanyAdministrationList from './company-administration/company-administration-list.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_COMPANY_ADMINISTRATION } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    CompanyAdministrationBanner,
    CompanyAdministrationList,
    InfraBreadcrumbContainer
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_COMPANY_ADMINISTRATION
    }
  }
}
</script>
