<template>
  <div
    v-if="isValidatorCreated && getValidatorFormField(labels.field_name)"
    :style="widthStyle"
    @click="showDependencyMessage">
    <tws-validated-checkbox
      :id="`form-checkbox-${labels.field_name}`"
      v-model="model"
      :validator="getValidatorFormField(labels.field_name)"
      :validation-messages="validationMessages"
      :label="labels.checkbox_label"
      :disabled="disabledField(labels.field_name)" />

    <p
      v-show="showFieldMessage"
      class="dependency-text">
      {{ dependencyMessage }}
    </p>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import { TwsValidatedCheckbox } from 'tws-vue-components'

export default {
  name: 'TwsFormCheckbox',
  components: {
    TwsValidatedCheckbox
  },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        required: this.labels.validation_message
      },
      showFieldMessage: false
    }
  },
  computed: {
    ...mapGetters('DynamicForm', [
      'getValidatorFormField',
      'isValidatorCreated',
      'disabledField',
      'getFieldDependencyMessage',
      'getFieldValue'
    ]),
    model: {
      get () {
        return !!this.getFieldValue(this.labels.field_name)
      },
      set (value) {
        this.onValueInput(value)
      }
    },
    dependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        return this.getFieldDependencyMessage(this.labels.field_name)
      }
      return ''

    },
    widthStyle () {
      const componentWidth = this.labels.component_width
      return componentWidth ? 'width: ' + componentWidth + '%;' : ''
    }
  },
  mounted () {
    this.addValidation(
      {
        fieldName: this.labels.field_name,
        validations: this.labels.validations
      }
    )
    this.fieldCreated(
      {
        fieldName: this.labels.field_name,
        label: this.labels.checkbox_label,
        templateText: this.labels.template_text,
        isFieldRequired: this.labels.is_field_required
      }
    )
  },
  methods: {
    ...mapActions('DynamicForm',
      [
        'fieldCreated',
        'fieldValueEntered',
        'addValidation',
        'removeEmailByFieldName'
      ]
    ),
    onValueInput (value) {
      this.fieldValueEntered(
        {
          key: this.labels.field_name,
          value: value ? this.labels.value : '',
          email: this.labels.email
        }
      )

      if (!value) {
        this.removeEmailByFieldName(this.labels.field_name)
      }
    },
    showDependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        this.showFieldMessage = !this.showFieldMessage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dependency-text {
    color: grey;
    font-size: medium;
  }
</style>
