<template>
  <AddressElementPicker
    :title="titlePrefix + pickedCity.toUpperCase()"
    :sub-title="$t('address-picker.street.sub-title')"
    :options="streetNames"
    :can-go-back="false"
    @select="$emit('street-pick', $event.value)"
    @close="$emit('close')" />
</template>

<script>
import AddressElementPicker from './AddressElementPicker'

const reduceToStreetNames = (agg, address) => {
  agg.add(address.streetName)
  return agg
}

export default {
  components: { AddressElementPicker },
  props: {
    addresses: {
      type: Array,
      required: true
    },
    pickedCity: {
      type: String,
      required: true
    },
    canGoBack: {
      type: Boolean,
      default: false
    },
    titlePrefix: {
      type: String,
      default: ''
    }
  },
  computed: {
    streetNames () {
      return Array
        .from(this.addresses.reduce(reduceToStreetNames, new Set()))
        .map(streetName => ({
          label: streetName,
          value: streetName,
          width: 'half'
        }))
    }
  }
}
</script>
