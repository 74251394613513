<template>
  <div class="row fault-report-basic-info">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <p class="title-200">
            {{ $t('fault-reporting.form.basic-info.description') }}
          </p>
        </div>
      </div>

      <div>
        <div class="row">
          <div class="fault-report-basic-info__ref-number col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedTextInputGroup
              id="customerRefNumber"
              v-model="customerRefNumberProxy"
              :validator="$v.customerRefNumber"
              :tooltip-text="$t('fault-reporting.form.basic-info.customer-ref-number.tooltip')"
              :tooltip-width="tooltipWidth"
              :validation-messages="validationMessages.customerRefNumber"
              :label="$t('fault-reporting.form.basic-info.customer-ref-number.label')" />
          </div>
        </div>

        <div class="row fieldset">
          <div class="fault-reporting__action-panel__products-search col-xl-5 col-sm-5 col-xs-12">
            <InfraSearchableDropdown
              id="faultCategory"
              ref="faultCategoryRef"
              v-model="faultCategoryProxy"
              :options="getFaultCategoriesOptions"
              :label-getter="categoriesLabelGetter"
              :validator="$v.faultCategory"
              :validation-messages="validationMessages.faultCategory"
              :label="$t('fault-reporting.form.basic-info.category.label')" />
          </div>

          <template v-if="isMobileSP">
            <div class="fault-reporting__action-panel__problem-type col-xl-5 col-sm-5 col-xs-12">
              <InfraSearchableDropdown
                id="problemType"
                ref="problemTypeRef"
                v-model="problemTypeProxy"
                :disabled="!faultCategoryProxy"
                :options="getProblemTypeOptions"
                :label-getter="categoriesLabelGetter"
                :validator="$v.problemType"
                :validation-messages="validationMessages.problemType"
                :label="$t('fault-reporting.form.basic-info.problem-type')" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  InfraSearchableDropdown, TwsValidatedTextInputGroup
} from 'tws-vue-components'
import {
  mapState, mapActions
} from 'vuex'
import {
  required, maxLength, requiredIf
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  FORM_TYPE, PRODUCT_CATEGORY_ITEMS, TOOLTIP_WIDTH, FAULT_CATEGORY_DROPDOWN_OPTION
} from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  components: {
    TwsValidatedTextInputGroup,
    InfraSearchableDropdown
  },
  mixins: [validationMixin],
  props: {
    selectedProduct: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        customerRefNumber: {
          required: this.$t('fault-reporting.form.basic-info.customer-ref-number.validation-message'),
          maxLength: this.$t('fault-reporting.form.basic-info.customer-ref-number.validation-message-max-length')
        },
        faultCategory: {
          required: this.$t('fault-reporting.form.basic-info.category.validation-message')
        },
        problemType: {
          required: this.$t('fault-reporting.form.basic-info.category.validation-message')
        }
      },
      tooltipWidth: TOOLTIP_WIDTH
    }
  },
  computed: {
    ...mapState('FaultReporting/FaultReportForm/BasicInfo', [
      'customerRefNumber',
      'faultCategory',
      'problemType',
      'isValid'
    ]),
    customerRefNumberProxy: {
      get () {
        return this.customerRefNumber
      },
      set (value) {
        this.updateCustomerRefNumber(value)
      }
    },
    faultCategoryProxy: {
      get () {
        return this.faultCategory
      },
      set (value) {
        this.updateFaultCategory(value)
      }
    },
    problemTypeProxy: {
      get () {
        return this.problemType
      },
      set (value) {
        this.updateProblemType(value)
      }
    },
    isValidProxy () {
      return !this.$v.$invalid
    },
    isMobileSP () {
      return PRODUCT_CATEGORY_ITEMS[this.selectedProduct.categoryId].FORM_TYPE === FORM_TYPE.MOBILESP
    },
    getFaultCategoriesOptions () {
      return PRODUCT_CATEGORY_ITEMS[this.selectedProduct.categoryId].FAULT_CATEGORY_OPTIONS
    },
    getProblemTypeOptions () {
      const [key = ''] = Object.entries(FAULT_CATEGORY_DROPDOWN_OPTION).find((
        [
          , value
        ]
      ) => value.value === this.faultCategoryProxy?.value) || []

      return key ? PRODUCT_CATEGORY_ITEMS[this.selectedProduct.categoryId].PROBLEM_TYPE_OPTIONS[key] : []
    }
  },
  validations: {
    customerRefNumber: {
      required,
      maxLength: maxLength(50)
    },
    faultCategory: {
      required
    },
    problemType: {
      required: requiredIf(function () {
        return this.isMobileSP
      })
    }
  },
  watch: {
    isValidProxy (val) {
      this.updateIsValid(val)
    }
  },
  methods: {
    ...mapActions('FaultReporting/FaultReportForm/BasicInfo', [
      'updateCustomerRefNumber',
      'updateFaultCategory',
      'updateProblemType',
      'updateIsValid'
    ]),
    categoriesLabelGetter (val) {
      return val && val.label ? val.label : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.fault-reporting__action-panel__products-search {
  padding-right: 40px;
}

.fault-reporting__action-panel__problem-type {
  @media screen and (max-width: 703px) {
    padding-right: 40px;
  }
}
</style>
