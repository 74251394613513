<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <user-details-banner />
    </InfraBreadcrumbContainer>

    <div class="user-details-container">
      <div v-if="informationLoading || informationException">
        <div class="container">
          <div class="row">
            <div class="col">
              <InfraPageAlert
                v-if="informationLoading"
                type="info">
                {{ $t('user-details.loading') }}
              </InfraPageAlert>

              <InfraPageAlert
                v-if="informationException"
                type="danger">
                {{ $t('user-details.loading-failed') }}
              </InfraPageAlert>
            </div>
          </div>
        </div>
      </div>

      <template v-if="informationLoaded">
        <tws-personal-information-container
          :countries="countries"
          :companies="companies" />

        <tws-account-information-container />

        <tws-account-history />
      </template>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import PersonalInformationContainer
  from '@/components/personal-information/personal-information-container.vue'
import AccountInformationContainer
  from '@/components/account-information/account-information-container.vue'
import AccountHistory from '@/components/account-information/account-history.vue'

import { InfraPageAlert } from 'tws-vue-components'
import UserDetailsBanner from './user-details-banner.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_USER_DETAILS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    'tws-personal-information-container': PersonalInformationContainer,
    'tws-account-information-container': AccountInformationContainer,
    'tws-account-history': AccountHistory,
    InfraPageAlert,
    UserDetailsBanner,
    InfraBreadcrumbContainer
  },
  data () {
    return {
      userId: this.$route.query.userId,
      username: this.$route.query.username,
      breadcrumbItems: BREADCRUMBS_USER_DETAILS
    }
  },
  computed: {
    ...mapState('userDetails', [
      'informationLoading',
      'informationLoaded',
      'informationException',
      'information'
    ]),
    ...mapState('lookups/countries', {
      countries: 'countries'
    }),
    ...mapState('lookups/companies', {
      companies: 'companies'
    })
  },
  mounted () {
    this.fetchCountries()
    this.fetchCompanies()
    this.fetchUser({
      userId: this.userId,
      username: this.username
    })
  },
  methods: {
    ...mapActions({
      fetchCountries: 'lookups/countries/fetchCountries',
      fetchCompanies: 'lookups/companies/fetchCompanies',
      fetchUser: 'userDetails/fetchUser'
    })
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 45px;
}
</style>
