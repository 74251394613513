<template>
  <div>
    <GrootHeroBanner
      title="Begäran om tillträde till Skanovas fysiska infrastruktur"
      :breadcrumbs="breadcrumbItems" />

    <div class="form-container">
      <component
        :is="form"
        v-if="html" />
    </div>
  </div>
</template>

<script>
import { BREADCRUMBS_DEVELOPMENT_ACT } from '@/constants/breadcrumbs-constants'
import { GrootHeroBanner } from 'groot-components'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  components: {
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_DEVELOPMENT_ACT,
      html: ''
    }
  },
  computed: {
    form () {
      return {
        template: this.html
      }
    }
  },
  mounted () {
    this.loadContent()
  },
  methods: {
    loadContent () {
      const htmlContent = require('./html/development-act.html').default

      this.html = htmlContent
    }
  }
}
</script>
