<script>
import { chevronRight } from '@purpurds/icon'
import PurpurParagraph from '../purpur/PurpurParagraph.vue'
import PurpurLink from '../purpur/PurpurLink.vue'
import PurpurIcon from '../purpur/PurpurIcon.vue'

export default {
  components: {
    PurpurParagraph,
    PurpurLink,
    PurpurIcon
  },
  props: {
    text: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      chevronRight,
      target: this.link?.includes('http') ? '_blank' : '_self'
    }
  }
}
</script>

<template>
  <PurpurLink
    class="product-card-link"
    :href="link"
    :target="target"
    variant="navigation">
    <PurpurParagraph
      variant="paragraph-100-medium">
      <span class="product-card-link__text">
        {{ text }}
      </span>
    </PurpurParagraph>

    <PurpurIcon
      class="product-card-link__icon"
      :svg="chevronRight" />
  </PurpurLink>
</template>

<style lang="scss" scoped>
@use "@purpurds/tokens/breakpoint/variables" as breakpoints;
@use "@purpurds/tokens/color/variables" as colors;
@use '../../assets/scss/_mixins.scss' as mixins;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;

::v-deep.product-card-link a {
  > div {
    width: 100%;
    > span {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }
  }

  padding:  spacing.$purpur-spacing-200;
  border-radius:  spacing.$purpur-spacing-200;
  background: colors.$purpur-color-purple-50;
  border: 1px solid colors.$purpur-color-purple-800;
  box-sizing: border-box;
  @include mixins.indent-margin;

  @media screen and (min-width: breakpoints.$purpur-breakpoint-md) {
    padding:  spacing.$purpur-spacing-200 spacing.$purpur-spacing-300;
  }

  @media screen and (min-width: breakpoints.$purpur-breakpoint-xl) {
    margin: 0;
  }

  &:hover {
    background: colors.$purpur-color-purple-100;
  }
}

.product-card-link__text {
  color: colors.$purpur-color-purple-800;
}

::v-deep .product-card-link__icon span {
  width: spacing.$purpur-spacing-300;
  height: spacing.$purpur-spacing-300;
  color: colors.$purpur-color-functional-black;
}
</style>
