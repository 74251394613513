const capitalizeToken = input => `${input.substr(0, 1).toUpperCase()}${input.substr(1).toLowerCase()}`

const capitalizeString = (input) => {
  const words = input.split(' ').map(capitalizeToken)
  return words.join(' ')
}

export const printAddress = ({ streetName, streetNumber, entrance, city, postNumber }) => {
  if (!streetName || !city) {
    return ''
  }

  let string = `${capitalizeString(streetName)}`

  if (streetNumber) {
    string += ` ${streetNumber}`

    if (entrance) {
      string += ` ${entrance.toUpperCase()}`
    }
  }

  string += ','

  if (postNumber) {
    string += ` ${postNumber}`
  }

  string += ` ${capitalizeString(city)}`

  return string
}

export const printNode = ({ node, coverageArea, kc }) => {
  let string = node || coverageArea || ''

  if (kc) {
    string += ` ${kc}`
  }

  return string
}

export const printProperty = ({ municipality, district, block, unit }) => {
  if (!municipality) {
    return ''
  }

  let string = capitalizeString(municipality)

  if (district) {
    string += `, ${capitalizeString(district)}`
  }

  if (block && unit) {
    string += ` ${block}:${unit}`
  }

  return string
}
