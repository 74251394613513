<template>
  <div class="container">
    <div class="row">
      <div class="col heading">
        <h1 class="heading-m">
          {{ title }}
        </h1>

        <tws-borderless-button
          :disabled="updating"
          @click="toggleEdit">
          {{ editing ? $t('common.cancel') : $t('common.change') }}

          <tws-edit-icon
            v-if="!editing"
            class="edit-icon" />
        </tws-borderless-button>
      </div>
    </div>

    <slot />

    <div
      v-show="editing"
      class="row">
      <div class="col edit-actions">
        <tws-button-primary
          :disabled="updating"
          @click="confirmEdit">
          {{ updating ? $t('common.updating') : $t('user-administration.save-changes') }}
        </tws-button-primary>

        <tws-borderless-button
          :disabled="updating"
          @click="toggleEdit">
          {{ $t('common.cancel') }}
        </tws-borderless-button>
      </div>
    </div>

    <hr>
  </div>
</template>

<script>
import {
  TwsBorderlessButton,
  TwsButtonPrimary
} from 'tws-vue-components'

export default {
  components: {
    TwsBorderlessButton,
    TwsButtonPrimary
  },
  props: {
    title: {
      type: String,
      required: true
    },
    validator: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggleEdit () {
      this.$emit('toggleEdit')
    },
    confirmEdit () {
      this.validator.$touch()

      if (this.validator.$invalid) {
        return
      }

      this.$emit('confirmEdit')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.container {
  padding-top: 45px;
}

.edit-icon {
  margin-left: 5px;
}

.heading {
  display: flex;
  padding-bottom: 30px;

  .tws-borderless-button {
    margin-left: auto;
  }
}

hr {
  margin-top: 25px;
  border-color: $core-purple;
}

.edit-actions .tws-borderless-button {
  margin-left: 25px;
}

.tws-borderless-button {
  color: $core-purple;
}
</style>
