<template>
  <div
    v-if="isValidatorCreated && getValidatorFormField(labels.field_name)"
    @click="showDependencyMessage">
    <tws-validated-text-area-group
      :id="'form-text-area' + labels.field_name"
      v-model="model"
      :label="labels.field_label"
      :placeholder="labels.field_placeholder"
      :tooltip-text="labels.tooltip_text"
      :tooltip-width="tooltipWidth"
      :validator="getValidatorFormField(labels.field_name)"
      :validation-messages="validationMessages"
      :disabled="disabledField(labels.field_name)"
      @input="onValueInput" />
    <p
      v-show="showFieldMessage"
      class="dependency-text">
      {{ dependencyMessage }}
    </p>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import { TwsValidatedTextAreaGroup } from 'tws-vue-components'

export default {
  name: 'TwsFormInputField',
  components: {
    TwsValidatedTextAreaGroup
  },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        required: this.labels.validation_message
      },
      showFieldMessage: false
    }
  },
  computed: {
    ...mapGetters('DynamicForm', [
      'getValidatorFormField',
      'isValidatorCreated',
      'disabledField',
      'getFieldDependencyMessage',
      'getFieldValue'
    ]),
    model: {
      get () {
        return this.getFieldValue(this.labels.field_name)
      },
      set (value) {
        this.onValueInput(value)
      }
    },
    tooltipWidth () {
      return this.labels.tooltip_width.concat('px')
    },
    dependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        return this.getFieldDependencyMessage(this.labels.field_name)
      }
      return ''

    }
  },
  mounted () {
    this.addValidation(
      {
        fieldName: this.labels.field_name,
        validations: this.labels.validations
      })
    this.fieldCreated(
      {
        fieldName: this.labels.field_name,
        label: this.labels.text_area_label,
        templateText: this.labels.template_text,
        isFieldRequired: this.labels.is_field_required
      })
  },
  methods: {
    ...mapActions('DynamicForm', [
      'fieldCreated',
      'fieldValueEntered',
      'addValidation'
    ]),
    onValueInput (value) {
      this.fieldValueEntered(
        {
          key: this.labels.field_name,
          value: value
        })
    },
    showDependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        this.showFieldMessage = !this.showFieldMessage
      }
    }
  }
}
</script>>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

  .dependency-text {
    color: $grey;
    font-size: medium;
  }

</style>
