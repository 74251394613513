<template>
  <div>
    <Stepper
      :route-index="routeIndex"
      :routes="routes"
      :picked-city="pickedCity"
      :picked-street="pickedStreet"
      :picked-street-number="pickedStreetNumber"
      :picked-post-number="pickedPostNumber"
      :addresses="filteredAddresses"
      :title-prefix="titlePrefix"
      :can-go-back="canGoBack"
      @street-pick="handleStreetPick"
      @street-number-pick="handleStreetNumberPick"
      @post-number-pick="handlePostNumberPick"
      @entrance-pick="handleEntrancePick"
      @close="handleClose" />
  </div>
</template>

<script>
import Stepper from '@/apps/address-search/components/Stepper'
import StreetPicker from './StreetPicker'
import StreetNumberPicker from './StreetNumberPicker'
import PostNumberPicker from './PostNumberPicker'
import EntrancePicker from './EntrancePicker'

const ROUTES = [
  { component: StreetPicker },
  { component: StreetNumberPicker },
  { component: PostNumberPicker },
  { component: EntrancePicker }
]

const POST_NUMBER_PICKER_INDEX = 2

const reduceToCities = (agg, address) => { agg.add(address.city); return agg }
const reduceToStreets = (agg, address) => { agg.add(address.streetName); return agg }
const reduceToStreetNumbers = (agg, address) => { agg.add(address.streetNumber); return agg }
const reduceToPostNumbers = (agg, address) => { agg.add(address.postNumber); return agg }
const reduceToEntrances = (agg, address) => { agg.add(address.entrance); return agg }

const extractSingleResult = (reducer) => function (key) {
  const [
    foundResult,
    ...ambiguation
  ] = Array.from(this[key].reduce(reducer, new Set()))
  if (ambiguation.length > 0) {
    return null
  }
  return foundResult || null
}

export default {
  components: {
    Stepper
  },
  props: {
    addresses: {
      type: Array,
      required: true
    },
    point: {
      type: String,
      default: null
    }
  },
  data () {
    const foundStreet = this.getSingleStreetFrom('addresses')
    const foundStreetNumber = this.getSingleStreetNumberFrom('addresses')
    const foundPostNumber = this.getSinglePostNumberFrom('addresses')
    const foundEntrance = this.getSingleEntranceFrom('addresses')

    const foundElements = [
      foundStreet,
      foundStreetNumber,
      foundPostNumber,
      foundEntrance
    ]

    return {
      routes: ROUTES,
      routeIndex: foundElements.indexOf(null),
      pickedCity: this.getSingleCityFrom('addresses'),
      pickedStreet: foundStreet,
      pickedStreetNumber: foundStreetNumber,
      pickedPostNumber: foundPostNumber,
      pickedEntrance: foundEntrance,
      foundElements: Object.freeze(foundElements)
    }
  },
  computed: {
    titlePrefix () {
      if (this.point) {
        return `${this.$t('general.point')} ${this.point}: `
      }

      return ''
    },
    canGoBack () {
      return this.foundElements.indexOf(null) < this.routeIndex
    },
    filteredAddresses () {
      return this.addresses
        .filter(a => (this.pickedStreet === null || this.pickedStreet === a.streetName)
          && (this.pickedStreetNumber === null || this.pickedStreetNumber === a.streetNumber)
          && (this.pickedPostNumber === null || this.pickedPostNumber === a.postNumber)
          && (this.pickedEntrance === null || this.pickedEntrance === a.entrance)
        )
    }
  },
  methods: {
    getSingleCityFrom: extractSingleResult(reduceToCities),
    getSingleStreetFrom: extractSingleResult(reduceToStreets),
    getSingleStreetNumberFrom: extractSingleResult(reduceToStreetNumbers),
    getSinglePostNumberFrom: extractSingleResult(reduceToPostNumbers),
    getSingleEntranceFrom: extractSingleResult(reduceToEntrances),
    getPickedElementKeyByIndex (routeIndex) {
      switch (routeIndex) {
        case 0: return 'pickedStreet'
        case 1: return 'pickedStreetNumber'
        case 2: return 'pickedPostNumber'
        case 3: return 'pickedEntrance'
        default: throw Error('invalid picker requested')
      }
    },
    getNextRouteIndex () {
      const pickedElements = [
        this.pickedStreet,
        this.pickedStreetNumber,
        this.pickedPostNumber,
        this.pickedEntrance
      ]

      const calculatedIndex = pickedElements.indexOf(null)
      const postNumberFound = this.getSinglePostNumberFrom('filteredAddresses')

      return calculatedIndex === POST_NUMBER_PICKER_INDEX && postNumberFound
        ? POST_NUMBER_PICKER_INDEX + 1
        : calculatedIndex
    },
    getPrevRouteIndex () {
      const calculatedIndex = this.foundElements.slice(0, this.routeIndex).lastIndexOf(null)
      const postNumberFound = this.getSinglePostNumberFrom('filteredAddresses')

      return calculatedIndex === POST_NUMBER_PICKER_INDEX && postNumberFound
        ? POST_NUMBER_PICKER_INDEX - 1
        : calculatedIndex
    },
    completePicking () {
      this.$emit('address-pick', this.filteredAddresses[0])
    },
    handleClose () {
      this.$emit('close')
    },
    handleStreetPick (street) {
      this.pickedStreet = street
      if (this.filteredAddresses.length === 1) {
        this.completePicking()
        return
      }

      this.updatePickerIndex(street !== null)
    },
    handleStreetNumberPick (streetNumber) {
      this.pickedStreetNumber = streetNumber
      this.pickedPostNumber = this.getSinglePostNumberFrom('filteredAddresses')

      if (this.filteredAddresses.length === 1) {
        this.completePicking()
        return
      }

      this.updatePickerIndex(streetNumber !== null)
    },
    handlePostNumberPick (postNumber) {
      this.pickedPostNumber = postNumber
      if (this.filteredAddresses.length === 1) {
        this.completePicking()
        return
      }

      this.updatePickerIndex(postNumber !== null)
    },
    handleEntrancePick (entrance) {
      this.pickedEntrance = entrance

      if (entrance !== null) {
        this.completePicking()
      } else {
        this.pickedPostNumber = null
        this.updatePickerIndex(false)
      }
    },
    updatePickerIndex (forward) {
      if (forward) {
        this.routeIndex = this.getNextRouteIndex()
      } else {
        const routeIndex = this.getPrevRouteIndex()
        this[this.getPickedElementKeyByIndex(routeIndex)] = null
        this.routeIndex = routeIndex
      }
    }
  }
}
</script>
