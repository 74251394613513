import {
  FORM_DESCRIPTION_PROPERTIES,
  FORM_TYPE
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import { sendForm } from '@/apps/fault-reporting/services/teliaNOW.service'

export default {
  namespaced: true,
  state: {
    [FORM_DESCRIPTION_PROPERTIES.CASE_ID]: '',
    [FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO]: '',
    [FORM_DESCRIPTION_PROPERTIES.COMPLAINT]: '',
    [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: [],
    [FORM_DESCRIPTION_PROPERTIES.HAS_TRAFFIC_WORKED_BEFORE]: null,
    [FORM_DESCRIPTION_PROPERTIES.IS_CAUSED_BY_PORTING]: null,
    [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_BETWEEN_OPERATOR_AFFECTED]: null,
    [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_FOR_CUSTOMER_AFFECTED]: null,
    [FORM_DESCRIPTION_PROPERTIES.AFFECTED_CUSTOMER]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_TYPE_AFFECTED]: null,
    [FORM_DESCRIPTION_PROPERTIES.AFFECTED_TYPE]: '',
    [FORM_DESCRIPTION_PROPERTIES.IS_VALID]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_LOADING]: false,
    [FORM_DESCRIPTION_PROPERTIES.IS_ERROR]: false
  },
  mutations: {
    setCaseId: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.CASE_ID] = val,
    setAdditionalInformation: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO] = val,
    setComplaint: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.COMPLAINT] = val,
    setAttachments: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS] = val,
    setHasTrafficWorkedBefore: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.HAS_TRAFFIC_WORKED_BEFORE] = val,
    setIsCausedByPorting: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_CAUSED_BY_PORTING] = val,
    setIsTrafficBetweenOperatorAffected: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_BETWEEN_OPERATOR_AFFECTED] = val,
    setIsTrafficForCustomerAffected: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_FOR_CUSTOMER_AFFECTED] = val,
    setIsTrafficTypeAffected: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_TYPE_AFFECTED] = val,
    setAffectedCustomer: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.AFFECTED_CUSTOMER] = val,
    setAffectedType: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.AFFECTED_TYPE] = val,
    setIsValid: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_VALID] = val,
    setIsLoading: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_LOADING] = val,
    setIsError: (state, val) => state[FORM_DESCRIPTION_PROPERTIES.IS_ERROR] = val
  },
  actions: {
    updateAdditionalInformation ({ commit }, val) {
      commit('setAdditionalInformation', val)
    },
    updateComplaint ({ commit }, val) {
      commit('setComplaint', val)
    },
    updateAttachments ({ commit }, val) {
      commit('setAttachments', val)
    },
    updateIsCausedByPorting ({ commit }, val) {
      commit('setIsCausedByPorting', val)
    },
    updateHasTrafficWorkedBefore ({ commit }, val) {
      commit('setHasTrafficWorkedBefore', val)
    },
    updateIsTrafficBetweenOperatorAffected ({ commit }, val) {
      commit('setIsTrafficBetweenOperatorAffected', val)
    },
    updateIsTrafficForCustomerAffected ({ commit }, val) {
      commit('setIsTrafficForCustomerAffected', val)
      commit('setAffectedCustomer', '')
    },
    updateIsTrafficTypeAffected ({ commit }, val) {
      commit('setIsTrafficTypeAffected', val)
      commit('setAffectedType', '')
    },
    updateAffectedCustomer ({ commit }, val) {
      commit('setAffectedCustomer', val)
    },
    updateAffectedType ({ commit }, val) {
      commit('setAffectedType', val)
    },
    updateIsValid ({ commit }, val) {
      commit('setIsValid', val)
    },
    resetState ({ commit }) {
      commit('setCaseId', '')
      commit('setAdditionalInformation', '')
      commit('setAttachments', [])
      commit('setComplaint', '')
      commit('setIsCausedByPorting', null)
      commit('setHasTrafficWorkedBefore', null)
      commit('setIsTrafficBetweenOperatorAffected', null)
      commit('setIsTrafficForCustomerAffected', null)
      commit('setIsTrafficTypeAffected', null)
      commit('setAffectedCustomer', '')
      commit('setAffectedType', '')
      commit('setIsValid', false)
      commit('setIsError', false)
    },
    async sendForm ({ state, commit }, baseInfo) {
      const request = {
        ...baseInfo,
        [FORM_DESCRIPTION_PROPERTIES.FORM_TYPE]: FORM_TYPE.INTERCONNECT,
        [FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO]: state[FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO],
        [FORM_DESCRIPTION_PROPERTIES.COMPLAINT]: state[FORM_DESCRIPTION_PROPERTIES.COMPLAINT],
        [FORM_DESCRIPTION_PROPERTIES.HAS_TRAFFIC_WORKED_BEFORE]: state[FORM_DESCRIPTION_PROPERTIES.HAS_TRAFFIC_WORKED_BEFORE],
        [FORM_DESCRIPTION_PROPERTIES.IS_CAUSED_BY_PORTING]: state[FORM_DESCRIPTION_PROPERTIES.IS_CAUSED_BY_PORTING],
        [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_BETWEEN_OPERATOR_AFFECTED]: state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_BETWEEN_OPERATOR_AFFECTED],
        ...!state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_BETWEEN_OPERATOR_AFFECTED] && {
          [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_FOR_CUSTOMER_AFFECTED]: state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_FOR_CUSTOMER_AFFECTED],
          ...state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_FOR_CUSTOMER_AFFECTED] && {
            [FORM_DESCRIPTION_PROPERTIES.AFFECTED_CUSTOMER]: state[FORM_DESCRIPTION_PROPERTIES.AFFECTED_CUSTOMER]
          },
          [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_TYPE_AFFECTED]: state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_TYPE_AFFECTED],
          ...state[FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_TYPE_AFFECTED] && {
            [FORM_DESCRIPTION_PROPERTIES.AFFECTED_TYPE]: state[FORM_DESCRIPTION_PROPERTIES.AFFECTED_TYPE]
          }
        },
        [FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]: state[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]
      }
      try {
        commit('setIsLoading', true)
        commit('setIsError', false)
        const response = await sendForm(request)

        commit('setCaseId', response.id)
      } catch (err) {
        commit('setIsError', true)
      } finally {
        commit('setIsLoading', false)
      }
    }
  }
}
