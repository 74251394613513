import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Products/ContentModule', ['category']),
    categoryComputed () {
      if (this.category) {
        return {
          title: this.category.title,
          path: this.category.path,
          hero: this.category.hero,
          products: this.category.products,
          pageIcons: this.category.pageIcons
        }
      }
      return null
    }
  }
}
