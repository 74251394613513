<template>
  <div class="products">
    <div class="container">
      <h2 class="products__heading">
        {{ heading }}
      </h2>

      <div
        v-if="items"
        class="products-row row">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="product-column col-xs-12 col-sm-6 col-lg-4">
          <Product
            :title="item.title"
            :description="item.description"
            :path="item.path" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from './Product'

export default {
  components: { Product },
  props: {
    heading: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import "tws-core-atoms/atoms/typography";

.products__heading {
  @extend .title-400;
  margin-bottom: 20px;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 35px;
  }
}

.products-row {
  margin: -10px;
}

.product-column {
  display: flex;
  padding: 10px;
}
</style>
