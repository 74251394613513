import Constants from './constants'

/*
 * Values that are sent to WoW system.
 * Although, looking similar to Swedish translations, there are some differences when sending case or product type
 * to WoW system and representing it on portal.
 */

const CASE_TYPE_TO_STRING = {
  [Constants.CASE_TYPES.GENERAL]: 'Generell fråga',
  [Constants.CASE_TYPES.ORDER]: 'Fråga om beställning',
  [Constants.CASE_TYPES.DELIVERY]: 'Fråga om leverans',
  [Constants.CASE_TYPES.ESCALATION]: 'Eskalering'
}

const PRODUCT_TO_STRING = {
  [Constants.PRODUCTS.TELIA_WHOLESALE_FIBER]: 'Telia Wholesale Fiber',
  [Constants.PRODUCTS.SKANOVA_FIBER_2009]: 'Skanova Fiber 2009',
  [Constants.PRODUCTS.SKANOVA_FIBER_SINGEL]: 'Skanova Fiber singel',
  [Constants.PRODUCTS.FIBER_VILLA]: 'Fiber villa',
  [Constants.PRODUCTS.FIBER_LAGENHET]: 'Fiber lägenhet',
  [Constants.PRODUCTS.FIBER_SMAFORETAG]: 'Fiber småföretag',
  [Constants.PRODUCTS.COM_OP]: 'ComOP',

  [Constants.PRODUCTS.TELIA_WHOLESALE_CAPACITY]: 'Telia Wholesale Capacity',
  [Constants.PRODUCTS.ETHERNET_SWEDEN]: 'Ethernet Sweden',
  [Constants.PRODUCTS.WAVELENGTH]: 'Wavelength',

  [Constants.PRODUCTS.KOPPARACCESS]: 'Skanova Kopparaccess',
  [Constants.PRODUCTS.TELEFONIABONNEMANG]: 'Skanova Telefoniabonnemang',
  [Constants.PRODUCTS.IP_STREAM_ENHANCED]: 'IP Stream Enhanced',

  [Constants.PRODUCTS.SAMLOKALISERING]: 'Skanova Samlokalisering',
  [Constants.PRODUCTS.INPLACERING]: 'Skanova Inplacering',
  [Constants.PRODUCTS.OPERATORSTRUNK]: 'Operatörstrunk',
  [Constants.PRODUCTS.INOM_NOD]: 'Telia Wholesale Inom Nod',

  [Constants.PRODUCTS.KUNDPORTALEN]: 'Kundportalen',

  [Constants.FAKE_PRODUCTS.OTHER_FIBER]: 'Telia Wholesale Fiber',
  [Constants.FAKE_PRODUCTS.OTHER_CAPACITY]: 'Ethernet Sweden',
  [Constants.FAKE_PRODUCTS.OTHER_COPPER]: 'Skanova Kopparaccess',
  [Constants.FAKE_PRODUCTS.OTHER_ESTABLISHMENT]: 'Skanova Inplacering',
  [Constants.FAKE_PRODUCTS.SUPPORT_FOR_CUSTOMER_PORTAL]: 'Kundportalen',
  [Constants.FAKE_PRODUCTS.SUPPORT_AFTER_WORK_HOURS]: 'Kundportalen'
}

export default {
  CASE_TYPE_TO_STRING,
  PRODUCT_TO_STRING
}
