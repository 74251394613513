import i18n from '@/i18n'
import ContentfulWrapper from '@/services/contentful/contentful.wrapper'

export const fetch = async () => {
  const content = await ContentfulWrapper.fetchEntries({
    content_type: 'publicLandingNewAssembly',
    locale: i18n.locale,
    include: 3
  })

  return content.items && content.items[0].fields
}

export default { fetch }
