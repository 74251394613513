<template>
  <div class="container">
    <div
      v-show="deletedUsernames.length > 0"
      class="row message">
      <div class="col">
        <InfraPageAlert
          type="success">
          {{ successfulDeletionMessage }}
        </InfraPageAlert>
      </div>
    </div>
    <div
      v-show="loaded && users.length === 0"
      class="row message">
      <div class="col">
        <InfraPageAlert
          type="info">
          {{ $t('mass-user-deletion.user-missing') }}
        </InfraPageAlert>
      </div>
    </div>
    <tws-mass-user-deletion-filters
      :loading="loading"
      :exception="loadingException"
      @search="search" />
    <tws-mass-user-deletion-list
      v-show="loaded && users.length > 0"
      :users="users"
      :deleting="deleting"
      :exception="deletionException"
      @deleteUsers="deleteSelectedUsers"
      @userSelected="selectUser" />
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import MassUserDeletionFilters from '@/views/mass-user-deletion/mass-user-deletion-filters/mass-user-deletion-filters.vue'
import MassUserDeletionList from '@/views/mass-user-deletion/mass-user-deletion-list/mass-user-deletion-list.vue'
import { InfraPageAlert } from 'tws-vue-components'

export default {
  components: {
    'tws-mass-user-deletion-filters': MassUserDeletionFilters,
    'tws-mass-user-deletion-list': MassUserDeletionList,
    InfraPageAlert
  },
  computed: {
    ...mapGetters({
      loading: 'massUserDeletion/search/isLoading',
      loaded: 'massUserDeletion/search/isLoaded',
      loadingException: 'massUserDeletion/search/isException',
      users: 'massUserDeletion/search/getUsers',
      deleting: 'massUserDeletion/deletion/isDeleting',
      deletedUsernames: 'massUserDeletion/deletion/getDeletedUsernames',
      deletionException: 'massUserDeletion/deletion/isException'
    }),
    successfulDeletionMessage () {
      if (this.deletedUsernames.length === 0) {
        return ''
      }

      return this.deletedUsernames.length > 1 ?
        this.$t('mass-user-deletion.users-removed', [this.deletedUsernames.length]) :
        this.$t('mass-user-deletion.user-removed', [this.deletedUsernames[0]])
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: 'massUserDeletion/search/fetchUsers',
      userSelected: 'massUserDeletion/search/userSelected',
      deleteUsers: 'massUserDeletion/deletion/deleteUsers'
    }),
    search (data) {
      const requestData = Object.assign({}, data)
      requestData.usernames = requestData.usernames ? requestData.usernames.trim().split(/[,;\r?\n\s]/) : null

      this.fetchUsers(requestData)
    },
    deleteSelectedUsers () {
      this.deleteUsers()
    },
    selectUser (users) {
      this.userSelected(users)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 44px;
}

.message {
  margin-bottom: 46px;
}
</style>
