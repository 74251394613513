<template>
  <tws-mobile-menu-item :item="item">
    <div
      v-if="item.expandable"
      v-show="item.expanded"
      class="sub-menu-container">
      <tws-mobile-sub-menu-item
        v-for="(subMenuItem, index) in subMenuItems"
        :key="index"
        :item="subMenuItem">
        <div
          v-show="subMenuItem.expanded"
          class="sub-menu-container">
          <tws-mobile-sub-menu-item-2
            v-for="(subMenuItemCategory, index2) in subMenuItem.categories"
            :key="index2"
            :item="subMenuItemCategory" />
        </div>
      </tws-mobile-sub-menu-item>
    </div>
  </tws-mobile-menu-item>
</template>

<script>
import Item from '../mobile-menu-item/mobile-menu-item'
import SubItem from '../mobile-sub-menu-item/mobile-sub-menu-item'
import SubItem2 from '../mobile-sub-menu-item-2/mobile-sub-menu-item-2'

export default {
  components: {
    'tws-mobile-menu-item': Item,
    'tws-mobile-sub-menu-item': SubItem,
    'tws-mobile-sub-menu-item-2': SubItem2
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    subMenuItems: {
      type: Array,
      default: null
    }
  }
}
</script>
