<template>
  <div class="list-wrapper">
    <div class="row">
      <div class="col">
        <tws-table
          class="user-list-table"
          :items="users">
          <template #thead>
            <tr>
              <th class="checkbox-column">
                <input
                  id="toggle-all-users"
                  v-model="allUsersSelected"
                  class="input-checkbox"
                  type="checkbox"
                  @change="toggleAllUsers">
                <label
                  for="toggle-all-users"
                  class="label" />
              </th>

              <th class="medium-column">
                {{ $t('mass-user-deletion.table-headers.username') }}
              </th>
              <th class="small-column">
                {{ $t('mass-user-deletion.table-headers.name') }}
              </th>
              <th class="small-column">
                {{ $t('mass-user-deletion.table-headers.status') }}
              </th>
              <th class="small-column">
                {{ $t('mass-user-deletion.table-headers.valid') }}
              </th>
              <th class="extra-large-column">
                {{ $t('mass-user-deletion.table-headers.business') }}
              </th>
              <th class="large-column">
                {{ $t('mass-user-deletion.table-headers.latest-log-in') }}
              </th>
            </tr>
          </template>
          <template #tr="{ item, index }">
            <td>
              <input
                :id="'user-' + index"
                v-model="selectedUsers"
                class="input-checkbox"
                type="checkbox"
                :value="item">
              <label
                :for="'user-' + index"
                class="label" />
            </td>
            <td>{{ item.username }}</td>
            <td>{{ item.firstName + " " + item.lastName }}</td>
            <td>{{ $t(accountStatusText[item.accountStatus]) }}</td>
            <td>{{ item.expirationDate }}</td>
            <td>{{ item.company ? item.company.name : "" }}</td>
            <td>{{ item.lastLoginDate }}</td>
          </template>
        </tws-table>
      </div>
    </div>
    <div class="row message-row">
      <div class="col">
        <tws-button-primary
          :disabled="deleting || selectedUsers.length === 0"
          @click="toggleDeletionDialog">
          {{ $t('mass-user-deletion.delete-selected') }}
        </tws-button-primary>
      </div>
    </div>
    <div
      v-show="exception"
      class="row message-row">
      <div class="col">
        <InfraPageAlert
          type="danger">
          {{ $t('mass-user-deletion.errors.default') }}
        </InfraPageAlert>
      </div>
    </div>
    <div
      v-show="deleting"
      class="row message-row">
      <div class="col">
        {{ $t('mass-user-deletion.deleting') }}
      </div>
    </div>
    <confirmation-dialog
      v-show="showDeletionDialog"
      :heading="$t('mass-user-deletion.delete-user')"
      :confirmation-button-text="$t('mass-user-deletion.delete')"
      :cancel-button-text="$t('common.cancel')"
      @confirm="onUserDeletionConfirm"
      @cancel="toggleDeletionDialog">
      <template #body>
        {{ deletionDialogMessage }}
      </template>
    </confirmation-dialog>
  </div>
</template>

<script>
import { ACCOUNT_STATUS_TEXT } from '@/constants/account-status.js'
import ConfirmationDialog from '@/components/common/confirmation-dialog.vue'

import {
  TwsButtonPrimary,
  InfraPageAlert,
  TwsTable
} from 'tws-vue-components'

export default {
  components: {
    TwsButtonPrimary,
    InfraPageAlert,
    TwsTable,
    ConfirmationDialog
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    deleting: {
      type: Boolean,
      required: true
    },
    exception: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      accountStatusText: ACCOUNT_STATUS_TEXT,
      showDeletionDialog: false,
      allUsersSelected: false
    }
  },
  computed: {
    selectedUsers: {
      get () {
        return this.$store.getters['massUserDeletion/search/getSelectedUsers']
      },
      set (value) {
        this.$store.dispatch('massUserDeletion/search/userSelected', value)
      }
    },
    deletionDialogMessage () {
      if (this.selectedUsers.length === 0) {
        return ''
      }

      return this.$t('mass-user-deletion.confirmation', [this.selectedUsers.length > 1 ? `(${this.selectedUsers.length})` : this.selectedUsers[0].username])
    }
  },
  methods: {
    toggleAllUsers () {
      const users = this.allUsersSelected ? this.users : []
      this.$store.dispatch('massUserDeletion/search/userSelected', users)
    },
    toggleDeletionDialog () {
      this.showDeletionDialog = !this.showDeletionDialog
    },
    onUserDeletionConfirm () {
      this.$emit('deleteUsers')
      this.toggleDeletionDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.list-wrapper {
  margin-top: 76px;
  margin-bottom: 135px;
}

.message-row {
  margin-top: 57px;
}

.user-list-table {
  table-layout: fixed;
}

.checkbox-column {
  width: 50px;
}

.small-column {
  width: 10%;
  word-wrap: break-word;
}

.medium-column {
  width: 15%;
  word-wrap: break-word;
}

.large-column {
  width: 20%;
  word-wrap: break-word;
}

.extra-large-column {
  width: 35%;
  word-wrap: break-word;
}
</style>
