<template>
  <div class="chat-container container">
    <div class="row">
      <div class="col-12">
        <div
          v-if="!isBrowserSupported()"
          class="chat-container__unsupported">
          {{ $t('sub-categories.chat.unsupported') }}
        </div>

        <h2 class="chat-container__heading heading-m">
          {{ subCategory.fields.heading }}
        </h2>
      </div>
    </div>

    <div class="chats-row row">
      <Chat
        v-for="(item, index) in subCategory.fields.items"
        :key="index"
        :title="item.fields.title"
        :link="item.fields.internalLink" />
    </div>
  </div>
</template>

<script>
import { detect } from 'detect-browser'

import { Chat } from '../components/'

export default {
  components: { Chat },
  props: {
    subCategory: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      browser: detect()
    }
  },
  mounted () {
    window.dispatchEvent(new Event('tws-chat-container-open'))
  },
  destroyed () {
    window.dispatchEvent(new Event('tws-chat-container-close'))
  },
  methods: {
    isBrowserSupported () {
      return !(this.browser.name === 'edge' && this.browser.version === '18.17763.0')
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-container__heading {
  margin-bottom: 25px;
}

.chats-row {
  margin-top: -20px;
  margin-bottom: -20px;
}
</style>
