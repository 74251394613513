export const sortObjects = (a, b, field) => {
  const x = a[field]
  const y = b[field]

  if (x > y) {
    return 1
  }
  if (x < y) {
    return -1
  }
  return 0
}
