<template>
  <div>
    <pebble-background-container :grey-background="true">
      <template #breadcrumb>
        <InfraBreadcrumbContainer
          :items="breadcrumbItems"
          :grey-background="false">
          <user-administration-banner
            :help-dialog-header="content.helpDialogHeader"
            :help-dialog-message="content.helpDialogMessage" />
        </InfraBreadcrumbContainer>
      </template>
    </pebble-background-container>

    <user-administration-list />
  </div>
</template>

<script>
import PebbleBackgroundContainer from '@/components/common/pebble-background-container.vue'
import UserAdministrationBanner from './user-administration-banner/user-administration-banner.vue'
import UserAdministrationList from './user-administration-list/user-administration-list.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_USER_ADMINISTRATION } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    PebbleBackgroundContainer,
    UserAdministrationBanner,
    UserAdministrationList,
    InfraBreadcrumbContainer
  },
  data () {
    return {
      content: {
        helpDialogHeader: this.$t('user-administration.header'),
        helpDialogMessage: this.$t('user-administration.description')
      },
      breadcrumbItems: BREADCRUMBS_USER_ADMINISTRATION
    }
  }
}
</script>
