<template>
  <div class="row">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <h2>
            {{ $t('fault-reporting.summary.basic-info.description') }}
          </h2>
        </div>
      </div>

      <SummaryField
        :value="customerRefNumber"
        :label="$t('fault-reporting.form.basic-info.customer-ref-number.label')" />

      <SummaryField
        :value="faultCategory.label"
        :label="$t('fault-reporting.form.basic-info.category.label')" />

      <SummaryField
        v-if="problemType"
        :value="problemType.label"
        :label="$t('fault-reporting.form.basic-info.problem-type')" />

      <div class="row mt-5">
        <div class="col-12">
          <h2>{{ $t('fault-reporting.summary.description.description') }}</h2>
        </div>
      </div>

      <div
        v-for="(key, index) in summaryStateKeys"
        :key="index">
        <SummaryField
          v-if="isSummaryFieldShown(key)"
          :value="isStateExamplesShown(key) ? '' : getFieldValue(key)"
          :label="getFieldLabel(key)">
          <template v-if="isAttachmentsShown(key)">
            <div
              v-for="(attachment, attachmentIndex) in attachments"
              :key="attachmentIndex"
              class="attachment">
              <span class="attachment__name">{{ attachment.name }}</span><span class="attachment__size">{{ attachment.size | convertFileSize }}</span>
            </div>
          </template>

          <template v-else-if="isStateExamplesShown(key)">
            <div
              v-for="(example, exampleIndex) in stateExamples"
              :key="exampleIndex"
              class="example">
              <span class="example__date">{{ example.date }}</span>
              <p class="example__description">
                {{ example.description }}
              </p>
            </div>
          </template>
        </SummaryField>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLogicalValue, convertFileSize
} from '@/apps/fault-reporting/utils/string.utils'
import SummaryField from '@/apps/fault-reporting/components/report-summary/SummaryField'
import {
  FORM_DESCRIPTION_INPUT_LABELS,
  FORM_DESCRIPTION_PROPERTIES
} from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  filters: {
    convertFileSize
  },
  components: {
    SummaryField
  },
  props: {
    summaryState: {
      type: Object,
      required: true
    },
    customerRefNumber: {
      type: String,
      required: true
    },
    faultCategory: {
      type: Object,
      required: true
    },
    problemType: {
      type: [
        Object,
        String
      ],
      required: false,
      default: null
    }
  },
  computed: {
    summaryStateKeys () {
      return Object.keys(this.summaryState)
    },
    attachments () {
      return this.summaryState[FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS]
    },
    stateExamples () {
      return this.summaryState[FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]
    }
  },
  methods: {
    getLogicalValue,
    getFieldLabel (key) {
      return FORM_DESCRIPTION_INPUT_LABELS[key]
    },
    isSummaryFieldShown (key) {
      const value = this.summaryState[key]
      return this.getFieldLabel(key) && value !== null && (this.isBoolean(value) || value.length)
    },
    getFieldValue (key) {
      const val = this.summaryState[key]

      if (this.isBoolean(val)) {
        return getLogicalValue(val)
      }

      return val
    },
    isBoolean (val) {
      return typeof val === 'boolean'
    },
    isAttachmentsShown (key) {
      return (key === FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO || key === FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION || key === FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION) && this.attachments.length
    },
    isStateExamplesShown (key) {
      return key === FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM
    }
  }
}
</script>
<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.attachment {
  &__name {
    color: $core-purple;
  }

  &__size {
    margin-left: 20px;
    color: $dark-grey;
  }
}

.example {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  &__description {
    margin: 0px;
  }
}
</style>
