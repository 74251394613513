import DeliveryTimeView from '@/views/network-information/delivery-time/DeliveryTimeView'
import OtherNewsView from '@/views/network-information/other-news/OtherNewsView'

export default {
  path: 'natinformation',
  component: {
    template: '<router-view />'
  },
  redirect: {
    name: 'network-information'
  },
  children: [
    {
      path: 'natforandringar',
      name: 'network-information',
      component: OtherNewsView
    },
    {
      path: 'Leveranstider-Fiber',
      name: 'delivery-time',
      component: DeliveryTimeView
    }
  ]
}
