export const transformAccessToTransport = (raw, node) => {
  if (raw.pointA || raw.siteA) { return raw }

  const freeSingleFiberAtAccessPoint = raw.accessPoint?.freeSingleFiber || 0
  const freePairAtAccessPoint = raw.accessPoint?.freePair || 0

  return {
    pointA: raw,
    siteB: raw[node],
    validConnection: true,
    transportWithinNodesArea: true,
    deliveryTimeFast: raw.deliveryTimeFast,
    deliveryTimeNormal: raw.deliveryTimeNormal,
    freeFiber: node === 'aNode' ? freeSingleFiberAtAccessPoint : raw.freeSingleFiberAnodeToOnode,
    freePair: node === 'aNode' ? freePairAtAccessPoint : raw.freePairAnodeToOnode,
    fixedPrice: raw.fixedPriceAddress,
    offers: [
      null,
      null,
      null
    ]
  }
}

const SINGLE_FIBER_PROPERTIES = [
  'freeFiber',
  'freeSingleFiberAnodeToOnode',
  'freeSingleFiber'
]

const FIBER_PAIR_PROPERTIES = [
  'freePair',
  'freePairAnodeToOnode'
]

const getFreeSingleFibers = node => {
  // eslint-disable-next-line no-prototype-builtins
  const key = SINGLE_FIBER_PROPERTIES.find(k => node.hasOwnProperty(k))
  return node[key]
}

const getFreePairs = node => {
  // eslint-disable-next-line no-prototype-builtins
  const key = FIBER_PAIR_PROPERTIES.find(k => node.hasOwnProperty(k))
  return node[key]
}

export function WholesaleFiberProductData (input, node) {
  const raw = transformAccessToTransport(input, node)

  const isAddressAddress = () => raw.pointA && raw.pointB
  const isNodeNode = () => raw.siteA && raw.siteB

  const areAllAccessPointsAvailable = () => {
    return raw.pointA.accessPoint && raw.pointB.accessPoint
  }

  const pointABelongingNodeIsSiteB = () => {
    const belongingNode = raw.pointA?.aNode || raw.pointA?.oNode

    return belongingNode && belongingNode.siteId === raw.siteB?.siteId
  }

  const getAvailableCapacityAsList = getter => [
    ...raw.pointA ? [raw.pointA.accessPoint] : [],
    ...pointABelongingNodeIsSiteB() ? [] : [raw],
    ...raw.pointB ? [raw.pointB.accessPoint] : []
  ].map(getter)

  const getTotalCapacity = getter => {
    return Math.min(...getAvailableCapacityAsList(getter))
  }

  const bothAddressesBelongToTheSameNode = () => {
    if (!raw.pointB) {
      return false
    }

    const aNodeSame = raw.pointA.aNode?.siteId === raw.pointB.aNode?.siteId && raw.pointA.aNode
    const oNodeSame = raw.pointA.oNode?.siteId === raw.pointB.oNode?.siteId && raw.pointA.oNode

    return aNodeSame || oNodeSame
  }

  const addressesBelongToTheSameAreaNode = () => {
    return raw.pointA?.nodesAreaNodes?.find(node => node.fqn === raw.pointB?.oNode?.fqn)
      || raw.pointB?.nodesAreaNodes?.find(node => node.fqn === raw.pointA?.oNode?.fqn)
  }

  const pointAConnectedToSiteB = () => {
    if (!raw.pointA.oNode && !raw.pointA.aNode || !raw.siteB) { return false }

    return raw.pointA.oNode?.siteId === raw.siteB.siteId || raw.pointA.aNode?.siteId === raw.siteB.siteId
  }

  const getSingleFiberCapacity = () => {
    if (isAddressAddress()) {
      return areAllAccessPointsAvailable() ? getTotalCapacity(getFreeSingleFibers) : 0
    }

    if (isNodeNode()) {
      return getTotalCapacity(getFreeSingleFibers)
    }

    return raw.pointA.accessPoint ? getTotalCapacity(getFreeSingleFibers) : 0
  }

  const getFiberPairCapacity = () => {
    if (isAddressAddress()) {
      return areAllAccessPointsAvailable() ? getTotalCapacity(getFreePairs) : 0
    }

    if (isNodeNode()) {
      return getTotalCapacity(getFreePairs)
    }

    return raw.pointA.accessPoint ? getTotalCapacity(getFreePairs) : 0
  }

  const isFixedPriceArea = () => {
    if (isAddressAddress()) {
      return raw.fixedPrice && raw.pointA.fixedPriceAddress && raw.pointB.fixedPriceAddress
    }

    if (isNodeNode()) {
      return raw.fixedPrice
    }

    return raw.fixedPrice && raw.pointA.fixedPriceAddress
  }

  const isTransportWithinArea = () => raw.transportWithinNodesArea

  const getOffers = () => {
    if (isNodeNode()) {
      return raw.offers
    }

    if (isAddressAddress()) {
      if (bothAddressesBelongToTheSameNode() || addressesBelongToTheSameAreaNode()) {
        return raw.pointA.offers.filter(offer => !offer.product.toLowerCase().includes('single'))
      }
    }

    if (pointAConnectedToSiteB()) {
      return raw.pointA.offers
    }

    return raw.offers
  }

  const getDeliveryTimeNormal = () => raw.deliveryTimeNormal
  const getDeliveryTimeFast = () => raw.deliveryTimeFast

  return {
    getSingleFiberCapacity,
    getFiberPairCapacity,
    isFixedPriceArea,
    isTransportWithinArea,
    getOffers,
    getDeliveryTimeNormal,
    getDeliveryTimeFast,
    pointA: raw.pointA,
    pointB: raw.pointB,
    siteA: raw.siteA,
    siteB: raw.siteB
  }
}
