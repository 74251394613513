<template>
  <CommonPageView
    content-wrapper-class=""
    :breadcrumb-items="breadcrumbItems"
    :loading="false"
    :use-infra-generic-banner="false"
    :banner="banner">
    <template #content>
      <div class="form-container">
        <component
          :is="form" />
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import Vue from 'vue'
import CommonPageView from '@/views/CommonPageView.vue'

import {
  Alert,
  CheckboxGroup,
  Checkbox,
  Column,
  Dropdown,
  Form,
  Heading,
  Image,
  InputField,
  Paragraph,
  RadioButtonGroup,
  RichText,
  Row,
  TextArea,
  TextBox
} from '@/apps/dynamic-form/components/'
import i18n from '@/i18n'

Vue.component('TwsForm', Form)
Vue.component('TwsFormRow', Row)
Vue.component('TwsFormColumn', Column)
Vue.component('TwsFormInputField', InputField)
Vue.component('TwsFormRadioGroup', RadioButtonGroup)
Vue.component('TwsFormTextArea', TextArea)
Vue.component('TwsFormHeading', Heading)
Vue.component('TwsFormTextBox', TextBox)
Vue.component('TwsFormDropdown', Dropdown)
Vue.component('TwsFormCheckbox', Checkbox)
Vue.component('TwsFormCheckboxGroup', CheckboxGroup)
Vue.component('TwsFormParagraph', Paragraph)
Vue.component('TwsFormImage', Image)
Vue.component('TwsRichText', RichText)
Vue.component('TwsFormAlert', Alert)

export default {
  components: {
    CommonPageView
  },
  props: {
    banner: {
      type: Object,
      required: true
    },
    breadcrumbItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    form () {
      return {
        template: require(`./forms/${this.$route.name}-${i18n.locale}.html`).default
      }
    }
  }
}
</script>
