<template>
  <div class="navigation-container">
    <div class="container">
      <div class="row">
        <div
          v-for="index in 4"
          :key="index"
          class="navigation-link-column col-lg-3 col-sm-6 col-xs-12">
          <InfraActionLinkPlaceholder />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfraActionLinkPlaceholder } from 'tws-vue-components'

export default {
  components: { InfraActionLinkPlaceholder }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.navigation-container {
  padding-top: 30px;
  padding-bottom: 30px;
}

.row {
  margin: -0.5px;
}

.navigation-link-column {
  padding: 0.5px;
}
</style>
