<template>
  <purpur-cta-link
    :variant="variant"
    :size="size"
    :href="href">
    <slot />
  </purpur-cta-link>
</template>

<script>
import { CtaLink } from '@purpurds/purpur'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  components: {
    'purpur-cta-link': applyReactInVueWithOptions(CtaLink)
  },
  props: {
    variant: {
      type: String,
      default: 'primary-negative'
    },
    size: {
      type: String,
      default: 'md'
    },
    href: {
      type: String,
      default: ''
    }
  }
}
</script>
