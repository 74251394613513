import { getFiberProducts } from '@/apps/address-search/services/products.service'

const POINT_KEYS = [
  'productAddressA',
  'productAddressB',
  'productNodeA',
  'productNodeB'
]

const clearOtherPoints = (correctPointKeys, commit) => {
  POINT_KEYS
    .filter(key => !correctPointKeys.includes(key))
    .forEach(key => commit(key, null))
}

const buildProductFetcher = (config) => async ({ commit }, query) => {
  const { pointKeyA, pointKeyB, fetcher } = config

  commit('loaded', false)
  commit('loading', true)
  commit('exception', false)

  try {
    const products = await fetcher(query)
    commit(pointKeyA, query[0])
    commit(pointKeyB, query[1])
    clearOtherPoints([
      pointKeyA,
      pointKeyB
    ], commit)
    commit('productData', products)
    commit('loaded', true)
  } catch (err) {
    commit('loaded', false)
    if (err.response && err.response.status === 400) {
      commit('AddressSearch/AddressSearch/exception', err.response.data.errors, { root: true })
    } else {
      commit('exception', true)
    }
  } finally {
    commit('loading', false)
  }
}

export default {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    exception: false,
    productAddressA: null,
    productAddressB: null,
    productNodeA: null,
    productNodeB: null,
    productData: null
  },
  mutations: {
    loading: (state, loading) => { state.loading = loading },
    loaded: (state, loaded) => { state.loaded = loaded },
    exception: (state, exception) => { state.exception = exception },
    productAddressA: (state, productAddressA) => { state.productAddressA = productAddressA },
    productAddressB: (state, productAddressB) => { state.productAddressB = productAddressB },
    productNodeA: (state, productNodeA) => { state.productNodeA = productNodeA },
    productNodeB: (state, productNodeB) => { state.productNodeB = productNodeB },
    productData: (state, productData) => { state.productData = productData }
  },
  getters: {
    loading: state => state.loading,
    loaded: state => state.loaded,
    exception: state => state.exception,
    productAddressA: state => state.productAddressA,
    productAddressB: state => state.productAddressB,
    productNodeA: state => state.productNodeA,
    productNodeB: state => state.productNodeB,
    productData: state => state.productData,
    fiber2009Available: ({ productData }) => productData
      && productData.fiber2009Access
      && (productData.fiber2009Access.deliverability || productData.fiber2009Access.investigationRequired),
    wholesaleFiberAvailable: state => state.productData && state.productData.wholesaleFiberAccess,
    wholesaleFiberOutsideAvailable: state => state.productData && state.productData.wholesaleFiberAccessOutside && state.productData.wholesaleFiberAccessOutside.exists
  },
  actions: {
    fetchFiberProductsAddressAddress: buildProductFetcher({
      pointKeyA: 'productAddressA',
      pointKeyB: 'productAddressB',
      fetcher: getFiberProducts.addressAddress
    }),
    fetchFiberProductsAddressNode: buildProductFetcher({
      pointKeyA: 'productAddressA',
      pointKeyB: 'productNodeB',
      fetcher: getFiberProducts.addressStation
    }),
    fetchFiberProductsNodeNode: buildProductFetcher({
      pointKeyA: 'productNodeA',
      pointKeyB: 'productNodeB',
      fetcher: getFiberProducts.stationStation
    }),
    resetState: ({ commit }) => {
      commit('loading', false)
      commit('loaded', false)
      commit('exception', false)
      commit('productAddressA', null)
      commit('productAddressB', null)
      commit('productNodeA', null)
      commit('productNodeB', null)
      commit('productData', null)
    }
  }
}
