<template>
  <CommonPageView
    class="tws-asset-search"
    :breadcrumb-items="breadcrumbItems"
    :loading="false"
    :banner="banner">
    <template #content>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <tws-phone-form
              :phone="phone"
              :text="formText"
              :validator="$v.phone"
              @phoneChanged="onPhoneChange"
              @enterKeyPressed="onSubmit" />
          </div>
        </div>

        <tws-button-primary
          @click="onSubmit">
          {{ $t('common.search') }}
        </tws-button-primary>

        <div class="results mt-5">
          <div v-show="isLoading">
            {{ $t('common.loading') }}
          </div>

          <div
            v-show="!isLoading && isLoaded && !(noProducts || errorFetchingData)"
            class="row">
            <div class="col-12">
              <tws-result
                :assets="assets"
                :header="resultHeader" />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div
                v-if="noProducts">
                <InfraPageAlert
                  type="warning">
                  {{ messages.noProducts }}
                </InfraPageAlert>
              </div>

              <div v-if="errorFetchingData">
                <InfraPageAlert
                  type="danger">
                  {{ messages.error }}
                </InfraPageAlert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import {
  maxLength,
  minLength,
  numeric,
  required
} from 'vuelidate/lib/validators'

import { validationMixin } from 'vuelidate'

import Form from '@/views/static/search-tools/phone-search/PhoneSearchForm'
import Result from '@/views/static/search-tools/phone-search/PhoneSearchResult'
import CommonPageView from '@/views/CommonPageView'

import {
  TwsButtonPrimary,
  InfraPageAlert
} from 'tws-vue-components'
import i18n from '@/i18n'
import { BREADCRUMBS_PHONE_SEARCH } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    CommonPageView,
    'tws-phone-form': Form,
    'tws-result': Result,
    TwsButtonPrimary,
    InfraPageAlert
  },
  mixins: [validationMixin],
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_PHONE_SEARCH,
      banner: {
        heading: this.$t('search-categories.phone')
      },
      messages: {
        noProducts: i18n.t('phone-search.messages.no-products'),
        error: i18n.t('phone-search.messages.error')
      },
      formText: i18n.t('phone-search.search-for-copper-numbers'),
      resultHeader: '',
      phone: {
        cityCodeField: this.$route.query.prefix ? this.$route.query.prefix : '',
        numberField: this.$route.query.number ? this.$route.query.number : ''
      }
    }
  },
  computed: {
    assets () {
      return this.$store.getters['phoneSearch/getAssetDetails']
    },
    isLoaded () {
      return this.$store.getters['phoneSearch/isLoaded']
    },
    isLoading () {
      return this.$store.getters['phoneSearch/isLoading']
    },
    isException () {
      return this.$store.getters['assetSearch/isException']
    },
    noProducts () {
      return !this.dataValuesNotEmpty(this.assets) &&
        this.isLoaded &&
        !this.isException &&
        !this.isLoading
    },
    errorFetchingData () {
      return !this.isLoading && this.isException
    }
  },
  validations: {
    phone: {
      cityCodeField: {
        required,
        numeric,
        minLengthCityCode: minLength(2),
        maxLengthCityCode: maxLength(5)
      },
      numberField: {
        required,
        numeric,
        minLengthNumber: minLength(4),
        maxLengthNumber: maxLength(15)
      }
    }
  },
  mounted () {
    if (this.phone.cityCodeField.length && this.phone.numberField.length) {
      this.onSubmit()
    }
  },
  methods: {
    dataValuesNotEmpty (data) {
      return data !== null && data !== undefined && Object.values(data).filter(s => s !== '').length !== 0
    },
    onPhoneChange (phone) {
      this.phone = Object.assign({}, phone)
    },
    onSubmit () {
      this.$v.phone.$touch()
      if (this.$v.phone.$invalid) {
        return
      }

      this.resultHeader = this.phone.cityCodeField + '-' + this.phone.numberField
      this.$store.dispatch('phoneSearch/loadAssets', {
        cityCode: this.phone.cityCodeField,
        number: this.phone.numberField
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    margin-top: 40px;
  }
  .results {
    padding-bottom: 50px;
  }
</style>
