<template>
  <div class="product-view-placeholder">
    <BannerPlaceholder />

    <div class="product-content">
      <InfraPageIconsPlaceholder />

      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-12">
            <DescriptionPlaceholder />
          </div>

          <div class="col-md-4 col-sm-12 offset-md-1">
            <RelatedProductsPlaceholder />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfraPageIconsPlaceholder } from 'tws-vue-components'

import BannerPlaceholder from './BannerPlaceholder'
import DescriptionPlaceholder from './DescriptionPlaceholder'

import RelatedProductsPlaceholder from './related-products/RelatedProductsPlaceholder'

export default {
  components: {
    InfraPageIconsPlaceholder,
    BannerPlaceholder,
    DescriptionPlaceholder,
    RelatedProductsPlaceholder
  }
}
</script>

<style lang="scss" scoped>
.infra-page-icons-placeholder {
  margin-bottom: 40px;
}

.product-content {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>
