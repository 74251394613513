<template>
  <div class="row mt-4">
    <div class="col-xl-5 col-sm-5 col-xs-12">
      <b class="subsection-100">{{ label }}</b>
    </div>
    <div class="col-xl-7 col-sm-7 col-xs-12">
      <div> {{ value }} </div>
      <slot />
    </div>
  </div>
</template>
<script>

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
}
</script>
