import InvoicesService from '@/services/invoices.service'

export const SORTING_KEY = {
  DATE_ASC: 'DATE_ASC',
  DATE_DESC: 'DATE_DESC'
}

const sortByDateAsc = (a, b) => {
  if (a.invoiceDate > b.invoiceDate) { return 1 }
  if (a.invoiceDate < b.invoiceDate) { return -1 }
  if (a.invoiceNumber > b.invoiceNumber) { return 1 }
  if (a.invoiceNumber < b.invoiceNumber) { return -1 }
  return 0
}

const sortByDateDesc = (a, b) => {
  if (a.invoiceDate > b.invoiceDate) { return -1 }
  if (a.invoiceDate < b.invoiceDate) { return 1 }
  if (a.invoiceNumber > b.invoiceNumber) { return -1 }
  if (a.invoiceNumber < b.invoiceNumber) { return 1 }
  return 0
}

const sorterMap = {
  [SORTING_KEY.DATE_DESC]: sortByDateDesc,
  [SORTING_KEY.DATE_ASC]: sortByDateAsc
}

const buildTypeFilter = specificationList => {
  return specificationList.reduce((filterSet, item) => {
    filterSet[item.type] = true
    return filterSet
  }, {})
}

export const InvoiceSpecificationsModule = {
  namespaced: true,
  state: {
    items: [],
    loading: false,
    loaded: false,
    exception: false,

    sortingKey: SORTING_KEY.DATE_DESC,
    typeFilter: null,
    searchTerm: '',
    limit: 10,
    offset: 0
  },
  mutations: {
    setItems (state, items) {
      state.items = items
      state.items.sort(sorterMap[state.sortingKey])
    },
    setSorting (state, sortingKey) {
      state.sortingKey = sortingKey
      state.items.sort(sorterMap[state.sortingKey])
      state.offset = 0
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    },
    setOffset (state, offset) {
      state.offset = offset
    },
    setSearchTerm (state, searchTerm) {
      state.searchTerm = searchTerm
      state.offset = 0
    },
    setTypeFilter (state, typeFilter) {
      state.typeFilter = typeFilter
      state.offset = 0
    }
  },
  getters: {
    filteredItems: (state) => {
      const normalizedTerm = (state.searchTerm || '').toLowerCase()
      const { typeFilter } = state

      return state.items.filter(item => (!typeFilter || typeFilter[item.type]) && (
        !normalizedTerm
        || item.id.toLowerCase().includes(normalizedTerm)
        || item.invoiceDate.toLowerCase().includes(normalizedTerm)
        || item.invoicePeriodStart.toLowerCase().includes(normalizedTerm)
        || item.invoicePeriodEnd.toLowerCase().includes(normalizedTerm)
        || item.invoiceNumber.toLowerCase().includes(normalizedTerm)
      ))
    },
    availableInvoiceTypes: state => buildTypeFilter(state.items)
  },
  actions: {
    async loadItems ({ commit, rootGetters, rootState }) {
      commit('setLoading', true)
      commit('setLoaded', false)
      commit('setException', false)

      try {
        const superuser = rootGetters['eamCookies/isSuperUser']
        const admin = rootGetters['eamCookies/isAdmin']
        const billingAdmin = rootGetters['eamCookies/isBillingAdmin']

        const company = rootState.MyDocuments.company

        let data
        if ((superuser || admin || billingAdmin) && company) {
          data = await InvoicesService.getSpecificationsForCompany(company.code)
        } else {
          data = await InvoicesService.getSpecifications()
        }

        const { specifications } = data

        commit('setItems', specifications)
        commit('setTypeFilter', buildTypeFilter(specifications))
        commit('setLoaded', true)
      } catch (err) {
        if (!err.response || err.response.status !== 400) {
          commit('setException', true)
        }
      } finally {
        commit('setLoading', false)
      }
    },
    changeOffset ({ commit }, newOffset) {
      commit('setOffset', newOffset)
    },
    changeSearchTerm ({ commit }, newSearchTerm) {
      commit('setSearchTerm', newSearchTerm)
    },
    changeTypeFilter ({ commit, state }, newFilters) {
      if (newFilters === null) {
        commit('setTypeFilter', null)
        return
      }

      commit('setTypeFilter', {
        ...state.typeFilter,
        ...newFilters
      })
    },
    changeSorting ({ commit }, newSortingKey) {
      commit('setSorting', newSortingKey)
    }
  }
}
