import { render, staticRenderFns } from "./ProductCardContainer.vue?vue&type=template&id=3636255b&scoped=true"
import script from "./ProductCardContainer.vue?vue&type=script&lang=js"
export * from "./ProductCardContainer.vue?vue&type=script&lang=js"
import style0 from "./ProductCardContainer.vue?vue&type=style&index=0&id=3636255b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3636255b",
  null
  
)

export default component.exports