<template>
  <div class="product">
    <h2 class="product__title">
      {{ title }}
    </h2>

    <div class="product__description">
      {{ description }}
    </div>

    <div>
      <TwsLinkButton
        :href="fullPath"
        class="product__link"
        router-link>
        {{ $t('common.read-more') }}
      </TwsLinkButton>
    </div>
  </div>
</template>

<script>
import { TwsLinkButton } from 'tws-vue-components'

export default {
  components: { TwsLinkButton },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  computed: {
    fullPath () {
      return `/products/${this.$route.params.category}/${this.path}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import "tws-core-atoms/atoms/typography";

.product {
  flex: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $medium-grey;

  &__title {
    @extend .title-200;
    word-break: break-word;
  }

  &__description {
    flex-grow: 1;
    margin-bottom: 30px;
  }

  &__link {
    padding: 16px 25px;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
