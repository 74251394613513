import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_PRODUCT_SEARCH_URL)

const AssetSearchService = {
  getAssets: async number => {
    return await api.get('/asset/search',
      {
        params: {
          'number': 'FB-' + number
        }
      })
  }
}

export default AssetSearchService
