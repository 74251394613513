export default {
  namespaced: true,
  state: {
    customerRefNumber: '',
    faultCategory: '',
    problemType: '',
    isValid: false
  },
  mutations: {
    setCustomerRefNumber: (state, val) => state.customerRefNumber = val,
    setFaultCategory: (state, val) => state.faultCategory = val,
    setProblemType: (state, val) => state.problemType = val,
    setIsValid: (state, val) => state.isValid = val
  },
  actions: {
    updateCustomerRefNumber ({ commit }, val) {
      commit('setCustomerRefNumber', val)
    },
    updateFaultCategory ({ commit }, val) {
      commit('setFaultCategory', val)
    },
    updateProblemType ({ commit }, val) {
      commit('setProblemType', val)
    },
    updateIsValid ({ commit }, val) {
      commit('setIsValid', val)
    },
    resetState ({ commit }) {
      commit('setCustomerRefNumber', '')
      commit('setFaultCategory', '')
      commit('setIsValid', false)
    }
  }
}
