<template>
  <div class="tabs-container">
    <div class="active-tab">
      <tws-nav-link
        :key="tabs[activeTabIndex].title"
        :href="tabs[activeTabIndex].link"
        class="active-tab__title">
        {{ tabs[activeTabIndex].title }}
      </tws-nav-link>
      <div
        class="active-tab__icon"
        @click="toggleCollapsed">
        <tws-mobile-menu-icon
          height="24"
          width="24" />
      </div>
    </div>

    <template v-for="(tab, index) in tabs">
      <tws-nav-link
        v-if="index !== activeTabIndex"
        :key="tab.title"
        :class="{'tab--collapsed' : collapsed}"
        :href="tab.link"
        class="tab">
        {{ tab.title }}
      </tws-nav-link>
    </template>
  </div>
</template>

<script>
import { TwsNavLink } from 'tws-vue-components'

export default {
  components: {
    TwsNavLink
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTabIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      collapsed: true
    }
  },
  methods: {
    toggleCollapsed () {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

%tab-shared {
  padding: 20px 25px;
  font-size: 20px;
}

.tabs-container {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  margin-bottom: -20px;
}

.tab {
  @extend %tab-shared;
  background-color: $medium-grey;

  &--collapsed {
    display: none;
  }

  &:not(:last-of-type) {
    margin-bottom: 1px;
  }
}

.active-tab {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  &__title {
    @extend %tab-shared;
    flex-grow: 1;
    background-color: $white;
    box-shadow: 0 3px 0 $core-purple inset;
    cursor: default;
    color: $core-purple;
  }

  &__icon {
    display: flex;
    align-items: center;
    padding: 0 23px;
    margin-left: 3px;
    cursor: pointer;
    background-color: $medium-grey;
  }
}

</style>
