<script>
import ProductCardLink from './ProductCardLink.vue'
import PurpurHeading from '../purpur/PurpurHeading.vue'
import PurpurParagraph from '../purpur/PurpurParagraph.vue'
import PurpurLink from '../purpur/PurpurLink.vue'
import PurpurIcon from '../purpur/PurpurIcon.vue'
import { arrowRight } from '@purpurds/icon'

export default {
  components: {
    ProductCardLink,
    PurpurHeading,
    PurpurParagraph,
    PurpurLink,
    PurpurIcon
  },
  props: {
    productIcon: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      arrowRight
    }
  },
  computed: {
    mainProduct () {
      return this.products[0]
    },
    secondaryProductList () {
      return this.products.slice(1)
    }
  }
}

</script>

<template>
  <div class="product-container-wrapper">
    <div class="product-container container">
      <article class="product-container__column product-container__column--primary">
        <section class="product-container__content container">
          <img
            class="product-container__image"
            :src="productIcon.iconUrl"
            alt="">

          <PurpurHeading
            tag="h2"
            class="product-container__title"
            variant="title-300">
            {{ mainProduct.title }}
          </PurpurHeading>

          <PurpurParagraph
            v-if="mainProduct.description"
            class="product-container__paragraph">
            {{ mainProduct.description }}
          </PurpurParagraph>

          <PurpurLink
            v-if="mainProduct.path"
            class="product-container__link"
            :href="mainProduct.path"
            variant="standalone-negative">
            {{ $t("public-landing.common.more_about_product") }}

            <PurpurIcon
              class="product-container__icon"
              :svg="arrowRight" />
          </PurpurLink>
        </section>
      </article>

      <div class="product-container__column product-container__column--secondary">
        <div class="product-container__content container">
          <ProductCardLink
            v-for="(product, index) in secondaryProductList"
            :key="index"
            class="product-item"
            :text="product.title"
            :link="product.path" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@purpurds/tokens/breakpoint/variables" as breakpoints;
@use '@purpurds/tokens/color/variables' as colors;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;

.product-container-wrapper {
  @media (min-width: breakpoints.$purpur-breakpoint-xl) {
    background: linear-gradient(to right, colors.$purpur-color-purple-900 60%, colors.$purpur-color-purple-50 40%);
  }
}

.product-container {
  display: flex;
  flex-direction: column;

  &.container {
    @media (max-width: breakpoints.$purpur-breakpoint-xl) {
      all: unset;
    }
  }

  @media (min-width: breakpoints.$purpur-breakpoint-lg) {
    flex-direction: row;
  }

  &__image {
    margin-bottom: spacing.$purpur-spacing-100;
  }

  &__column {
    position: relative;

    & .product-container__content {
      @media (min-width: breakpoints.$purpur-breakpoint-xl) {
        padding-left: unset;
        padding-right: unset;
      }
    }

    &--primary {
      flex-basis: 100%;
      background-color: colors.$purpur-color-purple-900;
      padding-top: spacing.$purpur-spacing-200;
      padding-bottom: 40px;

      @media (min-width: breakpoints.$purpur-breakpoint-md) {
        padding-top: 40px;
        padding-bottom: 64px;
      }

      @media (min-width: breakpoints.$purpur-breakpoint-lg) {
        flex-basis: 60%;
      }

      @media (min-width: breakpoints.$purpur-breakpoint-xl) {
        padding-top: spacing.$purpur-spacing-300;
        padding-bottom: 40px;
        padding-right: spacing.$purpur-spacing-800;
      }

      @media (min-width: 1700px) {
        padding-right: 80px;
      }
    }

    &--secondary {
      flex-basis: 100%;
      background-color: colors.$purpur-color-purple-50;
      padding-top: 40px;
      padding-bottom: 40px;

      &.product-container__content {
        display: flex;
        gap: spacing.$purpur-spacing-300;
        flex-direction: column;
        justify-content: center;
      }

      @media (min-width: breakpoints.$purpur-breakpoint-md) {
        padding-top: spacing.$purpur-spacing-400;
        padding-bottom: spacing.$purpur-spacing-400;
      }

      @media (min-width: breakpoints.$purpur-breakpoint-lg) {
        flex-basis: 40%;
        display: flex;
        align-items: center;
      }

      @media (min-width: breakpoints.$purpur-breakpoint-xl) {
        padding-left: spacing.$purpur-spacing-800;
      }

      @media (min-width: 1700px) {
        padding-left: 80px;
      }
    }
  }
}

::v-deep.product-container__icon {
  width: spacing.$purpur-spacing-200;
}

::v-deep.product-container__link span {
  color: colors.$purpur-color-functional-white
}

::v-deep.product-container__title span {
  color: colors.$purpur-color-purple-50!important;
  margin-bottom: spacing.$purpur-spacing-100!important;
}

::v-deep.product-container__paragraph span {
  color: colors.$purpur-color-purple-50;
  margin: spacing.$purpur-spacing-100 0 spacing.$purpur-spacing-300;
}

.product-item:not(:last-child) {
  margin-bottom: 24px;
}
</style>
