<template>
  <div class="row fault-report-interconnect">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <p class="title-200">
            {{ $t('fault-reporting.form.description.description') }}
          </p>
        </div>
      </div>

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-6 col-xs-12">
          <HorizontalRadioButtons
            :id="'complaint'"
            v-model="complaintProxy"
            :validation-messages="validationMessages.complaint"
            :validator="$v.complaint"
            :tooltip-text="$t('fault-reporting.form.description.complaint.tooltip')"
            :options="complaintOptions"
            :label="$t('fault-reporting.form.description.complaint.label')" />
        </div>
      </div>
      <hr class="divider">

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            :id="'hasTrafficWorkedBefore'"
            v-model="hasTrafficWorkedBeforeProxy"
            :validation-messages="validationMessages.hasTrafficWorkedBefore"
            :validator="$v.hasTrafficWorkedBefore"
            :label="$t('fault-reporting.form.description.has-traffic-worked-before.label')" />
        </div>
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            :id="'isCausedByPorting'"
            v-model="isCausedByPortingProxy"
            :validation-messages="validationMessages.isCausedByPorting"
            :validator="$v.isCausedByPorting"
            :label="$t('fault-reporting.form.description.is-caused-by-porting.label')" />
        </div>
      </div>

      <div class="row">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            :id="'isTrafficBetweenOperatorAffected'"
            v-model="isTrafficBetweenOperatorAffectedProxy"
            :validation-messages="validationMessages.isTrafficBetweenOperatorAffected"
            :validator="$v.isTrafficBetweenOperatorAffected"
            :label="$t('fault-reporting.form.description.is-traffic-between-operator-affected.label')" />
        </div>
      </div>

      <template v-if="isTrafficBetweenOperatorAffected !== null && !isTrafficBetweenOperatorAffected">
        <div class="row fieldset">
          <div class="col-xl-5 col-sm-5 col-xs-12 radios">
            <HorizontalRadioButtons
              :id="'isTrafficForCustomerAffected'"
              v-model="isTrafficForCustomerAffectedProxy"
              :validation-messages="validationMessages.isTrafficForCustomerAffected"
              :validator="$v.isTrafficForCustomerAffected"
              :label="$t('fault-reporting.form.description.is-traffic-for-customer-affected.label')" />
          </div>
          <div
            v-if="isTrafficForCustomerAffectedProxy"
            class="col-xl-5 col-sm-5 col-xs-12">
            <tws-validated-text-input-group
              id="affectedCustomer"
              v-model="affectedCustomerProxy"
              :validator="$v.affectedCustomer"
              :validation-messages="validationMessages.affectedCustomer"
              :label="$t('fault-reporting.form.description.affected-customer.label')" />
          </div>
        </div>

        <div class="row fieldset">
          <div class="col-xl-5 col-sm-5 col-xs-12 radios">
            <HorizontalRadioButtons
              :id="'isTrafficTypeAffected'"
              v-model="isTrafficTypeAffectedProxy"
              :validation-messages="validationMessages.isTrafficTypeAffected"
              :validator="$v.isTrafficTypeAffected"
              :label="$t('fault-reporting.form.description.is-traffic-type-affected.label')" />
          </div>
          <div
            v-if="isTrafficTypeAffectedProxy"
            class="col-xl-5 col-sm-5 col-xs-12">
            <tws-validated-text-input-group
              id="affectedType"
              v-model="affectedTypeProxy"
              :validator="$v.affectedType"
              :validation-messages="validationMessages.affectedType"
              :label="$t('fault-reporting.form.description.affected-type.label')" />
          </div>
        </div>
      </template>

      <hr class="divider">
      <div class="row">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="additionalInfo"
            v-model="additionalInformationProxy"
            :validator="$v.additionalInformation"
            :validation-messages="validationMessages.additionalInformation"
            :label="$t('fault-reporting.form.description.additional-information.label')" />
        </div>
      </div>

      <div class="row">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <InfraFilePicker
            v-model="attachmentsProxy"
            button-type="primary"
            :acceptable-file-extensions="acceptableFileExtensions"
            :limit="attachmentsLimit"
            :file-size-limit="defaultFileSizeLimit"
            :show-add-icon="true"
            :add-file-text="$t('fault-reporting.form.attachments-button.button-text')"
            :limit-is-reached-text="$t('fault-reporting.form.attachments-button.limit-is-reached-text').replace('limit', attachmentsLimit)"
            :size-limit-text="$t('fault-reporting.form.attachments-button.size-is-reached-text')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TwsValidatedTextAreaGroup,
  TwsValidatedTextInputGroup,
  InfraFilePicker
} from 'tws-vue-components'
import HorizontalRadioButtons from '@/apps/fault-reporting/components/form/HorizontalRadioButtons'
import {
  mapState, mapActions
} from 'vuex'
import {
  required, requiredIf, maxLength
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  ATTACHMENTS_ALLOWED_EXTENSIONS, ATTACHMENTS_FILE_SIZE_LIMIT,
  ATTACHMENTS_LIMIT, COMPLAINT_OPTIONS
} from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  components: {
    HorizontalRadioButtons,
    TwsValidatedTextAreaGroup,
    TwsValidatedTextInputGroup,
    InfraFilePicker
  },
  mixins: [validationMixin],
  data () {
    return {
      attachmentsLimit: ATTACHMENTS_LIMIT,
      complaintOptions: COMPLAINT_OPTIONS,
      acceptableFileExtensions: ATTACHMENTS_ALLOWED_EXTENSIONS,
      defaultFileSizeLimit: ATTACHMENTS_FILE_SIZE_LIMIT,
      validationMessages: {
        additionalInformation: {
          required: this.$t('fault-reporting.form.description.additional-information.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.additional-information.validation-message-max-length')
        },
        complaint: {
          required: this.$t('fault-reporting.form.description.complaint.validation-message')
        },
        affectedCustomer: {
          required: this.$t('fault-reporting.form.description.affected-customer.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.affected-customer.validation-message-max-length')
        },
        affectedType: {
          required: this.$t('fault-reporting.form.description.affected-type.validation-message'),
          maxLength: this.$t('fault-reporting.form.description.affected-type.validation-message-max-length')
        },
        hasTrafficWorkedBefore: {
          required: this.$t('fault-reporting.form.description.has-traffic-worked-before.validation-message')
        },
        isCausedByPorting: {
          required: this.$t('fault-reporting.form.description.is-caused-by-porting.validation-message')
        },
        isTrafficBetweenOperatorAffected: {
          required: this.$t('fault-reporting.form.description.is-traffic-between-operator-affected.validation-message')
        },
        isTrafficForCustomerAffected: {
          required: this.$t('fault-reporting.form.description.is-traffic-for-customer-affected.validation-message')
        },
        isTrafficTypeAffected: {
          required: this.$t('fault-reporting.form.description.is-traffic-type-affected.validation-message')
        }
      }
    }
  },
  computed: {
    ...mapState('FaultReporting/FaultReportForm/InterconnectCase', [
      'additionalInformation',
      'complaint',
      'attachments',
      'hasTrafficWorkedBefore',
      'isCausedByPorting',
      'isTrafficBetweenOperatorAffected',
      'isTrafficForCustomerAffected',
      'isTrafficTypeAffected',
      'affectedCustomer',
      'affectedType',
      'isValid'
    ]),
    additionalInformationProxy: {
      get () {
        return this.additionalInformation
      },
      set (value) {
        this.updateAdditionalInformation(value)
      }
    },
    complaintProxy: {
      get () {
        return this.complaint
      },
      set (value) {
        this.updateComplaint(value)
      }
    },
    attachmentsProxy: {
      get () {
        return this.attachments
      },
      set (value) {
        this.updateAttachments(value)
      }
    },
    hasTrafficWorkedBeforeProxy: {
      get () {
        return this.hasTrafficWorkedBefore
      },
      set (value) {
        this.updateHasTrafficWorkedBefore(value)
      }
    },
    isCausedByPortingProxy: {
      get () {
        return this.isCausedByPorting
      },
      set (value) {
        this.updateIsCausedByPorting(value)
      }
    },
    isTrafficBetweenOperatorAffectedProxy: {
      get () {
        return this.isTrafficBetweenOperatorAffected
      },
      set (value) {
        this.updateIsTrafficBetweenOperatorAffected(value)
      }
    },
    isTrafficForCustomerAffectedProxy: {
      get () {
        return this.isTrafficForCustomerAffected
      },
      set (value) {
        this.updateIsTrafficForCustomerAffected(value)
      }
    },
    affectedCustomerProxy: {
      get () {
        return this.affectedCustomer
      },
      set (value) {
        this.updateAffectedCustomer(value)
      }
    },
    isTrafficTypeAffectedProxy: {
      get () {
        return this.isTrafficTypeAffected
      },
      set (value) {
        this.updateIsTrafficTypeAffected(value)
      }
    },
    affectedTypeProxy: {
      get () {
        return this.affectedType
      },
      set (value) {
        this.updateAffectedType(value)
      }
    },
    isValidProxy () {
      return !this.$v.$invalid
    }
  },
  validations: {
    additionalInformation: {
      required,
      maxLength: maxLength(1000)
    },
    complaint: {
      required
    },
    affectedCustomer: {
      required: requiredIf((form) => form.isTrafficForCustomerAffectedProxy && !form.isTrafficBetweenOperatorAffectedProxy),
      maxLength: maxLength(50)
    },
    affectedType: {
      required: requiredIf((form) => form.isTrafficTypeAffectedProxy && !form.isTrafficBetweenOperatorAffectedProxy),
      maxLength: maxLength(50)
    },
    hasTrafficWorkedBefore: {
      required
    },
    isCausedByPorting: {
      required
    },
    isTrafficBetweenOperatorAffected: {
      required
    },
    isTrafficForCustomerAffected: {
      required: requiredIf((form) => !form.isTrafficBetweenOperatorAffectedProxy)
    },
    isTrafficTypeAffected: {
      required: requiredIf((form) => !form.isTrafficBetweenOperatorAffectedProxy)
    }
  },
  watch: {
    isValidProxy (val) {
      this.updateIsValid(val)
    }
  },
  methods: {
    ...mapActions('FaultReporting/FaultReportForm/InterconnectCase', [
      'updateComplaint',
      'updateAdditionalInformation',
      'updateAttachments',
      'updateHasTrafficWorkedBefore',
      'updateIsCausedByPorting',
      'updateIsTrafficBetweenOperatorAffected',
      'updateIsTrafficForCustomerAffected',
      'updateAffectedCustomer',
      'updateIsTrafficTypeAffected',
      'updateAffectedType',
      'updateIsValid'
    ])
  }
}
</script>
