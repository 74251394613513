import { render, staticRenderFns } from "./wholesale-fiber-product-side.vue?vue&type=template&id=3fd1caac&scoped=true"
import script from "./wholesale-fiber-product-side.vue?vue&type=script&lang=js"
export * from "./wholesale-fiber-product-side.vue?vue&type=script&lang=js"
import style0 from "./wholesale-fiber-product-side.vue?vue&type=style&index=0&id=3fd1caac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fd1caac",
  null
  
)

export default component.exports