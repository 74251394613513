const FAQ = 'twsFaq'

const extractBanner = banner => {
  if (!banner || !banner.fields) {
    return null
  }
  const fields = banner.fields

  return {
    heading: fields.heading,
    description: fields.description
  }
}

const extractLinks = relatedProducts => {
  if (!relatedProducts || !relatedProducts.length) {
    return null
  }

  return relatedProducts
    .map(relatedProduct => ({
      title: relatedProduct.fields.title,
      path: relatedProduct.fields.path
    }))
}

const extractRelatedProducts = relatedProductsContainers => {
  if (!relatedProductsContainers || !relatedProductsContainers.length) {
    return null
  }

  return relatedProductsContainers
    .map(item => ({
      heading: item.fields.heading,
      links: extractLinks(item.fields.relatedProducts)
    }))
}

const extractPageIcons = pageIcons => {
  if (!pageIcons || !pageIcons.length) {
    return null
  }
  return pageIcons
    .map(item => ({
      icon: item.fields.icon.fields.file.url,
      title: item.fields.iconText
    }))
}

const extractProductCards = productCards => {
  if (!productCards) {
    return null
  }

  const nodes = productCards['@nodes']

  if (nodes.length === 0) {
    return null
  }

  return nodes
    .map(node => productCards[node])
    .map(item => ({
      buttonLink: item.buttonLink,
      buttonText: item.buttonText,
      fullText: item.fullText,
      image: item.image,
      shortText: item.shortText,
      title: item.title
    }))
}

const extractDescription = description => {
  if (!description || !description.fields) {
    return null
  }
  const fields = description.fields

  return {
    title: fields.title,
    content: fields.content,
    subTitle: fields.subTitle,
    subDescription: fields.subDescription,
    pageIcons: extractPageIcons(fields.pageIcons),
    relatedProducts: extractRelatedProducts(fields.relatedProductsContainers),
    productCards: extractProductCards(description.productCards)
  }
}

const extractSpecificationsContent = contentList => {
  if (contentList.length === 0) {
    return null
  }

  const mapContentByType = (contentItem) => {
    let richText = null
    let faq = null

    const contentType = contentItem.sys.contentType.sys.id

    switch (contentType) {
      case FAQ:
        faq = contentItem.fields
        break
      default:
        richText = contentItem.fields.value
    }

    return {
      richText,
      faq
    }
  }

  return contentList.map(i => mapContentByType(i))
}

const extractSpecifications = specifications => {
  if (!specifications || !specifications.fields || !specifications.fields.productSpecifications || !specifications.fields.productSpecifications.length) {
    return null
  }
  const fields = specifications.fields

  return {
    title: fields.title,
    list: fields.productSpecifications
      .map(spec => {
        const fields = spec.fields
        return {
          id: fields.productId,
          title: fields.title,
          public: fields.isVisibleForPublic === true,
          path: fields.path,
          content: extractSpecificationsContent(fields.content)
        }
      })
  }
}

const extractTabs = response => {
  if (!response) {
    return null
  }

  const description = extractDescription(response.descriptionTab)
  const specifications = extractSpecifications(response.productSpecificationsTab)
  const documents = response.showDocumentsTab

  if (description || specifications || documents) {
    return {
      description,
      specifications,
      documents
    }
  }

  return null
}

const extractItems = products => {

  if (products.length === 0) {
    return null
  }

  return products
    .map(product => {
      const item = product.fields

      return {
        title: item.title,
        description: item.shortDescription,
        path: item.path,
        isAvailableOnCategoryView: item.showProductOnCategoryView === true
      }
    })
}

export const extractProduct = product => {
  if (!product) {
    return null
  }

  return {
    id: product.productId,
    title: product.title,
    description: product.shortDescription,
    path: product.path,
    banner: extractBanner(product.banner),
    tabs: extractTabs(product),
    isEnglishAvailable: product.englishContentIsAvailable === true,
    isAvailableOnCategoryView: product.showProductOnCategoryView === true,
    isProductCardAvailable: product.productCardsEnabled === true
  }
}

export const extractProducts = response => {
  const products = response.products
  if (!products.length) {
    return null
  }

  return {
    heading: response.title,
    items: extractItems(products)
  }
}
