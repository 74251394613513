import i18n from '@/i18n'

export const links = {
  contact: '/kontakt',
  products: '/products',
  solutions: '/losningar'
}

export const MEGA_MENU = {
  SOLUTIONS: 'solutions',
  ABOUT_US: 'about-us',
  PRODUCTS: 'products'
}

export const HEADER_ITEMS = [
  {
    menu: MEGA_MENU.ABOUT_US,
    title: i18n.t('header.menu-items.about-us'),
    order: 0
  },
  {
    menu: MEGA_MENU.SOLUTIONS,
    title: i18n.t('header.menu-items.solutions'),
    order: 1
  },
  {
    menu: MEGA_MENU.PRODUCTS,
    title: i18n.t('header.menu-items.products'),
    order: 2
  },
  {
    title: i18n.t('header.menu-items.contact'),
    link: links.contact,
    order: 3
  }
]
