import i18n from '../i18n'

export const getTranslationValue = (value) => {
  return value && value.includes('.') && value === value.toLowerCase()
    ? i18n.t(value)
    : value
}

export const parseTranslationsFromEnum = (object) => {
  return Object.entries(object).reduce((agg, [
    labelKey,
    labelValue
  ]) => ({
    ...agg,
    [labelKey]: i18n.t(labelValue)
  }), {})
}

export const parseTranslationsFromArray = (array) => {
  return array.map((translationKey) => i18n.t(translationKey))
}
