<template>
  <div class="company-information-edit">
    <div class="row">
      <div class="col-3">
        <tws-validated-text-input-group
          id="code"
          v-model="information.code"
          :validator="validator.code"
          :validation-messages="validationMessages.code"
          :disabled="!creation"
          :label="$t('common.company.code')" />
      </div>

      <div class="col-3">
        <tws-validated-text-input-group
          id="name"
          v-model="information.name"
          :validator="validator.name"
          :validation-messages="validationMessages.name"
          :label="$t('common.company.name')" />
      </div>

      <div class="col-3">
        <tws-validated-text-input-group
          id="org-nr"
          v-model="information.orgNr"
          :validator="validator.orgNr"
          :validation-messages="validationMessages.orgNr"
          :label="$t('common.company.number')" />
      </div>

      <div class="col-3">
        <tws-text-input-group
          id="sp-id"
          v-model="information.spId"
          :label="$t('common.company.id')" />
      </div>
    </div>

    <div class="row self-admin-row">
      <div class="col-12">
        <tws-checkbox
          id="self-administration"
          v-model="information.selfAdministration"
          :label="$t('company-information.self-administration')" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  TwsCheckbox,
  TwsTextInputGroup,
  TwsValidatedTextInputGroup
} from 'tws-vue-components'

export default {
  components: {
    TwsCheckbox,
    TwsTextInputGroup,
    TwsValidatedTextInputGroup
  },
  props: {
    information: {
      type: Object,
      required: true
    },
    validator: {
      type: Object,
      required: true
    },
    creation: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      validationMessages: {
        code: {
          required: this.$t('company-information.validations.code.required'),
          companyCode: this.$t('company-information.validations.code.company-code')
        },
        name: {
          required: this.$t('company-information.validations.name.required')
        },
        orgNr: {
          required: this.$t('company-information.validations.org-nr.required'),
          orgNumber: this.$t('company-information.validations.org-nr.org-number')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.self-admin-row {
  margin-top: 15px;
}
</style>
