<template>
  <PropertySearchView
    :title="$t('property-search.form.title')"
    :display-warning="noPropertyFound"
    :display-danger="displayError"
    :warning-text="$t('property-search.form.warning-text')"
    :danger-text="$t('property-search.form.danger-text')"
    class="property-search-form__container"
    @close="handleClose">
    <form
      class="property-search-form__fields"
      @submit.prevent="submit">
      <div class="row">
        <TextField
          id="municipality"
          v-model="municipality"
          class="col-8"
          name="municipality"
          :label="$t('property-search.form.labels.municipality')"
          :validator="$v.municipality"
          :validation-messages="validationMessages.municipality" />
      </div>
      <div class="row">
        <TextField
          id="district"
          v-model="district"
          class="col-6"
          name="district"
          :label="$t('property-search.form.labels.district')"
          :validator="$v.district"
          :validation-messages="validationMessages.district" />
        <TextField
          id="block"
          v-model="block"
          class="col-2"
          name="block"
          :label="$t('property-search.form.labels.block')"
          :validator="$v.block"
          :validation-messages="validationMessages.block" />
        <TextField
          id="unit"
          v-model="unit"
          class="col-2"
          name="unit"
          :label="$t('property-search.form.labels.unit')"
          :validator="$v.unit"
          :validation-messages="validationMessages.unit" />
      </div>
      <div
        class="row buttons"
        :class="{ 'buttons--slim': noPropertyFound || displayError }">
        <div class="col-12 d-flex flex-row">
          <TwsButtonStateful
            id="submit-property-search"
            :text="$t('property-search.form.buttons.search')"
            type="submit"
            :in-progress="loading"
            :disabled="disableSearch" />
          <TwsBorderlessButton
            id="abort-property-search"
            class="ml-4"
            @click="handleClose">
            {{ $t('property-search.form.buttons.cancel') }}
          </TwsBorderlessButton>
        </div>
      </div>
    </form>
  </PropertySearchView>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import {
  TwsBorderlessButton,
  TwsButtonStateful
} from 'tws-vue-components'
import PropertySearchView from './PropertySearchView'
import TextField from '@/apps/address-search/components/TextField'
import {
  municipality,
  district,
  block,
  unit
} from '@/apps/address-search/utils/validation-rules'

export default {
  components: {
    TextField,
    TwsBorderlessButton,
    TwsButtonStateful,
    PropertySearchView
  },
  mixins: [validationMixin],
  data () {
    return {
      validationMessages: {
        municipality: {
          municipality: this.$t('property-search.form.validation-messages.municipality.invalid'),
          required: this.$t('property-search.form.validation-messages.municipality.required')
        },
        district: {
          district: this.$t('property-search.form.validation-messages.district.invalid'),
          required: this.$t('property-search.form.validation-messages.district.required')
        },
        block: {
          block: this.$t('property-search.form.validation-messages.block.invalid')
        },
        unit: {
          unit: this.$t('property-search.form.validation-messages.unit.invalid')
        }
      }
    }
  },
  computed: {
    municipality: {
      get () { return this.$store.getters['AddressSearch/PropertySearch/municipality'] },
      set (value) { this.$store.dispatch('AddressSearch/PropertySearch/setMunicipality', value) }
    },
    district: {
      get () { return this.$store.getters['AddressSearch/PropertySearch/district'] },
      set (value) { this.$store.dispatch('AddressSearch/PropertySearch/setDistrict', value) }
    },
    block: {
      get () { return this.$store.getters['AddressSearch/PropertySearch/block'] },
      set (value) { this.$store.dispatch('AddressSearch/PropertySearch/setBlock', value) }
    },
    unit: {
      get () { return this.$store.getters['AddressSearch/PropertySearch/unit'] },
      set (value) { this.$store.dispatch('AddressSearch/PropertySearch/setUnit', value) }
    },
    propertyBlocks () {
      return this.$store.getters['AddressSearch/PropertySearch/propertyBlocks']
    },
    loading () { return this.$store.getters['AddressSearch/PropertySearch/loading'] },
    loaded () { return this.$store.getters['AddressSearch/PropertySearch/loaded'] },
    exception () { return this.$store.getters['AddressSearch/PropertySearch/exception'] },
    disableSearch () {
      return this.$v.$anyError || this.loading
    },
    noPropertyFound () {
      return this.loaded && this.propertyBlocks === undefined && !this.exception
    },
    displayError () {
      return this.exception
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    submit () {
      this.$v.municipality.$touch()
      this.$v.district.$touch()
      this.$v.block.$touch()
      this.$v.unit.$touch()

      if (!this.$v.$anyError) {
        this.$store.dispatch('AddressSearch/PropertySearch/fetchPropertyBlocks')
      }
    }
  },
  validations: {
    municipality: {
      required,
      municipality
    },
    district: {
      required,
      district
    },
    unit: {
      unit
    },
    block: {
      block
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.buttons {
  margin-bottom: 30px;

  &--slim {
    margin-bottom: 1px; // fixes alertbox clipping issue
  }
}

button {
  display: flex;
  align-items: center;
  border-radius: 2.5rem / 50%;
  padding: 1.6rem 3rem;
}

.property-search-form {
  &__fields {
    margin-top: 14px;
  }
}

#abort-property-search {
  padding: 16px 30px;
  text-transform: uppercase;
  font-weight: 700;
  color: $core-purple;
}
</style>
