<template>
  <div class="account-information-display">
    <div class="row account-information-display__general">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.username') }}
        </label>

        <label>
          {{ information.username }}
        </label>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.login-method') }}
        </label>

        <label>
          {{ information.loginMethodPassword ? $t('account-information.password') : $t('account-information.one-time-code') }}
        </label>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.account-status') }}
        </label>

        <label>
          {{ $t(accountStatus[information.accountStatus]) }}
        </label>
      </div>

      <div
        v-if="information.accountExpirationDate"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.expiration-date') }}
        </label>

        <label>
          {{ information.accountExpirationDate }}
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h3 class="heading-s account-information-display__roles-heading">
          {{ $t('account-information.roles') }}
        </h3>
      </div>
    </div>

    <div class="row account-information-display__roles--common">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label
          v-if="information.roles.SYSTEM_USER"
          class="d-block">
          {{ $t(getRole('SYSTEM_USER')) }}
        </label>
      </div>
    </div>

    <div class="row account-information-display__roles--common">
      <div
        v-if="hasToolsRole"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.roles-and-tools') }}
        </label>

        <label
          v-if="information.roles.AGREEMENT_RESPONSIBLE"
          class="d-block">
          {{ $t(getRole('AGREEMENT_RESPONSIBLE')) }}
        </label>

        <label
          v-if="information.roles.REPORTS"
          class="d-block">
          {{ $t(getRole('REPORTS')) }}
        </label>

        <label
          v-if="information.roles.SEARCH"
          class="d-block">
          {{ $t(getRole('SEARCH')) }}
        </label>
        <label
          v-if="information.roles.INVOICES"
          class="d-block">
          {{ $t(getRole('INVOICES')) }}
        </label>
        <label
          v-if="information.roles.SPECIFICATIONS"
          class="d-block">
          {{ $t(getRole('SPECIFICATIONS')) }}
        </label>
      </div>

      <div
        v-if="hasTicketHandlingRole"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.complaints') }}
        </label>

        <label v-if="information.roles.TROUBLE_TICKET">
          {{ $t(getRole('TROUBLE_TICKET')) }}
        </label>

        <label v-if="information.roles.TROUBLE_TICKET_PLUS">
          {{ $t(getRole('TROUBLE_TICKET_PLUS')) }}
        </label>

        <label v-if="information.roles.TROUBLE_TICKET_NO_ACCESS">
          {{ $t(getRole('TROUBLE_TICKET_NO_ACCESS')) }}
        </label>
      </div>

      <div
        v-if="hasNetBusinessRole"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.business-role') }}
        </label>

        <label v-if="information.roles.NETBUSINESS_READ">
          {{ $t(getRole('NETBUSINESS_READ')) }}
        </label>

        <label v-if="information.roles.NETBUSINESS_QOUTE_CREATION">
          {{ $t(getRole('NETBUSINESS_QOUTE_CREATION')) }}
        </label>

        <label v-if="information.roles.NETBUSINESS_ORDER_CREATION">
          {{ $t(getRole('NETBUSINESS_ORDER_CREATION')) }}
        </label>

        <label v-if="information.roles.NETBUSINESS_MANAGE_LEVEL_NO_ACCESS">
          {{ $t(getRole('NETBUSINESS_MANAGE_LEVEL_NO_ACCESS')) }}
        </label>
      </div>

      <div
        v-if="hasPhoneSubscriptionRole"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.subscription-role') }}
        </label>

        <label
          v-if="information.roles.PHONE_SUBCRIPTION_ORDER_CREATION"
          class="d-block">
          {{ $t(getRole('PHONE_SUBCRIPTION_ORDER_CREATION')) }}
        </label>

        <label
          v-if="information.roles.PHONE_SUBCRIPTION_ORDER_MANAGMENT"
          class="d-block">
          {{ $t(getRole('PHONE_SUBCRIPTION_ORDER_MANAGMENT')) }}
        </label>

        <label
          v-if="information.roles.PHONE_SUBCRIPTION_FAULT_HANDLING"
          class="d-block">
          {{ $t(getRole('PHONE_SUBCRIPTION_FAULT_HANDLING')) }}
        </label>
      </div>
    </div>

    <div
      v-if="!isDelegatedAdmin && (hasWebTabRole || hasAdministratorRole)"
      class="row account-information-display__roles--admin">
      <div
        v-if="hasWebTabRole"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.web-tab-role') }}
        </label>

        <label
          v-if="information.roles.WEBTAB_ADMIN_TAB_ADMIN"
          class="d-block">
          {{ $t(getRole('WEBTAB_ADMIN_TAB_ADMIN')) }}
        </label>

        <label
          v-if="information.roles.WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE"
          class="d-block">
          {{ $t(getRole('WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE')) }}
        </label>

        <label
          v-if="information.roles.WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR"
          class="d-block">
          {{ $t(getRole('WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR')) }}
        </label>

        <label
          v-if="information.roles.WEBTAB_ADMIN_SUPERUSER"
          class="d-block">
          {{ $t(getRole('WEBTAB_ADMIN_SUPERUSER')) }}
        </label>

        <label
          v-if="information.roles.WEBTAB_ADMIN_MAINTENANCE"
          class="d-block">
          {{ $t(getRole('WEBTAB_ADMIN_MAINTENANCE')) }}
        </label>

        <label
          v-if="information.roles.WEBTAB_ADMIN_TAB_PORTING_INTERNAL"
          class="d-block">
          {{ $t(getRole('WEBTAB_ADMIN_TAB_PORTING_INTERNAL')) }}
        </label>

        <label
          v-if="information.roles.WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL"
          class="d-block">
          {{ $t(getRole('WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL')) }}
        </label>
      </div>

      <div
        v-if="hasAdministratorRole"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.administrator-role') }}
        </label>

        <label
          v-if="information.roles.ADMINISTRATOR"
          class="d-block">
          {{ $t(getRole('ADMINISTRATOR')) }}
        </label>

        <label
          v-if="information.roles.DELEGATED_ADMINISTRATOR"
          class="d-block">
          {{ $t(getRole('DELEGATED_ADMINISTRATOR')) }}
        </label>

        <label
          v-if="information.roles.BILLING_ADMINISTRATOR"
          class="d-block">
          {{ $t(getRole("BILLING_ADMINISTRATOR")) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { ACCOUNT_STATUS_TEXT } from '@/constants/account-status'
import { USER_ROLES } from '@/constants/user-roles'

export default {
  props: {
    information: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      accountStatus: ACCOUNT_STATUS_TEXT
    }
  },
  computed: {
    ...mapGetters('eamCookies', ['isDelegatedAdmin']),
    hasNetBusinessRole () {
      return this.information.roles.NETBUSINESS_READ ||
      this.information.roles.NETBUSINESS_QOUTE_CREATION ||
      this.information.roles.NETBUSINESS_ORDER_CREATION ||
      this.information.roles.NETBUSINESS_MANAGE_LEVEL_NO_ACCESS
    },
    hasToolsRole () {
      return this.information.roles.AGREEMENT_RESPONSIBLE ||
      this.information.roles.REPORTS ||
      this.information.roles.SEARCH ||
      this.information.roles.INVOICES ||
      this.information.roles.SPECIFICATIONS ||
      this.information.roles.BILLING_ADMINISTRATOR
    },
    hasTicketHandlingRole () {
      return this.information.roles.TROUBLE_TICKET ||
       this.information.roles.TROUBLE_TICKET_PLUS ||
       this.information.roles.TROUBLE_TICKET_NO_ACCESS
    },
    hasPhoneSubscriptionRole () {
      return this.information.roles.PHONE_SUBCRIPTION_ORDER_CREATION ||
      this.information.roles.PHONE_SUBCRIPTION_ORDER_MANAGMENT ||
      this.information.roles.PHONE_SUBCRIPTION_FAULT_HANDLING
    },
    hasWebTabRole () {
      return this.information.roles.WEBTAB_ADMIN_TAB_ADMIN ||
       this.information.roles.WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE ||
       this.information.roles.WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR ||
       this.information.roles.WEBTAB_ADMIN_SUPERUSER ||
       this.information.roles.WEBTAB_ADMIN_MAINTENANCE ||
       this.information.roles.WEBTAB_ADMIN_TAB_PORTING_INTERNAL ||
       this.WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL
    },
    hasAdministratorRole () {
      return this.information.roles.ADMINISTRATOR ||
      this.information.roles.DELEGATED_ADMINISTRATOR ||
      this.information.roles.MASTER_ADMINISTRATOR ||
      this.information.roles.COMPANY_ADMIN ||
      this.information.roles.COMPANY_TRANSFER ||
      this.information.roles.BILLING_ADMINISTRATOR
    }
  },
  methods: {
    getRole (role) {
      return USER_ROLES[role]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.account-information-display {
  &__general,
  &__roles--common,
  &__roles--admin {
    @media (max-width: $breakpoint-lg) {
      [class*="col-"]:nth-child(n+4) {
        margin-top: 45px;
      }
    }

    @media (max-width: $breakpoint-md) {
      [class*="col-"]:nth-child(n+3) {
        margin-top: 45px;
      }
    }

    @media (max-width: $breakpoint-sm) {
      [class*="col-"]:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  &__roles {
    &-heading {
      margin-top: 50px;
      margin-bottom: 25px;
    }

    &--admin {
      margin-top: 45px;
    }
  }
}
</style>
