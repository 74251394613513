import LoginService from '@/services/login.service'

export const LoginModule = {
  namespaced: true,
  state: {
    username: null,
    password: null,
    oneTimeCode: null,
    oneTimeCodeUsername: null,
    oneTimeCodeSent: false,
    eamAuthenticationUrl: null,
    loaded: false,
    exception: false
  },
  mutations: {
    setUsername (state, username) {
      state.username = username
    },
    setPassword (state, password) {
      state.password = password
    },
    setOneTimeCode (state, code) {
      state.oneTimeCode = code
    },
    setOneTimeCodeUsername (state, username) {
      state.oneTimeCodeUsername = username
    },
    setOneTimeCodeSent (state, sent) {
      state.oneTimeCodeSent = sent
    },
    setEamAuthenticationUrl (state, value) {
      state.eamAuthenticationUrl = value
    },
    setLoaded (state, value) {
      state.loaded = value
    },
    setException (state, value) {
      state.exception = value
    }
  },
  actions: {
    usernameEntered ({ commit }, username) {
      commit('setUsername', username)
    },
    passwordEntered ({ commit }, password) {
      commit('setPassword', password)
    },
    oneTimeCodeEntered ({ commit }, code) {
      commit('setOneTimeCode', code)
    },
    oneTimeCodeUsernameEntered ({ commit }, username) {
      commit('setOneTimeCodeUsername', username)
    },
    fetchEamAuthenticationUrl ({ commit }) {
      commit('setException', false)
      commit('setLoaded', false)

      const url = LoginService.getEamAuthenticationUrl()

      commit('setEamAuthenticationUrl', url)
      commit('setLoaded', true)
    },
    sendOneTimeCode ({ commit, state }) {
      LoginService.sendOneTimeCode(
        state.eamAuthenticationUrl,
        state.oneTimeCodeUsername,
        location.origin + process.env.VUE_APP_DASHBOARD_PATH,
        location.origin + process.env.VUE_APP_LOGIN_PATH
      )

      commit('setOneTimeCodeSent', true)
    },
    oneTimeCodeCanceled ({ commit }) {
      commit('setOneTimeCodeSent', false)
    }
  }
}
