<template>
  <div class="information">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <tws-nav-link :href="logoLink">
            <img
              src="../../../assets/png/wholesale-logo-white.png"
              class="logo">
          </tws-nav-link>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12">
          <div
            class="address-1"
            v-html="addresses.address1" />
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12">
          <div
            class="address-2"
            v-html="addresses.address2" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="integrity">
            <tws-nav-link :href="integrityLink">
              {{ $t('footer.integrity') }}
            </tws-nav-link>
            |
            <tws-borderless-button @click="cookieSettingsScriptInit">
              {{ $t('footer.cookies') }}
            </tws-borderless-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TwsBorderlessButton,
  TwsNavLink
} from 'tws-vue-components'

export default {
  components: {
    TwsBorderlessButton,
    TwsNavLink
  },
  props: {
    addresses: {
      type: Object,
      required: true
    },
    logoLink: {
      type: String,
      required: true
    },
    integrityLink: {
      type: String,
      required: true
    }
  },
  methods: {
    cookieSettingsScriptInit () {
      // @ts-ignore
      window.OneTrust.ToggleInfoDisplay()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.information {
  background-color: $deep-purple;
  padding-top: 32px;
  padding-bottom: 29px;
}

.row:first-of-type {
  margin-bottom: 53px;
}

.integrity {
  text-align: center;
}

a {
  cursor: pointer;
}

.logo {
  width: 100%
}

@media (max-width: 991px) {
  .logo {
    width: 50%;
    margin-bottom: 34px;
  }

  .integrity {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .address-1 {
    margin-bottom: 24px;
  }
}
</style>
