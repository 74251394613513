<script>
import Carousel from './Carousel.vue'
import Card from '../card/Card.vue'
import { CarouselVariant } from './variant'
import { CardVariant } from '../card/variant'
import PurpurHeading from '../purpur/PurpurHeading.vue'
export default {
  components: {
    Carousel,
    Card,
    PurpurHeading
  },
  props: {
    cards: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      screenWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      shouldRenderCarousel: this.screenWidth >= 600,
      CarouselVariant,
      CardVariant
    }
  },
  watch: {
    screenWidth: {
      handler (val) {
        this.shouldRenderCarousel = val >= 600
      },
      immediate: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.updateScreenWidth)
  },
  destroyed () {
    window.removeEventListener('resize', this.updateScreenWidth)
  },
  methods: {
    updateScreenWidth () {
      this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
  }
}

</script>

<template>
  <section class="carousel-section container">
    <div v-if="shouldRenderCarousel">
      <Carousel
        :variant="CarouselVariant.MEDIUM"
        :title="title">
        <Card
          v-for="(card, index) in cards"
          :key="index"
          :title="card.title"
          :paragraph="card.paragraph"
          :img-src="card.imgSrc"
          :alt-text="card.altText"
          :button-link="card.buttonLink"
          :button-text="card.buttonText"
          :card-variant="CardVariant.REGULAR" />
      </Carousel>
    </div>
    <div
      v-else
      class="carousel-section__cards-container">
      <div class="carousel-section__cards-container__title">
        <PurpurHeading
          variant="title-400">
          {{ title }}
        </PurpurHeading>
      </div>
      <div class="carousel-section__cards">
        <Card
          v-for="(card, index) in cards"
          :key="index"
          :title="card.title"
          :paragraph="card.paragraph"
          :img-src="card.imgSrc"
          :alt-text="card.altText"
          :button-link="card.buttonLink"
          :button-text="card.buttonText"
          :card-variant="CardVariant.REGULAR" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use '@purpurds/tokens/color/variables' as colors;
@use '@purpurds/tokens/breakpoint/variables' as breakpoints;
@use '../../assets/scss/_mixins.scss' as mixins;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;

.carousel-section {
  padding-top: 40px;
  padding-bottom: 64px;

  &__cards-container {
    display: flex;
    flex-direction: column;
    gap: spacing.$purpur-spacing-200;

    &__title {
      margin-bottom: spacing.$purpur-spacing-200;
    }
  }

  &__cards {
    @extend .carousel-section__cards-container;
    @include mixins.indent-margin;
  }

  @media (min-width: breakpoints.$purpur-breakpoint-md) {
    padding-top: 40px;
    padding-bottom: spacing.$purpur-spacing-800;
  }

  @media (min-width: breakpoints.$purpur-breakpoint-lg) {
    padding-top: spacing.$purpur-spacing-800;
    padding-bottom: 80px;
  }
}
</style>
