<template>
  <div class="documents">
    <InfraAccordion :heading="heading">
      <InfraAccordionItem
        v-for="(category, categoryIndex) in categories"
        ref="accordion-item"
        :key="categoryIndex"
        :title="$t(getCategoryName(category))">
        <InfraTable
          class="documents-table"
          :items="items[category]">
          <template #thead>
            <tr>
              <th colspan="2">
                {{ $t('product-documents.table-headers.document') }}
              </th>
              <th>{{ $t('product-documents.table-headers.publish-date') }}</th>
              <th>{{ $t('product-documents.table-headers.version') }}</th>
              <th>{{ $t('product-documents.table-headers.language') }}</th>
              <th>{{ $t('product-documents.table-headers.comments') }}</th>
            </tr>
          </template>

          <template #tr="{ item }">
            <td>
              <TeliaCoreIcon
                :name="getIconName(item.extension)"
                :color="getIconColor(item.extension)"
                size="30px" />
            </td>
            <td class="font-weight-bold">
              <a
                class="document-display-name"
                :href="getDownloadLink(item)"
                @click="downloadDocument(item, category)">
                {{ item.displayName }}
              </a>
            </td>
            <td>{{ item.publishDate | isoDate }}</td>
            <td>{{ item.version }}</td>
            <td>{{ item.language }}</td>
            <td>{{ item.comments }}</td>
          </template>
        </InfraTable>
      </InfraAccordionItem>
    </InfraAccordion>
  </div>
</template>

<script>
import {
  InfraAccordion, InfraAccordionItem, InfraTable
} from 'tws-vue-components'

import { PRODUCT_DOCUMENT_CATEGORY } from '@/apps/products/constants'

import { isLoggedIn } from '@/utils/user.utils'
import DateUtils from '@/utils/date.utils'

import AnalyticsService from '@/services/analytics.service'

export default {
  components: {
    InfraAccordion,
    InfraAccordionItem,
    InfraTable
  },
  filters: {
    isoDate: value => DateUtils.dateTime.formatIsoDate(value)
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    items: {
      type: Object,
      required: true
    },
    productId: {
      type: String,
      required: true
    },
    productTitle: {
      type: String,
      required: true
    }
  },
  methods: {
    getCategoryName: category => PRODUCT_DOCUMENT_CATEGORY[category],
    getDownloadLink (item) {
      return isLoggedIn()
        ? `${process.env.VUE_APP_DOCUMENT_SERVICE_CTF}/document/logged-in/download?sysId=${item.id}&product=${this.productId}`
        : `${process.env.VUE_APP_DOCUMENT_SERVICE_PUBLIC}/document/public/download?sysId=${item.id}`
    },
    downloadDocument (item, category) {
      AnalyticsService.event(
        this.productTitle,
        'Document Download',
        `${this.$t(this.getCategoryName(category))} | ${item.displayName}`
      )
    },
    resolveExtension (extension) {
      switch (extension) {
        case 'docx':
          return 'doc'
        case 'xlsx':
          return 'xls'
        case 'xlsm':
          return 'xls'
        case 'pptx':
          return 'ppt'
        default:
          return extension
      }
    },
    getIconName (extension) {
      if (extension) {
        return 'document_' + this.resolveExtension(extension)
      }
      return 'document'
    },
    getIconColor (extension) {
      const resolvedExtension = this.resolveExtension(extension)
      switch (resolvedExtension) {
        case 'pdf':
          return 'red'
        case 'xls':
          return 'green'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .infra-table {
  tr:hover {
    cursor: default !important;
  }
}

.documents-table {
  table-layout: auto !important;
}

.document-display-name {
  text-decoration: none;
  color: #212529;

  &:hover {
    color: #990AE3;
  }
}
</style>
