<template>
  <table class="map-table">
    <thead>
      <tr>
        <th class="county">
          {{ $t('map.table-headers.area') }}
        </th>
        <th>{{ $t('map.table-headers.step-1') }}</th>
        <th>{{ $t('map.table-headers.step-2') }}</th>
        <th>{{ $t('map.table-headers.step-3') }}</th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(item, index) in items"
        :key="index">
        <td class="county">
          <span
            class="clickable"
            @click="$emit('select', getCountyCodeByName(item.county))">
            {{ item.county }}
          </span>
        </td>
        <td>{{ getCustomContent(item, 1) }}</td>
        <td>{{ getCustomContent(item, 2) }}</td>
        <td>{{ item.deliveryTime }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { COUNTY_CODE_BY_NAME } from '@/utils/map-utils'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCountyCodeByName (name) {
      return COUNTY_CODE_BY_NAME[name]
    },
    getCustomContent (item, field) {
      const contentKey = `${this.getCountyCodeByName(item.county)}_${field}`
      return this.content[contentKey]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import '@/components/map/scss/map-table';

.county {
  width: 40%;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: $core-purple;
  }
}

</style>
