import i18n from '@/i18n'

export const OPERATOR_PRODUCT = {
  ETHERNET_SWEDEN: 'ethernetsweden',
  ETHERNET_NORDIC: 'ethernetnordic',
  IP_STREAM: 'ipstream',
  IP_STREAM_ENHANCED: 'ipstreamenhanced',
  IP_TRANSIT: 'iptransit',
  SDH_SWEDEN: 'sdhsweden',
  WAVELENGTH: 'wavelength',
  OPEN_FIBER: 'openfiber',
  INTERCONNECT: 'interconnect',
  MOBILESP: 'mobilesp'
}

export const FORM_TYPE = {
  GENERAL: 'GENERAL',
  OPEN_FIBER: 'OPEN_FIBER',
  INTERCONNECT: 'INTERCONNECT',
  MOBILESP: 'MOBILESP'
}

export const VUEX_NAMESPACES = {
  FAULT_REPORT_FORM: 'FaultReportForm',
  FORM_VALIDATION: 'FormValidation',
  BASIC_INFO: 'BasicInfo',
  GENERAL_CASE: 'GeneralCase',
  INTERCONNECT_CASE: 'InterconnectCase',
  OPEN_FIBER: 'OpenFiberCase',
  NOTIFICATIONS: 'Notifications',
  MOBILESP: 'MobileSP'
}

export const FAULT_CATEGORY_DROPDOWN_OPTION = {
  CIRCUIT_DOWN: {
    label: i18n.t('fault-reporting.form.fault-category-options.circuit-down'),
    value: i18n.t('fault-reporting.form.fault-category-options.circuit-down')
  },
  CIRCUIT_BOUNCING: {
    label: i18n.t('fault-reporting.form.fault-category-options.circuit-bouncing'),
    value: i18n.t('fault-reporting.form.fault-category-options.circuit-bouncing')
  },
  HIGH_LATENCY: {
    label: i18n.t('fault-reporting.form.fault-category-options.high-latency'),
    value: i18n.t('fault-reporting.form.fault-category-options.high-latency')
  },
  PACKET_LOSS: {
    label: i18n.t('fault-reporting.form.fault-category-options.packet-loss'),
    value: i18n.t('fault-reporting.form.fault-category-options.packet-loss')
  },
  ALARM_INDICATING_SIGNAL: {
    label: i18n.t('fault-reporting.form.fault-category-options.alarm-indicating-signal'),
    value: i18n.t('fault-reporting.form.fault-category-options.alarm-indicating-signal')
  },
  LOSS_OF_SIGNAL: {
    label: i18n.t('fault-reporting.form.fault-category-options.loss-of-signal'),
    value: i18n.t('fault-reporting.form.fault-category-options.loss-of-signal')
  },
  LOSS_OF_FRAME: {
    label: i18n.t('fault-reporting.form.fault-category-options.loss-of-frame'),
    value: i18n.t('fault-reporting.form.fault-category-options.loss-of-frame')
  },
  SPEED_ISSUE: {
    label: i18n.t('fault-reporting.form.fault-category-options.speed-issue'),
    value: i18n.t('fault-reporting.form.fault-category-options.speed-issue')
  },
  NO_ACCESS: {
    label: i18n.t('fault-reporting.form.fault-category-options.no-access'),
    value: i18n.t('fault-reporting.form.fault-category-options.no-access')
  },
  TV_ISSUE: {
    label: i18n.t('fault-reporting.form.fault-category-options.tv-issue'),
    value: i18n.t('fault-reporting.form.fault-category-options.tv-issue')
  },
  OTHER: {
    label: i18n.t('fault-reporting.form.fault-category-options.other'),
    value: i18n.t('fault-reporting.form.fault-category-options.other')
  },
  INCIDENT_MOBILE_DATA: {
    label: i18n.t('fault-reporting.form.fault-category-options.incident-mobile-data'),
    value: i18n.t('fault-reporting.form.fault-category-options.incident-mobile-data')
  },
  INCIDENT_PRODUCTS_MOBILE_NUMBER_PORTING: {
    label: i18n.t('fault-reporting.form.fault-category-options.incident-products-mobile-number-porting'),
    value: i18n.t('fault-reporting.form.fault-category-options.incident-products-mobile-number-porting')
  },
  INCIDENT_VOICE_MOBILE_NETWORK: {
    label: i18n.t('fault-reporting.form.fault-category-options.incident-voice-mobile-network'),
    value: i18n.t('fault-reporting.form.fault-category-options.incident-voice-mobile-network')
  },
  IMEI_BARRING: {
    label: i18n.t('fault-reporting.form.fault-category-options.imei-barring'),
    value: i18n.t('fault-reporting.form.fault-category-options.imei-barring')
  }
}

export const PROBLEM_TYPE_DROPDOWN_OPTION = {
  GENERAL_PROBLEM_MOBILE_DATA: {
    label: i18n.t('fault-reporting.form.problem-type-options.general-problem-mobile-data'),
    value: i18n.t('fault-reporting.form.problem-type-options.general-problem-mobile-data')
  },
  INTERMITTENT_PROBLEM: {
    label: i18n.t('fault-reporting.form.problem-type-options.intermittent-problem'),
    value: i18n.t('fault-reporting.form.problem-type-options.intermittent-problem')
  },
  SLOW_SPEED: {
    label: i18n.t('fault-reporting.form.problem-type-options.slow-speed'),
    value: i18n.t('fault-reporting.form.problem-type-options.slow-speed')
  },
  PROBLEM_ABROAD: {
    label: i18n.t('fault-reporting.form.problem-type-options.problem-abroad'),
    value: i18n.t('fault-reporting.form.problem-type-options.problem-abroad')
  },
  SMS_MMS: {
    label: i18n.t('fault-reporting.form.problem-type-options.sms-mms'),
    value: i18n.t('fault-reporting.form.problem-type-options.sms-mms')
  },
  VOICEMAIL: {
    label: i18n.t('fault-reporting.form.problem-type-options.voicemail'),
    value: i18n.t('fault-reporting.form.problem-type-options.voicemail')
  },
  PROBLEM_PROVISIONING: {
    label: i18n.t('fault-reporting.form.problem-type-options.problem-provisioning'),
    value: i18n.t('fault-reporting.form.problem-type-options.problem-provisioning')
  },
  MOBILE_NUMBER_PORTABILITY: {
    label: i18n.t('fault-reporting.form.problem-type-options.mobile-number-portability'),
    value: i18n.t('fault-reporting.form.problem-type-options.mobile-number-portability')
  },
  PROBLEM_CALL_SPEECH_QUALITY: {
    label: i18n.t('fault-reporting.form.problem-type-options.problem-call-speech-quality'),
    value: i18n.t('fault-reporting.form.problem-type-options.problem-call-speech-quality')
  },
  PROBLEM_INCOMING_OUTGOING_CALLS: {
    label: i18n.t('fault-reporting.form.problem-type-options.problem-incoming-outgoing-calls'),
    value: i18n.t('fault-reporting.form.problem-type-options.problem-incoming-outgoing-calls')
  },
  PROBLEM_DROPPED_CALLS: {
    label: i18n.t('fault-reporting.form.problem-type-options.problem-dropped-calls'),
    value: i18n.t('fault-reporting.form.problem-type-options.problem-dropped-calls')
  },
  PROBLEM_REDUCED_COVERAGE: {
    label: i18n.t('fault-reporting.form.problem-type-options.problem-reduced-coverage'),
    value: i18n.t('fault-reporting.form.problem-type-options.problem-reduced-coverage')
  },
  OTHER_FAULTS: {
    label: i18n.t('fault-reporting.form.problem-type-options.other-faults'),
    value: i18n.t('fault-reporting.form.problem-type-options.other-faults')
  },
  ADD_BARRING_IMEI: {
    label: i18n.t('fault-reporting.form.problem-type-options.add-barring-imei'),
    value: i18n.t('fault-reporting.form.problem-type-options.add-barring-imei')
  },
  REMOVE_BARRING_IMEI: {
    label: i18n.t('fault-reporting.form.problem-type-options.remove-barring-imei'),
    value: i18n.t('fault-reporting.form.problem-type-options.remove-barring-imei')
  }
}

const PROBLEM_TYPE_DROPDOWN = {
  INCIDENT_MOBILE_DATA: [
    PROBLEM_TYPE_DROPDOWN_OPTION.GENERAL_PROBLEM_MOBILE_DATA,
    PROBLEM_TYPE_DROPDOWN_OPTION.INTERMITTENT_PROBLEM,
    PROBLEM_TYPE_DROPDOWN_OPTION.SLOW_SPEED,
    PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_ABROAD
  ],
  INCIDENT_PRODUCTS_MOBILE_NUMBER_PORTING: [
    PROBLEM_TYPE_DROPDOWN_OPTION.SMS_MMS,
    PROBLEM_TYPE_DROPDOWN_OPTION.VOICEMAIL,
    PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_PROVISIONING,
    PROBLEM_TYPE_DROPDOWN_OPTION.MOBILE_NUMBER_PORTABILITY
  ],
  INCIDENT_VOICE_MOBILE_NETWORK: [
    PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_CALL_SPEECH_QUALITY,
    PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_INCOMING_OUTGOING_CALLS,
    PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_DROPPED_CALLS,
    PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_REDUCED_COVERAGE,
    PROBLEM_TYPE_DROPDOWN_OPTION.OTHER_FAULTS,
    PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_ABROAD
  ],
  IMEI_BARRING: [
    PROBLEM_TYPE_DROPDOWN_OPTION.ADD_BARRING_IMEI,
    PROBLEM_TYPE_DROPDOWN_OPTION.REMOVE_BARRING_IMEI
  ]
}

export const FAULT_CATEGORY_DROPDOWN = {
  ETHERNET: [
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_DOWN,
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_BOUNCING,
    FAULT_CATEGORY_DROPDOWN_OPTION.HIGH_LATENCY,
    FAULT_CATEGORY_DROPDOWN_OPTION.PACKET_LOSS,
    FAULT_CATEGORY_DROPDOWN_OPTION.OTHER
  ],
  SDH_SWEDEN: [
    FAULT_CATEGORY_DROPDOWN_OPTION.ALARM_INDICATING_SIGNAL,
    FAULT_CATEGORY_DROPDOWN_OPTION.LOSS_OF_SIGNAL,
    FAULT_CATEGORY_DROPDOWN_OPTION.LOSS_OF_FRAME,
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_DOWN,
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_BOUNCING,
    FAULT_CATEGORY_DROPDOWN_OPTION.HIGH_LATENCY,
    FAULT_CATEGORY_DROPDOWN_OPTION.PACKET_LOSS,
    FAULT_CATEGORY_DROPDOWN_OPTION.OTHER
  ],
  IP_STREAM: [
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_DOWN,
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_BOUNCING,
    FAULT_CATEGORY_DROPDOWN_OPTION.HIGH_LATENCY,
    FAULT_CATEGORY_DROPDOWN_OPTION.PACKET_LOSS,
    FAULT_CATEGORY_DROPDOWN_OPTION.SPEED_ISSUE,
    FAULT_CATEGORY_DROPDOWN_OPTION.OTHER
  ],
  INTERCONNECT: [
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_DOWN,
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_BOUNCING,
    FAULT_CATEGORY_DROPDOWN_OPTION.HIGH_LATENCY,
    FAULT_CATEGORY_DROPDOWN_OPTION.PACKET_LOSS,
    FAULT_CATEGORY_DROPDOWN_OPTION.OTHER
  ],
  OPEN_FIBER: [
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_DOWN,
    FAULT_CATEGORY_DROPDOWN_OPTION.NO_ACCESS,
    FAULT_CATEGORY_DROPDOWN_OPTION.CIRCUIT_BOUNCING,
    FAULT_CATEGORY_DROPDOWN_OPTION.HIGH_LATENCY,
    FAULT_CATEGORY_DROPDOWN_OPTION.PACKET_LOSS,
    FAULT_CATEGORY_DROPDOWN_OPTION.SPEED_ISSUE,
    FAULT_CATEGORY_DROPDOWN_OPTION.TV_ISSUE,
    FAULT_CATEGORY_DROPDOWN_OPTION.OTHER
  ],
  MOBILESP: [
    FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_MOBILE_DATA,
    FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_PRODUCTS_MOBILE_NUMBER_PORTING,
    FAULT_CATEGORY_DROPDOWN_OPTION.INCIDENT_VOICE_MOBILE_NETWORK,
    FAULT_CATEGORY_DROPDOWN_OPTION.IMEI_BARRING
  ]
}

const PRODUCT_CATEGORY_FORM_ITEMS = {
  ETHERNET: {
    FORM_TYPE: FORM_TYPE.GENERAL,
    FAULT_CATEGORY_OPTIONS: FAULT_CATEGORY_DROPDOWN.ETHERNET,
    VUEX_NAMESPACE: VUEX_NAMESPACES.GENERAL_CASE
  },
  SDH_SWEDEN: {
    FORM_TYPE: FORM_TYPE.GENERAL,
    FAULT_CATEGORY_OPTIONS: FAULT_CATEGORY_DROPDOWN.SDH_SWEDEN,
    VUEX_NAMESPACE: VUEX_NAMESPACES.GENERAL_CASE
  },
  IP_STREAM: {
    FORM_TYPE: FORM_TYPE.GENERAL,
    FAULT_CATEGORY_OPTIONS: FAULT_CATEGORY_DROPDOWN.IP_STREAM,
    VUEX_NAMESPACE: VUEX_NAMESPACES.GENERAL_CASE
  },
  IP_STREAM_ENHANCED: {
    FORM_TYPE: FORM_TYPE.GENERAL,
    FAULT_CATEGORY_OPTIONS: FAULT_CATEGORY_DROPDOWN.IP_STREAM,
    VUEX_NAMESPACE: VUEX_NAMESPACES.GENERAL_CASE
  },
  INTERCONNECT: {
    FORM_TYPE: FORM_TYPE.INTERCONNECT,
    FAULT_CATEGORY_OPTIONS: FAULT_CATEGORY_DROPDOWN.INTERCONNECT,
    VUEX_NAMESPACE: VUEX_NAMESPACES.INTERCONNECT_CASE
  },
  OPEN_FIBER: {
    FORM_TYPE: FORM_TYPE.OPEN_FIBER,
    FAULT_CATEGORY_OPTIONS: FAULT_CATEGORY_DROPDOWN.OPEN_FIBER,
    VUEX_NAMESPACE: VUEX_NAMESPACES.OPEN_FIBER
  },
  MOBILESP: {
    FORM_TYPE: FORM_TYPE.MOBILESP,
    FAULT_CATEGORY_OPTIONS: FAULT_CATEGORY_DROPDOWN.MOBILESP,
    PROBLEM_TYPE_OPTIONS: PROBLEM_TYPE_DROPDOWN,
    VUEX_NAMESPACE: VUEX_NAMESPACES.MOBILESP
  }
}

export const PRODUCT_CATEGORY_ITEMS = {
  [OPERATOR_PRODUCT.ETHERNET_SWEDEN]: PRODUCT_CATEGORY_FORM_ITEMS.ETHERNET,
  [OPERATOR_PRODUCT.ETHERNET_NORDIC]: PRODUCT_CATEGORY_FORM_ITEMS.ETHERNET,
  [OPERATOR_PRODUCT.IP_TRANSIT]: PRODUCT_CATEGORY_FORM_ITEMS.ETHERNET,
  [OPERATOR_PRODUCT.WAVELENGTH]: PRODUCT_CATEGORY_FORM_ITEMS.ETHERNET,
  [OPERATOR_PRODUCT.SDH_SWEDEN]: PRODUCT_CATEGORY_FORM_ITEMS.SDH_SWEDEN,
  [OPERATOR_PRODUCT.IP_STREAM]: PRODUCT_CATEGORY_FORM_ITEMS.IP_STREAM,
  [OPERATOR_PRODUCT.IP_STREAM_ENHANCED]: PRODUCT_CATEGORY_FORM_ITEMS.IP_STREAM_ENHANCED,
  [OPERATOR_PRODUCT.INTERCONNECT]: PRODUCT_CATEGORY_FORM_ITEMS.INTERCONNECT,
  [OPERATOR_PRODUCT.OPEN_FIBER]: PRODUCT_CATEGORY_FORM_ITEMS.OPEN_FIBER,
  [OPERATOR_PRODUCT.MOBILESP]: PRODUCT_CATEGORY_FORM_ITEMS.MOBILESP
}

export const DATE_PICKER_OPTIONS = {
  enableTime: true,
  dateFormat: 'Y-m-d H:i',
  maxDate: 'today',
  time_24hr: true
}

export const NETWORK_OPTIONS = [
  {
    text: '3G',
    value: '3G'
  },
  {
    text: '4G',
    value: '4G'
  },
  {
    text: '5G',
    value: '5G'
  }
]

export const ATTACHMENTS_LIMIT = 5
export const ATTACHMENTS_FILE_SIZE_LIMIT = 26214400
export const ATTACHMENTS_FILE_SIZE_LIMIT_5MB = 5242880
export const ATTACHMENTS_ALLOWED_EXTENSIONS = [
  '.doc',
  '.docx',
  '.odt',
  '.pdf',
  '.xls',
  '.xlsx',
  '.xlsm',
  '.csv',
  '.ods',
  '.ppt',
  '.pptx',
  '.txt',
  '.jpg',
  '.jpeg',
  '.png',
  '.odp',
  '.pcap',
  '.pcapng',
  '.msgs'
]

export const FORM_DESCRIPTION_PROPERTIES = {
  FORM_TYPE: 'formType',
  CUSTOMER_REF_NUMBER: 'customerRefNumber',
  FAULT_CATEGORY: 'faultCategory',
  PROBLEM_TYPE: 'problemType',
  ADDITIONAL_INFO: 'additionalInformation',
  ATTACHMENTS: 'attachments',
  ENTRY_CODE: 'entryCode',
  IS_CIRCUIT_BEEN_USED: 'isCircuitBeenUsed',
  LAST_CIRCUIT_OPERATIONAL_DATE: 'lastCircuitOperationalDate',
  LOCAL_CONTACT_NAME: 'localContactName',
  LOCAL_CONTACT_PHONE: 'localContactPhone',
  PREMISES_NETWORK_CONSENT: 'premisesNetworkContent',
  HAS_TRAFFIC_WORKED_BEFORE: 'hasTrafficWorkedBefore',
  IS_CAUSED_BY_PORTING: 'isCausedByPorting',
  IS_TRAFFIC_BETWEEN_OPERATOR_AFFECTED: 'isTrafficBetweenOperatorAffected',
  IS_TRAFFIC_FOR_CUSTOMER_AFFECTED: 'isTrafficForCustomerAffected',
  AFFECTED_CUSTOMER: 'affectedCustomer',
  IS_TRAFFIC_TYPE_AFFECTED: 'isTrafficTypeAffected',
  AFFECTED_TYPE: 'affectedType',
  IS_EQUIPMENT_RESTARTED: 'isEquipmentRestarted',
  IS_LINK_TO_NETADMIN: 'isLinkToNetadmin',
  IS_LINK_IN_CUSTOMER_EQUIPMENT: 'isLinkInCustomerEquipment',
  ACTIVE_LAMPS: 'activeLamps',
  IP_ADDRESS: 'ipAddress',
  MAC_ADDRESS: 'MACAddress',
  BROKEN_POWER_ADAPTER_TYPE: 'brokenPowerAdapterType',
  IS_CORRECT_PORT_IN_BF: 'isCorrectPortInBF',
  CHARACTERISTICS: 'characteristic',
  WATCHLIST: 'watchlist',
  COMPLAINT: 'complaint',
  IS_VALID: 'isValid',
  IS_LOADING: 'isLoading',
  IS_ERROR: 'isError',
  CASE_ID: 'caseId',

  IS_TERMINAL_RESTARTED_UPDATED: 'isTerminalRestartedUpdated',
  IS_PROBLEM_IN_DIFFERENT_TERMINAL: 'isProblemInDifferentTerminal',
  NETWORK_PROVIDER: 'networkProvider',
  IS_SELECTING_PROVIDER_MANUALLY_TRIED: 'isSelectingProviderManuallyTried',
  COUNTRY: 'country',
  CITY: 'city',
  IS_ROAMING_ALLOWED_FOR_TERMINAL: 'isRoamingAllowedForTerminal',
  NETWORK_USED: 'networkUsed',
  APN_IN_USE: 'apnInUse',
  SPEED_TEST_RESULTS: 'speedTestResults',
  NUMBER_AFFECTED: 'numberAffected',
  ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM: 'areCustomersExperiencingSameProblem',
  AFFECTED_NUMBERS: 'affectedNumbers',
  NUMBERS_OF_AFFECTED_CUSTOMERS: 'numbersOfAffectedCustomers',
  PROBLEM_OCCURENCE_DATE: 'problemOccurenceDate',
  PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION: 'problemCustomerExperiencingDescription',
  STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM: 'stateExamplesWhenCustomerExperiencedProblem',
  WHAT_TROUBLESHOOTING_PERFORMED: 'whatTroubleshootingPerformed',
  ADRESS_WHERE_PROBLEM_HAPPENED: 'adressWhereProblemHappened',
  PROBLEM_WITH_SENDING_RECEIVING: 'problemWithSendingReceiving',
  INCIDENT_DESCRIPTION: 'incidentDescription',
  ERROR_MESSAGE: 'errorMessage',
  PORT_IN_OUT: 'portInOut',
  WHICH_SERVICE_EXPERIENCING_PROBLEM: 'whichServiceExperiencingProblem',
  IS_LTE_OR_WIFI_CALLING_ACTIVATED: 'isLteOrWifiCallingActivated',
  IS_PROBLEM_PERSIST_IF_TRAFFIC_CONTROL_IS_INACTIVATED: 'isProblemPersistIfTrafficControlIsInactivated',
  SELECTED_PROVIDER: 'selectedProvider',
  CONTACT: 'contact',
  IMEI: 'IMEI',
  REGISTRATION_NUMBER: 'registrationNumber',
  OWN_REFERENCE: 'ownReference'
}

export const FORM_DESCRIPTION_INPUT_LABELS = {
  [FORM_DESCRIPTION_PROPERTIES.ADDITIONAL_INFO]: i18n.t('fault-reporting.form.description.additional-information.label'),
  [FORM_DESCRIPTION_PROPERTIES.ENTRY_CODE]: i18n.t('fault-reporting.form.description.entrycode.label'),
  [FORM_DESCRIPTION_PROPERTIES.COMPLAINT]: i18n.t('fault-reporting.form.description.complaint.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_CIRCUIT_BEEN_USED]: i18n.t('fault-reporting.form.description.circuit-been-used.label'),
  [FORM_DESCRIPTION_PROPERTIES.LAST_CIRCUIT_OPERATIONAL_DATE]: i18n.t('fault-reporting.form.description.circuit-operational-date.label'),
  [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_NAME]: i18n.t('fault-reporting.form.description.local-contact-name.label'),
  [FORM_DESCRIPTION_PROPERTIES.LOCAL_CONTACT_PHONE]: i18n.t('fault-reporting.form.description.local-contact-phone.label'),
  [FORM_DESCRIPTION_PROPERTIES.PREMISES_NETWORK_CONSENT]: i18n.t('fault-reporting.form.description.premises-network-content.label'),
  [FORM_DESCRIPTION_PROPERTIES.HAS_TRAFFIC_WORKED_BEFORE]: i18n.t('fault-reporting.form.description.has-traffic-worked-before.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_CAUSED_BY_PORTING]: i18n.t('fault-reporting.form.description.is-caused-by-porting.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_BETWEEN_OPERATOR_AFFECTED]: i18n.t('fault-reporting.form.description.is-traffic-between-operator-affected.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_FOR_CUSTOMER_AFFECTED]: i18n.t('fault-reporting.form.description.is-traffic-for-customer-affected.label'),
  [FORM_DESCRIPTION_PROPERTIES.AFFECTED_CUSTOMER]: i18n.t('fault-reporting.form.description.affected-customer.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_TRAFFIC_TYPE_AFFECTED]: i18n.t('fault-reporting.form.description.is-traffic-type-affected.label'),
  [FORM_DESCRIPTION_PROPERTIES.AFFECTED_TYPE]: i18n.t('fault-reporting.form.description.affected-type.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_EQUIPMENT_RESTARTED]: i18n.t('fault-reporting.form.description.is-equipment-restarted.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_LINK_TO_NETADMIN]: i18n.t('fault-reporting.form.description.link-to-netadmin.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_LINK_IN_CUSTOMER_EQUIPMENT]: i18n.t('fault-reporting.form.description.link-in-customer-equipment.label'),
  [FORM_DESCRIPTION_PROPERTIES.ACTIVE_LAMPS]: i18n.t('fault-reporting.form.description.active-lamps.label'),
  [FORM_DESCRIPTION_PROPERTIES.IP_ADDRESS]: i18n.t('fault-reporting.form.description.ip-address.label'),
  [FORM_DESCRIPTION_PROPERTIES.MAC_ADDRESS]: i18n.t('fault-reporting.form.description.mac-address.label'),
  [FORM_DESCRIPTION_PROPERTIES.BROKEN_POWER_ADAPTER_TYPE]: i18n.t('fault-reporting.form.description.broken-power-adapter-type.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_CORRECT_PORT_IN_BF]: i18n.t('fault-reporting.form.description.is-correct-port-in-bf.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_TERMINAL_RESTARTED_UPDATED]: i18n.t('fault-reporting.form.description.is-terminal-restarted-updated.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_IN_DIFFERENT_TERMINAL]: i18n.t('fault-reporting.form.description.is-problem-in-different-terminal.label'),
  [FORM_DESCRIPTION_PROPERTIES.NETWORK_PROVIDER]: i18n.t('fault-reporting.form.description.network-provider.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_SELECTING_PROVIDER_MANUALLY_TRIED]: i18n.t('fault-reporting.form.description.is-selecting-provider-manually-tried.label'),
  [FORM_DESCRIPTION_PROPERTIES.COUNTRY]: i18n.t('fault-reporting.form.description.country.label'),
  [FORM_DESCRIPTION_PROPERTIES.CITY]: i18n.t('fault-reporting.form.description.city.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_ROAMING_ALLOWED_FOR_TERMINAL]: i18n.t('fault-reporting.form.description.is-roaming-allowed-for-terminal.label'),
  [FORM_DESCRIPTION_PROPERTIES.NETWORK_USED]: i18n.t('fault-reporting.form.description.network-used.label'),
  [FORM_DESCRIPTION_PROPERTIES.APN_IN_USE]: i18n.t('fault-reporting.form.description.apn-in-use.label'),
  [FORM_DESCRIPTION_PROPERTIES.SPEED_TEST_RESULTS]: i18n.t('fault-reporting.form.description.speed-test-results.label'),
  [FORM_DESCRIPTION_PROPERTIES.NUMBER_AFFECTED]: i18n.t('fault-reporting.form.description.number-affected.label'),
  [FORM_DESCRIPTION_PROPERTIES.ARE_CUSTOMERS_EXPERIENCING_SAME_PROBLEM]: i18n.t('fault-reporting.form.description.are-customers-experiencing-same-problem.label'),
  [FORM_DESCRIPTION_PROPERTIES.AFFECTED_NUMBERS]: i18n.t('fault-reporting.form.description.affected-numbers.label'),
  [FORM_DESCRIPTION_PROPERTIES.NUMBERS_OF_AFFECTED_CUSTOMERS]: i18n.t('fault-reporting.form.description.numbers-of-affected-customers.label'),
  [FORM_DESCRIPTION_PROPERTIES.PROBLEM_OCCURENCE_DATE]: i18n.t('fault-reporting.form.description.problem-occurence-date.label'),
  [FORM_DESCRIPTION_PROPERTIES.PROBLEM_CUSTOMER_EXPERIENCING_DESCRIPTION]: i18n.t('fault-reporting.form.description.problem-customers-experiencing-description.label'),
  [FORM_DESCRIPTION_PROPERTIES.STATE_EXAMPLES_WHEN_CUSTOMER_EXPERIENCED_PROBLEM]: i18n.t('fault-reporting.form.description.state-examples-when-customer-experienced-problem.label'),
  [FORM_DESCRIPTION_PROPERTIES.WHAT_TROUBLESHOOTING_PERFORMED]: i18n.t('fault-reporting.form.description.what-troubleshooting-performed.label'),
  [FORM_DESCRIPTION_PROPERTIES.ADRESS_WHERE_PROBLEM_HAPPENED]: i18n.t('fault-reporting.form.description.adress-where-problem-happened.label'),
  [FORM_DESCRIPTION_PROPERTIES.PROBLEM_WITH_SENDING_RECEIVING]: i18n.t('fault-reporting.form.description.problem-with-sending-receiving.label'),
  [FORM_DESCRIPTION_PROPERTIES.INCIDENT_DESCRIPTION]: i18n.t('fault-reporting.form.description.incident-description.label'),
  [FORM_DESCRIPTION_PROPERTIES.ERROR_MESSAGE]: i18n.t('fault-reporting.form.description.error-message.label'),
  [FORM_DESCRIPTION_PROPERTIES.PORT_IN_OUT]: i18n.t('fault-reporting.form.description.port-in-out.label'),
  [FORM_DESCRIPTION_PROPERTIES.WHICH_SERVICE_EXPERIENCING_PROBLEM]: i18n.t('fault-reporting.form.description.which-service-experiencing-problem.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_PROBLEM_PERSIST_IF_TRAFFIC_CONTROL_IS_INACTIVATED]: i18n.t('fault-reporting.form.description.is-problem-persist-if-traffic-control-is-inactivated.label'),
  [FORM_DESCRIPTION_PROPERTIES.IS_LTE_OR_WIFI_CALLING_ACTIVATED]: i18n.t('fault-reporting.form.description.is-lte-or-wifi-calling-activated.label'),
  [FORM_DESCRIPTION_PROPERTIES.SELECTED_PROVIDER]: i18n.t('fault-reporting.form.description.specify-selected-provider.label'),
  [FORM_DESCRIPTION_PROPERTIES.CONTACT]: i18n.t('fault-reporting.form.description.contact.label'),
  [FORM_DESCRIPTION_PROPERTIES.IMEI]: i18n.t('fault-reporting.form.description.IMEI.label'),
  [FORM_DESCRIPTION_PROPERTIES.REGISTRATION_NUMBER]: i18n.t('fault-reporting.form.description.registration-number.label'),
  [FORM_DESCRIPTION_PROPERTIES.OWN_REFERENCE]: i18n.t('fault-reporting.form.description.own-reference.label')
}

export const COMPLAINT_OPTIONS = [
  {
    text: i18n.t('fault-reporting.form.description.complaint.options.no'),
    value: 'No'
  },
  {
    text: i18n.t('fault-reporting.form.description.complaint.options.yes-delivery'),
    value: 'delivery'
  },
  {
    text: i18n.t('fault-reporting.form.description.complaint.options.yes-service'),
    value: 'service'
  }
]

export const PORT_IN_OUT_OPTIONS = [
  {
    text: i18n.t('fault-reporting.form.description.port-in-out.options.in'),
    value: i18n.t('fault-reporting.form.description.port-in-out.options.in')
  },
  {
    text: i18n.t('fault-reporting.form.description.port-in-out.options.out'),
    value: i18n.t('fault-reporting.form.description.port-in-out.options.out')
  }
]

export const PROBLEM_WITH_SENDING_RECEIVING_OPTIONS = [
  {
    text: i18n.t('fault-reporting.form.description.problem-with-sending-receiving.options.sending'),
    value: i18n.t('fault-reporting.form.description.problem-with-sending-receiving.options.sending')
  },
  {
    text: i18n.t('fault-reporting.form.description.problem-with-sending-receiving.options.receiving'),
    value: i18n.t('fault-reporting.form.description.problem-with-sending-receiving.options.receiving')
  },
  {
    text: i18n.t('fault-reporting.form.description.problem-with-sending-receiving.options.sending-and-receiving'),
    value: i18n.t('fault-reporting.form.description.problem-with-sending-receiving.options.sending-and-receiving')
  }
]

export const WOW_PRODUCT_CATEGORY_IDS = [
  'infracopperaccess',
  'infracopperptp',
  'infraestablishmentantenn',
  'infraestablishmentasnlkoppar',
  'infraestablishmentinpl',
  'infraestablishmentona',
  'infraestablishmentoptrunk',
  'infraestablishmentsamlok',
  'infraestablishmentspodf',
  'infraestablishmentspona',
  'infrafiberfibback',
  'infrafiberfl',
  'infrafiberfladress',
  'infrafiberfsmå',
  'infrafiberfsmfadress',
  'infrafiberfv',
  'infrafibergalv',
  'infrafiberlägmel',
  'infrafibers09',
  'infrafiberspsfs',
  'infrafibersptwfmu',
  'infrafibersptwfmud',
  'infrafibersptwfsu',
  'infrafibersptwftrans',
  'infrafibersptwftransm',
  'infrafibertwf'
]

export const CASEY_PRODUCT_CATEGORY_IDS = ['mobilemvno']

export const TOOLTIP_WIDTH = '500px'

export const MAX_STATE_EXAMPLES = 5
