import { render, staticRenderFns } from "./result-table.vue?vue&type=template&id=939588da&scoped=true"
import script from "./result-table.vue?vue&type=script&lang=js"
export * from "./result-table.vue?vue&type=script&lang=js"
import style0 from "./result-table.vue?vue&type=style&index=0&id=939588da&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "939588da",
  null
  
)

export default component.exports