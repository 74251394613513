<template>
  <div class="wholesale-fiber-product__container">
    <wholesale-fiber-product-side
      side="A"
      :title="address"
      :tooltip-content="tooltipContent"
      :point-data="parsedProductData" />
    <template v-if="isWholesaleFiberOutside">
      <InfraPageAlert
        type="info"
        class="mt-5 mb-5">
        {{ $t('products-display.wholesale-fiber-outside.product-view-text') }}
        <span class="purple-link">
          <a :href="getFragaBestallaLink()">
            {{ $t('products-display.wholesale-fiber-outside.quoteOrder') }}
          </a>
        </span>
      </InfraPageAlert>
    </template>

    <template v-else>
      <template v-if="offers.length !== 0">
        <div class="row mt-5">
          <div class="col-12">
            <span class="side-box side-box--orange">
              {{ $t('general.side') }} B
            </span>
          </div>
        </div>
        <div class="row my-5 px-3">
          <div
            v-if="parsedProductData.aNode"
            class="mb-3 px-2 col-6 col-md-4 col-lg-3 col-xl-2">
            <tws-selection-button
              v-model="selection"
              name="selection"
              value="aNode"
              type="radio">
              <div class="node__title">
                {{ formatNodeName(parsedProductData.aNode) }}
              </div>
              <div
                v-if="showDistanceParameter"
                class="node__subtitle">
                {{ formatDistance(parsedProductData.aNode.distanceToAddress) }}
              </div>
            </tws-selection-button>
          </div>
          <div
            v-if="parsedProductData.oNode"
            class="mb-3 px-2 col-6 col-md-4 col-lg-3 col-xl-2">
            <tws-selection-button
              v-model="selection"
              name="selection"
              value="oNode"
              type="radio">
              <div class="node__title">
                {{ formatNodeName(parsedProductData.oNode) }}
              </div>
              <div
                v-if="showDistanceParameter"
                class="node__subtitle">
                {{ formatDistance(parsedProductData.oNode.distanceToAddress) }}
              </div>
            </tws-selection-button>
          </div>
          <div
            class="mb-3 px-2 col-6 col-md-4 col-lg-3 col-xl-2">
            <tws-selection-button
              v-model="selection"
              name="selection"
              value="advanced"
              type="radio">
              <div class="node__title">
                {{ $t('address-search.buttons.advanced-search') }}
              </div>
              <div class="node__subtitle">
                {{ $t('products-display.advanced-wholesale-fiber.button-subtitle') }}
              </div>
            </tws-selection-button>
          </div>
        </div>
      </template>
      <InfraPageAlert
        v-else
        type="info">
        {{ $t('products-display.advanced-wholesale-fiber.no-offers') }}
      </InfraPageAlert>
      <div
        v-show="!advancedSearchSelected && !!selection"
        class="row my-5">
        <template>
          <div class="col-3">
            <span class="access-point-details__title">
              {{ $t('products-display.advanced-wholesale-fiber.title') }}
            </span>
          </div>
          <div class="col-lg-6 col-9">
            <ul class="offers">
              <li
                v-for="offer in offers"
                :key="offer.product"
                class="offer">
                <strong>{{ offer.product }}</strong> {{ formatDeliveryTime(offer.deliveryTime) }}
              </li>
            </ul>
          </div>
        </template>
      </div>
      <div
        v-show="advancedSearchSelected"
        class="row mb-5 justify-content-end">
        <div class="col-2">
          <tws-button-primary
            class="continue-button"
            @click="goToAdvancedSearch">
            {{ $t('general.buttons.move-on') }}
          </tws-button-primary>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  TwsButtonPrimary, InfraPageAlert
} from 'tws-vue-components'
import TwsSelectionButton from '../SelectionButton'
import WholesaleFiberProductSide from './wholesale-fiber-product-side'
import { SEARCH_MODE } from '@/apps/address-search/utils/constants.utils'
import parseProductData from '@/apps/address-search/utils/wholesale-fiber'
import URIUtils from '@/apps/address-search/utils/uri-utils'

const sortByProduct = (a, b) => {
  if (a.product.toLowerCase().includes('single')) {
    return -1
  }
  if (b.product.toLowerCase().includes('single')) {
    return 1
  }
  return a.product >= b.product ? 0 : -1
}

export default {
  name: 'WholesaleFiberProduct',
  components: {
    TwsSelectionButton,
    TwsButtonPrimary,
    WholesaleFiberProductSide,
    InfraPageAlert
  },
  props: {
    address: {
      type: String,
      required: true
    },
    tooltipContent: {
      type: Object,
      required: true
    },
    productData: {
      type: Object,
      default: () => {}
    },
    isWholesaleFiberOutside: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      selection: '',
      showDistanceParameter: false
    }
  },
  computed: {
    advancedSearchSelected () {
      return this.selection === 'advanced'
    },
    parsedProductData () {
      if (!this.productData) {
        return null
      }
      if (this.selection === 'aNode' || this.selection === 'oNode') {
        return parseProductData(this.productData, this.selection).pointA
      }

      return parseProductData(this.productData, this.productData.aNode ? 'aNode' : 'oNode').pointA
    },
    offers () {
      return [...this.parsedProductData.offers].sort(sortByProduct)
    }
  },
  methods: {
    ...mapActions('AddressSearch/AddressSearch', ['setSearchMode']),
    formatDistance (input) {
      const from = Math.round(input / 1000)

      return `${from}-${from + 1} km`
    },
    formatNodeName (input) {
      const { node, suffix } = input

      if (node) {
        return `${node}${suffix ? ` ${suffix}` : ''}`
      }

      return input.fqn
    },
    formatDeliveryTime (deliveryTime) {
      if (deliveryTime === -1) {
        return `(${this.$t('products-display.advanced-wholesale-fiber.delivery-time.manual-quote')})`
      }

      return `(${this.$t('products-display.advanced-wholesale-fiber.delivery-time.preliminary-delivery')} ${deliveryTime} ${this.$t('products-display.advanced-wholesale-fiber.delivery-time.working-days')})`
    },
    goToAdvancedSearch () {
      this.setSearchMode(SEARCH_MODE.ADDRESS_NODE)
    },
    getFragaBestallaLink () {
      return URIUtils.constructFragaBestallaLink()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.purple-link {
  text-decoration: underline;
  color: $core-purple;
}

.wholesale-fiber-product__container {
  padding: 22px 0;
}

.node {
  &__title {
    font-weight: bold;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
  }
}

.access-point-details__title {
  font-weight: bold;
}

.side-box {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 3px;
  color: $white;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.56px;
  text-transform: uppercase;

  &--purple {
    background-color: $core-purple;
  }

  &--orange {
    background-color: #DD8804;
  }
}

.address {
  font-weight: bold;
  text-transform: capitalize;
}

.continue-button {
  align-self: end;
}

.offers {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
