<template>
  <div :class="type">
    <div :class="type + '-title'">
      <template v-if="category.link">
        <tws-nav-link
          :href="category.link"
          :external="category.external">
          {{ title }}
        </tws-nav-link>

        <tws-new-window-icon v-if="category.external" />
        <tws-arrow-right-icon v-if="type === 'category' && !category.external" />
      </template>

      <span v-else>{{ title }}</span>
    </div>

    <slot />
  </div>
</template>

<script>
import { TwsNavLink } from 'tws-vue-components'
import { getTranslationValue } from '@/utils/translation.utils'
import TwsNewWindowIcon from '@/img/svg/new-window.svg'
import TwsArrowRightIcon from '@/img/svg/arrow-right.svg'

export default {
  components: {
    TwsNavLink,
    TwsNewWindowIcon,
    TwsArrowRightIcon
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: (value) => [
        'category',
        'sub-category'
      ].includes(value)
    }
  },
  computed: {
    title () {
      return getTranslationValue(this.category.title)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.category-title {
  font-weight: bold;
  padding-bottom: 10px;

  svg {
    margin-left: 8px;
    margin-bottom: -2px;
  }
}

.sub-category-title {
  padding-bottom: 5px;
}
</style>
