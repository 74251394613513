import TicketService from '@/apps/customer-tickets/service/ticket.service'
import { sortObjects } from '@/apps/customer-tickets/utils/sort.utils'
import {
  FILTER_KEYS,
  STATUSES
} from '@/apps/customer-tickets/utils/constants'
import UserUtils from '@/utils/user.utils'

export const SORTING_KEY = {
  LAST_UPDATED_DESC: 'LAST_UPDATED_DESC',
  LAST_UPDATED_ASC: 'LAST_UPDATED_ASC'
}

export default {
  namespaced: true,
  state: {
    tickets: [],
    loading: false,
    exception: false,
    currentPage: 1,
    sortBy: SORTING_KEY.LAST_UPDATED_DESC,
    userFilter: FILTER_KEYS.SELF,
    statusFilter: FILTER_KEYS.ALL,
    searchTerm: ''
  },
  mutations: {
    setTickets: (state, tickets) => state.tickets = tickets,
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setCurrentPage: (state, page) => state.currentPage = page,
    setSortBy: (state, sortBy) => state.sortBy = sortBy,
    sortTickets: (state) => {
      if (state.sortBy === SORTING_KEY.LAST_UPDATED_DESC) {
        state.tickets.sort((a, b) => {
          return sortObjects(b, a, 'lastUpdated')
        })
      }
      if (state.sortBy === SORTING_KEY.LAST_UPDATED_ASC) {
        state.tickets.sort((a, b) => {
          return sortObjects(a, b, 'lastUpdated')
        })
      }
      state.currentPage = 1
    },
    setUserFilter: (state, payload) => {
      state.userFilter = payload
    },
    setStatusFilter: (state, payload) => {
      state.statusFilter = payload
    },
    setSearchTerm: (state, payload) => {
      state.searchTerm = payload
    }
  },
  actions: {
    async findTickets ({ commit,
      dispatch }, query) {
      if (query && query.length < 3) {
        return
      } else if (query.length === 0) {
        dispatch('getTicketList', { root: true })
        return
      }

      commit('setLoading', true)
      commit('setException', false)

      try {
        const ticketsAll = await TicketService.getTicketList()
        const ticketsFiltered = await TicketService.filterTickets(ticketsAll,
          query)

        const ticketsResult = ticketsFiltered.length > 0 ? ticketsFiltered
          : await TicketService.findTicketsByQuery(query)

        commit('setTickets', ticketsResult)
        commit('setLoading', false)
      } catch {
        commit('setException', true)
        commit('setLoading', false)
      }
    },
    async getTicketList ({ commit }) {
      commit('setLoading', true)
      commit('setException', false)

      try {
        const tickets = await TicketService.getTicketList()
        commit('setTickets', tickets)
        commit('sortTickets')
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    changeCurrentPage ({ commit }, page) {
      commit('setCurrentPage', page)
    },
    changeSort ({ commit }, sortBy) {
      commit('setSortBy', sortBy)
      commit('sortTickets')
    },
    changeUserFilter ({ commit }, payload) {
      commit('setUserFilter', payload)
    },
    changeStatusFilter ({ commit }, payload) {
      commit('setStatusFilter', payload)
    },
    changeSearchTerm ({ commit }, payload) {
      commit('setSearchTerm', payload)
    }
  },
  getters: {
    filteredTickets: ({ tickets,
      userFilter,
      statusFilter }) => {
      if (!tickets.length) {
        return []
      }

      let filteredTickets = [...tickets]

      if (userFilter !== FILTER_KEYS.ALL) {
        filteredTickets = filteredTickets.filter(ticket => {
          return ticket.createdByUser
          && ticket.createdByUser.toLowerCase()
          === UserUtils.username().toLowerCase()
        })
      }

      if (statusFilter !== FILTER_KEYS.ALL) {
        switch (statusFilter) {
          case STATUSES.ONGOING:
            filteredTickets = filteredTickets.filter(ticket => {
              return ticket.status === STATUSES.ONGOING
                || ticket.status === STATUSES.UPDATED
            })
            break
          case STATUSES.CLOSED:
            filteredTickets = filteredTickets.filter(ticket => {
              return ticket.status === STATUSES.CLOSED
                || ticket.status === STATUSES.CANCELED
            })
            break
          default:
            filteredTickets = filteredTickets.filter(ticket => {
              return ticket.status === statusFilter
            })
        }
      }

      return filteredTickets
    }
  }
}
