import { getProducts } from '@/apps/address-search/services/products.service'
import LocalizationUtils from '@/utils/l10n.utils'
import { objectIsEmpty } from '@/apps/address-search/utils/object-utils'

import advancedProducts from './advanced-products.store'

export const ProductsModule = {
  namespaced: true,
  modules: {
    advancedProducts
  },
  state: {
    loading: false,
    loaded: false,
    exception: false,
    productList: null,
    productAddress: null
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    },
    setProductList (state, list) {
      state.productList = list
    },
    setProductAddress (state, address) {
      state.productAddress = address
    },
    toggleAccordion (state, accordionKey) {
      state.productList[accordionKey]['isExpanded'] = !state.productList[accordionKey]['isExpanded']
    },
    closeAllAccordions (state) {
      Object.keys(state.productList)
        .forEach((key) => {
          if (state.productList[key] !== null && typeof state.productList[key] === 'object') {
            state.productList[key]['isExpanded'] = false
          }
        })
    },
    openAllAccordions (state) {
      Object.keys(state.productList)
        .forEach((key) => {
          if (state.productList[key] !== null && typeof state.productList[key] === 'object') {
            state.productList[key]['isExpanded'] = true
          }
        })
    }
  },
  getters: {
    isLoading (state) {
      return state.loading
    },
    isLoaded (state) {
      return state.loaded
    },
    isException (state) {
      return state.exception
    },
    products (state) {
      return state.productList
    },
    productAddress (state) {
      return state.productAddress
    },
    isAllAccordionsOpen (state) {
      return Object.values(state.productList).reduce((acc, value) => acc && (value !== null && typeof value === 'object' ? value['isExpanded'] : true), true)
    },
    productsEmpty (state) {
      return objectIsEmpty(state.productList || {})
    }
  },
  actions: {
    fetchProducts ({ dispatch }, addressQuery) {
      dispatch('searchProducts', addressQuery)
    },
    searchProducts ({ commit }, addressQuery) {
      commit('setLoaded', false)
      commit('setLoading', true)
      commit('setException', false)

      return getProducts(addressQuery)
        .then(response => {
          Object.keys(response)
            .forEach((productName) => {
              if (response[productName] !== null && typeof response[productName] === 'object') {
                response[productName]['isExpanded'] = false
                if (productName === 'copperAccess' ||
                productName === 'fiberArea' ||
                productName === 'ipStreamEnhancedAccess' ||
                productName === 'phoneSubscription') {
                  response[productName].addressesInformation.forEach(address => {
                    address['orderable'] = LocalizationUtils.getYesNoByBool(address['orderable'])
                    address['vsdlInformation'] = LocalizationUtils.getVsdl(address['vsdlInformation'])
                  })
                }
                if (productName === 'fiberSingleAccess') {
                  response[productName].nearestAccessNodes.forEach(node => {
                    const isPriceIncluded = node['priceType']
                    node['price'] = LocalizationUtils.getYesNoByBool(!isPriceIncluded)
                    node['priceWithTransport'] = LocalizationUtils.getYesNoByBool(isPriceIncluded)
                  })
                }
                if (productName === 'fiberArea') {
                  const fiberStatus = response[productName].fiberStatus
                  response[productName].fiberStatus = LocalizationUtils.getFiberStatus(fiberStatus)
                }
              }
            })
          commit('setProductList', response)
          commit('setProductAddress', addressQuery)
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setLoaded', false)
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    resetState ({ commit }) {
      commit('setLoading', false)
      commit('setLoaded', false)
      commit('setException', false)
      commit('setProductList', null)
      commit('setProductAddress', null)
    },
    toggleAccordion ({ commit }, accordionKey) {
      commit('toggleAccordion', accordionKey)
    },
    openAllAccordions ({ commit }) {
      commit('openAllAccordions')
    },
    closeAllAccordions ({ commit }) {
      commit('closeAllAccordions')
    }
  }
}

export default ProductsModule
