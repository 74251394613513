import NewsService from '../services/news.service.js'

export const ContactUsNewsModule = {
  namespaced: true,
  state: {
    loading: true,
    exception: false,
    items: []
  },
  mutations: {
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setItems: (state, items) => state.items = items
  },
  actions: {
    async load ({ commit }) {
      try {
        commit('setItems', await NewsService.fetch())
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    }
  }
}
