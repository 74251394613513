<template>
  <router-link
    :to="getTo()"
    :exact="exact">
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: [
        String,
        Object
      ],
      required: true
    },
    exact: {
      type: Boolean
    }
  },
  methods: {
    getTo () {
      const locale = this.$route.params.locale

      if (typeof this.to !== 'string') {
        return {
          ...this.to,
          params: {
            ...this.to.params,
            locale
          }
        }
      }

      if (locale) {
        return `/${locale}/${this.to.replace(/^\/|\/$/g, '')}`
      }

      return this.to
    }
  }
}
</script>
