<template>
  <div
    class="confirmation-dialog"
    @click.self="$emit('cancel')">
    <div class="confirmation-dialog-container">
      <div
        :class="'text-' + headingAlignment"
        class="confirmation-dialog-heading">
        <h2 class="heading-m heading-m--white">
          {{ heading }}
        </h2>
      </div>

      <div class="confirmation-dialog-body">
        <slot name="body" />
      </div>

      <div class="confirmation-dialog-footer">
        <button
          type="button"
          class="button button--primary"
          @click="$emit('confirm')">
          {{ confirmationButtonText }}
        </button>

        <button
          type="button"
          class="button button--secondary"
          @click="$emit('cancel')">
          {{ cancelButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true
    },
    confirmationButtonText: {
      type: String,
      default: null
    },
    cancelButtonText: {
      type: String,
      default: null
    },
    headingAlignment: {
      type: String,
      default: 'center',
      validator: (val) => [
        'left',
        'center',
        'right'
      ].includes(val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.confirmation-dialog {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.confirmation-dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  margin: 0 auto;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
}

.confirmation-dialog-heading {
  background-color: $core-purple;
  padding: 35px 100px;
}

.confirmation-dialog-body {
  max-height: 500px;
  font-size: 16px;
  padding: 0 100px;
  margin: 50px 0;
  overflow-y: auto;
}

.confirmation-dialog-footer {
  padding: 0 100px 50px 100px;
}

.button--primary {
  margin-right: 10px;
}
</style>
