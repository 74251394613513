<template>
  <purpur-link
    :variant="variant"
    :href="href">
    <slot />
  </purpur-link>
</template>
<script>
import { Link } from '@purpurds/purpur'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  components: {
    'purpur-link': applyReactInVueWithOptions(Link)
  },
  props: {
    href: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: 'standalone'
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep div span {
  display: inline-flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
}

</style>
