<template>
  <div class="news-container">
    <div class="row">
      <div class="col-12">
        <h2 class="heading-m">
          {{ $t('news.latest-news') }}
        </h2>
      </div>
    </div>

    <InfraPageAlert
      v-if="loading"
      type="info">
      {{ $t('news.loading') }}
    </InfraPageAlert>

    <InfraPageAlert
      v-if="exception"
      type="danger">
      {{ $t('news.loading-failed') }}
    </InfraPageAlert>

    <template v-if="loaded">
      <InfraPageAlert
        v-if="news.length === 0"
        type="success">
        {{ $t('news.no-data') }}
      </InfraPageAlert>

      <template v-else>
        <div class="row">
          <div class="col">
            <tws-news-section-item
              v-for="(item, index) in news"
              :key="index"
              :news="item"
              :news-view-link="newsViewLink" />
          </div>
        </div>

        <div class="row news-archive-link-row">
          <div class="col-12">
            <tws-nav-link
              :href="newsListLink">
              {{ $t('news.more') }}
              <tws-arrow-right-light-icon />
            </tws-nav-link>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'
import NewsSectionItem from '@/components/news-section/news-section-item'
import TwsArrowRightLightIcon from '@/img/svg/arrow-right-light.svg'

import {
  InfraPageAlert,
  TwsNavLink
} from 'tws-vue-components'

export default {
  components: {
    'tws-news-section-item': NewsSectionItem,
    InfraPageAlert,
    TwsNavLink,
    TwsArrowRightLightIcon
  },
  props: {
    newsListLink: {
      type: String,
      required: true
    },
    newsViewLink: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'eamCookies/isLoggedIn'
    }),
    ...mapState('news', [
      'loading',
      'loaded',
      'exception',
      'news'
    ])
  },
  mounted () {
    this.loadPublicNews({
      size: 5,
      page: 1
    })
  },
  methods: {
    ...mapActions('news', [
      'loadPublicNews',
      'loadAllNews'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.heading-m {
  margin-bottom: 2rem;

  font-size: 24px;
  font-family: $teliaSans;
  font-weight: bold;
}

.news-archive-link-row {
  padding: 2rem 0;
}

.news-archive-link-row .tws-nav-link {
  float: right;
}
</style>
