<template>
  <div class="row">
    <div class="col-3">
      <tws-prefixed-validated-text-input-group
        id="asset-search-field"
        v-model="editableAsset.searchField"
        :validator="validator.searchField"
        :validation-messages="validationMessages"
        :label="$t('asset-search.search-field')"
        :placeholder="$t('asset-search.search-field-placeholder')"
        input-wrapper-class="prefix-wrapper"
        @enterKeyPressed="$emit('enterKeyPressed')" />
    </div>
  </div>
</template>

<script>
import { TwsPrefixedValidatedTextInputGroup } from 'tws-vue-components'

export default {
  components: {
    TwsPrefixedValidatedTextInputGroup
  },
  props: {
    asset: {
      type: Object,
      required: true
    },
    validator: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      searchField: '',
      editableAsset: Object.assign({}, this.asset),
      validationMessages: {
        required: this.$t('asset-search.validations.required'),
        numeric: this.$t('asset-search.validations.numeric'),
        minLength: this.$t('asset-search.validations.min-length'),
        maxLength: this.$t('asset-search.validations.max-length')
      }
    }
  },
  watch: {
    editableAsset: {
      handler (newValue) {
        this.$emit('assetChanged', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-control::v-deep .prefix-wrapper {
    display: flex;
  }

  .form-control::v-deep .prefix-wrapper:before {
    content: "FB-";
    margin-top: 12px;
    margin-right: 8px;
  }
</style>
