<template>
  <div class="document-links-container">
    <h2 class="document-links-heading">
      {{ heading }}
    </h2>

    <tws-loading-information
      :loading="productsWithAgreementLoading"
      :exception="productsWithAgreementException"
      exception-text="Det gick inte att visa produkterna just nu, försök igen senare" />

    <template v-if="productsWithAgreementLoaded">
      <div
        v-if="productsWithAgreement.length > 0"
        class="row document-categories">
        <div
          v-for="(category, index) in productsWithAgreement"
          :key="index"
          class="col-lg-12 col-md-4 col-sm-6 document-category">
          <h2 class="document-category__title">
            {{ getLocalizedTitle(category) }}
          </h2>

          <ul class="document-links">
            <li
              v-for="(subCategory, index2) in category.subCategories"
              :key="index2">
              <tws-nav-link
                :href="subCategory.link"
                class="document-link">
                {{ getLocalizedTitle(subCategory) }}
              </tws-nav-link>
            </li>
          </ul>
        </div>
      </div>

      <p v-else>
        Du hittar produkt och avtalsdokument under fliken "Dokument" på produktsidorna.
      </p>
    </template>
  </div>
</template>

<script>
import { isLocaleEnglish } from '@/utils/locale.utils'
import {
  TwsLoadingInformation,
  TwsNavLink
} from 'tws-vue-components'
import {
  mapActions,
  mapState
} from 'vuex'

export default {
  components: {
    TwsLoadingInformation,
    TwsNavLink
  },
  props: {
    heading: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('secureHeader', [
      'productsWithAgreement',
      'productsWithAgreementLoading',
      'productsWithAgreementLoaded',
      'productsWithAgreementException'
    ]),
    ...mapState('publicHeader', ['isContentfulLoading'])
  },
  watch: {
    isContentfulLoading (newVal) {
      if (!newVal) {
        this.fetchProductsWithAgreement()
      }
    }
  },
  mounted () {
    if (!this.isContentfulLoading) {
      this.fetchProductsWithAgreement()
    }
  },
  methods: {
    ...mapActions('secureHeader', ['fetchProductsWithAgreement']),
    getLocalizedTitle (item) {
      return isLocaleEnglish() && item.titleEn ? item.titleEn : item.title
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import "tws-core-atoms/atoms/typography.scss";

.document-links-container {
  background-color: $light-grey;
  padding: 35px 25px;

  @media (max-width: $breakpoint-lg) {
    margin-top: 55px;
  }
}

.document-links-heading {
  @extend .title-200;

  color: $black;
  overflow-wrap: break-word;
  margin: 0;
}

.document-links {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  li {
    margin-bottom: 5px;
  }
}

.document-category {
  &__title {
    @extend .title-100;

    overflow-wrap: break-word;
    margin-top: 25px;
    margin-bottom: 5px;
  }
}
</style>
