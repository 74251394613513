export const OFFER = {
  SINGLE_USER: 'single user',
  MULTI_USER: 'multi user',
  MULTI_USER_DUAL: 'multi user dual',
  TRANSPORT: 'transport'
}

const OFFER_PATTERN = {
  SINGLE_USER: /^single(.*)user$/i,
  MULTI_USER: /^multi(.*)user$/i,
  MULTI_USER_DUAL: /^multi(.*)user(.*)dual$/i,
  TRANSPORT: /^transport$/i
}

const mapProductToOfferEnum = product => {
  if (OFFER_PATTERN.SINGLE_USER.test(product)) {return OFFER.SINGLE_USER}
  if (OFFER_PATTERN.MULTI_USER.test(product)) {return OFFER.MULTI_USER}
  if (OFFER_PATTERN.MULTI_USER_DUAL.test(product)) {return OFFER.MULTI_USER_DUAL}
  if (OFFER_PATTERN.TRANSPORT.test(product)) {return OFFER.TRANSPORT}
  throw new Error('this shouldn\'t happen')
}

export const MANUAL_QUOTE = -1

export function WholesaleFiber (parsed) {
  const getCapacityFor = offer => {
    if (offer === OFFER.SINGLE_USER) {
      return parsed.getSingleFiberCapacity()
    }

    if (offer === OFFER.MULTI_USER) {
      return Math.max(parsed.getSingleFiberCapacity(), parsed.getFiberPairCapacity())
    }

    return parsed.getFiberPairCapacity()
  }

  const getDeliveryTimeFor = offer => {
    if (!parsed.isTransportWithinArea()) {
      return MANUAL_QUOTE
    }

    if (getCapacityFor(offer) > 0) {
      return parsed.getDeliveryTimeFast()
    }

    if (!parsed.isFixedPriceArea()) {
      return MANUAL_QUOTE
    }

    return parsed.getDeliveryTimeNormal()
  }

  const addDeliveryTime = product => ({
    ...product,
    deliveryTime: getDeliveryTimeFor(mapProductToOfferEnum(product.product))
  })

  return {
    getDeliveryTimeFor,
    get offers () {
      const rawOffers = [...parsed.getOffers()]
      const transportOffer = rawOffers.find(offer => offer && OFFER_PATTERN.TRANSPORT.test(offer.product))

      if (transportOffer) {
        return [addDeliveryTime(transportOffer)]
      }

      return rawOffers.map(addDeliveryTime)
    },
    get pointA () {
      return {
        ...parsed.pointA,
        offers: this.offers
      }
    },
    get pointB () { return parsed.pointB },
    get siteA () { return parsed.siteA },
    get siteB () { return parsed.siteB },
    get accessPoint () { return parsed.accessPoint },
    get aNode () { return parsed.aNode },
    get oNode () { return parsed.oNode }
  }
}
