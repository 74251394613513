import axios from 'axios'
import i18n from '@/i18n'

const fetch = async () => {
  const { data } = await axios.get(`${process.env.VUE_APP_CONTENTFUL_GATEWAY}/operational-news/actual`, { params: {
    locale: i18n.locale
  } } )
  return data
}

export default { fetch }
