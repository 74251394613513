<template>
  <div class="container ticket-create-container">
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <InfraSearchableDropdown
          id="caseType"
          v-model="dropdownValue.caseType"
          :translation-key.sync="form.caseType"
          :options="caseTypes"
          :validator="$v.form.caseType"
          :validation-messages="validationMessages.caseType"
          :label="formLabels.caseType"
          translate />
      </div>
      <div class="col-md-5 col-sm-12">
        <InfraSearchableDropdown
          id="product"
          v-model="dropdownValue.product"
          :translation-key.sync="form.product"
          :options="products"
          :validator="$v.form.product"
          :validation-messages="validationMessages.product"
          :label="formLabels.product"
          translate />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <TwsValidatedTextInputGroup
          id="subject"
          v-model="form.subject"
          :validator="$v.form.subject"
          :validation-messages="validationMessages.subject"
          :label="formLabels.subject"
          required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 col-sm-12">
        <TwsValidatedTextAreaGroup
          id="content"
          v-model="form.content"
          :validator="$v.form.content"
          :validation-messages="validationMessages.content"
          :label="formLabels.content" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2>{{ referenceText }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <TwsValidatedTextInputGroup
          id="customerReference"
          v-model="form.customerReference"
          :validator="$v.form.customerReference"
          :validation-messages="validationMessages.customerReference"
          :label="formLabels.customerReference" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <TwsValidatedTextInputGroup
          id="orderId"
          v-model="form.orderId"
          :validator="$v.form.orderId"
          :validation-messages="validationMessages.orderId"
          :label="formLabels.orderId" />
      </div>
      <div class="col-md-3 col-sm-12">
        <TwsValidatedTextInputGroup
          id="assetId"
          v-model="form.assetId"
          :validator="$v.form.assetId"
          :validation-messages="validationMessages.assetId"
          :label="formLabels.assetId" />
      </div>
      <div class="col-md-3 col-sm-12">
        <TwsValidatedTextInputGroup
          id="ttId"
          v-model="form.ttId"
          :validator="$v.form.ttId"
          :validation-messages="validationMessages.ttId"
          :label="formLabels.ttId" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2 class="tc-title tc-title--s">
          {{ addFileHeading }}
        </h2>
      </div>
    </div>
    <div
      class="row"
      :class="{ 'mt-3': form.files.length === 0}">
      <div class="col">
        <InfraFilePicker
          :add-file-text="addFileText"
          :value="form.files" />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <TwsCheckbox
          id="receiveEmailNotification"
          v-model="form.receiveEmailNotification"
          :label="formLabels.receiveEmailNotification"
          checked-value="true" />
      </div>
    </div>

    <div
      v-show="form.receiveEmailNotification"
      class="row pb-5">
      <div class="col-md-8 col-sm-12">
        <TwsValidatedTextInputGroup
          id="responseEmail"
          v-model="form.responseEmail"
          class="contact-email"
          :validator="$v.form.responseEmail"
          :validation-messages="validationMessages.responseEmail"
          :label="formLabels.responseEmail"
          required />
        <span class="contact-email-hint">{{ $t('ticket-create.contact-email-hint') }}</span>
      </div>
    </div>

    <div
      v-show="loading || exception"
      class="row mt-5">
      <div class="col-md-9 col-sm-12">
        <InfraPageAlert
          v-show="loading"
          type="info">
          {{ loadingMessage }}
        </InfraPageAlert>

        <InfraPageAlert
          v-show="exception"
          type="danger">
          {{ exceptionMessage }}
        </InfraPageAlert>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <button
          class="button button--primary mr-2"
          :class="{'button--disabled': loading}"
          @click="submit">
          {{ submitButtonText }}
        </button>
        <button
          v-if="showGoBackButton"
          class="button button--secondary"
          @click="$emit('goBack')">
          {{ goBackButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  InfraPageAlert,
  TwsCheckbox,
  TwsValidatedTextAreaGroup,
  TwsValidatedTextInputGroup,
  InfraFilePicker,
  InfraSearchableDropdown
} from 'tws-vue-components'

import { validationMixin } from 'vuelidate'
import UserUtils from '@/utils/user.utils'
import { ticketCreateFormValidations } from '../../utils/validation.utils'

export default {
  components: {
    InfraPageAlert,
    TwsCheckbox,
    TwsValidatedTextAreaGroup,
    TwsValidatedTextInputGroup,
    InfraFilePicker,
    InfraSearchableDropdown
  },
  mixins: [validationMixin],
  props: {
    formLabels: {
      type: Object,
      required: true
    },
    validationMessages: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    exception: {
      type: Boolean,
      default: false
    },
    products: {
      type: Object,
      required: true
    },
    caseTypes: {
      type: Array,
      required: true
    },
    addFileHeading: {
      type: String,
      default: 'Bifogade filer'
    },
    loadingMessage: {
      type: String,
      default: 'Laddar...'
    },
    exceptionMessage: {
      type: String,
      default: 'Misslyckades...'
    },
    submitButtonText: {
      type: String,
      default: 'Skapa'
    },
    goBackButtonText: {
      type: String,
      default: 'Avbryt'
    },
    referenceText: {
      type: String,
      default: 'Referens'
    },
    addFileText: {
      type: String,
      default: 'Bifoga en fil'
    },
    showGoBackButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dropdownValue: {
        caseType: null,
        product: null
      },
      form: {
        caseType: null,
        product: null,
        subject: null,
        content: null,
        customerReference: null,
        orderId: null,
        assetId: null,
        ttId: null,

        responseEmail: UserUtils.email(),
        receiveEmailNotification: false,

        files: []
      }
    }
  },
  validations: ticketCreateFormValidations,
  methods: {
    submit () {
      this.$v.form.$touch()

      if (!this.$v.form.$invalid) {
        this.$emit('submit', this.form)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ticket-create-container {
  margin-top: 4rem;
  margin-bottom: 10rem;
}

.contact-email {
  margin-bottom: 1rem;

  ::v-deep .form-control {
    margin-bottom: 1rem;
  }

  &-hint {
    color: var(--dark-grey);
  }

  &, &-hint {
    margin-left: 4rem;
  }
}
</style>
