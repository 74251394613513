import { STATES } from '@/apps/fault-reporting/constants/fault-report-case-list.contants'
import { fetchCaseList } from '@/apps/fault-reporting/services/teliaNOW.service'

export default {
  namespaced: true,
  state: {
    cases: [],
    isCasesShown: false,
    isAllCasesRequested: false,
    loading: false,
    exception: false,
    currentPage: 1,
    stateFilter: STATES.OPEN,
    searchTerm: ''
  },
  mutations: {
    setCases: (state, cases) => state.cases = cases,
    setIsCasesShown: (state, val) => state.isCasesShown = val,
    setIsAllCasesRequested: (state, val) => state.isAllCasesRequested = val,
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setCurrentPage: (state, page) => state.currentPage = page,
    setStateFilter: (state, val) => state.stateFilter = val,
    setSearchTerm: (state, payload) => state.searchTerm = payload
  },
  actions: {
    async fetchCaseList ({ commit }, payload) {
      commit('setLoading', true)
      commit('setException', false)

      try {
        const cases = await fetchCaseList(payload)

        commit('setCases', cases)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    changeCurrentPage ({ commit }, page) {
      commit('setCurrentPage', page)
    },
    changeStateFilter ({ commit }, payload) {
      commit('setStateFilter', payload)
    },
    changeSearchTerm ({ commit }, payload) {
      commit('setSearchTerm', payload)
    },
    changeIsCasesShown ({ commit }, val) {
      commit('setIsCasesShown', val)
    },
    changeIsAllCasesRequested ({ commit }, val) {
      commit('setIsAllCasesRequested', val)
    },
    resetCaseList ({ commit }) {
      commit('setCases', [])
      commit('setCurrentPage', 1)
      commit('setStateFilter', STATES.OPEN)
      commit('setSearchTerm', '')
    }
  },
  getters: {
    filteredCases: ({ cases, searchTerm, stateFilter }) => {
      const normalizedTerm = (searchTerm || '').toLowerCase()

      return cases.filter(item => (!stateFilter || stateFilter === STATES.ALL || item.status === stateFilter) && (
        !normalizedTerm
          || item.caseId.toLowerCase().includes(normalizedTerm)
          || item.assetName.toLowerCase().includes(normalizedTerm)
          || item.customerReference.toLowerCase().includes(normalizedTerm)
      ))
    }
  }
}
