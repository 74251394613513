import {
  fetchProductCategories,
  queryAssets
} from '@/apps/fault-reporting/services/c2b-cache.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    assetsQueryLoading: false,
    exception: false,
    assets: [],
    products: [],
    selectedAsset: null,
    selectedAssetViewValue: null,
    selectedProduct: null
  },
  mutations: {
    setLoading: (state, loading) => {
      state.loading = loading
    },
    setAssetsQueryLoading: (state, loading) => {
      state.assetsQueryLoading = loading
    },
    setException: (state, exception) => state.exception = exception,
    setAssets: (state, value) => state.assets = value,
    setSelectedAsset: (state, val) => state.selectedAsset = val,
    selectedAssetViewValue: (state, val) => state.selectedAssetViewValue = val,
    setSelectedProduct: (state, val) => state.selectedProduct = val,
    setProducts: (state, val) => state.products = val
  },
  getters: {
    products: (state) => {
      return state.products
    },
    selectedAsset: (state) => {
      return state.assets?.find(asset => state.SelectedAsset === asset.subscriptionid)
    },
    selectedProductEntity: (state) => {
      return state.selectedProduct || state.selectedAsset
    },
    tscId: (_state, getters) => {
      return getters.selectedProductEntity && getters.selectedProductEntity.tscid
    }
  },
  actions: {
    async queryAssets ({ commit }, query) {
      try {
        commit('setException', false)
        commit('setAssetsQueryLoading', true)

        const response = await queryAssets(query)

        commit('setAssets', response)
      } catch {
        //do nothing
      } finally {
        commit('setAssetsQueryLoading', false)
      }
    },
    async fetchProductsCategories ({ commit }) {
      try {
        commit('setException', false)
        commit('setLoading', true)

        const response = await fetchProductCategories()

        commit('setProducts', response)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    clearAssets ({ commit }) {
      commit('setAssets', [])
    },
    cancelQueryAssets ({ commit }) {
      commit('setAssetsQueryLoading', false)
    },
    updateSelectedAsset ({ commit }, val) {
      commit('setSelectedAsset', val)
    },
    updateSelectedAssetViewValue ({ commit }, val) {
      commit('selectedAssetViewValue', val)
    },
    updateSelectedProduct ({ commit }, val) {
      commit('setSelectedProduct', val)
    }
  }
}
