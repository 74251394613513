<template>
  <div class="row">
    <div class="col-8">
      <infra-page-alert
        class="faults-page-alert"
        type="info">
        {{ $t('components.faults-page-alert.text') }}
        <LocalisedLink
          class="link"
          :to="{ name: skanovaTicketListRoute }">
          {{ $t('components.faults-page-alert.link') }}
        </LocalisedLink>.
      </infra-page-alert>
    </div>
  </div>
</template>

<script>
import { InfraPageAlert } from 'tws-vue-components'
import { RouteName } from '@/apps/fault-reporting/router/routing-items'

export default {
  components: {
    InfraPageAlert
  },
  data () {
    return {
      skanovaTicketListRoute: RouteName.SkanovaTicketsList
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  @import "tws-core-atoms/variables.scss";
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
    color: $core-purple;
  }
}
</style>
