import { links } from '@/constants/header-contants'

export const mapProductEntries = entries => ({
  categories: entries
    .map(({ fields }) => ({
      categories: fields.products
        .filter(p => p.fields.showProductOnCategoryView === true)
        .map(({ fields: { title, path, showProductOnCategoryView, productId } }) => ({
          title,
          link: `${links.products}/${fields.path}/${path}`,
          showProductOnCategoryView,
          productId
        })),
      title: fields.title,
      link: `${links.products}/${fields.path}`,
      order: fields.order
    }))
    .sort((a, b) => a.order - b.order)
})

export const mapSimplePageEntries = entries => ({
  categories: entries
    .map(({ fields: { title, path, order } }) => ({
      title: title,
      link: path,
      order: order
    }))
    .sort((a, b) => a.order - b.order)
})

export const pathIncludesSolutions = i => i.fields.path.includes(links.solutions)
