import Vue from 'vue'

import {
  TcAccordion,
  TcAccordionItem,
  TcDatePicker,
  TcLink
} from '@telia/shared-ui-components'

Vue.component(TcDatePicker.name, TcDatePicker)
Vue.component(TcLink.name, TcLink)
Vue.component(TcAccordion.name, TcAccordion)
Vue.component(TcAccordionItem.name, TcAccordionItem)
