<template>
  <div class="specifications-view">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-12">
          <SpecificationAuthorization v-if="specificationLocked" />

          <template v-else>
            <Specification
              v-for="(item, index) in specification.content"
              :key="index"
              :content="item" />
          </template>
        </div>

        <div class="col-sm-12 col-md-4 order-md-last order-first">
          <SpecificationsMenu :items="specifications" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { isLoggedIn } from '@/utils/user.utils'

import {
  SpecificationAuthorization,
  Specification,
  SpecificationsMenu
} from '@/apps/products/components'

export default {
  components: {
    SpecificationAuthorization,
    Specification,
    SpecificationsMenu
  },
  computed: {
    ...mapState('Products/ContentModule', [
      'category',
      'product'
    ]),
    specifications () {
      return this.product.tabs.specifications.list
    },
    specification () {
      return this.specifications.find(item => item.path === this.$route.params.specification)
    },
    specificationLocked () {
      return this.specification && !this.specification.public && !isLoggedIn()
    }
  }
}
</script>
