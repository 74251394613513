<template>
  <div
    class="toggler"
    @click="updateExpanded()">
    <div>{{ expanded ? $t('common.hide') : $t('common.show-more') }}</div>
    <TeliaCoreIcon
      v-show="expanded"
      name="chevron_up_thin"
      size="16px" />
    <TeliaCoreIcon
      v-show="!expanded"
      name="chevron_down_thin"
      size="16px" />
  </div>
</template>

<script>
import TeliaCoreIcon from '@telia/core-icons'

export default {
  name: 'Toggler',
  components: {
    TeliaCoreIcon
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateExpanded () {
      this.$emit('update:expanded', !this.expanded)
    }
  }
}
</script>

<style lang="scss" scoped>
.toggler {
  display: flex;
  gap: 5px;
  align-items: center;

  margin-top: 30px;
  color: #990AE3;

  &:hover {
    cursor: pointer;
  }
}
</style>
