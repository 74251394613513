<template>
  <CommonPageView
    :banner="banner"
    :content="content"
    :breadcrumb-items="breadcrumbItems"
    :exception="exception"
    :loading="loading">
    <template #content>
      <MultifunctionalContent :content="content" />

      <div class="row">
        <div class="col-lg-12">
          <h3 class="new-fault-report__sub-title heading-m">
            {{ $t('fault-reporting.sub-heading') }}
          </h3>
        </div>
      </div>

      <NavLinkWrapper
        v-if="hasAnyWebtabRole()"
        class="mb-5"
        :href="`${hostOrigin}${navigationLinkWebtab}`"
        :text="$t('fault-reporting.navigation-link-webtab')" />

      <div class="row">
        <div class="new-fault-report__reference-search col-xl-5 col-sm-5 col-xs-12">
          <AutocompleteField
            id="ReferenceSearch"
            v-model="referenceNumberViewValue"
            :rounded="true"
            :has-external-value="true"
            :placeholder="$t('fault-reporting.reference-search-input.placeholder')"
            :background="'grey'"
            :options="assets"
            :tooltip-text="$t('fault-reporting.reference-search-input.tooltip')"
            :label-getter="referencesLabelGetter"
            :label="$t('fault-reporting.reference-search-input.label')"
            @select="handleRefNumberSelect"
            @input="handleSearchStringChange">
            <template #icon>
              <TeliaCoreIcon
                class="new-fault-report__search-icon"
                name="search_thin"
                size="2.7rem" />
            </template>
            <template #spinner>
              <TwsLoadingSpinner
                v-show="assetsQueryLoading"
                :small="true"
                class="loading-spinner" />
            </template>
          </AutocompleteField>
        </div>
      </div>

      <NavLinkWrapper
        v-if="externalLink"
        :href="externalLink"
        :text="$t('fault-reporting.navigation-link')" />

      <AssetInformation
        v-if="selectedAssetProxy"
        :title="selectedAssetProxy.offeringname | processText"
        :address="computedAddress"
        :company-name="selectedAssetProxy.subscribername | processText"
        @onNewFaultReportButtonClick="goToNewFaultReportForm()" />
    </template>
  </CommonPageView>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  mapState, mapActions
} from 'vuex'
import { TwsLoadingSpinner } from 'tws-vue-components'
import CommonPageView from '@/views/CommonPageView'
import MultifunctionalContent from '@/apps/fault-reporting/components/content/MultifunctionalContent'
import AutocompleteField from '@/apps/fault-reporting/components/input/AutocompleteField'
import AssetInformation from '@/apps/fault-reporting/components/AssetInformation'
import { RouteName } from '@/apps/fault-reporting/router/routing-items'
import {
  PRODUCT_CATEGORY_ITEMS,
  WOW_PRODUCT_CATEGORY_IDS,
  CASEY_PRODUCT_CATEGORY_IDS
} from '@/apps/fault-reporting/constants/fault-report-form.constants'
import { getTextValue } from '@/apps/fault-reporting/utils/string.utils'
import { hasAnyWebtabRole } from '@/apps/fault-reporting/utils/user.utils'
import NavLinkWrapper from '@/apps/fault-reporting/components/common/NavLinkWrapper'

export default {
  components: {
    NavLinkWrapper,
    AssetInformation,
    AutocompleteField,
    CommonPageView,
    MultifunctionalContent,
    TwsLoadingSpinner
  },
  filters: {
    processText: function (value) {
      return getTextValue(value)
    }
  },
  data () {
    return {
      referenceNumberViewValue: null,
      externalLink: null,
      navigationLinkWebtab: process.env.VUE_APP_WEBTAB_PATH,
      hostOrigin: window.location.origin
    }
  },
  computed: {
    ...mapState('FaultReporting/NewFaultReportContent', [
      'loading',
      'exception',
      'content'
    ]),
    ...mapState('FaultReporting/C2bCache', {
      assets: 'assets',
      selectedAsset: 'selectedAsset',
      assetsQueryLoading: 'assetsQueryLoading'
    }),
    selectedAssetProxy: {
      get () {
        return this.selectedAsset
      },
      set (value) {
        if (value) {
          this.handleSearchStringChange(null)
        }
      }
    },
    computedAddress () {
      return `${getTextValue(this.selectedAssetProxy.subscriptionadress)}, ${getTextValue(this.selectedAssetProxy.subscriptioncity)}, ${getTextValue(this.selectedAssetProxy.subscriptioncountry)}`
    },
    queryAssetsDebounced () {
      return debounce(function (input) {
        this.externalLink = null
        const inputLowerCase = input.toLowerCase()

        if (inputLowerCase.startsWith('z') || inputLowerCase.startsWith('tt')) {
          this.externalLink = process.env.VUE_APP_ZITIUS_PORTAL_PATH
        } else {
          this.queryAssets(input)
        }
      }, 300)
    },
    banner () {
      return {
        heading: this.content?.fields.pageBanner.fields.heading,
        description: this.content?.fields.pageBanner.fields.description
      }
    },
    breadcrumbItems () {
      return this.content?.breadcrumbItems
    }
  },
  created () {
    this.loadContent()
  },
  mounted () {
    this.updateSelectedAsset(null)
  },
  methods: {
    ...mapActions('FaultReporting/NewFaultReportContent', ['loadContent']),
    ...mapActions('FaultReporting/FormGlobal', ['resetGlobalFormsState']),
    ...mapActions('FaultReporting/C2bCache', [
      'queryAssets',
      'fetchProductsCategories',
      'queryAssets',
      'updateSelectedProduct',
      'updateSelectedAsset',
      'clearAssets',
      'cancelQueryAssets'
    ]),
    referencesLabelGetter (val) {
      return val && val.friendlyname ? val.friendlyname : '-'
    },
    handleSearchStringChange (value) {
      this.clearAssets()
      this.updateSelectedAsset(null)

      if (value && value.length > 2) {
        this.queryAssetsDebounced(value)
      } else {
        this.cancelQueryAssets()
        this.queryAssetsDebounced.cancel()
      }
    },
    handleRefNumberSelect (val) {
      this.updateSelectedAsset(val)

      if (val) {
        this.referenceNumberViewValue = val['friendlyname']
      }
    },
    goToNewFaultReportForm () {
      this.resetGlobalFormsState()

      if (PRODUCT_CATEGORY_ITEMS[this.selectedAsset.categoryid]) {
        this.updateSelectedProduct({
          categoryId: this.selectedAsset.categoryid,
          categoryName: this.selectedAsset.categoryname,
          tscid: this.selectedAsset.tscid
        })

        this.$router.push({
          name: RouteName.NewFaultReportForm,
          locale: this.$route.params.locale
        })
      } else if (WOW_PRODUCT_CATEGORY_IDS.includes(this.selectedAsset.categoryid)) {
        this.$router.push({
          name: RouteName.SkanovaTicketCreate,
          params: this.$route.params
        })
      } else if (CASEY_PRODUCT_CATEGORY_IDS.includes(this.selectedAsset.categoryid)) {
        this.$router.push(process.env.VUE_APP_IFRAME_TICKETS_MOBILE_WHOLESALE_PRODUCTS)
      } else {
        this.$router.push(process.env.VUE_APP_OPERATOR_PORTAL_PATH)
      }
    },
    hasAnyWebtabRole
  }
}
</script>
<style lang="scss" scoped>
@import "@/main.scss";

.loading-spinner {
  position: absolute;
  left: 90%;
  top: 67%;
}
.new-fault-report {
  &__search-icon {
    position: absolute;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  &__sub-title {
    margin: 1em 0;
  }
}

</style>
