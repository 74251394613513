const ArrayUtils = {

  // Chunks an 'array' into arrays with 'size' elements. The last chunk may contain less than 'size' elements.
  chunk: (array, size) => {
    if (size < 1) {
      throw new RangeError('Size must be a positive integer')
    }

    const chunkedArray = []
    let index = 0
    while (index < array.length) {
      chunkedArray.push(array.slice(index, size + index))
      index += size
    }
    return chunkedArray
  }

}

export default ArrayUtils
