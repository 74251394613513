<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h2 class="heading-m search-result-header">
          {{ header }}
        </h2>
      </div>
    </div>

    <div class="row">
      <!-- 1 column -->
      <div class="col-6">
        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.coverage-area') }}
          </div>
          <div class="col">
            {{ assets.coverageArea }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.line-length') }}
          </div>
          <div class="col">
            {{ assets.lineLength }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.cabinet') }}
          </div>
          <div class="col">
            {{ assets.cabinet }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.shared-broadband') }}
          </div>
          <div class="col">
            {{ $t(localizeYesNo(assets.copperBroadbandAccess)) }}
          </div>
        </div>

        <div
          class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.vdsl') }}
          </div>

          <div class="col">
            {{ assets.vsdl === VsdlExist ? $t('common.yes') : (assets.vsdl === NoVsdl ? $t('phone-search.network-control'): '') }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.trunced-pair') }}
          </div>

          <div class="col">
            {{ $t(localizeYesNo(assets.truncedPair)) }}
          </div>
        </div>
      </div>
      <!-- 2 column -->
      <div class="col-6">
        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.street') }}
          </div>

          <div class="col">
            <tws-nav-link :href="addressSearch">
              {{ assets.streetName | capitalizeFirstLetter }} {{ assets.streetNumber }}{{ assets.entrance }}
            </tws-nav-link>
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.post-code') }}
          </div>

          <div class="col">
            <tws-nav-link :href="addressSearch">
              {{ assets.city | capitalizeFirstLetter }}
            </tws-nav-link>
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.unit-number') }}
          </div>

          <div class="col">
            {{ assets.layerUnitNumber }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.stairs') }}
          </div>

          <div class="col">
            {{ assets.numberOfStairs }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.tvth') }}
          </div>

          <div class="col">
            {{ assets.tvth }}
          </div>
        </div>

        <div class="row asset-row">
          <div class="col bold">
            {{ $t('phone-search.comment') }}
          </div>

          <div class="col">
            {{ assets.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalizationUtils from '@/utils/l10n.utils'
import { capitalizeFirstLetter } from '@/utils/string.utils'
import { TwsNavLink } from 'tws-vue-components'

export default {
  components: {
    TwsNavLink
  },
  filters: {
    capitalizeFirstLetter
  },
  props: {
    assets: {
      type: Object,
      required: true
    },
    header: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      VsdlExist: 'N',
      NoVsdl: 'Na'
    }
  },
  computed: {
    addressSearch () {
      return process.env.VUE_APP_ADDRESS_SEARCH_PATH + '?streetName=' + this.assets.streetName +
        '&streetNumber=' + this.assets.streetNumber + '&entrance=' + this.assets.entrance +
        '&city=' + this.assets.city
    }
  },
  methods: {
    localizeYesNo (value) {
      return LocalizationUtils.getYesNo(value)
    }
  }
}
</script>

<style lang="scss" scoped>

  .asset-row {
    padding-top: 5px;
  }

  .search-result-header {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .bold {
    font-weight: bold;
  }

</style>
