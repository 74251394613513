import { createInstance } from '@/services/axios.api'
export const api = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE)

const getProperties = async ({ municipality, district, block, unit }) => {
  return await api.get(
    `${process.env.VUE_APP_PRODUCT_SEARCH_URL}/real/estate/search`, {
      params: {
        municipality,
        district,
        block,
        unit
      }
    })
}

export default { getProperties }
