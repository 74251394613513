import { createInstance } from '@/services/axios.api'
export const api = createInstance(process.env.VUE_APP_C2B_CACHE_SERVICE)

export const queryAssets = async query => {
  return await api.post(
    '/v1/assets',
    { query: query.toUpperCase() })
}

export const fetchAssetsByCategoryId = async id => {
  return await api.post(
    '/v1/assets',
    { category: id })
}

export const fetchProductCategories = async () => {
  return await api.post(
    '/v1/categories')
}
