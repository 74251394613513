<template>
  <div class="login-container">
    <div class="login-form-background">
      <div class="container login-form-container">
        <div class="row">
          <div class="col col-12">
            <div class="welcome-text display-200">
              {{ $t('login.welcome1') }}<br>
              {{ $t('login.welcome2') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container access-container">
      <div
        v-if="redirectAction"
        class="row redirect-message-row">
        <div class="col-12">
          <InfraPageAlert
            type="success">
            {{ redirectMessage }}
          </InfraPageAlert>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <h1 class="title-200">
            {{ $t('login.info.heading') }}
          </h1>

          <p>
            {{ $t('login.info.paragraph-1') }}
          </p>

          <p>
            {{ $t('login.info.paragraph-2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { InfraPageAlert } from 'tws-vue-components'
import { SESSION_KEY_RETURN_URI } from '@/common/auth-constants'

export default {
  components: {
    InfraPageAlert
  },
  data () {
    return {
      loginMethod: 'password',
      loginMethods: [
        {
          text: this.$t('login.methods.password'),
          value: 'password'
        },
        {
          text: this.$t('login.methods.one-time-code'),
          value: 'one-time-code'
        }
      ],
      returnUri: this.$route.query.ct_orig_uri,
      returnUriSessionStorage: sessionStorage.getItem(SESSION_KEY_RETURN_URI),
      errorCode: this.$route.query.dMessage,
      redirectAction: this.$route.query.action
    }
  },
  computed: {
    ...mapState('login', ['oneTimeCodeSent']),
    redirectMessage () {
      if (this.redirectAction === 'logout') {
        return this.$t('login.messages.logout')
      } else if (this.redirectAction === 'requestResetPassword') {
        return this.$t('login.messages.reset-password')
      }
      return null
    },
    errorMessage () {
      if (!this.errorCode || this.loginMethod === 'one-time-code') {
        return null
      }

      switch (this.errorCode) {
        case '3':
          return this.$t('login.errors.invalid-credentials')
        case '31':
          return this.$t('login.errors.account-locked')
        case '32':
          return this.$t('login.errors.password-expired')
        case '33':
          return this.$t('login.errors.unable-to-login')
        case 'GA3141':
          return this.$t('login.errors.password-changed')
        case 'sysUserLoginError':
          return this.$t('login.errors.system-account')
        default:
          return this.$t('login.errors.default')
      }
    }
  },
  created () {
    if (!this.returnUriSessionStorage && !this.returnUri && !this.errorCode) {
      sessionStorage.setItem(SESSION_KEY_RETURN_URI, process.env.VUE_APP_DASHBOARD_PATH)
    } else if (this.returnUri) {
      sessionStorage.setItem(SESSION_KEY_RETURN_URI, this.returnUri)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.login-form-background {
  background-color: #29003e;
  background-size: cover;
  background-position: center;
}

.login-form-container {
  .heading-l {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

.login-form-column {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: $white;
    border-top: 2px solid $core-purple;
  }

.welcome-text {
  color: $light-purple;
  font-size: 50px;
  margin: 180px auto 60px;
}

.access-container {
  margin-top: 40px;
}

.redirect-message-row {
  margin-bottom: 35px;
}

.infra-page-alert--danger {
  margin-top: 25px;
}
</style>
