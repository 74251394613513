import { render, staticRenderFns } from "./invoices-expandable-row.vue?vue&type=template&id=196925fc&scoped=true"
import script from "./invoices-expandable-row.vue?vue&type=script&lang=js"
export * from "./invoices-expandable-row.vue?vue&type=script&lang=js"
import style0 from "./invoices-expandable-row.vue?vue&type=style&index=0&id=196925fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "196925fc",
  null
  
)

export default component.exports