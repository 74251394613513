<template>
  <div
    class="information-dialog"
    @click.self="$emit('close')">
    <div
      class="information-dialog-container">
      <div
        :class="'text-' + headingAlignment"
        class="information-dialog-header">
        <h2 class="heading-m heading-m--white">
          {{ header }}
        </h2>

        <span v-if="showCloseButton">
          <tws-close-icon
            @click="$emit('close')" />
        </span>
      </div>

      <div class="information-dialog-body">
        <slot name="body">
          {{ text }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    headingAlignment: {
      type: String,
      default: 'center',
      validator: (val) => [
        'left',
        'center',
        'right'
      ].includes(val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.information-dialog {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;

  @media(max-width: $breakpoint-md) {
    background-color: $white;
  }

  @media(min-width: $breakpoint-md) {
    background-color: rgba(0, 0, 0, .5);
  }
}

.information-dialog-container {
  background-color: $white;

  @media(max-width: $breakpoint-md) {
    width: 100%;
    margin: 0;
    padding: 0 0 35px;
  }

  @media(min-width: $breakpoint-md) {
    margin: 50px auto;
    width: 800px;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  }
}

.information-dialog-header {
  display: flex;
  justify-content: space-between;
  background-color: $core-purple;

  @media(max-width: $breakpoint-md) {
    padding: 35px;
  }

  @media (min-width: $breakpoint-md) {
    padding: 30px 30px 30px 100px;
  }

  svg {
    path {
      &:first-of-type {
        fill: $core-purple;
      }

      &:last-of-type {
        fill: $white;
      }
    }

    cursor: pointer;
  }
}

.information-dialog-body {
  position: relative;
  font-size: 16px;

  @media(max-width: $breakpoint-md) {
    height: 100%;
    padding: 35px;
  }

  @media(min-width: $breakpoint-md) {
    padding: 25px 100px 50px;
  }
}
</style>
