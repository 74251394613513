export default {
  namespaced: true,
  state: {
    emailInputList: [{ value: '' }]
  },
  getters: {
    watchlist: (state) => {
      const emailStringArray = []

      state.emailInputList.forEach(item => {
        if (item.value) {
          emailStringArray.push(item.value)
        }
      })

      return emailStringArray
    }
  },
  mutations: {
    setEmailInputList: (state, val) => state.emailInputList = val
  },
  actions: {
    updateEmailInputList ({ commit }, val) {
      commit('setEmailInputList', val)
    },
    resetState ({ commit }) {
      commit('setEmailInputList', [{ value: '' }])
    }
  }
}
