import { getAddresses } from '@/apps/address-search/services/address-search.service'
import { SEARCH_MODE } from '@/apps/address-search/utils/constants.utils'

const addressSorter = (a, b) => {
  const streetNumberA = Number(a.streetNumber)
  const streetNumberB = Number(b.streetNumber)

  if (streetNumberA !== streetNumberB) { return streetNumberA - streetNumberB }

  if (a.entrance > b.entrance) { return 1 }
  if (a.entrance < b.entrance) { return -1 }

  return 0
}

export default {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    error: false,
    exceptionA: null,
    exceptionB: null,
    searchMode: SEARCH_MODE.ADDRESS,
    addressesA: [],
    addressesB: [],
    addressQueryA: {
      streetName: '',
      streetNumber: '',
      entrance: '',
      city: ''
    },
    addressQueryB: {
      streetName: '',
      streetNumber: '',
      entrance: '',
      city: ''
    },
    nodeQueryA: {
      node: '',
      kc: ''
    },
    nodeQueryB: {
      node: '',
      kc: ''
    }
  },
  mutations: {
    loading (state, loading) {
      state.loading = loading
    },
    loaded (state, loaded) {
      state.loaded = loaded
    },
    error (state, error) {
      state.error = error
    },
    exception (state, exception) {
      state.exceptionA = exception[0] || null
      state.exceptionB = exception[1] || null
    },
    searchMode (state, searchMode) {
      state.searchMode = searchMode
    },
    addresses (state, { value, point }) {
      state[`addresses${point.toUpperCase()}`] = value
    },
    streetName (state, { value, point }) {
      state[`addressQuery${point.toUpperCase()}`].streetName = value
    },
    streetNumber (state, { value, point }) {
      state[`addressQuery${point.toUpperCase()}`].streetNumber = value
    },
    entrance (state, { value, point }) {
      state[`addressQuery${point.toUpperCase()}`].entrance = value
    },
    city (state, { value, point }) {
      state[`addressQuery${point.toUpperCase()}`].city = value
    },
    node (state, { value, point }) {
      state[`nodeQuery${point.toUpperCase()}`].node = value
    },
    kc (state, { value, point }) {
      state[`nodeQuery${point.toUpperCase()}`].kc = value
    }
  },
  getters: {
    loading: state => state.loading,
    loaded: state => state.loaded,
    error: state => state.error,
    exceptionA: state => state.exceptionA,
    exceptionB: state => state.exceptionB,
    sortedAddresses: state => ({
      sortedAddressesA: [...state.addressesA].sort(addressSorter),
      sortedAddressesB: [...state.addressesB].sort(addressSorter)
    }),
    searchMode: state => state.searchMode,
    addressQueryA: state => state.addressQueryA,
    addressQueryB: state => state.addressQueryB,
    nodeQueryA: state => state.nodeQueryA,
    nodeQueryB: state => state.nodeQueryB
  },
  actions: {
    async fetchAddresses ({ commit, dispatch, getters }) {
      dispatch('AddressSearch/clearProducts', null, { root: true })
      commit('error', false)
      commit('exception', [])
      commit('loaded', false)
      commit('loading', true)

      const queries = [getters.addressQueryA]
      if (getters.searchMode === SEARCH_MODE.ADDRESS_ADDRESS) {
        queries.push(getters.addressQueryB)
      }

      try {
        const [
          addressesA,
          addressesB
        ] = await getAddresses(queries)

        commit('addresses', {
          value: addressesA,
          point: 'A'
        })
        commit('addresses', {
          value: addressesB || [],
          point: 'B'
        })
        commit('loaded', true)
      } catch (err) {
        if (err.response && err.response.status === 400) {
          commit('exception', err.response.data.errors)
        } else {
          commit('error', true)
        }
      } finally {
        commit('loading', false)
      }
    },
    setAddressQuery ({ commit }, { key, value, point }) {
      commit(key, {
        value,
        point
      })
    },
    setNodeQuery ({ commit }, { key, value, point }) {
      commit(key, {
        value,
        point
      })
    },
    setSelectedAddress ({ commit }, { address, point }) {
      commit('addresses', {
        value: [address],
        point
      })
      commit('streetName', {
        value: address.streetName,
        point
      })
      commit('streetNumber', {
        value: address.streetNumber,
        point
      })
      commit('entrance', {
        value: address.entrance,
        point
      })
      commit('city', {
        value: address.city,
        point
      })
    },
    setSearchMode ({ commit }, searchMode) {
      commit('searchMode', searchMode)
    }
  }
}
