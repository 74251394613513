<template>
  <div
    v-if="isValidatorCreated && getValidatorFormField(labels.field_name)"
    :style="widthStyle"
    @click="showDependencyMessage">
    <div v-if="labels.is_read_only && !informationException && getUsername() !== undefined">
      <p>
        <span class="area-text-prefix-font">{{ labels.read_only_text }} </span>
        {{ model }}
      </p>
    </div>
    <tws-validated-text-input-group
      v-else
      :id="labels.field_name"
      v-model="model"
      :name="labels.field_name"
      :label="labels.field_label"
      :placeholder="labels.field_placeholder"
      :tooltip-text="labels.tooltip_text"
      :tooltip-width="tooltipWidth"
      :validator="getValidatorFormField(labels.field_name)"
      :validation-messages="validationMessages"
      :disabled="disabledField(labels.field_name)" />
    <p
      v-show="showFieldMessage"
      class="dependency-text">
      {{ dependencyMessage }}
    </p>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState
} from 'vuex'

import { TwsValidatedTextInputGroup } from 'tws-vue-components'

import { getUsername } from '@/utils/user.utils'

export default {
  name: 'TwsFormInputField',
  components: {
    TwsValidatedTextInputGroup
  },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        required: this.labels.validation_message,
        email: 'Detta ser inte ut som en e-postadress',
        phone: 'Skriv telefonnummer med landskod (dvs +46...)'
      },
      showFieldMessage: false
    }
  },
  computed: {
    ...mapState('DynamicForm', ['formFields']),
    ...mapState('DynamicForm/UserDetails', [
      'information',
      'informationException'
    ]),
    ...mapGetters('DynamicForm',
      [
        'getValidatorFormField',
        'isValidatorCreated',
        'disabledField',
        'getFieldDependencyMessage',
        'getFieldValue'
      ]
    ),
    model: {
      get () {
        return this.getFieldValue(this.labels.field_name)
      },
      set (value) {
        this.onValueInput(value)
      }
    },
    tooltipWidth () {
      return this.labels.tooltip_width.concat('px')
    },
    dependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        return this.getFieldDependencyMessage(this.labels.field_name)
      }
      return ''

    },
    widthStyle () {
      const componentWidth = this.labels.component_width
      return componentWidth ? 'width: ' + componentWidth + '%;' : ''
    }
  },
  watch: {
    information: {
      handler (newValue) {
        if (newValue) {
          switch (this.labels.prefilledField) {
            case 'USERNAME':
              this.onValueInput(this.information.username)
              break
            case 'EMAIL':
              this.onValueInput(this.information.email)
              break
            case 'COMPANY_NAME':
              this.onValueInput(this.information.company.name)
              break
            case 'FULL_NAME':
              this.onValueInput(this.information.firstName + ' ' + this.information.lastName)
              break
            case 'COMPANY_CODE':
              this.onValueInput(this.information.company.code)
              break
            case 'PHONE_NUMBER':
              this.onValueInput(this.information.mobilePhone)
              break
            default:
              this.onValueInput('')
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    this.addValidation(
      {
        fieldName: this.labels.field_name,
        validations: this.labels.validations
      }
    )
    this.fieldCreated(
      {
        fieldName: this.labels.field_name,
        label: this.labels.field_label,
        templateText: this.labels.template_text,
        isFieldRequired: this.labels.is_field_required,
        prefilledField: this.labels.prefilledField
      }
    )
  },
  methods: {
    ...mapActions('DynamicForm', [
      'fieldCreated',
      'fieldValueEntered',
      'addValidation'
    ]),
    onValueInput (value) {
      this.fieldValueEntered(
        {
          key: this.labels.field_name,
          value: value
        }
      )
    },
    showDependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        this.showFieldMessage = !this.showFieldMessage
      }
    },
    getUsername: getUsername
  }
}
</script>

<style lang="scss" scoped>
  .dependency-text {
    color: grey;
    font-size: medium;
  }

  .area-text-prefix-font {
    font-weight: bold;
  }
</style>
