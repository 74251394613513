<template>
  <div class="view">
    <InfraModalHeader
      class="header"
      :title="title"
      :can-go-back="canGoBack"
      @go-back="$emit('go-back')"
      @close="$emit('close')" />

    <slot />

    <div
      v-if="displayWarning || displayDanger"
      class="alert">
      <InfraPageAlert
        v-if="displayWarning"
        class="property-search-alert"
        type="warning">
        {{ warningText }}
      </InfraPageAlert>

      <InfraPageAlert
        v-else
        class="property-search-alert"
        type="danger">
        {{ dangerText }}
      </InfraPageAlert>
    </div>
  </div>
</template>

<script>
import {
  InfraModalHeader,
  InfraPageAlert
} from 'tws-vue-components'

export default {
  components: {
    InfraModalHeader,
    InfraPageAlert
  },
  props: {
    title: {
      type: String,
      required: true
    },
    canGoBack: {
      type: Boolean,
      default: false
    },
    displayWarning: {
      type: Boolean,
      default: false
    },
    warningText: {
      type: String,
      default: null
    },
    displayDanger: {
      type: Boolean,
      default: false
    },
    dangerText: {
      type: String,
      default: null
    }
  },
  mounted () {
    document.getElementsByClassName('modal-container')[0]?.scroll({ top: 0 })
  }
}
</script>

<style lang="scss" scoped>
.view {
  padding: 0 30px;
}

.header {
  padding: 30px 0 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.alert {
  padding: 20px 0 30px;
  position: sticky;
  bottom: 1px; // fixes scroll issues compared to bottom: 0
  z-index: 1;
  background-color: #fff;
}
</style>
