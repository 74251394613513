<template>
  <CommonPageView
    class="tws-asset-search"
    :breadcrumb-items="breadcrumbItems"
    :loading="false"
    :banner="banner">
    <template #content>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <tws-asset-search-form
              :asset="asset"
              :validator="$v.asset"
              @assetChanged="onAssetChange"
              @enterKeyPressed="onSubmit" />
          </div>
        </div>

        <tws-button-primary
          @click="onSubmit">
          {{ $t('common.search') }}
        </tws-button-primary>

        <div class="results mt-5">
          <div v-show="isLoading">
            Laddar...
          </div>

          <div
            v-show="!isLoading && isLoaded && !(noProducts || errorFetchingData)"
            class="row">
            <div class="col-12">
              <tws-asset-search-result
                :assets="assets"
                :header="resultHeader" />
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <div
                v-if="noProducts">
                <InfraPageAlert
                  type="warning">
                  {{ messages.noProducts }}
                </InfraPageAlert>
              </div>

              <div v-if="errorFetchingData">
                <InfraPageAlert
                  type="danger">
                  {{ messages.error }}
                </InfraPageAlert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import { validationMixin } from 'vuelidate'

import {
  maxLength,
  minLength,
  numeric,
  required
} from 'vuelidate/lib/validators'

import CommonPageView from '@/views/CommonPageView'
import AssetSearchForm from '@/views/static/search-tools/asset-search/AssetSearchForm'
import AssetSearchResult from '@/views/static/search-tools/asset-search/AssetSearchResult'

import {
  TwsButtonPrimary,
  InfraPageAlert
} from 'tws-vue-components'
import i18n from '@/i18n'
import { BREADCRUMBS_ASSET_SEARCH } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    CommonPageView,
    'tws-asset-search-form': AssetSearchForm,
    'tws-asset-search-result': AssetSearchResult,
    TwsButtonPrimary,
    InfraPageAlert
  },
  mixins: [validationMixin],
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_ASSET_SEARCH,
      banner: {
        heading: this.$t('search-categories.connection-number')
      },
      resultHeader: '',
      asset: {
        searchField: this.$route.query.number
      },
      messages: {
        noProducts: i18n.t('asset-search.messages.no-products'),
        error: i18n.t('asset-search.messages.error')
      }
    }
  },
  computed: {
    assets () {
      return this.$store.getters['assetSearch/getAssetDetails']
    },
    isLoaded () {
      return this.$store.getters['assetSearch/isLoaded']
    },
    isLoading () {
      return this.$store.getters['assetSearch/isLoading']
    },
    isException () {
      return this.$store.getters['assetSearch/isException']
    },
    noProducts () {
      return !this.dataValuesNotEmpty(this.assets) &&
          this.isLoaded &&
          !this.isException &&
          !this.isLoading
    },
    errorFetchingData () {
      return !this.isLoading && this.isException
    }
  },
  validations: {
    asset: {
      searchField: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(12)
      }
    }
  },
  mounted () {
    if (this.asset.searchField !== undefined && this.asset.searchField.length) {
      this.onSubmit()
    }
  },
  methods: {
    dataValuesNotEmpty (data) {
      return data !== null && data !== undefined && Object.values(data).filter(s => s !== '').length !== 0
    },
    onAssetChange (asset) {
      this.asset = Object.assign({}, asset)
    },
    onSubmit () {
      this.$v.asset.$touch()
      if (this.$v.asset.$invalid) {
        return
      }

      this.resultHeader = 'FB-' + this.asset.searchField
      this.$store.dispatch('assetSearch/loadAssets', this.asset.searchField)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 40px;
}
.results {
  padding-bottom: 50px;
}
</style>
