import CompanyService from '@/services/company.service'

export const CompanyDetailsModule = {
  namespaced: true,
  state: {
    loading: false,
    updating: false,
    loadException: false,
    updateException: false,
    information: {}
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setUpdating (state, updating) {
      state.updating = updating
    },
    setLoadException (state, loadException) {
      state.loadException = loadException
    },
    setUpdateException (state, updateException) {
      state.updateException = updateException
    },
    setInformation (state, information) {
      state.information = Object.assign({}, information)
    }
  },
  actions: {
    fetchCompany ({ commit }, companyId) {
      commit('setLoading', true)
      commit('setLoadException', false)

      return CompanyService
        .getCompanyById(companyId)
        .then(information => {
          commit('setInformation', information)
        })
        .catch(() => {
          commit('setLoadException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    updateCompany ({ commit }, information) {
      commit('setUpdating', true)
      commit('setUpdateException', false)

      return CompanyService
        .update(information)
        .then(() => {
          commit('setInformation', information)
        })
        .catch(() => {
          commit('setUpdateException', true)
        })
        .finally(() => {
          commit('setUpdating', false)
        })
    },
    editingCanceled ({ commit }) {
      commit('setUpdateException', false)
    }
  }
}
