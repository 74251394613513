<template>
  <p class="paragraph">
    {{ labels.description }}
  </p>
</template>

<script>
export default {
  name: 'TwsFormParagraph',
  props: {
    labels: {
      type: Object,
      required: true
    }
  }
}
</script>
