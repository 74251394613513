import AssetSearchService from '@/services/asset-search.service'

export const AssetSearchModule = {
  namespaced: true,
  state: {
    number: '',
    loading: false,
    loaded: false,
    exception: false,
    assetDetails: {
      coverageArea: '',
      lineLength: '',
      cabinet: '',
      vsdl: '',
      streetName: '',
      streetNumber: '',
      city: '',
      tvth: '',
      numberOfStairs: '',
      comment: '',
      assetType: ''
    }
  },
  mutations: {
    setAssetDetails (state, assetDetails) {
      state.assetDetails = assetDetails
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    }
  },
  getters: {
    getAssetDetails (state) {
      return state.assetDetails
    },
    isLoaded (state) {
      return state.loaded
    },
    isLoading (state) {
      return state.loading
    },
    isException (state) {
      return state.exception
    }
  },
  actions: {
    loadAssets ({ commit }, number) {
      commit('setLoading', true)
      commit('setException', false)
      return AssetSearchService.getAssets(number)
        .then((response) => {
          commit('setAssetDetails', response)
          commit('setLoaded', true)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)
          commit('setAssetDetails', {})
          commit('setLoaded', false)
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  }
}
