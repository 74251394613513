import { isLocaleEnglish } from '@/utils/locale.utils'

export const getComputedTitle = (news) => {
  return isLocaleEnglish() && news.titleEn ? news.titleEn : news.title
}

const getSkip = (currentPage, pageSize) => {
  return (currentPage - 1) * pageSize
}

export const getNewsPayload = (params, locale) => {
  return {
    limit: params.size,
    locale: locale,
    skip: getSkip(params.page, params.size),
    ...params.dateFrom && { dateFrom: params.dateFrom },
    ...params.dateTo && { dateTo: params.dateTo },
    ...params.newsType && { newsType: params.newsType }
  }
}
