<template>
  <component
    :is="href ? 'LocalisedLink' : 'a'"
    class="header-action"
    :to="href"
    :role="href ? null : 'button'"
    @click="$emit('click')">
    <span class="header-action__icon">
      <slot name="icon" />
    </span>

    <span class="header-action__text">
      <slot name="text" />
    </span>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.header-action {
  cursor: pointer;
  text-align: center;

  &__text {
    font-size: 12px;
  }

  &__icon {
    display: block;
    line-height: 0;
  }
}
</style>
