<template>
  <div class="company-administration-banner">
    <div class="row">
      <div class="col-12">
        <h1 class="company-administration-banner__heading heading-l--black title-500">
          {{ $t('company-administration.heading') }}
        </h1>
      </div>
    </div>

    <div class="row company-administration-banner__content">
      <div class="col-xl-3 col-lg-4 col-md-6">
        <tws-action-card
          :text="companies.length"
          :active="true"
          :title="$t('company-administration.total-companies')" />
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="row company-administration-banner__actions">
          <div class="col-12">
            <tws-action-button @click="onAddCompany">
              <TeliaCoreIcon
                name="add_thin"
                size="3rem" />
              <span>{{ $t('company-administration.add-company') }}</span>
            </tws-action-button>
          </div>

          <div class="col-12">
            <tws-action-button @click="toggleHelpDialog">
              <TeliaCoreIcon
                name="guiding_light_thin"
                size="3rem" />
              <span>{{ $t('common.help') }}</span>
            </tws-action-button>
          </div>
        </div>
      </div>
    </div>

    <information-dialog
      v-show="showHelpDialog"
      :header="helpDialogHeader"
      @close="toggleHelpDialog">
      <template #body>
        <tws-rich-text :html="helpDialogText" />
      </template>
    </information-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import {
  TwsActionButton,
  TwsActionCard
} from 'tws-vue-components'

import InformationDialog from '@/components/common/information-dialog.vue'

export default {
  components: {
    TwsActionButton,
    TwsActionCard,
    InformationDialog
  },
  props: {
    helpDialogHeader: {
      type: String,
      required: true
    },
    helpDialogText: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showHelpDialog: false
    }
  },
  computed: {
    ...mapState('companyAdministration', ['companies'])
  },
  methods: {
    toggleHelpDialog () {
      this.showHelpDialog = !this.showHelpDialog
    },
    onAddCompany () {
      this.$router.push({
        name: 'company-creation',
        params: this.$route.params
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.company-administration-banner {
  &__heading {
    margin-bottom: 20px;
  }

  &__content,
  &__actions {
    margin-left: -0.5px;
    margin-right: -0.5px;

    [class*="col-"] {
      padding-left: 0.5px;
      padding-right: 0.5px;
    }
  }

  &__actions {
    .col-12 {
      &:first-child {
        margin-bottom: 0.5px;
      }

      &:last-child {
        margin-top: 0.5px;
      }
    }
  }
}

@media (max-width: 56em) {
  .company-administration-banner {
    &__content {
      > [class*="col-"]:not(:first-child) {
        margin-top: 1px;
      }
    }
  }
}
</style>
