<script>
import ProductCardContainer from '@/apps/public-landing/components/product-card/ProductCardContainer'
import HeroBanner from '@/apps/public-landing/components/hero-banner/HeroBanner'
import NewsPreviewWrapper from '@/apps/public-landing/components/news-preview/NewsPreviewWrapper'
import PromotionCardContainer from '@/apps/public-landing/components/promotion-card/PromotionCardContainer'
import CardsCarouselContainer from '@/apps/public-landing/components/carousel/CardsCarouselContainer'
import {
  mapActions,
  mapState
} from 'vuex'

import '@purpurds/purpur/styles'

export default {
  components: {
    ProductCardContainer,
    HeroBanner,
    NewsPreviewWrapper,
    PromotionCardContainer,
    CardsCarouselContainer
  },
  data () {
    return {
      locale: this.$route.params?.locale
    }
  },
  computed: {
    ...mapState('publicLanding',
      {
        content: 'content',
        isContentLoading: 'loading'

      }
    ),
    ...mapState('publicLandingNews', {
      articles: 'articles',
      isNewsLoading: 'loading'
    })
  },
  mounted () {
    this.loadContent()
    this.loadNews()
  },
  methods: {
    ...mapActions('publicLandingNews', ['loadNews']),
    ...mapActions('publicLanding', ['loadContent'])
  }
}
</script>

<template>
  <p
    v-if="isContentLoading"
    class="container">
    {{ $t('public-landing.loading') }}
  </p>

  <div
    v-else
    class="tws-landing-page-new">
    <HeroBanner
      :title="content.hero.heading"
      :preamble="content.hero.paragraph"
      :cta="content.hero.cta"
      :img="content.hero.img" />

    <ProductCardContainer
      :products="content.products"
      :product-icon="content.productIcon" />

    <p
      v-if="isNewsLoading"
      class="container">
      {{ $t('public-landing.loading') }}
    </p>
    <NewsPreviewWrapper
      v-else
      :title="$t('public-landing.news')"
      :articles="articles"
      :cta="{ url: `${locale ? `/${locale}` : ''}/news-archive` } " />

    <PromotionCardContainer
      :title="content.promotionCards.title"
      :promotion-card-list="content.promotionCards.cards" />

    <CardsCarouselContainer
      :title="content.carousel.title"
      :cards="content.carousel.cards" />
  </div>
</template>
<style lang="scss">
@use '@purpurds/tokens/typography/variables' as typography;
@import url("https://cdn.voca.teliacompany.com/fonts/TeliaSansV10/TeliaSans.css");

.document {
  --purpur-rescale: 1.6;
}

.tws-landing-page-new {
  *,
  *::before,
  *::after {
    font-family: typography.$purpur-typography-family-default;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6{
    font-family: typography.$purpur-typography-family-display;
  }
}

</style>
