import {
  ref, withParams
} from 'vuelidate/lib/validators/common'

/* eslint-disable no-useless-escape */
const STREET_NAME_REGEXP = /^[A-Za-z*\s0-9\055\072\305\304\326\344\345\366\311\351\374\334]{0,50}$/
const STREET_NUMBER_REGEXP = /^[0-9]{0,5}$/
const CITY_REGEXP = /^[A-Za-z*\s\055\072\305\304\326\344\345\366\311\351\374\334]{0,50}$/
const MUNICIPALITY_DISTRICT_REGEXP = /^[A-Za-zÅÄÖåäö\\s\\055\\072\\305\\304\\326\\344\\345\\366\\311\\351\\374\\334\-\s]{0,50}$/
const BLOCK_REGEXP = /^[A-Za-z0-9*-ÅÄÖåäö]{0,4}$/
const UNIT_REGEXP = /^[0-9*]{0,4}$/

export const streetName = value => {
  const trimmed = value.trim()
  return trimmed.length > 1 && trimmed.length <= 50 && STREET_NAME_REGEXP.test(value)
}

export const streetNumber = value => {
  return STREET_NUMBER_REGEXP.test(value)
}

export const city = value => {
  return CITY_REGEXP.test(value)
}

export const addressWildcard = value => {
  if (!value) {
    return true
  }

  const index = value.indexOf('*')
  return index === -1 || index > 1
}

export const addressMultiWildcard = prop => withParams({
  type: 'addressMultiWildcard',
  prop
}, (value, parentVm) => {
  const value2 = ref(prop, this, parentVm)

  if (!value || !value2) {
    return true
  }

  const index = value.indexOf('*')
  const index2 = value2.indexOf('*')

  return index < 2 || index2 < 2
})

export const cityWildcard = props => withParams({
  type: 'cityWildcard',
  props
}, (city, parentVm) => {
  const streetNumber = ref(props.streetNumber, this, parentVm)
  const entrance = ref(props.entrance, this, parentVm)

  if (!city || !streetNumber || !entrance) {
    return true
  }

  const index = city.indexOf('*')

  return index < 2
})

export const municipality = value => {
  return MUNICIPALITY_DISTRICT_REGEXP.test(value)
}

export const district = value => {
  return MUNICIPALITY_DISTRICT_REGEXP.test(value)
}

export const block = value => {
  return BLOCK_REGEXP.test(value)
}

export const unit = value => {
  return UNIT_REGEXP.test(value)
}
