<template>
  <div class="products-container">
    <ProductsView
      v-if="showProductsView"
      class="mt-5"
      :product-list="products"
      :descriptions="productDescriptions"
      :header="formattedAddress"
      :map-search-url="mapSearchUrl" />

    <AdvancedProductsView v-if="showAdvancedProductsView" />

    <InfraPageAlert
      v-if="noProductsFound"
      class="products-view-alert--warning"
      type="warning">
      {{ $t('products-display.alerts.no-products-found') }}
    </InfraPageAlert>

    <InfraPageAlert
      v-if="displayException"
      class="products-view-alert--danger"
      type="danger">
      {{ $t('products-display.alerts.error') }}
    </InfraPageAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { InfraPageAlert } from 'tws-vue-components'
import { printAddress } from '@/apps/address-search/utils/string-utils'
import { objectIsEmpty } from '@/apps/address-search/utils/object-utils'
import ProductsView from './products-view'
import AdvancedProductsView from './AdvancedProductsView'
import { SEARCH_MODE } from '@/apps/address-search/utils/constants.utils'

export default {
  components: {
    AdvancedProductsView,
    ProductsView,
    InfraPageAlert
  },
  computed: {
    ...mapGetters('AddressSearch/Products', [
      'products',
      'productAddress',
      'productsEmpty',
      'isLoaded',
      'isException'
    ]),
    ...mapGetters('AddressSearch/Products/advancedProducts', {
      advancedProductData: 'productData',
      advancedProductsLoaded: 'loaded',
      advancedProductException: 'exception'
    }),
    ...mapGetters('AddressSearch', ['anythingLoading']),
    ...mapGetters('AddressSearch/Content', [
      'productDescriptions',
      'mapSearchUrl'
    ]),
    ...mapGetters('AddressSearch', ['searchMode']),
    noProductsFound () {
      return this.searchMode === SEARCH_MODE.ADDRESS && this.productsEmpty && this.isLoaded
        || objectIsEmpty(this.advancedProductData) && this.advancedProductsLoaded
    },
    displayException () {
      return this.searchMode === SEARCH_MODE.ADDRESS && this.isException
        || this.advancedProductException
    },
    formattedAddress () {
      return printAddress(this.productAddress || {})
    },
    showProductsView () {
      return this.searchMode === SEARCH_MODE.ADDRESS && !this.productsEmpty && this.isLoaded && !this.anythingLoading
    },
    showAdvancedProductsView () {
      return this.searchMode !== SEARCH_MODE.ADDRESS && this.advancedProductsLoaded && this.advancedProductData && !this.anythingLoading
    }
  }
}
</script>
