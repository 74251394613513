<template>
  <TwsAccordion>
    <TwsAccordionItem
      v-if="wholesaleFiberAvailable || wholesaleFiberOutsideAvailable"
      class="wholesale-fiber-accordion"
      :title="$t('products-display.advanced-products-view.wholesale-fiber-title')"
      :expanded="accordionOpen.wholesaleFiber"
      @toggle="toggleAccordion('wholesaleFiber')">
      <WholesaleFiber
        :is-wholesale-fiber-outside="wholesaleFiberOutsideAvailable"
        :product-data="productData.wholesaleFiberAccess || productData.wholesaleFiberAccessOutside"
        :searched-address-a="productAddressA"
        :searched-address-b="productAddressB"
        :searched-node-a="productNodeA"
        :searched-node-b="productNodeB" />
    </TwsAccordionItem>
  </TwsAccordion>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  TwsAccordion,
  TwsAccordionItem
} from 'tws-vue-components'
import WholesaleFiber from './AdvancedWholesaleFiber/AdvancedWholesaleFiberProduct'
import MapSearchMixin from '@/apps/address-search/utils/map-search.mixin'

export default {
  components: {
    TwsAccordion,
    TwsAccordionItem,
    WholesaleFiber
  },
  mixins: [MapSearchMixin],
  data () {
    return {
      accordionOpen: {
        fiber2009: false,
        wholesaleFiber: false
      }
    }
  },
  computed: {
    ...mapGetters('AddressSearch/Products/advancedProducts', [
      'productData',
      'productAddressA',
      'productAddressB',
      'productNodeA',
      'productNodeB',
      'wholesaleFiberAvailable',
      'wholesaleFiberOutsideAvailable',
      'loading'
    ]),
    ...mapGetters('AddressSearch/Content', ['mapSearchUrl'])
  },
  watch: {
    productData () {
      this.closeAllAccordionItems()
    }
  },
  methods: {
    toggleAccordion (key) {
      this.accordionOpen[key] = !this.accordionOpen[key]
    },
    closeAllAccordionItems () {
      this.accordionOpen.wholesaleFiber = false
      this.accordionOpen.fiber2009 = false
    },
    handleOpenMap (key) {
      this.openMap(this[`productAddress${key.toUpperCase()}`])
    }
  }
}
</script>
