<template>
  <div class="row fault-report-mobile-sp">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <p class="title-200">
            {{ $t('fault-reporting.form.description.description') }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <HorizontalRadioButtons
            id="problemWithSendingReceiving"
            v-model="problemWithSendingReceivingProxy"
            :validation-messages="validationMessages.problemWithSendingReceiving"
            :options="problemWithSendingReceivingOptions"
            :validator="$v.problemWithSendingReceiving"
            :label="$t('fault-reporting.form.description.problem-with-sending-receiving.label')" />
        </div>
      </div>

      <hr class="divider">

      <div class="row mt-5 fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            id="isTerminalRestartedUpdated"
            v-model="isTerminalRestartedUpdatedProxy"
            :validation-messages="validationMessages.isTerminalRestartedUpdated"
            :validator="$v.isTerminalRestartedUpdated"
            :label="$t('fault-reporting.form.description.is-terminal-restarted-updated.label')" />
        </div>

        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            id="isProblemInDifferentTerminal"
            v-model="isProblemInDifferentTerminalProxy"
            :validation-messages="validationMessages.isProblemInDifferentTerminal"
            :validator="$v.isProblemInDifferentTerminal"
            :label="$t('fault-reporting.form.description.is-problem-in-different-terminal.label')" />
        </div>
      </div>

      <hr class="divider">

      <div class="row mt-5">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <TwsValidatedDatePicker
            id="problemOccurenceDate"
            v-model="problemOccurenceDateProxy"
            :show-icon="true"
            :read-only="true"
            :validator="$v.problemOccurenceDate"
            :validation-messages="validationMessages.problemOccurenceDate"
            :options="datePickerOptions"
            :label="$t('fault-reporting.form.description.problem-occurence-date.label')"
            required />
        </div>
      </div>

      <hr class="divider">

      <div class="row">
        <p class="col-12 font-weight-medium">
          {{ $t('fault-reporting.form.description.state-examples-when-customer-experienced-problem-sms-mms.label') }}<br>
          {{ $t('fault-reporting.form.description.state-examples-when-customer-experienced-problem-sms-mms.sub-label') }}
        </p>
      </div>

      <div
        v-for="(v, index) in $v.stateExamplesWhenCustomerExperiencedProblem.$each.$iter"
        :key="`${v.description.value}-${index}`">
        <div class="row mt-2 fieldset">
          <div class="col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedTextInputGroup
              :id="`stateExampleDescription-${index}`"
              v-model="v.description.$model"
              :validator="v.description"
              :validation-messages="validationMessages.stateExamplesWhenCustomerExperiencedProblem"
              :label="`#${Number(index) + 1} ${$t('fault-reporting.form.description.example.label')}`" />
          </div>

          <div class="col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedDatePicker
              :id="`stateExampleDate-${index}`"
              v-model="v.date.$model"
              :show-icon="true"
              :read-only="true"
              :validator="v.date"
              :validation-messages="validationMessages.stateExamplesWhenCustomerExperiencedProblem"
              :options="datePickerOptions"
              :label="$t('common.date_time')"
              required />
          </div>

          <div class="col-12 mb-5">
            <TwsButtonPrimary
              v-if="Number(index) > 0"
              @click="removeStateExample(index)">
              <div class="button-wrapper">
                <TeliaCoreIcon
                  class="notification-input__trash-icon__icon"
                  name="trash_empty_thin"
                  size="20px" />

                <span>{{ $t('common.remove') }}</span>
              </div>
            </TwsButtonPrimary>
          </div>
        </div>
      </div>

      <TwsButtonPrimary
        class="mb-5"
        @click="addEmptyStateExample">
        <div class="button-wrapper">
          <TeliaCoreIcon
            class="icon"
            name="add_thin"
            size="20px" />
          <span>{{ $t('common.add_example') }}</span>
        </div>
      </TwsButtonPrimary>

      <hr class="divider">

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="incidentDescription"
            v-model="incidentDescriptionProxy"
            :validator="$v.incidentDescription"
            :validation-messages="validationMessages.incidentDescription"
            :label="$t('fault-reporting.form.description.incident-description.label')" />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <InfraFilePicker
            v-model="attachmentsProxy"
            class="mb-5"
            button-type="primary"
            :acceptable-file-extensions="acceptableFileExtensions"
            :limit="attachmentsLimit"
            :file-size-limit="defaultFileSizeLimit"
            :show-add-icon="true"
            :add-file-text="$t('fault-reporting.form.attachments-button.button-text')"
            :limit-is-reached-text="$t('fault-reporting.form.attachments-button.limit-is-reached-text').replace('limit', attachmentsLimit)"
            :size-limit-text="$t('fault-reporting.form.attachments-button.size-is-reached-text-5mb')" />
        </div>
      </div>

      <hr class="divider">

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="error-message"
            v-model="errorMessageProxy"
            :validator="$v.errorMessage"
            :validation-messages="validationMessages.errorMessage"
            :label="$t('fault-reporting.form.description.error-message.label')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TwsValidatedTextAreaGroup,
  TwsValidatedTextInputGroup,
  TwsValidatedDatePicker,
  TwsButtonPrimary,
  InfraFilePicker
} from 'tws-vue-components'
import HorizontalRadioButtons from '@/apps/fault-reporting/components/form/HorizontalRadioButtons'
import {
  mapState, mapActions
} from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  ATTACHMENTS_ALLOWED_EXTENSIONS, ATTACHMENTS_FILE_SIZE_LIMIT_5MB,
  ATTACHMENTS_LIMIT, DATE_PICKER_OPTIONS, PROBLEM_WITH_SENDING_RECEIVING_OPTIONS
} from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  components: {
    HorizontalRadioButtons,
    TwsValidatedTextAreaGroup,
    TwsValidatedTextInputGroup,
    TwsValidatedDatePicker,
    TwsButtonPrimary,
    InfraFilePicker
  },

  mixins: [validationMixin],

  data () {
    return {
      attachmentsLimit: ATTACHMENTS_LIMIT,
      acceptableFileExtensions: ATTACHMENTS_ALLOWED_EXTENSIONS,
      defaultFileSizeLimit: ATTACHMENTS_FILE_SIZE_LIMIT_5MB,
      datePickerOptions: DATE_PICKER_OPTIONS,
      problemWithSendingReceivingOptions: PROBLEM_WITH_SENDING_RECEIVING_OPTIONS,
      validationMessages: {
        problemWithSendingReceiving: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        isTerminalRestartedUpdated: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        isProblemInDifferentTerminal: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        problemOccurenceDate: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        stateExamplesWhenCustomerExperiencedProblem: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        incidentDescription: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        errorMessage: {
          required: this.$t('fault-reporting.default-required-validation-message')
        }
      }
    }
  },

  computed: {
    ...mapState('FaultReporting/FaultReportForm/MobileSP', [
      'problemWithSendingReceiving',
      'isTerminalRestartedUpdated',
      'isProblemInDifferentTerminal',
      'problemOccurenceDate',
      'stateExamplesWhenCustomerExperiencedProblem',
      'attachments',
      'incidentDescription',
      'errorMessage'
    ]),
    problemWithSendingReceivingProxy: {
      get () {
        return this.problemWithSendingReceiving
      },
      set (value) {
        this.updateProblemWithSendingReceiving(value)
      }
    },
    isTerminalRestartedUpdatedProxy: {
      get () {
        return this.isTerminalRestartedUpdated
      },
      set (value) {
        this.updateIsTerminalRestartedUpdated(value)
      }
    },
    isProblemInDifferentTerminalProxy: {
      get () {
        return this.isProblemInDifferentTerminal
      },
      set (value) {
        this.updateIsProblemInDifferentTerminal(value)
      }
    },
    problemOccurenceDateProxy: {
      get () {
        return this.problemOccurenceDate
      },
      set (value) {
        this.updateProblemOccurenceDate(value)
      }
    },
    attachmentsProxy: {
      get () {
        return this.attachments
      },
      set (value) {
        this.updateAttachments(value)
      }
    },
    stateExamplesWhenCustomerExperiencedProblemProxy: {
      get () {
        return this.stateExamplesWhenCustomerExperiencedProblem
      },
      set (value) {
        this.updateStateExamplesWhenCustomerExperiencedProblem(value)
      }
    },
    incidentDescriptionProxy: {
      get () {
        return this.incidentDescription
      },
      set (value) {
        this.updateIncidentDescription(value)
      }
    },
    errorMessageProxy: {
      get () {
        return this.errorMessage
      },
      set (value) {
        this.updateErrorMessage(value)
      }
    },
    isValidProxy () {
      return !this.$v.$invalid
    }
  },

  watch: {
    isValidProxy (val) {
      this.updateIsValid(val)
    }
  },

  created () {
    if (!this.stateExamplesWhenCustomerExperiencedProblemProxy?.length) {
      this.updateStateExamplesWhenCustomerExperiencedProblem([{
        date: '',
        description: ''
      }])
    }
  },

  validations: {
    problemWithSendingReceiving: {
      required
    },
    isTerminalRestartedUpdated: {
      required
    },
    isProblemInDifferentTerminal: {
      required
    },
    problemOccurenceDate: {
      required
    },
    stateExamplesWhenCustomerExperiencedProblem: {
      $each: {
        date: {
          required
        },
        description: {
          required
        }
      },
      required
    },
    incidentDescription: {
      required
    },
    errorMessage: {
      required
    }
  },

  methods: {
    ...mapActions('FaultReporting/FaultReportForm/MobileSP', [
      'updateProblemWithSendingReceiving',
      'updateIsTerminalRestartedUpdated',
      'updateIsProblemInDifferentTerminal',
      'updateProblemOccurenceDate',
      'updateStateExamplesWhenCustomerExperiencedProblem',
      'updateAttachments',
      'updateIncidentDescription',
      'updateErrorMessage',
      'updateIsValid',
      'addEmptyStateExample',
      'removeStateExample'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import "./default.scss";
</style>
