const STORAGE_KEY = 'my-documents-company-filter'

export const MyDocumentsModule = {
  namespaced: true,
  state: { company: '' },
  mutations: { setCompany: (state, company) => state.company = company },
  actions: {
    loadCompany ({ commit }) {
      commit('setCompany', JSON.parse(sessionStorage.getItem(STORAGE_KEY)) || '')
    },
    updateCompany ({ commit }, company) {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(company) || '')
      commit('setCompany', company)
    }
  }
}
