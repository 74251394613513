<template>
  <span class="container-authenticated">
    <TeliaCoreIcon
      class="icon"
      name="end_user_thin"
      size="2rem" />

    <div>
      <div class="username-text">{{ getFullName }}</div>

      <div
        v-if="getCompany"
        class="company-text">{{ getCompany }}</div>
    </div>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('eamCookies', [
      'getFullName',
      'getCompany'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.container-authenticated {
  text-align: left;
  display: inline-flex;
  justify-content: flex-start;

  .icon {
    margin-right: 1rem;
    color: black;
  }

  .username-text {
    line-height: normal;
    color: #000;
  }

  .company-text {
    color: $dark-grey;
  }
}
</style>
