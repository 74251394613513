import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_REPORT_MANAGEMENT_API)

export default {
  loadMyReports: () => api.get('/reports/fetch-metadata'),
  loadAllReports: () => api.get('/reports/fetch-metadata-all'),
  deleteReport: reportId => api.delete('/reports/delete', { params: { id: reportId } }),
  uploadReport: request => {
    const formData = new FormData()
    formData.append('file', request.file)
    formData.append('companyCode', request.companyCode)
    formData.append('publishingDate', request.publishingDate)
    formData.append('expirationDate', request.expirationDate)

    return api.post('/reports/upload', formData)
  }
}
