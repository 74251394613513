<template>
  <div class="page-icons-container">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="page-icons-container__heading mx-auto" />
        </div>
      </div>

      <div class="row justify-content-center">
        <div
          v-for="index in 3"
          :key="index"
          class="col-sm-3 col-xs-12">
          <InfraPageIconsPlaceholder />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfraPageIconsPlaceholder } from 'tws-vue-components'

export default {
  components: { InfraPageIconsPlaceholder }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.page-icons-container {
  margin-top: 55px;

  &__heading {
    margin-bottom: 35px;
    text-align: center;
    height: 24px;
    max-width: 500px;
    background-color: $light-grey;
  }
}
</style>
