import BasicInfoView from '@/views/basic-info/BasicInfoView.vue'

export default [
  {
    path: ':slug',
    name: 'basic-info',
    component: BasicInfoView
  },
  {
    path: 'losningar/:slug',
    name: 'basic-info-level-2',
    component: BasicInfoView,
    meta: { level: 2 }
  }
]
