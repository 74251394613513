<template>
  <div class="container footer-mobile">
    <div class="row">
      <div class="col-12">
        <tws-accordion>
          <tws-accordion-item
            v-for="category in categories"
            :key="category.heading"
            :title="category.heading">
            <tws-sub-categories :categories="category.sub_categories" />
          </tws-accordion-item>
        </tws-accordion>
      </div>
    </div>
  </div>
</template>

<script>
import SubCategories from '../footer-sub-categories/footer-sub-categories.vue'

import {
  TwsAccordion,
  TwsAccordionItem
} from 'tws-vue-components'

export default {
  components: {
    'tws-sub-categories': SubCategories,
    TwsAccordion,
    TwsAccordionItem
  },
  props: {
    categories: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'tws-core-atoms/variables.scss';

.footer-mobile::v-deep .accordion {
  background-color: $deep-purple;

  &-item {
    border-top: none !important;

    &__header {
      border-bottom: 1px solid $dark-purple !important;
      padding: 0 !important;

      &--expanded {
        background-color: #380354 !important;
      }

      path {
        fill: $white;
      }
    }

    &__content {
      border-bottom: none !important;
      margin-bottom: 18px !important;
    }
  }
}

</style>
