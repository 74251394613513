import Vue from 'vue'

const components = require.context('./assets/icons/', true, /\.svg$/)
const regexp = new RegExp('_', 'g')

components.keys().forEach(fileName => {
  const componentName = fileName.split('/').pop().replace('.svg', '').replace(regexp, '-')
  const importName = fileName.replace('./', '').replace('.svg', '')

  Vue.component(`tws-${componentName}-icon`, () => import(`./assets/icons/${importName}.svg`))
})
