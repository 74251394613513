import { applyReactInVue } from 'vuereact-combined'

export const applyReactInVueWithOptions = (component, className) => {
  const componentWrapAttrs = {
    style: {
      display: 'block'
    }
  }

  if (className) {
    componentWrapAttrs.class = className
  }

  return applyReactInVue(component, {
    react: {
      componentWrap: 'span',
      slotWrap: 'span',
      style: {
        display: 'block'
      },
      componentWrapAttrs,
      slotWrapAttrs: {
        style: {
          display: 'block'
        }
      }
    },
    vue: {
      slotWrapAttrs: {
        style: {}
      }
    }
  })
}
