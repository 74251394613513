<template>
  <div class="related-products-placeholder">
    <div class="related-products-placeholder__heading" />

    <RelatedProductPlaceholder
      v-for="index in 3"
      :key="index" />
  </div>
</template>

<script>
import RelatedProductPlaceholder from './RelatedProductPlaceholder'

export default {
  components: { RelatedProductPlaceholder }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.related-products-placeholder {
  background-color: $light-grey;
  padding: 24px;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  @media (max-width: $breakpoint-md) {
    margin-top: 15px;
  }

  &__heading {
    margin-bottom: 20px;
    height: 24px;
    background-color: $white;
  }
}
</style>
