export const extractHero = response => {
  if (!response || !response.fields) {
    return null
  }
  const hero = response.fields

  return {
    backgroundImage: hero.backgroundImage.fields.file.url,
    heading: hero.heading,
    paragraph: hero.paragraph,
    alignment: hero.alignment
  }
}

export const extractPageIcons = response => {
  if (!response || !response.pageIcons || !response.pageIcons.length) {
    return null
  }

  const pageIcons = response.pageIcons

  return pageIcons.map(pageIcon => {
    return {
      icon: pageIcon.fields.icon.fields.file.url,
      title: pageIcon.fields.iconText
    }
  })
}
