import { createInstance } from '@/services/axios.api'
export const api = createInstance(process.env.VUE_APP_PRODUCT_SEARCH_URL)

export const getProducts = async ({ streetName, streetNumber, entrance, city, postNumber, pointId }) => {
  return await api.get('/product/accesses', {
    params: {
      streetName,
      streetNumber,
      entrance,
      city,
      postNumber,
      pointId
    }
  })
}

const getPointForNode = node => ({
  coverageArea: node.node,
  kc: node.kc
})

export const getFiberProducts = {
  addressAddress: async ([
    pointA,
    pointB
  ]) => {
    return await api.post(
      '/extended/search/address/address',
      {
        pointA,
        pointB
      }
    )
  },
  addressStation: async ([
    pointA,
    pointB
  ]) => {
    return await api.post(
      '/extended/search/address/station',
      {
        pointA,
        pointB: getPointForNode(pointB)
      }
    )
  },
  stationStation: async ([
    pointA,
    pointB
  ]) => {
    return await api.post(
      '/extended/search/station/station',
      {
        pointA: getPointForNode(pointA),
        pointB: getPointForNode(pointB)
      }
    )
  }
}
