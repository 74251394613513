export const INVOICE_SPECIFICATION_TYPE = {
  IC_CASCADE: 'IC_CASCADE',
  IC_TRANSIT1: 'IC_TRANSIT1',
  IC_TRANSIT2: 'IC_TRANSIT2',
  IC_SPECIFICATION: 'IC_SPECIFICATION',
  IC_SPECIFICATION_YOURS: 'IC_SPECIFICATION_YOURS',
  NUMBER_PORTABILITY_FIXED: 'NUMBER_PORTABILITY_FIXED',
  IC_MOBILE_SETTLEMENT: 'IC_MOBILE_SETTLEMENT',
  IC_MOBILE_SPECIFICATION: 'IC_MOBILE_SPECIFICATION',
  NUMBER_PORTABILITY_MOBILE: 'NUMBER_PORTABILITY_MOBILE',
  SP_SPECIFICATION: 'SP_SPECIFICATION',
  TAB_SUBSCRIPTION: 'TAB_SUBSCRIPTION',
  TAB_STOCK: 'TAB_STOCK',
  TAB_EVENT: 'TAB_EVENT',
  INVOICE_SPECIFICATION: 'INVOICE_SPECIFICATION',
  EXTENDED_INVOICE_SPECIFICATION: 'EXTENDED_INVOICE_SPECIFICATION',
  EXTENDED_INVOICE_SPECIFICATION_PLUS: 'EXTENDED_INVOICE_SPECIFICATION_PLUS'
}

export const INVOICE_SPECIFICATION_TYPE_LABEL = {
  [INVOICE_SPECIFICATION_TYPE.IC_CASCADE]: 'invoice-specifications.type-labels.ic-cascade',
  [INVOICE_SPECIFICATION_TYPE.IC_TRANSIT1]: 'invoice-specifications.type-labels.ic-transit-1',
  [INVOICE_SPECIFICATION_TYPE.IC_TRANSIT2]: 'invoice-specifications.type-labels.ic-transit-2',
  [INVOICE_SPECIFICATION_TYPE.IC_SPECIFICATION]: 'invoice-specifications.type-labels.ic-specification',
  [INVOICE_SPECIFICATION_TYPE.IC_SPECIFICATION_YOURS]: 'invoice-specifications.type-labels.ic-specification-yours',
  [INVOICE_SPECIFICATION_TYPE.NUMBER_PORTABILITY_FIXED]: 'invoice-specifications.type-labels.number-portability-fixed',
  [INVOICE_SPECIFICATION_TYPE.IC_MOBILE_SETTLEMENT]: 'invoice-specifications.type-labels.mobile-settlement',
  [INVOICE_SPECIFICATION_TYPE.IC_MOBILE_SPECIFICATION]: 'invoice-specifications.type-labels.mobile-specification',
  [INVOICE_SPECIFICATION_TYPE.NUMBER_PORTABILITY_MOBILE]: 'invoice-specifications.type-labels.number-portability-mobile',
  [INVOICE_SPECIFICATION_TYPE.SP_SPECIFICATION]: 'invoice-specifications.type-labels.sp-specification',
  [INVOICE_SPECIFICATION_TYPE.TAB_SUBSCRIPTION]: 'invoice-specifications.type-labels.tab-subscription',
  [INVOICE_SPECIFICATION_TYPE.TAB_STOCK]: 'invoice-specifications.type-labels.tab-stock',
  [INVOICE_SPECIFICATION_TYPE.TAB_EVENT]: 'invoice-specifications.type-labels.tab-event',
  [INVOICE_SPECIFICATION_TYPE.INVOICE_SPECIFICATION]: 'invoice-specifications.type-labels.invoice-specification',
  [INVOICE_SPECIFICATION_TYPE.EXTENDED_INVOICE_SPECIFICATION]: 'invoice-specifications.type-labels.extended-invoice-specification',
  [INVOICE_SPECIFICATION_TYPE.EXTENDED_INVOICE_SPECIFICATION_PLUS]: 'invoice-specifications.type-labels.extended-invoice-specification-plus'
}

export const INVOICE_SPECIFICATION_TYPE_GROUP = {
  FIXED_IC: 'FIXED_IC',
  FIXED_NUMBER_PORTING: 'FIXED_NUMBER_PORTING',
  MOBILE_IC: 'MOBILE_IC',
  MOBILE_NUMBER_PORTING: 'MOBILE_NUMBER_PORTING',
  MOBILE_SP_HOST: 'MOBILE_SP_HOST',
  TAB: 'TAB',
  CAPACITY: 'CAPACITY',
  OTHER: 'OTHER',
  NOT_SPECIFIED: 'NOT_SPECIFIED'
}

export const INVOICE_SPECIFICATION_TYPE_GROUP_LABEL = {
  [INVOICE_SPECIFICATION_TYPE_GROUP.FIXED_IC]: 'invoice-specifications.type-group-labels.fixed-ic',
  [INVOICE_SPECIFICATION_TYPE_GROUP.FIXED_NUMBER_PORTING]: 'invoice-specifications.type-group-labels.fixed-number-porting',
  [INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_IC]: 'invoice-specifications.type-group-labels.mobile-ic',
  [INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_NUMBER_PORTING]: 'invoice-specifications.type-group-labels.mobile-number-porting',
  [INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_SP_HOST]: 'invoice-specifications.type-group-labels.mobile-sp-host',
  [INVOICE_SPECIFICATION_TYPE_GROUP.TAB]: 'invoice-specifications.type-group-labels.tab',
  [INVOICE_SPECIFICATION_TYPE_GROUP.CAPACITY]: 'invoice-specifications.type-group-labels.capacity',
  [INVOICE_SPECIFICATION_TYPE_GROUP.OTHER]: 'invoice-specifications.type-group-labels.other',
  [INVOICE_SPECIFICATION_TYPE_GROUP.NOT_SPECIFIED]: 'invoice-specifications.type-group-labels.not-specified'
}

export const INVOICE_SPECIFICATION_TYPES = [
  INVOICE_SPECIFICATION_TYPE.IC_CASCADE,
  INVOICE_SPECIFICATION_TYPE.IC_TRANSIT1,
  INVOICE_SPECIFICATION_TYPE.IC_TRANSIT2,
  INVOICE_SPECIFICATION_TYPE.IC_SPECIFICATION,
  INVOICE_SPECIFICATION_TYPE.IC_SPECIFICATION_YOURS,
  INVOICE_SPECIFICATION_TYPE.NUMBER_PORTABILITY_FIXED,
  INVOICE_SPECIFICATION_TYPE.IC_MOBILE_SETTLEMENT,
  INVOICE_SPECIFICATION_TYPE.IC_MOBILE_SPECIFICATION,
  INVOICE_SPECIFICATION_TYPE.NUMBER_PORTABILITY_MOBILE,
  INVOICE_SPECIFICATION_TYPE.SP_SPECIFICATION,
  INVOICE_SPECIFICATION_TYPE.TAB_SUBSCRIPTION,
  INVOICE_SPECIFICATION_TYPE.TAB_STOCK,
  INVOICE_SPECIFICATION_TYPE.TAB_EVENT,
  INVOICE_SPECIFICATION_TYPE.INVOICE_SPECIFICATION,
  INVOICE_SPECIFICATION_TYPE.EXTENDED_INVOICE_SPECIFICATION,
  INVOICE_SPECIFICATION_TYPE.EXTENDED_INVOICE_SPECIFICATION_PLUS
]

export const INVOICE_SPECIFICATION_TYPE_GROUPS = [
  INVOICE_SPECIFICATION_TYPE_GROUP.FIXED_IC,
  INVOICE_SPECIFICATION_TYPE_GROUP.FIXED_NUMBER_PORTING,
  INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_IC,
  INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_NUMBER_PORTING,
  INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_SP_HOST,
  INVOICE_SPECIFICATION_TYPE_GROUP.TAB,
  INVOICE_SPECIFICATION_TYPE_GROUP.CAPACITY,
  INVOICE_SPECIFICATION_TYPE_GROUP.OTHER,
  INVOICE_SPECIFICATION_TYPE_GROUP.NOT_SPECIFIED
]

export const INVOICE_SPECIFICATION_TYPE_GROUP_MAP = {
  [INVOICE_SPECIFICATION_TYPE_GROUP.FIXED_IC]: [
    INVOICE_SPECIFICATION_TYPE.IC_CASCADE,
    INVOICE_SPECIFICATION_TYPE.IC_TRANSIT1,
    INVOICE_SPECIFICATION_TYPE.IC_TRANSIT2,
    INVOICE_SPECIFICATION_TYPE.IC_SPECIFICATION,
    INVOICE_SPECIFICATION_TYPE.IC_SPECIFICATION_YOURS
  ],
  [INVOICE_SPECIFICATION_TYPE_GROUP.FIXED_NUMBER_PORTING]: [INVOICE_SPECIFICATION_TYPE.NUMBER_PORTABILITY_FIXED],
  [INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_IC]: [
    INVOICE_SPECIFICATION_TYPE.IC_MOBILE_SETTLEMENT,
    INVOICE_SPECIFICATION_TYPE.IC_MOBILE_SPECIFICATION
  ],
  [INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_NUMBER_PORTING]: [INVOICE_SPECIFICATION_TYPE.NUMBER_PORTABILITY_MOBILE],
  [INVOICE_SPECIFICATION_TYPE_GROUP.MOBILE_SP_HOST]: [INVOICE_SPECIFICATION_TYPE.SP_SPECIFICATION],
  [INVOICE_SPECIFICATION_TYPE_GROUP.TAB]: [
    INVOICE_SPECIFICATION_TYPE.TAB_SUBSCRIPTION,
    INVOICE_SPECIFICATION_TYPE.TAB_STOCK,
    INVOICE_SPECIFICATION_TYPE.TAB_EVENT
  ],
  [INVOICE_SPECIFICATION_TYPE_GROUP.CAPACITY]: [
    INVOICE_SPECIFICATION_TYPE.INVOICE_SPECIFICATION,
    INVOICE_SPECIFICATION_TYPE.EXTENDED_INVOICE_SPECIFICATION,
    INVOICE_SPECIFICATION_TYPE.EXTENDED_INVOICE_SPECIFICATION_PLUS
  ]
}
