const basePath = 'felanmalan'

export const RoutePath = {
  FaultReporting: basePath,
  FaultReport: `${basePath}/fault-report/details/:id`,
  NewFaultReport: `${basePath}/create`,
  SkanovaTicketsList: `${basePath}/esales-ticket-list`,
  SkanovaTicketCreate: `${basePath}/esales-ticket-create`,
  NewFaultReportForm: `${basePath}/new-fault-report`,
  NewFaultReportSummary: `${basePath}/summary`,
  ThankYouPage: `${basePath}/thank-you`
}

export const RouteName = {
  FaultReporting: 'fault-reporting',
  FaultReport: 'fault-report',
  NewFaultReport: 'new-fault-report',
  SkanovaTicketsList: 'skanova-ticket-list',
  SkanovaTicketCreate: 'skanova-ticket-create',
  NewFaultReportForm: 'new-fault-report-form',
  NewFaultReportSummary: 'new-fault-report-summary',
  ThankYouPage: 'thank-you-page'
}
