<template>
  <div
    ref="securedHeaderContainer"
    class="secured-header-container">
    <div class="container secured-navigation-container">
      <nav class="secured-navigation">
        <ul class="desktop-secured-menu">
          <li class="desktop-secured-menu-item">
            <tws-nav-link
              :href="pages.dashboard"
              exact>
              {{ $t('header.menu-items.dashboard') }}
            </tws-nav-link>
          </li>

          <li
            v-if="canUseSearchTools"
            class="desktop-secured-menu-item">
            <tws-borderless-button
              :class="{ 'color-core-purple' : megaMenus.searchTools.visible }"
              @click="toggleMegaMenu('searchTools')">
              {{ $t('header.menu-items.search-tools') }}
            </tws-borderless-button>
          </li>

          <li class="desktop-secured-menu-item">
            <tws-borderless-button
              :class="{ 'color-core-purple' : megaMenus.products.visible }"
              @click="toggleMegaMenu('products')">
              {{ $t('header.menu-items.orders') }}
            </tws-borderless-button>
          </li>

          <li class="desktop-secured-menu-item">
            <tws-borderless-button
              :class="{ 'color-core-purple' : megaMenus.myOrders.visible }"
              @click="toggleMegaMenu('myOrders')">
              {{ $t('header.menu-items.my-orders') }}
            </tws-borderless-button>
          </li>

          <li
            v-if="hasUserTicketsRole"
            class="desktop-secured-menu-item">
            <tws-nav-link
              :href="pages.felanmalan">
              {{ $t('header.menu-items.tickets') }}
            </tws-nav-link>
          </li>

          <li
            v-if="isDelegatedAdmin"
            class="desktop-secured-menu-item">
            <tws-nav-link :href="pages.userAdministration">
              {{ $t('header.menu-items.user-administration') }}
            </tws-nav-link>
          </li>

          <li
            v-if="customerTicketsEnabled"
            class="desktop-secured-menu-item">
            <tws-nav-link :href="pages.customerTickets">
              {{ $t('header.menu-items.customer-tickets') }}
            </tws-nav-link>
          </li>

          <li
            v-if="canAccessInvoicesOrSpecifications"
            class="desktop-secured-menu-item">
            <tws-nav-link :href="pages.myInvoices">
              {{ $t('header.menu-items.my-invoices') }}
            </tws-nav-link>
          </li>

          <li
            v-if="canAccessReportsInMenu"
            class="desktop-secured-menu-item">
            <tws-nav-link :href="pages.myReports">
              {{ $t('header.menu-items.my-reports') }}
            </tws-nav-link>
          </li>

          <li
            v-if="canAccessOnlyCompanyAdministrationSubMenu"
            class="desktop-secured-menu-item">
            <tws-nav-link :href="pages.companyAdministration">
              {{ $t('header.menu-items.company-administration') }}
            </tws-nav-link>
          </li>

          <li
            v-else-if="canAccessAdministrationSubMenu"
            class="desktop-secured-menu-item">
            <tws-borderless-button
              :class="{ 'color-core-purple' : megaMenus.administration.visible }"
              @click="toggleMegaMenu('administration')">
              {{ $t('header.menu-items.administration') }}
            </tws-borderless-button>
          </li>
        </ul>
      </nav>
    </div>

    <tws-mega-menu
      v-show="megaMenus.searchTools.visible"
      :categories="megaMenus.searchTools.categories" />

    <tws-mega-menu
      v-show="megaMenus.products.visible"
      :categories="orders"
      :loading="ordersLoading"
      :exception="ordersException">
      <div
        v-show="!ordersLoading && !ordersException"
        class="products-text col-12">
        <p>{{ $t('header.products-text') }}</p>
      </div>
    </tws-mega-menu>

    <tws-mega-menu
      v-show="megaMenus.myOrders.visible"
      :categories="myOrders"
      :loading="myOrdersLoading"
      :exception="myOrdersException" />

    <tws-mega-menu
      v-if="canAccessAdministrationSubMenu"
      v-show="megaMenus.administration.visible"
      :categories="megaMenus.administration.categories" />

    <tws-mega-menu
      v-show="megaMenus.tickets.visible"
      :categories="tickets"
      :loading="ticketsLoading"
      :exception="ticketsException">
      <feedback-button :link="felanmalanFeedbackLink" />
    </tws-mega-menu>
  </div>
</template>

<script>
import Feature from '@telia/infra-feature-manager'
import MegaMenu from '@/components/common/mega-menu/mega-menu'
import SecureMenuMixin from '@/mixins/secure-menu.mixin'
import ArrayUtils from '@/common/array-utils'

import {
  TwsBorderlessButton,
  TwsNavLink
} from 'tws-vue-components'
import FeedbackButton from './feedback-button.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    'tws-mega-menu': MegaMenu,
    TwsBorderlessButton,
    TwsNavLink,
    FeedbackButton
  },
  mixins: [SecureMenuMixin],
  props: {
    isPublicMegaMenuOpen: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      customerTicketsEnabled: Feature.CUSTOMER_TICKETS_LINK,
      megaMenus: {
        products: {
          visible: false,
          categories: []
        },
        searchTools: {
          visible: false,
          categories: []
        },
        myOrders: {
          visible: false,
          categories: []
        },
        tickets: {
          visible: false
        },
        administration: {
          visible: false,
          categories: []
        }
      },
      felanmalanFeedbackLink: process.env.VUE_APP_FELANMALAN_FEEDBACK
    }
  },
  computed: {
    ...mapGetters('eamCookies', [
      'canUseSearchTools',
      'isDelegatedAdmin',
      'hasUserTicketsRole'
    ])
  },
  watch: {
    isPublicMegaMenuOpen (isOpen) {
      if (isOpen) {
        this.collapseMegaMenu()
      }
    }
  },
  created () {
    document.addEventListener('click', this.closeMegaMenuEvent)

    this.megaMenus.searchTools.categories = this.constructSearchToolCategoriesChunked()

    if (this.canAccessAdministrationSubMenu) {
      this.megaMenus.administration.categories = this.constructAdministrationCategories()
    }
  },
  destroyed () {
    document.removeEventListener('click', this.closeMegaMenuEvent)
  },
  methods: {
    closeMegaMenuEvent (e) {
      const isClickedWithinHeader = this.$refs.securedHeaderContainer.contains(e.target)

      if (!isClickedWithinHeader) {
        this.collapseMegaMenu()
        return
      }

      if (isClickedWithinHeader && e.target.nodeName === 'A') {
        this.collapseMegaMenu()
      }
    },
    constructSearchToolCategoriesChunked () {
      const chunkedCategories = ArrayUtils.chunk(this.constructSearchToolCategories(), 3)

      return chunkedCategories.map(categories => {
        return {
          title: '',
          categories
        }
      })
    },
    constructAdministrationCategories () {
      return [
        {
          title: this.$t('header.administration-categories.user-administration'),
          link: this.pages.userAdministration,
          categories: [
            ...this.isSuperUser ? [{
              title: this.$t('header.administration-categories.user-deletion'),
              link: this.pages.userDeletion
            }] : [],
            ...this.canTransferUsers ? [{
              title: this.$t('header.administration-categories.user-transfer'),
              link: this.pages.userTransfer
            }] : []
          ]
        },
        ...this.canCreateCompanies || this.isSuperUser ? [{
          title: this.$t('header.administration-categories.company-administration'),
          link: this.pages.companyAdministration
        }] : [],
        ...this.canAdministrateReports ? [{
          title: this.$t('header.administration-categories.reports-administration'),
          link: this.pages.reportsAdministration,
          categories: [{
            title: this.$t('header.administration-categories.reports-manual-upload'),
            link: this.pages.reportsManualUpload
          }]
        }] : []
      ]
    },
    collapseMegaMenu () {
      Object.keys(this.megaMenus).forEach(key => {
        const menu = this.megaMenus[key]
        menu.visible = false
      })
    },
    toggleMegaMenu (name) {
      Object.keys(this.megaMenus).forEach(key => {
        const menu = this.megaMenus[key]

        menu.visible = key === name ? !menu.visible : false
        if (menu.visible) {
          this.$emit('onMegaMenuExpand')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.secured-header-container {
  @media(max-width: $breakpoint-md) {
    display: none;
  }
}

.desktop-secured-menu {
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-flow: row;
  list-style: none;
  margin-right: auto;

  &-item {
    font-size: 14px;

    &:not(:last-of-type) {
      margin-right: 35px;
    }

    &--active {
      color: $core-purple;
    }
  }
}

.products-text {
  color: #757575;
}

.tws-nav-link {
  display: inline-block;
}

.tws-borderless-button,
.tws-nav-link {
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1;
}
</style>
