import CompanyService from '@/services/company.service'
import router from '@/router/router'

export const CompanyCreationModule = {
  namespaced: true,
  state: {
    creating: false,
    exception: false,
    information: {
      code: '',
      name: '',
      orgNr: '',
      spId: '',
      selfAdministration: false
    }
  },
  mutations: {
    setCreating (state, creating) {
      state.creating = creating
    },
    setException (state, exception) {
      state.exception = exception
    },
    setInformation (state, information) {
      state.information = Object.assign({}, information)
    }
  },
  actions: {
    createCompany ({ commit, state }) {
      commit('setCreating', true)
      commit('setException', false)

      return CompanyService
        .create(state.information)
        .then(() => {
          const pathLocale = router.currentRoute.params?.locale
            ? `/${router.currentRoute.params.locale}` : ''

          window.location.href = pathLocale + process.env.VUE_APP_COMPANY_ADMINISTRATION_PATH + '?action=creation&company=' + state.information.name
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setCreating', false)
        })
    }
  }
}
