import {
  ref, withParams
} from 'vuelidate/lib/validators/common'

import DateTimeUtils from './datetime-utils'

/* eslint-disable no-useless-escape */
const INVALID_CHARS_REGEXP = /[^a-zA-Z0-9!@#$%^&*()_\-\+={\[}\]|\:;"'<,>.?/~`]/

const TWO_DIGITS_REGEXP = /[^\d]*\d[^\d]*\d.*/
const UPPERCASE_REGEXP = /[A-Z]/
const LOWERCASE_REGEXP = /[a-z]/
const SPECIAL_CHARS_REGEXP = /[!@#$%^&*()_\-\+={\[}\]|\:;"'<,>.?/~`]/

const EMAIL_REGEXP = /^(([a-zA-Z0-9\-\!#\$%&\'\*\/\=\?\^_`\{\|\}~]+(\.[a-zA-Z0-9\-_\!#\$%&\'\*/\=\?\^`\{\|\}~]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([a-zA-ZåäöÅÄÖ0-9]+(\-[a-zA-ZåäöÅÄÖ0-9]+)*(\.[a-zA-ZåäöÅÄÖ0-9]+(\-[a-zA-ZåäöÅÄÖ0-9]+)*)*\.([a-zA-Z0-9]+[a-zA-Z][a-zA-Z0-9]*|[a-zA-Z0-9]*[a-zA-Z][a-zA-Z0-9]+)))$/
const PHONE_REGEXP = /(\+)\d+$/
const MOBILE_PHONE_REGEXP = /(\+)\d+$/
const STREET_NAME_REGEXP = /^[A-Za-z*\s\055\072\305\304\326\344\345\366\311\351\374\334]{0,50}$/
const STREET_NUMBER_REGEXP = /^[0-9]{0,5}$/
const CITY_REGEXP = /^[A-Za-z*\s\055\072\305\304\326\344\345\366\311\351\374\334]{0,50}$/
const MUNICIPALITY_DISTRICT_REGEXP = /^[A-Za-zÅÄÖåäö\\s\\055\\072\\305\\304\\326\\344\\345\\366\\311\\351\\374\\334]{0,50}$/
const BLOCK_REGEXP = /^[A-Za-z0-9*-ÅÄÖåäö]{0,4}$/
const UNIT_REGEXP = /^[0-9*]{0,4}$/

const USERNAME_VALID_CHARS = /^[0-9A-Za-z][A-Za-z0-9]*(?:(-|_)[A-Za-z0-9]+)*$/
const COMPANY_CODE = /^[0-9]*$/
const ORG_NUMBER = /^[0-9]+(-[0-9]+)*$/

export const Expressions = {
  INVALID_CHARS_REGEXP,
  TWO_DIGITS_REGEXP,
  UPPERCASE_REGEXP,
  LOWERCASE_REGEXP,
  SPECIAL_CHARS_REGEXP,
  EMAIL_REGEXP,
  PHONE_REGEXP,
  STREET_NAME_REGEXP,
  STREET_NUMBER_REGEXP,
  CITY_REGEXP,
  MUNICIPALITY_DISTRICT_REGEXP,
  BLOCK_REGEXP,
  UNIT_REGEXP
}

export const usernameValidChars = value => {
  return USERNAME_VALID_CHARS.test(value)
}

export const companyCode = value => {
  return COMPANY_CODE.test(value)
}

export const orgNumber = value => {
  return ORG_NUMBER.test(value)
}

export const phone = value => {
  return !value || PHONE_REGEXP.test(value)
}

export const municipality = value => {
  return MUNICIPALITY_DISTRICT_REGEXP.test(value)
}

export const district = value => {
  return MUNICIPALITY_DISTRICT_REGEXP.test(value)
}

export const block = value => {
  return BLOCK_REGEXP.test(value)
}

export const unit = value => {
  return UNIT_REGEXP.test(value)
}

export const mobilePhone = value => {
  return !value || MOBILE_PHONE_REGEXP.test(value)
}

export const email = value => {
  return !value || EMAIL_REGEXP.test(value)
}

export const streetName = value => {
  return STREET_NAME_REGEXP.test(value)
}

export const streetNumber = value => {
  return STREET_NUMBER_REGEXP.test(value)
}

export const city = value => {
  return CITY_REGEXP.test(value)
}

export const invalidCharacter = value => {
  return !INVALID_CHARS_REGEXP.test(value)
}

export const twoDigits = value => {
  return TWO_DIGITS_REGEXP.test(value)
}

export const uppercase = value => {
  return UPPERCASE_REGEXP.test(value)
}

export const lowercase = value => {
  return LOWERCASE_REGEXP.test(value)
}

export const specialChar = value => {
  return SPECIAL_CHARS_REGEXP.test(value)
}

export const addressWildcard = value => {
  if (!value) {
    return true
  }

  const index = value.indexOf('*')
  return index === -1 || index > 1
}

export const addressMultiWildcard = prop => withParams({
  type: 'addressMultiWildcard',
  prop
}, (value, parentVm) => {
  const value2 = ref(prop, this, parentVm)

  if (!value || !value2) {
    return true
  }

  const index = value.indexOf('*')
  const index2 = value2.indexOf('*')

  return index < 2 || index2 < 2
})

export const cityWildcard = props => withParams({
  type: 'cityWildcard',
  props
}, (city, parentVm) => {
  const streetNumber = ref(props.streetNumber, this, parentVm)
  const entrance = ref(props.entrance, this, parentVm)

  if (!city || !streetNumber || !entrance) {
    return true
  }

  const index = city.indexOf('*')

  return index < 2
})

export const booleanTrue = value => {
  return value === true
}

export const todayOrAfter = date => {
  return DateTimeUtils.isTodayOrAfter(date)
}
