<template>
  <div>
    <component
      :is="currentRouteComponent"
      :route-index="routeIndex"
      :routes="routes"
      :can-go-back="canGoBackComputed"
      v-bind="$attrs"
      v-on="$listeners"
      @go-back="goBack"
      @advance="advance"
      @close="close"
      @complete="complete" />
  </div>
</template>

<script>

export default {
  name: 'Stepper',
  model: {
    prop: 'routeIndex',
    event: 'navigate'
  },
  props: {
    routes: {
      type: Array,
      required: true
    },
    routeIndex: {
      type: Number,
      default: 0
    },
    canGoBack: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentRouteComponent () {
      return this.routes[this.routeIndex].component
    },
    canAdvance () {
      return this.routeIndex < this.routes.length - 1
    },
    canGoBackComputed () {
      return this.routeIndex > 0 && this.canGoBack
    }
  },
  methods: {
    advance () {
      if (this.canAdvance) {
        this.$emit('navigate', this.routeIndex + 1)
      }
    },
    goBack () {
      if (this.canGoBackComputed) {
        this.$emit('navigate', this.routeIndex - 1)
      }
    },
    close () {
      this.$emit('close')
    },
    complete (payload) {
      this.$emit('complete', payload)
    }
  }
}
</script>
