<template>
  <div class="phone-container container">
    <Phone
      v-for="(item, index) in subCategory.fields.items"
      :key="index"
      :title="item.fields.title"
      :phone="item.fields.phone"
      :rate="item.fields.rate"
      :open-hours="item.fields.openHours"
      :shortcuts="item.fields.shortcuts ? item.fields.shortcuts : []" />
  </div>
</template>

<script>
import { Phone } from '../components/'

export default {
  components: { Phone },
  props: {
    subCategory: {
      type: Object,
      required: true
    }
  }
}
</script>
