<template>
  <div class="footer">
    <div class="navigation">
      <tws-footer-mobile
        class="mobile"
        :categories="categories" />

      <tws-footer-desktop
        class="desktop"
        :categories="categories" />
    </div>

    <tws-footer-information
      :addresses="addresses"
      :logo-link="logoLink"
      :integrity-link="integrityLink" />
  </div>
</template>

<script>
import Desktop from './footer-desktop/footer-desktop.vue'
import Mobile from './footer-mobile/footer-mobile.vue'
import Information from './footer-information/footer-information.vue'

export default {
  components: {
    'tws-footer-desktop': Desktop,
    'tws-footer-mobile': Mobile,
    'tws-footer-information': Information
  },
  data () {
    return {
      addresses: {
        address1: '© Telia Sverige AB 556430-0142<br> 169 94 Solna<br> Säte: Stockholm',
        address2: 'Skanova AB 556446-3734<br> Box 3010, 169 03 Solna<br> Säte: Solna'
      },
      logoLink: '/',
      integrityLink: '/public/var-integritetspolicy',
      categories: [
        {
          heading: this.$t('footer.about-us'),
          link: '/var-story',
          sub_categories: [
            {
              title: this.$t('footer.sustainability'),
              link: '/public/h-llbarhet',
              public: true
            },
            {
              title: this.$t('footer.security'),
              link: '/public/sakerhet',
              public: true
            },
            {
              title: this.$t('footer.campaigns'),
              link: '/private/Aktuella-kampanjer',
              public: false
            },
            {
              title: this.$t('footer.reference-offer'),
              link: '/public/referenserbjudande',
              public: true
            },
            {
              title: this.$t('footer.wholesale-in-nordics'),
              link: 'https://business.teliacompany.com/global-solutions/wholesale',
              public: true
            },
            {
              title: this.$t('footer.work-at-telia'),
              link: 'https://www.teliacompany.com/en/careers/open-positions',
              public: true
            },
            {
              title: this.$t('footer.contractors'),
              link: '/private/vara-entreprenorer',
              public: false
            }
          ]
        },
        {
          heading: this.$t('footer.network-changes'),
          link: '',
          public: true,
          sub_categories: [
            {
              title: this.$t('footer.station-modernization'),
              link: '/private/stationsmodernisering',
              public: false
            },
            {
              title: this.$t('footer.network-of-future'),
              link: '/public/framtidens-nat',
              public: true
            },
            {
              title: this.$t('footer.network-and-tools'),
              link: '/natinformation/natforandringar',
              public: true
            },
            {
              title: this.$t('footer.disturbances-in-telecommunications'),
              link: '/public/felanmalan/Information_om_fel_i_telenatet',
              public: true
            },
            {
              title: this.$t('footer.market-consultations'),
              link: '/samrad',
              public: false
            }
          ]
        },
        {
          heading: this.$t('footer.contact'),
          link: '/kontakt',
          sub_categories: [
            {
              title: this.$t('footer.contact-us'),
              link: '/kontakt',
              public: true
            },
            {
              title: this.$t('footer.private-individuals'),
              link: 'https://natet.telia.se',
              public: true
            },
            {
              title: this.$t('footer.property-owners'),
              link: 'https://natet.telia.se',
              public: true
            },
            {
              title: this.$t('footer.network-owners'),
              link: '/public/Natagare',
              public: true
            }
          ]
        },
        {
          heading: this.$t('footer.your-integrity'),
          link: '/public/var-integritetspolicy',
          public: true,
          sub_categories: [
            {
              title: this.$t('footer.our-privacy-policy'),
              link: '/public/var-integritetspolicy',
              public: true
            },
            {
              title: this.$t('footer.our-cookie-policy'),
              link: '/privat/om/integritetspolicy/cookiepolicy',
              public: true
            },
            {
              title: this.$t('footer.data-processing-agreement'),
              link: '/private/personuppgiftsbitradesavtal',
              public: false
            },
            {
              title: this.$t('footer.order-registry-extract'),
              link: '/public/var-integritetspolicy/individuella-rattigheter',
              public: false
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'tws-core-atoms/variables.scss';

.footer {
  color: $light-purple;
}

.footer::v-deep .tws-nav-link,
.footer::v-deep .tws-borderless-button {
  color: $light-purple;

  &:hover {
    color: $medium-grey;
  }
}

.navigation {
  background-color: $deep-purple;
  padding-top: 38px;
  padding-bottom: 32px;
}

@media (max-width: 991px) {
  .desktop {
    display: none;
  }

  .navigation {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .mobile {
    display: none;
  }
}
</style>
