<template>
  <PropertySearchView
    class="property-address-picker__container"
    :title="headerTitle"
    :can-go-back="true"
    @go-back="$emit('go-back')"
    @close="$emit('close')">
    <p class="property-address-picker__sub-title">
      {{ $t('property-search.address-picker.sub-title') }}
    </p>

    <FlexTable
      :data="addresses"
      :keys="addressHeaders"
      @click="pickAddress" />
  </PropertySearchView>
</template>

<script>
import { mapGetters } from 'vuex'
import FlexTable from '@/apps/address-search/components/FlexTable'
import { printProperty } from '@/apps/address-search/utils/string-utils'
import PropertySearchView from './PropertySearchView.vue'

export default {
  components: {
    FlexTable,
    PropertySearchView
  },
  data () {
    return {
      addressHeaders: [
        {
          key: 'streetName',
          label: this.$t('property-search.address-headers.street-name'),
          width: 2
        },
        {
          key: 'streetNumber',
          label: this.$t('property-search.address-headers.street-number')
        },
        {
          key: 'entrance',
          label: this.$t('property-search.address-headers.entrance')
        },
        {
          key: 'city',
          label: this.$t('property-search.address-headers.city'),
          width: 2
        }
      ]
    }
  },
  computed: {
    ...mapGetters('AddressSearch/PropertySearch', [
      'addresses',
      'municipality',
      'district',
      'block',
      'unit'
    ]),
    headerTitle () {
      return printProperty({
        municipality: this.municipality,
        district: this.district,
        block: this.block,
        unit: this.unit
      })
    }
  },
  methods: {
    pickAddress (address) {
      this.$emit('complete', address)
    }
  }
}
</script>

<style lang="scss" scoped>
.property-address-picker {
  &__sub-title {
    margin: 0 0 28px;
  }
}
</style>
