<template>
  <div class="container">
    <div class="row">
      <div
        v-for="category in categories"
        :key="category.heading"
        class="col-3">
        <h2 class="heading">
          <tws-nav-link
            v-if="category.link"
            :href="category.link">
            {{ category.heading }}
          </tws-nav-link>

          <template v-else>
            {{ category.heading }}
          </template>
        </h2>

        <tws-sub-categories :categories="category.sub_categories" />
      </div>
    </div>
  </div>
</template>

<script>
import SubCategories from '../footer-sub-categories/footer-sub-categories.vue'
import { TwsNavLink } from 'tws-vue-components'

export default {
  components: {
    'tws-sub-categories': SubCategories,
    TwsNavLink
  },
  props: {
    categories: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
}
</style>
