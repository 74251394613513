<template>
  <div class="specifications-menu">
    <router-link
      v-for="(item, index) in items"
      :key="index"
      :to="item.path"
      class="specifications-menu__item"
      :class="{ 'specifications-menu__item--locked': isItemLocked(item) }">
      {{ item.title }}

      <TeliaCoreIcon
        v-if="isItemLocked(item)"
        name="security_thin"
        size="20px" />
    </router-link>
  </div>
</template>

<script>
import { isLoggedIn } from '@/utils/user.utils'

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    isItemLocked (item) {
      return !item.public && !isLoggedIn()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.specifications-menu {
  display: flex;
  flex-direction: column;
  background-color: $white;

  @media (max-width: $breakpoint-md) {
    margin-bottom: 45px;
  }

  &__item {
    display: block;
    padding: 20px;
    font-weight: bold;
    border-top: 1px solid $medium-grey;
    border-left: 1px solid $medium-grey;
    border-right: 1px solid $medium-grey;
    text-decoration: none;
    color: $black;

    &:hover {
      color: $core-purple
    }

    &:first-of-type {
      border-top: 1px solid $medium-grey;
    }

    &:last-of-type {
      border-bottom: 1px solid $medium-grey;
    }

    &.router-link-active {
      color: $white;
      background-color: $core-purple;
    }

    &--locked {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $dark-grey;
    }
  }
}
</style>
