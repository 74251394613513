import { render, staticRenderFns } from "./planned-news.vue?vue&type=template&id=2337e8b1&scoped=true"
import script from "./planned-news.vue?vue&type=script&lang=js"
export * from "./planned-news.vue?vue&type=script&lang=js"
import style0 from "./planned-news.vue?vue&type=style&index=0&id=2337e8b1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2337e8b1",
  null
  
)

export default component.exports