<template>
  <div
    v-if="isValidatorCreated && getValidatorFormField(labels.field_name)"
    :style="widthStyle"
    @click="showDependencyMessage">
    <tws-validated-radio-group
      :id="'form-radio' + labels.field_name"
      v-model="model"
      :label-style="computeStyle"
      :options="mappedOptions"
      :label="labels.radio_button_label"
      :validator="getValidatorFormField(labels.field_name)"
      :validation-messages="validationMessages"
      :disabled="disabledField(labels.field_name)" />
    <p
      v-show="showFieldMessage"
      class="dependency-text">
      {{ dependencyMessage }}
    </p>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import { TwsValidatedRadioGroup } from 'tws-vue-components'

export default {
  name: 'TwsFormRadioGroup',
  components: {
    TwsValidatedRadioGroup
  },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        required: this.labels.validation_message
      },
      showFieldMessage: false
    }
  },
  computed: {
    ...mapGetters('DynamicForm', [
      'getValidatorFormField',
      'isValidatorCreated',
      'disabledField',
      'getFieldDependencyMessage',
      'getFieldValue'
    ]),
    model: {
      get () {
        return this.getFieldValue(this.labels.field_name)
      },
      set (value) {
        this.onValueInput(value)
      }
    },
    mappedOptions () {
      return Object
        .entries(this.labels.radio_button_group).map((v) => [
          Number(v[0]),
          v[1]
        ])
        .sort((a, b) => a[0] - b[0]).map(option => option[1]).sort((a, b) => a.order - b.order)
    },
    dependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        return this.getFieldDependencyMessage(this.labels.field_name)
      }
      return ''

    },
    computeStyle () {
      return 'font-weight: ' + this.labels.style + ';'
    },
    widthStyle () {
      const componentWidth = this.labels.component_width
      return componentWidth ? 'width: ' + componentWidth + '%;' : ''
    }
  },
  mounted () {
    this.addValidation(
      {
        fieldName: this.labels.field_name,
        validations: this.labels.validations
      })
    this.fieldCreated(
      {
        fieldName: this.labels.field_name,
        label: this.labels.radio_button_label,
        templateText: this.labels.template_text,
        isFieldRequired: this.labels.is_field_required
      })
  },
  methods: {
    ...mapActions('DynamicForm', [
      'fieldCreated',
      'fieldValueEntered',
      'addValidation'
    ]),
    onValueInput (value) {
      this.fieldValueEntered(
        {
          key: this.labels.field_name,
          value: value,
          email: Object.values(this.labels.radio_button_group).filter(option => option.value === value).map(option => option.email)[0]
        }
      )
    },
    showDependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        this.showFieldMessage = !this.showFieldMessage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dependency-text {
    color: grey;
    font-size: medium;
  }
</style>
