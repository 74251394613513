<template>
  <div class="specification">
    <InfraContentfulRichTextContainer
      v-if="content.richText"
      :content="content.richText" />
    <InfraAccordion
      v-if="content.faq"
      :heading="content.faq.heading">
      <p class="preamble mb-5">
        {{ content.faq.description }}
      </p>
      <InfraAccordionItem
        v-for="(faq, idx) in content.faq.faqItems"
        :key="idx"
        class="specification--accordion-item"
        :title="faq.fields.question">
        <InfraContentfulRichTextContainer
          class="mt-5 mb-5"
          :content="faq.fields.answer" />
      </InfraAccordionItem>
    </InfraAccordion>
  </div>
</template>

<script>
import {
  InfraContentfulRichTextContainer,
  InfraAccordion,
  InfraAccordionItem
} from 'tws-vue-components'

export default {
  components: {
    InfraContentfulRichTextContainer,
    InfraAccordion,
    InfraAccordionItem
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.specification {
  position: relative;
  z-index: 0;

  &:not(:first-of-type) {
    padding-top: 50px;
  }

  &:not(:last-of-type) {
    padding-bottom: 50px;
  }

  @media (min-width: $breakpoint-md) {
    margin-right: 100px;
  }

  &:nth-child(even):before {
    content: '';
    background-color: $light-grey;
    position: absolute;
    width: 5000px;
    height: 100%;
    left: -2500px;
    bottom: 0;
    z-index: -1;
  }

  &--accordion-item {
    ::v-deep h3  span {
      max-width: 80%;
    }
  }
}
</style>
