import i18n from '@/i18n'

const isEn = i18n.locale === 'en'

const buildProductLink = (categoryPath, productPath) => {
  const partialPath = isEn ? '/en' : ''

  const pathToCategory = `${partialPath}/products/${categoryPath}`

  if (productPath) {
    return `${pathToCategory}/${productPath}`
  }

  return pathToCategory
}

const extractCta = cta => {
  if (!cta || !cta.fields) {
    return null
  }
  return {
    text: cta.fields.text,
    url: cta.fields.linkTrigger
  }
}

const extractProductsContainerIcon = icon => {
  if (!icon || !icon.fields) {
    return null
  }
  return {
    iconUrl: icon.fields.file.url
  }
}

const extractHero = hero => {
  if (!hero || !hero.fields) {
    return null
  }

  const fields = hero.fields

  return {
    img: {
      alt: 'Landing Page Hero',
      url: fields.backgroundImage.fields.file.url
    },
    heading: fields.heading,
    paragraph: fields.paragraph,
    cta: extractCta(fields.cta)
  }
}

const extractPromotionCardsIcons = cards => {
  if (!cards || !cards.fields || !cards.fields.icons || !cards.fields.icons.length) {
    return null
  }

  const icons = cards.fields.icons

  return icons.map(icon => {
    const fields = icon.fields

    return {
      iconUrl: fields.icon.fields.file.url,
      text: fields.iconText
    }
  })
}

const extractProducts = categoryProductLinks => {
  return categoryProductLinks.map(p => {
    const productTitle = p.fields.product?.fields.title
    const categoryTitle = p.fields.productCategory.fields.title

    return {
      title: productTitle || categoryTitle,
      path: buildProductLink(p.fields.productCategory.fields.path, p.fields.product?.fields.path),
      description: p.fields.product?.fields?.shortDescription || ''
    }
  })
}

const extractPromotionCards = promotionCards => {
  return {
    title: promotionCards.fields.heading,
    cards: extractPromotionCardsIcons(promotionCards)
  }
}

const buildCard = card => {
  return {
    title: card.heading,
    paragraph: card.paragraph,
    buttonText: card.cta.fields.text,
    buttonLink: card.cta.fields.linkTrigger,
    imgSrc: card.backgroundImage.fields.file.url,
    altText: card.backgroundImage.fields.description
  }
}

const extractCarousel = carousel => {
  return {
    title: carousel.fields.title,
    cards: carousel.fields.carouselCards.map(c => buildCard(c.fields))
  }
}

export const extractContent = response => {
  return {
    hero: extractHero(response.heroBanner),
    productIcon: extractProductsContainerIcon(response.productsContainerIcon),
    products: extractProducts(response.categoryProductLinks),
    promotionCards: extractPromotionCards(response.promotionCards),
    carousel: extractCarousel(response.bottomCarousel)
  }
}

export const transformNewsArticles = (articles) => {
  const newestArticles = articles.sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))

  return newestArticles.map(article => ({
    id: article.sys.id,
    title: article.title,
    publishDate: new Date(article.publishDate).toLocaleDateString('sv'),
    leadParagraph: article.leadParagraph,
    linkUrl: `${isEn ? '/en' : ''}/news-archive/news-page?news-id=${article.sys.id}`
  }))
}
