<template>
  <div class="action-panel-conversation">
    <h2>{{ $t('details.activity-log') }}</h2>

    <InfraReportingActionPanel
      v-if="isFaultReportingConversationPanelShown"
      :label-conversation-title="$t('details.action-panel.conversation.message-label')"
      :label-attachments-add="$t('details.action-panel.attachments.add')">
      <template #conversation>
        <FaultReportActionPanelConversation />
      </template>
      <template #attachments>
        <FaultReportActionPanelAttachments />
      </template>
    </InfraReportingActionPanel>

    <div
      v-else
      class="row">
      <div class="col-lg-7 col-md-10 col-sm-12">
        <InfraPageAlert
          type="info">
          {{ $t('fault-reporting.closed-case-message') }}
        </InfraPageAlert>
      </div>
    </div>

    <h3>{{ $t('details.messages') }}</h3>
    <div
      v-if="comments.length <= 0"
      class="tc-title tc-title--s mb-5">
      {{ $t('details.action-panel.conversation.no-messages') }}
    </div>

    <InfraReportingConversation
      v-else
      :items="comments"
      content-property="text"
      date-property="date"
      :is-sent-by-customer-eval-func="isSentByCustomer"
      :resolved-created-by-func="resolveCreatedByName"
      :label-customer="$t('activity-log.activity-log')" />
  </div>
</template>

<script>
import {
  InfraReportingConversation, InfraReportingActionPanel, InfraPageAlert
} from 'tws-vue-components'
import FaultReportActionPanelAttachments
  from '@/apps/fault-reporting/views/action-panel/FaultReportActionPanelAttachments'
import FaultReportActionPanelConversation
  from '@/apps/fault-reporting/views/action-panel/FaultReportActionPanelConversation'
import { STATES } from '@/apps/fault-reporting/constants/fault-report-case-list.contants'

export default {
  components: {
    FaultReportActionPanelConversation,
    FaultReportActionPanelAttachments,
    InfraReportingConversation,
    InfraReportingActionPanel,
    InfraPageAlert
  },
  props: {
    comments: {
      type: Array,
      required: true
    },
    caseStatus: {
      type: String,
      required: true
    }
  },
  computed: {
    isFaultReportingConversationPanelShown () {
      return this.caseStatus !== STATES.CLOSED
    }
  },
  methods: {
    isSentByCustomer (item) {
      return !item.author || item.author === 'apigarden'
    },
    resolveCreatedByName (item) {
      return this.isSentByCustomer(item) ? this.$t('details.action-panel.conversation.customer') : 'Telia'
    }
  }
}
</script>
