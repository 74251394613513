import NewsService from '@/services/news.service'
import i18n from '@/i18n'

const breadcrumbItemsNews = [
  {
    title: i18n.t('breadcrumbs.dashboard.title'),
    path: '/dashboard',
    routerLink: true
  },
  {
    title: i18n.t('news.breadcrumbs.news-archive'),
    path: '/news-archive',
    routerLink: true
  }
]

export const NewsDetailsModule = {
  namespaced: true,
  state: {
    news: {},
    loading: true,
    loaded: false,
    exception: false
  },
  mutations: {
    setNews (state, news) {
      state.news = news
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    }
  },
  actions: {
    loadPublicNews ({ commit }, newsId) {
      return NewsService.getNewsDetails(newsId)
        .then(result => {
          const news = {
            breadcrumbItems: [
              ...breadcrumbItemsNews,
              {
                title: result.title
              }
            ],
            ...result
          }

          commit('setNews', news)
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    loadPrivateNews ({ commit }, newsId) {
      return NewsService.getNewsDetails(newsId)
        .then(result => {

          const news = {
            breadcrumbItems: [
              ...breadcrumbItemsNews,
              {
                title: result.title
              }
            ],
            ...result
          }

          commit('setNews', news)
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  }
}
