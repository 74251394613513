<template>
  <div class="row fault-report-mobile-sp">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <p class="title-200">
            {{ $t('fault-reporting.form.description.description') }}
          </p>
        </div>
      </div>

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            id="isProblemPersistIfTrafficControlIsInactivated"
            v-model="isProblemPersistIfTrafficControlIsInactivatedProxy"
            :validation-messages="validationMessages.isProblemPersistIfTrafficControlIsInactivated"
            :validator="$v.isProblemPersistIfTrafficControlIsInactivated"
            :label="$t('fault-reporting.form.description.is-problem-persist-if-traffic-control-is-inactivated.label')" />
        </div>

        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            id="isTerminalRestartedUpdated"
            v-model="isTerminalRestartedUpdatedProxy"
            :validation-messages="validationMessages.isTerminalRestartedUpdated"
            :validator="$v.isTerminalRestartedUpdated"
            :label="$t('fault-reporting.form.description.is-terminal-restarted-updated.label')" />
        </div>
      </div>

      <hr class="divider">

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            id="isProblemInDifferentTerminal"
            v-model="isProblemInDifferentTerminalProxy"
            :validation-messages="validationMessages.isProblemInDifferentTerminal"
            :validator="$v.isProblemInDifferentTerminal"
            :label="$t('fault-reporting.form.description.is-problem-in-different-terminal.label')" />
        </div>

        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            id="isLteOrWifiCallingActivated"
            v-model="isLteOrWifiCallingActivatedProxy"
            :validation-messages="validationMessages.isLteOrWifiCallingActivated"
            :validator="$v.isLteOrWifiCallingActivated"
            :label="$t('fault-reporting.form.description.is-lte-or-wifi-calling-activated.label')" />
        </div>
      </div>

      <hr class="divider">

      <template v-if="isProblemAbroadSelected">
        <div class="row mt-5 fieldset">
          <div class="col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedTextInputGroup
              id="networkProvider"
              v-model="networkProviderProxy"
              :validator="$v.networkProvider"
              :validation-messages="validationMessages.networkProvider"
              :label="$t('fault-reporting.form.description.network-provider.label')" />
          </div>

          <div class="col-xl-5 col-sm-5 col-xs-12">
            <HorizontalRadioButtons
              id="isSelectingProviderManuallyTried"
              v-model="isSelectingProviderManuallyTriedProxy"
              :validation-messages="validationMessages.isSelectingProviderManuallyTried"
              :validator="$v.isSelectingProviderManuallyTried"
              :label="$t('fault-reporting.form.description.is-selecting-provider-manually-tried.label')" />

            <TwsValidatedTextInputGroup
              v-if="isSelectingProviderManuallyTried"
              id="selectedProvider"
              v-model="selectedProviderProxy"
              :validator="$v.selectedProvider"
              :validation-messages="validationMessages.selectedProvider"
              :label="$t('fault-reporting.form.description.specify-selected-provider.label')" />
          </div>
        </div>

        <hr class="divider">

        <div class="row mt-5 fieldset">
          <div class="col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedTextInputGroup
              id="country"
              v-model="countryProxy"
              :validator="$v.country"
              :validation-messages="validationMessages.country"
              :label="$t('fault-reporting.form.description.country.label')" />
          </div>

          <div class="col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedTextInputGroup
              id="city"
              v-model="cityProxy"
              :validator="$v.city"
              :validation-messages="validationMessages.city"
              :label="$t('fault-reporting.form.description.city.label')" />
          </div>
        </div>

        <hr class="divider">

        <div class="row mt-5 fieldset">
          <div class="col-xl-5 col-sm-5 col-xs-12">
            <HorizontalRadioButtons
              id="isRoamingAllowedForTerminal"
              v-model="isRoamingAllowedForTerminalProxy"
              :validation-messages="validationMessages.isRoamingAllowedForTerminal"
              :validator="$v.isRoamingAllowedForTerminal"
              :label="$t('fault-reporting.form.description.is-roaming-allowed-for-terminal.label')" />
          </div>
        </div>

        <hr class="divider">
      </template>

      <div class="row mt-5 fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <TwsValidatedTextInputGroup
            id="numberAffected"
            v-model="numberAffectedProxy"
            :validator="$v.numberAffected"
            :validation-messages="validationMessages.numberAffected"
            :label="$t('fault-reporting.form.description.number-affected.label')" />
        </div>

        <div class="col-xl-5 col-sm-5 col-xs-12">
          <HorizontalRadioButtons
            id="areCustomersExperiencingSameProblem"
            v-model="areCustomersExperiencingSameProblemProxy"
            :validation-messages="validationMessages.areCustomersExperiencingSameProblem"
            :validator="$v.areCustomersExperiencingSameProblem"
            :label="$t('fault-reporting.form.description.are-customers-experiencing-same-problem.label')" />

          <TwsValidatedTextInputGroup
            v-if="areCustomersExperiencingSameProblemProxy"
            id="numbersOfAffectedCustomers"
            v-model="numbersOfAffectedCustomersProxy"
            :validator="$v.numbersOfAffectedCustomers"
            :validation-messages="validationMessages.numbersOfAffectedCustomers"
            :label="$t('fault-reporting.form.description.numbers-of-affected-customers.label')" />
        </div>
      </div>

      <hr class="divider">

      <template v-if="!isProblemAbroadSelected">
        <div class="row mt-5">
          <div class="col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedTextInputGroup
              id="adressWhereProblemHappened"
              v-model="adressWhereProblemHappenedProxy"
              :validator="$v.adressWhereProblemHappened"
              :validation-messages="validationMessages.adressWhereProblemHappened"
              :label="$t('fault-reporting.form.description.adress-where-problem-happened.label')" />
          </div>
        </div>

        <hr class="divider">
      </template>

      <div class="row mt-5">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <TwsValidatedDatePicker
            id="problemOccurenceDate"
            v-model="problemOccurenceDateProxy"
            :show-icon="true"
            :read-only="true"
            :validator="$v.problemOccurenceDate"
            :validation-messages="validationMessages.problemOccurenceDate"
            :options="datePickerOptions"
            :label="$t('fault-reporting.form.description.problem-occurence-date.label')"
            required />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="problemCustomerExperiencingDescription"
            v-model="problemCustomerExperiencingDescriptionProxy"
            :validator="$v.problemCustomerExperiencingDescription"
            :validation-messages="validationMessages.problemCustomerExperiencingDescription"
            :label="$t('fault-reporting.form.description.problem-customers-experiencing-description.label')" />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <InfraFilePicker
            v-model="attachmentsProxy"
            class="mb-5"
            button-type="primary"
            :acceptable-file-extensions="acceptableFileExtensions"
            :limit="attachmentsLimit"
            :file-size-limit="defaultFileSizeLimit"
            :show-add-icon="true"
            :add-file-text="$t('fault-reporting.form.attachments-button.button-text')"
            :limit-is-reached-text="$t('fault-reporting.form.attachments-button.limit-is-reached-text').replace('limit', attachmentsLimit)"
            :size-limit-text="$t('fault-reporting.form.attachments-button.size-is-reached-text-5mb')" />
        </div>
      </div>

      <div class="row">
        <p class="col-12 font-weight-medium">
          {{ $t('fault-reporting.form.description.state-examples-when-customer-experienced-problem.label') }}<br>
          {{ $t('fault-reporting.form.description.state-examples-when-customer-experienced-problem.sub-label') }}
        </p>
      </div>

      <div
        v-for="(v, index) in $v.stateExamplesWhenCustomerExperiencedProblem.$each.$iter"
        :key="`${v.date.value}-${index}`">
        <div class="row mt-2">
          <div class="col-xl-5 col-sm-5 col-xs-12">
            <TwsValidatedDatePicker
              :id="`stateExampleDate-${index}`"
              v-model="v.date.$model"
              :show-icon="true"
              :read-only="true"
              :validator="v.date"
              :validation-messages="validationMessages.stateExamplesWhenCustomerExperiencedProblem"
              :options="datePickerOptions"
              :label="`#${Number(index) + 1} ${$t('common.date_time')}`"
              required />
          </div>

          <div class="col-xl-10 col-sm-10 col-xs-12">
            <TwsValidatedTextAreaGroup
              :id="`stateExampleDescription-${index}`"
              v-model="v.description.$model"
              :validator="v.description"
              :validation-messages="validationMessages.stateExamplesWhenCustomerExperiencedProblem"
              :label="$t('fault-reporting.form.description.state-examples-description.label')" />
          </div>

          <div class="col-12 mb-5">
            <TwsButtonPrimary
              v-if="Number(index) > 2"
              @click="removeStateExample(index)">
              <div class="button-wrapper">
                <TeliaCoreIcon
                  class="notification-input__trash-icon__icon"
                  name="trash_empty_thin"
                  size="20px" />

                <span>{{ $t('common.remove') }}</span>
              </div>
            </TwsButtonPrimary>
          </div>
        </div>
      </div>

      <TwsButtonPrimary
        v-if="stateExamplesWhenCustomerExperiencedProblemProxy.length < maxStateExamples"
        class="mb-5"
        @click="addEmptyStateExample">
        <div class="button-wrapper">
          <TeliaCoreIcon
            class="icon"
            name="add_thin"
            size="20px" />
          <span>{{ $t('common.add_example') }}</span>
        </div>
      </TwsButtonPrimary>

      <hr class="divider">

      <div class="row mt-5">
        <div class="col-xl-10 col-sm-10 col-xs-12">
          <TwsValidatedTextAreaGroup
            id="whatTroubleshootingPerformed"
            v-model="whatTroubleshootingPerformedProxy"
            :validator="$v.whatTroubleshootingPerformed"
            :validation-messages="validationMessages.whatTroubleshootingPerformed"
            :label="$t('fault-reporting.form.description.what-troubleshooting-performed.label')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TwsValidatedTextAreaGroup,
  TwsValidatedTextInputGroup,
  TwsValidatedDatePicker,
  TwsButtonPrimary,
  InfraFilePicker
} from 'tws-vue-components'
import HorizontalRadioButtons from '@/apps/fault-reporting/components/form/HorizontalRadioButtons'
import {
  mapState, mapActions
} from 'vuex'
import {
  required, minLength, requiredIf
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  ATTACHMENTS_ALLOWED_EXTENSIONS, ATTACHMENTS_FILE_SIZE_LIMIT_5MB,
  ATTACHMENTS_LIMIT, DATE_PICKER_OPTIONS, NETWORK_OPTIONS, PROBLEM_TYPE_DROPDOWN_OPTION, MAX_STATE_EXAMPLES
} from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  components: {
    HorizontalRadioButtons,
    TwsValidatedTextAreaGroup,
    TwsValidatedTextInputGroup,
    TwsValidatedDatePicker,
    TwsButtonPrimary,
    InfraFilePicker
  },

  mixins: [validationMixin],

  data () {
    return {
      attachmentsLimit: ATTACHMENTS_LIMIT,
      acceptableFileExtensions: ATTACHMENTS_ALLOWED_EXTENSIONS,
      defaultFileSizeLimit: ATTACHMENTS_FILE_SIZE_LIMIT_5MB,
      datePickerOptions: DATE_PICKER_OPTIONS,
      networkOptions: NETWORK_OPTIONS,
      maxStateExamples: MAX_STATE_EXAMPLES,
      validationMessages: {
        isProblemPersistIfTrafficControlIsInactivated: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        isTerminalRestartedUpdated: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        isProblemInDifferentTerminal: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        networkProvider: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        isSelectingProviderManuallyTried: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        selectedProvider: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        country: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        city: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        isRoamingAllowedForTerminal: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        isLteOrWifiCallingActivated: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        numberAffected: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        areCustomersExperiencingSameProblem: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        numbersOfAffectedCustomers: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        adressWhereProblemHappened: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        problemOccurenceDate: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        problemCustomerExperiencingDescription: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        stateExamplesWhenCustomerExperiencedProblem: {
          required: this.$t('fault-reporting.default-required-validation-message'),
          minLength: this.$t('fault-reporting.form.description.state-examples-when-customer-experienced-problem.validation-message-min-length')
        },
        whatTroubleshootingPerformed: {
          required: this.$t('fault-reporting.default-required-validation-message')
        }
      }
    }
  },

  computed: {
    ...mapState('FaultReporting/FaultReportForm/MobileSP', [
      'isProblemPersistIfTrafficControlIsInactivated',
      'isTerminalRestartedUpdated',
      'isProblemInDifferentTerminal',
      'networkProvider',
      'isSelectingProviderManuallyTried',
      'selectedProvider',
      'country',
      'city',
      'isRoamingAllowedForTerminal',
      'isLteOrWifiCallingActivated',
      'numberAffected',
      'areCustomersExperiencingSameProblem',
      'numbersOfAffectedCustomers',
      'adressWhereProblemHappened',
      'problemOccurenceDate',
      'problemCustomerExperiencingDescription',
      'attachments',
      'stateExamplesWhenCustomerExperiencedProblem',
      'whatTroubleshootingPerformed'
    ]),
    ...mapState('FaultReporting/FaultReportForm/BasicInfo', ['problemType']),
    isProblemAbroadSelected () {
      return this.problemType.value === PROBLEM_TYPE_DROPDOWN_OPTION.PROBLEM_ABROAD.value
    },
    isProblemPersistIfTrafficControlIsInactivatedProxy: {
      get () {
        return this.isProblemPersistIfTrafficControlIsInactivated
      },
      set (value) {
        this.updateIsProblemPersistIfTrafficControlIsInactivated(value)
      }
    },
    isTerminalRestartedUpdatedProxy: {
      get () {
        return this.isTerminalRestartedUpdated
      },
      set (value) {
        this.updateIsTerminalRestartedUpdated(value)
      }
    },
    isProblemInDifferentTerminalProxy: {
      get () {
        return this.isProblemInDifferentTerminal
      },
      set (value) {
        this.updateIsProblemInDifferentTerminal(value)
      }
    },
    networkProviderProxy: {
      get () {
        return this.networkProvider
      },
      set (value) {
        this.updateNetworkProvider(value)
      }
    },
    isSelectingProviderManuallyTriedProxy: {
      get () {
        return this.isSelectingProviderManuallyTried
      },
      set (value) {
        this.updateIsSelectingProviderManuallyTried(value)
      }
    },
    selectedProviderProxy: {
      get () {
        return this.selectedProvider
      },
      set (value) {
        this.updateSelectedProvider(value)
      }
    },
    countryProxy: {
      get () {
        return this.country
      },
      set (value) {
        this.updateCountry(value)
      }
    },
    cityProxy: {
      get () {
        return this.city
      },
      set (value) {
        this.updateCity(value)
      }
    },
    isRoamingAllowedForTerminalProxy: {
      get () {
        return this.isRoamingAllowedForTerminal
      },
      set (value) {
        this.updateIsRoamingAllowedForTerminal(value)
      }
    },
    isLteOrWifiCallingActivatedProxy: {
      get () {
        return this.isLteOrWifiCallingActivated
      },
      set (value) {
        this.updateIsLteOrWifiCallingActivated(value)
      }
    },
    numberAffectedProxy: {
      get () {
        return this.numberAffected
      },
      set (value) {
        this.updateNumberAffected(value)
      }
    },
    areCustomersExperiencingSameProblemProxy: {
      get () {
        return this.areCustomersExperiencingSameProblem
      },
      set (value) {
        this.updateAreCustomersExperiencingSameProblem(value)
      }
    },
    numbersOfAffectedCustomersProxy: {
      get () {
        return this.numbersOfAffectedCustomers
      },
      set (value) {
        this.updateNumbersOfAffectedCustomers(value)
      }
    },
    adressWhereProblemHappenedProxy: {
      get () {
        return this.adressWhereProblemHappened
      },
      set (value) {
        this.updateAdressWhereProblemHappened(value)
      }
    },
    problemOccurenceDateProxy: {
      get () {
        return this.problemOccurenceDate
      },
      set (value) {
        this.updateProblemOccurenceDate(value)
      }
    },
    problemCustomerExperiencingDescriptionProxy: {
      get () {
        return this.problemCustomerExperiencingDescription
      },
      set (value) {
        this.updateProblemCustomerExperiencingDescription(value)
      }
    },
    attachmentsProxy: {
      get () {
        return this.attachments
      },
      set (value) {
        this.updateAttachments(value)
      }
    },
    stateExamplesWhenCustomerExperiencedProblemProxy: {
      get () {
        return this.stateExamplesWhenCustomerExperiencedProblem
      },
      set (value) {
        this.updateStateExamplesWhenCustomerExperiencedProblem(value)
      }
    },
    whatTroubleshootingPerformedProxy: {
      get () {
        return this.whatTroubleshootingPerformed
      },
      set (value) {
        this.updateWhatTroubleshootingPerformed(value)
      }
    },
    isValidProxy () {
      return !this.$v.$invalid
    }
  },

  watch: {
    isValidProxy (val) {
      this.updateIsValid(val)
    }
  },

  created () {
    if (!this.stateExamplesWhenCustomerExperiencedProblemProxy?.length) {
      this.updateStateExamplesWhenCustomerExperiencedProblem([
        {
          date: '',
          description: ''
        },
        {
          date: '',
          description: ''
        },
        {
          date: '',
          description: ''
        }
      ])
    }
  },

  validations: {
    isProblemPersistIfTrafficControlIsInactivated: {
      required
    },
    isTerminalRestartedUpdated: {
      required
    },
    isProblemInDifferentTerminal: {
      required
    },
    networkProvider: {
      required: requiredIf(function () {
        return this.isProblemAbroadSelected
      })
    },
    isSelectingProviderManuallyTried: {
      required: requiredIf(function () {
        return this.isProblemAbroadSelected
      })
    },
    selectedProvider: {
      required: requiredIf(function () {
        return this.isProblemAbroadSelected && this.isSelectingProviderManuallyTriedProxy
      })
    },
    country: {
      required: requiredIf(function () {
        return this.isProblemAbroadSelected
      })
    },
    city: {
      required: requiredIf(function () {
        return this.isProblemAbroadSelected
      })
    },
    isRoamingAllowedForTerminal: {
      required: requiredIf(function () {
        return this.isProblemAbroadSelected
      })
    },
    isLteOrWifiCallingActivated: {
      required
    },
    numberAffected: {
      required
    },
    areCustomersExperiencingSameProblem: {
      required
    },
    numbersOfAffectedCustomers: {
      required: requiredIf(function () {
        return this.areCustomersExperiencingSameProblemProxy
      })
    },
    adressWhereProblemHappened: {
      required: requiredIf(function () {
        return !this.isProblemAbroadSelected
      })
    },
    problemOccurenceDate: {
      required
    },
    problemCustomerExperiencingDescription: {
      required
    },
    stateExamplesWhenCustomerExperiencedProblem: {
      $each: {
        date: {
          required
        },
        description: {
          required
        }
      },
      required,
      minLength: minLength(3)
    },
    whatTroubleshootingPerformed: {
      required
    }
  },

  methods: {
    ...mapActions('FaultReporting/FaultReportForm/MobileSP', [
      'updateIsProblemPersistIfTrafficControlIsInactivated',
      'updateIsTerminalRestartedUpdated',
      'updateIsProblemInDifferentTerminal',
      'updateNetworkProvider',
      'updateIsSelectingProviderManuallyTried',
      'updateSelectedProvider',
      'updateCountry',
      'updateCity',
      'updateIsRoamingAllowedForTerminal',
      'updateIsLteOrWifiCallingActivated',
      'updateNumberAffected',
      'updateAreCustomersExperiencingSameProblem',
      'updateNumbersOfAffectedCustomers',
      'updateAdressWhereProblemHappened',
      'updateProblemOccurenceDate',
      'updateProblemCustomerExperiencingDescription',
      'updateAttachments',
      'updateStateExamplesWhenCustomerExperiencedProblem',
      'updateWhatTroubleshootingPerformed',
      'updateIsValid',
      'addEmptyStateExample',
      'removeStateExample'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import "./default.scss";
</style>
