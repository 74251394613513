import UserService from '../services/user.service'

export default {
  namespaced: true,
  state: {
    informationException: false,
    information: {}
  },
  mutations: {
    setException (state, exception) {
      state.informationException = exception
    },
    setInformation (state, information) {
      state.information = information
    }
  },
  actions: {
    fetchUser ({ commit }) {
      return UserService
        .getCurrentUserDetails()
        .then(information => {
          commit('setInformation', information)
        })
        .catch(() => {
          commit('setException', true)
        })
    }
  }
}
