<template>
  <div class="container content-container">
    <div class="row">
      <div
        v-if="hasAccessToSkanovaProducts"
        class="col-lg-4">
        <tws-rich-text
          :html="content.skanovaText" />

        <tws-link-button-primary :href="skanovaProductsUrl">
          {{ $t('order.skanova-link') }}
        </tws-link-button-primary>
      </div>

      <div
        v-if="hasAccessToTelefoniabonnemang"
        class="col-lg-4">
        <tws-rich-text
          :html="content.telefoniabonnemangsText" />

        <tws-link-button-primary
          :href="telefoniabonnemagUrl"
          :external="true">
          {{ $t('order.telefon-link') }}
          <tws-new-window-icon />
        </tws-link-button-primary>
      </div>

      <div
        v-if="!hasAccessToSkanovaProducts && !hasAccessToTelefoniabonnemang"
        class="col-12">
        <InfraPageAlert type="info">
          {{ $t('order.no-access') }}
        </InfraPageAlert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductManagementService from '@/services/product-management.service'
import * as logger from '../../logger'
import TwsRichText from '@/components/rich-text/rich-text.vue'

import {
  InfraPageAlert,
  TwsLinkButtonPrimary
} from 'tws-vue-components'

export default {
  components: {
    InfraPageAlert,
    TwsLinkButtonPrimary,
    TwsRichText
  },
  data () {
    return {
      products: {
        skanovaProducts: false,
        ipStreamEnhanced: false,
        telefoniabonnemang: false
      },
      content: {
        skanovaText: '<p>Produkt- och/eller operat&ouml;rsbyte till<br /> <strong>Kopparaccess<br /> Fiber Villa<br /> Fiber L&auml;genhet<br /> Fiber Sm&aring;f&ouml;retag<br /> IP Stream Enhanced</strong></p>',
        telefoniabonnemangsText: '<p>Operat&ouml;rsbyte till<br /><strong>Telefoniabonnemang </strong></p>'
      }
    }
  },
  computed: {
    ...mapGetters('eamCookies', [
      'hasUserOperatorsbyteSkanovaTobRoles',
      'hasUserOperatorsbyteTelefoniamagRole'
    ]),
    hasAccessToSkanovaProducts () {
      return this.products.skanovaProducts && this.hasUserOperatorsbyteSkanovaTobRoles
    },
    hasAccessToTelefoniabonnemang () {
      return this.products.telefoniabonnemang && this.hasUserOperatorsbyteTelefoniamagRole
    },
    skanovaProductsUrl () {
      return ProductManagementService.getIframePath(
        process.env.VUE_APP_HANTERA_OPERATOR_PATH,
        process.env.VUE_APP_IFRAME_SKANOVA_PRODUCTS_OPERATORSBYTE
      )
    },
    telefoniabonnemagUrl () {
      return process.env.VUE_APP_IFRAME_TELEFONIABONNEMAG
    }
  },
  created () {
    return ProductManagementService.getProductVisibility()
      .then((response) => {
        this.$set(this.$data, 'products', response)
      })
      .catch((error) => {
        logger.error(error)
      })
  }
}
</script>
