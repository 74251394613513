import c2bCache from '@/apps/fault-reporting/store/modules/c2b-cache.store'
import faultReportFormGlobalStore from '@/apps/fault-reporting/store/modules/report-form/fault-report-form-global.store'
import faultReportFormValidationStore from '@/apps/fault-reporting/store/modules/report-form/fault-report-form-validation.store'
import faultReportBasicInfoStore from '@/apps/fault-reporting/store/modules/report-form/fault-report-basic-info.store'
import faultReportGeneralCaseStore from '@/apps/fault-reporting/store/modules/report-form/fault-report-general-case.store'
import faultReportNotificationsStore from '@/apps/fault-reporting/store/modules/report-form/fault-report-notifications.store'
import faultReportInterconnectCaseStore from '@/apps/fault-reporting/store/modules/report-form/fault-report-interconnect-case.store'
import faultReportOpenFiberCaseStore from '@/apps/fault-reporting/store/modules/report-form/fault-report-open-fiber-case.store'
import faultReportDetailsStore from '@/apps/fault-reporting/store/modules/fault-report-details.store'
import faultReportCommentsStore from '@/apps/fault-reporting/store/modules/fault-report-comments.store'
import faultReportMobileSPCaseStore from '@/apps/fault-reporting/store/modules/report-form/fault-report-mobilesp-case.store'

import caseListStore from '@/apps/fault-reporting/store/modules/case-list.store'

import { contentStoreFactory } from './modules/content.store'
import {
  fetchLandingPageContent,
  fetchNewFaultReportContent
} from '@/apps/fault-reporting/services/content.service'
import { VUEX_NAMESPACES } from '@/apps/fault-reporting/constants/fault-report-form.constants'

export const FaultReportingModule = {
  namespaced: true,
  modules: {
    LandingPageContent: contentStoreFactory(fetchLandingPageContent),
    NewFaultReportContent: contentStoreFactory(fetchNewFaultReportContent),
    C2bCache: c2bCache,
    CaseList: caseListStore,
    FormGlobal: faultReportFormGlobalStore,
    FaultReportDetails: faultReportDetailsStore,
    FaultReportComments: faultReportCommentsStore,
    [VUEX_NAMESPACES.FAULT_REPORT_FORM]: {
      namespaced: true,
      modules: {
        [VUEX_NAMESPACES.FORM_VALIDATION]: faultReportFormValidationStore,
        [VUEX_NAMESPACES.BASIC_INFO]: faultReportBasicInfoStore,
        [VUEX_NAMESPACES.GENERAL_CASE]: faultReportGeneralCaseStore,
        [VUEX_NAMESPACES.INTERCONNECT_CASE]: faultReportInterconnectCaseStore,
        [VUEX_NAMESPACES.NOTIFICATIONS]: faultReportNotificationsStore,
        [VUEX_NAMESPACES.OPEN_FIBER]: faultReportOpenFiberCaseStore,
        [VUEX_NAMESPACES.MOBILESP]: faultReportMobileSPCaseStore
      }
    }
  }
}
