const URIUtils = {
  decodeURIParam (param) {
    try {
      return decodeURIComponent(param)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Malformed URI param: ' + param)
      return null
    }
  }
}

export default URIUtils
