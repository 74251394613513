<template>
  <InfraReportingActionPanelConversation
    :message.sync="form.message"
    :invalid="$v.form.message.$error"
    :loading="loading"
    :loaded="loaded"
    :exception="exception"
    :label-button-state="labelButtonState"
    :label-conversation-message="$t('action-panel.conversation.message-label')"
    :label-error="$t('info-alert.error')"
    @submit="submit" />
</template>

<script>
import { InfraReportingActionPanelConversation } from 'tws-vue-components'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import {
  mapActions, mapState
} from 'vuex'

export default {
  components: {
    InfraReportingActionPanelConversation
  },
  mixins: [validationMixin],
  data () {
    return {
      form: {
        message: null
      }
    }
  },
  validations: {
    form: {
      message: {
        required
      }
    }
  },
  computed: {
    ...mapState('CustomerTickets/TicketDetails', ['response']),
    ...mapState('CustomerTickets/TicketNotes', [
      'loading',
      'loaded',
      'exception'
    ]),
    labelButtonState () {
      if (this.loading) {
        return this.$t('action-panel.conversation.button.sending')
      } else if (this.loaded) {
        return this.$t('action-panel.conversation.button.sent')
      }
      return this.$t('action-panel.conversation.button.send')
    }
  },
  created () {
    this.resetState()
  },
  methods: {
    ...mapActions('CustomerTickets/TicketNotes', [
      'postNote',
      'resetState'
    ]),
    submit () {
      this.$v.form.$touch()

      if (this.$v.form.$invalid) {
        return
      }

      this.postNote({
        supportId: this.response.supportId,
        request: {
          subject: this.$t('action-panel.conversation.post-subject'),
          content: this.form.message
        }
      })
    }
  }
}
</script>
