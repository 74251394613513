import { render, staticRenderFns } from "./generic-banner.vue?vue&type=template&id=45185b65&scoped=true"
import script from "./generic-banner.vue?vue&type=script&lang=js"
export * from "./generic-banner.vue?vue&type=script&lang=js"
import style0 from "./generic-banner.vue?vue&type=style&index=0&id=45185b65&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45185b65",
  null
  
)

export default component.exports