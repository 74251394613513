<template>
  <div class="infra-autocomplete-field-container">
    <div
      ref="infra-autocomplete-field"
      class="form-control infra-autocomplete-field-form-control">
      <label
        :for="id"
        :class="{ 'label--required': isRequired }"
        class="label infra-autocomplete-field-label">
        {{ label }}
      </label>
      <div class="infra-autocomplete-field-input-wrapper">
        <slot name="icon" />
        <input
          :id="id"
          v-model="model"
          autocomplete="off"
          :readonly="readonly"
          :disabled="disabled"
          :class="inputClass"
          :placeholder="placeholder"
          class="infra-autocomplete-field-input"
          @click="handleClick"
          @input="handleInputChange"
          @focus="handleFocus"
          @blur="handleBlur">
      </div>
      <slot name="spinner" />
      <tws-field-validation-errors
        v-if="validator"
        :validator="validator"
        :messages="validationMessages"
        :invalid="isInvalid"
        :field-name="label" />
      <ul
        v-show="showAutocompletion"
        :ref="'infra-autocomplete-field-items'"
        class="infra-autocomplete-field-items"
        :class="{ 'infra-autocomplete-field-items--rounded': rounded }"
        @mouseout="preselectOption(null)">
        <template>
          <li
            v-for="(option, index) in options"
            :key="option.index"
            :ref="'options'"
            class="infra-autocomplete-field-item"
            :class="{ 'focused': index === preselectedOptionIndex }"
            @mouseover="preselectOption(index)">
            {{ labelGetter(option) }}
          </li>
        </template>
      </ul>
    </div>

    <TwsTooltip
      v-if="tooltipText"
      :text="tooltipText"
      :width="tooltipWidth"
      class="infra-autocomplete-field-tooltip" />
  </div>
</template>

<script>
import {
  TwsFieldValidationErrors, TwsTooltip
} from 'tws-vue-components'
import { TOOLTIP_WIDTH } from '@/apps/fault-reporting/constants/fault-report-form.constants'

const ARROW_DOWN = 'ArrowDown'
const ARROW_UP = 'ArrowUp'
const ENTER = 'Enter'
const ESCAPE = 'Escape'

export default {
  components: {
    TwsFieldValidationErrors,
    TwsTooltip
  },
  props: {
    value: {
      type: [
        String,
        Object
      ],
      default: null
    },
    options: {
      type: [
        Array,
        Object
      ],
      required: true
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasExternalValue: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: 'white',
      validator: value => [
        '',
        'white',
        'grey'
      ].includes(value)
    },
    rounded: {
      type: Boolean,
      default: false
    },
    validator: {
      type: Object,
      default: null
    },
    validationMessages: {
      type: Object,
      default: null
    },
    labelGetter: {
      type: Function,
      default: value => value || ''
    }
  },
  data () {
    return {
      fieldFocused: false,
      optionsVisible: false,
      preselectedOptionIndex: null,
      tooltipWidth: TOOLTIP_WIDTH
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    showAutocompletion () {
      return this.options.length && this.optionsVisible
    },
    inputClass () {
      return {
        'infra-autocomplete-field-input--open': this.showAutocompletion,
        'infra-autocomplete-field-input--rounded': this.rounded,
        'infra-autocomplete-field-input--background--white': !this.background || this.background === 'white',
        'infra-autocomplete-field-input--background--grey': this.background === 'grey',
        'infra-autocomplete-field-input--has-icon': !!this.$slots['icon'],
        'infra-autocomplete-field-input--invalid': this.isInvalid
      }
    },
    isRequired () {
      return this.validator
        ? this.validator.$params.required
        : this.required
    },
    isInvalid () {
      return this.validator
        ? this.validator.$invalid && this.validator.$dirty
        : false
    }
  },
  methods: {
    setFocused (focused) {
      this.fieldFocused = focused
    },
    setOptionsVisible (visible) {
      this.optionsVisible = visible
    },
    preselectOption (option) {
      this.preselectedOptionIndex = option
    },
    preselectNextOption () {
      if (this.preselectedOptionIndex === null) {
        this.preselectedOptionIndex = 0
        return
      }

      if (this.preselectedOptionIndex === this.options.length - 1) {
        return
      }

      this.preselectedOptionIndex += 1
    },
    preselectPreviousOption () {
      if (this.preselectedOptionIndex === null) {
        this.preselectedOptionIndex = this.options.length - 1
        return
      }

      if (this.preselectedOptionIndex === 0) {
        return
      }

      this.preselectedOptionIndex -= 1
    },
    handleSelect () {
      if (this.preselectedOptionIndex !== null) {
        const selectedOption = this.options[this.preselectedOptionIndex]

        this.$emit('select', selectedOption)
        this.setOptionsVisible(false)
      }
    },
    scrollIntoView () {
      const optionsRefs = this.$refs.options
      const preselectedOptionRef = optionsRefs[this.preselectedOptionIndex]

      if (optionsRefs && preselectedOptionRef) {

        preselectedOptionRef.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'start'
        })
      }

    },
    handleDownKey () {
      if (!this.optionsVisible) {
        this.setOptionsVisible(true)
      }
      this.preselectNextOption()
      this.scrollIntoView()

    },
    handleUpKey () {
      if (!this.optionsVisible) {
        this.setOptionsVisible(true)
      }

      this.preselectPreviousOption()
      this.scrollIntoView()
    },
    handleEnterKey (event) {
      if (this.optionsVisible) {
        event.preventDefault()
      }

      if (this.options[this.preselectedOptionIndex]) {
        this.handleBlur()
      }
    },
    handleEscapeKey (event) {
      if (this.optionsVisible) {
        event.preventDefault()
        this.setOptionsVisible(false)
      }
    },
    handleKeyPress (event) {
      switch (event.key) {
        case ARROW_DOWN: {
          this.handleDownKey(event)
          break
        }

        case ARROW_UP: {
          this.handleUpKey(event)
          break
        }

        case ENTER: {
          this.handleEnterKey(event)
          break
        }

        case ESCAPE: {
          this.handleEscapeKey(event)
          break
        }
      }
    },
    enableKeys () {
      window.addEventListener('keydown', this.handleKeyPress)
    },
    disableKeys () {
      window.removeEventListener('keydown', this.handleKeyPress)
    },
    handleFocus (event) {
      this.setFocused(true)
      this.enableKeys()
      event.target.select()
    },
    handleBlur () {
      this.handleSelect()
      this.preselectOption(null)
      this.disableKeys()
      this.setOptionsVisible(false)
      this.setFocused(false)
    },
    handleInputChange () {
      this.setOptionsVisible(true)
    },
    handleClick () {
      this.setOptionsVisible(!this.optionsVisible)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.infra-autocomplete-field {

  &-container {
    display: flex;
  }

  &-tooltip {
    align-self: center;
    margin-left: 5px;
  }

  &-input-wrapper {
    position: relative;
  }

  &-form-control {
    flex-grow: 1;
  }

  &-input {
    height: 5.2rem;
    width: 100%;
    padding: 1.3rem 2.4rem;
    background: $white;
    border: 1px solid $black;
    font: inherit;
    cursor: default;
    text-overflow: ellipsis;

    &::-ms-clear {
      display: none
    }

    &--invalid {
      border-color: #ff3264;
    }

    &--background {
      &--white {
        background-color: $white;
      }

      &--grey {
        background-color: $light-grey;
        border-color: $light-grey;
      }
    }

    &--has-icon {
      padding-left: 46px;
    }

    &--rounded {
      border-radius: 50px;
    }

    &--open {
      border: 1px solid $core-purple;
      border-bottom: 1px solid $dark-grey;

      &.infra-autocomplete-field-input--rounded {
        border-radius: 25px 25px 0 0;
      }
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      border-color: $dark-grey;

      + .infra-autocomplete-field-arrow {
        color: $dark-grey;
      }
    }
  }

  &-items {
    position: absolute;
    top: 8.2rem;
    max-height: 368px;
    width: 100%;
    margin: 0;
    padding: 4px 0;
    border: 1px solid $core-purple;
    border-top: 0;
    background: $white;
    overflow-y: scroll;
    line-height: 36px;
    z-index: 99;

    &--rounded {
      border-radius: 0 0 3px 3px;
    }
  }

  &-item {
    padding-left: 2.4rem;
    cursor: pointer;

    &:hover {
      background: $light-core-purple;
    }

    &.focused {
      background: $core-purple;
    }

    &:hover,
    &.focused {
      color: $white;

    }
  }
}
</style>
