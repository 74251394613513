<template>
  <div class="tabs-container">
    <tws-nav-link
      v-for="(tab, index) in tabs"
      :key="tab.title"
      :class="{ 'tab--active': index === activeTabIndex }"
      :href="tab.link"
      class="tab">
      {{ tab.title }}
    </tws-nav-link>
  </div>
</template>

<script>
import { TwsNavLink } from 'tws-vue-components'

export default {
  components: {
    TwsNavLink
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTabIndex: {
      type: Number,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.tabs-container {
  display: flex;
  margin-top: 35px;
  margin-bottom: -20px;
}

.tab {
  padding: 20px 50px 20px 25px;
  font-size: 22px;
  margin-right: 2px;
  background-color: $medium-grey;

  &--active {
    background-color: $white;
    box-shadow: 0 3px 0 $core-purple inset;
    cursor: default;
    color: $core-purple;
  }
}
</style>
