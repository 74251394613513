<template>
  <div class="horizontal-radio-group">
    <tws-validated-radio-group
      :id="id"
      v-model="model"
      :options="options"
      :label="label"
      alignment="horizontal"
      :validator="validator"
      :validation-messages="validationMessages" />
    <TwsTooltip
      v-if="tooltipText"
      class="tooltip"
      :text="tooltipText"
      :width="tooltipWidth" />
  </div>
</template>

<script>
import {
  TwsValidatedRadioGroup, TwsTooltip
} from 'tws-vue-components'
import i18n from '@/i18n'
import { TOOLTIP_WIDTH } from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  components: {
    TwsValidatedRadioGroup,
    TwsTooltip
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validator: {
      type: Object,
      required: true
    },
    tooltipText: {
      type: String,
      default: ''
    },
    validationMessages: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      default: () => [
        {
          text: i18n.t('common.yes'),
          value: true
        },
        {
          text: i18n.t('common.no'),
          value: false
        }
      ]
    },
    value: {
      type: [
        String,
        Boolean
      ],
      default: () => false
    }
  },
  data () {
    return {
      referenceNumberViewValue: null,
      tooltipWidth: TOOLTIP_WIDTH
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.horizontal-radio-group {
  display: flex;
}

.tooltip {
  align-self: center;
}

::v-deep .form-control--horizontal {
  margin-bottom: 0;
}

</style>
