import locale from 'date-fns/locale/sv'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import isFuture from 'date-fns/isFuture'
import isToday from 'date-fns/isToday'
import {
  isMatch, isValid
} from 'date-fns'

const formatOpts = { locale }

const parse = date => {
  return typeof date === 'string' ? parseISO(date) : date
}

const safe = (date, formatting) => {
  return format(parse(date), formatting, formatOpts)
}

const dayAndMonth = date => safe(date, 'dd MMMM')

const dateTime = {
  DATE_FORMAT: 'yyyy-MM-dd HH:mm',

  safeFormat (date, formatting) {
    try {
      return format(parse(date), formatting)}
    catch (e) {
      return '-'
    }
  },
  formatDateTime (date) {
    return dateTime.safeFormat(date, dateTime.DATE_FORMAT)
  },
  formatIsoDate (date) { return dateTime.safeFormat(date, 'yyyy-MM-dd') }
}

const isValidDate = (date) => {
  return isMatch(date, 'yyyy-MM-dd') && isValid(parseISO(date))
}

export default {
  dateTime,
  dayAndMonth,
  isValidDate,
  isTodayOrAfter: date => {
    return isToday(parse(date)) || isFuture(parse(date))
  }
}
