import ContentfulWrapper from '@/services/contentful/contentful.wrapper'
import BasicInfoTransformer from '@/store/transformers/basic-info.transformer.js'

export const BasicInfoModule = {
  namespaced: true,
  state: {
    loading: true,
    exception: false,
    content: null
  },
  mutations: {
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setContent: (state, content) => state.content = content
  },
  actions: {
    async loadContent ({ commit }, query) {
      try {
        const response = await ContentfulWrapper.fetchEntries(query)
        const result = response.items && response.items[0].fields

        commit('setContent', BasicInfoTransformer.extractContent(result))
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    }
  }
}
