<template>
  <div>
    <div class="row">
      <div class="col-2">
        <tws-validated-text-input-group
          id="city-code-phone-search-field"
          v-model="editablePhone.cityCodeField"
          :validator="validator.cityCodeField"
          :validation-messages="validationMessages"
          :label="$t('phone-search.city-code')"
          @enterKeyPressed="$emit('enterKeyPressed')" />
      </div>

      <div class="col-3">
        <tws-validated-text-input-group
          id="number-phone-search-field"
          v-model="editablePhone.numberField"
          :validator="validator.numberField"
          :validation-messages="validationMessages"
          :label="$t('phone-search.phone-number')"
          @enterKeyPressed="$emit('enterKeyPressed')" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { TwsValidatedTextInputGroup } from 'tws-vue-components'

export default {
  components: {
    TwsValidatedTextInputGroup
  },
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      required: true
    },
    phone: {
      type: Object,
      required: true
    },
    validator: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cityCodeField: '',
      numberField: '',
      editablePhone: Object.assign({}, this.phone),
      validationMessages: {
        required: this.$t('phone-search.validations.required'),
        numeric: this.$t('phone-search.validations.numeric'),
        minLengthCityCode: this.$t('phone-search.validations.city-code.min-length'),
        maxLengthCityCode: this.$t('phone-search.validations.city-code.max-length'),
        minLengthNumber: this.$t('phone-search.validations.phone-number.min-length'),
        maxLengthNumber: this.$t('phone-search.validations.phone-number.max-length')
      }
    }
  },
  watch: {
    editablePhone: {
      handler (newValue) {
        this.$emit('phoneChanged', newValue)
      },
      deep: true
    }
  }
}
</script>
