<template>
  <div class="documents-view">
    <div class="container">
      <div
        v-if="loading || exception"
        class="row">
        <div class="col-12">
          <InfraPageAlert
            v-if="loading"
            type="info">
            {{ $t('product-documents.loading') }}
          </InfraPageAlert>

          <InfraPageAlert
            v-else-if="exception"
            type="danger">
            {{ $t('product-documents.loading-failed') }}
          </InfraPageAlert>
        </div>
      </div>

      <template v-else>
        <div class="row toolbar">
          <div class="col-lg-4 col-md-12">
            <div class="form-control search-field">
              <TeliaCoreIcon
                class="search-field__icon"
                name="search_thin"
                size="2.7rem" />

              <input
                v-model="searchTermProxy"
                type="text"
                class="input-text"
                :placeholder="$t('product-documents.search-placeholder')">
            </div>
          </div>
          <div class="col result-actions-container">
            <div class="result-actions">
              <TwsBorderlessDropdown
                :items="sortingItems"
                :default-key="sortBy"
                :displayed-value-wrapper="`${$t('product-documents.sort')} ({value})`"
                class="color-core-purple"
                @onChange="changeSort" />
            </div>
          </div>
        </div>

        <Documents
          v-if="showPublished"
          ref="documents-published"
          :heading="$t('product-documents.heading')"
          :product-id="product.id"
          :categories="publishedCategories"
          :product-title="product.title"
          :items="published" />

        <Documents
          v-if="showArchived"
          ref="documents-archived"
          :heading="$t('product-documents.archived-heading')"
          :product-id="product.id"
          :categories="archivedCategories"
          :product-title="product.title"
          :items="archived"
          class="product-documents-archived" />

        <template v-if="!showPublished && !showArchived">
          <InfraPageAlert
            v-if="isLoggedIn() || searchTerm"
            type="info">
            {{ $t('product-documents.no-data') }}
          </InfraPageAlert>
          <InfraPageAlert
            v-else
            type="info">
            {{ $t('product-documents.not-logged-in') }}
          </InfraPageAlert>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapState, mapGetters
} from 'vuex'
import debounce from 'lodash.debounce'
import {
  InfraPageAlert, TwsBorderlessDropdown
} from 'tws-vue-components'
import { Documents } from '@/apps/products/components'
import { isLoggedIn } from '@/utils/user.utils'
import { SortOptions } from '@/apps/products/store/modules/document.module'

export default {
  components: {
    InfraPageAlert,
    TwsBorderlessDropdown,
    Documents
  },
  data () {
    return {
      sortingItems: [
        {
          key: SortOptions.STANDARD,
          value: this.$t('product-documents.sort-options.standard')
        },
        {
          key: SortOptions.PUBLISH_DATE_DESC,
          value: this.$t('product-documents.sort-options.publish-date-descending')
        },
        {
          key: SortOptions.PUBLISH_DATE_ASC,
          value: this.$t('product-documents.sort-options.publish-date-ascending')
        },
        {
          key: SortOptions.EN_SV,
          value: this.$t('product-documents.sort-options.en-sv')
        },
        {
          key: SortOptions.SV_EN,
          value: this.$t('product-documents.sort-options.sv-en')
        }
      ]
    }
  },
  computed: {
    ...mapState('Products/ContentModule', ['product']),
    ...mapState('Products/ProductDocumentModule', [
      'loading',
      'exception',
      'documents',
      'sortBy',
      'searchTerm'
    ]),
    ...mapGetters('Products/ProductDocumentModule', ['filteredItems']),
    published () {
      return this.filteredItems.published
    },
    archived () {
      return this.filteredItems.archived

    },
    publishedCategories () {
      return Object.keys(this.published).filter(key => this.published[key].length > 0)
    },
    archivedCategories () {
      return Object.keys(this.archived).filter(key => this.archived[key].length > 0)
    },
    showPublished () {
      return this.publishedCategories.length > 0
    },
    showArchived () {
      return this.archivedCategories.length > 0
    },
    searchTermProxy: {
      get () { return this.searchTerm },
      set: debounce(function (term) { this.changeSearchTerm(term) }, 500)
    }
  },
  watch: {
    async searchTerm (searchTerm) {
      await this.$nextTick()

      if (searchTerm !== '') {
        this.setAccordionExpanded(true)
      } else {
        this.setAccordionExpanded(false)
      }
    }
  },
  created () {
    this.loadDocuments(this.product.id)
  },
  methods: {
    ...mapActions('Products/ProductDocumentModule', [
      'loadDocuments',
      'changeSort',
      'changeSearchTerm'
    ]),
    isLoggedIn,
    setAccordionExpanded (value) {
      const refs = this.$refs

      Object.keys(refs).forEach(key => {
        refs[key]?.$refs['accordion-item'].forEach(
          accordionItem => accordionItem._data.expandedProxy = value)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.toolbar {
  margin-bottom: 4rem;
}

.result-actions-container {
  align-self: center;
}

.result-actions {
  justify-content: flex-end;
  display: flex;
}

.product-documents-archived {
  margin-top: 70px;
}

.search-field {
  margin-bottom: 0;

  &__icon {
    position: absolute;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  input {
    background-color: $light-grey;
    border-radius: 50px;
    border-color: $light-grey;
    padding-left: 46px;
  }
}
</style>
