<template>
  <div
    class="status-badge"
    :class="statusClass">
    {{ status }}
  </div>
</template>

<script>
import Constants from '@/apps/customer-tickets/utils/constants'

export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    statusClass () {
      if (!Constants.STATUS_TO_COLOR[this.status]) {
        return 'status-badge--grey'
      }

      return 'status-badge--' + Constants.STATUS_TO_COLOR[this.status]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.status-badge {
  display: inline-block;
  border-radius: 3px;
  padding: 5px 14px;
  color: $white;
  font-weight: bold;

  &--blue {
    background-color: #0082D9;
  }

  &--green {
    background-color: #00A552;
  }

  &--dark-grey {
    background-color: #6E6E6E;
  }

  &--grey {
    background-color: $dark-grey;
  }

  &--orange {
    background-color: #DD8804;
  }

  &--red {
    background-color: #BF134D;
  }
}
</style>
