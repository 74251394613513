<template>
  <InfraReportingActionPanelAttachments
    :attachments="computedAttachments"
    :attachments-loading="attachmentsLoading"
    :attachments-exception="attachmentsException"
    :remove-attachment-loading="removeAttachmentLoading"
    :label-delete="$t('details.action-panel.attachments.delete')"
    :label-confirmation="$t('details.action-panel.attachments.delete-confirmation')"
    :label-cancel="$t('common.cancel')"
    :label-remove="$t('common.remove')"
    :label-add-file-button="$t('details.action-panel.attachments.add')"
    :label-exception-message="$t('common.state.error')"
    :add-file-text="$t('fault-reporting.form.attachments-button.button-text')"
    :limit-is-reached-text="$t('fault-reporting.form.attachments-button.limit-is-reached-text').replace('limit', attachmentsLimit)"
    :size-limit-text="$t('fault-reporting.form.attachments-button.size-is-reached-text')"
    :acceptable-file-extensions="acceptableFileExtensions"
    @removeAttachment="removeAttachment"
    @putAttachments="putAttachments" />
</template>

<script>
import {
  mapActions, mapGetters, mapState
} from 'vuex'
import { InfraReportingActionPanelAttachments } from 'tws-vue-components'
import {
  ATTACHMENTS_ALLOWED_EXTENSIONS,
  ATTACHMENTS_FILE_SIZE_LIMIT, ATTACHMENTS_LIMIT
} from '@/apps/fault-reporting/constants/fault-report-form.constants'

export default {
  components: {
    InfraReportingActionPanelAttachments
  },
  data () {
    return {
      attachmentsLimit: ATTACHMENTS_LIMIT,
      acceptableFileExtensions: ATTACHMENTS_ALLOWED_EXTENSIONS,
      defaultFileSizeLimit: ATTACHMENTS_FILE_SIZE_LIMIT
    }
  },
  computed: {
    computedAttachments () {
      return this.attachments.map(i => {
        return {
          fileName: i.fileName,
          fileKey: this.attachments.indexOf(i)
        }
      })
    },
    ...mapState('FaultReporting/FaultReportDetails', [
      'attachmentsLoading',
      'removeAttachmentLoading',
      'attachmentsException'
    ]),
    ...mapGetters('FaultReporting/FaultReportDetails', ['attachments'])
  },
  methods: {
    ...mapActions('FaultReporting/FaultReportDetails', [
      'putAttachments',
      'removeAttachment'
    ])
  }
}
</script>
