<template>
  <div class="row">
    <div class="col-12">
      <h1 class="heading-l--black title-500">
        {{ $t('user-dashboard.heading') }}
      </h1>

      <tws-action-link :link="link">
        <TeliaCoreIcon
          class="search-icon"
          name="secured_thin"
          size="3rem" />
        {{ $t('user-dashboard.change-password') }}
      </tws-action-link>
    </div>
  </div>
</template>

<script>
import { TwsActionLink } from 'tws-vue-components'

export default {
  components: {
    TwsActionLink
  },
  data () {
    return {
      link: process.env.VUE_APP_USER_CHANGE_PASSWORD_PATH
    }
  }
}
</script>

<style lang="scss" scoped>
.heading-l {
  margin-bottom: 25px;
}

.action-link {
  width: 295px;
}
</style>
