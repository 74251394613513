import i18n from '@/i18n'
import ProductManagementService from '@/services/product-management.service'
import SecureHeaderProductsService from '@/services/secure-header-products.service'

const pushTransformedOrder = (transformedOrders, title, categories) => {
  transformedOrders.push({
    title: title,
    categories: categories,
    expandable: true,
    expanded: false
  })
}

const includesTeliaWholesaleFiber = (products) => {
  const fiberProduct = products.find(product => product.title.toLowerCase() === i18n.t(SecureHeaderProductsService.PRODUCT_CATEGORY_NAMES.FIBER).toLowerCase())
  return fiberProduct?.subCategories.find(category => category.title.toLowerCase() === i18n.t(SecureHeaderProductsService.FIBER_PRODUCT_NAMES.TELIA_WHOLESALE_FIBER).toLowerCase())
}

export const SecureHeaderModule = {
  namespaced: true,
  state: {
    orders: [],
    ordersLoading: false,
    ordersLoaded: false,
    ordersException: false,
    myOrders: [],
    myOrdersLoading: false,
    myOrdersLoaded: false,
    myOrdersException: false,
    tickets: [],
    ticketsLoading: false,
    ticketsLoaded: false,
    ticketsException: false,
    productsWithAgreement: [],
    productsWithAgreementLoading: false,
    productsWithAgreementLoaded: false,
    productsWithAgreementException: false
  },
  mutations: {
    setDefaultOrders (state) {
      state.orders = SecureHeaderProductsService.MENU_ITEMS.ALL_LOGGEDIN_ORDER_PRODUCTS()
    },
    setOrders (state, { orders, hasOrdersTelefoniabonnemangRole, hasOrdersNetbusinessRole }) {
      const transformedOrders = []

      const hasFiberlagnhetProduct = orders.some(order => {
        return order.category === 'FIBER' &&
            order.productDTOs.some(product => product.productName === 'FIBER_LAGENHET')
      })

      orders.forEach(order => {
        const selectedCategory = SecureHeaderProductsService.MENU_ITEMS.CATEGORIES()[order.category]

        const transformedProducts = order
          .productDTOs
          .filter(products => selectedCategory.categories[products.productName] !== undefined)
          .map(products => {
            return {
              index: selectedCategory.categories[products.productName].index,
              title: selectedCategory.categories[products.productName].title,
              link: selectedCategory.categories[products.productName].link,
              external: selectedCategory.categories[products.productName].external
            }
          })

        if (order.category === 'TJANSTER') {
          let categories = transformedProducts

          if (hasOrdersNetbusinessRole) {
            categories = categories.concat(SecureHeaderProductsService.MENU_ITEMS.TJANSTER_PRODUCTS())

            if (hasFiberlagnhetProduct) {
              categories = categories.concat(SecureHeaderProductsService.MENU_ITEMS.TJANSTER_ADDITIONAL_PRODUCTS()).sort((a, b) => a.index - b.index)
            }
          }

          pushTransformedOrder(transformedOrders, selectedCategory.title, categories)
        } else if (order.category === 'FIBER') {
          transformedProducts.sort((a, b) => a.index - b.index)
          const categories = hasOrdersNetbusinessRole ? transformedProducts.concat(SecureHeaderProductsService.MENU_ITEMS.ALL_LOGGEDIN_OPPENFIBER_PRODUCT()) : SecureHeaderProductsService.MENU_ITEMS.ALL_LOGGEDIN_OPPENFIBER_PRODUCT()
          pushTransformedOrder(transformedOrders, selectedCategory.title, categories)
        } else if (order.category === 'KOPPAR' && (hasOrdersNetbusinessRole || hasOrdersTelefoniabonnemangRole)) {
          let filteredProductsByRoles = []
          const indexOfTelefoniabonnemang = transformedProducts.findIndex(product => product.title === SecureHeaderProductsService.COPPER_PRODUCT_NAMES.TELEFONIABONNEMANG)
          if (indexOfTelefoniabonnemang > -1) {
            if (hasOrdersTelefoniabonnemangRole) {
              filteredProductsByRoles.push(transformedProducts[indexOfTelefoniabonnemang])
            }
            transformedProducts.splice(indexOfTelefoniabonnemang, 1)
          }

          if (hasOrdersNetbusinessRole) {
            filteredProductsByRoles = filteredProductsByRoles.concat(transformedProducts)
          }

          if (filteredProductsByRoles.length > 0) {
            pushTransformedOrder(transformedOrders, selectedCategory.title, filteredProductsByRoles)
          }
        } else if (order.category === 'IP_WDM' && hasOrdersNetbusinessRole) {
          transformedProducts.sort((a, b) => a.index - b.index)
          pushTransformedOrder(transformedOrders, selectedCategory.title, transformedProducts)
        }
      })

      state.orders = transformedOrders
    },
    setOrdersLoading (state, ordersLoading) {
      state.ordersLoading = ordersLoading
    },
    setOrdersLoaded (state, ordersLoaded) {
      state.ordersLoaded = ordersLoaded
    },
    setOrdersException (state, ordersException) {
      state.ordersException = ordersException
    },
    setDefaultMyOrders (state) {
      state.myOrders = SecureHeaderProductsService.MENU_ITEMS.ALL_LOGGEDIN_OPPENFIBER_PRODUCT()
    },
    setMyOrders (state, { myOrders, hasMyOrdersTelefoniabonnemangRole, hasMyOrdersNetbusinessRole }) {
      const filteredOrders = []
      if (!myOrders) {
        return
      }

      if (myOrders['telefoniabonnemang'] && hasMyOrdersTelefoniabonnemangRole) {
        filteredOrders.push(SecureHeaderProductsService.MENU_ITEMS.MY_ORDERS()['telefoniabonnemang'])
      }
      if (hasMyOrdersNetbusinessRole) {
        filteredOrders.push(SecureHeaderProductsService.MENU_ITEMS.MY_ORDERS()['skanovaProducts'])
      }

      state.myOrders = filteredOrders.concat(SecureHeaderProductsService.MENU_ITEMS.ALL_LOGGEDIN_OPPENFIBER_PRODUCT())
    },
    setMyOrdersLoading (state, myOrdersLoading) {
      state.myOrdersLoading = myOrdersLoading
    },
    setMyOrdersLoaded (state, myOrdersLoaded) {
      state.myOrdersLoaded = myOrdersLoaded
    },
    setMyOrdersException (state, myOrdersException) {
      state.myOrdersException = myOrdersException
    },
    setDefaultTickets (state) {
      state.tickets = [
        SecureHeaderProductsService.collectMobileTickets(false),
        SecureHeaderProductsService.MENU_ITEMS.CAPACITY_TICKETS()
      ]
    },
    setTickets (state, { tickets, hasTicketsSkanovaProductsRole, hasTicketsTelefoniabonnemangRole, loggedinUsername }) {
      const teliaWholesaleFiberVisible = includesTeliaWholesaleFiber(state.productsWithAgreement)

      const skanovaTicketsVisible = tickets.skanovaProducts && hasTicketsSkanovaProductsRole
      const telefoniabonnemangTicketsVisible = tickets.telefoniabonnemang && hasTicketsTelefoniabonnemangRole

      const fixedTicketsVisible = skanovaTicketsVisible || telefoniabonnemangTicketsVisible
      const fiberTicketsVisible = skanovaTicketsVisible || teliaWholesaleFiberVisible

      state.tickets = [
        ...fiberTicketsVisible ? [SecureHeaderProductsService.collectFiberTickets(skanovaTicketsVisible, loggedinUsername, teliaWholesaleFiberVisible)] : [],
        SecureHeaderProductsService.collectMobileTickets(skanovaTicketsVisible),
        SecureHeaderProductsService.MENU_ITEMS.CAPACITY_TICKETS(),
        ...fixedTicketsVisible ? [SecureHeaderProductsService.collectFixedTickets(skanovaTicketsVisible, telefoniabonnemangTicketsVisible)] : [],
        ...skanovaTicketsVisible ? [
          SecureHeaderProductsService.MENU_ITEMS.KOPPAR_TICKETS(loggedinUsername),
          SecureHeaderProductsService.MENU_ITEMS.ESTABLISHMENT_TICKETS(loggedinUsername)
        ] : [],
        SecureHeaderProductsService.MENU_ITEMS.DIGITAL_TOOLS_TICKETS()
      ]
    },
    setTicketsLoading (state, ticketsLoading) {
      state.ticketsLoading = ticketsLoading
    },
    setTicketsLoaded (state, ticketsLoaded) {
      state.ticketsLoaded = ticketsLoaded
    },
    setTicketsException (state, ticketsException) {
      state.ticketsException = ticketsException
    },
    setProductsWithAgreement (state, products) {
      state.productsWithAgreement = products
    },
    setProductsWithAgreementLoading (state, loading) {
      state.productsWithAgreementLoading = loading
    },
    setProductsWithAgreementLoaded (state, loaded) {
      state.productsWithAgreementLoaded = loaded
    },
    setProductsWithAgreementException (state, exception) {
      state.productsWithAgreementException = exception
    }
  },
  actions: {
    async fetchOrders ({ commit, rootGetters }) {
      const hasUserOrderRole = rootGetters['eamCookies/hasUserOrderRole']
      if (!hasUserOrderRole) {
        commit('setDefaultOrders')
        return null
      }

      commit('setOrdersLoading', true)

      return ProductManagementService.getOrders()
        .then(orders => {
          const hasOrdersTelefoniabonnemangRole = rootGetters['eamCookies/hasOrdersTelefoniabonnemangRole']
          const hasOrdersNetbusinessRole = rootGetters['eamCookies/hasOrdersNetbusinessRole']
          commit('setOrders', {
            orders,
            hasOrdersTelefoniabonnemangRole,
            hasOrdersNetbusinessRole
          })
          commit('setOrdersLoaded', true)
        })
        .catch(() => {
          commit('setOrdersException', true)
        })
        .finally(() => {
          commit('setOrdersLoading', false)
        })
    },
    async fetchMyOrders ({ commit, rootGetters }) {
      const hasUserMyOrdersRole = rootGetters['eamCookies/hasUserMyOrdersRole']
      if (!hasUserMyOrdersRole) {
        commit('setDefaultMyOrders')
        return null
      }

      commit('setMyOrdersLoading', true)

      return ProductManagementService.getMyOrders()
        .then(myOrders => {
          const hasMyOrdersTelefoniabonnemangRole = rootGetters['eamCookies/hasMyOrdersTelefoniabonnemangRole']
          const hasMyOrdersNetbusinessRole = rootGetters['eamCookies/hasMyOrdersNetbusinessRole']
          commit('setMyOrders', {
            myOrders,
            hasMyOrdersTelefoniabonnemangRole,
            hasMyOrdersNetbusinessRole
          })
          commit('setMyOrdersLoaded', true)
        })
        .catch(() => {
          commit('setMyOrdersException', true)
        })
        .finally(() => {
          commit('setMyOrdersLoading', false)
        })
    },
    async fetchTickets ({ commit, rootGetters, dispatch, state }) {
      const hasUserTicketsRole = rootGetters['eamCookies/hasUserTicketsRole']
      if (!hasUserTicketsRole) {
        commit('setDefaultTickets')
        return
      }

      commit('setTicketsLoading', true)

      if (!state.productsWithAgreementLoaded) {
        await dispatch('fetchProductsWithAgreement')
      }

      ProductManagementService.getTicketHandlingMenuItems()
        .then(tickets => {
          const hasTicketsSkanovaProductsRole = rootGetters['eamCookies/hasTicketsSkanovaProductsRole']
          const hasTicketsTelefoniabonnemangRole = rootGetters['eamCookies/hasTicketsTelefoniabonnemangRole']
          const loggedinUsername = rootGetters['eamCookies/getUsername']

          commit('setTickets', {
            tickets,
            hasTicketsSkanovaProductsRole,
            hasTicketsTelefoniabonnemangRole,
            loggedinUsername
          })
          commit('setTicketsLoaded', true)
        })
        .catch(() => {
          commit('setTicketsException', true)
        })
        .finally(() => {
          commit('setTicketsLoading', false)
        })
    },
    async fetchProductsWithAgreement ({ commit, rootGetters }) {
      commit('setProductsWithAgreementLoading', true)

      const productsItems = rootGetters['publicHeader/getMegaMenus'].products.categories

      return ProductManagementService.getProductsWithAgreementLinks(productsItems)
        .then(categories => {

          commit('setProductsWithAgreement', categories)
          commit('setProductsWithAgreementLoaded', true)
        })
        .catch(() => {
          commit('setProductsWithAgreementException', true)
        })
        .finally(() => {
          commit('setProductsWithAgreementLoading', false)
        })
    }
  }
}
