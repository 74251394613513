import {
  CategoryView,
  ProductView,
  DescriptionView,
  SpecificationsView,
  DocumentsView
} from '@/apps/products/views'

import store from '@/store/store'

export const routes = {
  DESCRIPTION: 'description',
  SPECIFICATIONS: 'specifications',
  SPECIFICATION: 'specification',
  DOCUMENTS: 'documents'
}

export default [{
  path: 'products',
  component: {
    template: '<router-view />'
  },
  children: [{
    path: ':category',
    component: {
      template: '<router-view />'
    },
    children: [
      {
        path: '',
        name: 'category',
        component: CategoryView
      },
      {
        path: ':product',
        component: ProductView,
        children: [
          {
            path: '',
            name: 'product',
            redirect: {
              name: 'description'
            }
          },
          {
            path: 'dokument',
            redirect: {
              name: 'documents'
            }
          },
          {
            name: 'description',
            path: 'description',
            component: DescriptionView,
            beforeEnter (to, from, next) {
              const product = store.state.Products.ContentModule.product

              if (product) {
                const params = to.params

                if (product.tabs.description) {
                  next()
                } else if (product.tabs.specifications) {
                  next({
                    name: 'specifications',
                    params: {
                      product: params.product,
                      locale: params.locale
                    }
                  })
                } else {
                  next({
                    name: 'documents',
                    params: {
                      product: params.product,
                      locale: params.locale
                    }
                  })
                }
              } else {
                next()
              }
            }
          },
          {
            name: 'specifications',
            path: 'specifications',
            component: SpecificationsView,
            beforeEnter: (to, from, next) => {
              const product = store.state.Products.ContentModule.product

              if (product) {
                const params = to.params

                next({
                  name: 'specification',
                  params: {
                    product: params.product,
                    specification: product.tabs.specifications.list[0].path,
                    locale: params.locale
                  }
                })
              } else {
                next()
              }
            }
          },
          {
            name: 'specification',
            path: 'specifications/:specification',
            component: SpecificationsView
          },
          {
            name: 'documents',
            path: 'documents',
            component: DocumentsView
          },
          {
            path: '*',
            redirect: {
              name: 'description'
            }
          }
        ]
      }
    ]
  }]
}]
