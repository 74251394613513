<template>
  <article
    class="search-result-item">
    <section>
      <tws-nav-link
        :href="link"
        class="search-result-item__header">
        {{ title }}
      </tws-nav-link>

      <div
        v-if="subtitle"
        id="search-result-item-subtitle"
        class="search-result-item__subtitle">
        {{ subtitle }}
      </div>

      <template v-if="!subtitle && product.length">
        <div
          v-for="(item, index) of product"
          id="search-result-item-category"
          :key="index"
          class="search-result-item__subtitle">
          {{ item.category }}
          <TeliaCoreIcon
            name="arrow_right"
            size="12px" />
          {{ item.subCategory }}
        </div>
      </template>

      <div class="search-result-item__text">
        {{ leadParagraph | limitText }}
      </div>
    </section>

    <figure
      v-if="thumbnail"
      class="search-result-item__image">
      <img
        :src="thumbnail.url"
        :alt="thumbnail.url">
    </figure>

    <div class="d-flex">
      <div v-if="documentType">
        <tws-file-extension-icon
          id="file-extension-icon"
          :extension="documentType" />
      </div>

      <time class="search-result-item__date">
        {{ date | formatDate }}
      </time>
    </div>
  </article>
</template>

<script>
import DateTimeUtils from '@/common/datetime-utils'
import { TwsNavLink } from 'tws-vue-components'

export default {
  components: {
    TwsNavLink
  },
  filters: {
    formatDate: DateTimeUtils.formatDateIso,
    limitText (text) {
      return text && text.length > 256 ? text.substring(0, 256) + '...' : text
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    leadParagraph: {
      type: String,
      required: true
    },
    thumbnail: {
      type: Object,
      default: null
    },
    date: {
      type: Date,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    product: {
      type: Array,
      default: () => []
    },
    documentType: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.heading-m {
  margin-bottom: 30px;
}

.search-result-item {
  display: flex;
  justify-content: space-between;

  border-top: 1px solid $medium-grey;
  border-bottom: 0;
  padding: 15px 0 20px;

  @media(max-width: $breakpoint-sm) {
    flex-direction: column-reverse;
  }

  &:last-of-type {
    border-bottom: 1px solid $medium-grey;
  }

  &__header {
    font-size: 22px;
    color: $core-purple;
  }

  &__subtitle {
    margin-top: 5px;
    font-size: 16px;
    color: $dark-grey;
  }

  &__text {
    margin-top: 10px;
    color: #222222;

    a {
      white-space: nowrap;
    }
  }

  &__image {
    margin-left: 50px;

    @media(max-width: $breakpoint-sm) {
      display: none;
    }

    img {
      max-width: 200px;
    }
  }

  &__date {
    white-space: nowrap;
    color: $dark-grey;
    margin-bottom: 10px;

    @media(min-width: $breakpoint-sm) {
      margin-left: 50px;
    }
  }
}
</style>
