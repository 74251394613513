import { CATEGORY } from '@/views/site-search/constants'
import SiteSearchService from '@/services/site-search.service'

export const INITIAL_SEARCH_MODEL = {
  category: CATEGORY.ALL,
  limit: 20,
  offset: 0
}

const mapSearchResults = (result) => {
  const data = {
    title: result.title,
    subtitle: result.subtitle,
    leadParagraph: result.description,
    publishDate: new Date(result.date),
    link: result.url,
    ...result.additionalData ? {
      thumbnail: result.additionalDate.thumbnail,
      category: result.additionalDate.category
    } : {}
  }

  data.documentType = result.documentType
  data.product = result.product

  return data
}

export const SiteSearchModule = {
  namespaced: true,
  state: {
    results: [],
    totalCount: 0,
    loading: false,
    loaded: false,
    exception: false,

    searchModel: {
      ...INITIAL_SEARCH_MODEL
    }
  },
  mutations: {
    setResults (state, results = []) {
      state.results = [...results]
    },
    setTotalCount (state, count) {
      state.totalCount = count
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    },

    setSearchModel (state, searchModel = {}) {
      state.searchModel = searchModel
    }
  },
  actions: {
    loadSearchResults: async function ({ commit, state, rootState }) {
      commit('setLoading', true)
      commit('setLoaded', false)
      commit('setResults', [])
      commit('setTotalCount', 0)
      commit('setException', false)

      try {
        const productCategories = rootState.publicHeader.megaMenus.products.categories
        const remappedProductCategories = productCategories.map(c => {
          return {
            order: c.order,
            title: c.title,
            subCategories: c.categories.map((s, index) => {
              return {
                order: index,
                link: s.link,
                title: s.title,
                productId: s.productId
              }
            })
          }
        })

        const seenProductIds = new Set()

        const uniqueCategoryList = remappedProductCategories.map(category => {
          const filteredSubCategories = category.subCategories.filter(subCategory => {
            if (subCategory.productId && !seenProductIds.has(subCategory.productId)) {
              seenProductIds.add(subCategory.productId)
              return true
            }
            return false
          })
          return {
            ...category,
            subCategories: filteredSubCategories
          }
        })

        const body = {
          ...state.searchModel,
          categoryList: uniqueCategoryList
        }

        const response = await SiteSearchService.getSearchResults(body)

        commit('setResults', response.results.map(mapSearchResults))
        commit('setTotalCount', response.total)
        commit('setLoaded', true)
      } catch (err) {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    clearSearchResults ({ commit }) {
      commit('setLoading', false)
      commit('setLoaded', true)
      commit('setResults', [])
      commit('setTotalCount', 0)
      commit('setException', false)
    },
    changeSearchModel ({ commit, dispatch }, searchModel) {
      commit('setSearchModel', {
        ...searchModel
      })

      dispatch('loadSearchResults')
    },
    changeSearchOffset ({ commit, dispatch, state }, offset) {
      commit('setSearchModel', {
        ...state.searchModel,
        offset
      })
      dispatch('loadSearchResults')
    },
    changeSearchInput ({ commit, state, dispatch }, query) {
      commit('setSearchModel', {
        ...state.searchModel,
        query,
        offset: 0
      })

      if (!query) {
        dispatch('clearSearchResults')
      } else {
        dispatch('loadSearchResults')
      }
    },
    changeCategory ({ commit, dispatch, state }, category) {
      commit('setSearchModel', {
        ...state.searchModel,
        category
      })
      dispatch('loadSearchResults')
    },
    changeSorting ({ commit, state, dispatch }, sorting) {
      commit('setSearchModel', {
        ...state.searchModel,
        sorting
      })
      dispatch('loadSearchResults')
    },
    toggleBoolField ({ commit, state, dispatch }, field) {
      commit('setSearchModel', {
        ...state.searchModel,
        [field]: !state.searchModel[field]
      })
      dispatch('loadSearchResults')
    },
    clearFields ({ commit, state, dispatch }, fields) {
      const searchModelWithoutFields = {
        ...state.searchModel
      }

      fields.forEach(key => {
        delete searchModelWithoutFields[key]
      })

      commit('setSearchModel', searchModelWithoutFields)
      dispatch('loadSearchResults')
    }
  }
}
