<template>
  <CommonPageView
    class="tws-news-view"
    :is-grey-background="false"
    :loading="false"
    :breadcrumb-items="breadcrumbItems"
    :banner="banner">
    <template #content>
      <div class="container">
        <div class="row upper-block">
          <div class="col">
            <tws-actual-news />
          </div>
          <div class="col">
            <tws-planned-news />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <tws-archived-news />
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import ActualOperationalNews from '@/components/operational-news/actual/actual-news'
import ArchivedOperationalNews from '@/components/operational-news/archived/archived-news'
import PlannedOperationalNews from '@/components/operational-news/planned/planned-news'
import CommonPageView from '@/views/CommonPageView'
import i18n from '@/i18n'
import { BREADCRUMBS_OPERATIONAL_NEWS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    'tws-actual-news': ActualOperationalNews,
    'tws-archived-news': ArchivedOperationalNews,
    'tws-planned-news': PlannedOperationalNews,
    CommonPageView
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_OPERATIONAL_NEWS,
      banner: {
        heading: i18n.t('news.breadcrumbs.operational-news')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 48px;
}

.upper-block {
  padding-bottom: 50px;
}
</style>
