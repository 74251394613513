import UserUtils from '@/utils/user.utils'
import Constants from '../utils/constants'
import Wow from './wow-mapping'

const resolveCaseType = (caseType, product) => {
  if (product === Constants.FAKE_PRODUCTS.SUPPORT_AFTER_WORK_HOURS) {
    return Wow.CASE_TYPE_TO_STRING[Constants.CASE_TYPES.ESCALATION]
  }

  return Wow.CASE_TYPE_TO_STRING[caseType]
}

const resolveProduct = (product) => {
  return Wow.PRODUCT_TO_STRING[product]
}

const resolveContent = (form) => {
  const contentProxy = []
  contentProxy.push(form.content)

  if (form.assetId || form.orderId || form.ttId) {
    contentProxy.push('')
  }

  if (form.orderId) {
    contentProxy.push(`Ordernummer: ${form.orderId}`)
  }

  if (form.assetId) {
    contentProxy.push(`Förbindelsenummer: ${form.assetId}`)
  }

  if (form.ttId) {
    contentProxy.push(`Reklamationsnummer: ${form.ttId}`)
  }

  return contentProxy.join('\n')
}

const resolveContactEmailData = (form) => {
  return form.receiveEmailNotification ? {
    responseEmail: form.responseEmail,
    contactEmail: UserUtils.email(),
    contactFirstName: UserUtils.firstName(),
    contactLastName: UserUtils.lastName()
  } : { responseEmail: form.responseEmail }
}

export const buildTicketCreateRequest = (form) => {
  return {
    caseType: resolveCaseType(form.caseType, form.product),
    product: resolveProduct(form.product),
    subject: form.subject,
    content: resolveContent(form),
    ...form.assetId && { assetId: form.assetId },
    ...form.customerReference && { customerReference: form.customerReference },
    ...form.orderId && { orderId: form.orderId },
    ...form.ttId && { ttId: form.ttId },
    ...resolveContactEmailData(form)
  }
}
