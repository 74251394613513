<template>
  <div>
    <table
      class="table table-borderless">
      <thead>
        <tr>
          <th colspan="4">
            {{ $t('invoices.table-headers.invoice') }}
          </th>
          <th>{{ $t('invoices.table-headers.invoice-date') }}</th>
          <th>{{ $t('invoices.table-headers.invoice-due-date') }}</th>
        </tr>
      </thead>
      <expandable-row
        v-for="(item, index) in items"
        :key="index"
        :is-expandable-row-available="isExpandableRowAvailable(item)"
        :item="item"
        :row-index="index">
        <template #columns>
          <td colspan="4">
            <a
              :href="getDownloadLinkInvoices(item)"
              class="invoice-link">
              <tws-file-extension-icon :extension="invoiceExtension" />
              <span class="document-number">{{ item.invoiceNumber }}</span>
            </a>
          </td>
          <td>{{ item.invoiceDate }}</td>
          <td>{{ item.dueDate }}</td>
        </template>
        <template #subRow>
          <tr>
            <td />
            <td
              class="specification-info"
              colspan="6">
              {{ $t('invoices.table-headers.specification-info') }}
            </td>
          </tr>
          <tr>
            <td />
            <td
              class="invoices-specifications-cell"
              colspan="6">
              <table class="table table-borderless sub-table">
                <thead class="heading">
                  <tr>
                    <th colspan="3">
                      {{ $t('invoices.table-headers.specification') }}
                    </th>
                    <th>{{ $t('invoices.table-headers.date') }}</th>
                    <th>{{ $t('invoices.table-headers.type') }}</th>
                    <th>{{ $t('invoices.table-headers.period-start') }}</th>
                    <th>{{ $t('invoices.table-headers.period-end') }}</th>
                  </tr>
                </thead>
                <tr
                  v-for="(specification, specIndex) in item.specifications"
                  :key="specIndex">
                  <td colspan="3">
                    <a
                      :href="getDownloadLinkSpecs(specification)"
                      class="invoice-specification-link">
                      <span class="">{{ specification.id }}</span>
                    </a>
                  </td>
                  <td>{{ specification.invoicePeriodEnd }}</td>
                  <td>{{ specification.contentType }}</td>
                  <td>{{ specification.invoicePeriodStart }}</td>
                  <td>{{ specification.invoicePeriodEnd }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </template>
      </expandable-row>
    </table>
    <tws-pagination
      v-if="totalCount > limit"
      :aria-label="ariaLabel"
      :current-page="currentPage"
      :extra-page-count="5"
      :item-count-on-page="limit"
      :data-count="totalCount"
      @onPageClick="changePage" />
  </div>
</template>

<script>
import { TwsPagination } from 'tws-vue-components'

import ExpandableRow from './invoices-expandable-row'
import TwsFileExtensionIcon from '@/components/common/file-extension-icon.vue'

export default {
  components: {
    TwsPagination,
    ExpandableRow,
    TwsFileExtensionIcon
  },
  props: {
    ariaLabel: {
      type: String,
      default: null
    },
    totalCount: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    offset: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    hasInvoiceSpecificationsRole: {
      type: Boolean
    }
  },
  data () {
    return {
      invoiceExtension: 'pdf'
    }
  },
  computed: {
    currentPage () {
      return Math.floor(this.offset / this.limit) + 1
    }
  },
  methods: {
    changePage (page) {
      this.$emit('onOffsetChange', (page - 1) * this.limit)
    },
    getDownloadLinkInvoices (item) {
      return `${process.env.VUE_APP_BILLING_SYSTEM_INTEGRATION_API}/invoices/${item.invoiceNumber}/content`
    },
    getDownloadLinkSpecs (item) {
      return `${process.env.VUE_APP_BILLING_SYSTEM_INTEGRATION_API}/specifications/contents/${item.id}`
    },
    isExpandableRowAvailable (item) {
      return this.hasInvoiceSpecificationsRole && !!item.specifications && !!item.specifications.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.table {
  table-layout: auto !important;
  border-collapse: collapse;
  margin-top: 1rem;

  th {
    text-align: left;
  }

  td, th {
    padding: 2rem;
  }
}

.document-number {
  margin-left: 15px;
  font-weight: bold;
}

.invoice-specification-link {
  color: $core-purple;
}

.invoice-link {
  &:hover {
    color: $core-purple;
  }
}

.heading {
  background-color: $grey;
}

.sub-table {
  tr:not(:first-child) {
    border-bottom: solid thin $medium-grey;
  }
}

.specification-info {
  padding: 1rem 0 !important;
}

.invoices-specifications-cell {
  padding: 0 !important;
}
</style>
