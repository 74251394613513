import PublicLandingService from '../services/public-landing.service'
import { extractContent } from './transformers/landing-page.transformer'

export const PublicLandingModule = {
  namespaced: true,
  state: {
    loading: true,
    exception: false,
    content: null
  },
  mutations: {
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setContent: (state, content) => state.content = content
  },
  actions: {
    async loadContent ({ commit }) {
      try {
        const response = await PublicLandingService.fetch()

        commit('setContent', extractContent(response))
      } catch {
        commit('setException', true)
      }
      finally {
        commit('setLoading', false)
      }
    }
  }
}
