<template>
  <div>
    <AddressPickerNavigator
      v-if="showAddressPickerA"
      key="picker-A"
      :point="advancedSearch ? 'A' : null"
      :addresses="addressesA"
      @address-pick="handleAddressPickA"
      @close="closeAddressPicker" />
    <AddressPickerNavigator
      v-if="showAddressPickerB"
      key="picker-B"
      point="B"
      :addresses="addressesB"
      @address-pick="handleAddressPickB"
      @close="closeAddressPicker" />
  </div>
</template>

<script>
import AddressPickerNavigator from './AddressPickerNavigator'

export default {
  components: { AddressPickerNavigator },
  props: {
    advancedSearch: {
      type: Boolean,
      default: false
    },
    addressesA: {
      type: Array,
      required: true
    },
    addressesB: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showAddressPickerA () {
      return this.addressesA.length > 1
    },
    showAddressPickerB () {
      return this.addressesB.length > 1 && !this.showAddressPickerA
    },
    foundAddressA () {
      return this.addressesA[0]
    },
    foundAddressB () {
      return this.addressesB[0]
    }
  },
  methods: {
    closeAddressPicker () {
      this.$emit('close')
    },
    handleAddressPickA (payload) {
      this.$emit('address-pick-a', payload)
    },
    handleAddressPickB (payload) {
      this.$emit('address-pick-b', payload)
    }
  }
}
</script>
