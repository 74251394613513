<template>
  <AddressElementPicker
    :title="title"
    :sub-title="$t('address-picker.entrance.sub-title')"
    :options="entrances"
    :can-go-back="canGoBack"
    @select="$emit('entrance-pick', $event.value)"
    @go-back="$emit('entrance-pick', null)"
    @close="$emit('close')" />
</template>

<script>
import AddressElementPicker from './AddressElementPicker'
import { printAddress } from '@/apps/address-search/utils/string-utils'

const reduceToEntrances = (agg, address) => {
  agg.add(address.entrance)
  return agg
}

export default {
  components: { AddressElementPicker },
  props: {
    addresses: {
      type: Array,
      required: true
    },
    pickedCity: {
      type: String,
      required: true
    },
    pickedStreet: {
      type: String,
      required: true
    },
    pickedPostNumber: {
      type: String,
      required: true
    },
    pickedStreetNumber: {
      type: String,
      required: true
    },
    canGoBack: {
      type: Boolean,
      default: false
    },
    titlePrefix: {
      type: String,
      default: ''
    }
  },
  computed: {
    title () {
      return this.titlePrefix + printAddress({
        city: this.pickedCity,
        streetName: this.pickedStreet,
        streetNumber: this.pickedStreetNumber,
        postNumber: this.pickedPostNumber
      })
    },
    entrances () {
      return Array
        .from(this.addresses.reduce(reduceToEntrances, new Set()))
        .map(entrance => ({
          label: entrance || this.$t('address-picker.entrance.label-no-entrance'),
          value: entrance,
          width: entrance ? 1 : 2
        }))
    }
  }
}
</script>
