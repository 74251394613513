<template>
  <div>
    <GrootHeroBanner
      v-if="mainTitle"
      :title="mainTitle"
      :preamble="mainDescription"
      :breadcrumbs="breadcrumbItems" />

    <div class="container">
      <AddressSearch
        @select-address="fetchProducts"
        @select-address-address="fetchFiberProductsAddressAddress"
        @select-address-node="fetchFiberProductsAddressNode"
        @select-node-node="fetchFiberProductsNodeNode" />
      <ProductsDisplay v-if="!addressSearchLoading" />
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import { GrootHeroBanner } from 'groot-components'
import AddressSearch from '@/apps/address-search/components/AddressSearch'
import ProductsDisplay from '@/apps/address-search/components/ProductsDisplay'
import { SEARCH_MODE } from '@/apps/address-search/utils/constants.utils'
import { BREADCRUMBS_ADDRESS_SEARCH } from '@/constants/breadcrumbs-constants'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  name: 'Main',
  components: {
    AddressSearch,
    ProductsDisplay,
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner)
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_ADDRESS_SEARCH
    }
  },
  computed: {
    ...mapGetters('AddressSearch/AddressSearch', {
      searchMode: 'searchMode',
      addressSearchLoading: 'loading'
    }),
    ...mapGetters('AddressSearch/Content', [
      'loading',
      'mainTitle',
      'mainDescription'
    ])
  },
  watch: {
    searchMode (next) {
      if (next === SEARCH_MODE.ADDRESS) {
        this.resetProducts()
      } else {
        this.resetAdvancedProducts()
      }
    }
  },
  mounted () {
    this.$store.dispatch('AddressSearch/Content/fetchContent')
  },
  methods: {
    ...mapActions('AddressSearch/Products', {
      fetchProducts: 'fetchProducts',
      resetProducts: 'resetState'
    }),
    ...mapActions('AddressSearch/Products/advancedProducts', {
      fetchFiberProductsAddressAddress: 'fetchFiberProductsAddressAddress',
      fetchFiberProductsAddressNode: 'fetchFiberProductsAddressNode',
      fetchFiberProductsNodeNode: 'fetchFiberProductsNodeNode',
      resetAdvancedProducts: 'resetState'
    })
  }
}
</script>
