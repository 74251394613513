<template>
  <div class="row fault-report-mobile-sp">
    <div class="col-xl-11 col-sm-11 col-xs-12">
      <div class="row">
        <div class="col-12">
          <p class="title-200">
            {{ $t('fault-reporting.form.description.description') }}
          </p>
        </div>
      </div>

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <TwsValidatedTextInputGroup
            id="contact"
            v-model="contactProxy"
            :validator="$v.contact"
            :validation-messages="validationMessages.contact"
            :label="$t('fault-reporting.form.description.contact.label')" />
        </div>

        <div class="col-xl-5 col-sm-5 col-xs-12">
          <TwsValidatedTextInputGroup
            id="IMEI"
            v-model="IMEIProxy"
            :validator="$v.IMEI"
            :validation-messages="validationMessages.IMEI"
            :label="$t('fault-reporting.form.description.IMEI.label')" />
        </div>
      </div>

      <hr class="divider">

      <div class="row fieldset">
        <div class="col-xl-5 col-sm-5 col-xs-12">
          <TwsValidatedTextInputGroup
            id="registrationNumber"
            v-model="registrationNumberProxy"
            :validator="$v.registrationNumber"
            :validation-messages="validationMessages.registrationNumber"
            :label="$t('fault-reporting.form.description.registration-number.label')" />
        </div>

        <div class="col-xl-5 col-sm-5 col-xs-12">
          <TwsValidatedTextInputGroup
            id="ownReference"
            v-model="ownReferenceProxy"
            :validator="$v.ownReference"
            :validation-messages="validationMessages.ownReference"
            :label="$t('fault-reporting.form.description.own-reference.label')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TwsValidatedTextInputGroup } from 'tws-vue-components'
import {
  mapState, mapActions
} from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  components: {
    TwsValidatedTextInputGroup
  },

  mixins: [validationMixin],

  data () {
    return {
      validationMessages: {
        contact: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        IMEI: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        registrationNumber: {
          required: this.$t('fault-reporting.default-required-validation-message')
        },
        ownReference: {
          required: this.$t('fault-reporting.default-required-validation-message')
        }
      }
    }
  },

  computed: {
    ...mapState('FaultReporting/FaultReportForm/MobileSP', [
      'contact',
      'IMEI',
      'registrationNumber',
      'ownReference'
    ]),
    contactProxy: {
      get () {
        return this.contact
      },
      set (value) {
        this.updateContact(value)
      }
    },
    IMEIProxy: {
      get () {
        return this.IMEI
      },
      set (value) {
        this.updateIMEI(value)
      }
    },
    registrationNumberProxy: {
      get () {
        return this.registrationNumber
      },
      set (value) {
        this.updateRegistrationNumber(value)
      }
    },
    ownReferenceProxy: {
      get () {
        return this.ownReference
      },
      set (value) {
        this.updateOwnReference(value)
      }
    },
    isValidProxy () {
      return !this.$v.$invalid
    }
  },

  watch: {
    isValidProxy (val) {
      this.updateIsValid(val)
    }
  },

  validations: {
    contact: {
      required
    },
    IMEI: {
      required
    },
    registrationNumber: {
      required
    },
    ownReference: {
      required
    }
  },

  methods: {
    ...mapActions('FaultReporting/FaultReportForm/MobileSP', [
      'updateContact',
      'updateIMEI',
      'updateRegistrationNumber',
      'updateOwnReference',
      'updateIsValid'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import "./default.scss";
</style>
