<script>
import PromotionCard from './PromotionCard.vue'
import PurpurHeading from '../purpur/PurpurHeading.vue'

export default {
  components: {
    PromotionCard,
    PurpurHeading
  },
  props: {
    title: {
      type: String,
      required: true
    },
    promotionCardList: {
      type: Array,
      default: () => []
    }
  }
}

</script>

<template>
  <div class="promotion-section">
    <div class="container">
      <PurpurHeading
        variant="title-400"
        class="promotion-section__title">
        {{ title }}
      </PurpurHeading>

      <div class="promotion-section__cards">
        <PromotionCard
          v-for="card in promotionCardList"
          :key="card.text"
          :icon-url="card.iconUrl"
          :text="card.text" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@purpurds/tokens/color/variables' as colors;
@use '@purpurds/tokens/breakpoint/variables' as breakpoints;
@use '../../assets/scss/_mixins.scss' as mixins;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;

.promotion-section {
  display: flex;
  flex-direction: column;
  gap: spacing.$purpur-spacing-400;
  background: colors.$purpur-color-gray-50;
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: breakpoints.$purpur-breakpoint-md) {
    padding-bottom: spacing.$purpur-spacing-800;
  }

  @media (min-width: breakpoints.$purpur-breakpoint-lg) {
    padding-top: spacing.$purpur-spacing-800;
    padding-bottom: 80px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: spacing.$purpur-spacing-300;
    @include mixins.indent-margin;

    @media (min-width: breakpoints.$purpur-breakpoint-lg) {
      flex-direction: row;
    }
  }
}

::v-deep.promotion-section__title {
  margin-bottom: spacing.$purpur-spacing-400;
}
</style>
