<template>
  <span :class="'status-' + status + '--' + colorPalette" />
</template>

<script>

import MapUtils from '@/utils/map-utils'

export default {
  props: {
    status: {
      type: Number,
      required: true
    },
    colorPalette: {
      type: String,
      required: true,
      validator: MapUtils.colorPaletteValidator
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import 'scss/map-colors';

span {
  margin-bottom: -2px;
  margin-right: 5px;
  height: 16px;
  width: 16px;
  background-color: $medium-grey;
  border-radius: 50%;
  display: inline-block;
}

@each $status, $color in $map-colors-orange {
  .status-#{$status}--orange {
    background-color: $color;
  }
}

@each $status, $color in $map-colors-cyan {
  .status-#{$status}--cyan {
    background-color: $color;
  }
}

@each $status, $color in $map-colors-orange-alt {
  .status-#{$status}--orange-alt {
    background-color: $color;
  }
}

</style>
