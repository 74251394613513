<template>
  <CommonPageView
    class="tws-iframe"
    :banner="banner"
    :breadcrumb-items="breadcrumbItems"
    :loading="loading"
    :exception="exception">
    <template #content>
      <iframe-wrapper
        :key="$route.fullPath"
        :content-url="computedUrl">
        <slot name="iframeSlot" />
      </iframe-wrapper>
    </template>
  </CommonPageView>
</template>

<script>
import CommonPageView from '@/views/CommonPageView'
import IframeWrapper from '@/components/inframe/IframeWrapper'
import { getIframeUrl } from '@/apps/fault-reporting/utils/iframe-path.utils'

export default {
  components: {
    CommonPageView,
    IframeWrapper
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    exception: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    banner: {
      type: Object,
      required: true
    },
    breadcrumbItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    computedUrl () {
      return getIframeUrl(this.url)
    }
  }
}
</script>
