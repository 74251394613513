/* eslint-disable */
import { createInstance } from './axios.api.js'

export const api = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE)

const LoginService = {
  /**
   * Silently call EAM to request OTC vis SMS, which is then used to login.
   * Legacy approach. Can't catch any errors this way, since EAM returns redirects.
   * In order to work on localhost environment browser plugin allowing CORS is required.
   */
  sendOneTimeCode: (authenticationUrl, username, returnUrl, errorUrl) => {
     axios({
       method: 'post',
       maxRedirects: 0,
       url: authenticationUrl,
       data: `username=${username}&password=GG&type=SMS&return_url=${returnUrl}&error_url=${errorUrl}`,
       config: {
         headers: {
           'Content-Type': 'application/x-www-form-urlencoded'
         }
       }
     })
  },
  getEamAuthenticationUrl: () => {
    return process.env.VUE_APP_EAM_AUTHENTICATION_URL
  }
}

export default LoginService
