<template>
  <div
    class="icons">
    <div class="container">
      <div class="row">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="col-md-3 gg">
          <div :class="`${'icon__' + variant}`">
            <span :class="`${'icon__' + variant + '__image'}`">
              <img
                :src="item.icon"
                alt="icon">
            </span>

            <p class="icon__title preamble">
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    variant: {
      type: String,
      default: 'primary',
      validator (value) {
        return [
          'primary',
          'secondary'
        ].indexOf(value) !== -1
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.row {
  justify-content: center;
}

.icon {
  &__primary {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 25px;

    img {
      width: 72px;
      height: 72px;
    }

    &__image {
      margin-bottom: 5px;
    }

  }

  &__secondary {
    display: flex;
    flex-direction: column;
    text-align: center;

    @media(max-width: $breakpoint-md) {
      margin-top: 12px;
      flex-direction: row;
      text-align: left;
      align-items: center;
    }

    &__image {
      line-height: 0;
      margin-bottom: 5px;

      @media(max-width: $breakpoint-md) {
        margin-right: 15px;
        margin-bottom: 0;
      }

      @media(min-width: $breakpoint-md) {
        margin: 15px;
      }
    }

    img {
      width: 54px;
      height: 54px;

      @media(max-width: $breakpoint-md) {
        max-height: 24px;
        max-width: 24px;
      }
    }
  }

  &__title {
    word-break: break-word;

    @media(max-width: $breakpoint-md) {
      font-size: 16px;
    }
  }
}

</style>
