<template>
  <purpur-paragraph :variant="variant">
    <slot />
  </purpur-paragraph>
</template>

<script>
import { Paragraph } from '@purpurds/purpur'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

export default {
  components: {
    'purpur-paragraph': applyReactInVueWithOptions(Paragraph)
  },
  props: {
    variant: {
      type: String,
      default: 'preamble-100'
    }
  }
}

</script>
