import { BREADCRUMB_DASHBOARD } from '@/constants/breadcrumbs-constants'

export const contentStoreFactory = (contentFetchFn) => {
  return {
    namespaced: true,
    state: {
      loading: true,
      exception: false,
      content: null
    },
    mutations: {
      setLoading: (state, loading) => state.loading = loading,
      setException: (state, exception) => state.exception = exception,
      setContent: (state, content) => state.content = content
    },
    actions: {
      async loadContent ({ commit }) {
        try {
          const response = await contentFetchFn()
          const breadcrumbItems = [
            ...BREADCRUMB_DASHBOARD,
            {
              title: response.fields.pageBanner.fields.heading
            }
          ]
          const content = {
            breadcrumbItems: breadcrumbItems,
            ...response
          }
          commit('setContent', content)
        } catch {
          commit('setException', true)
        } finally {
          commit('setLoading', false)
        }
      }
    }
  }
}
