<template>
  <CommonPageView
    class="tws-map-search"
    :breadcrumb-items="breadcrumbItems"
    :loading="false"
    :banner="banner">
    <template #content>
      <div class="container tws-map-search__article">
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="tws-map-search__content">
              <tws-rich-text :html="content" />
              <tws-button-primary
                @click="openMap">
                {{ $t('search-tools.map-search.button') }}
                <tws-new-window-icon />
              </tws-button-primary>
            </div>
          </div>
          <div
            class="col-lg-6 col-sm-12">
            <div class="tws-map-search__image">
              <img
                src="@/assets/png/kartsok_new.png"
                alt="map-search">
            </div>
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import CommonPageView from '@/views/CommonPageView'
import TwsRichText from '@/components/rich-text/rich-text'
import TwsNewWindowIcon from '@/img/svg/new-window.svg'
import { TwsButtonPrimary } from 'tws-vue-components'
import { BREADCRUMBS_MAP_SEARCH } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    CommonPageView,
    TwsButtonPrimary,
    TwsRichText,
    TwsNewWindowIcon
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_MAP_SEARCH,
      banner: {
        heading: this.$t('search-tools.map-search.title')
      },
      content: this.$t('search-tools.map-search.content')
    }
  },
  methods: {
    openMap () {
      window.open('/mapsearch-web/index.html')
    }
  }
}
</script>
<style lang="scss" scoped>

.tws-map-search {

  &__content {
    @media (min-width: 992px) {
      padding: 0 48px 0 0;

      ::v-deep{
        p {
          margin-top: 0;
        }
      }
    }
  }

  &__article {
    padding: 58px 0 0 0;
  }

  &__button-icon {
    padding: 0 0 0 2px;
  }

  &__image {
    img {
      max-width: 100%;
    }

    @media (min-width: 992px) {
      padding: 0 0 0 48px;
    }
  }
}
</style>
