<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 290 660">
    <template
      v-for="county in counties">
      <path
        :key="county.code"
        :d="county.path"
        class="county"
        :class="'status-' + statusClass(county) + '--' + colorPalette"
        @click="$emit('select', county.code)">
        <title>{{ titleText(county) }}</title>
      </path>
    </template>
  </svg>
</template>

<script>
import staticMapData from '@/components/map/data/map-default.json'
import staticMapCableFaultsData from '@/components/map/data/map-cable-faults.json'

export default {
  props: {
    items: {
      type: Object,
      required: true
    },
    colorPalette: {
      type: String,
      required: true,
      validator: (value) => {
        return [
          'orange',
          'orange-alt',
          'cyan'
        ].indexOf(value) !== -1
      }
    },
    mapType: {
      type: String,
      default: 'default',
      validator: (value) => {
        return [
          'default',
          'cable-faults'
        ].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      counties: {}
    }
  },
  mounted () {
    this.counties = this.mapType === 'cable-faults' ? staticMapCableFaultsData : staticMapData
  },
  methods: {
    statusClass (county) {
      const item = this.items[county.code]
      return item ? item.status : 0
    },
    titleText (county) {
      const item = this.items[county.code]

      if (!item || !item.title) {
        return county.name
      }

      return item.title
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import 'scss/map-colors';

// IE11 svg size bug workaround
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  svg {
    height: 900px;
  }
}

.county {
  fill: $medium-grey;
  stroke: $white;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

@each $status, $color in $map-colors-orange {
  .status-#{$status}--orange {
    fill: $color;
  }
}

@each $status, $color in $map-colors-cyan {
  .status-#{$status}--cyan {
    fill: $color;
  }
}

@each $status, $color in $map-colors-orange-alt {
  .status-#{$status}--orange-alt {
    fill: $color;
  }
}
</style>
