<template>
  <div
    v-if="isValidatorCreated && getValidatorFormField(labels.field_name)"
    @click="showDependencyMessage">
    <tws-validated-checkbox-group
      :id="'form-check-' + labels.field_name"
      v-model="model"
      :options="mappedOptions"
      :label="labels.checkbox_label"
      :validator="getValidatorFormField(labels.field_name)"
      :validation-messages="validationMessages"
      :disabled="disabledField(labels.field_name)" />
    <p
      v-show="showFieldMessage"
      class="dependency-text">
      {{ dependencyMessage }}
    </p>
  </div>
</template>

<script>
import {
  mapGetters, mapActions
} from 'vuex'

import { TwsValidatedCheckboxGroup } from 'tws-vue-components'

export default {
  name: 'TwsFormCheckbox',
  components: {
    TwsValidatedCheckboxGroup
  },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validationMessages: {
        required: this.labels.validation_message
      },
      showFieldMessage: false
    }
  },
  computed: {
    ...mapGetters('DynamicForm', [
      'getValidatorFormField',
      'isValidatorCreated',
      'disabledField',
      'getFieldDependencyMessage',
      'getFieldValue'
    ]),
    model: {
      get () {
        const value = this.getFieldValue(this.labels.field_name)
        return value ? value : []
      },
      set (value) {
        this.onValueInput(value)
      }
    },
    mappedOptions () {
      const options = []
      Object.values(this.labels.checkbox_group)
        .map(option => options.push({
          text: option.text,
          value: option.value,
          email: option.email
        }))
      return options
    },
    dependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        return this.getFieldDependencyMessage(this.labels.field_name)
      }
      return ''

    }
  },
  mounted () {
    this.addValidation(
      {
        fieldName: this.labels.field_name,
        validations: this.labels.validations
      }
    )
    this.fieldCreated(
      {
        fieldName: this.labels.field_name,
        label: this.labels.checkbox_label,
        templateText: this.labels.template_text,
        isFieldRequired: this.labels.is_field_required
      }
    )
  },
  methods: {
    ...mapActions('DynamicForm',
      [
        'fieldCreated',
        'fieldValueEntered',
        'addValidation',
        'removeEmailByFieldName'
      ]
    ),
    onValueInput (checkboxValues) {
      this.removeEmailByFieldName(this.labels.field_name)
      if (checkboxValues) {
        this.fieldValueEntered(
          {
            key: this.labels.field_name,
            value: checkboxValues,
            email: Object.values(this.labels.checkbox_group).filter(option => checkboxValues.includes(option.value)).map(option => option.email)[0]
          }
        )
      }
    },
    showDependencyMessage () {
      if (this.disabledField(this.labels.field_name)) {
        this.showFieldMessage = !this.showFieldMessage
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dependency-text {
    color: grey;
    font-size: medium;
  }
</style>
