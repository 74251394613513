import { getNewsPayload } from '@/utils/news.utils'
import {
  createInstance,
  createInstanceWithoutCredentials
} from '@/services/axios.api'
import i18n from '@/i18n'
import { isLoggedIn } from '@/utils/user.utils'

export const api = createInstanceWithoutCredentials(process.env.VUE_APP_CONTENTFUL_GATEWAY)
export const apiLoggedIn = createInstance(process.env.VUE_APP_CONTENTFUL_GATEWAY_LOGGED_IN)

const NewsService = {
  getNewsDetails: async (newsId) => {
    return isLoggedIn() ? await apiLoggedIn.get('/news', {
      params: {
        id: newsId,
        locale: i18n.locale
      }
    }) : await api.get('/news', {
      params: {
        id: newsId,
        locale: i18n.locale
      }
    })
  },
  getAllNews: async (form) => {
    return await apiLoggedIn.post('/news/all', getNewsPayload(form, i18n.locale))
  },
  getPublicNews: async (form) => {
    return await api.post('/news/public', getNewsPayload(form, i18n.locale))
  }
}

export default NewsService
