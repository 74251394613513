export const readFile = (file) => {
  const fileReader = new FileReader()

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject()
    }

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.readAsArrayBuffer(file)
  })
}

export const cleanupFilename = (filename) => {
  if (!filename) {
    return ''
  }

  return filename.replace(/2-([A-Z0-9]*)_/gi, '')
}
