<template>
  <div class="company-details-banner">
    <div
      v-if="loading || loadException"
      class="row">
      <div class="col-12">
        <InfraPageAlert
          v-if="loading"
          type="info">
          {{ $t('company-details.loading') }}
        </InfraPageAlert>

        <InfraPageAlert
          v-if="loadException"
          type="danger">
          {{ $t('company-details.loading-failed') }}
        </InfraPageAlert>
      </div>
    </div>

    <template v-else>
      <div class="row">
        <div class="col-12">
          <h1 class="heading-l--black title-500">
            {{ information.name }}
          </h1>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p class="heading-label heading-label--below">
            {{ information.orgNr }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <tws-action-button @click="toggleDialog">
            <tws-close-icon />
            <span>{{ $t('company-details.toggle-dialog') }}</span>
          </tws-action-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import {
  TwsActionButton,
  InfraPageAlert
} from 'tws-vue-components'

export default {
  components: {
    TwsActionButton,
    InfraPageAlert
  },
  computed: {
    ...mapState('companyDetails', [
      'loading',
      'loadException',
      'information'
    ])
  },
  methods: {
    toggleDialog () {
      alert('Not yet implemented!')
    }
  }
}
</script>

<style lang="scss" scoped>
.heading-l, p {
  margin-bottom: 0;
}

.action-button {
  margin-top: 25px;
  font-size: 20px;
}
</style>
